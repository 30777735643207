import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faGrinTongueSquint } from "@fortawesome/free-solid-svg-icons";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { PlantillaEmisor } from "src/app/models/PlantillaImpresion/PlantillaEmisor";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { PlantillaImpresionService } from "src/app/services/plantilla-impresion.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-asignador-plantillas",
	templateUrl: "./asignador-plantillas.component.html",
	styleUrls: ["./asignador-plantillas.component.scss"],
})
export class AsignadorPlantillasComponent implements OnInit {
	@Input() public plantillaSelected: PlantillaImpresion = undefined;
	@Output() private sendAsignRequest: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	public listOfEmisores: Emisor[] = [];
	public emisoresDePlantilla: PlantillaEmisor[] = [];
	public assignPlantillaForm: FormGroup;
	public loading: boolean = false;
	private asignaPlantillaRequest: PlantillaEmisor = new PlantillaEmisor();

	constructor(public permissions: PermissionService, private formBuilder: FormBuilder, private plantillaService: PlantillaImpresionService, private emisorService: EmisorService, private tokenStorage: TokenStorageService, private ref: ChangeDetectorRef, private localStorage: LocalStorageService) {}

	ngOnInit(): void {
		this.assignPlantillaForm = this.formBuilder.group({
			empresaAsignar: [, Validators.required],
		});

		if (this.tokenStorage.getUser().superAdmin) {
			this.getFullEmisoresList();
		} else this.listOfEmisores = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		this.getPlantillaEmisores();
	}

	onSubmit() {
		if (!this.assignPlantillaForm.valid) {
			return;
		}
		let emisorSelected = this.assignPlantillaForm.controls.empresaAsignar.value as Emisor;
		if (this.emisorAlreadyExist(emisorSelected)) {
			Swal.fire({
				icon: "info",
				title: "Emisor asignado",
				text: "El emisor seleccionado ya se encuentra asignado a esta plantilla",
			});
			return;
		}
		this.loading = true;
		this.plantillaService.assignPlantilla(emisorSelected, this.plantillaSelected, true).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				// let plem:PlantillaEmisor = new PlantillaEmisor();
				// plem.tipoPlantillaId = this.plantillaSelected.tipoPlantillaId;
				// plem.emisorId = emisorSelected.emisorId;
				// plem.emisorAsociado = emisorSelected;
				// plem.activo = true;
				// this.emisoresDePlantilla.push(plem);
				// this.emisoresDePlantilla = JSON.parse(JSON.stringify(this.emisoresDePlantilla)) as PlantillaEmisor[];
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Plantilla Asignada",
					text: "La plantilla " + this.plantillaSelected.descripcion + " fue asignada al emisor " + emisorSelected.razonSocial + " correctamente",
				});
				this.getPlantillaEmisores();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				let texto = "No ha sido posible asignar al emisor" + emisorSelected.razonSocial + ", la plantilla actual";
				Swal.fire({
					icon: "error",
					title: "Error en Asignación",
					text: texto,
				});
			}
		);
	}
	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}

	getFullEmisoresList() {
		this.loading = true;
		this.emisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				// let emisorService = new Emisor();
				// emisorService.razonSocial = "SERVICES";
				// emisorService.emisorId = null;
				// emisores.push(emisorService);
				this.listOfEmisores = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				console.log(error);
			}
		);
	}

	getPlantillaEmisores() {
		this.loading = true;
		this.plantillaService.emisorListOfPlantilla(this.plantillaSelected).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				//let listaEmisores = result.data as PlantillaEmisor[];
				this.emisoresDePlantilla = result.data as PlantillaEmisor[];
				// listaEmisores.forEach(emisor=>{
				//   let plem:PlantillaEmisor = new PlantillaEmisor();
				//   plem.tipoPlantillaId = this.plantillaSelected.tipoPlantillaId;
				//   plem.emisorId = emisor.emisorId;
				//   plem.emisorAsociado = emisor;
				//   plem.activo = true;
				//   this.emisoresDePlantilla.push(plem);
				// })
				// this.emisoresDePlantilla = JSON.parse(JSON.stringify(this.emisoresDePlantilla)) as PlantillaEmisor[];
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en Empresas",
					text: "No fue posible obtener los emisores asignados a esta plantilla, favor recargue la pagina e intente nuevamente.",
				});
			}
		);
	}

	emisorAlreadyExist(emisor: Emisor) {
		return this.emisoresDePlantilla.some((plem) => plem.emisorId == emisor.emisorId);
	}

	activate(plantilla) {
		this.loading = true;
		this.plantillaService.activatePlantilla(plantilla).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Reasignación Exitosa",
					text: "Se ha reasignado la plantilla al emisor",
				});
				this.getPlantillaEmisores();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "info",
					title: "Error al Reasignar Plantilla",
					text: "No fue posible reasignar la plantilla",
				});
			}
		);
	}
}
