import { Emisor } from "../Emisor/Emisor";
import { Receptor } from "../Receptor/Receptor";
import { IDte } from "./IDte";
import { DescuentoRecargo } from "./DescuentoRecargo";
import { DetalleDte } from "./DetalleDte";
import { OtraMoneda } from "./OtraMoneda";
import { GuiaDespacho } from "../GuiaDespacho";
import { ReferenciaDteEmitido } from "./ReferenciaDteEmitido";
export class FacturaCompra implements IDte {
	dteId?: string;
	receptorId?: string;
	rutReceptor?: string;
	emisorId?: string;
	tipoDteId?: string;
	codigoTipoDte?: number;
	sucursalId?: string;
	folio?: number;
	fecha?: Date;
	estadoSii?: number;
	trackId?: number;
	xmlString?: string;
	formaPago?: string;
	formaPagoEnum?: number;
	FormaPagoExportacion: string;
	FormaPagoExportacionEnum?: number;
	archivoExcelId?: string;
	reutilizarFoliohabilitado: boolean;
	neto?: number;
	iva?: number;
	exento?: number;
	total?: number;
	impuestos?: number;
	fechaProcesamiento?: Date;
	consumoFolioId?: string;
	detalleDte?: Array<DetalleDte>;
	emisorAsociado?: Emisor;
	nombreTipoDte?: string;
	razonSocial?: string;
	receptorAsociado?: Receptor;
	receptorEnt: Receptor;
	anulado?: boolean;
	rutReceptorDv?: string;
	folioReutilizado: boolean;
	estadoAcuse: number;
	descripcionEstadoAcuse: string;
	//campos de referencias dte
	fechaDteReferenciado?: Date;
	razonReferencia?: string;
	rutEmisor?: number;
	codigoTipoDteReferencia?: string;
	folioReferencia?: string;
	fechaReferencia?: Date;
	codigoRazonReferencia?: number;
	listaReferenciasDteEmitido: ReferenciaDteEmitido[];
	//tipo de moneda
	tipoMoneda?: string;
	descuentoGlobal?: number;
	formato?: number;
	diasVencimiento?: number;
	otraMonedaEnt?: OtraMoneda;
	DescuentoRecargoEnts?: DescuentoRecargo[];
	notaCreditoHabilitada?: boolean;
	notaDebitoHabilitada?: boolean;
	indicadorServicio?: number;
	indicadorMontosNetos?: boolean;
	retencion: number;
	rutProveedor: string;
	guiaDespacho: GuiaDespacho;
	tieneIvaTerceros: boolean;
	ivaTerceros: number;
	ivaPropio: number;
	tipoMonedaAduana: string;
	codigoMonedaAduana: number;
	documentosLiquidados: string[];
	guiasFacturadas: string[];
}
