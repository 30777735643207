import { AbstractControl } from "@angular/forms";

export function PositiveNumberValidator(control: AbstractControl): { [key: string]: any } | null {
   if (control.value) {
      const numberValue = +control.value;
      return numberValue <= 0 ? { PositiveNumber: true } : null;
   } else {
      return null;
   }
}
