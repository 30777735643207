import { DatePipe } from "@angular/common";
import { ThrowStmt } from "@angular/compiler";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SortType } from "@swimlane/ngx-datatable";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LogTransaccion } from "src/app/models/LogTransaccion/LogTransaccion";
import { LogTransaccionFilter } from "src/app/models/LogTransaccion/LogTransaccionFilter";
import { Usuario } from "src/app/models/Usuario/Usuario";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LogsTransaccionesService } from "src/app/services/logs-transacciones.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigMantenedorLogs } from "./config-mantenedor-logs";
@Component({
	selector: "app-mantenedor-log-transacciones",
	templateUrl: "./mantenedor-log-transacciones.component.html",
	styleUrls: ["./mantenedor-log-transacciones.component.scss"],
})
export class MantenedorLogTransaccionesComponent implements OnInit {
	SortType = SortType;
	public loading: boolean = false;
	public rowsMantenedor: LogTransaccion[] = [];
	public configScroll: PerfectScrollbarConfigInterface = {};
	public page: Page = new Page();
	private filter: LogTransaccionFilter = new LogTransaccionFilter();
	public configLocal = ConfigMantenedorLogs;
	private detalleLogModalRef: NgbModalRef;
	public logToShow: LogTransaccion = undefined;
	public emisoresFullList: Emisor[] = [];
	private currentAvailableEmisores: Emisor[] = [];
	public usuariosFullList: Usuario[] = [];
	//private fullListEmisoresPermission:boolean = this.tokenStorage.getUser().superAdmin;//this.permissions.getPermission('10-10').asignado;
	//private fullListUsuariosPermission:boolean = this.tokenStorage.getUser().superAdmin;//this.permissions.getPermission('10-10').asignado;
	public isSuperAdmin: boolean = this.tokenStorage.getUser().superAdmin;
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	constructor(private logTransaccionesService: LogsTransaccionesService, public utilities: UtilitiesService, private ref: ChangeDetectorRef, private datepipe: DatePipe, private modalService: NgbModal, public permissions: PermissionService, private emisorService: EmisorService, private usuariosService: UsuarioService, private localStorage: LocalStorageService, private tokenStorage: TokenStorageService) {
		this.filter.emisorId = this.tokenStorage.getCompany();
		if (this.isSuperAdmin) {
			this.getFullEmisoresList();
		}
	}

	ngOnInit(): void {
		this.setPage({ offset: 0 });
	}

	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		this.logTransaccionesService
			.getDataByPage(this.filter, localPage)
			.subscribe(
				(result) => {
					this.rowsMantenedor = result.data as LogTransaccion[];
					this.setPageFromResponse(this.page, result);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error en Registro de Transacciones",
						text: "Error al obtener la lista de transacciones. " + this.utilities.setErrorMessageFromArray(error),
					});
				}
			)
			.add(() => (this.componentStarted = true));
	}

	setPageSize() {
		this.setPage({ offset: 0 });
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<LogTransaccion>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	searchElementsByFilter(e) {
		this.filter.fechaDesde = e.fechaInicio;
		this.filter.fechaHasta = e.fechaFin;
		this.filter[e.criterio] = e.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(e.criterio, this.filter, this.configLocal);
		this.filter.emisorId = e.emisorId;
		this.filter.usuarioId = e.usuarioId;
		this.setPage({ offset: 0 });
	}

	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}

	getAllRowsByFilter(currentPageRef: Page, currentFilter: LogTransaccionFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.logTransaccionesService.getDataByPage(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as LogTransaccion[];
				this.ref.detectChanges();
				elements.forEach((row) => {});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al exportar",
					text: "No es posible exportar los datos. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<LogTransaccion>): void {
		let arregloExportar = [];
		arrayToExport.forEach((item) => {
			let elementToExport = {};
			elementToExport["Status Code"] = item.statusCode;
			elementToExport["Metodo"] = item.metodo;
			elementToExport["Error"] = item.titulo;
			elementToExport["Mensaje"] = item.mensaje; //this.truncarCampo(item.mensaje,250);
			elementToExport["StackTrace"] = item.stacktrace; //this.truncarCampo(item.stacktrace,250);
			elementToExport["Fecha"] = this.datepipe.transform(item.fecha, "dd/MM/yyyy  HH:mm:ss");
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Log Transacciones");
	}

	truncarCampo(str: string, limite: number): string {
		return str && str.length == 0 ? str.slice(0, limite) : str;
	}

	openDetailModal(modalRef, row: LogTransaccion) {
		this.logToShow = row;
		this.detalleLogModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	getFullEmisoresList() {
		this.loading = true;
		this.emisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				// let emisorService = new Emisor();
				// emisorService.razonSocial = "SERVICES";
				// emisorService.emisorId = null;
				// emisores.push(emisorService);
				this.emisoresFullList = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	getFullUsuariosList() {
		this.loading = true;
		this.usuariosService.getFullList().subscribe(
			(result) => {
				//this.loading = false;
				let usuarios = result.data;
				this.ref.detectChanges();
				usuarios.forEach((u) => {
					u.nombreCompleto = u.nombre + " " + u.apellidos;
				});
				this.usuariosFullList = usuarios;
			},
			(error) => {
				this.loading = false;
				Swal.fire({
					icon: "error",
					title: "Error en Usuarios",
					text: "No fue posible obtener la lista de usuarios completa, tiene disponible solamente los usuarios ya presentes en el selector de usuarios." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}
	// AddEmisoresListToFilter(){
	//   //used if the current logged user dont have permission to see the Users Full List
	//   this.currentAvailableEmisores = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
	//   this.filter.emisoresIdList = [];
	//   this.currentAvailableEmisores.forEach(em => this.filter.emisoresIdList.push(em.emisorId));
	// }
}
