import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { SortType } from "@swimlane/ngx-datatable";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { stringify } from "querystring";
import { Contrato } from "src/app/models/Contrato";
import { Receptor } from "src/app/models/Receptor/Receptor";
import { ReceptorFilter } from "src/app/models/Receptor/ReceptorFilter";
import { ReceptorRequest } from "src/app/models/Receptor/ReceptorRequest";
import { ClienteService } from "src/app/services/cliente.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import { ConfigClientes } from "./config-clientes";

@Component({
	selector: "app-mantenedor-clientes",
	templateUrl: "./mantenedor-clientes.component.html",
	styleUrls: ["./mantenedor-clientes.component.scss"],
})
export class MantenedorClientesComponent implements OnInit {
	SortType = SortType;
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	public loading: boolean = false;
	public rowsMantenedor: Receptor[] = [];
	public selectedClient: Receptor;
	public selectedClientId: string;
	public contractsFullList: Contrato[] = [];
	private emisorId: string = this.tokenStorage.getCompany();
	public configScroll: PerfectScrollbarConfigInterface = {};
	public page: Page = new Page();
	private filter = new ReceptorFilter();
	public createClientModal: NgbModalRef;
	public editClientModal: NgbModalRef;
	public createContract: NgbModalRef;
	public contractListModal: NgbModalRef;
	private contractListRef: any;
	public detailClientModal: NgbModalRef;
	public setContractToNewClient: boolean = false;
	public editClientMode: boolean = false;
	public configLocal = ConfigClientes;
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	constructor(private modalService: NgbModal, private changeRef: ChangeDetectorRef, private clientService: ClienteService, private tokenStorage: TokenStorageService, private router: Router, public utilities: UtilitiesService, public permissions: PermissionService) {}

	ngOnInit(): void {
		this.filter.emisorId = this.emisorId;
		this.filter.activo = true;
		this.setPage({ offset: 0 });
		//this.setFakeContract();
	}
	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo, $event?) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;

		this.clientService
			.getDataByPage(this.filter, localPage)
			.subscribe(
				(result) => {
					this.rowsMantenedor = result.data as Receptor[];
					this.setPageFromResponse(this.page, result);
					//this.getContractList();
				},
				(error) => {
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en Clientes";
					this.currentSwal.text = "No ha sido posible obtener la lista de clientes disponibles,favor recarge la página." + this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				}
			)
			.add(() => {
				this.loading = false;
				this.componentStarted = true;
				this.changeRef.detectChanges();
			});
	}

	openCreateClientModal(modalRef) {
		this.setContractToNewClient = false;
		this.createClientModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	closeCreateClientModal() {
		//close modal from ClientEntry component event
		this.createClientModal.close();
	}

	sendOnlyClientRequest(clientRequest) {
		//send request to service
		clientRequest.emisorId = this.emisorId;
		this.loading = true;
		this.clientService.sendClientRequest(clientRequest).subscribe(
			(result) => {
				this.currentSwal.title = "Cliente ingresado";
				this.currentSwal.text = "Se ha ingresado correctamente el nuevo cliente";
				this.currentSwal.icon = "success";
				this.currentSwal.fire();
				this.changeRef.detectChanges();

				this.setPage({ offset: 0 });
				this.changeRef.detectChanges();
				this.closeCreateClientModal();
			},
			(error) => {
				this.loading = false;
				this.changeRef.detectChanges();
				this.currentSwal.title = "Error en ingreso";
				this.currentSwal.text = "Ha ocurrido un error al intentar ingresar un nuevo cliente." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}

	clientThenContractRequest(clientRequest) {
		//send request and wait for create contract request
		this.clientService
			.sendClientRequest(clientRequest)
			.subscribe(
				(result) => {
					this.currentSwal.title = "Cliente ingresado";
					this.currentSwal.text = "Se ha ingresado correctamente el nuevo cliente";
					this.currentSwal.icon = "success";
					this.currentSwal.fire();
					this.setPage({ offset: 0 });
				},
				(error) => {
					//console.log(error.error.errors[0]);
					this.currentSwal.title = "Error en ingreso";
					this.currentSwal.text = "Ha ocurrigo un error al intentar ingresar un nuevo cliente" + ". " + error.error.errors[0];
					this.currentSwal.icon = "error";
					this.currentSwal.fire();
				}
			)
			.add(() => {
				this.loading = false;
				this.changeRef.detectChanges();
			});
	}

	deactivateClient(row: Receptor) {
		this.loading = true;
		this.clientService
			.deactivateClient(row.receptorId, this.emisorId)
			.subscribe(
				(result) => {
					if (result.data) {
						//row.activo = false;
						this.setPage({ offset: 0 });
						this.currentSwal.title = "Cliente desactivado";
						this.currentSwal.text = "Se ha desactivado correctamente el cliente seleccionado";
						this.currentSwal.icon = "success";
						this.currentSwal.fire();
					}
				},
				(error) => {
					this.currentSwal.title = "Error en desactivación";
					this.currentSwal.text = "Ha ocurrigo un error al intentar desactivar el cliente" + this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.icon = "error";
					this.currentSwal.fire();
				}
			)
			.add(() => {
				this.loading = false;
				this.changeRef.detectChanges();
			});
	}
	reactivateClient(row: Receptor) {
		if (row.activo) {
			this.currentSwal.title = "Error en desactivación";
			this.currentSwal.text = "Este cliente ya se encuentra activo";
			this.currentSwal.icon = "error";
			this.currentSwal.fire();
		} else {
			this.loading = true;
			let request: ReceptorRequest = new ReceptorRequest();
			request.activo = true;
			request.receptorId = row.receptorId;
			// request.rut= row.rut;
			request.rutString = row.rutString;
			request.razonSocial = row.razonSocial;
			request.giro = row.giro;
			request.dirPart = row.dirPart;
			request.dirFact = row.dirFact;
			request.correoPar = row.correoPar;
			request.correoFact = row.correoFact;
			request.ciudad = row.ciudad;
			request.comuna = row.comuna;
			request.emisorId = row.emisorId;
			request.dv = row.dv;
			this.clientService
				.editClientRequest(request)
				.subscribe(
					(result) => {
						//row.activo = true;
						this.loading = false;
						this.setPage({ offset: 0 });
						this.currentSwal.title = "Cliente Reactivado";
						this.currentSwal.text = "Se ha reactivado correctamente al cliente seleccionado";
						this.currentSwal.icon = "success";
						this.currentSwal.fire();
					},
					(error) => {
						this.loading = false;
						this.currentSwal.title = "Error en activación";
						this.currentSwal.text = "Ha ocurrigo un error al intentar reactivar el cliente" + this.utilities.setErrorMessageFromArray(error.error);
						this.currentSwal.icon = "error";
						this.currentSwal.fire();
					}
				)
				.add(() => {
					this.loading = false;
					this.changeRef.detectChanges();
				});
		}
	}
	openEditClientModal(modalRef, rowClient: Receptor) {
		this.selectedClient = rowClient;
		this.editClientMode = true;
		this.editClientModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	closeEditClientModal() {
		this.editClientModal.close();
		this.editClientMode = false;
		this.selectedClient = undefined;
	}

	editClientRequest(editedClientRequest: ReceptorRequest) {
		//receive client request
		this.loading = true;
		editedClientRequest.emisorId = this.emisorId;
		this.clientService
			.editClientRequest(editedClientRequest)
			.subscribe(
				(result) => {
					this.loading = false;
					this.currentSwal.title = "Cliente modificado";
					this.currentSwal.text = "Se han guardado correctamente los datos del cliente";
					this.currentSwal.icon = "success";
					this.currentSwal.fire();
					this.setPage({ offset: 0 });
				},
				(error) => {
					//console.log(error);
					this.currentSwal.title = "Error en edición";
					this.currentSwal.text = "Ha ocurrigo un error al intentar guardar los cambios del cliente" + this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.icon = "error";
					this.currentSwal.fire();
				}
			)
			.add(() => {
				//this.changeRef.detectChanges();
				//after response change editMode to false
				this.closeEditClientModal();
				this.loading = false;
				this.changeRef.detectChanges();
			});
	}

	openAddContract(modalRef, rowClient: Receptor) {
		this.selectedClient = rowClient;
		this.createContract = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	closeAddContractModal() {
		this.selectedClient = undefined;
		this.createContract.close();
	}

	openContractList(modalRef, rowClient: Receptor) {
		this.selectedClient = rowClient;
		this.contractListRef = modalRef;
		this.contractListModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	closeContractListModal() {
		this.selectedClient = undefined;
		this.contractListModal.close();
	}

	openDetailsClient(modalRef, row: Receptor) {
		this.selectedClient = row;
		this.detailClientModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	closeDetailsClient() {
		this.detailClientModal.close();
	}

	replaceClientInList(client: Receptor) {
		this.selectedClient = { ...client };
		let changeClient = this.rowsMantenedor.find((receptor) => receptor.receptorId == client.receptorId);
		let changeClientIndex = this.rowsMantenedor.indexOf(this.selectedClient);
		if (changeClientIndex !== -1) {
			this.rowsMantenedor[changeClientIndex] = { ...client };
		}
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<Receptor>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}
	searchElementsByFilter(event) {
		let filterLocal = new ReceptorFilter();
		this.filter = { ...filterLocal };
		if (event.criterio == "rutReceptorDv") {
			this.filter[event.criterio] = event.busqueda;
		} else {
			this.filter[event.criterio] = event.busqueda;
		}
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.activo = event.registroActivo ? false : true;
		this.filter.emisorId = this.emisorId;
		this.setPage({ offset: 0 });
	}

	relatedDtes(row: Receptor) {
		let rutString = row.rutString;
		let nombre = row.razonSocial;
		this.router.navigateByUrl("/documentos-emitidos", {
			state: {
				receptorRut: rutString,
				filterDescription: "Filtrado por cliente " + nombre,
			},
		});
	}
	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}

	getAllRowsByFilter(currentPageRef: Page, currentFilter: ReceptorFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.clientService.getDataByPage(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as Receptor[];
				this.loading = false;
				this.changeRef.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.changeRef.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al exportar";
				this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. ";
				if (error.error.message) {
					this.currentSwal.text += +this.utilities.setErrorMessageFromArray(error.error);
				}
			},
			() => {
				this.loading = false;
				this.changeRef.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<Receptor>): void {
		let arregloExportar = [];
		arrayToExport.forEach((row) => {
			let elementToExport = {};
			elementToExport["Rut"] = row.rutString;
			elementToExport["Razón Social"] = row.razonSocial;
			elementToExport["Giro"] = row.giro;
			//elementToExport["Dirección Particular"] = row.dirPart;
			elementToExport["Dirección Facturación"] = row.dirFact;
			elementToExport["Correo Facturación"] = row.correoFact;
			elementToExport["Correo Particular"] = row.correoPar;
			elementToExport["Ciudad"] = row.ciudad;
			elementToExport["Comuna"] = row.comuna;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "clientes_cd");
	}
	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}
}
