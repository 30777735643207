<tr [formGroup]="detalleFormGroup" #localDetalleForm="ngForm">
    <td>{{detalleNumero}}</td>
    <td WIDTH="12%">
        <div class="form-group">

            <div class="row">

                <div class="col-12" style="overflow: visible;">
                    <!-- <input type="text" class="form-control"  name="codigo" formControlName="codigo" (blur)="onSubmit()"> -->
                    <!-- <ng-select
                        formControlName="codigo"
                        [items]="searchCatalog"
                        [selectOnTab]="true"
                        [loading]="searchLoading"
                        bindLabel="codigoBarra"
                        placeholder="Ingrese código de barra"
                        (change)="refreshItemDetail($event)"
                        (search)="searchItem($event,'codigoBarra')"
                        addTagText="Agregar producto"
                        [addTag]="AddProductByCode"
                        notFoundText="Sin resultado"
                        ></ng-select> -->
                    <input type="text" class="form-control" formControlName="codigo" disabled>
                    <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.codigo.invalid"
                        class="text-danger">
                        <div *ngIf="detalleFormGroup.controls.codigo.errors?.required">Campo Requerido</div>
                    </div>
                </div>
            </div>
        </div>
    </td>

    <!-- product based item input -->
    <td WIDTH="40%">
        <div class="form-group">
            <!-- <input type="text" class="form-control" name="nombreItem" formControlName="nombreItem" (blur)="onSubmit()"> -->
            <!-- <ng-select
                    formControlName="nombreItem"
                    [items]="searchCatalog"
                    [selectOnTab]="true"
                    [loading]="searchLoading"
                    bindLabel="nombre"
                    placeholder="Ingrese nombre"
                    (change)="refreshItemDetail($event)"
                    (search)="searchItem($event,'nombre')"
                    addTagText="Agregar Producto"
                    [addTag]="AddProductByName"
                    notFoundText="Sin resultado"
                ></ng-select> -->
            <input type="text" class="form-control" formControlName="nombreItem" (blur)="onSubmit()">

            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.nombreItem.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.nombreItem.errors?.required">Campo Requerido</div>
            </div>
            <div class="row" *ngIf="detalleFormGroup.controls.haveDescripcion.value && showingDetails" [ngClass]="{
                    'mt-2':opciones.tipoMoneda=='CLP',
                    'mt-4':opciones.tipoMoneda!='CLP'
                }">
                <div class="col-9" style="overflow: visible;">
                    <label for="" class="label-control font-weight-bold">Descripción</label>
                    <textarea type="text" class="form-control" name="descripcion" formControlName="descripcion"
                        (blur)="onSubmit()" rows="2" cols="90" style="overflow: visible; width: 136%;"></textarea>
                    <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.descripcion.invalid"
                        class="text-danger">
                        <div *ngIf="detalleFormGroup.controls.descripcion.errors?.required">Campo Requerido</div>
                    </div>
                </div>
            </div>
        </div>
    </td>

    <td *ngIf="showingDetails">
        <div class="form-group">
            <div class="row pl-5 mb-4">
                <div class="ml-5">
                    <input type="checkbox" class="form-check-input" name="haveDescripcion"
                        formControlName="haveDescripcion" (change)="changeHaveDescripcion($event)" (blur)="onSubmit()"
                        [attr.disabled]="currentInvoiceType==4?true:null">
                </div>
            </div>
        </div>
    </td>

    <td WIDTH="9%">
        <div class="form-group">
            <input style="text-indent: 5px; padding-left: 0px;" type="number" class="form-control" min="1"
                name="cantidad" formControlName="cantidad" (blur)="onSubmit()">
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.cantidad.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.cantidad.errors?.required">Campo Requerido</div>
                <div *ngIf="detalleFormGroup.controls.cantidad.errors?.PositiveNumber">Número debe ser positivo</div>
            </div>
        </div>
    </td>

    <td WIDTH="12%">
        <div class="form-group">
            <input style="text-indent: 3px; padding-left: 0px;" type="number" class="form-control" min="1"
                name="precioUnitario" formControlName="precioUnitario" (blur)="onSubmit()">
            <div class="clp-equivalent text-right mt-3" *ngIf="opciones.tipoMoneda!='CLP'">

                <div class="float-left">
                    CLP
                </div>
                <div class="float right">
                    {{this.subPrice | number:'1.0-3':'es'}}
                </div>
            </div>
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.precioUnitario.invalid"
                class="text-danger">
                <div *ngIf="detalleFormGroup.controls.precioUnitario.errors?.required">Campo Requerido</div>
                <div *ngIf="detalleFormGroup.controls.precioUnitario.errors?.PositiveNumber">Número debe ser positivo
                </div>
            </div>

            <div class="row " *ngIf="detalleFormGroup.controls.haveDescripcion.value && showingDetails" [ngClass]="{
                    'mt-1':opciones.tipoMoneda=='CLP',
                    'mt-0':opciones.tipoMoneda!='CLP'
                }">
                <div class="col-12 pt-2" style="overflow: visible;">
                    <div class="form-group">
                        <label for="" class="label-control font-weight-bold mb-1">Unidad Medida</label>
                        <input type="text" class="form-control padding-unidad-medida" name="unidadMedida"
                            formControlName="unidadMedida" (blur)="onSubmit()" style="overflow: visible; width: 100%;">
                        <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.unidadMedida.invalid"
                            class="text-danger">
                            <div *ngIf="detalleFormGroup.controls.unidadMedida.errors?.required">Campo Requerido</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </td>

    <td>
        <div class="form-group">
            <input style="text-indent: 5px; padding-left: 0px;" type="number" class="form-control"
                name="descuentoRecargo" formControlName="descuentoRecargo" (blur)="onSubmit()"
                ngbTooltip="Valores Negativos indican descuentos y positivos indican recargos">
            <div class="clp-equivalent text-right mt-3" *ngIf="opciones.tipoMoneda!='CLP'">
                <div class="float-left">
                    CLP
                </div>
                <div class="float-right">
                    {{this.subDiscount | number:'1.0-2':'es'}}
                </div>
            </div>
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.descuentoRecargo.invalid"
                class="text-danger">
                <div *ngIf="detalleFormGroup.controls.descuentoRecargo.errors?.required">Campo Requerido</div>
            </div>
        </div>
    </td>

    <td class="ml-5 text-center">
        <div class="form-group ml-5">
            <input type="checkbox" class="form-check-input" name="exento" formControlName="exento"
                (change)="onExentoChange($event)">
        </div>
    </td>
    <td WIDTH="2%" *ngIf="currentInvoiceType==3">
        <div class="form-group ml-2" style="max-width:50px;">
            <div class="row mb-5" style="width:30%">
                <div class="col-12">
                    <input type="checkbox" class="ml-0 form-check-input" name="haveRetencion"
                        formControlName="haveRetencion" (change)="changeRetencionInput($event)">
                </div>
            </div>

            <div class="row pt-5" *ngIf="showRetencionPercent">
                <div class="col-12 pl-0">
                    <input type="number" min="0" [max]="porcentajeIva*100" class="form-control"
                        name="retencionPorcentaje" formControlName="retencionPorcentaje" (blur)="onSubmit()">
                    <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.retencionPorcentaje.invalid"
                        class="text-danger">
                        <div *ngIf="detalleFormGroup.controls.retencionPorcentaje.errors?.numberIsHigher"
                            class="unbreakable-words">Excede máximo {{porcentajeIva*100}}%</div>
                    </div>
                </div>
            </div>
        </div>
    </td>
    <td WIDTH="12%">
        <div class="float-right" style="width:100%">
            <div class="alter-equivalent text-right mb-5 pt-2" *ngIf="opciones.tipoMoneda != 'CLP'">
                <div class="mb-5 pt-2">
                    <div class="float-left"
                        *ngIf="currentProduct && currentProduct.impuestos && currentProduct.impuestos.length>0">
                        <fa-icon icon="exclamation-triangle" class="fa-lg text-warning" [ngbTooltip]="list"></fa-icon>
                    </div>
                    <div class="float-right">
                        {{totalForeign | number:'1.0-2':'es'}}

                    </div>
                </div>
                <div class="text-right mt-5 pt-5 pt-1">
                    <div class="float-left mt-1">
                        CLP
                    </div>
                    <div class="float-right mt-1">
                        {{detalleDte.total | number:'1.0-2':'es'}}
                    </div>
                </div>
            </div>
            <!-- <input type="number" class="form-control" name="subtotal" formControlName="subtotal" (blur)="onSubmit()"> -->
            <div class="text-right" *ngIf="opciones.tipoMoneda == 'CLP'">
                <div class="float-left"
                    *ngIf="currentProduct && currentProduct.impuestos && currentProduct.impuestos.length>0">
                    <fa-icon icon="exclamation-triangle" class="fa-lg text-warning" [ngbTooltip]="list"></fa-icon>
                </div>
                <div class="float-right">
                    {{detalleDte.total | number:'1.0-2':'es'}}
                </div>
            </div>
        </div>
    </td>
    <td>
        <button class="btn btn-danger" (click)="deleteItem()">
            <i class="flaticon-delete"></i>
        </button>
    </td>

</tr>
<ng-template #list>
    <div class="d-flex flex-column">
        <b class="text-danger" *ngIf="opciones.tipoMoneda=='USD' && currentInvoiceType==4">No considerado</b>
        <b>Impuestos</b>
        <span *ngFor="let tax of currentProduct.impuestos">
            - {{tax.nombre}}, {{tax.valor}} %
        </span>
    </div>
</ng-template>