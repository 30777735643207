<form [formGroup]="assignPerfil" (ngSubmit)="onSubmit()" #assignPerfilFormRef="ngForm">
	<div class="modal-body">
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label class="col-2 label-control">Empresas</label>
				<div class="col-8">
					<ng-select
						[items]="listOfEmisores"
						placeholder="Seleccione Empresa"
						formControlName="empresaAsignar"
						[clearable]="false"
						bindLabel="razonSocial"
						[ngClass]="{
							'is-invalid': assignPerfilFormRef.submitted && assignPerfil.get('empresaAsignar').invalid
						}"></ng-select>
				</div>
				<div class="col-1">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Asignar</span>
					</button>
				</div>
			</div>
		</div>

		<div class="row">
			<ng-container>
				<br />
				<h4>Listado de empresas que consideran este perfíl</h4>
				<table class="table">
					<thead>
						<tr>
							<th>Emisor</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let em of emisoresDePerfil">
							<tr>
								<td>{{ em.razonSocial }}</td>
								<td>
									<a ngbTooltip="Desasociar" class="primary edit mr-1 mrless" (click)="deleteEmisor(em)">
										<div class="icon-circle bg-danger text-center align-middle pt-info-button">
											<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
										</div>
									</a>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
