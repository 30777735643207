<div class="card card-custom" *ngIf="showingInSettlement">
    <div class="card-header">
        <div class="card-title">
            <p>Detalles Comisiones y Otros Cargos</p>
        </div>
    </div>
    <div class="card-content">
        <div class="card-body">
            <!-- <ngb-alert *ngIf="maxItemError">
                    {{sizeMessage}}
            </ngb-alert> -->
            <alert id="alert-size" style="z-index: 1050;"></alert>
            <div class="row">
                <!-- loop de lista de items de detalles -->
                <table class="table">
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th>Tipo</th>
                            <th>Glosa</th>
                            <th>Tasa %</th>
                            <th>Neto</th>
                            <th>Exento</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let comision of listaComisiones;let index=index" app-item-detalle-comision
                        [detalleDte]="comision" [detalleNumero]="index+1" (deleteDetalle)="deleteItemComision(index)">

                    </tbody>
                </table>
                <!-- fin loop de lista de items de detalles -->
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-warning" (click)="agregarDetalleComision()">
                    <i class="flaticon-add-circular-button"></i>Agregar</button>
            </div>
        </div>
    </div>
</div>