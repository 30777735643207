import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { PersonaAutorizada } from "src/app/models/PersonaAutorizada";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import Swal from "sweetalert2";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { PermissionService } from "src/app/services/permission.service";
import { PersonaAutorizadaScrapper } from "src/app/models/PersonaAutorizadaScrapper";
import { PersonasAutorizadasService } from "src/app/services/paseronas-autorizadas.service";
@Component({
	selector: "app-persona-autorizada-entry",
	templateUrl: "./persona-autorizada-entry.component.html",
	styleUrls: ["./persona-autorizada-entry.component.scss"],
})
export class PersonaAutorizadaEntryComponent implements OnInit {
	public loading = false;
	public addAuthorizedPersonForm: FormGroup;
	public updateAuthorizedPersonForm: FormGroup;
	public authorizedPersonsCesionList: PersonaAutorizada[] = [];
	public authorizedPersonsBHEList: PersonaAutorizada[] = [];
	public addAuthorizedPersonModal: NgbModalRef;
	public newType: number;
	private updateAuthorizedPersonModal: NgbModalRef;
	private authorizedPersonToEdit = new PersonaAutorizada();
	private authorizedPersonScrapperToEdit = new PersonaAutorizadaScrapper();
	@Input() public emisor: Emisor = undefined;

	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	constructor(private formBuilder: FormBuilder, private personasAutorizadasService: PersonasAutorizadasService, private ref: ChangeDetectorRef, private modal: NgbModal, public permissions: PermissionService, private emisorService: EmisorService, private localStorage: LocalStorageService) {}

	ngOnInit(): void {
		this.setForm();
		this.authorizedPersonsCesionList = this.emisor.personasAutorizadas.filter((x) => x.tipoPersona == 1);
		this.authorizedPersonsBHEList = this.emisor.personasAutorizadas.filter((x) => x.tipoPersona == 2);
	}
	// onSubmit(){
	//   if(this.addAuthorizedPersonForm.valid){

	//   }

	// }

	setForm() {
		this.addAuthorizedPersonForm = this.formBuilder.group({
			rutString: ["", [Validators.required, VerificacionRutValidator]],
			nombre: ["", Validators.required],
			correo: ["", [Validators.required, SingleEmailValidator]],
			password: ["", [Validators.required]],
		});
		this.updateAuthorizedPersonForm = this.formBuilder.group({
			rutString: ["", [Validators.required, VerificacionRutValidator]],
			nombre: ["", Validators.required],
			correo: ["", [Validators.required, SingleEmailValidator]],
			password: ["", [Validators.required]],
		});
		this.updateFormControls();
	}

	updateFormControls() {
		this.addAuthorizedPersonForm.controls.password.disable();
		this.updateAuthorizedPersonForm.controls.password.disable();
		this.addAuthorizedPersonForm.controls.correo.enable();
		this.addAuthorizedPersonForm.controls.correo.setValidators([Validators.required, SingleEmailValidator]);
		this.updateAuthorizedPersonForm.controls.correo.enable();
		this.updateAuthorizedPersonForm.controls.correo.setValidators([Validators.required, SingleEmailValidator]);
	}

	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}
	openAddAuthorizedPersonModal(modalRef, tipo) {
		this.newType = tipo;
		this.setForm();
		this.addAuthorizedPersonModal = this.modal.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	closeAddAuthorizedPersonModal() {
		this.loading = false;
		this.ref.detectChanges();
		this.addAuthorizedPersonModal.close();
	}
	add() {
		this.loading = false;
		if (this.addAuthorizedPersonForm.valid) {
			var authorizedPerson = new PersonaAutorizada();
			var rut = this.addAuthorizedPersonForm.get("rutString").value;
			authorizedPerson.rut = parseInt(rut.substring(0, rut.length - 2));
			authorizedPerson.dv = rut.substring(rut.length - 1);
			authorizedPerson.activo = true;
			authorizedPerson.correo = this.addAuthorizedPersonForm.get("correo").value;
			authorizedPerson.nombre = this.addAuthorizedPersonForm.get("nombre").value;
			authorizedPerson.emisorId = this.emisor.emisorId;
			authorizedPerson.tipoPersona = this.newType;
			this.personasAutorizadasService
				.toAssignAuthorizedPersons(authorizedPerson)
				.subscribe(
					(res) => {
						Swal.fire({
							icon: "success",
							title: "Persona Autorizada Agregada",
							text: "Se ha agregado exitosamente a la persona autorizada " + authorizedPerson.nombre,
						});
						this.updateAuthorizedPersonsByEmisor();
						this.loading = false;
						this.ref.detectChanges();
						this.closeAddAuthorizedPersonModal();
					},
					(err) => {
						this.loading = false;
						this.ref.detectChanges();
						if (err.error.data) {
							Swal.fire({
								icon: "info",
								title: "Persona Autorizada Existente",
								text: "Ya existe una persona autorizada con el rut " + authorizedPerson.rut + "-" + authorizedPerson.dv,
							});
						} else {
							Swal.fire({
								icon: "error",
								title: "Error al Agregar Persona Autorizada",
								text: "No se ha podido agregar a la persona autorizada " + authorizedPerson.nombre + ", intente nuevamente",
							});
						}
					}
				)
				.add(() => {
					this.loading = false;
				});
		}
	}
	setAutorizedPersonForm(authorizedPerson) {
		this.updateFormControls();
		this.updateAuthorizedPersonForm.patchValue(authorizedPerson);
		this.updateAuthorizedPersonForm.get("rutString").patchValue(authorizedPerson.rutFormateado);
		this.updateAuthorizedPersonForm.get("nombre").patchValue(authorizedPerson.nombre);
		this.updateAuthorizedPersonForm.get("correo").patchValue(authorizedPerson.correo);
	}
	openUpdateAuthorizedPersonModal(modalRef, row: PersonaAutorizada | PersonaAutorizadaScrapper, tipo: number) {
		this.newType = tipo;
		this.loading = false;
		this.authorizedPersonToEdit = new PersonaAutorizada();
		this.authorizedPersonToEdit = row as PersonaAutorizada;

		this.setAutorizedPersonForm(row);
		this.updateAuthorizedPersonModal = this.modal.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	closeUpdateAuthorizedPersonModal() {
		this.loading = false;
		this.ref.detectChanges();
		this.updateAuthorizedPersonModal.close();
	}
	update() {
		this.loading = false;
		if (this.updateAuthorizedPersonForm.valid) {
			var rut = this.updateAuthorizedPersonForm.get("rutString").value;
			this.authorizedPersonToEdit.rut = parseInt(rut.substring(0, rut.length - 2));
			this.authorizedPersonToEdit.dv = rut.substring(rut.length - 1);
			this.authorizedPersonToEdit.activo = true;
			this.authorizedPersonToEdit.correo = this.updateAuthorizedPersonForm.get("correo").value;
			this.authorizedPersonToEdit.nombre = this.updateAuthorizedPersonForm.get("nombre").value;
			this.authorizedPersonToEdit.emisorId = this.emisor.emisorId;
			this.personasAutorizadasService
				.updateAuthorizedPersons(this.authorizedPersonToEdit)
				.subscribe(
					(res) => {
						Swal.fire({
							icon: "success",
							title: "Persona Autorizada Actualizada",
							text: "Se ha actualizado exitosamente a la persona autorizada " + this.authorizedPersonToEdit.nombre,
						});
						this.updateAuthorizedPersonsByEmisor();
						this.loading = false;
						this.ref.detectChanges();
						this.closeUpdateAuthorizedPersonModal();
					},
					(err) => {
						this.loading = false;
						this.ref.detectChanges();
						if (err.error.data) {
							Swal.fire({
								icon: "info",
								title: "Persona Autorizada Existente",
								text: "Ya existe una persona autorizada con el rut " + this.authorizedPersonToEdit.rutFormateado,
							});
						} else {
							Swal.fire({
								icon: "error",
								title: "Error al Actualizar Persona Autorizada",
								text: "No se ha podido actualizar a la persona autorizada " + this.authorizedPersonToEdit.nombre + ", intente nuevamente",
							});
						}
					}
				)
				.add(() => {
					this.loading = false;
				});
		}
	}
	deleteAuthorizedPerson(authorizedPerson) {
		this.loading = true;
		this.personasAutorizadasService
			.deleteAuthorizedPersons(authorizedPerson)
			.subscribe(
				(res) => {
					Swal.fire({
						icon: "success",
						title: "Persona Autorizada Eliminada",
						text: "Se ha eliminado exitosamente a la persona autorizada " + authorizedPerson.nombre,
					});
					this.updateAuthorizedPersonsByEmisor();
					this.loading = false;
					this.ref.detectChanges();
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "info",
						title: "Error al Eliminar",
						text: "No fue posible eliminar a la persona autorizada " + authorizedPerson.nombre,
					});
				}
			)
			.add(() => {
				this.loading = false;
			});
	}
	updateAuthorizedPersonsByEmisor() {
		this.loading = false;
		this.personasAutorizadasService.getAuthorizedPersonsByEmisor(this.emisor.emisorId).subscribe(
			(res) => {
				this.emisor.personasAutorizadas = res.data;
				this.authorizedPersonsCesionList = this.emisor.personasAutorizadas.filter((x) => x.tipoPersona == 1);
				this.authorizedPersonsBHEList = this.emisor.personasAutorizadas.filter((x) => x.tipoPersona == 2);
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Personas Autorizadas No Encontradas",
					text: "Error al buscar personas autorizadas del emisor " + this.emisor.razonSocial,
				});
			}
		);
	}
}
