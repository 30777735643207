<div class="card card-custom" *ngIf="currentInvoiceType != 5">
    <div class="card-content">
        <div class="card-body" [formGroup]="optionsReferenceFormGroup">
            <div class="row">
                <div class="form-group col-2 pr-0">
                    <div class="row">
                        <div class="col-1 ml-5 text-center pr-1">
                            <input type="checkbox" class="form-check-input" formControlName="haveDteReference"
                                (change)="changeReference()">
                        </div>
                        <div class="col-8 label-control float-left pl-0">Referencias</div>
                    </div>
                </div>
                <div class="form-group col-auto pl-0 pr-0" *ngIf="showingCurrencyOptions">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="radioCLP" name="tipoMoneda" class="custom-control-input" value="CLP"
                            formControlName="tipoMoneda" (change)="onSubmit(false)" [attr.disabled]="disableCLPOption">
                        <label class="custom-control-label" for="radioCLP">CLP</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="radioUSD" name="tipoMoneda" class="custom-control-input" value="USD"
                            formControlName="tipoMoneda" (change)="onSubmit(false)">
                        <label class="custom-control-label" for="radioUSD">USD</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="radioUF" name="tipoMoneda" class="custom-control-input" value="UF"
                            formControlName="tipoMoneda" (change)="onSubmit(false)">
                        <label class="custom-control-label" for="radioUF">UF</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="radioOther" name="tipoMoneda" class="custom-control-input" value="OTHER"
                            formControlName="tipoMoneda" (change)="onSubmit(false)">
                        <label class="custom-control-label" for="radioOther">Otros</label>
                    </div>
                </div>
                <div class="col-3" *ngIf="divisasList.length>0 && showingCurrencyOptions">
                    <div class="row mb-0">
                        <div class="col-5">
                            <b>{{divisasList[0].nombre }}</b>
                            <p class="mb-0">$ {{divisasList[0].valorClp | number:'1.0-2':'es'}}</p>
                        </div>
                        <div class="col-6">
                            <b>{{divisasList[1].nombre }}</b>
                            <p class="mb-0">$ {{divisasList[1].valorClp | number:'1.0-2':'es'}}</p>
                        </div>
                    </div>

                </div>
                <div class="form-inline col pr-0" style="align-items: start;">
                    <div class="ml-2">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                formControlName="detallesExentos" (change)="changeDetallesConIVA($event)">
                            <label class="custom-control-label label-control" for="customSwitch1">Precio detalles IVA
                                incl.</label>
                        </div>
                    </div>
                    <div class="ml-2 col" *ngIf="showGlobalRefCheckbox">
                        <div class="row">
                            <div class="col-1 ml-5 text-center pr-1">
                                <input type="checkbox" class="form-check-input" formControlName="refGlobal"
                                    (change)="changeGlobalReference()">
                            </div>
                            <div class="col-9 label-control float-left">Referencia Global</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- formulario referencia -->
                <div *ngIf="optionsReferenceFormGroup.controls.haveDteReference.value" class="col-12">
                    <div class="col-12">
                        <app-references-list (referencesListEmitter)="getReferencesList($event)"></app-references-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>