<div class="card card-custom">
    <div class="card-header">
        <div class="card-title" *ngIf="!showInInvoiceTicket">
            <p>Detalles</p>
        </div>
        <div class="card-title" *ngIf="showInInvoiceTicket">
            <p>Detalles: El tipo de documento cambia a exento solo si todos los detalles son exentos</p>
        </div>
    </div>
    <div class="card-content">
        <div class="card-body">
            <!-- <ngb-alert *ngIf="maxItemError">
                    {{sizeMessage}}
            </ngb-alert> -->
            <alert id="alert-size" style="z-index: 1050;"></alert>
            <div class="row">
                <app-cuadro-producto-detalle></app-cuadro-producto-detalle>
                <br>
            </div>
            <div class="row">
                <!-- loop de lista de items de detalles -->
                <table class="table">
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th *ngIf="showingInInvoice">Detalle</th>

                            <th>Cantidad</th>
                            <th>Precio Unitario</th>
                            <th ngbTooltip="Descuento/Recargo" container="body">Descto./Rec.</th>
                            <th>Exento</th>
                            <th *ngIf="currentInvoiceType==3" ngbTooltip="Indicar porcentaje de retención">Retiene</th>
                            <th class="text-right">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let detalle of listaDetalles;let index=index" app-item-detalle-dte
                        [detalleDte]="detalle" [detalleNumero]="index+1" (deleteDetalle)="deleteItem(index)">

                    </tbody>
                </table>
                <!-- fin loop de lista de items de detalles -->
            </div>
            <!-- <div class="d-flex justify-content-center">
                <button class="btn btn-warning" (click)="agregarDetalle()">
                    <i class="flaticon-add-circular-button"></i>Agregar</button>
            </div> -->
        </div>
    </div>
</div>