import { Impuesto } from "../Impuesto";

export class Producto {
   productoId: string;
   codigoBarra: string;
   nombre: string;
   precio: number;
   exento: boolean;
   emisorId: string;
   sucursalId: string;
   impuestos: Impuesto[];
   activo: boolean;
   unidadMedida: string;
}
