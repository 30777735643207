export class ReceptorRequest {
	receptorId: string;
	rut: number;
	rutString: string;
	razonSocial: string;
	giro: string;
	dirPart: string;
	dirFact: string;
	correoFact: string;
	correoPar: string;
	ciudad: string;
	comuna: string;
	emisorId?: string;
	dv: string;
	activo: boolean;
}
