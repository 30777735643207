import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Router, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel } from "@angular/router";
import { LayoutService } from "../../../../_metronic/core";
import KTLayoutHeader from "../../../../../assets/js/layout/base/header";
import KTLayoutHeaderMenu from "../../../../../assets/js/layout/base/header-menu";
import { KTUtil } from "../../../../../assets/js/components/util";
import { Subscription, Observable, BehaviorSubject, timer } from "rxjs";
import { BnNgIdleService } from "bn-ng-idle";
import Swal from "sweetalert2";
import { AuthService } from "src/app/modules/auth";
import { environment } from "src/environments/environment";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenStorageService } from "src/app/services/token-storage.service";
const idleTime = environment.idleTimeout;
const logoutTime = environment.logoutTimeout;
@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
	headerContainerCSSClasses: string;
	headerMenuSelfDisplay: boolean;
	headerMenuSelfStatic: boolean;
	asideSelfDisplay: boolean;
	headerLogo: string;
	headerSelfTheme: string;
	headerMenuCSSClasses: string;
	headerMenuHTMLAttributes: any = {};
	routerLoaderTimout: any;
	showingTimeoutDialog: boolean = false;
	idleSubscription: Subscription;
	resetIdleSubscription: Subscription;
	logoutTimerObs$: Subscription;
	//IDLE IMPLEMENTATION VARIABLES
	idleState = "NOT_STARTED";
	countdown?: number = null;
	lastPing: Date = null;
	@ViewChild("timeoutSwal") private timeoutSwal: SwalComponent;
	loadingElementTemplate = undefined;
	@ViewChild("ktHeaderMenu", { static: true }) ktHeaderMenu: ElementRef;
	loader$: Observable<number>;

	private loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	constructor(private layout: LayoutService, private router: Router, private bnIdle: BnNgIdleService, private authService: AuthService, private idle: Idle, private ref: ChangeDetectorRef, private modalService: NgbModal, private tokenService: TokenStorageService) {
		this.loader$ = this.loaderSubject;
		// page progress bar percentage
		const routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loaderSubject.next(10);
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loaderSubject.next(65);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loaderSubject.next(90);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loaderSubject.next(100);
				if (this.routerLoaderTimout) {
					clearTimeout(this.routerLoaderTimout);
				}
				this.routerLoaderTimout = setTimeout(() => {
					this.loaderSubject.next(0);
				}, 300);
			}
		});
		this.unsubscribe.push(routerSubscription);

		//IDLE IMPLEMENTANTION
		idle.setIdle(idleTime);
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		this.idle.watch();
		const startIdleSub = idle.onIdleStart.subscribe(() => {
			this.idleState = "IDLE";
			this.loadingElementTemplate = document.getElementsByClassName("backdrop");
			if (this.loadingElementTemplate.length > 0) {
				this.idle.interrupt();
			}
			if (!this.showingTimeoutDialog && this.loadingElementTemplate && this.loadingElementTemplate.length <= 0) {
				this.showingTimeoutDialog = true;
				this.ref.detectChanges();

				this.startLogoutTimer();

				this.ref.detectChanges();
				this.timeoutSwal.update();
				this.timeoutSwal.fire().then((res) => {
					if (res.isDismissed && res.dismiss == Swal.DismissReason.cancel) {
						this.showingTimeoutDialog = false;
						this.authService.logout();
						this.timeoutSwal.close();
					}
					if (res.isConfirmed || (res.isDismissed && res.dismiss != Swal.DismissReason.cancel)) {
						this.reset();
						this.endLogoutTimer();
						idle.watch();
					}
				});
			}
		});

		const endIdleSub = idle.onIdleEnd.subscribe(() => {
			this.idleState = "NOT_IDLE";
			if (!this.showingTimeoutDialog) {
				this.countdown = null;
				this.loadingElementTemplate = undefined;
				this.reset();
				ref.detectChanges();
			}
		});

		const timeoutWarningSub = idle.onTimeoutWarning.subscribe((seconds) => {
			//this.countdown = seconds;
		});

		this.unsubscribe.push(startIdleSub);
		this.unsubscribe.push(endIdleSub);
		//this.unsubscribe.push(timeoutSub);
		this.unsubscribe.push(timeoutWarningSub);
	}

	ngOnInit(): void {
		this.headerContainerCSSClasses = this.layout.getStringCSSClasses("header_container");
		this.headerMenuSelfDisplay = this.layout.getProp("header.menu.self.display");
		this.headerMenuSelfStatic = this.layout.getProp("header.menu.self.static");
		this.asideSelfDisplay = this.layout.getProp("aside.self.display");
		this.headerSelfTheme = this.layout.getProp("header.self.theme") || "";
		this.headerLogo = this.getLogoURL();
		this.headerMenuCSSClasses = this.layout.getStringCSSClasses("header_menu");
		this.headerMenuHTMLAttributes = this.layout.getHTMLAttributes("header_menu");
		this.reset();
	}

	private getLogoURL(): string {
			return "assets/deployAssets/logo/logo_pequenno.png";
		
	}

	ngAfterViewInit(): void {
		this.timeoutSwal.icon = "warning";
		this.timeoutSwal.title = "Cierre de sesión";
		this.timeoutSwal.text = "Su sesión expirará debido a inactividad en " + this.idleState;
		this.timeoutSwal.confirmButtonText = "Mantener sesión";
		this.timeoutSwal.cancelButtonText = "Cerrar sesión";
		this.timeoutSwal.showCancelButton = true;
		this.timeoutSwal.confirmButtonColor = "#3699FF";
		this.timeoutSwal.cancelButtonColor = "#f4516c";

		if (this.ktHeaderMenu) {
			for (const key in this.headerMenuHTMLAttributes) {
				if (this.headerMenuHTMLAttributes.hasOwnProperty(key)) {
					this.ktHeaderMenu.nativeElement.attributes[key] = this.headerMenuHTMLAttributes[key];
				}
			}
		}

		KTUtil.ready(() => {
			// Init Desktop & Mobile Headers
			KTLayoutHeader.init("kt_header", "kt_header_mobile");
			// Init Header Menu
			KTLayoutHeaderMenu.init("kt_header_menu", "kt_header_menu_wrapper");
		});
	}

	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
		if (this.routerLoaderTimout) {
			clearTimeout(this.routerLoaderTimout);
		}
	}

	reset() {
		this.idleState = "NOT_IDLE";
		this.countdown = null;
		this.lastPing = null;
		this.showingTimeoutDialog = false;
	}

	startLogoutTimer() {
		this.countdown = logoutTime;
		if (this.logoutTimerObs$ != undefined) {
			this.logoutTimerObs$.unsubscribe();
		}
		this.logoutTimerObs$ = timer(0, 1000).subscribe((t) => {
			if (this.countdown != null) this.idleState = "Su sesión expirará debido a inactividad en " + this.countdown;
			if (
				this.timeoutSwal
				//Swal.getHtmlContainer()
			) {
				//Swal.getHtmlContainer().textContent = this.idleState;
				this.timeoutSwal.text = this.idleState;
				this.timeoutSwal.update();
				this.ref.detectChanges();
				if (this.countdown == 0) {
					this.closeSessionOnDemand();
				}
				--this.countdown;
			}
		});
		this.unsubscribe.push(this.logoutTimerObs$);
	}
	endLogoutTimer() {
		if (this.logoutTimerObs$) {
			this.logoutTimerObs$.unsubscribe();
		}
	}

	closeSessionOnDemand() {
		this.idleState = "Sesión Cerrada";
		//Swal.getHtmlContainer().textContent = this.idleState;
		//Swal.close();
		this.timeoutSwal.text = this.idleState;
		this.timeoutSwal.update();
		this.timeoutSwal.close();
		this.modalService.dismissAll(ModalDismissReasons.BACKDROP_CLICK);
		this.endLogoutTimer();
		this.authService.logout();
	}
	goToMain() {
		this.router.navigate(["/"]);
	}
}
