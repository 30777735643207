import { DescuentoRecargo } from "./DescuentoRecargo";
import { DetalleDte } from "./DetalleDte";
import { OptionsReferenceDteEmitido } from "./OptionsReferenceDteEmitido";
import { OtraMoneda } from "./OtraMoneda";

export class TotalDteEmitido {
	//class used in individual request for invoice
	tipoDteId: string;
	codigoTipoDte: number;
	formaPago: number;
	formaPagoExportacion: number;
	fechaEmision: Date;
	descuentoGlobal: number;
	netoDte: number;
	exentoDte: number;
	ivaDte: number;
	TieneIvaTerceros: boolean;
	ivaTerceros: number;
	ivaPropio: number;
	totalDte: number;
	formato: any;
	diasVencimiento: number;
	otraMonedaEnt: OtraMoneda;
	descuentoRecargoEnt: DescuentoRecargo;
	montoImpuestos: number;
	retencionIva: number;
	tipoTraslado: number;
	tipoDespacho: number;
	totalComisionCargos: number;
	ivaComisionCargos: number;
	netoComisionCargos: number;
	exentoComisionCargos: number;
	tieneRetenciones() {
		let totalSinImpuesto = Number(this.totalDte) - Number(this.retencionIva);
		//this.ivaDte = 0;
		this.totalDte = totalSinImpuesto;
		return this.totalDte;
	}
	calcularRetenciones(lista: DetalleDte[], esNeto: boolean, mainTax: number) {
		let retencionTotal = 0;
		if (esNeto) {
			lista.forEach((detalle) => {
				if (detalle.retenciones && detalle.retenciones.length > 0) {
					detalle.retenciones.forEach((ret) => {
						retencionTotal += !detalle.esExento ? Math.round(detalle.cantidad * detalle.precioUnitario * (detalle.porcentajeRetencion / 100)) : 0;
					});
				} else {
					retencionTotal += !detalle.esExento ? Math.round(detalle.cantidad * detalle.precioUnitario * (detalle.porcentajeRetencion / 100)) : 0;
				}
			});
		} else {
			lista.forEach((detalle) => {
				let sumTaxRates = 0;
				if (detalle.impuestosAdicionales) {
					detalle.impuestosAdicionales.forEach((imp) => {
						sumTaxRates += imp.tasa;
					});
				}
				let netoOfDetalle = 0;
				netoOfDetalle = Math.round(detalle.cantidad * detalle.precioUnitario) / (1 + sumTaxRates / 100 + mainTax);
				if (detalle.retenciones && detalle.retenciones.length > 0) {
					detalle.retenciones.forEach((ret) => {
						retencionTotal += !detalle.esExento ? Math.round(netoOfDetalle * (detalle.porcentajeRetencion / 100)) : 0;
					});
				} else {
					retencionTotal += !detalle.esExento ? Math.round(netoOfDetalle * (detalle.porcentajeRetencion / 100)) : 0;
				}
			});
		}
		return retencionTotal;
	}
}
