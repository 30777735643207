import { ActividadEconomica } from "../ActividadEconomica";
import { Ambiente } from "../Ambiente";
import { ConfiguracionEmision } from "../ConfiguracionEmision";
import { PersonaAutorizada } from "../PersonaAutorizada";
import { PlantillaImpresion } from "../PlantillaImpresion/PlantillaImpresion";
import { Sucursal } from "../Sucursal";

export class Emisor {
	emisorId: string;
	rut: number;
	razonSocial: string;
	giro: string;
	dirPart: string;
	dirFact: string;
	correoPar: string;
	correoFact: string;
	ciudad: string;
	comuna: string;
	nroResol: number;
	fechaResol: Date;
	ambiente: number;
	activo: boolean;
	dv: string;
	rutString: string;
	sucursales: Sucursal[];
	actividadesEconomicas: ActividadEconomica[];
	configuracionDte: ConfiguracionEmision[];
	personasAutorizadas: PersonaAutorizada[];
	nombreAmbiente: string;
	ambienteEnt: Ambiente;
	representanteLegalRut: number;
	representanteLegalDv: string;
	representanteLegalRutString: string;
	telefono: number;
	plantillasPdfOfEmisor: PlantillaImpresion[];
	passwordSii: string;
	tienePassSii: boolean;
	unidadSII: string;
	actual: boolean;
	logo: string;
}
