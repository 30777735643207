import { Emisor } from "../Emisor/Emisor";

export class PlantillaEmisor {
	plantillaEmisorId: string;
	emisorId: string;
	tipoPlantillaId: string;
	activo: boolean;
	emisorAsociado: Emisor;
	nombrePlantilla: string;
}
