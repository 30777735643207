import { PlantillaCorreoEnvio } from "./PlantillaCorreoEnvio";
import { TipoCorreoEnvio } from "./TipoCorreoEnvio";
import { TipoPlantillaCorreoEnvio } from "./TipoPlantillaCorreoEnvio";

export class CorreoEnvio {
   envioCorreoId: string;
   nombre: string;
   apiKey: string;
   servidor: string;
   puerto: number;
   ssl: boolean;
   correo: string;
   senderName: string;
   tipoEnvioCorreoId: string;
   tipoEnvioCorreo: TipoCorreoEnvio;
   nombreTipoEnvio: string;
   tipoPlantillaId: number;
   tipoPlantillaEnvioCorreo: TipoPlantillaCorreoEnvio;
   nombreTipoPlantillaEnvio: string;
   password: string;
   activo: boolean;
   Plantillas: PlantillaCorreoEnvio[];
   isSengrid: boolean;
}
