import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { timeStamp } from "console";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { Perfil } from "src/app/models/Perfil/Perfil";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PerfilService } from "src/app/services/perfil.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import Swal from "sweetalert2";

@Component({
   selector: "app-asignador-perfil",
   templateUrl: "./asignador-perfil.component.html",
   styleUrls: ["./asignador-perfil.component.scss"],
})
export class AsignadorPerfilComponent implements OnInit {
   public loading: boolean = false;
   @Input() public perfilSelected: Perfil = undefined;
   //@Output() private sendAsignEmisorRequest:EventEmitter<any> = new EventEmitter<any>();
   @Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();

   assignPerfil: FormGroup;
   listOfEmisores: Emisor[] = [];
   emisoresDePerfil: Emisor[] = [];
   constructor(private localStorage: LocalStorageService, private formBuilder: FormBuilder, private perfilService: PerfilService, private utilities: UtilitiesService, private ref: ChangeDetectorRef) {}

   ngOnInit(): void {
      this.assignPerfil = this.formBuilder.group({
         empresaAsignar: [, Validators.required],
      });
      this.listOfEmisores = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
      this.utilities.sortAlphabeth(this.listOfEmisores, "razonSocial");
      this.getListOfEmisores();
   }

   onSubmit() {
      if (!this.assignPerfil.valid) return;
      //console.log(this.assignPerfil);
      let emisor = this.assignPerfil.controls.empresaAsignar.value;
      if (this.emisorAlreadyExist(emisor)) {
         Swal.fire({
            icon: "info",
            title: "Emisor asignado",
            text: "El mesor sleeccionado ya se encuentra asignado a este perfíl",
         });
         return;
      }
      this.loading = true;
      this.ref.detectChanges();
      this.perfilService.assignEmisorToPerfil(emisor, this.perfilSelected).subscribe(
         (result) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Emisor asignado",
               text: "El emisor ahora dispone de éste perfíl para asignarlo a sus usuarios.",
            });
            this.emisoresDePerfil.push(emisor);
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error al asignar",
               text: "Ocurrió un error al asignar un emisor a este ´perfíl. " + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }

   deleteEmisor(em: Emisor) {
      this.loading = true;
      this.ref.detectChanges();
      this.perfilService.unassigEmisorToPerfil(em, this.perfilSelected).subscribe(
         (result) => {
            this.loading = false;
            this.ref.detectChanges();
            this.getListOfEmisores();
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error al desasociar emisor",
               text: "No fue posible desasociar el emisor seleccionado a este perfíl. " + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }

   sendCloseRequest() {
      this.sendCloseRequestEmitter.emit();
   }

   emisorAlreadyExist(emisor: Emisor) {
      return this.emisoresDePerfil.some((em) => em.emisorId == emisor.emisorId);
   }

   getListOfEmisores() {
      this.loading = true;
      this.perfilService.emisorListOfProfile(this.perfilSelected).subscribe(
         (result) => {
            this.emisoresDePerfil = result.data;
            this.loading = false;
         },
         (error) => {
            this.loading = false;
            Swal.fire({
               icon: "error",
               title: "Error al obtener lista de emisores",
               text: "No fue posible obtener la lista de empresas asociadas a este perfíl. " + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }
}
