import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { EventMessage } from "@azure/msal-browser";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { Receptor } from "src/app/models/Receptor/Receptor";
import { ReceptorRequest } from "src/app/models/Receptor/ReceptorRequest";
import { ClienteService } from "src/app/services/cliente.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";

@Component({
	selector: "app-client-entry",
	templateUrl: "./client-entry.component.html",
	styleUrls: ["./client-entry.component.scss"],
})
export class ClientEntryComponent implements OnInit {
	@ViewChild("clientFormRef") private childFormRef: NgForm;
	public clientForm: FormGroup;
	private clientObj: ReceptorRequest = new ReceptorRequest();
	private emisorId: string = this.tokenStorage.getCompany();
	private tryingClientContractRequest: boolean = false;
	@Input() public clientToEdit: Receptor;
	@Input() public editMode: boolean = null;
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendNewClientRequest: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendEditedClientRequest: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendNewClientToContractRequest: EventEmitter<any> = new EventEmitter<any>();
	constructor(private formBuilder: FormBuilder, private tokenStorage: TokenStorageService, public permissions: PermissionService) {}

	ngOnInit(): void {
		this.createForm();
		if (this.clientToEdit) {
			this.setClientDataToForm(this.clientToEdit);
		}
	}

	createForm() {
		this.clientForm = this.formBuilder.group({
			rutString: ["", [Validators.required, VerificacionRutValidator, Validators.maxLength(10)]],
			razonSocial: ["", Validators.required],
			giro: ["", Validators.required],
			dirFact: ["", Validators.required],
			correoFact: ["", [Validators.required, SingleEmailValidator]],
			correoPar: ["", [SingleEmailValidator]],
			ciudad: ["", Validators.required],
			comuna: ["", Validators.required],
		});
	}
	onSubmit() {
		if (this.clientForm.valid && !this.tryingClientContractRequest) {
			this.getFormData();
			if (this.clientToEdit) {
				this.sendEditedClientRequest.emit(this.clientObj);
			} else {
				this.sendNewClientRequest.emit(this.clientObj);
			}
		}
	}
	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}

	onSubmitThenContract(formRef: FormGroupDirective, event) {
		this.tryingClientContractRequest = true;
		this.clientForm.updateValueAndValidity();
		this.childFormRef.ngSubmit.emit();
		formRef.onSubmit(event);
		this.clientForm.markAllAsTouched();
		if (this.clientForm.valid) {
			this.getFormData();
			this.sendNewClientToContractRequest.emit(this.clientObj);
			this.tryingClientContractRequest = false;
		}
	}

	getFormData() {
		this.clientObj.rutString = this.clientForm.controls.rutString.value;
		this.clientObj.razonSocial = this.clientForm.controls.razonSocial.value;
		this.clientObj.giro = this.clientForm.controls.giro.value;
		//this.clientObj.dirPart = this.clientForm.controls.dirPart.value;
		this.clientObj.dirFact = this.clientForm.controls.dirFact.value;
		this.clientObj.correoPar = this.clientForm.controls.correoPar.value;
		this.clientObj.correoFact = this.clientForm.controls.correoFact.value;
		this.clientObj.ciudad = this.clientForm.controls.ciudad.value;
		this.clientObj.comuna = this.clientForm.controls.comuna.value;
		if (this.clientToEdit) {
			this.clientObj.receptorId = this.clientToEdit.receptorId;
			this.clientObj.activo = this.clientToEdit.activo;
			//console.log(this.clientToEdit.receptorId,this.clientToEdit.receptorId);
		}
	}

	setClientDataToForm(receivedClient: Receptor) {
		this.clientForm.patchValue(receivedClient);
	}
}
