<div class="row">
	<div class="col-4">
		<div class="card-custom card">
			<div class="card-content">
				<div class="card-body" [formGroup]="totalesDteFormGroup" (blur)="onSubmit()">
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Formato</div>
						<div class="col-8">
							<ng-select [items]="sizeFormat" bindLabel="name" formControlName="formato" [clearable]="false" (blur)="onSubmit()" [attr.disabled]="true" [readonly]="true" [ngClass]="{ 'hide-arrow': true }" placement="right"></ng-select>
							<div *ngIf="(totalesDteFormGroup.touched && totalesDteFormGroup.controls.formato.invalid) || totalesDteFormGroup.controls.tipoDte.invalid" class="text-danger">
								<div *ngIf="totalesDteFormGroup.controls.formato.errors?.required">Campo Requerido</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="!(currentInvoiceType == 3)">
						<div class="col-4 label-control">Tipo Dte</div>
						<div class="col-8">
							<ng-select [items]="tipoDteSource" bindLabel="nombre" formControlName="tipoDte" [clearable]="false" (blur)="onSubmit()" [attr.disabled]="true" [readonly]="true" [ngClass]="{ 'hide-arrow': true }" placement="right" ngbTooltip="{{ totalesDteFormGroup.controls.tipoDte.value.nombre }}"> </ng-select>
							<div *ngIf="(totalesDteFormGroup.touched && totalesDteFormGroup.controls.tipoDte.invalid) || totalesDteFormGroup.controls.tipoDte.invalid" class="text-danger">
								<div *ngIf="totalesDteFormGroup.controls.tipoDte.errors?.required">Campo Requerido</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="showTransferType">
						<div class="col-4 label-control">Tipo Traslados</div>
						<div class="col-8">
							<ng-select [items]="tipoTrasladosSource" bindLabel="nombre" formControlName="tipoTraslado" [clearable]="false" (blur)="onSubmit()">
								<ng-template ng-option-tmp let-item="item">
									<div title="{{ item.nombre }}" ngbTooltip="{{ item.nombre }}" container="body" placement="right">{{ item.nombre }}</div>
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="showDeliveryType">
						<div class="col-4 label-control">Tipo Despacho</div>
						<div class="col-8">
							<ng-select [items]="tipoDespachoSource" bindLabel="nombre" formControlName="tipoDespacho" [clearable]="false" (blur)="onSubmit()">
								<ng-template ng-option-tmp let-item="item">
									<div ngbTooltip="{{ item.nombre }}" container="body" placement="right">{{ item.nombre }}</div>
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="showPaymentType">
						<div class="col-4 label-control">Forma de Pago</div>
						<div class="col-8">
							<ng-select [items]="formasPagoSource" bindLabel="nombreForma" formControlName="formaPago" [clearable]="false" (change)="updateFormaPago($event)" (blur)="onSubmit()"></ng-select>
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Fecha de Emisión</div>
						<div class="col-8">
							<input type="text" [maxDate]="todayString" [minDate]="minDateString" ngxDaterangepickerMd name="fechaEmision" formControlName="fechaEmision" class="form-control" [locale]="locale" [singleDatePicker]="true" [autoApply]="true" (change)="detectChangeResumenDate($event)" />
							<div *ngIf="totalesDteFormGroup.touched && (totalesDteFormGroup.controls.fechaEmision.invalid || !validDate)" class="text-danger">
								<div *ngIf="!validDate">Fecha inválida, usar formato dd/mm/yyyy</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="showDueDate && !showInExport">
						<div class="col-4 label-control">Dias de Vencimiento</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="diasVencimiento" (blur)="onSubmit()" />
							<div *ngIf="totalesDteFormGroup.touched && totalesDteFormGroup.controls.diasVencimiento.invalid" class="text-danger">
								<div *ngIf="totalesDteFormGroup.controls.diasVencimiento.errors?.PositiveNumber">Número debe ser positivo</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="showDiscount && !showInExport">
						<div class="col-4 label-control">Descuento Global (Monto)</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="descuentoGlobal" (blur)="calculateTotals()" />
							<div *ngIf="totalesDteFormGroup.touched && totalesDteFormGroup.controls.descuentoGlobal.invalid" class="text-danger">
								<div *ngIf="totalesDteFormGroup.controls.descuentoGlobal.errors?.NonNegativeNumber">Número no debe ser negativo</div>
							</div>
							<small>Se aplica sobre el neto total</small>
						</div>
						<div class="col-4 label-control mt-4" *ngIf="opciones.tipoMoneda != 'CLP' && !showInExport">Descuento Global CLP</div>
						<div class="col-8 text-right mt-4" *ngIf="opciones.tipoMoneda != 'CLP' && currentInvoiceType != 4">
							{{ montoDescuento | number : "1.0-0" : "es" }}
						</div>
						<div class="col-8 text-right mt-4" *ngIf="opciones.tipoMoneda != 'CLP' && currentInvoiceType == 4">
							{{ montoDescuentoForeign | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div *ngIf="showIvaTerceros">
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">Iva Terceros</div>
							<div class="col-8">
								<input type="checkbox" class="form-check-input" (change)="changeHaveIvaTerceros($event)" />
							</div>
						</div>
						<div class="form-group row mx-auto" *ngIf="showInputIvaTerceros">
							<div class="col-4 label-control">Valor Iva Terceros</div>
							<div class="col-8">
								<input currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: '0' }" class="form-control" formControlName="ivaTerceros" (blur)="calculateIvaPropio()" />
								<div *ngIf="totalesDteFormGroup.touched && totalesDteFormGroup.controls.ivaTerceros.invalid" class="text-danger">
									<div *ngIf="totalesDteFormGroup.controls.ivaTerceros.errors?.PositiveNumber">Número debe ser positivo</div>
								</div>
							</div>
						</div>
						<div class="form-group row mx-auto" *ngIf="showInputIvaTerceros">
							<div class="col-4 label-control">Valor Iva Propio</div>
							<div class="col-8">
								<input type="number" class="form-control" formControlName="ivaPropio" disabled />
								<div *ngIf="totalesDteFormGroup.touched && totalesDteFormGroup.controls.ivaPropio.invalid" class="text-danger">
									<div *ngIf="totalesDteFormGroup.controls.ivaPropio.errors?.PositiveNumber">Número debe ser positivo</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-4" *ngIf="ShowInSettlement">
		<div class="card card-custom">
			<div class="card-content">
				<div class="card-header">
					<h5>Resumen comisiones</h5>
				</div>
				<div class="card-body">
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Neto</div>
						<div class="col-8 text-right">
							{{ netoAfectoResumenComision | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Subtotal</div>
						<div class="col-8 text-right">
							{{ subTotalResumenComision | number : "1.0-0" : "es" }}
						</div>
						<div *ngIf="subTotalResumen < 0" class="text-danger">Monto no puede ser negativo</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Exento</div>
						<div class="col-8 text-right">
							{{ exentoResumenComision | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">IVA</div>
						<div class="col-8 text-right">
							{{ montoIvaComision | number : "1.0-0" : "es" }}
						</div>
					</div>

					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Total</div>
						<div class="col-8 text-right">
							{{ totalResumenComision | number : "1.0-0" : "es" }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-4" *ngIf="currentInvoiceType != 4 && currentInvoiceType != 2">
		<!-- general purpouse resumme -->
		<div class="card card-custom">
			<div class="card-content">
				<div class="card-header">
					<h5>Resumen {{ opciones.tipoMoneda != "CLP" ? "CLP" : "" }}</h5>
				</div>
				<div class="card-body">
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Neto</div>
						<div class="col-8 text-right">
							{{ netoAfectoResumen | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Exento</div>
						<div class="col-8 text-right">
							{{ exentoResumen | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Subtotal</div>
						<div class="col-8 text-right">
							{{ subTotalResumen | number : "1.0-0" : "es" }}
						</div>
						<div *ngIf="subTotalResumen < 0" class="text-danger">Monto no puede ser negativo</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="subTotalExento > 0">
						<div class="col-4 label-control">Subtotal Exento</div>
						<div class="col-8 text-right">
							{{ subTotalExento | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">IVA</div>
						<div class="col-8 text-right">
							{{ montoIva | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto text-danger" *ngIf="currentInvoiceType == 3">
						<div class="col-4 label-control">Retención IVA</div>
						<div class="col-8 text-right">
							{{ montoRetencionIva | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div *ngIf="currentInvoiceType != 1" class="form-group row mx-auto">
						<div class="col-4 label-control">Imp. específicos</div>
						<div class="col-8 text-right">
							{{ montoTotalImpuestosEspecificos | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="showComission">
						<div class="col-4 label-control">Comision / Cargos</div>
						<div class="col-8 text-right">
							{{ totalResumenComision | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Total</div>
						<div class="col-8 text-right">
							{{ totalResumen | number : "1.0-0" : "es" }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-4" *ngIf="currentInvoiceType == 2 && !checkComercialValue()">
		<!-- general purpouse resumme -->
		<div class="card card-custom">
			<div class="card-content">
				<div class="card-header">
					<h5>Resumen {{ opciones.tipoMoneda != "CLP" ? "CLP" : "" }}</h5>
				</div>
				<div class="card-body">
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Neto</div>
						<div class="col-8 text-right">
							{{ netoAfectoResumen | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Exento</div>
						<div class="col-8 text-right">
							{{ exentoResumen | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Subtotal</div>
						<div class="col-8 text-right">
							{{ subTotalResumen | number : "1.0-0" : "es" }}
						</div>
						<div *ngIf="subTotalResumen < 0" class="text-danger">Monto no puede ser negativo</div>
					</div>

					<div class="form-group row mx-auto">
						<div class="col-4 label-control">IVA</div>
						<div class="col-8 text-right">
							{{ montoIva | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Imp. específicos</div>
						<div class="col-8 text-right">
							{{ montoTotalImpuestosEspecificos | number : "1.0-0" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Total</div>
						<div class="col-8 text-right">
							{{ totalResumen | number : "1.0-0" : "es" }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-4" *ngIf="currentInvoiceType == 2 && checkComercialValue()">
		<!-- general purpouse resumme -->
		<div class="card card-custom">
			<div class="card-content">
				<div class="card-header">
					<h5>Resumen {{ opciones.tipoMoneda != "CLP" ? "CLP" : "" }}</h5>
				</div>
				<div class="card-body">
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Neto</div>
						<div class="col-8 text-right">0</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Subtotal</div>
						<div class="col-8 text-right">0</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Exento</div>
						<div class="col-8 text-right">0</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">IVA</div>
						<div class="col-8 text-right">0</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Imp. específicos</div>
						<div class="col-8 text-right">0</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Total</div>
						<div class="col-8 text-right">0</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-8 text-right" style="color: red">Sin Valor Comercial</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- RESUMEN PARA DTE EXPORTACION -->
	<div class="col-4" *ngIf="currentInvoiceType == 4">
		<div class="card card-custom">
			<div class="card-content">
				<div class="card-header">
					<h5>
						Resumen
						{{
							opciones.tipoMoneda != "CLP"
								? opciones.tipoMoneda == "OTHER"
									? "Otra
                        Moneda"
									: opciones.tipoMoneda
								: ""
						}}
					</h5>
				</div>
				<div class="card-body">
					<div class="form-group row mx-auto">
						<div class="col-5 label-control">Valor de Bienes</div>
						<div class="col-7 text-right">
							{{ exentoResumen | number : "1.0-2" : "es" }}
						</div>
					</div>
					<!-- <div class="form-group row mx-auto">
                        <div class="col-4 label-control">
                            Total
                        </div>
                        <div class="col-8 text-right">
                           {{totalResumen | number:'1.0-0':'es'}}
                        </div>
                    </div> -->
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Flete</div>
						<div class="col-8 text-right">
							{{ fleteResumen | number : "1.0-2" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto">
						<div class="col-4 label-control">Seguro</div>
						<div class="col-8 text-right">
							{{ seguroResumen | number : "1.0-2" : "es" }}
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="currentInvoiceType == 4">
						<div class="col-5 label-control">Clausula de Venta</div>
						<div class="col-7 text-right">
							{{ totalClausulaVenta | number : "1.0-2" : "es" }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- RESUMEN EN MONEDA EXTRANJERA PARA DTE EN GENERAL -->
	<div class="col-4" *ngIf="currentInvoiceType != 4">
		<div class="card card-custom" *ngIf="opciones.tipoMoneda != 'CLP'">
			<div class="card-content">
				<div class="card-header">
					<h5>
						Resumen
						{{
							opciones.tipoMoneda != "CLP"
								? opciones.tipoMoneda == "OTHER"
									? "Otra
                        Moneda"
									: opciones.tipoMoneda
								: ""
						}}
					</h5>
				</div>
				<div class="card-body">
					<div class="alter-resumen" *ngIf="opciones.tipoMoneda != 'CLP'">
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Neto
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.montoNeto | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Subtotal
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.subTotalForeign | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Exento
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.montoExento | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								IVA
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.iva | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Imp. Específico
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.imptoEspecifico | number : "1.0-0" : "es" }}
							</div>
						</div>

						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Total
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.montoTotal | number : "1.0-0" : "es" }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- RESUMEN EN MONEDA EXTRANJERA PARA DTE EXPORTACION -->
	<div class="col-4" *ngIf="currentInvoiceType == 4">
		<div class="card card-custom" *ngIf="opciones.tipoMoneda != 'CLP'">
			<div class="card-content">
				<div class="card-header">
					<h5>Resumen CLP</h5>
				</div>
				<div class="card-body">
					<div class="alter-resumen" *ngIf="opciones.tipoMoneda != 'CLP'">
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Valor de Bienes
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.montoExento | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto">
							<div class="col-4 label-control">
								Flete
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.flete | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto" *ngIf="opciones.tipoMoneda != 'CLP'">
							<div class="col-4 label-control">
								Seguro
								{{
									opciones.tipoMoneda != "CLP"
										? opciones.tipoMoneda == "OTHER"
											? "Otra
                                Moneda"
											: opciones.tipoMoneda
										: ""
								}}
							</div>
							<div class="col-8 text-right">
								{{ this.otraMonedaResumen.seguro | number : "1.0-0" : "es" }}
							</div>
						</div>
						<div class="form-group row mx-auto">
							<div class="col-5 label-control">Clausula de Venta</div>
							<div class="col-7 text-right">
								{{ this.otraMonedaResumen.montoClausulaVenta | number : "1.0-0" : "es" }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<swal #currentSwal title="Operación" text="Operación realizada" icon="info" style="z-index: 900000 !important"></swal>
