import { ExpresionDinero } from "./ExpresionDinero.enum";
import { IndicadorExento } from "./IndicadorExento.enum";
import { TipoMovimiento } from "./TipoMovimiento.enum";

export class DescuentoRecargo {
   numero: number;
   tipoMovimiento: TipoMovimiento;
   descripcion: string;
   tipoValor: ExpresionDinero;
   valor: number;
   IndicadorExento: IndicadorExento;
}
