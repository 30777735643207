import { Directive } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Directive({
   selector: "[appMaxNumber]",
})
export class MaxNumberDirective {
   constructor() {}
}

export function MaxNumberValidator(maxNumber: number): ValidatorFn {
   return (control: AbstractControl): ValidationErrors | null => {
      const numberIsHigher = control.value > maxNumber;
      return numberIsHigher ? { numberIsHigher: { value: control.value } } : null;
   };
}
