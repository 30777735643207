import { Component, OnInit } from "@angular/core";

@Component({
   selector: "app-dropdown-menu3",
   templateUrl: "./dropdown-menu3.component.html",
})
export class DropdownMenu3Component implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
