<!-- begin::Header Nav -->
<ul class="menu-nav" [ngClass]="ulCSSClasses">
	<!-- begin::1 Level -->
	<li class="menu-item menu-item-submenu menu-item-rel" routerLinkActive="menu-item-active" aria-haspopup="true" [attr.data-menu-toggle]="headerMenuDesktopToggle">
		<a class="menu-link menu-toggle" routerLinkActive="active">
			<span class="menu-text">Ventas</span>
			<ng-container *ngIf="rootArrowEnabled">
				<i class="menu-arrow"></i>
			</ng-container>

			<div class="menu-submenu menu-submenu-classic menu-submenu-left">
				<ul class="menu-subnav">
					<!-- MENU FACTURACION -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLink="/documentos-emitidos" routerLinkActive="active">
							<div class="symbol symbol-40 symbol-dark-warning mr-5 seguimiento-menu">
								<span class="symbol-label">
									<span [inlineSVG]="'./assets/deployAssets/icons/dtes3.svg'" cacheSVG="true" class="svg-icon svg-icon-xl"></span>
								</span>
							</div>
							<span class="menu-text seguimiento-menu" superiorMenu="Ventas">Documentos Emitidos</span>
						</a>
					</li>
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLinkActive="active" routerLink="/factura-masiva">
							<!-- <span [inlineSVG]="'./assets/media/svg/icons/Design/PenAndRuller.svg'" cacheSVG="true"
                class="svg-icon menu-icon"></span> -->
							<div class="symbol symbol-40 symbol-dark-warning mr-5 seguimiento-menu">
								<span class="symbol-label">
									<span [inlineSVG]="'./assets/deployAssets/icons/facturacion_masiva.svg'" cacheSVG="true" class="svg-icon svg-icon-xl svg-icon-warning"></span>
								</span>
							</div>
							<span class="menu-text seguimiento-menu" superiorMenu="Ventas">Factura Masiva</span>
						</a>
					</li>
				</ul>
			</div>
		</a>
	</li>
	
	<!-- begin::1 Level -->
	<li class="menu-item menu-item-submenu menu-item-rel" routerLinkActive="menu-item-active" aria-haspopup="true" [attr.data-menu-toggle]="headerMenuDesktopToggle">
		<a class="menu-link menu-toggle" routerLinkActive="active">
			<span class="menu-text">Mantenedores</span>
			<ng-container *ngIf="rootArrowEnabled">
				<i class="menu-arrow"></i>
			</ng-container>

			<div class="menu-submenu menu-submenu-classic menu-submenu-left">
				<ul class="menu-subnav">
					<!-- MENU FACTURACION -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLink="/folios" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Mantenedores">Folios</span>
						</a>
					</li>
					<!-- end::2 Level -->

					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLink="/usuarios" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Mantenedores">Usuarios</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLink="/productos" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Mantenedores">Productos</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<li>
						<div class="separator separator-dashed my-1"></div>
					</li>
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a href="javascript:;" class="menu-link menu-toggle" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text">Clientes</span>
							<i class="menu-arrow"></i>
						</a>
						<div class="menu-submenu menu-submenu-classic menu-submenu-right" data-hor-direction="menu-submenu-right">
							<ul class="menu-subnav">
								<!-- begin::2 Level -->
								<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
									<a class="menu-link seguimiento-menu" routerLink="/clientes" routerLinkActive="active">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text seguimiento-menu" superiorMenu="Mantenedores">Clientes Nacionales</span>
									</a>
								</li>
								<!-- end::2 Level -->
							</ul>
						</div>
					</li>
					
				</ul>
			</div>
		</a>
	</li>
	<!-- end::1 Level -->

	<!-- begin::1 Level -->
	<li class="menu-item menu-item-submenu menu-item-rel" routerLinkActive="menu-item-active" aria-haspopup="true" [attr.data-menu-toggle]="headerMenuDesktopToggle">
		<a class="menu-link menu-toggle" routerLinkActive="active">
			<span class="menu-text">Configuración</span>
			<ng-container *ngIf="rootArrowEnabled">
				<i class="menu-arrow"></i>
			</ng-container>

			<div class="menu-submenu menu-submenu-classic menu-submenu-left">
				<ul class="menu-subnav">
					<!-- MENU FACTURACION -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLink="/emisores" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Configuración">Empresas</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLink="/alertas" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Configuración">Alertas</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLinkActive="active" routerLink="/certificados">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Configuración">Certificados Digitales</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link seguimiento-menu" routerLinkActive="active" routerLink="/plantillas">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text seguimiento-menu" superiorMenu="Configuración">Plantillas Impresión</span>
						</a>
					</li>
					<!-- end::2 Level -->
				</ul>
			</div>
		</a>
	</li>

	<li *ngIf="isSuperAdmin" class="menu-item menu-item-submenu menu-item-rel" routerLinkActive="menu-item-active" aria-haspopup="true" [attr.data-menu-toggle]="headerMenuDesktopToggle">
		<a class="menu-link menu-toggle" routerLinkActive="active">
			<span class="menu-text">SuperAdmin</span>
			<ng-container *ngIf="rootArrowEnabled">
				<i class="menu-arrow"></i>
			</ng-container>

			<div class="menu-submenu menu-submenu-classic menu-submenu-left">
				<ul class="menu-subnav">
					<!-- MENU FACTURACION -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link" routerLinkActive="active" routerLink="/plantillas">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text">Plantillas Impresión</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link" routerLink="/perfiles" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text" superiorMenu="Configuración">Perfiles</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link" routerLink="/logs" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text">Registro de Transacciones</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link" routerLink="/correoEnvio" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text">Correos de Envio</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<!-- begin::2 Level -->
					<li class="menu-item menu-item-submenu menu-item-special" data-menu-toggle="hover" aria-haspopup="true" routerLinkActive="menu-item-active">
						<a class="menu-link" routerLink="/impuestos" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text">Impuestos</span>
						</a>
					</li>
					<!-- end::2 Level -->
					<li *ngIf="isSuperAdmin" class="menu-item menu-item-submenu menu-item-special" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
						<a class="menu-link" routerLink="/resellers" routerLinkActive="active">
							<i class="menu-bullet menu-bullet-dot">
								<span></span>
							</i>
							<span class="menu-text">Resellers</span>
						</a>
					</li>
				</ul>
			</div>
		</a>
	</li>
	<!-- end::1 Level -->
</ul>
<!-- end::Header Nav -->
