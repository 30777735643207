import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DteEmitido } from "../models/Dte/DteEmitido";
import { Response } from "src/app/shared/models/Response";
import { Observable } from "rxjs";
import { PaginationFilter } from "../shared/models/PaginationFilter";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Page } from "../shared/models/Page";
import { DteFilter } from "../models/Dte/DteFilter";
import { DteEmitidoFile } from "../models/Dte/DteEmitidoFile";
import { Traza } from "../models/Traza";
import { NotaCredito } from "../models/NotaCredito";
import { DetalleDte } from "../models/Dte/DetalleDte";
import { Receptor } from "../models/Receptor/Receptor";
import { ReferenciaDte } from "../models/Dte/ReferenciaDte";
import { NotaDebito } from "../models/NotaDebito";
import { ComisionRecargo } from "../models/Dte/ComisionRecargo";
import { IDte } from "../models/Dte/IDte";
import { InfoDetalladaDte } from "../models/InfoDetalladaDte";
import { FacturacionGuiaRequest } from "../models/Dte/FacturacionGuiaRequest";
import { ActualizarEstadoSiiRequest } from "../models/Dte/ActualizarEstadoSiiRequest";
import { ReenvioEmailRequest } from "../models/Dte/ReenvioEmailRequest";
import { Conciliacion } from "../models/Dte/Conciliacion";
import { ReutilizacionMasivaRequest } from "../models/Dte/ReutilzacionMasivaRequest";

@Injectable({
	providedIn: "root",
})
export class DteEmitidosService {
	private REST_API_SERVER = environment.backend_server + "/dte";
	private REST_API_SERVER_ONVISION = environment.backend_server + "/Impresion";
	private REST_API_SERVER_DebitNote = environment.backend_server + "/NotaDebito";
	private REST_API_SERVER_DTE_EXPORT = environment.backend_server + "/Exportacion";
	private REST_API_SERVER_CESION = environment.backend_server + "/Cesion";
	private REST_API_SERVER_SETTLEMENT = environment.backend_server + "/LiquidacionFactura";
	private REST_API_SERVER_GUIDE = environment.backend_server + "/GuiaDespacho";
	private auth = {};
	constructor(private http: HttpClient) {}

	public getData(emisorId: string): Observable<Response<DteEmitido[]>> {
		return this.http.get<Response<DteEmitido[]>>(this.REST_API_SERVER + "/list?emisorId=" + emisorId);
	}

	public getDataByPage(filtro: DteFilter, page: Page): Observable<PagedResponse<DteEmitido>> {
		let stringRut = "&rutReceptor=" + filtro.rutReceptor;
		if (filtro.rutReceptor == "") {
			stringRut = "";
		}
		let stringEstadoSii = filtro.estadoSii ? "&estadoSii=" + filtro.estadoSii : "";
		return this.http.get<PagedResponse<DteEmitido>>(
			this.REST_API_SERVER +
				"/list/filter?emisorId=" +
				filtro.emisorId +
				"&importacionMasivaId=" +
				filtro.importacionMasivaId +
				"&tipoDteId=" +
				filtro.tipoDteId +
				"&Importado=" +
				(filtro.importado != undefined ? filtro.importado : "") +
				"&Reutilizado=" +
				(filtro.reutilizado != undefined ? filtro.reutilizado : "") +
				"&Nulos=" +
				(filtro.nulos != undefined ? filtro.nulos : "") +
				"&TrackId=" +
				(filtro.trackId != undefined ? filtro.trackId : "") +
				"&fechaDesde=" +
				(filtro.fechaDesde != undefined ? filtro.fechaDesde : "") +
				"&fechaHasta=" +
				(filtro.fechaHasta != undefined ? filtro.fechaHasta : "") +
				stringRut +
				"&razonSocialReceptor=" +
				(filtro.razonSocialReceptor != undefined ? filtro.razonSocialReceptor : "") +
				"&razonSocialProveedor=" +
				(filtro.razonSocialProveedor != undefined ? filtro.razonSocialProveedor : "") +
				"&codigoSii=" +
				(filtro.codigoSii != undefined ? filtro.codigoSii : "") +
				"&folio=" +
				(filtro.folio != undefined ? filtro.folio : "") +
				stringEstadoSii +
				"&rutReceptorDv=" +
				(filtro.rutReceptor != undefined ? filtro.rutReceptor : "") +
				"&SucursalId=" +
				(filtro.sucursalId ? filtro.sucursalId : "") +
				"&EstadoAcuse=" +
				(filtro.estadoAcuse ? filtro.estadoAcuse : "") +
				"&pageNumber=" +
				page.pageNumber +
				"&pageSize=" +
				page.pageSize
		);
	}
	public getDataByPageForGuides(filtro: DteFilter, page: Page): Observable<PagedResponse<DteEmitido>> {
		let stringRut = "&rutReceptor=" + filtro.rutReceptor;
		if (filtro.rutReceptor == "") {
			stringRut = "";
		}
		let stringEstadoSii = filtro.estadoSii ? "&estadoSii=" + filtro.estadoSii : "";
		return this.http.get<PagedResponse<DteEmitido>>(
			this.REST_API_SERVER +
				"/listGuides/filter?emisorId=" +
				filtro.emisorId +
				"&importacionMasivaId=" +
				filtro.importacionMasivaId +
				"&TipoTraslado=" +
				filtro.tipoTraslado +
				"&TipoDespacho=" +
				filtro.tipoDespacho +
				"&Facturable=" +
				filtro.facturable +
				"&TrackId=" +
				(filtro.trackId != undefined ? filtro.trackId : "") +
				"&tipoDteId=" +
				filtro.tipoDteId +
				"&Reutilizado=" +
				(filtro.reutilizado != undefined ? filtro.reutilizado : "") +
				"&fechaDesde=" +
				(filtro.fechaDesde != undefined ? filtro.fechaDesde : "") +
				"&fechaHasta=" +
				(filtro.fechaHasta != undefined ? filtro.fechaHasta : "") +
				stringRut +
				"&razonSocialReceptor=" +
				(filtro.razonSocialReceptor != undefined ? filtro.razonSocialReceptor : "") +
				"&folio=" +
				(filtro.folio != undefined ? filtro.folio : "") +
				stringEstadoSii +
				"&rutReceptorDv=" +
				(filtro.rutReceptor != undefined ? filtro.rutReceptor : "") +
				"&SucursalId=" +
				(filtro.sucursalId ? filtro.sucursalId : "") +
				"&pageNumber=" +
				page.pageNumber +
				"&pageSize=" +
				page.pageSize
		);
	}

	public getDataByPageOnRVD(rvdId: string, emisorId: string, page: Page) {
		return this.http.get<PagedResponse<DteEmitido>>(this.REST_API_SERVER + "/list/filterbyrvd?registroVentaDiarioId=" + rvdId + "&emisorId=" + emisorId + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	public getDataByPageAndExcel(filtro: DteFilter, page: Page): Observable<PagedResponse<DteEmitido>> {
		return this.http.get<PagedResponse<DteEmitido>>(this.REST_API_SERVER + "/list/filter?emisorId=" + filtro.emisorId + "&importacionMasivaId=" + filtro.importacionMasivaId + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	public getXml(dteId: string): Observable<Response<DteEmitidoFile>> {
		return this.http.get<Response<DteEmitidoFile>>(this.REST_API_SERVER + "/xml?dteId=" + dteId);
	}

	public getTrace(dteId: string): Observable<Response<Traza[]>> {
		return this.http.get<Response<Traza[]>>(this.REST_API_SERVER + "/trazabilidad?dteId=" + dteId);
	}

	public getPdf(dteId: string): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER_ONVISION + "/pdf/" + dteId + "/" + false);
	}

	public getPdfCedible(dteId: string, cedible: boolean): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER_ONVISION + "/pdf/" + dteId + "/" + cedible);
	}
	public getPdfLiquidacion(dteId: string): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER_ONVISION + "/pdf/liquidacion?dteId=" + dteId);
	}
	// public sendDocumentToEmail(emailRequest: EmailRequest): Observable<any> {
	//   let request = {};
	//   request["auth"] = this.auth;
	//   request["email"] = emailRequest;
	//   return this.http.post<any>(EMAIL_API_SERVER + "/Send/Template", request);
	// }
	public getDetalleDte(dteId: string): Observable<Response<DetalleDte[]>> {
		return this.http.get<Response<DetalleDte[]>>(this.REST_API_SERVER + "/detalle?dteId=" + dteId);
	}
	public getComisionesDte(dteId: string): Observable<Response<ComisionRecargo[]>> {
		return this.http.get<Response<ComisionRecargo[]>>(this.REST_API_SERVER + "/comisiones?dteId=" + dteId);
	}
	public getReceptorDte(dteId: string): Observable<Response<Receptor>> {
		return this.http.get<Response<Receptor>>(this.REST_API_SERVER + "/receptor?dteId=" + dteId);
	}
	public getReceptorDteLiquidacion(dteId: string): Observable<Response<Receptor>> {
		return this.http.get<Response<Receptor>>(this.REST_API_SERVER + "/receptorLiquidacion?dteId=" + dteId);
	}

	public creditNoteNullification(note: NotaCredito): Observable<Response<DteEmitido>> {
		let formData = new FormData();

		switch (note.tipoNota) {
			case 0:
				//default
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentario);
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER + "/nota_credito/anulacion", formData);
				break;

			case 1: //montos
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("detalleString", JSON.stringify(note.itemsNota));
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentario);
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER + "/nota_credito/corrige_monto", formData);

			case 2: //textos
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("detalleString", JSON.stringify(note.itemsNota));
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentarioCorreccionTexto);
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER + "/nota_credito/corrige_texto", formData);
		}
	}
	public creditNoteDteExportNullification(note: NotaCredito): Observable<Response<DteEmitido>> {
		let formData = new FormData();

		switch (note.tipoNota) {
			case 0:
				//default
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentario);
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER_DTE_EXPORT + "/nota_credito/anulacion", formData);
		}
	}
	public debitNoteDteExportNullification(note: NotaDebito): Observable<Response<DteEmitido>> {
		let formData = new FormData();

		switch (note.tipoNota) {
			case 0:
				//default
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentario);
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER_DTE_EXPORT + "/nota_debito/anulacion", formData);
		}
	}
	public debitNoteNullification(note: NotaDebito): Observable<Response<DteEmitido>> {
		let formData = new FormData();

		switch (note.tipoNota) {
			case 0:
				//default
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentario);
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER_DebitNote + "/anulacion", formData);
		}
	}
	public debitNoteNullificationInterests(note: NotaDebito): Observable<Response<DteEmitido>> {
		let formData = new FormData();

		switch (note.tipoNota) {
			case 0: //Intereses
				formData.append("dteId", note.dteReferenciaId);
				formData.append("usuarioId", note.usuarioId);
				formData.append("motivo", note.motivoString);
				formData.append("razonRef", note.comentario);
				if (note.exento > 0) {
					formData.append("exento", note.exento.toString());
					formData.append("iva", "0");
					formData.append("neto", "0");
				} else {
					formData.append("neto", note.neto.toString());
					formData.append("iva", note.iva.toString());
					formData.append("exento", "0");
				}
				formData.append("total", note.total.toString());
				return this.http.post<Response<DteEmitido>>(this.REST_API_SERVER_DebitNote + "/intereses", formData);
		}
	}
	public getReferencias(dteId: string): Observable<Response<ReferenciaDte[]>> {
		return this.http.get<Response<ReferenciaDte[]>>(this.REST_API_SERVER + "/referencias?dteId=" + dteId);
	}

	public getReferenciasDte(dteId: string): Observable<Response<ReferenciaDte[]>> {
		return this.http.get<Response<ReferenciaDte[]>>(this.REST_API_SERVER + "/dtereferenciado?dteId=" + dteId);
	}

	public sendEmailBackend(emailList: string[], emailCopyList: string[], emailBlindCopyList: string[], dteList: string[], sendPdf: boolean, sendXml: boolean): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("listaDteIds", JSON.stringify(dteList));
		formData.append("sendPdf", sendPdf.toString());
		formData.append("sendXml", sendXml.toString());
		formData.append("emailList", JSON.stringify(emailList));
		formData.append("copyEmails", JSON.stringify(emailCopyList));
		formData.append("blindCopyEmails", JSON.stringify(emailBlindCopyList));
		return this.http.post<Response<string>>(this.REST_API_SERVER + "sendDocumentsToEmail", formData);
	}


	getInfoDetalleDte(dteId: string): Observable<InfoDetalladaDte> {
		return this.http.get<InfoDetalladaDte>(this.REST_API_SERVER + "/infodetallada?dteId=" + dteId);
	}

	guidesToInvoices(req: FacturacionGuiaRequest): Observable<Response<any>> {
		return this.http.post<Response<any>>(this.REST_API_SERVER_GUIDE + "/CreateInvoice", req);
	}

	resendDte(request: ActualizarEstadoSiiRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateSiiStatus", request);
	}

	documentsOfClient(filtro: DteFilter, page: Page): Observable<PagedResponse<DteEmitido>> {
		let stringRut = "&rutReceptor=" + filtro.rutReceptor;
		if (filtro.rutReceptor == "") {
			stringRut = "";
		}
		let stringEstadoSii = filtro.estadoSii ? "&estadoSii=" + filtro.estadoSii : "";
		return this.http.get<PagedResponse<DteEmitido>>(
			this.REST_API_SERVER +
				"/list/filter?emisorId=" +
				filtro.emisorId +
				"&importacionMasivaId=" +
				filtro.importacionMasivaId +
				"&tipoDteId=" +
				filtro.tipoDteId +
				"&fechaDesde=" +
				(filtro.fechaDesde != undefined ? filtro.fechaDesde : "") +
				"&fechaHasta=" +
				(filtro.fechaHasta != undefined ? filtro.fechaHasta : "") +
				stringRut +
				"&razonSocialReceptor=" +
				filtro.razonSocialReceptor +
				"&razonSocialProveedor=" +
				(filtro.razonSocialProveedor != undefined ? filtro.razonSocialProveedor : "") +
				"&codigoSii=" +
				(filtro.codigoSii != undefined ? filtro.codigoSii : "") +
				"&folio=" +
				filtro.folio +
				stringEstadoSii +
				"&rutReceptorDv=" +
				filtro.rutReceptor +
				"&ReceptorId=" +
				(filtro.receptorId ? filtro.receptorId : "") +
				"&SucursalId=" +
				(filtro.sucursalId ? filtro.sucursalId : "") +
				"&AmbienteId=" +
				(filtro.ambienteId != undefined ? filtro.ambienteId : "") +
				"&pageNumber=" +
				page.pageNumber +
				"&pageSize=" +
				page.pageSize
		);
	}
	resendEmailSii(request: ReenvioEmailRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/resendEmailSii", request);
	}
	importDteSii(conciliacion: Conciliacion): Observable<Response<string>> {
		//console.log("Conciliacionmensual",conciliacion);
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/importDteSII", conciliacion);
	}

	reuse(dteId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/reuseFolio/" + dteId);
	}
	massivereuse(request: ReutilizacionMasivaRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/massiveReuseFolio", request);
	}
}
