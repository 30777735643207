import { ThrowStmt } from "@angular/compiler";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import Stepper from "bs-stepper";
import * as moment from "moment";
import { interval, Observable, of } from "rxjs";
import { catchError, filter, first, last, map, retryWhen, switchMap, take, takeWhile, tap } from "rxjs/operators";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { ActividadEconomica } from "src/app/models/ActividadEconomica";
import { ActividadEconomicaEmisor } from "src/app/models/ActividadEconomicaEmisor";
import { Ambiente } from "src/app/models/Ambiente";
import { CertificadoDigital } from "src/app/models/CertificadoDigital/CertificadoDigital";
import { ConfiguracionEmision } from "src/app/models/ConfiguracionEmision";
import { CuentaSii } from "src/app/models/CuentaSii";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { CertificadoRequest, ConsumoFolio, EmisorWizardRequest, FolioRequest, PlantillaFileRequest } from "src/app/models/Emisor/EmisorWizardRequest";
import { LogoEmisorRequest } from "src/app/models/Emisor/LogoEmisorRequest";
import { LogoWizardRequest } from "src/app/models/Emisor/LogoWizardRequest";
import { FolioFileObject } from "src/app/models/Folio/FolioFileObject";
import { FormatoPapel } from "src/app/models/FormatoPapel";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { Sucursal } from "src/app/models/Sucursal";
import { TipoDte } from "src/app/models/tipoDte";
import { AuthService } from "src/app/modules/auth";
import { CertificadosDigitalesService } from "src/app/services/certificados-digitales.service";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Response } from "src/app/shared/models/Response";
import { SessionUser } from "src/app/shared/models/sessionUser";
import Swal from "sweetalert2";

@Component({
	selector: "app-wizard-emisor",
	templateUrl: "./wizard-emisor.component.html",
	styleUrls: ["./wizard-emisor.component.scss"],
})
export class WizardEmisorComponent implements OnInit {
	private stepper: Stepper;
	@ViewChild("stepper1") stepperRef: ElementRef;
	@ViewChild("emisorBaseFormRef") public emisorBaseFormRef;
	@ViewChild("sucursalLocalFormRef") public sucursalLocalFormRef;
	@ViewChild("actividadLocalFormRef") public actividadLocalFormRef;
	@ViewChild("logoLocalFormRef") public logoLocalFormRef;
	@ViewChild("emisorPagoFormRef") public pagoLocalFormRef;

	public emisorSuperGroup: FormGroup;
	public emisorBasicFormGroup: FormGroup;
	public emisorSucursalesFormGroup: FormGroup;
	public economicActivityFormGroup: FormGroup;
	public logoFormGroup: FormGroup;
	public pagoFormGroup: FormGroup;
	public facturacionFormGroup: FormGroup;
	public emisor: FormGroup;
	public certificadoFormGroup: FormGroup;
	public configEmisionFormArray: FormArray;

	public economicActivitiesList: ActividadEconomica[] = [];
	public economicActivitiesSource: ActividadEconomica[] = JSON.parse(this.localStorage.getActividadesEconomicas()) as ActividadEconomica[];
	//public selectedEmpresa: EmpresaFacturacion;

	//public environmentType = ["Certificacion", "Produccion"];
	public environmentType: Ambiente[] = JSON.parse(this.localStorage.getTipoAmbiente()) as Ambiente[];
	private emisorBaseToSend: Emisor = new Emisor();
	private sucursalToSend: Sucursal = new Sucursal();
	private activitiesToSend: ActividadEconomicaEmisor[] = [];
	private foliosToSend: FolioRequest[] = [];
	private plantillasToSend: PlantillaFileRequest[] = [];
	//private configsEmisionToSend: ConfiguracionEmision[] = [];
	private certificadoToSend: CertificadoRequest = new CertificadoRequest();
	public logoRequest: LogoEmisorRequest = new LogoEmisorRequest();
	private logoWizardRequest: LogoWizardRequest = new LogoWizardRequest();

	// public tipoDteFullSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
	// public tiposDteAgrupados = JSON.parse(this.localStorage.getDteTypesAgrupados()) as TipoDteAgrupado[];
	public selectedTiposDte: TipoDte[] = [];
	public submittedChildrenFormsArray: boolean[] = [false, false, false, false];
	public fileToUploadArray: File[] = [];

	public certificadoFileToUpload: File;
	public certificadoFilesArray: File[] = [];
	public logosFilesArray: any[] = [];
	public tipoPlantillasSource: PlantillaImpresion[] = [];
	public formatoPapelSource: FormatoPapel[] = JSON.parse(this.localStorage.getFormatoPapel()) as FormatoPapel[];
	private cuentaSiiFormGroup: FormGroup;
	public loading: boolean = false;

	private modalSIIHelp: NgbModalRef;

	locale = {
		startDate: moment(),
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD/MM/YYYY",
	};
	public validDate: boolean = true;
	public user: SessionUser;
	public isSuperAdmin: boolean;

	constructor(
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
		private authService: AuthService,
		private utilities: UtilitiesService,
		private tokenService: TokenStorageService,
		private localStorage: LocalStorageService,
		private auth: AuthService,
		public permissions: PermissionService,
		private ref: ChangeDetectorRef,
		private emisorService: EmisorService,
		private certificadoService: CertificadosDigitalesService,
		private router: Router
	) {
		this.user = this.tokenService.getUser() as SessionUser;
		this.isSuperAdmin = this.tokenService.getUser().superAdmin;
	}

	ngOnInit(): void {
		this.setSuperForm();
		this.economicActivitiesSource = this.economicActivitiesSource.map((ae) => ({
			...ae,
			displayText: `${ae.codigo} - ${ae.descripcion}`,
		}));
	}
	ngAfterViewInit() {
		this.stepperInit();
	}

	next($event, childFormRef?) {
		//console.log($event, this.stepper, this.emisorSuperGroup);
		let stepperObj: any = this.stepper;
		if (this.checkValidityOfCurrentForm(stepperObj._currentIndex)) {
			console.log("valido");
			this.stepper.next();
		}
	}

	previous() {
		this.stepper.previous();
	}

	onSubmit() {
		//console.log(this.emisorBaseToSend,this.foliosToSend,this.plantillasToSend,this.emisorSuperGroup);
		if (this.isSuperAdmin) {
			this.emisorSuperGroup.controls.facturacion.clearValidators();
		}
		this.emisorSuperGroup.updateValueAndValidity();
		if (this.emisorSuperGroup.valid) {
			this.getEmisorBaseFormData();
			this.getSucursalFormData();
			this.getActivitiesFormData();
			this.getCertificadoFormData();
			this.getLogoFormData();
			let wizardReq: EmisorWizardRequest = new EmisorWizardRequest();
			wizardReq.emisorBase = this.emisorBaseToSend;
			wizardReq.sucursal = this.sucursalToSend;
			wizardReq.actividades = this.activitiesToSend;
			wizardReq.certificado = this.certificadoToSend;
			wizardReq.firstTime = !this.user.configured;
			//wizardReq.folios = this.foliosToSend;
			//wizardReq.plantillasImpresionRequests = this.plantillasToSend;
			if (this.logoRequest.logoFile) {
				wizardReq.logoEmisor = this.logoWizardRequest;
			}
			//wizardReq.configuraciones = this.configsEmisionToSend;
			wizardReq.usuarioId = (this.tokenService.getUser() as SessionUser).id;
			//this.requestEmisorData(wizardReq);
			this.formatPreviewText(wizardReq);
		} else {
			Swal.fire({
				icon: "error",
				title: "Faltan datos",
				html: "Favor revisar los datos faltantes",
				confirmButtonText: "Entendido",
			});
		}
	}

	formatPreviewText(wizardReq: EmisorWizardRequest) {
		let previewActivities = "<ul>";
		wizardReq.actividades.forEach((act) => {
			let actividad = this.economicActivitiesSource.find((activity) => activity.actividadEconomicaId == act.actividadEconomicaId);
			let listElement = "<li>" + actividad.descripcion + "</li>";
			previewActivities += listElement;
		});
		previewActivities += "</ul>";
		let previewTiposDte = "<ul>";
		this.selectedTiposDte.forEach((tipo) => {
			let listTipo = "<li>" + tipo.nombre + "</li>";
			previewTiposDte += listTipo;
		});
		var ResumenTipoDoc = "";

		previewTiposDte += "</ul>";
		if (this.selectedTiposDte.length > 0) {
			ResumenTipoDoc = "<li ><b>Tipos Documento a Emitir</b>: " + previewTiposDte + "</li>";
		}
		let total = "";

		let previewHtml =
			'<ul style="text-align:left;">' +
			"<li><b>Nombre</b>: " +
			wizardReq.emisorBase.razonSocial +
			"</li>" +
			"<li><b>Rut</b>: " +
			wizardReq.emisorBase.rutString +
			"</li>" +
			"<li><b>Giro</b>: " +
			wizardReq.emisorBase.giro +
			"</li>" +
			"<li><b>Dirección</b>: " +
			wizardReq.emisorBase.dirFact +
			"," +
			wizardReq.emisorBase.ciudad +
			"</li>" +
			"<li><b>Correo</b>: " +
			wizardReq.emisorBase.correoFact +
			"</li>" +
			"<li><b>Sucursal</b>: " +
			wizardReq.sucursal.nombre +
			"," +
			wizardReq.sucursal.direccion +
			"</li>" +
			ResumenTipoDoc +
			"<li><b>Actividades Economicas</b>: " +
			previewActivities +
			"</li>" +
			"</ul>" +
			'<ul style="text-align:left;">' +
			total +
			"</ul>";

		Swal.fire({
			icon: "question",
			title: "Resumen de creación de empresa",
			html: previewHtml,
			confirmButtonText: "Crear Empresa",
			showCancelButton: true,
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.value) {
				this.requestEmisorData(wizardReq);
			}
		});
	}

	requestEmisorData(ewr: EmisorWizardRequest) {
		this.loading = true;
		this.emisorService.processWizardEmisor(ewr).subscribe(
			(result) => {
				if (result.status == 200) {
					if (result.message == "webpay") {
						var form = document.createElement("form");
						form.method = "post";
						form.target = "_blank";
						form.action = result.data.url;
						document.body.appendChild(form);

						var input = document.createElement("input");
						input.type = "hidden";
						input.name = "token_ws";
						input.value = result.data.token;
						form.appendChild(input);
						form.submit();

						//const paymentId = result.data.url; // Asume que el token se utiliza como identificador de pago

						this.pollPaymentStatus(result.data.token).subscribe((paymentCompleted) => {
							this.loading = false;
							this.ref.detectChanges();
							if (paymentCompleted) {
								// Ejecutar la verificación de Transbank y continuar con la generación de la factura
								Swal.fire({
									icon: "success",
									title: "Empresa Ingresada",
									text: "Se ha ingresado exitosamente la empresa " + ewr.emisorBase.razonSocial + ". Debes iniciar sesión de nuevo para operar con tu empresa.",
								}).then((result) => {
									if (result.value) {
										this.authService.logout();
									}
								});
							} else {
								Swal.fire({
									icon: "warning",
									title: "Empresa Ingresada",
									text: "No obtuvimos respuesta del pago. Si se efectuó será procesado en breves minutos.",
								}).then((result) => {
									if (result.value) {
										this.authService.logout();
									}
								});
							}
						});
					} else {
						this.loading = false;

						this.ref.detectChanges();

						//this.emisorSuperGroup.reset();
						Swal.fire({
							icon: "success",
							title: "Empresa Ingresada",
							text: "Se ha ingresado exitosamente la empresa " + ewr.emisorBase.razonSocial + ". Debes iniciar sesión de nuevo para operar con tu empresa.",
						});

						if (this.user.configured) {
							this.router.navigateByUrl("/emisores");
						} else {
							this.auth.logout();
						}
					}
				}
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Ingresar Emisor",
					text: "No se ha podido ingresar al emisor " + ewr.emisorBase.razonSocial + ", intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	private pollPaymentStatus(token: string, maxAttempts: number = 15, pollingInterval: number = 3000): Observable<boolean> {
		let completed = false;
		let attempts = 0;
		return interval(pollingInterval).pipe(
			takeWhile(() => !completed && attempts < maxAttempts),
			switchMap(() => {
				attempts++;
				return this.emisorService.verifyTransbank(token);
			}),
			map((response: Response<boolean>) => {
				return response.data;
			}),
			tap((success: boolean) => {
				if (success) {
					completed = true;
					this.loading = false;
				}
			}),
			last(),
			catchError(() => of(false))
		);
	}

	requestAttachedData() {
		//request for sucursal/branch-office, economic activities
		let sucursalRequest = this.emisorService.addBranchOffice(this.sucursalToSend);
		let activitiesRequest = this.emisorService.toAssignEconomicActivity(this.activitiesToSend);
	}
	
	getEmisorBaseFormData() {
		this.emisorBaseToSend.rutString = this.emisorSuperGroup.controls.base.get("rutString").value;
		this.emisorBaseToSend.razonSocial = this.emisorSuperGroup.controls.base.get("razonSocial").value;
		this.emisorBaseToSend.giro = this.emisorSuperGroup.controls.base.get("giro").value;
		this.emisorBaseToSend.ciudad = this.emisorSuperGroup.controls.base.get("ciudad").value;
		this.emisorBaseToSend.comuna = this.emisorSuperGroup.controls.base.get("comuna").value;
		this.emisorBaseToSend.telefono = this.emisorSuperGroup.controls.base.get("telefono").value;
		this.emisorBaseToSend.dirFact = this.emisorSuperGroup.controls.base.get("dirFact").value;
		this.emisorBaseToSend.correoFact = this.emisorSuperGroup.controls.base.get("correoFact").value;
		this.emisorBaseToSend.fechaResol = this.emisorSuperGroup.controls.base.get("fechaResol").value;
		this.emisorBaseToSend.nroResol = this.emisorSuperGroup.controls.base.get("nroResol").value;
		var ambiente = this.emisorSuperGroup.controls.base.get("ambiente").value;
		this.emisorBaseToSend.ambiente = ambiente.ambienteId;
		this.emisorBaseToSend.correoPar = this.emisorBaseToSend.correoFact;
		this.emisorBaseToSend.dirPart = this.emisorBaseToSend.dirFact;
		const rut = this.emisorBaseToSend.rutString;

		this.emisorBaseToSend.rut = parseInt(rut.includes("-") ? rut.substring(0, rut.length - 2) : rut.substring(0, rut.length - 1));
		this.emisorBaseToSend.dv = this.emisorBaseToSend.rutString.substring(this.emisorBaseToSend.rutString.length - 1);
		const rutRepresentateString = this.emisorSuperGroup.controls.base.get("rutRepresentanteLegal").value;
		this.emisorBaseToSend.representanteLegalRutString = rutRepresentateString;

		this.emisorBaseToSend.representanteLegalRut = parseInt(rutRepresentateString.includes("-") ? rutRepresentateString.substring(0, rutRepresentateString.length - 2) : rutRepresentateString.substring(0, rutRepresentateString.length - 1));
		this.emisorBaseToSend.representanteLegalDv = rutRepresentateString.substring(rutRepresentateString.length - 1);
		this.emisorBaseToSend.passwordSii = this.emisorSuperGroup.controls.base.get("passwordSii").value;
		this.emisorBaseToSend.unidadSII = this.emisorSuperGroup.controls.base.get("unidadSII").value;
	}

	getSucursalFormData() {
		this.sucursalToSend.nombre = this.emisorSuperGroup.controls.sucursal.get("nombreSucursal").value;
		this.sucursalToSend.direccion = this.emisorSuperGroup.controls.sucursal.get("direccionSucursal").value;
	}

	getActivitiesFormData() {
		this.activitiesToSend = [];
		this.economicActivitiesList.forEach((element) => {
			var newActivity = new ActividadEconomicaEmisor();
			newActivity.actividadEconomicaId = element.actividadEconomicaId;
			newActivity.emisorId = this.emisorBaseToSend.emisorId;
			this.activitiesToSend.push(newActivity);
		});
	}

	stepperInit() {
		//console.log(this.stepperRef);
		this.stepper = new Stepper(this.stepperRef.nativeElement, {
			linear: false,
			animation: true,
		});
	}

	setSuperForm() {
		this.setEmisorBaseGroup();
		this.setSucursalFormGroup();
		this.setActivityForm();
		this.setCertificadoFormGroup();
		this.setLogoFormGroup();
		this.emisorSuperGroup = this.formBuilder.group({
			base: this.emisorBasicFormGroup,
			sucursal: this.emisorSucursalesFormGroup,
			actividades: this.economicActivityFormGroup,
			logo: this.logoFormGroup,
			certificado: this.certificadoFormGroup,
			configsEmision: this.configEmisionFormArray,
			pago: this.pagoFormGroup
		});
	}

	setEmisorBaseGroup() {
		this.emisorBasicFormGroup = this.formBuilder.group({
			rutString: ["", [Validators.required, Validators.minLength(8), VerificacionRutValidator]],
			razonSocial: [, Validators.required],
			giro: [, [Validators.required, Validators.maxLength(500)]],
			ciudad: [, [Validators.required, Validators.maxLength(100)]],
			comuna: [, [Validators.required, Validators.maxLength(100)]],
			telefono: [, Validators.required],
			dirFact: [, Validators.required],
			correoFact: ["", [Validators.required, SingleEmailValidator, Validators.maxLength(100)]],
			fechaResol: [, Validators.required],
			nroResol: [0, Validators.required],
			ambiente: [this.environmentType[0]],
			rutRepresentanteLegal: ["", [Validators.required, Validators.minLength(8), VerificacionRutValidator]],
			passwordSii: [,],
			unidadSII: [, [Validators.required, Validators.maxLength(30)]],
		});
	}

	// SUCURSAL RELATED FUNCTIONS
	setSucursalFormGroup() {
		this.emisorSucursalesFormGroup = this.formBuilder.group({
			nombreSucursal: [, Validators.required],
			direccionSucursal: [, Validators.required],
		});
	}

	detectChangeReferenceDateAdd(event) {
		this.validDate = true;
		if (event.startDate != null && event.endDate != null) {
			this.emisorSuperGroup.controls.base.get("fechaResol").patchValue(moment(event.startDate).toDate());
		} else {
			if (event.target && event.target.value) {
				this.validDate = moment(event.target.value, "DD/MM/YYYY", true).isValid();
				if (this.validDate) {
					let newDate = moment(event.target.value, "DD-MM-YYYY").format("L");
					if (moment(this.emisorSuperGroup.controls.base.get("fechaResol").value).format("L") != newDate) {
						let dateToSave = moment(event.target.value, "DD-MM-YYYY");
						this.emisorSuperGroup.controls.base.get("fechaResol").patchValue(dateToSave.toDate());
					}
				}
			}
		}
	}

	openModalSII(modalRef) {
		this.modalSIIHelp = this.modalService.open(modalRef, {
			windowClass: "",
			size: "xl",
			centered: true,
		});
	}
	// ACTIVITY RELATED FUNCTIONS
	setActivityForm() {
		this.economicActivityFormGroup = this.formBuilder.group({
			actividadEconomica: [],
			listaActividadesEconomicas: [, Validators.required],
		});
	}

	filtro() {
		if (this.economicActivityFormGroup.get("actividadEconomica").value) {
			let selected = this.economicActivityFormGroup.get("actividadEconomica").value as ActividadEconomica;
			this.economicActivitiesSource = this.economicActivitiesSource.filter((ae) => ae.actividadEconomicaId == selected.actividadEconomicaId);
			this.economicActivitiesSource = this.economicActivitiesSource.map((ae) => ({
				...ae,
				displayText: `${ae.codigo} - ${ae.descripcion}`,
			}));
		} else {
			this.getEconomicActivitiesList();
		}
	}

	getEconomicActivitiesList() {
		this.economicActivitiesSource = JSON.parse(this.localStorage.getActividadesEconomicas()) as ActividadEconomica[];
		this.economicActivitiesSource = this.economicActivitiesSource.map((ae) => ({
			...ae,
			displayText: `${ae.codigo} - ${ae.descripcion}`,
		}));
	}

	addEconomicActivityToList() {
		let activity = this.economicActivityFormGroup.get("actividadEconomica").value as ActividadEconomica;
		if (activity) {
			let alreadyExist = this.economicActivitiesList.some((act) => act.actividadEconomicaId == activity.actividadEconomicaId);
			if (this.economicActivitiesList.length >= 4) {
				Swal.fire({
					icon: "info",
					titleText: "Cantidad Máxima",
					text: "Solo se pueden asignar 4 actividades económicas por emisor",
				});
				this.clear();
				this.getEconomicActivitiesList();
				return;
			} else if (this.economicActivitiesList.includes(activity) || alreadyExist || this.economicActivitiesList.length > 4) {
				Swal.fire({
					icon: "info",
					titleText: "La Actividad Económica ya esta asignada",
					text: "La Actividad económica ya se encuentra asignada.",
				});
				this.clear();
				this.getEconomicActivitiesList();
				return;
			} else {
				this.economicActivitiesList.push(activity);
			}
			this.clear();
			this.getEconomicActivitiesList();
			if (this.economicActivitiesList.length > 0) {
				this.economicActivityFormGroup.controls.listaActividadesEconomicas.patchValue(this.economicActivitiesList);
			}
		}

		this.ref.detectChanges();
	}
	clear() {
		this.economicActivityFormGroup.get("actividadEconomica").setValue(undefined);
	}
	deleteItem(index) {
		this.economicActivitiesList.splice(index, 1);
		if (this.economicActivitiesList.length == 0) {
			this.economicActivityFormGroup.controls.listaActividadesEconomicas.reset();
		}
	}

	//certificado functions
	setCertificadoFormGroup() {
		if (this.user.isPartner) {
			this.certificadoFormGroup = this.formBuilder.group({
				file: [],
				rutString: [],
				password: [],
			});
		} else {
			this.certificadoFormGroup = this.formBuilder.group({
				file: [, Validators.required],
				rutString: [, [Validators.required, VerificacionRutValidator, Validators.minLength(8)]],
				password: [, Validators.required],
			});
		}
	}

	getCertificadoFormData() {
		try {
			let file = (this.emisorSuperGroup.controls.certificado as FormGroup).controls.file.value as File;

			if (file) {
				this.utilities
					.convertFileToB64String(file)
					.then((data) => {
						this.certificadoToSend.fileB64 = data.split(",")[1];
					})
					.catch((error) => {
						console.log("Error al convertir el archivo a Base64:", error);
					});
			}

			let certificado: CertificadoDigital = new CertificadoDigital();
			certificado.rutString = (this.emisorSuperGroup.controls.certificado as FormGroup).controls.rutString.value;
			certificado.password = (this.emisorSuperGroup.controls.certificado as FormGroup).controls.password.value;
			this.certificadoToSend.certificadoString = JSON.stringify(certificado);
		} catch (error) {}
	}

	onFileChangeCER(files) {
		if (files.addedFiles[0].type != "application/x-pkcs12") {
			this.certificadoFormGroup.get("file").reset();
			this.certificadoFileToUpload = null;
			//this.filenameToUpload = this.fileCriteria.fileNameLabel;
			Swal.fire({
				icon: "error",
				text: "El archivo debe ser PFX",
			});
			return;
		} else {
			this.certificadoFilesArray.push(...files.addedFiles);
			this.certificadoFormGroup.controls.file.patchValue(files.addedFiles[0]);
			this.certificadoFileToUpload = files.addedFiles[0];
			let file = (this.emisorSuperGroup.controls.certificado as FormGroup).controls.file.value as File;
			this.utilities.convertFileToB64String(file).then((data) => {
				this.certificadoToSend.fileB64 = data.split(",")[1];
			});
		}
	}
	onRemoveCertificaddoArray(file: File): void {
		if (this.certificadoFilesArray.length > 1 || (this.certificadoFilesArray.length === 1 && this.certificadoFilesArray[0] !== file)) {
			const index = this.certificadoFilesArray.indexOf(file);
			if (index > -1) {
				this.certificadoFilesArray.splice(index, 1);
			}
		}
	}

	//logo functions

	setLogoFormGroup() {
		this.logoFormGroup = this.formBuilder.group({
			logoFile: [],
			propiedadLogo: [],
			plantillaImpresionPreview: [],
		});
	}

	onLogoFileChange(event) {
		var fileExtensionName = this.getLogoName(event);
		if (event.rejectedFiles[0] || (fileExtensionName.toLowerCase() != "png" && fileExtensionName.toLowerCase() != "jpg" && fileExtensionName.toLowerCase() != "jpeg")) {
			Swal.fire({
				icon: "error",
				title: "Error de Archivo",
				text: "El archivo ingresado no es de formato de imagen .JPG o .PNG, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo",
			});
		}

		this.logosFilesArray.push(...event.addedFiles);
		if (this.logosFilesArray.length > 1) {
			this.logosFilesArray.shift();
		}
		this.logoFormGroup.controls.logoFile.patchValue(this.logosFilesArray[0]);
		this.logoRequest.logoCompleteFile = this.logosFilesArray[0];
		this.logoRequest.nombre = event.addedFiles[0].name;
		this.loading = true;
		this.utilities
			.convertFileToB64String(this.logoRequest.logoCompleteFile)
			.then((data) => {
				this.logoRequest.logoFile = data.split(",")[1];
				this.loading = false;
				this.ref.detectChanges();
			})
			.catch(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	getLogoName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}

	onRemoveLogo(event) {
		this.logosFilesArray.splice(this.logosFilesArray.indexOf(event), 1);
		this.logoFormGroup.controls.logoFile.patchValue(null);
		this.logoRequest.logoFile = null;
	}

	getLogoFormData() {
		let logoFile = this.emisorSuperGroup.controls.logo.get("logoFile").value;
		this.logoRequest.logoCompleteFile = logoFile;
		this.logoRequest.fechaSubida = new Date();
		let propiedad = this.emisorSuperGroup.controls.logo.get("propiedadLogo").value;
		this.logoRequest.propiedadEnum = propiedad && propiedad.id ? propiedad.id : 0;
		this.logoRequest.propiedadEnumNombre = propiedad && propiedad.nombre ? propiedad.nombre : "contain";
		this.logoWizardRequest.fileB64 = this.logoRequest.logoFile;
		if (this.logoRequest.logoFile) {
			this.logoWizardRequest.logoString = JSON.stringify(this.logoRequest);
		}
	}

	setAddress() {
		let direccionTemp = this.emisorSuperGroup.controls.base.get("dirFact").value;
		this.emisorSuperGroup.controls.sucursal.get("direccionSucursal").setValue(direccionTemp);
		this.emisorSuperGroup.controls.sucursal.get("nombreSucursal").setValue("Casa Matriz");
	}

	setRepresentanteLegal() {
		let rutRep = this.emisorSuperGroup.controls.base.get("rutRepresentanteLegal").value;
		this.emisorSuperGroup.controls.certificado.get("rutString").setValue(rutRep);
	}

	//generic functions of wizard

	checkValidityOfCurrentForm(stepIndex) {
		this.submittedChildrenFormsArray[stepIndex] = true;
		switch (stepIndex) {
			case 0:
				return this.emisorSuperGroup.controls.base.valid && this.validDate;
			case 1:
				return this.emisorSuperGroup.controls.sucursal.valid;
			case 2:
				return this.emisorSuperGroup.controls.actividades.valid;
			case 3:
				return this.user.isPartner ? true : this.emisorSuperGroup.controls.certificado.valid;
			case 4:
				return this.logoRequest.logoFile ? this.emisorSuperGroup.controls.logo.valid : true; //Si el logo esta agregado, valida el formulario, de lo contrario se considera correcto
		}
	}

	validateCertificate($event) {
		if (!this.user.isPartner) {
			this.getCertificadoFormData();
			this.loading = true;
			if (!this.emisorSuperGroup.controls.certificado.valid) {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "info",
					title: "Validación de Certificado",
					text: "Datos Incompletos, reintente nuevamente",
				});
			} else if (this.emisorSuperGroup.controls.certificado.valid && this.certificadoToSend.fileB64 != undefined) {
				this.certificadoService.validateCertificate(this.certificadoToSend).subscribe(
					(res) => {
						this.loading = false;
						this.ref.detectChanges();
						if (res.data) this.next($event);
						else
							Swal.fire({
								icon: "info",
								title: "Validación de Certificado",
								text: res.message,
							});
					},
					(err) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Validación de Certificado",
							text: "No fue posible validar password del certificado. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(err.error),
						});
					}
				);
			}
		} else {
			this.next($event);
		}
	}
	comprobar($event) {
		if (this.emisorSuperGroup.controls.base.get("rutString").valid) {
			this.loading = true;
			this.emisorService.findEmisorByRut($event.currentTarget.value).subscribe(
				(res) => {
					this.loading = false;
					this.ref.detectChanges();
					if (res.data) {
						Swal.fire({
							icon: "info",
							title: "Comprobación Rut Emisor",
							text: res.message,
						});
						this.emisorSuperGroup.controls.base.get("rutString").setValue("");
					}
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Comprobación Rut Emisor",
						text: "Error al comprobar existencia de rut del emisor en el sistema",
					});
				}
			);
		}
	}


	filtroEmpresasFacturacion() {
		const selectedEmpresa = this.emisorSuperGroup.controls.facturacion.get("empresaSeleccionada").value;
		if (selectedEmpresa != null && selectedEmpresa != undefined) {
			this.emisorSuperGroup.controls.facturacion.get("rutString").setValue(selectedEmpresa.rut + "-" + selectedEmpresa.dv);
			this.emisorSuperGroup.controls.facturacion.get("razonSocial").setValue(selectedEmpresa.razonSocial);
			this.emisorSuperGroup.controls.facturacion.get("giro").setValue(selectedEmpresa.giro);
			this.emisorSuperGroup.controls.facturacion.get("comuna").setValue(selectedEmpresa.comuna);
			this.emisorSuperGroup.controls.facturacion.get("telefono").setValue(selectedEmpresa.telefono);
			this.emisorSuperGroup.controls.facturacion.get("dirFact").setValue(selectedEmpresa.dirFact);
			this.emisorSuperGroup.controls.facturacion.get("correoFact").setValue(selectedEmpresa.correoFact);
			this.ref.detectChanges();
		}
	}

	onValueChangeComboEmpresa(value: any) {
		if (value === null || value === undefined) {
			// El valor del ng-select ha sido limpiado
			this.emisorSuperGroup.controls.facturacion.get("rutString").setValue(this.emisorSuperGroup.controls.base.get("rutString").value);
			this.emisorSuperGroup.controls.facturacion.get("razonSocial").setValue(this.emisorSuperGroup.controls.base.get("razonSocial").value);
			this.emisorSuperGroup.controls.facturacion.get("giro").setValue(this.emisorSuperGroup.controls.base.get("giro").value);
			this.emisorSuperGroup.controls.facturacion.get("comuna").setValue(this.emisorSuperGroup.controls.base.get("comuna").value);
			this.emisorSuperGroup.controls.facturacion.get("telefono").setValue(this.emisorSuperGroup.controls.base.get("telefono").value);
			this.emisorSuperGroup.controls.facturacion.get("dirFact").setValue(this.emisorSuperGroup.controls.base.get("dirFact").value);
			this.emisorSuperGroup.controls.facturacion.get("correoFact").setValue(this.emisorSuperGroup.controls.base.get("correoFact").value);
			this.emisorSuperGroup.controls.facturacion.get("empresaSeleccionada").setValue(null);
			// Aquí puedes añadir la lógica que desees ejecutar cuando el control se limpia
		}
	}
}
