<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar [config]="configLocal.barraBusqueda" [useAccordionMode]="false" [fechaInicio]="configLocal.barraBusqueda.fechaInicio" [fechaFin]="configLocal.barraBusqueda.fechaFin" [nombreCampoDatePicker]="configLocal.barraBusqueda.nombreCampoDatePicker" (busqueda)="searchElementsByFilter($event)" [filtroEmisor]="true" [filtroSucursal]="true" [emisorClearable]="false" [sucursalClearable]="true"> </app-search-bar>
				<section class="row">
					<div class="col-8">
						<div class="card card-custom">
							<div style="text-align: center" class="mr-5 ml-5 pr-2 pl-2">
								<apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [fill]="chartOptions.fill" [stroke]="chartOptions.stroke" [tooltip]="chartOptions.tooltip" [xaxis]="chartOptions.xaxis"></apx-chart>
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card card-custom gutter-b card-stretch">
							<!--begin::Header-->
							<div class="card-header border-0 pt-1">
								<div class="card-title">
									<div class="card-label">
										<div class="font-weight-bolder">Resumen de datos</div>
										<div class="font-size-sm text-muted mt-2">Según tus filtros de búsqueda</div>
									</div>
								</div>
							</div>
							<!--end::Header-->
							<!--begin::Body-->
							<div class="card-body d-flex flex-column px-0">
								<!--begin::Items-->
								<div class="flex-grow-1 card-spacer-x">
									<!--begin::Item-->
									<div class="d-flex align-items-center justify-content-between mb-5">
										<div class="d-flex align-items-center mr-2">
											<div class="symbol symbol-40 symbol-light mr-3 flex-shrink-0 d-none d-xl-block">
												<div class="symbol-label">
													<img src="assets/media/svg/flaticon/calculator.svg" alt="" class="h-50" />
												</div>
											</div>
											<div>
												<a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Total Ventas</a>
												<div class="font-size-sm text-muted font-weight-bold mt-1">Suma de totales de todos los DTE</div>
											</div>
										</div>
										<div class="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">{{ totalDTE | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
									</div>
									<!--end::Item-->
									<!--begin::Item-->
									<div class="d-flex align-items-center justify-content-between mb-5">
										<div class="d-flex align-items-center mr-2">
											<div class="symbol symbol-40 symbol-light mr-3 flex-shrink-0 d-none d-xl-block">
												<div class="symbol-label">
													<img src="assets/media/svg/flaticon/abacus.svg" alt="" class="h-50" />
												</div>
											</div>
											<div>
												<a href="#" class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder">Cantidad Emitidos</a>
												<div class="font-size-sm text-muted font-weight-bold mt-1">El máximo de tu plan es de {{ maxPlan }}</div>
											</div>
										</div>
										<div class="label label-light label-inline font-weight-bold text-dark-50 py-4 px-3 font-size-base">{{ cantidadDTE }} DTEs</div>
									</div>
									<!--end::Item-->
								</div>
								<!--end::Items-->
							</div>
							<!--end::Body-->
						</div>
					</div>
				</section>
				<section class="row mt-4">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">
									{{ configLocal.table.title
									}}{{
										filterDescription
											? " -
                                    " + filterDescription
											: ""
									}}
								</h4>
								<div class="alert-text">Incluye los siguientes documentos: Factura (33), Factura Exenta (34), Boleta (39), Boleta Exenta (41), Nota Débito (56), Factura Exportación (110), Nota Débito Exportación (111)<small></small></div>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
								</div>
							</div>
							<div class="card-content">
								<div class="card-body">
									<!-- seccion tabla -->
									<ngx-datatable
										#tableConsolidado
										*ngIf="componentStarted"
										class="material"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[selectionType]="'checkbox'"
										[selected]="selected"
										(selected)="onSelect($event)"
										[rowIdentity]="getId"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(tableConsolidado, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false">
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!isRowExpanded(row)" [class.datatable-icon-down]="isRowExpanded(row)" title="Expand/Collapse Row" (click)="toggleExpandRow(row)"> </a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-row-detail [rowHeight]="rowHeightValue" #myDetailRow>
											<ng-template let-row="row" ngx-datatable-row-detail-template>
												<div class="table-container">
													<div class="row ml-4 pl-3">
														<div class="cell">Fecha</div>
														<div class="cell-largo">Tipo DTE</div>
														<div class="cell">Emitidos</div>
														<div class="cell">Anulados</div>
														<div class="cell">Total Neto</div>
														<div class="cell">Total Exento</div>
														<div class="cell">Total IVA</div>
														<div class="cell">Total</div>
														<!-- Otras celdas -->
													</div>
													<ng-container *ngFor="let group of row.detalle">
														<div class="row ml-4 pl-3">
															<div class="cell">{{ group.fecha | date : "dd/MM/yyyy" }}</div>
															<div class="cell-largo">{{ group.tipoDTE }}</div>
															<div class="cell">
																<a [ngClass]="{ link: group.cantidadEmitidos > 0 }" (click)="group.cantidadEmitidos > 0 ? toMantenedorFromDetail(group, false) : null">{{ group.cantidadEmitidos }}</a>
															</div>
															<div class="cell">
																<a [ngClass]="{ link: group.cantidadAnulados > 0 }" (click)="group.cantidadAnulados > 0 ? toMantenedorFromDetail(group, true) : null">{{ group.cantidadAnulados }}</a>
															</div>
															<div class="cell">{{ group.totalNeto | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
															<div class="cell">{{ group.totalExento | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
															<div class="cell">{{ group.totalIva | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
															<div class="cell">{{ group.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
															<!-- Otras celdas -->
														</div>
													</ng-container>
												</div>

												<!-- <div *ngFor="let detailRow of row.rows" class="text-wrap">Fecha: {{ detailRow.fecha }} Emitidos: {{ detailRow.cantidadEmitidos }}</div> -->
											</ng-template>
										</ngx-datatable-row-detail>

										<ngx-datatable-column name="fecha" [flexGrow]="1" [sortable]="true" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="date-table-alignment">Fecha</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="date-table-alignment">{{ row.fecha | date : "dd/MM/yyyy" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="tipoDTE" [flexGrow]="1.6" [sortable]="true" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo DTE</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.tiposDTE }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="CantidadEmitidos" [flexGrow]="1" [sortable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Emitidos</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment"
													><a [ngClass]="{ link: row.emitidos > 0 }" (click)="row.emitidos > 0 ? toMantenedorFromGeneral(row, false) : null">{{ row.emitidos }}</a>
												</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="CantidadAnulados" [flexGrow]="1" [sortable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Anulados</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment"
													><a [ngClass]="{ link: row.anulados > 0 }" (click)="row.anulados > 0 ? toMantenedorFromGeneral(row, false) : null">{{ row.anulados }}</a></span
												></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="neto" [flexGrow]="1" [sortable]="true" [headerClass]="'number-table-alignment'" [cellClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Total Neto</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.totalNeto | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="exento" [flexGrow]="1" [sortable]="true" [headerClass]="'number-table-alignment'" [cellClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Total Exento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.totalExento | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="iva" [flexGrow]="1" [sortable]="true" [headerClass]="'number-table-alignment'" [cellClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Total IVA</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.totalIva | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="total" [flexGrow]="1" [sortable]="true" [headerClass]="'number-table-alignment'" [cellClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Total</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableConsolidado.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>
