import { Directive, Input } from "@angular/core";
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const SameWordEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
   const receiverEmail: string[] =
      control.get("receiverEmail").value == null
         ? []
         : control
              .get("receiverEmail")
              .value.split(",")
              .map((e) => e.trim())
              .filter((item) => item);
   const ccEmail: string[] =
      control.get("ccEmail").value == null
         ? []
         : control
              .get("ccEmail")
              .value.split(",")
              .map((e) => e.trim())
              .filter((item) => item);
   const ccoEmail: string[] =
      control.get("ccoEmail").value == null
         ? []
         : control
              .get("ccoEmail")
              .value.split(",")
              .map((e) => e.trim())
              .filter((item) => item);
   let emailRepeated: boolean = receiverEmail.some((email) => {
      return ccEmail.includes(email) || ccoEmail.includes(email);
   });
   let ccRepeated = ccEmail.some((email) => {
      return receiverEmail.includes(email) || ccoEmail.includes(email);
   });
   let ccoRepeated = ccoEmail.some((email) => {
      return receiverEmail.includes(email) || ccEmail.includes(email);
   });
   // console.log(receiverEmail,ccEmail,ccoEmail,emailRepeated,ccRepeated,ccoRepeated);
   // console.log((receiverEmail.length>0 || ccEmail.length>0 || ccoEmail.length>0),(emailRepeated || ccRepeated || ccoRepeated));
   return (receiverEmail.length > 0 || ccEmail.length > 0 || ccoEmail.length > 0) && (emailRepeated || ccRepeated || ccoRepeated) ? { mailRepeated: true } : null;
};
