export class ComisionRecargo {
   nroItem: number;
   tipoId: string;
   tipoSigla: string;
   tipoNombre: string;
   glosa: string;
   tasa?: number;
   iva: number;
   total: number;
   neto: number;
   cantidad: number;
   exento: number;
   subtotal: number;
}
