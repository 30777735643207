import { Identifiers } from "./Identifiers";

export class AuthenticationRequest {
   identifiers: Identifiers;
   password: string;

   constructor(identifierInput: Identifiers, passwordInput: string) {
      this.identifiers = identifierInput;
      this.password = passwordInput;
   }
}
