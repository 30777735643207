export const environment = {
	production: true,
	appVersion: "v726demo1",
	USERDATA_KEY: "authf649fc9a5f55",
	isMockEnabled: true,
	apiUrl: "api",
	backend_server: "http://backend.hannasoft.cl/api",
	taxPercentage: 0.19,
	country_code_port: 41,
	checkSession: true,
	msal_client_key: "",
	msal_authority: "",
	msal_return_url: "",
	msal_postlogout_redirect_uri: "",
	msal_graph_endpoint: "",
	title: "Portal Facturación",
	idleTimeout: 900,
	logoutTimeout: 30,
};
 