<form [formGroup]="productForm" (ngSubmit)="onSubmit()" #productFormRef="ngForm">
    <div class="modal-body">
        <div *ngIf="!useTaxes || useProductAndTaxes">
            <!-- product inputs -->
            <div class="form-group position-relative mx-auto">
                <div class="row">
                    <label for="" class="col-3 label-control">Nombre Producto</label>
                    <div class="col-9">
                        <input type="text" class="form-control" id="nombre" formControlName="nombre"
                            [attr.disabled]="detailMode?detailMode:null">
                        <div *ngIf="productFormRef.submitted &&( productForm.get('nombre').invalid ||  productForm.get('nombre').dirty)"
                            class="invalid-feedback">
                            <div *ngIf="productForm.get('nombre').errors?.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group position-relative mx-auto">
                <div class="row">
                    <label for="" class="col-3 label-control">Unidad de Medida</label>
                    <div class="col-9">
                        <input type="text" class="form-control" id="unidadMedida" formControlName="unidadMedida"
                            [attr.disabled]="detailMode?detailMode:null">
                        <div *ngIf="productFormRef.submitted &&( productForm.get('unidadMedida').invalid ||  productForm.get('unidadMedida').dirty)"
                            class="invalid-feedback">
                            <div *ngIf="productForm.get('unidadMedida').errors?.required">Campo requerido</div>
                            <div *ngIf="productForm.get('unidadMedida').errors?.pattern">No ingrese caracteres numéricos
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group position-relative mx-auto">
                <div class="row">
                    <label for="" class="col-3 label-control">Código de Barra</label>
                    <div class="col-9">
                        <input type="text" class="form-control" id="codigoBarra" formControlName="codigoBarra"
                            [attr.disabled]="detailMode?detailMode:null">
                        <div *ngIf="productFormRef.submitted &&( productForm.get('codigoBarra').invalid ||  productForm.get('codigoBarra').dirty)"
                            class="invalid-feedback">
                            <div *ngIf="productForm.get('codigoBarra').errors?.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group position-relative mx-auto">
                <div class="row">
                    <label for="" class="col-3 label-control">Precio</label>
                    <div class="col-9">
                        <input type="number" class="form-control" id="precio" formControlName="precio"
                            [attr.disabled]="detailMode?detailMode:null">
                        <div *ngIf="productFormRef.submitted &&( productForm.get('precio').invalid ||  productForm.get('precio').dirty)"
                            class="invalid-feedback">
                            <div *ngIf="productForm.get('precio').errors?.required">Campo requerido</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group position-relative mx-auto">
                <div class="row">
                    <label for="" class="col-3 label-control">Exento</label>
                    <div class="col-9">
                        <input type="checkbox" class="input-checkbox" id="exento" formControlName="exento"
                            [attr.disabled]="detailMode?detailMode:null">
                        <div *ngIf="useProductAndTaxes && productForm.get('exento').value && taxesList.length>0"
                            class="invalid-feedback">
                            Si crea un producto exento, los impuestos que haya seleccionado no se agregarán
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="useTaxes || useProductAndTaxes">
            <!-- end product inputs -->
            <h4>Agregar impuesto específico</h4>
            <!-- tax selector -->
            <div class="mt-5 form-group position-relative mx-auto">
                <div class="row pt-5">
                    <label for="" class="col-3 label-control">Impuestos</label>
                    <div class="col-7">
                        <ng-select [clearable]="false" bindLabel="nombre" [searchable]="false" [selectOnTab]="false"
                            [items]="taxesSource" formControlName="impuesto"
                            placeholder="Seleccione impuesto a aplicar">
                        </ng-select>
                    </div>
                    <div class="ml-5">
                        <button type="button" class="btn btn-success" (click)="addTaxToList()"
                            *ngIf="!detailMode && !(taxesList && taxesList.length >=2)">Agregar</button>
                    </div>
                </div>
            </div>
            <!-- end tax selector -->
            <!-- tax list -->
            <div class="alert alert-warning mr-3" role="alert" *ngIf="taxesList.length >=2">
                Este producto ya tiene asignado 2 impuestos, si desea agregar otro, favor eliminar alguno de la lista
                actual.
            </div>
            <div *ngIf="taxesList.length>0 && (useTaxes || useProductAndTaxes)">
                <h4>Impuestos asociados</h4>
                <table class="table">
                    <thead>
                        <tr>
                            <td>Nombre</td>
                            <td>%</td>
                            <td>Acción</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tax of taxesList;let index=index">
                            <td>{{tax.nombre}}</td>
                            <td>{{tax.valor}}</td>
                            <td>
                                <a ngbTooltip="Quitar " class="primary edit mr-1 mrless" (click)="deleteTax(index)">
                                    <div class="icon-circle bg-danger text-center align-middle pt-info-button">
                                        <fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
                                    </div>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- end tax list -->
        </div>


    </div>
    <div class="modal-footer">
        <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" *ngIf="!detailMode">
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">{{productToEdit?"Guardar":"Generar"}}</span>
        </button>
        <button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
            <span class="d-none d-lg-block">Cerrar</span>
        </button>
    </div>
</form>