export class FolioFileObject {
   codigoSii: number;
   tipoDte: string;
   fechaCaf: Date;
   desde: string;
   hasta: string;
   rutEmisor: string;
   ambiente: number;
   sucursalId: string;
   emisorId: string;
}
