import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Response } from "src/app/shared/models/Response";
import { Observable } from "rxjs";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Page } from "../shared/models/Page";
import { EmisorFilter } from "../models/Emisor/EmisorFilter";
import { Emisor } from "../models/Emisor/Emisor";
import { Sucursal } from "../models/Sucursal";
import { SucursalFilter } from "../models/Usuario/SucursalFilter";
import { ActividadEconomicaEmisor } from "../models/ActividadEconomicaEmisor";
import { ConfiguracionEmision } from "../models/ConfiguracionEmision";
import { ActividadEconomica } from "../models/ActividadEconomica";
import { ConfiguracionEmisionFilter } from "../models/ConfiguracionEmisionFilter";
import { PersonaAutorizada } from "../models/PersonaAutorizada";
import { EmisorWizardRequest } from "../models/Emisor/EmisorWizardRequest";
import { PersonaAutorizadaScrapper } from "../models/PersonaAutorizadaScrapper";
import { LogoEmisorRequest } from "../models/Emisor/LogoEmisorRequest";
import { LogoEmisor } from "../models/Emisor/LogoEmisor";
import { PlantillaImpresion } from "../models/PlantillaImpresion/PlantillaImpresion";
import { EmisorPassRequest } from "../models/Emisor/EmisorPassRequest";
import { UpdateEmisorEnvironment } from "../models/Emisor/UpdateEmisorEnvironment";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
	providedIn: "root",
})
export class EmisorService {
	private REST_API_SERVER_PAYMENT = environment.backend_server + "/pago/returntransbank";
	private REST_API_SERVER = environment.backend_server + "/Emisor";
	private REST_API_SERVER_BRANCH_OFFICE = environment.backend_server + "/Sucursal";
	private LOGO_EMISOR_SERVICE = environment.backend_server + "/LogoEmisor";
	private PLANTILLA_SERVICE = environment.backend_server + "/plantilla";
	constructor(private http: HttpClient, private tokenService: TokenStorageService) {}

	public getData(): Observable<Response<Emisor[]>> {
		return this.http.get<Response<Emisor[]>>(this.REST_API_SERVER + "/list");
	}

	public getDataByPage(filtro: EmisorFilter, page: Page, usuarioId: String): Observable<PagedResponse<Emisor>> {
		return this.http.get<PagedResponse<Emisor>>(
			this.REST_API_SERVER +
				"/list/filter/" +
				usuarioId +
				"?RazonSocial=" +
				(filtro.razonSocial != undefined ? filtro.razonSocial : "") +
				"&Rut=" +
				(filtro.rut != undefined ? filtro.rut : "") +
				"&Comuna=" +
				(filtro.comuna != undefined ? filtro.comuna : "") +
				"&Ciudad=" +
				(filtro.ciudad != undefined ? filtro.ciudad : "") +
				"&Giro=" +
				(filtro.giro != undefined ? filtro.giro : "") +
				"&ResellerId=" +
				(filtro.resellerId != undefined ? filtro.resellerId : "") +
				"&Activo=" +
				filtro.activo +
				"&pageNumber=" +
				page.pageNumber +
				"&pageSize=" +
				page.pageSize
		);
	}

	public getSucursalDataByPage(filtro: SucursalFilter, page: Page): Observable<PagedResponse<Sucursal>> {
		//console.log(filtro);

		return this.http.get<PagedResponse<Sucursal>>(this.REST_API_SERVER_BRANCH_OFFICE + "/list/filter/?Nombre=" + (filtro.nombre != undefined ? filtro.nombre : "") + "&Direccion=" + (filtro.direccion != undefined ? filtro.direccion : "") + "&EmisorId=" + (filtro.emisorId != undefined ? filtro.emisorId : "") + "&Activo=" + (filtro.activo != null ? filtro.activo : "") + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}
	deactivateEmisor(idEmisor: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivate/" + idEmisor);
	}

	activateEmisor(idEmisor: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activate/" + idEmisor);
	}

	updateEmisor(Emisor: Emisor): Observable<Response<Emisor>> {
		return this.http.post<Response<Emisor>>(this.REST_API_SERVER + "/update", Emisor);
	}

	addEmisor(ent: Emisor): Observable<Response<string>> {
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/add", ent);
	}

	processWizardEmisor(ent: EmisorWizardRequest): Observable<Response<any>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/processWizardEmisor", ent);
	}

	verifyTransbank(token: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER_PAYMENT + "/verify?token_ws=" + token);
	}

	deactivateBranchOffice(id: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER_BRANCH_OFFICE + "/deactivate/" + id);
	}

	activateBranchOffice(id: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER_BRANCH_OFFICE + "/activate/" + id);
	}

	updateBranchOffice(ent: Sucursal): Observable<Response<Sucursal>> {
		return this.http.post<Response<Sucursal>>(this.REST_API_SERVER_BRANCH_OFFICE + "/update", ent);
	}
	setActualBranchOffice(sucursalId: string, usuarioId: string): Observable<Response<Sucursal>> {
		return this.http.get<Response<Sucursal>>(this.REST_API_SERVER_BRANCH_OFFICE + "/update-principal?sucursalId=" + sucursalId + "&usuarioId=" + usuarioId);
	}

	addBranchOffice(ent: Sucursal): Observable<Response<Sucursal>> {
		return this.http.post<Response<Sucursal>>(this.REST_API_SERVER_BRANCH_OFFICE + "/add", ent);
	}
	getEconomicActivitiesByEmisor(idEmisor: string): Observable<Response<ActividadEconomica[]>> {
		return this.http.get<Response<ActividadEconomica[]>>(this.REST_API_SERVER + "/getEconomicActivitiesByEmisor/" + idEmisor);
	}

	toAssignEconomicActivity(economicActivities: ActividadEconomicaEmisor[]): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/toAssignEconomicActivity", economicActivities);
	}
	deleteEconomicActivity(economicActivity: ActividadEconomicaEmisor): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/toAssignEconomicActivity", economicActivity);
	}

	getConfigByEmisor(idEmisor: string): Observable<Response<ConfiguracionEmision[]>> {
		return this.http.get<Response<ConfiguracionEmision[]>>(this.REST_API_SERVER + "/getConfigByEmisor/" + idEmisor);
	}
	toAssignConfig(config: ConfiguracionEmision): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/toAssignConfig", config);
	}
	updateConfig(config: ConfiguracionEmision): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateConfig", config);
	}
	deactivateConfig(config: ConfiguracionEmision): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/deactivateConfig", config);
	}
	activateConfig(config: ConfiguracionEmision): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/activateConfig", config);
	}

	public getDataConfigByPage(filtro: ConfiguracionEmisionFilter, page: Page): Observable<PagedResponse<ConfiguracionEmision>> {
		return this.http.get<PagedResponse<ConfiguracionEmision>>(this.REST_API_SERVER + "/listConfig/filter?TipoDoc=" + (filtro.tipoDteId != undefined ? filtro.tipoDteId : "") + "&Activo=" + filtro.activo + "&EmisorId=" + (filtro.emisorId != undefined ? filtro.emisorId : "") + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	updateAuthorizedPersonScrapper(authPersonScrapper: PersonaAutorizadaScrapper): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateAuthorizedPersonRCV", authPersonScrapper);
	}

	getAuthorizedPersonScrapper(emisorId: string): Observable<Response<PersonaAutorizadaScrapper>> {
		return this.http.get<Response<PersonaAutorizadaScrapper>>(this.REST_API_SERVER + "/getAuthorizedPersonsRCVByEmisor/" + emisorId);
	}

	// addEmisorByWizard(emisorWizardRequest:EmisorWizardRequest):Observable<Response<any>>{
	//   console.log(emisorWizardRequest);
	//   return this.http.post<Response<any>>(this.REST_API_SERVER+'',emisorWizardRequest);
	// }

	findEmisorByRut(rutEmisor: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/findEmisorByRut/" + rutEmisor);
	}

	//logo related endpoints
	emisorLogosList(emisor: Emisor): Observable<Response<LogoEmisor[]>> {
		return this.http.get<Response<LogoEmisor[]>>(this.LOGO_EMISOR_SERVICE + "/list?issuingId=" + emisor.emisorId);
	}

	addLogo(request: LogoEmisorRequest) {
		let formData = new FormData();
		formData.append("file", request.logoCompleteFile);
		formData.append("logoString", JSON.stringify(request));
		return this.http.post<Response<boolean>>(this.LOGO_EMISOR_SERVICE + "/add", formData);
	}

	updateLogo(request) {
		let formData = new FormData();
		formData.append("file", request.logoCompleteFile);
		formData.append("logoString", JSON.stringify(request));
		return this.http.post<Response<boolean>>(this.LOGO_EMISOR_SERVICE + "/updateLogo", formData);
	}

	activateLogo(logo: LogoEmisor) {
		return this.http.get(this.LOGO_EMISOR_SERVICE + "activate/" + logo.logoId);
	}

	deactivateLogo(logo: LogoEmisor) {
		return this.http.get(this.LOGO_EMISOR_SERVICE + "deactivate/" + logo.logoId);
	}

	deleteLogo(logo: LogoEmisor) {
		return this.http.get(this.LOGO_EMISOR_SERVICE + "/delete/" + logo.logoId);
	}

	getTipoPlantillasDisponibles(emisor: Emisor): Observable<Response<PlantillaImpresion[]>> {
		return this.http.get<Response<PlantillaImpresion[]>>(this.LOGO_EMISOR_SERVICE + "/getTemplateByIssuing?issuingId=" + emisor.emisorId);
	}

	getLogoPreviewNacional(logorequest: LogoEmisorRequest): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("logob64", logorequest.logoFile);
		return this.http.post<Response<string>>(this.PLANTILLA_SERVICE + "/pdf/sample" + "?plantillaId=" + logorequest.tipoPlantillaIdPreview + "&emisorId=" + logorequest.emisorId + "&logoType=" + logorequest.propiedadEnumNombre + "&logo=" + true, formData);
	}

	getLogoPreviewExporta(logorequest: LogoEmisorRequest): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("logob64", logorequest.logoFile);
		return this.http.post<Response<string>>(this.PLANTILLA_SERVICE + "/pdf/sample/exporta" + "?plantillaId=" + logorequest.tipoPlantillaIdPreview + "&logoType=" + logorequest.propiedadEnumNombre + "&logo=" + true, formData);
	}

	updatePasswordSii(request: EmisorPassRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/changePass", request);
	}

	changeAmbient(req: UpdateEmisorEnvironment): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateEnvironment", req);
	}
}
