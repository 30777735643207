<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
<swal #notesSwal title="Operación" text="Operación realizada" icon="info"></swal>

<!-- <h1>{{fileIdValue ? fileIdValue: 'Sin id por ruta'}}</h1> -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar
					[criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal"
					[config]="configLocal.barraBusqueda"
					[filtroDTE]="true"
					[filtroEstadoSii]="true"
					[filtroEstadoDte]="true"
					[filtroImportado]="true"
					[filtroReutilizado]="true"
					[filtroAnulados]="true"
					[nombreCampoDatePicker]="configLocal.barraBusqueda.nombreCampoDatePicker"
					(busqueda)="searchElementsByFilter($event)"
					[useAccordionMode]="true"
					[filtroEmisor]="true"
					[filtroSucursal]="true"
					[mostrarListaCompletaEmisores]="isSuperAdmin"
					[filtroAcuseEmitidos]="true"
					[emisorClearable]="false"
					[sucursalClearable]="false">
				</app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">
									{{ configLocal.table.title
									}}{{
										filterDescription
											? " -
                                    " + filterDescription
											: ""
									}}
								</h4>
								<div *ngIf="maxDTE > 0" class="card-toolbar">DTEs: {{ cantidadDTE }} |{{ maxDTE }}</div>
								<div *ngIf="maxDTE < 0" class="card-toolbar">DTEs: {{ cantidadDTE }} | Ilimitado</div>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="tryMassiveRouseConfirm()" ngbTooltip="Solo se pueden reutilizar folios de documentos que no estén aceptados por el sii" *ngIf="permissions.getPermission('40-18-46').asignado">
										<i class="flaticon-download-1"></i>
										Reutilizar Folios Masivo
									</button>
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar" *ngIf="permissions.getPermission('40-18-16').asignado">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
									<div ngbDropdown>
										<button ngbDropdownToggle id="dropdownConciliacion" class="btn btn-info float-right mr-5 mt-5" *ngIf="permissions.getPermission('40-18-42').asignado">
											<i class="flaticon-refresh"></i>
											Importar Dte Sii
										</button>
										<div ngbDropdownMenu aria-labelledby="dropdownConciliacion">
											<button *ngIf="permissions.getPermission('40-18-42').asignado" ngbDropdownItem (click)="openDatePickerImportedDte(datePickerImportedDteSiiModal, true)">Importación Mensual</button>
											<button *ngIf="permissions.getPermission('40-18-42').asignado" ngbDropdownItem (click)="openDatePickerImportedDte(datePickerImportedDteSiiModal, false)">Importación por Dia</button>
										</div>
									</div>
									<div ngbDropdown>
										<button ngbDropdownToggle id="dropdownDescargas" class="btn btn-primary float-right mr-5 mt-5" *ngIf="permissions.getPermission('40-18-12').asignado">
											<i class="flaticon-download"></i>
											Descarga Documentos
										</button>
										<div ngbDropdownMenu aria-labelledby="dropdownDescargas">
											<button ngbDropdownItem (click)="multipleDocumentDownload('pdf')">Descarga PDF</button>
											<button ngbDropdownItem (click)="multipleDocumentDownload('xml')">Descarga XML</button>
										</div>
									</div>
									<div ngbDropdown>
										<button ngbDropdownToggle id="dropdownDtes" class="btn btn-warning float-right mr-5 mt-5">
											<i class="flaticon-add-circular-button"></i>
											Generar DTE
										</button>
										<div ngbDropdownMenu aria-labelledby="dropdownDescargas">
											<button ngbDropdownItem (click)="navigateToFacturador(0)" *ngIf="permissions.getPermission('40-18-10').asignado">Factura Individual</button>
											<button ngbDropdownItem (click)="navigateToFacturador(1)" *ngIf="permissions.getPermission('40-18-33').asignado">Boleta Individual</button>
											<!-- <button ngbDropdownItem (click)="navigateToFacturador(5)" *ngIf="permissions.getPermission('40-18-35').asignado">Liquidación Factura</button> -->
										</div>
									</div>
									<!-- <button class="btn btn-primary float-right mr-5 mt-5" (click)="informarArrayDtes()"                                            
                                    *ngIf="permissions.getPermission('40-18-37').asignado"
                                >
                                    <i class="flaticon-upload-1"></i>
                                    Informar
                                </button> -->
								</div>
							</div>
							<div class="card-content">
								<div class="card-body">
									<!-- seccion tabla -->
									<ngx-datatable
										#table
										*ngIf="componentStarted"
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[selectionType]="'checkbox'"
										[selected]="selected"
										(selected)="onSelect($event)"
										[rowIdentity]="getId"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
											<ng-template ngx-datatable-header-template let-value="value">
												<input type="checkbox" class="customCheckbox" [checked]="selectedAllItemsInPage[page.pageNumber]" (change)="selectAll($event)" />
											</ng-template>
											<ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
												<input type="checkbox" class="customCheckbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="nombreTipoDte" [flexGrow]="1" [sortable]="true" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoDte }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="folio" [flexGrow]="1.6" [sortable]="true" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Folio</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.folio }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Cliente" [flexGrow]="2" [sortable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Cliente</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.rutReceptorDv
														? row.rutReceptorDv
														: "No
                                                    disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="4.5" [sortable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Razón Social</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><div class="text-limited">
													<span class="text-wrap">
														{{ row.razonSocial ? row.razonSocial : "No disponible" }}
													</span>
												</div></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="fecha" [flexGrow]="2" [sortable]="true" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="date-table-alignment">Fecha</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="date-table-alignment">{{ row.fecha | date : "dd/MM/yyyy" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="total" [flexGrow]="2" [sortable]="true" [headerClass]="'number-table-alignment'" [cellClass]="getCellClass" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Monto</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column [cellTemplate]="semaforo" [sortable]="false" name="Estado" [flexGrow]="2" [resizeable]="false"></ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Enviar correo" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Enviar_correo" (click)="openDocumentSenderModal(dteSenderModal, row)" *ngIf="!row.importado && permissions.getPermission('40-18-20').asignado && !row.folioReutilizado">
													<!-- <span aria-label="Enviar correo" [inlineSVG]="'./assets/media/svg/icons/Communication/Outgoing-mail.svg'" cacheSVG="true" class="svg-icon svg-icon-3x svg-icon-success"></span> -->
													<div class="icon-circle bg-success text-center align-middle pt-email-button">
														<fa-icon icon="envelope" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<div ngbDropdown class="dropdownBotoneraLinea" container="body">
													<a [routerLink]="" title="Descarga Documento" ngbTooltip="Descarga Documento" class="success edit mr-1 mrless" ngbDropdownToggle id="dropdownDoc" container="body" *ngIf="!row.importado && permissions.getPermission('40-18-22').asignado">
														<div class="icon-circle bg-danger text-center align-middle pt-file-button">
															<fa-icon icon="file-pdf" class="fa-lg text-white"></fa-icon>
														</div>
													</a>

													<div ngbDropdownMenu aria-labelledby="dropdownDoc">
														<button ngbDropdownItem (click)="downloadPDF(row)" *ngIf="!row.importado && permissions.getPermission('40-18-22').asignado">Descarga PDF</button>
														<button ngbDropdownItem (click)="downloadPDFCedible(row, true)" *ngIf="!row.importado && permissions.getPermission('40-18-23').asignado && row.cedibleHabilitado">Descarga PDF Cedible</button>
														<button ngbDropdownItem (click)="downloadXml(row)" *ngIf="!row.importado && permissions.getPermission('40-18-24').asignado">Descargar XML</button>
														<button ngbDropdownItem (click)="downloadMassiveFile(row)" *ngIf="!row.importado && permissions.getPermission('40-12').asignado && row.importacionMasivaId != null">Descargar Archivo Importación Masiva</button>
													</div>
												</div>

												<a [routerLink]="" ngbTooltip="Trazabilidad" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Trazabilidad" (click)="openTraceShowModal(dteTracesModal, row)" *ngIf="permissions.getPermission('40-18-26').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-list-button">
														<fa-icon icon="list-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Detalles" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Detalles" (click)="detailOpenModal(dteDetailModal, row)" *ngIf="permissions.getPermission('40-18-28').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<span class="badge badge-light imported-badge font-italic" *ngIf="row.importado">Importado</span>

												<div ngbDropdown class="dropdownBotoneraLinea" container="body">
													<a *ngIf="!row.importado && !row.anulado && (permissions.getPermission('40-18-30').asignado || permissions.getPermission('40-18-31').asignado) && (row.notaCreditoHabilitada || row.notaDebitoHabilitada) && row.codigoTipoDte != 46" [routerLink]="" container="body" ngbTooltip="Generar Nota" class="danger edit mr-1 mrless" ngbDropdownToggle id="dropdownNotas">
														<div class="icon-circle bg-danger text-center align-middle pt-null-button">
															<fa-icon icon="file-alt" class="fa-lg text-white"></fa-icon>
														</div>
													</a>
													<div ngbDropdownMenu aria-labelledby="dropdownNotas">
														<button ngbDropdownItem (click)="openAnularDteModal(anularNCDteModal, row)" *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-30').asignado && row.notaCreditoHabilitada && row.codigoTipoDte != 46">Generar Nota Credito</button>
														<button ngbDropdownItem (click)="openAnularDteModal(anularNDDteModal, row)" *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-31').asignado && row.notaDebitoHabilitada && row.codigoTipoDte == 61">Generar Nota Debito</button>
														<button ngbDropdownItem (click)="openAnularDteModal(anularNDInterestsDTEModal, row)" *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-31').asignado && row.notaDebitoHabilitada && row.codigoTipoDte != 61 && row.codigoTipoDte != 39">Generar Nota Debito</button>
													</div>
												</div>

												<a *ngIf="!row.importado && row.cesionHabilitada && permissions.getPermission('40-18-36').asignado && row.codigoTipoDte == 33 && !row.cedidoCompleto" [routerLink]="" container="body" ngbTooltip="Cesión de Factura" class="danger edit mr-1 mrless" (click)="openCesionDte(cesionDteModal, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-null-button">
														<fa-icon icon="hand-holding-usd" class="fa-lg text-white"> </fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Reenviar Documento al SII" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Reenviar_Documento_al_SII" (click)="resendDte(row)" *ngIf="!row.importado && permissions.getPermission('40-18-38').asignado && row.estadoSii == 8 && !row.folioReutilizado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="recycle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="!row.importado && permissions.getPermission('40-18-39').asignado && (row.estadoSii == 5 || row.estadoSii == 7) && row.codigoTipoDte != 39 && row.codigoTipoDte != 41 && !row.folioReutilizado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Reenviar Email Validación"
													[swal]="{
														title: 'Confirmación',
														text: 'El correo de validación se enviará a la casilla registrada en el sii, ¿Desea Continuar?',
														confirmButtonText: 'Continuar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="resendEmailSii(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="redo" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a
													*ngIf="!row.importado && !row.folioReutilizado && permissions.getPermission('40-18-44').asignado && row.reutilizarFoliohabilitado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Reutilizar Folio"
													[swal]="{
														title: 'Confirmación',
														text: 'El folio volvera a estar disponible, ¿Desea Continuar?',
														confirmButtonText: 'Continuar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="reuse(row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<span class="badge badge-light imported-badge font-italic" *ngIf="row.folioReutilizado">Folio reutilizado</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla seccion tabla -->

									<!-- tabla movil -->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[selectionType]="'checkbox'"
										[selected]="selected"
										(selected)="onSelect($event)"
										[rowIdentity]="getId"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow class="row-detail-width">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Tipo documento: {{ row.nombreTipoDte }} , Cliente: {{ row.razonSocial ? row.razonSocial : "No disponible" }} , Monto: {{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }} , Fecha: {{ row.fecha | date : "dd/MM/yyyy" }}</div>
											</ng-template>
										</ngx-datatable-row-detail>

										<ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
											<ng-template ngx-datatable-header-template let-value="value">
												<input type="checkbox" class="customCheckbox" [checked]="selectedAllItemsInPage[page.pageNumber]" (change)="selectAll($event)" />
											</ng-template>
											<ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
												<input type="checkbox" class="customCheckbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="3.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Documento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment"> Folio: {{ row.folio }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column [cellTemplate]="semaforo" [sortable]="false" name="Estado" [flexGrow]="2" [resizeable]="false"></ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="8" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Enviar correo" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Enviar_correo" (click)="openDocumentSenderModal(dteSenderModal, row)" *ngIf="!row.importado && permissions.getPermission('40-18-20').asignado">
													<div class="icon-circle bg-success text-center align-middle pt-email-button">
														<fa-icon icon="envelope" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<div ngbDropdown class="dropdownBotoneraLinea" container="body">
													<a [routerLink]="" title="Descarga Documento" ngbTooltip="Descarga Documento" class="success edit mr-1 mrless" ngbDropdownToggle id="dropdownDoc" container="body" *ngIf="!row.importado && permissions.getPermission('40-18-22').asignado">
														<div class="icon-circle bg-danger text-center align-middle pt-file-button">
															<fa-icon icon="file-pdf" class="fa-lg text-white"></fa-icon>
														</div>
													</a>

													<div ngbDropdownMenu aria-labelledby="dropdownDoc">
														<button ngbDropdownItem (click)="downloadPDF(row)" *ngIf="!row.importado && permissions.getPermission('40-18-22').asignado">Descarga PDF</button>
														<button ngbDropdownItem (click)="downloadPDFCedible(row, true)" *ngIf="!row.importado && permissions.getPermission('40-18-23').asignado && row.cedibleHabilitado">Descarga PDF Cedible</button>
														<button ngbDropdownItem (click)="downloadXml(row)" *ngIf="!row.importado && permissions.getPermission('40-18-24').asignado">Descargar XML</button>
													</div>
												</div>
												<a [routerLink]="" ngbTooltip="Trazabilidad" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Trazabilidad" (click)="openTraceShowModal(dteTracesModal, row)" *ngIf="permissions.getPermission('40-18-26').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-list-button">
														<fa-icon icon="list-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Detalles" class="primary edit mr-1 mrless" id="mantenedor-dte-emitidos.component_Detalles" (click)="detailOpenModal(dteDetailModal, row)" *ngIf="permissions.getPermission('40-18-28').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<span class="badge badge-light imported-badge font-italic" *ngIf="row.importado">Importado</span>

												<a *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-30').asignado && row.notaCreditoHabilitada && row.codigoTipoDte == 110" [routerLink]="" container="body" ngbTooltip="Generar Nota Credito Exportación" class="danger edit mr-1 mrless" (click)="openAnularDteModal(anularNCDteExportModal, row)">
													<div class="icon-circle bg-danger text-center align-middle pt-null-button">
														<fa-icon icon="file-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-31').asignado && row.notaDebitoHabilitada && row.codigoTipoDte == 112" [routerLink]="" container="body" ngbTooltip="Generar Nota Debito Exportación" class="danger edit mr-1 mrless" (click)="openAnularDteModal(anularNCDteExportModal, row)">
													<div class="icon-circle bg-danger text-center align-middle pt-null-button">
														<fa-icon icon="file-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-30').asignado && row.notaCreditoHabilitada && row.codigoTipoDte != 46" [routerLink]="" container="body" ngbTooltip="Generar Nota Credito" class="danger edit mr-1 mrless" (click)="openAnularDteModal(anularNCDteModal, row)">
													<div class="icon-circle bg-danger text-center align-middle pt-null-button">
														<fa-icon icon="file-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-31').asignado && row.notaDebitoHabilitada && row.codigoTipoDte == 61" [routerLink]="" container="body" ngbTooltip="Generar Nota Debito" class="danger edit mr-1 mrless" (click)="openAnularDteModal(anularNDDteModal, row)">
													<div class="icon-circle bg-danger text-center align-middle pt-null-button">
														<fa-icon icon="file-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a *ngIf="!row.importado && !row.anulado && permissions.getPermission('40-18-31').asignado && row.notaDebitoHabilitada && row.codigoTipoDte != 61" [routerLink]="" container="body" ngbTooltip="Generar Nota Debito" class="danger edit mr-1 mrless" (click)="openAnularDteModal(anularNDInterestsDTEModal, row)">
													<div class="icon-circle bg-danger text-center align-middle pt-null-button">
														<fa-icon icon="file-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a *ngIf="!row.importado && row.cesionHabilitada && permissions.getPermission('40-18-36').asignado && row.codigoTipoDte == 33 && !row.cedidoCompleto" [routerLink]="" container="body" ngbTooltip="Cesión de Factura" class="danger edit mr-1 mrless" (click)="openCesionDte(cesionDteModal, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-null-button">
														<fa-icon icon="hand-holding-usd" class="fa-lg text-white"> </fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #semaforo let-row="row">
	<app-semaforo-estado-dte [row]="row"></app-semaforo-estado-dte>
</ng-template>

<ng-template #dteDetailModal let-d="dismiss" id="detail-modal">
	<div class="modal-content">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Detalle de {{ dteToShow.nombreTipoDte }} - N° {{ dteToShow.folio }}</div>
				<div class="modal-wizard-desc">Detalles del documento seleccionado</div>
			</div>
		</div>
		<div class="modal-body">
			<div *ngIf="dteToShow.codigoTipoDte != 46" class="row form-group mx-auto" id="details-client">
				<h4>Datos del Cliente</h4>
				<div class="col-12">
					<div class="row">
						<table class="detail-table">
							<tr>
								<td>Rut</td>
								<td>{{ dteToShow.rutReceptorDv ? dteToShow.rutReceptorDv : dteToShow.receptorAsociado.rutString ? dteToShow.receptorAsociado.rutString : "No Disponible" }}</td>
							</tr>
							<tr>
								<td>Razón Social</td>
								<td>{{ dteToShow.razonSocial ? dteToShow.razonSocial : dteToShow.receptorAsociado.razonSocial ? dteToShow.receptorAsociado.razonSocial : "No disponible" }}</td>
							</tr>
							<tr>
								<td>Giro</td>
								<td>{{ dteToShow.receptorAsociado.giro ? dteToShow.receptorAsociado.giro : "No disponible" }}</td>
							</tr>
							<tr>
								<td>Comuna</td>
								<td>
									{{
										dteToShow.receptorAsociado.comuna
											? dteToShow.receptorAsociado.comuna
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Ciudad</td>
								<td>
									{{
										dteToShow.receptorAsociado.ciudad
											? dteToShow.receptorAsociado.ciudad
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Dirección</td>
								<td>
									{{
										dteToShow.receptorAsociado.dirFact
											? dteToShow.receptorAsociado.dirFact
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>
									{{
										dteToShow.receptorAsociado.correoFact
											? dteToShow.receptorAsociado.correoFact
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Fecha Emisión</td>
								<td>{{ dteToShow.fecha | date : "dd/MM/yyyy" }}</td>
							</tr>
							<tr *ngIf="dteToShow.fechaVencimiento">
								<td>Fecha Vencimiento</td>
								<td>{{ dteToShow.fechaVencimiento | date : "dd/MM/yyyy" }}</td>
							</tr>
							<tr *ngIf="dteToShow.estadoAcuse">
								<td>Estado Acuse</td>
								<td>{{ dteToShow.descripcionEstadoAcuse }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<hr />
			<div class="row form-group mx-auto" *ngIf="dteToShow.listaReferencias && dteToShow.listaReferencias.length > 0">
				<h4>Referencias</h4>
				<div class="col-12" *ngFor="let ref of dteToShow.listaReferencias">
					<!-- listado detalle referencias -->
					<div class="row">
						<div class="col-4">
							<span class="d-block">Fecha</span>
							<span class="d-block">Glosa</span>
						</div>
						<div class="col-8">
							<span class="d-block text-right">{{ ref.fecha | date : "dd/MM/yyyy HH:mm" }}</span>
							<span class="d-block text-right">{{ ref.glosa }}</span>
						</div>
					</div>
					<div class="row" *ngIf="ref.dte"></div>
				</div>
				<hr />
			</div>
			<div class="row form-group mx-auto" *ngIf="!dteToShow.importado">
				<h4>Detalles</h4>
				<div class="col-12">
					<div class="row" id="details-dte">
						<ng-container *ngFor="let detalle of dteToShow.detalleDte" class="col-12">
							<div class="col-9" *ngIf="dteToShow.codigoTipoDte != 43">
								<p>
									{{ detalle.nombre }}
								</p>
							</div>
							<div class="col-9" *ngIf="dteToShow.codigoTipoDte == 43">
								<p>{{ detalle.cantidad }} * {{ detalle.descripcion }}</p>
							</div>
							<div class="col-3">
								<p class="text-right">
									{{ detalle.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}
								</p>
							</div>
						</ng-container>
					</div>
					<hr />
				</div>
			</div>
			<div class="row form-group mx-auto" *ngIf="dteToShow.codigoTipoDte == 43">
				<h4>Comisiones y Otros Cargos</h4>
				<div class="col-12">
					<div class="row" id="details-dte">
						<ng-container *ngFor="let detalle of dteToShow.comisionesCargosDte" class="col-12">
							<div class="col-9">
								<p>
									{{ detalle.glosa }}
								</p>
							</div>
							<div class="col-3">
								<p class="text-right">
									{{ detalle.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}
								</p>
							</div>
						</ng-container>
					</div>
					<hr />
				</div>
			</div>
			<div class="row form-group mx-auto">
				<div class="col-12">
					<div class="row" id="details-total-dte">
						<div class="offset-4 col-5" *ngIf="dteToShow.descuentoGlobal > 0">
							<p class="text-right font-weight-bold">Descuento Global ( - )</p>
						</div>
						<div class="col-3" *ngIf="dteToShow.descuentoGlobal > 0">
							<p class="text-right font-weight-bold">
								{{ dteToShow.descuentoGlobal | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-6 col-3">
							<p class="text-right font-weight-bold">Neto</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.neto | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-6 col-3">
							<p class="text-right font-weight-bold">Iva</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.iva | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-6 col-3" *ngIf="dteToShow.tieneIvaTerceros && dteToShow.ivaTerceros > 0">
							<p class="text-right font-weight-bold">Iva Terceros</p>
						</div>
						<div class="col-3" *ngIf="dteToShow.tieneIvaTerceros && dteToShow.ivaTerceros > 0">
							<p class="text-right font-weight-bold">
								{{ dteToShow.ivaTerceros | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-6 col-3" *ngIf="dteToShow.tieneIvaTerceros && dteToShow.ivaPropio > 0">
							<p class="text-right font-weight-bold">Iva Propio</p>
						</div>
						<div class="col-3" *ngIf="dteToShow.tieneIvaTerceros && dteToShow.ivaPropio > 0">
							<p class="text-right font-weight-bold">
								{{ dteToShow.ivaPropio | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-4 col-5" *ngIf="dteToShow.impuestos > 0">
							<p class="text-right font-weight-bold">Impuestos Adicionales</p>
						</div>
						<div class="col-3" *ngIf="dteToShow.impuestos > 0">
							<p class="text-right font-weight-bold">
								{{ dteToShow.impuestos | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>

						<div class="offset-6 col-3">
							<p class="text-right font-weight-bold">Exento</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.exento | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-6 col-3" *ngIf="dteToShow.codigoTipoDte == 43">
							<p class="text-right font-weight-bold">Comisiones y Cargos</p>
						</div>
						<div class="col-3" *ngIf="dteToShow.codigoTipoDte == 43">
							<p class="text-right font-weight-bold">
								{{ dteToShow.comisionCargo | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-6 col-3">
							<p class="text-right font-weight-bold">Total</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #dteTracesModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Trazabilidad</h5>
		</div>
		<form #TrazaForm="ngForm">
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Documento:</label>
					<div class="col-9">
						<input type="text" value="{{ dteToShow.nombreTipoDte }} N° {{ dteToShow.folio }}" disabled name="ngModel" class="form-control" id="fecha" />
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<table class="table table-bordered" [hidden]="tracesToShow.length == 0">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Descripción</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let traza of tracesToShow">
								<td>
									{{ traza.fecha | date : "dd/MM/yyyy HH:mm" }}
								</td>
								<td style="overflow-wrap: anywhere">{{ traza.descripcion }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #dteSenderModal let-d="dismiss" let-c="close">
	<form [formGroup]="senderForm" (ngSubmit)="onSubmitSenderForm()" #senderFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Envío de documentos por Correo</div>
					<div class="modal-wizard-desc">Indique correo y seleccione los documentos a enviar</div>
				</div>
				<!-- <h5 class="modal-title" id="titleModalSender"></h5> -->
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" *ngIf="selected.length > 0 && selectedItem == undefined">
				<table class="table">
					<tr>
						<th>Folio</th>
						<th>Cliente</th>
						<th>Monto</th>
					</tr>
					<tr *ngFor="let dte of selected">
						<td>{{ dte.folio }}</td>
						<td>{{ dte.razonSocial }}</td>
						<td>{{ dte.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
				</table>
			</div>
			<div class="modal-body" *ngIf="selectedItem != undefined">
				<table class="table">
					<tr>
						<th>Folio</th>
						<th>Cliente</th>
						<th>Monto</th>
					</tr>
					<tr>
						<td>{{ selectedItem.folio }}</td>
						<td>{{ selectedItem.razonSocial }}</td>
						<td>{{ selectedItem.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
				</table>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto">
					<label for="email" class="col-3 label-control">Dirección de Correo</label>
					<div class="col-9">
						<input
							type="email"
							name="email"
							class="form-control"
							formControlName="receiverEmail"
							autocomplete="off"
							[ngClass]="{
								'is-invalid': (senderFormRef.submitted && senderForm.controls['receiverEmail'].invalid) || (senderForm.controls['receiverEmail'].touched && senderForm.controls['receiverEmail'].invalid)
							}" />
						<div class="invalid-feedback" *ngIf="senderFormRef.submitted && (senderForm.controls['receiverEmail'].invalid || senderForm.controls['receiverEmail'].touched || senderForm.controls['receiverEmail'].dirty)">
							<div *ngIf="senderForm.get('receiverEmail').errors?.required">Campo Obligatorio</div>
							<!-- <div *ngIf="senderForm.get('receiverEmail').errors?.email||senderForm.get('receiverEmail').errors?.pattern">Ingrese un email válido</div> -->
							<div *ngIf="senderForm.get('receiverEmail').hasError('toAddress')">Ingrese un email válido</div>
							<div *ngIf="senderForm.get('receiverEmail').errors?.maxlength">Ingrese menos de 320 carácteres</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<label for="ccEmail" class="col-3 label-control">CC</label>
					<div class="col-9">
						<input
							type="email"
							name="ccEmail"
							class="form-control"
							formControlName="ccEmail"
							autocomplete="new-password"
							[ngClass]="{
								'is-invalid': (senderFormRef.submitted && senderForm.controls['ccEmail'].invalid) || (senderForm.controls['ccEmail'].touched && senderForm.controls['ccEmail'].invalid)
							}" />
						<div class="invalid-feedback" *ngIf="senderFormRef.submitted && (senderForm.controls['ccEmail'].invalid || senderForm.controls['ccEmail'].touched || senderForm.controls['ccEmail'].dirty)">
							<div *ngIf="senderForm.get('ccEmail').errors?.required">Campo Obligatorio</div>
							<!-- <div *ngIf="senderForm.get('ccEmail').errors?.email||senderForm.get('ccEmail').errors?.pattern">Ingrese un email válido</div> -->
							<div *ngIf="senderForm.get('ccEmail').hasError('toAddress')">Ingrese un email válido</div>
							<div *ngIf="senderForm.get('ccEmail').errors?.maxLength">Ingrese menos de 320 carácteres</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<label for="ccoEmail" class="col-3 label-control">CCO</label>
					<div class="col-9">
						<input
							type="email"
							name="ccoEmail"
							class="form-control"
							formControlName="ccoEmail"
							autocomplete="new-password"
							[ngClass]="{
								'is-invalid': (senderFormRef.submitted && senderForm.controls['ccoEmail'].invalid) || (senderForm.controls['ccoEmail'].touched && senderForm.controls['ccoEmail'].invalid)
							}" />
						<div class="invalid-feedback" *ngIf="senderFormRef.submitted && (senderForm.controls['ccoEmail'].invalid || senderForm.controls['ccoEmail'].touched || senderForm.controls['ccoEmail'].dirty)">
							<div *ngIf="senderForm.get('ccoEmail').errors?.required">Campo Obligatorio</div>
							<!-- <div *ngIf="senderForm.get('ccoEmail').errors?.email||senderForm.get('ccoEmail').errors?.pattern">Ingrese un email válido</div> -->
							<div *ngIf="senderForm.get('ccoEmail').hasError('toAddress')">Ingrese un email válido</div>
							<div *ngIf="senderForm.get('ccoEmail').errors?.maxLength">Ingrese menos de 320 carácteres</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<div class="col-6">
						<div class="row">
							<div class="col-6 label-control mr-5" for="pdfCheckbox">Enviar PDF</div>
							<div class="col-5">
								<input type="checkbox" formControlName="sendPdfCheckbox" class="form-check-input" id="pdfCheckbox" />
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="row">
							<div class="col-5 label-control">Enviar XML</div>
							<div class="col-7">
								<input type="checkbox" formControlName="sendXmlCheckbox" class="form-check-input" id="xmlCheckbox" />
							</div>
						</div>
					</div>
					<div class="offset-3 col-9 pl-4">
						<!-- <div class="invalid-feedback-group invalid-feedback row pl-3" *ngIf="senderFormRef.submitted && (senderForm.controls['sendPdfCheckbox'].value == false && senderForm.controls['sendXmlCheckbox'].value == false)">
                            <div>Favor indicar por lo menos un documento a enviar</div>
                       </div> -->
						<div class="invalid-feedback-group invalid-feedback row pl-3" *ngIf="senderForm.errors?.mailRepeated && (senderForm.touched || senderForm.dirty || senderFormRef.submitted)">
							<div>Favor no repetir direcciones de correo</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Enviar</span>
					</button>
					<button type="button" class="btn btn-secondary ml-3" (click)="c('Cross click')">
						<span class="d-none d-lg-block">Cerrar</span>
					</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #anularNCDteModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Nota de Crédito de {{ nombreTipoNota }} para DTE</div>
				<div class="modal-wizard-desc">Indique tipo de Nota de Crédito e indique los detalles de la {{ nombreTipoNota }}</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<app-anulador-dte #anularDteComponent [receivedDte]="dteToNullify" [dteReferences]="dteToNullifyReferences" (sendCloseRequestEmitter)="closeAnularDteModal()" (newCreditNoteRequest)="newCreditNote($event)" (typeOfNullfySelected)="getNullifyTypeName($event)"></app-anulador-dte>
	</div>
</ng-template>
<ng-template #anularNCDteExportModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Nota de Crédito de DTE Exportación</div>
				<div class="modal-wizard-desc">Indique tipo de Nota de Crédito e indique los detalles</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<app-anulador-dte #anularDteComponent [receivedDte]="dteToNullify" [dteReferences]="dteToNullifyReferences" (sendCloseRequestEmitter)="closeAnularDteModal()" (newCreditNoteRequest)="newCreditNote($event)" (typeOfNullfySelected)="getNullifyTypeName($event)"></app-anulador-dte>
	</div>
</ng-template>
<ng-template #anularNDDteExportModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Nota de Débito de DTE Exportación</div>
				<div class="modal-wizard-desc">Indique tipo de Nota de Débito e indique los detalles</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<app-anulador-dte #anularDteComponent [receivedDte]="dteToNullify" [dteReferences]="dteToNullifyReferences" [DebitoMode]="true" (sendCloseRequestEmitter)="closeAnularDteModal()" (newDebitNoteRequest)="newDebitNoteNullification($event)"></app-anulador-dte>
	</div>
</ng-template>
<ng-template #anularNDDteModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Nota de Débito de DTE</div>
				<div class="modal-wizard-desc">Indique tipo de Nota de Débito e indique los detalles</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<app-anulador-dte #anularDteComponent [receivedDte]="dteToNullify" [dteReferences]="dteToNullifyReferences" [DebitoMode]="true" (sendCloseRequestEmitter)="closeAnularDteModal()" (newDebitNoteRequest)="newDebitNoteNullification($event)"></app-anulador-dte>
	</div>
</ng-template>

<ng-template #anularNDInterestsDTEModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Nota de Débito de DTE</div>
				<div class="modal-wizard-desc">Indique tipo de Nota de Débito e indique los detalles</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<app-anulador-dte #anularDteComponent [receivedDte]="dteToNullify" [dteReferences]="dteToNullifyReferences" [DebitoMode]="true" (sendCloseRequestEmitter)="closeAnularDteModal()" (newDebitNoteRequest)="newDebitNoteInterest($event)"></app-anulador-dte>
	</div>
</ng-template>
<ng-template #cesionDteModal let-d="dimiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Cesión de Factura</div>
				<div class="modal-wizard-desc">Indique los datos de la cesión del documento</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #datePickerImportedDteSiiModal let-d="dismiss">
	<div class="modal-content">
		<form (ngSubmit)="importDteSii(request)" #request="ngForm">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Importación Dte desde el SII</div>
					<div class="modal-wizard-desc">Indique fecha para lectura de datos en el S.I.I.</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto">
					<div class="col-4 label-control pt-3">Fecha</div>
					<div class="col-8">
						<input type="text" name="dateConciliacion" [maxDate]="todayString" ngxDaterangepickerMd [locale]="locale" (change)="detectChangeDate($event)" (input)="detectChangeDate($event)" [(ngModel)]="dateConciliacion" class="form-control" [singleDatePicker]="true" [autoApply]="true" [autocomplete]="false" [showDropdowns]="true" />
						<div *ngIf="request.submitted && (dateConciliacion.startDate == null || dateConciliacion == '' || !validDate)" class="invalid-feedback-group">
							<div *ngIf="dateConciliacion.startDate == null">Campo requerido</div>
							<div *ngIf="!validDate && locale.format == 'MM/YYYY'">Fecha inválida, use el formato mm/yyyy</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Importar</span>
					</button>
					<button type="button" class="btn btn-secondary ml-3" (click)="d('Cross click')">
						<span class="d-none d-lg-block">Cerrar</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
