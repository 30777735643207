export class SendInvoiceRequest {
   dteId: string;
   dteType: number;
   emailType: number;
   to: string[] = [];
   ccs?: string[] = [];
   ccos?: string[] = [];
   pdf: boolean;
   xml: boolean;
   IssuingId: string;
}
