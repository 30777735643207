<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<div class="card-title">Ingreso de Empresa</div>
							</div>
							<div class="card-content">
								<div class="card-body">
									<div id="stepper1" class="bs-stepper" #stepper1>
										<div class="bs-stepper-header">
											<div class="row">
												<div class="col-12">
													<div class="row">
														<div class="col-3">
															<div class="step" data-target="#form-emisor-base">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">1</span>
																	<span class="bs-stepper-label">Datos de Empresa</span>
																</button>
															</div>
														</div>
														<div class="col-3">
															<div class="step" data-target="#form-emisor-sucursales">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">2</span>
																	<span class="bs-stepper-label">Sucursal</span>
																</button>
															</div>
														</div>
														<div class="col-3">
															<div class="step" data-target="#form-emisor-actividades">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">3</span>
																	<span class="bs-stepper-label">Actividades Economicas</span>
																</button>
															</div>
														</div>
														<div class="col-3">
															<div class="step" data-target="#form-certificado">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">4</span>
																	<span class="bs-stepper-label">Certificado Digital</span>
																</button>
															</div>
														</div>
													</div>
												</div>
												<div class="col-12">
													<div class="row">
														<div class="col-3">
															<div class="step" data-target="#form-emisor-logo">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">5</span>
																	<span class="bs-stepper-label">Logo [Opcional]</span>
																</button>
															</div>
														</div>
														<div class="col-3">
															<div class="step" data-target="#form-pago">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">6</span>
																	<span class="bs-stepper-label">Pago del Plan</span>
																</button>
															</div>
														</div>
														<div class="col-3">
															<div class="step" data-target="#form-facturacion">
																<button class="step-trigger">
																	<span class="bs-stepper-circle">7</span>
																	<span class="bs-stepper-label">Datos de Facturación</span>
																</button>
															</div>
														</div>
														<!-- <div class="col-3">
                                             <div class="step" data-target="#form-folios-dte">
                                                <button class="step-trigger">
                                                   <span class="bs-stepper-circle">7</span>
                                                   <span class="bs-stepper-label">Folios [Opcional]</span>
                                                </button>
                                             </div>
                                          </div>
                                          <div class="col-3" *ngIf="user.configured">
                                             <div class="step" data-target="#form-plantillas-pdf">
                                                <button class="step-trigger">
                                                   <span class="bs-stepper-circle">8</span>
                                                   <span class="bs-stepper-label">Plantillas PDF</span>
                                                </button>
                                             </div>
                                          </div> -->
													</div>
												</div>
											</div>
										</div>
										<div class="bs-stepper-content">
											<form action="" (ngSubmit)="onSubmit()" [formGroup]="emisorSuperGroup">
												<div id="form-emisor-base" class="content fade">
													<ng-container *ngTemplateOutlet="baseEmisorForm"></ng-container>
													<button type="button" (click)="next($event, emisorBaseFormRef)" class="btn btn-primary">Siguiente</button>
												</div>
												<div id="form-emisor-sucursales" class="content fade">
													<ng-container *ngTemplateOutlet="sucursalForm"></ng-container>
													<button type="button" class="btn btn-default mr-5" (click)="previous()">Anterior</button>

													<button type="button" (click)="next($event, sucursalLocalFormRef)" class="btn btn-primary">Siguiente</button>
												</div>
												<div id="form-emisor-actividades" class="content fade">
													<ng-container *ngTemplateOutlet="actividadForm"></ng-container>
													<button type="button" class="btn btn-default mr-5" (click)="previous()">Anterior</button>

													<button type="button" (click)="next($event, actividadLocalFormRef)" class="btn btn-primary">Siguiente</button>
												</div>
												<div id="form-certificado" class="content fade">
													<ng-container *ngTemplateOutlet="certificadoForm"></ng-container>
													<button type="button" class="btn btn-default mr-5" (click)="previous()">Anterior</button>

													<button type="button" (click)="validateCertificate($event)" class="btn btn-primary">Siguiente</button>
												</div>
												<div id="form-emisor-logo" class="content fade">
													<ng-container *ngTemplateOutlet="logoForm"></ng-container>
													<button type="button" class="btn btn-default mr-5" (click)="previous()">Anterior</button>
													<button type="submit" class="btn btn-success mt-5">Enviar</button>
												</div>
												
												<!-- <div id="form-folios-dte" class="content fade">
                                       <ng-container *ngTemplateOutlet="foliosForm"></ng-container>
                                       <button type="button" class="btn btn-default mr-5" (click)="previous()">Anterior</button>

                                       <button type="button" (click)="next($event)" class="btn btn-primary">Siguiente</button>
                                    </div> -->
												<!-- <div id="form-plantillas-pdf" class="content fade">
                                       <ng-container *ngTemplateOutlet="plantillasForm"></ng-container>
                                       <button type="button" class="btn btn-default mr-5 mt-5" (click)="previous()">Anterior</button>

                                       <button class="btn btn-success mt-5">Enviar</button>
                                    </div> -->
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #baseEmisorForm [formGroup]="emisorSuperGroup">
	<h4>Datos de la empresa</h4>
	<form formGroupName="base" #emisorBaseFormRef>
		<div class="form-group row mx-auto" style="align-items: baseline">
			<label class="col-2 label-control" for="projectinput1">Rut Empresa</label>
			<div class="col-2">
				<input
					type="text"
					name="rutString"
					placeholder="Sin ptos con guión"
					class="form-control"
					formControlName="rutString"
					maxlength="10"
					(input)="comprobar($event)"
					autocomplete="new-password"
					required
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('rutString').invalid
					}" />
				<div *ngIf="(submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('rutString').invalid) || (emisorSuperGroup.controls.base.get('rutString').invalid && emisorSuperGroup.controls.base.get('rutString').touched)" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('rutString').errors.required">Campo obligatorio</div>
					<div *ngIf="emisorSuperGroup.controls.base.get('rutString').errors.VerificacionRutValidator" class="invalid-feedback">Rut Invalido</div>
				</div>
			</div>
			<label class="col-2 label-control" for="projectinput1">Razón Social</label>
			<div class="col-6">
				<input
					type="text"
					name="razonSocial"
					placeholder="Razón social. Nombre tributario/legal de tu empresa."
					class="form-control"
					formControlName="razonSocial"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('razonSocial').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('razonSocial').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('razonSocial').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
		<div class="form-group row mx-auto" style="align-items: baseline">
			<label class="col-2 label-control" for="projectinput1">Rut Representante Legal</label>
			<div class="col-2">
				<input
					type="text"
					name="rutRepresentanteLegal"
					placeholder="Sin ptos con guión"
					class="form-control"
					formControlName="rutRepresentanteLegal"
					maxlength="10"
					required
					(blur)="setRepresentanteLegal()"
					appVerificaRut
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('rutRepresentanteLegal').invalid
					}" />
				<div *ngIf="(submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('rutRepresentanteLegal').invalid) || (emisorSuperGroup.controls.base.get('rutRepresentanteLegal').invalid && emisorSuperGroup.controls.base.get('rutRepresentanteLegal').touched)" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('rutRepresentanteLegal').errors.required">Campo obligatorio</div>
					<div *ngIf="emisorSuperGroup.controls.base.get('rutRepresentanteLegal').errors.VerificacionRutValidator" class="invalid-feedback">Rut Invalido</div>
				</div>
			</div>
			<label class="col-2 label-control" for="projectinput1">Giro</label>
			<div class="col-6">
				<input
					type="text"
					name="giro"
					placeholder="Actividad de tu empresa"
					class="form-control"
					formControlName="giro"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('giro').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('giro').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('giro').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
		<div class="form-group row mx-auto" style="align-items: baseline">
			<label class="col-2 label-control" for="projectinput1">E-mail</label>
			<div class="col-2">
				<input
					pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
					type="email"
					name="correoFact"
					placeholder="Correo de contacto"
					class="form-control"
					formControlName="correoFact"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('correoFact').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('correoFact').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('correoFact').errors.required">Campo obligatorio</div>
					<div *ngIf="emisorSuperGroup.controls.base.get('correoFact').hasError('wrongEmail')">Correo inválido</div>
				</div>
			</div>
			<label class="col-2 label-control" for="projectinput1">Dirección Facturación</label>
			<div class="col-6">
				<input
					type="text"
					name="dirFact"
					placeholder="Dirección de tu casa matriz"
					class="form-control"
					formControlName="dirFact"
					(blur)="setAddress()"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('dirFact').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('dirFact').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('dirFact').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
		<div class="form-group row mx-auto" style="align-items: baseline">
			<label class="col-2 label-control" for="projectinput1">Ciudad</label>
			<div class="col-2">
				<input
					type="text"
					name="ciudad"
					placeholder=""
					maxlength="100"
					class="form-control"
					formControlName="ciudad"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('ciudad').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('ciudad').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('ciudad').errors.required">Campo obligatorio</div>
				</div>
			</div>
			<label class="col-2 label-control" for="projectinput1">Comuna</label>
			<div class="col-2">
				<input
					type="text"
					name="comuna"
					placeholder=""
					class="form-control"
					maxlength="100"
					formControlName="comuna"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('comuna').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('comuna').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('comuna').errors.required">Campo obligatorio</div>
				</div>
			</div>
			<label class="col-2 label-control" for="projectinput1">Telefono</label>
			<div class="col-2 d-flex align-items-center">
				<span>+56</span>
				<input
					oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
					maxlength="9"
					min="1"
					type="number"
					name="telefono"
					formControlName="telefono"
					placeholder="911111111"
					class="form-control ml-2"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('telefono').invalid
					}"
					required />
			</div>
		</div>
		<!-- <div class="form-group row mx-auto" style="align-items: baseline">
            <label class="col-2 label-control" for="projectinput1">Comuna</label>
            <div class="col-4">
               <input type="text" name="comuna" placeholder="Ingresar comuna" class="form-control" formControlName="comuna" [ngClass]="{
                     'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('comuna').invalid
                  }" required />
               <div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('comuna').invalid" class="invalid-feedback">
                  <div *ngIf="emisorSuperGroup.controls.base.get('comuna').errors.required">Campo obligatorio</div>
               </div>
            </div>
         </div> -->

		<div class="form-group row mx-auto" style="align-items: baseline">
			<a class="col-2 label-control" style="text-decoration: underline" for="projectinput1" (click)="openModalSII(modalSII)">Fecha Resolución</a>
			<div class="col-2">
				<input
					placeholder="Fecha de resolución"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('fechaResol').invalid
					}"
					[showDropdowns]="true"
					type="text"
					autocomplete="off"
					class="form-control"
					[opens]="'center'"
					name="fechaResol"
					ngxDaterangepickerMd
					[locale]="locale"
					[singleDatePicker]="true"
					[autoApply]="true"
					(change)="detectChangeReferenceDateAdd($event)" />
				<div *ngIf="submittedChildrenFormsArray[0] && (emisorSuperGroup.controls.base.get('fechaResol').invalid || !validDate)" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('fechaResol').errors.required">Campo obligatorio</div>
					<div *ngIf="!validDate">Fecha inválida, utilizar formato dd/mm/yyyy</div>
				</div>
			</div>
			<a class="col-2 label-control" style="text-decoration: underline" for="projectinput1" (click)="openModalSII(modalSII)">N° Resolución</a>
			<div class="col-2">
				<input
					value="0"
					type="number"
					name="nroResol"
					placeholder="Número de resolución"
					class="form-control"
					formControlName="nroResol"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('nroResol').invalid
					}"
					required />
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('nroResol').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('nroResol').errors.required">Campo obligatorio</div>
				</div>
			</div>
			<label class="col-2 label-control" for="projectinput1">Ambiente</label>
			<div class="col-2">
				<ng-select [items]="environmentType" [clearable]="false" [searchable]="false" bindLabel="nombre" [selectOnTab]="false" formControlName="ambiente"></ng-select>
				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('ambiente').invalid" class="invalid-feeback-group">
					<div *ngIf="emisorSuperGroup.controls.base.get('ambiente').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
		<div class="form-group row mx-auto" style="align-items: baseline">
			<label for="pInput1" class="col-2 label-control">Password S.I.I</label>
			<div class="col-4">
				<input ngbTooltip="Para el envío de cesiones y listados de compras" type="password" class="form-control" name="passwordSii" placeholder="De la empresa en el S.I.I." formControlName="passwordSii" />
				<small> Para el envío de cesiones, acceso a las compras y boletas de honorarios. No es obligatorio</small>
			</div>
			<label for="pInput1" class="col-2 label-control">Unidad S.I.I</label>
			<div class="col-4">
				<input
					type="text"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('unidadSII').invalid
					}"
					class="form-control"
					name="unidadSII"
					maxlength="30"
					placeholder="Nombre de la Unidad del S.I.I."
					formControlName="unidadSII" />

				<div *ngIf="submittedChildrenFormsArray[0] && emisorSuperGroup.controls.base.get('unidadSII').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.base.get('unidadSII').errors.required">Campo obligatorio</div>
				</div>
				<small> Corresponde a la dirección regional o unidad del SII a la que pertenece el emisor. <a target="_blank" href="https://www.sii.cl/ayudas/asistencia/oficinas/3048-3049.html">Consulta aquí el tuyo.</a></small>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #sucursalForm [formGroup]="emisorSuperGroup">
	<div class="row" formGroupName="sucursal" #sucursalLocalFormRef>
		<div class="modal-body">
			<h4>Crear Sucursal</h4>
			<div class="form-group row mx-auto" style="align-items: baseline">
				<label class="col-3 label-control" for="projectinput1">Nombre</label>
				<div class="col-9">
					<input
						type="text"
						name="nombre"
						placeholder="Ingresar nombre"
						class="form-control"
						formControlName="nombreSucursal"
						[ngClass]="{
							'is-invalid': submittedChildrenFormsArray[1] && emisorSuperGroup.controls.sucursal.get('nombreSucursal').invalid
						}"
						required />
					<div *ngIf="submittedChildrenFormsArray[1] && emisorSuperGroup.controls.sucursal.get('nombreSucursal').invalid" class="invalid-feedback">
						<div *ngIf="emisorSuperGroup.controls.sucursal.get('nombreSucursal').errors.required">Campo obligatorio</div>
					</div>
				</div>
			</div>
			<div class="form-group row mx-auto" style="align-items: baseline">
				<label class="col-3 label-control" for="projectinput1">Dirección</label>
				<div class="col-9">
					<input
						type="text"
						name="direccion"
						placeholder="Ingresar dirección"
						class="form-control"
						formControlName="direccionSucursal"
						[ngClass]="{
							'is-invalid': submittedChildrenFormsArray[1] && emisorSuperGroup.controls.sucursal.get('direccionSucursal').invalid
						}"
						required />
					<div *ngIf="submittedChildrenFormsArray[1] && emisorSuperGroup.controls.sucursal.get('direccionSucursal').invalid" class="invalid-feedback">
						<div *ngIf="emisorSuperGroup.controls.sucursal.get('direccionSucursal').errors.required">Campo obligatorio</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #actividadForm [formGroup]="emisorSuperGroup">
	<div formGroupName="actividades" #actividadLocalFormRef>
		<h4>Asignar Actividades Económicas</h4>
		<div class="row pt-5 ml-1 mb-5">
			<label for="" class="col-3 label-control">Actividades Económicas</label>
			<div class="col-7">
				<ng-select formControlName="actividadEconomica" [items]="economicActivitiesSource" [clearable]="true" (blur)="filtro()" placeholder="Seleccione actividad económica" bindLabel="displayText"></ng-select>
			</div>
			<div class="col-1 ml-1">
				<button *ngIf="permissions.getPermission('30-80-19-10').asignado" type="button" class="btn btn-success" (click)="addEconomicActivityToList()">Agregar</button>
			</div>
		</div>
		<div *ngIf="economicActivitiesList.length > 0">
			<h4>Actividades Económicas Asignadas</h4>
			<table class="table">
				<thead>
					<tr>
						<td>descripcion</td>
						<td>Acción</td>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of economicActivitiesList; let index = index">
						<td>{{ item.codigo }} - {{ item.descripcion }}</td>
						<td>
							<a *ngIf="permissions.getPermission('30-80-19-12').asignado" ngbTooltip="Quitar " class="primary edit mr-1 mrless" (click)="deleteItem(index)">
								<div class="icon-circle bg-danger text-center align-middle pt-info-button">
									<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="submittedChildrenFormsArray[2] && emisorSuperGroup.controls.actividades.get('listaActividadesEconomicas').invalid" class="invalid-feedback">
			<div *ngIf="emisorSuperGroup.controls.actividades.get('listaActividadesEconomicas').errors.required">Campo obligatorio, seleccione y agregue una actividad desde la lista</div>
		</div>
	</div>
</ng-template>

<ng-template #certificadoForm [formGroup]="emisorSuperGroup">
	<h4>Seleccionar Certificado Digital</h4>
	<div formGroupName="certificado" #certificadoFormRef>
		<div class="form-group row mx-auto">
			<div class="col-3">Archivo de Certificado (.pfx)</div>
			<div class="col-9">
				<ngx-dropzone #drop (change)="onFileChangeCER($event)" [multiple]="false">
					<ngx-dropzone-label class="px-5">
						<i class="flaticon-upload"></i>

						<p>Arrastra o selecciona el certificado digital.</p>

						<p>El formato del archivo debe ser .pfx</p>
					</ngx-dropzone-label>
					<ngx-dropzone-preview *ngFor="let f of certificadoFilesArray" [removable]="true" (removed)="onRemoveCertificaddoArray(f)">
						<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
					</ngx-dropzone-preview>
				</ngx-dropzone>
				<div *ngIf="submittedChildrenFormsArray[3] && emisorSuperGroup.controls.certificado.get('file').invalid" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.certificado.get('file').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
		<div class="form-group row mx-auto">
			<div class="col-3">Rut del certificado</div>
			<div class="col-9">
				<input
					type="text"
					name="rutString"
					placeholder="Ingresar rut de representante"
					class="form-control"
					formControlName="rutString"
					autocomplete="new-password"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[3] && emisorSuperGroup.controls.certificado.get('rutString').invalid && !user.isPartner
					}"
					[required]="!user.isPartner" />
				<div *ngIf="(submittedChildrenFormsArray[3] || emisorSuperGroup.controls.certificado.get('rutString').dirty) && emisorSuperGroup.controls.certificado.get('rutString').invalid && !user.isPartner" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.certificado.get('rutString').errors.VerificacionRutValidator" class="invalid-feedback">Rut Invalido</div>
					<div *ngIf="emisorSuperGroup.controls.certificado.get('rutString').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
		<div class="form-group row mx-auto">
			<div class="col-3">Password del certificado</div>
			<div class="col-9">
				<input
					type="password"
					name="password"
					placeholder="Ingresar contraseña del archivo"
					class="form-control"
					formControlName="password"
					autocomplete="new-password"
					[ngClass]="{
						'is-invalid': submittedChildrenFormsArray[3] && emisorSuperGroup.controls.certificado.get('password').invalid && !user.isPartner
					}"
					[required]="!user.isPartner" />
				<div *ngIf="submittedChildrenFormsArray[3] && emisorSuperGroup.controls.certificado.get('password').invalid && !user.isPartner" class="invalid-feedback">
					<div *ngIf="emisorSuperGroup.controls.certificado.get('password').errors.required">Campo obligatorio</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #logoForm [formGroup]="emisorSuperGroup">
	<div formGroupName="logo" #logoLocalFormRef>
		<div class="modal-body">
			<div class="row new-logo-container mb-3" *ngIf="logoRequest.logoFile">
				<div class="col-10 offset-sm-2">
					<img [src]="'data:image/png;base64,' + logoRequest.logoFile" alt="logo cargado" class="img-thumbnail" />
				</div>
			</div>
			<div class="form-group position-relative mx-auto">
				<div class="row">
					<label for="" class="col-2 label-control">Logo</label>
					<div class="col-10">
						<ngx-dropzone class="mx-auto" (change)="onLogoFileChange($event)" maxFileSize="2097152" accept="image/jpg,image/jpeg,image/png" multiple="false">
							<ngx-dropzone-label class="px-5">
								<i class="flaticon-upload"></i>
								<p>Arrastra o selecciona un archivo con la imagen del logo a utilizar</p>

								<p>El formato del archivo debe ser .png o .jpg</p>
							</ngx-dropzone-label>
							<!-- <ngx-dropzone-label class="d-inline">
                                 
                              </ngx-dropzone-label> -->
							<ngx-dropzone-preview *ngFor="let f of logosFilesArray" [removable]="true" (removed)="onRemoveLogo(f)">
								<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
							</ngx-dropzone-preview>
						</ngx-dropzone>

						<div *ngIf="submittedChildrenFormsArray[5] && (emisorSuperGroup.controls.logo.get('logoFile').invalid || emisorSuperGroup.controls.logo.get('logoFile').dirty)" class="invalid-feedback">
							<div *ngIf="emisorSuperGroup.controls.logo.get('logoFile').errors?.required">Campo requerido</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #modalSII let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Cómo obtener la fecha y número de resolución</div>
				<div class="modal-wizard-desc">Paso a paso</div>
			</div>
		</div>
		<div class="modal-body">
			<div class="form-group row mx-auto">
				<div class="col-12 mb-3">
					<ul class="list list-icons list-primary mb-0">
						<li>Si es primera vez que emitirás documentos electrónicos: <a target="_blank" href="https://www.sii.cl/servicios_online/1039-postulacion-1184.html">Postula aquí</a></li>
						<li>Para los datos en el ambiente de pruebas: <a target="_blank" href="https://maullin.sii.cl/cvc_cgi/dte/ad_empresa1">Ingresa aquí</a></li>
						<li>Para los datos en el ambiente de producción: <a target="_blank" href="https://palena.sii.cl/cvc_cgi/dte/ad_empresa1">Ingresa aquí</a></li>
					</ul>
				</div>
				<div class="col-12">
					<img class="img-fluid" src="./assets/media/gifs/datosResolucion.gif" />
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div class="form-group position-relative has-icon-left mb-0">
				<button type="button" class="btn btn-secondary ml-3" (click)="d('Cross click')">
					<span class="d-none d-lg-block">Cerrar</span>
				</button>
			</div>
		</div>
	</div>
</ng-template>
