import { NgPaginationNumber } from "src/app/_metronic/shared/crud-table/components/paginator/ng-pagination/ng-pagination.component";
import { Impuesto } from "../Impuesto";
import { Producto } from "../Producto/Producto";
import { DetalleDteImpuestoAdicional } from "./DetalleDteImpuestoAdicional";
import { DetalleDteRentencion } from "./DetalleDteRentencion";

export class DetalleDte {
   detalleDteId: string;
   dteId: string;
   itemId: string;
   descripcion: string;
   cantidad: number;
   unidadMedida: string;
   precioUnitario: number;
   precioUnidadMedida: number;
   descuento?: number;
   recargo?: number;
   total: number;
   esExento?: boolean;
   subtotal: number;
   codigoInterno: string;
   nroItem: number;
   nombre: string;
   impuestosAdicionales: DetalleDteImpuestoAdicional[];
   retenciones: DetalleDteRentencion[];
   totalImpuestosAdicionales: number;
   porcentajeRetencion: number;
   originalValuePrice: number;
   originalDiscount: number;
   originalRecharge: number;
   originalValueTotal: number;
   originalSubtotal: number;
   originalTotal: number;
   createCurrentProduct: boolean = false; //used as flag for creating a new product in Emision Individual
   relatedProductId: string; //used for checking the related product from what the detail is created.
   impuestosAsociados: Impuesto[] = [];
   relatedProduct: Producto;
   relatedDocumentsIds: string[] = []; // used for checking the dteId's of the documents that create a detail for Settlements
   public calcSubTotal(): void {
      this.subtotal = this.cantidad * this.precioUnitario;
   }
}
