<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"> </ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar
					[criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal"
					[config]="configLocal.barraBusqueda"
					[nombreCampoDatePicker]="configLocal.barraBusqueda.nombreCampoDatePicker"
					[filtroEmisor]="true"
					[mostrarListaCompletaEmisores]="isSuperAdmin"
					[listaCompletaEmisores]="emisoresFullList"
					[listaCompletaUsuarios]="usuariosFullList"
					[filtroUsuarios]="true"
					[searchByCurrentEmisorOnly]="true"
					[useAccordionMode]="true"
					[useInlineSearchButton]="true"
					(busqueda)="searchElementsByFilter($event)"></app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Registro de Transacciones</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<!-- <button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar">
                                        <i class="flaticon-download-1"></i>
                                        Exportar
                                    </button> -->
								</div>
							</div>
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material rows contacts-table mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="80"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column name="statusCode" [flexGrow]="1" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Status</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.statusCode }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="nombreUsuario" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Usuario</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.nombreUsuario
														? row.nombreUsuario
														: "Sin
                                                    Usuario"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="razonSocialEmisor" [flexGrow]="2.5" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Emisor</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.razonSocialEmisor }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="metodo" [flexGrow]="3" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Metodo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.metodo }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Resumen" [flexGrow]="4" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Resumen</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.titulo }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="fecha" [flexGrow]="2.8" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Fecha</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.fecha | date : "dd/MM/yyyy HH:mm:ss" }}</span></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Accion" [flexGrow]="1.2" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Detalles Transacción" id="mantenedor-log-transacciones.component_Detalles_Transacción" (click)="openDetailModal(detailLogRef, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="85"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Usuario: {{ row.nombreUsuario ? row.nombreUsuario : "Sin Usuario" }} , Emisor: {{ row.razonSocialEmisor }} , Metodo: {{ row.metodo }} , Resumen: {{ row.titulo }}, Fecha: {{ row.fecha | date : "dd/MM/yyyy HH:mm:ss" }}</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="status" [flexGrow]="1" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Status</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.statusCode }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="fecha" [flexGrow]="2" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Fecha</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.fecha | date : "dd/MM/yyyy HH:mm:ss" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="metodo" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Metodo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.metodo }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="1.2" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Detalles Transacción" id="mantenedor-log-transacciones.component_Detalles_Transacción">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>
<ng-template #detailLogRef let-d="dismiss">
	<div class="modal-lg">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Detalles de Registro de Transacción</h5>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="row form-group mx-auto" id="details-client">
				<h4>Datos del Registro</h4>
				<div class="col-12">
					<div class="row">
						<div class="col-4">
							<span class="d-block">Error</span>
							<span class="d-block">Status Code</span>
							<span class="d-block">Metodo</span>
							<span class="d-block">Fecha</span>
						</div>
						<div class="col-8">
							<span class="d-block text-right">{{ logToShow.titulo }}</span>
							<span class="d-block text-right">{{ logToShow.statusCode }}</span>
							<span class="d-block text-right">{{ logToShow.metodo }}</span>
							<span class="d-block text-right">{{ logToShow.fecha | date : "dd/MM/yyyy HH:mm:ss" }}</span>
						</div>
					</div>
					<br />
					<hr />
				</div>
				<br />
				<hr />
				<div class="col-12">
					<div class="row">
						<h4 style="align-content: center">Mensaje</h4>
						<!-- <div class="col-4">
                            <span class="d-block">StackTrace</span>
                            
                        </div> -->
						<div class="col-12" style="align-content: center; display: block; overflow: auto">
							<p>{{ logToShow.mensaje }}</p>
						</div>
					</div>
					<br />
					<hr />
				</div>
				<br />
				<hr />
				<div class="col-12">
					<div class="row">
						<h4 style="align-content: center">StackTrace</h4>
						<!-- <div class="col-4">
                            <span class="d-block">StackTrace</span>
                            
                        </div> -->
						<div class="col-12" style="align-content: center; display: block; overflow: auto">
							<span *ngIf="logToShow.stacktrace == null || logToShow.stacktrace == ''" class="d-block">Sin StackTrace</span>
							<span *ngIf="logToShow.stacktrace != null || logToShow.stacktrace != ''" class="d-block">{{ logToShow.stacktrace }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div class="form-group position-relative has-icon-left mb-0">
				<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
			</div>
		</div>
	</div>
</ng-template>
