import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Response } from "src/app/shared/models/Response";
import { Observable } from "rxjs";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Page } from "../shared/models/Page";
import { CorreoEnvioFilter } from "../models/CorreoEnvio/CorreoEnvioFilter";
import { CorreoEnvio } from "../models/CorreoEnvio/CorreoEnvio";
import { PlantillaCorreoEnvio } from "../models/CorreoEnvio/PlantillaCorreoEnvio";
import { SendInvoiceRequest } from "../models/CorreoEnvio/SendInvoiceRequest";
import { ExampleRequest } from "../models/CorreoEnvio/ExampleRequest";
import { TipoPlantillaCorreoEnvio } from "../models/CorreoEnvio/TipoPlantillaCorreoEnvio";
import { PlantillaEjemploRequest } from "../models/CorreoEnvio/PlantillaEjemploRequest";

@Injectable({
	providedIn: "root",
})
export class CorreoEnvioService {
	private REST_API_SERVER = environment.backend_server + "/EnvioCorreo";
	private auth = {};
	constructor(private http: HttpClient) {}

	public getDataByPage(filtro: CorreoEnvioFilter, page: Page): Observable<PagedResponse<CorreoEnvio>> {
		return this.http.get<PagedResponse<CorreoEnvio>>(this.REST_API_SERVER + "/list/filter?Nombre=" + (filtro.nombre != undefined ? filtro.nombre : "") + "&Activo=" + filtro.activo + "&TipoEnvio=" + filtro.tipoEnvioCorreo + "&TipoPlantilla=" + filtro.tipoPlantillaEnvioCorreo + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	deactivateEnvioCorreo(correoEnvioId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivate/" + correoEnvioId);
	}

	activateCorreoEnvio(correoEnvioId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activate/" + correoEnvioId);
	}

	updateCorreoEnvio(CorreoEnvio: CorreoEnvio): Observable<Response<CorreoEnvio>> {
		return this.http.post<Response<CorreoEnvio>>(this.REST_API_SERVER + "/update", CorreoEnvio);
	}

	addCorreoEnvio(ent: CorreoEnvio): Observable<Response<CorreoEnvio>> {
		return this.http.post<Response<CorreoEnvio>>(this.REST_API_SERVER + "/add", ent);
	}

	getTemplates(correoEnvioId: string): Observable<Response<PlantillaCorreoEnvio[]>> {
		return this.http.get<Response<PlantillaCorreoEnvio[]>>(this.REST_API_SERVER + "/getTemplates/" + correoEnvioId);
	}

	updateTemplate(ent: PlantillaCorreoEnvio, isSengrid: boolean): Observable<Response<CorreoEnvio>> {
		let formData = new FormData();
		formData.append("plantillaString", JSON.stringify(ent));
		formData.append("file", ent.file);
		formData.append("isSengrid", isSengrid ? "1" : "0");
		return this.http.post<Response<CorreoEnvio>>(this.REST_API_SERVER + "/updateTemplate", formData);
	}

	addTemplate(ent: PlantillaCorreoEnvio, isSengrid: boolean): Observable<Response<CorreoEnvio>> {
		let formData = new FormData();
		formData.append("plantillaString", JSON.stringify(ent));
		formData.append("file", ent.file);
		formData.append("isSengrid", isSengrid ? "1" : "0");
		return this.http.post<Response<CorreoEnvio>>(this.REST_API_SERVER + "/addTemplate", formData);
	}

	sendEmail(ent: SendInvoiceRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/sendEmail", ent);
	}

	deleteTemplate(templateId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deleteTemplate/" + templateId);
	}
	downloadTemplate(plantillaId: string): Observable<Response<string>> {
		return this.http.get<Response<string>>(this.REST_API_SERVER + "/downloadTemplate/" + plantillaId);
	}

	getExamples(): Observable<Response<ExampleRequest[]>> {
		return this.http.get<Response<ExampleRequest[]>>(this.REST_API_SERVER + "/getExamples");
	}
	deactivateTemplate(templateId: string, sendId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivateTemplate/" + templateId + "/" + sendId);
	}

	activateTemplate(templateId: string, sendId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activateTemplate/" + templateId + "/" + sendId);
	}

	getExampleTemplates(): Observable<Response<PlantillaEjemploRequest>> {
		return this.http.get<Response<PlantillaEjemploRequest>>(this.REST_API_SERVER + "/getExampleTemplates");
	}

	getTemplateTypes(): Observable<Response<TipoPlantillaCorreoEnvio[]>> {
		return this.http.get<Response<TipoPlantillaCorreoEnvio[]>>(this.REST_API_SERVER + "/getTemplateTypes");
	}

	updateExampleTemplate(ent: PlantillaCorreoEnvio, isSengrid: boolean): Observable<Response<CorreoEnvio>> {
		let formData = new FormData();
		formData.append("plantillaString", JSON.stringify(ent));
		formData.append("file", ent.file);
		formData.append("isSengrid", isSengrid ? "1" : "0");
		return this.http.post<Response<CorreoEnvio>>(this.REST_API_SERVER + "/updateExampleTemplate", formData);
	}

	addExampleTemplate(ent: PlantillaCorreoEnvio, isSengrid: boolean): Observable<Response<CorreoEnvio>> {
		let formData = new FormData();
		formData.append("plantillaString", JSON.stringify(ent));
		formData.append("file", ent.file);
		formData.append("isSengrid", isSengrid ? "1" : "0");
		return this.http.post<Response<CorreoEnvio>>(this.REST_API_SERVER + "/addExampleTemplate", formData);
	}

	deleteExampleTemplate(templateId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deleteExampleTemplate/" + templateId);
	}

	updateTemplateType(ent: TipoPlantillaCorreoEnvio): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateTemplateType", ent);
	}

	addTemplateType(ent: TipoPlantillaCorreoEnvio): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/addTemplateType", ent);
	}

	deactivateTemplateType(templateTypeId: number): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivateTemplateType/" + templateTypeId);
	}

	activateTemplateType(templateTypeId: number): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activateTemplateType/" + templateTypeId);
	}
}
