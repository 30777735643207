import { Perfil } from "../Perfil/Perfil";
import { Sucursal } from "../Sucursal";
import { Usuario } from "./Usuario";

export class UsuarioRequest {
	public user: Usuario;
	public userId: string;
	public sucursales: Sucursal[];
	public profiles: Perfil[];
	public emisorId: string;
	public sucursalId: string;
}
