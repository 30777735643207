<!-- begin::User Panel-->
<div id="kt_quick_user" class="offcanvas p-10" [ngClass]="extrasUserOffcanvasDirection">
	<ng-container *ngIf="_userSession">
		<!--begin::Header-->
		<div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
			<h3 class="font-weight-bold m-0">Menú de Usuario</h3>
		</div>
		<!--end::Header-->
		<!--begin::Content-->
		<div class="offcanvas-content pr-5 mr-n5">
			<!--begin::Header-->
			<div class="d-flex align-items-center mt-5">
				<div class="d-flex flex-column">
					<a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"> {{ _userSession.firstName }} {{ _userSession.lastName }} </a>
					<div class="text-muted mt-1">{{ _userSession.cargo }}</div>
					<div class="navi mt-2">
						<a href="#" class="navi-item">
							<span class="navi-link p-0 pb-2">
								<span class="navi-icon mr-1">
									<span [inlineSVG]="'./assets/media/svg/icons/Communication/Mail-notification.svg'" cacheSVG="true" class="svg-icon svg-icon-lg svg-icon-primary"></span>
								</span>
								<span class="navi-text text-muted text-hover-primary">{{ _userSession.email }}</span>
							</span>
						</a>

						<a class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5 cursor-pointer" (click)="logout()">Cerrar sesión</a>
					</div>
				</div>
			</div>
			<!--end::Header-->
			<!--begin::Separator-->
			<div class="separator separator-dashed mt-8 mb-5"></div>
			<!--end::Separator-->

			<!--begin::Nav-->
			<div class="navi navi-spacer-x-0 p-0">
				<!--begin::Item-->
				<mat-accordion class="mb-1">
					<mat-expansion-panel class="pt-4 pb-4">
						<mat-expansion-panel-header class="pt-2 pb-2">
							<a class="navi-item cursor-pointer">
								<div class="navi-link">
									<div class="symbol symbol-40 bg-light mr-1">
										<div class="symbol-label">
											<img src="./assets/media/svg/cs/registered.svg" class="img-fluid" />
										</div>
									</div>
									<div class="navi-text">
										<div class="font-weight-bold">Perfil de Usuario</div>
										<div class="text-muted">Tu cuenta y datos personales</div>
									</div>
								</div>
							</a>
						</mat-expansion-panel-header>
						<div class="pt-8 pb-6">
							<div class="d-flex align-items-center justify-content-between">
								<span class="font-weight-bold mr-2">RUT:</span>
								<span class="text-muted">{{ _user.rut }}-{{ _user.dv }}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<span class="font-weight-bold mr-2">Nombre:</span>
								<span class="text-muted">{{ _user.nombre }} {{ _user.apellidos }}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between mb-2">
								<span class="font-weight-bold mr-2">Email:</span>
								<a href="#" class="text-muted text-hover-primary">{{ _user.email }}</a>
							</div>
							<div class="d-flex align-items-center justify-content-between mb-2">
								<span class="font-weight-bold mr-2">Teléfono:</span>
								<span class="text-muted">{{ _user.telefono }}</span>
							</div>
						</div>
						<a (click)="goToProfile()" class="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Ir a Perfil de Usuario</a>
					</mat-expansion-panel>

					<!--begin::Item-->

					<mat-expansion-panel class="pt-4 pb-4">
						<mat-expansion-panel-header>
							<a class="navi-item cursor-pointer pt-4 pb-4">
								<div class="navi-link">
									<div class="symbol symbol-40 bg-light mr-3">
										<div class="symbol-label">
											<img src="./assets/media/svg/cs/office-building.svg" class="img-fluid" />
										</div>
									</div>

									<div class="navi-text">
										<div class="font-weight-bold">Perfil Empresa</div>
										<div class="text-muted">Configuración de tu empresa</div>
									</div>
								</div>
							</a>
						</mat-expansion-panel-header>
						<div class="pt-8 pb-6">
							<div class="d-flex align-items-center justify-content-between">
								<span class="font-weight-bold mr-2">RUT:</span>
								<span class="text-muted">{{ emisor.rutString }}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<span class="font-weight-bold mr-2">Razón Social:</span>
								<span class="text-muted">{{ emisor.razonSocial }}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between mb-2">
								<span class="font-weight-bold mr-2">Email:</span>
								<a href="#" class="text-muted text-hover-primary">{{ emisor.correoPar }}</a>
							</div>
							<div class="d-flex align-items-center justify-content-between mb-2">
								<span class="font-weight-bold mr-2">Teléfono:</span>
								<span class="text-muted">{{ emisor.telefono }}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between mb-2">
								<span class="font-weight-bold mr-2">Ambiente:</span>
								<span *ngIf="emisor.ambiente == 0" class="label label-inline label-lg label-warning">Certificación</span>
								<span *ngIf="emisor.ambiente == 1" class="label label-inline label-lg label-success">Producción</span>
							</div>
						</div>
						<a (click)="goToCompanyProfile()" class="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Ir a Perfil de Empresa</a>
					</mat-expansion-panel>

					<!--end:Item-->

					<!--begin::Item-->
				</mat-accordion>
				<!--end:Item-->
			</div>
			<!--end::Nav-->

			<!--begin::Separator-->
			<div class="separator separator-dashed my-7"></div>
			<!--end::Separator-->
		</div>
		<!--end::Content-->
	</ng-container>
</div>
<!-- end::User Panel-->
