<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<form [formGroup]="assignPlantillaForm" (ngSubmit)="onSubmit()" #assignPlantillaFormRef="ngForm">
	<div class="modal-body">
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Empresas</label>
				<div class="col-8">
					<ng-select
						[items]="listOfEmisores"
						[multiple]="false"
						placeholder="Seleccione Empresa"
						formControlName="empresaAsignar"
						[clearable]="false"
						bindLabel="razonSocial"
						[ngClass]="{
							'is-invalid': assignPlantillaFormRef.submitted && assignPlantillaForm.get('empresaAsignar').invalid
						}"></ng-select>
					<div *ngIf="assignPlantillaFormRef.submitted && (assignPlantillaForm.get('empresaAsignar').invalid || assignPlantillaForm.get('empresaAsignar').dirty || assignPlantillaForm.get('empresaAsignar').touched || assignPlantillaForm.get('empresaAsignar').value == null)" class="invalid-feedback">
						<div *ngIf="assignPlantillaForm.get('empresaAsignar').errors?.required">Campo requerido</div>
					</div>
				</div>
				<div class="col-1">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Asignar</span>
					</button>
				</div>
			</div>
		</div>

		<div class="row">
			<ng-container *ngIf="emisoresDePlantilla != null || emisoresDePlantilla != undefined || emisoresDePlantilla.length > 0">
				<br />
				<h4>Empresas asignadas a la plantilla {{ plantillaSelected.descripcion }}</h4>
				<table class="table">
					<thead>
						<tr>
							<th>Emisor</th>
							<th>Vigente</th>
							<th *ngIf="permissions.getPermission('20-30-14-10').asignado">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let plem of emisoresDePlantilla">
							<tr>
								<td>{{ plem.emisorAsociado.razonSocial }}</td>
								<td *ngIf="plem.activo">Si</td>
								<td *ngIf="!plem.activo">
									No <small>({{ plem.nombrePlantilla }})</small>
								</td>
								<td>
									<a ngbTooltip="Activar" class="primary edit mr-1 mrless" (click)="activate(plem)" *ngIf="!plem.activo && permissions.getPermission('20-30-14-10').asignado">
										<div class="icon-circle bg-success text-center align-middle pt-info-button">
											<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
										</div>
									</a>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
