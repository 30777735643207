export const ConfigMantenedorImpuesto = {
   barraBusqueda: {
      criterios: ["Nombre"],
      criteriosKeys: ["nombre"],
      criterioPrincipal: "Nombre",
   },
   table: {
      title: "Mantenedor Impuestos",
      add: "Carga Documento",
      options: [
         {
            name: "Importar",
            active: true,
            class: "ft-upload",
         },
         {
            name: "Exportar",
            active: true,
            class: "ft-download",
         },
         {
            name: "Solicitar Soporte",
            active: true,
            class: "ft-shuffle",
         },
      ],
   },
};
