import { Injectable, isDevMode } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class ConsoleDevService {
   constructor() {}

   public log(message?: any, ...optionalParams: any[]): void {
      if (isDevMode()) {
         console.log(message, ...optionalParams);
      }
   }
}
