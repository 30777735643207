import { ThrowStmt } from "@angular/compiler";
import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { Observable, Subscription } from "rxjs";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { TotalDteEmitido } from "src/app/models/Dte/TotalDteEmitido";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { environment } from "src/environments/environment";
import { EventEmitter } from "@angular/core";
import { TipoDte } from "src/app/models/tipoDte";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { FormaPago } from "src/app/models/Dte/FormaPago.enum";
import { OtraMoneda } from "src/app/models/Dte/OtraMoneda";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { Divisa } from "src/app/models/Divisa";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { NonNegativeNumberValidator } from "src/app/directives/non-negative.directive";
import { DescuentoRecargo } from "src/app/models/Dte/DescuentoRecargo";
import { TipoMovimiento } from "src/app/models/Dte/TipoMovimiento.enum";
import { ExpresionDinero } from "src/app/models/Dte/ExpresionDinero.enum";
import { IndicadorExento } from "src/app/models/Dte/IndicadorExento.enum";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { Producto } from "src/app/models/Producto/Producto";
import { TipoTraslado } from "src/app/models/TipoTraslado";
import { ComisionRecargo } from "src/app/models/Dte/ComisionRecargo";
import { threadId } from "worker_threads";
import { runInThisContext } from "vm";
import { TipoDespacho } from "src/app/models/TipoDespacho";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { ignoreElements } from "rxjs/operators";
import { UtilitiesService } from "src/app/services/utilities.service";
import { ThrottlingUtils } from "@azure/msal-common";
import { DetalleDteImpuestoAdicional } from "src/app/models/Dte/DetalleDteImpuestoAdicional";
import { ItemDetalleComisionComponent } from "../item-detalle-comision/item-detalle-comision.component";
import { CurrencyPipe } from "@angular/common";
import { FormatoDte } from "src/app/models/Dte/FormatoDte";
@Component({
	selector: "app-resumen-lista-detalles",
	templateUrl: "./resumen-lista-detalles.component.html",
	styleUrls: ["./resumen-lista-detalles.component.scss"],
})
export class ResumenListaDetallesComponent implements OnInit {
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	private listaResumir: DetalleDte[];
	public montoIva: number = 0;
	public montoRetencionIva: number = 0;
	public porcentajeIva: number = environment.taxPercentage;
	public netoAfectoResumen: number = 0;
	public brutoAfectoResumen: number = 0;
	public exentoResumen: number = 0;
	public montoDescuento: number = 0;
	public totalResumen: number = 0;
	public subTotalResumen: number = 0;
	public subTotalExento: number = 0;
	public subTotalForeign: number = 0;
	public montoTotalImpuestosEspecificos: number = 0;
	private listaComisionResumir: ComisionRecargo[];
	public montoIvaComision: number = 0;
	public montoRetencionIvaComision: number = 0;
	public porcentajeIvaComision: number = environment.taxPercentage;
	public netoAfectoResumenComision: number = 0;
	public brutoAfectoResumenComision: number = 0;
	public exentoResumenComision: number = 0;
	public montoDescuentoComision: number = 0;
	public totalResumenComision: number = 0;
	public subTotalResumenComision: number = 0;
	public subTotalForeignComision: number = 0;
	public montoTotalImpuestosEspecificosComision: number = 0;
	private listaResumirObservable$: Observable<DetalleDte[]>;
	private listaComisionResumirObservable$: Observable<ComisionRecargo[]>;
	public otraMonedaResumen: OtraMoneda = new OtraMoneda();
	public opcionesObservable$: Observable<OptionsReferenceDteEmitido>;
	public divisasObservable$: Observable<Divisa[]>;
	public divisasList: Divisa[] = [];
	public opciones: OptionsReferenceDteEmitido = new OptionsReferenceDteEmitido();
	public totalesDteEmitido: TotalDteEmitido = new TotalDteEmitido();
	public totalesDteFormGroup: FormGroup;
	private tipoDteFullSource: TipoDte[]; //complete list of Dte types
	public tipoDteSource: TipoDte[]; //filtered list of Dte types for showing and selecting
	public tipoTrasladosSource: TipoTraslado[];
	public tipoDespachoSource: TipoDespacho[];
	public montoDescuentoForeign: number = 0;
	public todayString = moment();
	public lastDayOfMonth = moment().endOf("month");
	public minDateString = moment().add(-6, "months");
	public dontShowInTicket: boolean = true;
	public dontShowInGuide: boolean = true;
	public showInGuide: boolean = true;
	public showTransferType: boolean = false;
	public showPaymentType: boolean = true;
	public showDeliveryType: boolean = false;
	public showDueDate: boolean = true;
	public showDiscount: boolean = true;
	public showInExport: boolean = false;
	public showComission: boolean = false;
	public ShowInSettlement: boolean = false;
	public showIvaTerceros: boolean = false;
	public showInputIvaTerceros: boolean = false;
	private invoiceType$: Observable<InvoiceType>;
	private formatSizeList$: Observable<FormatoDte[]>;
	private subscriptions: Subscription[] = [];
	public currentInvoiceType: InvoiceType;
	public totalClausulaVenta: number = 0;
	public fleteResumen: number = 0;
	public seguroResumen: number = 0;

	
	tipoFacturaSource = [
		{ id: 0, tipo: "Afecta" },
		{ id: 1, tipo: "Exenta" },
	];
	formasPagoSource = [
		{ id: 1, nombreForma: "Contado" },
		{ id: 2, nombreForma: "Credito" },
		{ id: 3, nombreForma: "Sin Costo" },
	];
	// sizeFormat = [
	//    { id: 0, name: "Carta", size: 16 },
	//    { id: 1, name: "Semicarta", size: 8 },
	//    { id: 2, name: "Ticket", size: 10000 },
	// ];
	public sizeFormat: { id: number; name: string; size: number }[] = [];
	locale = {
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD/MM/YYYY",
	};
	public validDate: boolean = true;
	constructor(private facturador: FacturacionIndividualService, private builder: FormBuilder, private localStorage: LocalStorageService, private ref: ChangeDetectorRef, private utilities: UtilitiesService, private currencyPipe: CurrencyPipe) {
		this.listaResumirObservable$ = this.facturador.detalleDteListObservable;
		this.listaComisionResumirObservable$ = this.facturador.detalleComisionDteListObservable;
		this.opcionesObservable$ = this.facturador.opcionesReferenciaObservable;
		this.divisasObservable$ = this.facturador.divisasListObservable;
		this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
		this.formatSizeList$ = this.facturador.formatSizeListObservable;
		this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		this.tipoDteFullSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		this.tipoTrasladosSource = JSON.parse(this.localStorage.getTipoTraslados()) as TipoTraslado[];
		this.tipoDespachoSource = JSON.parse(this.localStorage.getTipoDespacho()) as TipoDespacho[];
	}

	ngOnInit(): void {
		this.createTotalesForm();
		const invoiceTypesSub = this.invoiceType$.subscribe((result) => {
			//hide due days,  change between Boleta pairs or Factura pairs related to exento or not exento
			this.modificationsByInvoiceType(result);
		});

		this.defaultOtraMonedaResumen();
		const listResumirSub = this.listaResumirObservable$.subscribe((list) => {
			this.listaResumir = [...list.filter((producto) => Object.keys(producto).length > 0)];
			if (this.listaResumir[0]) {
				this.calculateTotals();
			} else {
				this.resetTotals();
			}
		});
		const listComisionResumirSub = this.listaComisionResumirObservable$.subscribe((list) => {
			this.listaComisionResumir = [...list];
			if (this.listaComisionResumir[0]) {
				this.calculateComisionTotals();
				this.calculateTotals();
			}
		});
		const divisasSub = this.divisasObservable$.subscribe((list) => {
			this.divisasList = list;
			if (this.divisasList[0]) {
				this.divisasList[0].nombre = "USD";
			}
		});
		const opcionesSub = this.opcionesObservable$.subscribe((opciones) => {
			this.opciones = opciones;

			this.otraMonedaResumen.tipoMoneda = this.opciones.tipoMoneda ? this.opciones.tipoMoneda : "CLP";
			//console.log("OPciones en resumen",this.opciones.sonDetallesConIVA)
			//let foundedDivisa = this.divisasList.find((divisa) =>divisa.nombre.toUpperCase() == this.otraMonedaResumen.tipoMoneda );
			let foundedDivisa = opciones.valorTipoMoneda;
			this.otraMonedaResumen.tipoCambio = this.otraMonedaResumen.tipoMoneda != "CLP" && foundedDivisa != undefined ? foundedDivisa : 0;
			this.otraMonedaResumen.tipoMonedaAduana = this.opciones.tipoMonedaAduana ? this.opciones.tipoMonedaAduana : undefined;
			this.otraMonedaResumen.codigoMonedaAduana = this.opciones.codigoMonedaAduana ? this.opciones.codigoMonedaAduana : undefined;
			this.calculateTotals();
			this.calculateComisionTotals();

			//this.ref.detectChanges();
		});
		const formatoSizeSub = this.formatSizeList$.subscribe((formatList) => {
			this.sizeFormat = [];
			formatList.forEach((formato, i) => {
				let size = { id: i, name: formato.formato, size: formato.maxLineas };
				this.sizeFormat.push(size);
			});
			this.totalesDteFormGroup.controls.formato.patchValue(this.sizeFormat[0]);
		});
		this.subscriptions = [listResumirSub, divisasSub, opcionesSub, invoiceTypesSub, listComisionResumirSub, formatoSizeSub];
		this.onSubmit();
		this.ref.detectChanges();
	}
	ngOnChanges() {
		this.calculateTotals();
		this.calculateComisionTotals();
		this.ref.detectChanges();
	}

	calculateTotals() {
		this.montoIva = 0;
		this.netoAfectoResumen = 0;
		this.brutoAfectoResumen = 0;
		this.exentoResumen = 0;
		this.totalResumen = 0;
		this.montoDescuento = 0;
		if (this.listaResumir[0].subtotal) {
			this.listaResumir.forEach((detalle) => {
				if (detalle.subtotal) {
					if (!detalle.esExento && this.opciones.sonDetallesConIVA) {
						this.brutoAfectoResumen += detalle.total;
					}
					// else if(!detalle.esExento && !detalle.impuestos && !this.opciones.sonDetallesConIVA){
					//   this.netoAfectoResumen += detalle.total;
					// }
					else if (detalle.esExento) {
						this.exentoResumen += detalle.total;
					}
				}
			});

			if (this.opciones.tipoMoneda != "CLP") {
				//discount and tax calculation for foreign values
				let valorTipoCambio = this.opciones.valorTipoMoneda;

				this.montoDescuentoForeign = this.totalesDteFormGroup.controls.descuentoGlobal.value;
				//let valorTipoCambio = this.divisasList.find((divisa) => divisa.nombre.toUpperCase() == this.otraMonedaResumen.tipoMoneda).valorClp;
				if (this.opciones.sonDetallesConIVA) {
					let valueWithTax = Math.round(+(this.montoDescuentoForeign * valorTipoCambio).toFixed(0));
					this.montoDescuento = Math.round(valueWithTax / (1 + this.porcentajeIva));
				} else {
					this.montoDescuento = +Math.round(+this.totalesDteFormGroup.controls.descuentoGlobal.value).toFixed(0);
				}

				if (this.opciones.sonDetallesConIVA) {
					this.montoDescuento = Math.round(+this.totalesDteFormGroup.controls.descuentoGlobal.value / (1 + this.porcentajeIva));
					if (this.listaResumir.some((item) => item.impuestosAdicionales && item.impuestosAdicionales.length > 0)) {
						this.getAllTotalsSpecificTaxItemsIncluded();
						this.totalesDteEmitido.montoImpuestos = this.montoTotalImpuestosEspecificos;
					} else {
						this.getTotalsMainTaxItemsIncluded();
					}
				} else {
					if (this.listaResumir.some((item) => item.impuestosAdicionales && item.impuestosAdicionales.length > 0)) {
						this.getAllTotalsSpecificTaxItemsExcluded();

						if (this.opciones.tipoMoneda == "USD") {
							this.montoTotalImpuestosEspecificos = Math.round(this.montoTotalImpuestosEspecificos * this.opciones.valorTipoMoneda);
						}
						this.totalesDteEmitido.montoImpuestos = this.montoTotalImpuestosEspecificos;
					} else {
						this.getTotalMainTaxItemsExcluded();
					}
				}
			} else if (this.opciones.tipoMoneda == "CLP") {
				this.montoDescuento = Math.round(this.totalesDteFormGroup.controls.descuentoGlobal.value);
				//DISCOUNT AND TAX CALCULATION FOR LOCAL VALUES
				if (this.opciones.sonDetallesConIVA) {
					if (this.currentInvoiceType != InvoiceType.Boleta && this.listaResumir.some((item) => item.impuestosAdicionales && item.impuestosAdicionales.length > 0)) {
						this.getAllTotalsSpecificTaxItemsIncluded();
						this.totalesDteEmitido.montoImpuestos = this.montoTotalImpuestosEspecificos;
					} else {
						this.getTotalsMainTaxItemsIncluded();
					}
				} else {
					if (this.currentInvoiceType != InvoiceType.Boleta && this.listaResumir.some((item) => (item.impuestosAdicionales && item.impuestosAdicionales.length > 0) || item.totalImpuestosAdicionales > 0)) {
						this.getAllTotalsSpecificTaxItemsExcluded();
						this.totalesDteEmitido.montoImpuestos = this.montoTotalImpuestosEspecificos;
					} else {
						this.getTotalMainTaxItemsExcluded();
					}
				}
			}
			if (this.currentInvoiceType == InvoiceType.Boleta && this.totalesDteEmitido.codigoTipoDte != 41) {
				this.totalResumen = this.opciones.sonDetallesConIVA ? this.montoIva + this.netoAfectoResumen + this.exentoResumen + this.montoTotalImpuestosEspecificos - (this.montoTotalImpuestosEspecificos > 0 ? this.montoDescuento / (1 + this.porcentajeIva) : 0) : this.montoIva + this.netoAfectoResumen + this.exentoResumen - (this.opciones.sonDetallesConIVA ? Math.round(this.montoDescuento / (1 + this.porcentajeIva)) : this.montoDescuento) + this.montoTotalImpuestosEspecificos;
			} else if (this.totalesDteEmitido.codigoTipoDte == 34 || this.totalesDteEmitido.codigoTipoDte == 41) {
				this.totalResumen = this.montoIva + this.netoAfectoResumen + this.exentoResumen - (this.opciones.sonDetallesConIVA && this.netoAfectoResumen > 0 ? Math.round(this.montoDescuento / (1 + this.porcentajeIva)) : this.montoDescuento) + this.montoTotalImpuestosEspecificos;
			} else {
				this.totalResumen = this.montoIva + this.netoAfectoResumen + this.exentoResumen - this.montoDescuento + this.montoTotalImpuestosEspecificos;
			}
			this.totalesDteEmitido.netoDte = this.netoAfectoResumen;
			this.totalesDteEmitido.exentoDte = this.exentoResumen;
			this.totalesDteEmitido.ivaDte = this.montoIva;
			this.totalesDteEmitido.totalDte = this.totalResumen;
			this.totalesDteEmitido.tipoTraslado = this.totalesDteFormGroup.controls.tipoTraslado.value.id;
			this.totalesDteEmitido.tipoDespacho = this.totalesDteFormGroup.controls.tipoDespacho.value.id;
			this.totalesDteEmitido.formaPago = this.totalesDteFormGroup.controls.formaPago.value;
			this.totalesDteEmitido.formaPagoExportacion = this.totalesDteFormGroup.controls.formaPagoExportacion.value.id;
			this.totalesDteEmitido.fechaEmision = moment(this.totalesDteFormGroup.controls.fechaEmision.value.startDate).toDate();
			this.totalesDteEmitido.descuentoGlobal = this.opciones.sonDetallesConIVA ? Math.round(this.montoDescuento / (1 + this.porcentajeIva)) : this.montoDescuento; //this.totalesDteFormGroup.controls.descuentoGlobal.value;
			this.totalesDteEmitido.formato = this.totalesDteFormGroup.controls.formato.value;
			this.totalesDteEmitido.descuentoRecargoEnt = this.setGlobalDiscountObject(this.montoDescuento);
			this.checkTaxesIntegrity();

			this.onSubmit();
		}
	}
	calculateComisionTotals() {
		this.montoIvaComision = 0;
		this.netoAfectoResumenComision = 0;
		this.brutoAfectoResumenComision = 0;
		this.exentoResumenComision = 0;
		this.totalResumenComision = 0;
		this.montoDescuentoComision = 0;

		this.listaComisionResumir.forEach((detalle) => {
			this.exentoResumenComision += detalle.exento;
		});
		this.netoAfectoResumenComision = this.listaComisionResumir.reduce((sum, item) => (item.neto > 0 ? sum + item.neto : sum + 0), 0);
		this.montoIvaComision = Math.round(this.porcentajeIva * this.netoAfectoResumenComision);

		this.totalResumenComision = this.montoIvaComision + this.netoAfectoResumenComision + this.exentoResumenComision;

		this.totalesDteEmitido.ivaComisionCargos = this.montoIvaComision;
		this.totalesDteEmitido.netoComisionCargos = this.netoAfectoResumenComision;
		this.totalesDteEmitido.exentoComisionCargos = this.exentoResumenComision;
		this.totalesDteEmitido.totalComisionCargos = this.totalResumenComision;
		this.onSubmit();
	}

	detectChangeResumenDate(event) {
		if (event.startDate != null) {
			this.totalesDteEmitido.fechaEmision = moment(event.startDate).toDate();
			this.totalesDteFormGroup.controls.fechaEmision.patchValue(event);
			this.onSubmit();
		} else if (event.target != null && event.target.value != null) {
			this.validDate = moment(event.target.value, "DD/MM/YYYY", true).isValid();
			if (this.validDate) {
				let obj: any = new Object();
				obj.startDate = moment(event.target.value, "DD-MM-YYYY");
				this.totalesDteEmitido.fechaEmision = moment(obj.startDate).toDate();
				this.totalesDteFormGroup.controls.fechaEmision.patchValue(obj);
				this.onSubmit();
			}
		}
	}

	createTotalesForm() {
		this.totalesDteFormGroup = this.builder.group({
			tipoDte: [this.tipoDteSource[0], { validator: Validators.required }],
			formaPago: [this.formasPagoSource[0]],
			tipoTraslado: [this.tipoTrasladosSource[0]],
			tipoDespacho: [this.tipoDespachoSource[0]],
			fechaEmision: [{ startDate: moment(), endDate: moment() }],
			descuentoGlobal: [0, [NonNegativeNumberValidator]],
			formato: [this.sizeFormat[0]],
			diasVencimiento: [0, [NonNegativeNumberValidator]],
			ivaTerceros: [this.montoIva, [PositiveNumberValidator]],
			ivaPropio: [0, [PositiveNumberValidator]],
		});
		this.totalesDteFormGroup.get("tipoDte").disable();
		this.totalesDteFormGroup.controls.formato.patchValue(this.sizeFormat[0]);
	}

	onSubmit() {
		if (this.showInputIvaTerceros) {
			this.totalesDteFormGroup.controls.ivaTerceros.enable();
			this.totalesDteFormGroup.controls.ivaTerceros.setValidators([PositiveNumberValidator]);
		} else {
			this.totalesDteFormGroup.controls.ivaTerceros.disable();
		}
		if (this.totalesDteFormGroup.valid && this.validDate) {
			var ivaPropio = Number.parseInt(this.totalesDteFormGroup.controls.ivaPropio.value);
			var ivaTerceros = Number.parseInt(this.totalesDteFormGroup.controls.ivaTerceros.value);
			if (ivaPropio <= 0 || ivaPropio + ivaTerceros != Math.round(this.montoIva)) {
				this.totalesDteFormGroup.controls.ivaTerceros.patchValue(Math.round(this.montoIva));
				this.totalesDteFormGroup.controls.ivaPropio.patchValue(0);
			}
			if (this.opciones.tipoMoneda != "CLP" && this.montoDescuentoForeign != this.totalesDteFormGroup.controls.descuentoGlobal.value) {
				this.refreshDiscount();
			}
			let completeTipoDteList = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
			let tipoDte = new TipoDte();
			tipoDte = this.totalesDteFormGroup.controls.tipoDte.value;
			if (tipoDte.codigosii != 52) {
				let newCodigoSii = this.setTipoDteAfectoExento(this.listaResumir, tipoDte);
				if (newCodigoSii == tipoDte.codigosii) {
					this.totalesDteEmitido.tipoDteId = tipoDte.tipoDteId;
					this.totalesDteEmitido.codigoTipoDte = tipoDte.codigosii;
				} else {
					let changedDte: TipoDte = completeTipoDteList.find((dteType) => dteType.codigosii == newCodigoSii);
					this.totalesDteFormGroup.controls.tipoDte.patchValue(this.tipoDteSource.find((type) => type.tipoDteId == changedDte.tipoDteId));
					this.totalesDteEmitido.tipoDteId = changedDte.tipoDteId;
					this.totalesDteEmitido.codigoTipoDte = changedDte.codigosii;
				}
			} else {
				this.totalesDteEmitido.tipoDteId = tipoDte.tipoDteId;
				this.totalesDteEmitido.codigoTipoDte = tipoDte.codigosii;
			}

			if (this.totalResumenComision > 0) this.totalResumen = this.totalResumen - this.totalResumenComision;
			this.totalesDteEmitido.tipoTraslado = this.totalesDteFormGroup.controls.tipoTraslado.value.id;
			this.totalesDteEmitido.tipoDespacho = this.totalesDteFormGroup.controls.tipoDespacho.value.id;
			this.totalesDteEmitido.formaPago = this.totalesDteFormGroup.controls.formaPago.value.id;
			this.totalesDteEmitido.fechaEmision = moment(this.totalesDteFormGroup.controls.fechaEmision.value.startDate).toDate();
			this.totalesDteEmitido.descuentoGlobal = this.montoDescuento; //+this.totalesDteFormGroup.controls.descuentoGlobal.value;
			this.totalesDteEmitido.netoDte = this.totalesDteEmitido.descuentoGlobal > 0 && this.netoAfectoResumen > 0 ? this.subTotalResumen : this.netoAfectoResumen;
			this.totalesDteEmitido.exentoDte = this.subTotalExento > 0 ? this.subTotalExento : this.exentoResumen;
			this.totalesDteEmitido.ivaDte = this.montoIva;
			this.totalesDteEmitido.totalDte = this.totalResumen;
			this.totalesDteEmitido.formato = this.totalesDteFormGroup.controls.formato.value;
			this.totalesDteEmitido.diasVencimiento = this.totalesDteFormGroup.controls.diasVencimiento.value;
			//console.log("Resumen en envio",this.totalesDteEmitido,this.totalesDteFormGroup.controls.formato.value);
			if (!(this.opciones.tipoMoneda == "CLP")) {
				this.updateAlternResumen();
			}
			if (this.showInputIvaTerceros) {
				this.totalesDteEmitido.TieneIvaTerceros = true;
				this.totalesDteEmitido.ivaTerceros = Number.parseInt(this.totalesDteFormGroup.controls.ivaTerceros.value);
				this.totalesDteEmitido.ivaPropio = Number.parseInt(this.totalesDteFormGroup.controls.ivaPropio.value);
			} else {
				this.totalesDteEmitido.TieneIvaTerceros = false;
				this.totalesDteEmitido.ivaTerceros = 0;
				this.totalesDteEmitido.ivaPropio = 0;
			}
			this.totalesDteEmitido.otraMonedaEnt = this.otraMonedaResumen;
			this.facturador.updateTotales(this.totalesDteEmitido);
		}
	}

	updateFormaPago(event) {
		var diasCredito = event.nombreForma == "Contado" ? "0" : "30";
		this.totalesDteFormGroup.controls.diasVencimiento.setValue(diasCredito);
	}
	refreshTotalesForm(totales: TotalDteEmitido) {
		this.totalesDteFormGroup.patchValue(totales);
		if (!totales.formaPago) {
			this.totalesDteFormGroup.patchValue(
				{
					formaPago: [this.formasPagoSource[0]],
				},
				{ onlySelf: true }
			);
		}
	}

	updateAlternResumen() {
		//update the OtraMoneda instance to show the  total value of the Dte in foreign value
		this.otraMonedaResumen.tipoMoneda = this.opciones.tipoMoneda;
		if (this.divisasList.length > 0 && this.otraMonedaResumen.tipoMoneda != "CLP" && this.otraMonedaResumen.tipoCambio > 0 && this.otraMonedaResumen.tipoMoneda) {
			//this.otraMonedaResumen.tipoCambio = this.divisasList.find((divisa) => divisa.nombre.toUpperCase() == this.otraMonedaResumen.tipoMoneda).valorClp;
			this.otraMonedaResumen.tipoCambio = this.opciones.valorTipoMoneda;
			this.otraMonedaResumen.montoNeto = +(this.totalesDteEmitido.netoDte / this.otraMonedaResumen.tipoCambio).toFixed(2);
			this.otraMonedaResumen.montoExento = +(this.totalesDteEmitido.exentoDte / this.otraMonedaResumen.tipoCambio).toFixed(2);
			this.otraMonedaResumen.iva = +(this.totalesDteEmitido.ivaDte / this.otraMonedaResumen.tipoCambio).toFixed(2);
			this.otraMonedaResumen.montoTotal = +(this.totalesDteEmitido.totalDte / this.otraMonedaResumen.tipoCambio).toFixed(2);
			this.otraMonedaResumen.imptoEspecifico = +(this.totalesDteEmitido.montoImpuestos / this.otraMonedaResumen.tipoCambio).toFixed(2);
			this.subTotalForeign = this.otraMonedaResumen.montoNeto - this.montoDescuentoForeign;
		}
	}
	refreshDiscount() {
		//when used in foreign currency, update the discount value in CLP
		this.montoDescuentoForeign = this.totalesDteFormGroup.controls.descuentoGlobal.value;

		// let valorTipoCambio = this.divisasList.find(
		//   (divisa) =>
		//     divisa.nombre.toUpperCase() == this.otraMonedaResumen.tipoMoneda
		// ).valorClp;
		let valorTipoCambio = this.otraMonedaResumen.tipoCambio;
		this.montoDescuento = Math.round(+(this.montoDescuentoForeign * valorTipoCambio).toFixed(0));
		this.montoIva = Math.round(this.porcentajeIva * (this.netoAfectoResumen - this.montoDescuento));
		this.totalResumen = this.montoIva + this.netoAfectoResumen + this.exentoResumen;
		if (this.totalResumenComision > 0) this.totalResumen = this.totalResumen - this.totalResumenComision;
		this.totalesDteEmitido.netoDte = this.netoAfectoResumen;
		this.totalesDteEmitido.exentoDte = this.exentoResumen;
		this.totalesDteEmitido.ivaDte = this.montoIva;
		this.totalesDteEmitido.totalDte = this.totalResumen;
	}

	defaultOtraMonedaResumen() {
		this.otraMonedaResumen.montoNeto = 0;
		this.otraMonedaResumen.montoExento = 0;
		this.otraMonedaResumen.iva = 0;
		this.otraMonedaResumen.montoTotal = 0;
	}

	setGlobalDiscountObject(discountValue: number) {
		let descuentoGlobal: DescuentoRecargo = new DescuentoRecargo();
		descuentoGlobal.numero = 0;
		descuentoGlobal.tipoMovimiento = TipoMovimiento.D;
		descuentoGlobal.descripcion = "Descuento sobre subtotal";
		descuentoGlobal.tipoValor = ExpresionDinero.$;

		descuentoGlobal.IndicadorExento = IndicadorExento["No facturable"];
		descuentoGlobal.valor = +discountValue;
		return descuentoGlobal;
	}

	setTipoDteAfectoExento(list: DetalleDte[], dteType: TipoDte): number {
		//check the dte list and change the Dte type if the conditions are ok
		let dteTypeSelected: TipoDte = dteType;
		if (dteTypeSelected.codigosii == 33 || dteTypeSelected.codigosii == 34) {
			return this.AllListElementsAreExento(list) ? 34 : 33;
		} else if (dteTypeSelected.codigosii == 39 || dteTypeSelected.codigosii == 41) {
			return this.AllListElementsAreExento(list) ? 41 : 39;
		} else {
			return dteTypeSelected.codigosii;
		}
	}

	AllListElementsAreExento(list: DetalleDte[]): boolean {
		return list.every((detail) => detail.esExento == true);
	}

	calculateListWithTax(list: DetalleDte[]) {
		//change the value for each item excludying the tax value
		return list.map((detail) => (detail = this.setDetailWithoutTax(detail)));
	}

	setDetailWithoutTax(detail) {
		detail.precioUnitario = detail.precioUnitario / (1 + this.porcentajeIva);
		detail.recargo = detail.recargo / (1 + this.porcentajeIva);
		detail.descuento = detail.descuento / (1 + this.porcentajeIva);
		detail.subtotal = detail.subtotal / (1 + this.porcentajeIva);
		detail.total = detail.total / (1 + this.porcentajeIva);
		return detail;
	}

	modificationsByInvoiceType(type: InvoiceType) {
		switch (type) {
			case InvoiceType.Factura: {
				this.tipoDteSource = this.tipoDteFullSource.filter((dteType) => dteType.codigosii == 33 || dteType.codigosii == 34);
				this.showComission = false;
				this.currentInvoiceType = type;
				break; //this.totalesDteFormGroup.controls.tipoDte.patchValue(this.tipoDteSource[0])
			}
			case InvoiceType.Boleta: {
				this.tipoDteSource = this.tipoDteFullSource.filter((dteType) => dteType.codigosii == 39 || dteType.codigosii == 41);
				//this.dontShowInTicket = true;
				this.showComission = false;
				this.showPaymentType = false;
				this.showDueDate = false;
				this.showDiscount = false;
				this.currentInvoiceType = type;
				this.totalesDteFormGroup.controls.diasVencimiento.patchValue(0);
				break;
			}
			case InvoiceType.GuiaDespacho: {
				this.tipoDteSource = this.tipoDteFullSource.filter((dteType) => dteType.codigosii == 52);

				this.currentInvoiceType = type;
				this.showDeliveryType = true;
				this.showTransferType = true;
				this.ShowInSettlement = false;
				this.showPaymentType = false;
				this.showDiscount = false;
				this.showComission = false;
				this.showDueDate = false;
				this.totalesDteFormGroup.controls.diasVencimiento.patchValue(0);
				this.todayString = this.lastDayOfMonth;
				break;
			}
		}
		this.totalesDteFormGroup.controls.tipoDte.patchValue(this.tipoDteSource[0]);
		if (this.tipoDteSource[0].codigosii == 33 || this.tipoDteSource[0].codigosii == 43) {
			this.showIvaTerceros = true;
		} else this.showIvaTerceros = false;
	}

	getTaxesFromDetailsNeto(detalles: DetalleDte[]) {
		let totalTaxes;
		detalles.forEach((prod) => {
			if (prod.impuestosAdicionales.length > 0) {
				prod.impuestosAdicionales.forEach((tax) => {
					totalTaxes += Math.round(prod.total * (tax.valor / 100));
				});
			}
		});
		return totalTaxes;
	}

	getNetoTotalFromTaxedDetailsIncluded(detalles: DetalleDte[]) {
		//
		this.checkCorrectTaxInDetail();
		let netoTotal: number = 0;

		detalles.forEach((detalle) => {
			if (detalle.impuestosAdicionales && !detalle.esExento) {
				let sumTaxRates = this.getSumOfTaxesRatesInItem(detalle);
				//console.log("suma de impuestos del item",sumTaxRates,"detalle item total",detalle.total,"sumatoria neto",netoTotal);
				netoTotal += Math.round(detalle.total / (1 + sumTaxRates / 100 + this.porcentajeIva));
				//netoTotal += Math.round(detalle.total / (1 + this.porcentajeIva))
			} else {
				netoTotal += Math.round(detalle.total / (1 + this.porcentajeIva));
			}
		});

		return netoTotal;
	}
	getSumOfTaxesRatesInItem(item: DetalleDte) {
		return item.impuestosAdicionales.reduce((sumTaxRates, imptoAdicional) => sumTaxRates + imptoAdicional.tasa, 0);
	}

	getSpecificTaxesTotalFromItemListIncluded(descuento: number) {
		//the total of specific tax has to be calculated from each detail
		let totalSpecificTaxValue = 0;
		if (this.currentInvoiceType != InvoiceType.Boleta) {
			this.listaResumir.forEach((item) => {
				if (!item.esExento && item.impuestosAdicionales && item.impuestosAdicionales.length > 0) {
					let sumTaxRates = this.getSumOfTaxesRatesInItem(item);
					let netoTotalItem = item.total / (1 + sumTaxRates / 100 + this.porcentajeIva) - descuento;
					item.totalImpuestosAdicionales = 0;
					item.impuestosAdicionales.forEach((imptoAdicional) => {
						let imptoSinRedond = netoTotalItem * (imptoAdicional.tasa / 100);
						imptoAdicional.valor = Math.round(imptoSinRedond);
						item.totalImpuestosAdicionales += imptoAdicional.valor;
					});
				}
				totalSpecificTaxValue += !item.esExento ? item.totalImpuestosAdicionales : 0;
				//console.log("impuestos considerados",totalSpecificTaxValue,item.totalImpuestosAdicionales)
			});
		}
		return totalSpecificTaxValue;
	}

	getAllTotalsSpecificTaxItemsIncluded() {
		//not all items contribute to MainTax / IVA
		//from net you can get the MainTax, then montoIva and subTotalResumen are related to the netoAfectoResumen
		if (this.currentInvoiceType == InvoiceType.Boleta) {
			this.netoAfectoResumen = this.getNetoTotalFromTaxedDetailsIncluded(this.listaResumir);
			let descuentoNeto = this.montoDescuento / (1 + this.porcentajeIva);
			this.montoIva = Math.round(this.porcentajeIva * (this.netoAfectoResumen - descuentoNeto));
			//this.montoTotalImpuestosEspecificos = this.getSpecificTaxesTotalFromItemListIncluded(descuentoNeto);
			this.subTotalResumen = this.netoAfectoResumen - descuentoNeto + this.exentoResumen;
		} else {
			this.netoAfectoResumen = this.getNetoTotalFromTaxedDetailsIncluded(this.listaResumir);
			let descuentoNeto = this.montoDescuento;
			this.montoIva = Math.round(this.porcentajeIva * (this.netoAfectoResumen - descuentoNeto));
			this.montoTotalImpuestosEspecificos = this.getSpecificTaxesTotalFromItemListIncluded(descuentoNeto);
			this.subTotalResumen = this.netoAfectoResumen - descuentoNeto + this.exentoResumen;
		}
		//console.log(this.netoAfectoResumen,this.montoIva,this.montoDescuento,this.subTotalResumen,this.montoTotalImpuestosEspecificos);
	}

	getTotalsMainTaxItemsIncluded() {
		//CON IVA
		/* ITEM PRICE INCLUDED THE TAXES SO NET VALUE IS OBTAINED FROM ARRAY OF ITEMS
     Previously the  gross total is obtained directly from the sum of total details
    then it's used to get the net total */
		if (this.currentInvoiceType == InvoiceType.Boleta) {
			this.netoAfectoResumen = this.brutoAfectoResumen > 0 ? (this.brutoAfectoResumen - this.montoDescuento) / (1 + this.porcentajeIva) : 0;
			this.montoIva = this.netoAfectoResumen > 0 ? this.netoAfectoResumen * this.porcentajeIva : 0;
			this.subTotalResumen = this.netoAfectoResumen + this.exentoResumen - this.montoDescuento;
			if (this.netoAfectoResumen + this.montoIva != this.brutoAfectoResumen + this.montoDescuento) {
				this.approximateTotalForTaxIncludedItems();
			}
		} else {
			this.netoAfectoResumen = this.brutoAfectoResumen / (1 + this.porcentajeIva);
			this.montoIva = this.netoAfectoResumen > 0 ? this.porcentajeIva * (this.netoAfectoResumen - this.montoDescuento) : 0;
			this.subTotalResumen = this.netoAfectoResumen - this.montoDescuento + this.exentoResumen;
			if (this.subTotalResumen + this.montoIva + this.exentoResumen != this.brutoAfectoResumen) {
				this.approximateTotalForTaxIncludedItems();
			}
			this.montoTotalImpuestosEspecificos = 0;
		}
	}

	getNetoTotalFromTaxedItemExcluded(detalles: DetalleDte[]) {
		let netoTotal = 0;
		detalles.forEach((detalle) => {
			if (!detalle.esExento) {
				netoTotal += detalle.total;
			}
		});
		return netoTotal;
	}

	getSpecificTaxesTotalFromItemListExcluded() {
		let totalSpecificTaxes = 0;
		if (this.currentInvoiceType != InvoiceType.Boleta) {
			this.checkCorrectTaxInDetail();
			if (this.listaResumir.some((detail) => detail.totalImpuestosAdicionales > 0)) {
				this.listaResumir.forEach((detail) => {
					if (detail.totalImpuestosAdicionales > 0) {
						totalSpecificTaxes += detail.totalImpuestosAdicionales;
					}
				});
				return totalSpecificTaxes;
			}
			this.listaResumir.forEach((item) => {
				if (!item.esExento && item.impuestosAdicionales && item.impuestosAdicionales.length > 0) {
					item.impuestosAdicionales.forEach((impAdic) => {
						//totalSpecificTaxes += Math.round(impAdic.valor*item.cantidad);
						totalSpecificTaxes += +(impAdic.valor * item.cantidad).toFixed(2);
					});
				}
			});
		}

		return totalSpecificTaxes;
	}
	checkCorrectTaxInDetail() {
		this.listaResumir.forEach((item) => {
			if (item.relatedProduct) {
				let impuestosFromProduct = item.relatedProduct.impuestos;
				item.impuestosAdicionales = [];
				item.totalImpuestosAdicionales = 0;
				if (impuestosFromProduct) {
					impuestosFromProduct.forEach((i) => {
						let impuestoAdicional = new DetalleDteImpuestoAdicional();
						impuestoAdicional.codigoSii = i.tipoImpuesto;
						impuestoAdicional.nombreImp = i.nombre;
						if (this.opciones.sonDetallesConIVA) {
							impuestoAdicional.valor = Math.round((i.valor / 100) * (item.total / (1.19 + i.valor / 100)));
						} else {
							impuestoAdicional.valor = Math.round((i.valor / 100) * item.total);
						}
						impuestoAdicional.nroImp = impuestosFromProduct.indexOf(i) + 1;
						impuestoAdicional.tasa = i.valor;
						impuestoAdicional.impuestoIdRelated = i.impuestoId;
						item.totalImpuestosAdicionales += impuestoAdicional.valor;
						item.impuestosAdicionales.push(impuestoAdicional);
					});
				}
			}
		});
	}
	getAllTotalsSpecificTaxItemsExcluded() {
		let netoFromTaxedExcludedItems = this.getNetoTotalFromTaxedItemExcluded(this.listaResumir);
		this.netoAfectoResumen = this.netoAfectoResumen > 0 ? this.netoAfectoResumen + netoFromTaxedExcludedItems : netoFromTaxedExcludedItems;
		this.montoDescuento = +this.totalesDteFormGroup.controls.descuentoGlobal.value;
		this.montoIva = Math.round(this.porcentajeIva * (this.netoAfectoResumen - this.montoDescuento));
		this.montoTotalImpuestosEspecificos = this.getSpecificTaxesTotalFromItemListExcluded();
		this.subTotalResumen = this.netoAfectoResumen > 0 ? this.netoAfectoResumen - this.montoDescuento + this.exentoResumen : 0 + this.exentoResumen - this.montoDescuento;
		this.subTotalExento = this.netoAfectoResumen <= 0 ? this.exentoResumen - this.montoDescuento + this.exentoResumen : 0 + this.exentoResumen - this.montoDescuento; //works only if not afecto items
	}

	getTotalMainTaxItemsExcluded() {
		this.netoAfectoResumen = this.listaResumir.reduce((sum, item) => (!item.esExento && item.total != undefined ? sum + item.total : sum + 0), 0);
		if (this.opciones.tipoMoneda == "CLP") {
			this.montoDescuento = +this.totalesDteFormGroup.controls.descuentoGlobal.value;
			this.subTotalExento = this.netoAfectoResumen <= 0 ? this.exentoResumen - this.montoDescuento : 0; //works only if not afecto items
		}
		this.montoIva = this.netoAfectoResumen > 0 ? Math.round(this.porcentajeIva * (this.netoAfectoResumen - this.montoDescuento)) : 0;
			this.subTotalResumen = this.netoAfectoResumen > 0 ? this.netoAfectoResumen - this.montoDescuento + this.exentoResumen : 0 + this.exentoResumen - this.montoDescuento;
		
		this.montoTotalImpuestosEspecificos = this.getSpecificTaxesTotalFromItemListExcluded();
	}

	getTotalMainTaxComisionItemsExcluded() {
		this.netoAfectoResumenComision = this.listaComisionResumir.reduce((sum, item) => (item.neto > 0 ? sum + item.total : sum + 0), 0);
		if (this.opciones.tipoMoneda == "CLP") {
			this.montoDescuentoComision = +this.totalesDteFormGroup.controls.descuentoGlobal.value;
		}
		this.montoIvaComision = Math.round(this.porcentajeIva * (this.netoAfectoResumenComision - this.montoDescuentoComision));
		this.subTotalResumenComision = this.netoAfectoResumenComision - this.montoDescuentoComision;
		this.montoTotalImpuestosEspecificosComision = 0;
	}

	getNetoTotal(detalles: DetalleDte[], considerExempt: boolean = false) {
		let netoTotal = 0;
		detalles.forEach((detalle) => {
			if (!detalle.esExento || considerExempt) {
				netoTotal += detalle.total;
			}
		});
		return netoTotal;
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => {
			sub.unsubscribe();
		});
	}
	
	changeHaveIvaTerceros($event) {
		this.totalesDteFormGroup.controls.ivaTerceros.patchValue(Math.round(this.montoIva));
		this.totalesDteFormGroup.controls.ivaPropio.patchValue(0);
		this.showInputIvaTerceros = $event.target.checked;
		if (this.showInputIvaTerceros) {
			this.totalesDteFormGroup.controls.ivaTerceros.enable();
			this.totalesDteFormGroup.controls.ivaTerceros.setValidators([PositiveNumberValidator]);
		} else {
			this.totalesDteFormGroup.controls.ivaTerceros.disable();
		}
	}
	calculateIvaPropio() {
		var ivaTercero = Number.parseInt(this.totalesDteFormGroup.controls.ivaTerceros.value);
		var ivaTotal = Math.round(this.montoIva);
		var ivaTerceroString = this.currencyPipe.transform(ivaTercero, null, "$ ", "0.0-2", "es");
		var ivaTotalString = this.currencyPipe.transform(ivaTotal, null, "$ ", "0.0-2", "es");
		if (ivaTercero > ivaTotal) {
			this.currentSwal.icon = "info";
			this.currentSwal.title = "Iva de Terceros Erroneo";
			this.currentSwal.text = "El iva de terceros " + ivaTerceroString + " no puede ser mayor al iva total " + ivaTotalString;
			this.currentSwal.fire();
			this.totalesDteFormGroup.controls.ivaTerceros.patchValue(Math.round(this.montoIva));
			this.totalesDteFormGroup.controls.ivaPropio.patchValue(0);
			return;
		}
		this.totalesDteFormGroup.controls.ivaPropio.patchValue(Math.round(this.montoIva - ivaTercero));
		this.onSubmit();
	}

	resetTotals() {
		this.netoAfectoResumen = 0;
		this.exentoResumen = 0;
		this.subTotalResumen = 0;
		this.montoIva = 0;
		this.montoTotalImpuestosEspecificos = 0;
		this.totalResumen = 0;
	}

	checkTaxesIntegrity() {
		if (this.currentInvoiceType != InvoiceType.Boleta && this.listaResumir.some((item) => item.relatedProduct && item.relatedProduct.impuestos && item.relatedProduct.impuestos.length > 0)) {
			this.listaResumir.forEach((item) => {
				if (!item.impuestosAdicionales || item.impuestosAdicionales.length == 0) {
					if (item.relatedProduct && Array.isArray(item.relatedProduct.impuestos)) {
						let detalleDteImpuestoAdicionalList: DetalleDteImpuestoAdicional[] = [];
						let impuestoFromProduct = [...item.relatedProduct.impuestos];
						impuestoFromProduct = [...impuestoFromProduct];
						detalleDteImpuestoAdicionalList = item.relatedProduct.impuestos.map((i) => {
							let impuestoAdicional = new DetalleDteImpuestoAdicional();
							impuestoAdicional.codigoSii = i.tipoImpuesto;
							impuestoAdicional.nombreImp = i.nombre;
							if (this.opciones.sonDetallesConIVA) {
								impuestoAdicional.valor = Math.round((i.valor / 100) * (item.total / (1.19 + i.valor / 100)));
							} else {
								impuestoAdicional.valor = Math.round((i.valor / 100) * item.total);
							}
							impuestoAdicional.nroImp = item.relatedProduct.impuestos.indexOf(i) + 1;
							impuestoAdicional.tasa = i.valor;
							impuestoAdicional.impuestoIdRelated = i.impuestoId;
							return impuestoAdicional;
						});
						item.impuestosAdicionales = [...detalleDteImpuestoAdicionalList];
						item.impuestosAdicionales = [...item.impuestosAdicionales];
					}
				}
			});
		}
	}

	approximateTotalForTaxIncludedItems() {
		//VERIFICA EL CUADRE DEL TOTAL PARA ITEMS CON IVA INCLUIDO
		//CONSIDERA LA APROXIMACION de MONTOS DE IVA O NETO PARA CALZAR EL TOTAL
		let neto = Math.round(this.netoAfectoResumen);
		let subTotal = Math.round(this.subTotalResumen);
		let iva = Math.round(this.montoIva);
		if (this.currentInvoiceType == InvoiceType.Boleta) {
			//let subTotal = Math.round(this.subTotalResumen>0?this.subTotalResumen:this.brutoAfectoResumen-this.montoDescuento);
			if (this.montoDescuento == 0 && subTotal > 0) {
				if (subTotal + iva != this.brutoAfectoResumen && this.exentoResumen == 0) {
					iva = this.brutoAfectoResumen - subTotal;
				}
			}
		} else {
			if (this.montoDescuento == 0) {
				if (subTotal + iva != this.brutoAfectoResumen && this.exentoResumen == 0) {
					iva = this.brutoAfectoResumen - subTotal;
				}
			}
		}

		this.netoAfectoResumen = neto;
		this.montoIva = iva;
	}

	checkComercialValue() : boolean{
		return this.totalesDteFormGroup.controls.tipoTraslado.value.id == 4 || 
		this.totalesDteFormGroup.controls.tipoTraslado.value.id == 5 ||
		this.totalesDteFormGroup.controls.tipoTraslado.value.id == 6 ||
		this.totalesDteFormGroup.controls.tipoTraslado.value.id == 7 ||
		this.totalesDteFormGroup.controls.tipoTraslado.value.id == 8;
	}
}
