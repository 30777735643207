<form [formGroup]="configForm" (ngSubmit)="onSubmit()" #configFormRef="ngForm">
	<div class="modal-body">
		<div>
			<h4>Configuración Emisión</h4>
			<div class="mt-5 form-group position-relative mx-auto">
				<div class="row pt-5">
					<label for="" class="col-3 label-control">Tipo Documento</label>
					<div class="col-7">
						<ng-select [readonly]="noEditTypeDte ? noEditTypeDte : null" formControlName="tipoDocumento" [items]="tipoDteSource" [clearable]="true" (blur)="filtro()" placeholder="Seleccione tipo documento" bindLabel="nombre"></ng-select>
					</div>
				</div>
			</div>
			<div class="mt-5 form-group position-relative mx-auto">
				<div class="row">
					<label for="" class="col-3 label-control">Max Lineas</label>
					<div class="col-9">
						<input
							type="number"
							min="0"
							max="60"
							class="form-control"
							id="lineas"
							formControlName="lineas"
							[attr.disabled]="detailMode ? detailMode : null"
							placeholder="Ingrese máximo de lineas"
							[ngClass]="{
								'is-invalid': configFormRef.submitted && configForm.get('lineas').invalid
							}" />
						<div *ngIf="configFormRef.submitted && (configForm.get('lineas').invalid || configForm.get('lineas').dirty)" class="invalid-feedback">
							<div *ngIf="configForm.get('lineas').errors?.required">Campo requerido</div>
							<div *ngIf="configForm.get('lineas').errors?.min">min 1 linea</div>
							<div *ngIf="configForm.get('lineas').errors?.max">max 30 lineas</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" *ngIf="!detailMode">
			<i class="la la-paper-plane-o d-lg-none"></i>
			<span class="d-none d-lg-block">{{ configToEdit ? "Guardar" : "Generar" }}</span>
		</button>
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
