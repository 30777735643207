// tri-state-checkbox.component.ts
import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export enum TriState {
	Unchecked,
	Indeterminate,
	Checked,
}

@Component({
	selector: "tri-state-checkbox",
	template: `<input type="checkbox" [indeterminate]="value === TriState.Indeterminate" [checked]="value === TriState.Checked" (change)="nextState()" />`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TriStateCheckboxComponent),
			multi: true,
		},
	],
})
export class TriStateCheckboxComponent implements ControlValueAccessor {
	TriState = TriState; // Para poder usarlo en la plantilla
	value: TriState = TriState.Unchecked;

	// Función a llamar cuando el checkbox cambia
	onChange = (value: TriState) => {};

	// Función a llamar cuando el checkbox es tocado
	onTouched = () => {};

	// Cambia al siguiente estado
	nextState() {
		this.value = (this.value + 1) % 3;
		this.onChange(this.value);
	}

	// Permite a Angular establecer el valor del checkbox
	writeValue(value: TriState) {
		this.value = value;
	}

	// Guarda la función a llamar cuando el checkbox cambia
	registerOnChange(fn: (value: TriState) => void) {
		this.onChange = fn;
	}

	// Guarda la función a llamar cuando el checkbox es tocado
	registerOnTouched(fn: () => void) {
		this.onTouched = fn;
	}
}
