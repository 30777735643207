import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActividadEconomica } from "src/app/models/ActividadEconomica";
import { ActividadEconomicaEmisor } from "src/app/models/ActividadEconomicaEmisor";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import Swal from "sweetalert2";

@Component({
   selector: "app-actividad-entry",
   templateUrl: "./actividad-entry.component.html",
   styleUrls: ["./actividad-entry.component.scss"],
})
export class ActividadEntryComponent implements OnInit {
   public economicActivityForm: FormGroup;
   public economicActivitiesList: ActividadEconomica[] = [];
   private economicActivityObj: ActividadEconomicaEmisor[] = [];
   @Input() public emisor: Emisor = undefined;
   @Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
   @Output() private sendEmisorRequest: EventEmitter<any> = new EventEmitter<any>();
   public economicActivitiesSource: ActividadEconomica[] = JSON.parse(this.localStorage.getActividadesEconomicas()) as ActividadEconomica[];
   constructor(private formBuilder: FormBuilder, public permissions: PermissionService, private ref: ChangeDetectorRef, private emisorService: EmisorService, private localStorage: LocalStorageService) {}

   ngOnInit(): void {
      this.setForm();
      this.economicActivitiesList = this.emisor.actividadesEconomicas;
      this.setEconomicActivitiesList();
   }
   onSubmit() {
      if (this.economicActivitiesList.length > 0) {
         this.setEconomicActivitiesList();
         this.sendEmisorRequest.emit(this.economicActivityObj);
      }
   }
   setEconomicActivitiesList() {
      this.economicActivityObj = [];
      this.economicActivitiesList.forEach((element) => {
         var newActivity = new ActividadEconomicaEmisor();
         newActivity.actividadEconomicaId = element.actividadEconomicaId;
         newActivity.emisorId = this.emisor.emisorId;

         this.economicActivityObj.push(newActivity);
      });
   }
   setForm() {
      this.economicActivityForm = this.formBuilder.group({
         actividadEconomica: [, Validators.required],
      });
   }
   addEconomicActivityToList() {
      let activity = this.economicActivityForm.get("actividadEconomica").value as ActividadEconomica;
      if (activity) {
         let coincidence = this.emisor.actividadesEconomicas.some((ae) => ae.actividadEconomicaId == activity.actividadEconomicaId);
         if (this.economicActivitiesList.length >= 4) {
            Swal.fire({
               icon: "info",
               titleText: "Cantidad Máxima",
               text: "Solo se pueden asignar 4 actividades económicas por emisor",
            });
            this.clear();
            this.getEconomicActivitiesList();
            return;
         } else if (this.economicActivitiesList.includes(activity) || coincidence || this.economicActivitiesList.length > 4) {
            Swal.fire({
               icon: "info",
               titleText: "La Actividad Económica ya esta asignada",
               text: "La Actividad economica ya se encuentra asignada.",
            });
            this.clear();
            this.getEconomicActivitiesList();
            return;
         } else {
            this.economicActivitiesList.push(activity);
         }
         this.clear();
         this.getEconomicActivitiesList();
      }

      this.ref.detectChanges();
   }
   clear() {
      this.economicActivityForm.get("actividadEconomica").setValue(undefined);
   }
   deleteItem(index) {
      this.economicActivitiesList.splice(index, 1);
   }
   deleteItemObj() {}

   sendCloseRequest() {
      this.sendCloseRequestEmitter.emit();
   }

   getEconomicActivitiesList() {
      this.economicActivitiesSource = JSON.parse(this.localStorage.getActividadesEconomicas()) as ActividadEconomica[];
   }

   filtro() {
      if (this.economicActivityForm.get("actividadEconomica").value) {
         let selected = this.economicActivityForm.get("actividadEconomica").value as ActividadEconomica;
         this.economicActivitiesSource = this.economicActivitiesSource.filter((ae) => ae.actividadEconomicaId == selected.actividadEconomicaId);
      } else {
         this.getEconomicActivitiesList();
      }
   }
}
