import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Perfil } from "src/app/models/Perfil/Perfil";
import { Permiso } from "src/app/models/Perfil/Permiso";
import { PerfilService } from "src/app/services/perfil.service";
import { FastSearchResponse } from "src/app/shared/models/FastSearchResponse";
import Swal from "sweetalert2";

@Component({
	selector: "app-permission-list",
	templateUrl: "./permission-list.component.html",
	styleUrls: ["./permission-list.component.scss"],
})
export class PermissionListComponent implements OnInit {
	private paramsState: Observable<any>;
	public currentProfile: Perfil;
	public rowsToShow: Permiso[] = [];
	public loading: boolean = false;
	public returnURL: string = "";
	public markAllTrue: number = 2;
	public searchCriteria = [{ key: "nombre" }];
	editing = {};
	constructor(private route: ActivatedRoute, private perfilService: PerfilService, private ref: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.paramsState = this.getParams();
		this.getProfileInParams();
	}

	getParams(): Observable<any> {
		return this.route.paramMap.pipe(map(() => window.history.state));
	}

	getProfileInParams() {
		this.loading = true;
		try {
			this.paramsState
				.subscribe(
					(result) => {
						this.currentProfile = JSON.parse(result["rowProfile"]) as Perfil;
						this.returnURL = result["currentUrl"];
						this.currentProfile.permisos.forEach((perm) => {
							this.addParentName(perm);
							perm = this.concatenateName(perm);
						});
						this.currentProfile.permisos.sort((a, b) => a.nombrePadre.localeCompare(b.nombrePadre));
						this.rowsToShow = [...this.currentProfile.permisos];
						if (!this.returnURL || this.returnURL == "") {
							this.returnURL = "/perfiles";
						}
						this.loading = false;
						this.ref.detectChanges();
						if (!this.currentProfile.permisos) {
							Swal.fire({
								title: "Error en Perfil",
								text: "No es posible acceder al perfíl seleccionado",
								icon: "error",
								showConfirmButton: true,
								confirmButtonText: "Aceptar",
							});
						}
					},
					(error) => {
						// console.log(error)
						Swal.fire({
							title: "Error en Perfil",
							text: "No es posible acceder al perfíl seleccionado",
							icon: "error",
							showConfirmButton: true,
							confirmButtonText: "Aceptar",
						});
					}
				)
				.add(() => {
					this.loading = false;
					if (!this.currentProfile.permisos) {
						Swal.fire({
							title: "Error en Perfil",
							text: "No es posible acceder al perfíl seleccionado",
							icon: "error",
							showConfirmButton: true,
							confirmButtonText: "Aceptar",
						});
					}
				});
		} catch (Error) {
			Swal.fire({
				title: "Error en Perfil",
				text: "No es posible acceder al perfíl seleccionado",
				icon: "error",
				showConfirmButton: true,
				confirmButtonText: "Aceptar",
			});
		}
	}
	markAll(event) {
		if (event.target.checked) this.currentProfile.permisos.forEach((x) => (x.asignado = true));
		else this.currentProfile.permisos.forEach((x) => (x.asignado = false));
		this.ref.detectChanges();
		this.checkChangesInRows();
		this.ref.checkNoChanges();
	}

	updateValue(event, row: Permiso) {
		if (event.target.checked) row.asignado = true;
		if (!event.target.checked) row.asignado = false;
	}

	sendPermissionsList() {
		this.loading = true;
		this.checkChangesInRows();
		this.perfilService.updatePermissionsProfile(this.currentProfile).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Perfil Actualizado",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "El perfil " + this.currentProfile.nombre + " fue actualizado" + "</p></div>",
				});
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Actualizar Permisos",
					showConfirmButton: true,
					confirmButtonText: "Aceptar",
					text: "No fue posible actualizar los permisos del perfil " + this.currentProfile.nombre + ". Intenta más tarde",
				});
			}
		);
	}

	addParentName(permission: Permiso) {
		let nivelesCodigo = permission.codigo.split("-");
		if (nivelesCodigo.length > 2) {
			let parentPermission = this.currentProfile.permisos.find((perm) => perm.codigo == nivelesCodigo[0] + "-" + nivelesCodigo[1]);
			permission.nombrePadre = parentPermission ? parentPermission.nombre : "Base";
		} else if (nivelesCodigo.length > 0 && nivelesCodigo[0] == "40" && (nivelesCodigo[1] == "12" || nivelesCodigo[1] == "14" || nivelesCodigo[1] == "16")) {
			let parentPermission = this.currentProfile.permisos.find((perm) => perm.codigo == "40-10");
			permission.nombrePadre = parentPermission ? parentPermission.nombre : "Base";
		} else if (nivelesCodigo.length > 0) {
			let parentPermission = this.currentProfile.permisos.find((perm) => perm.codigo == nivelesCodigo[0]);
			permission.nombrePadre = parentPermission ? parentPermission.nombre : "Base";
		} else {
			permission.nombrePadre = "Base";
		}
	}

	changeSearchedRows(event: FastSearchResponse) {
		if (event != null) {
			this.rowsToShow = [...event.searchResult];
		}
	}

	checkChangesInRows() {
		//check if some Permiso has been changed in rowsToShow and pass that change to permisos Array in the currentProfile
		this.currentProfile.permisos.forEach((perm) => {
			let showedPerm = this.rowsToShow.find((p) => p.permisoId === perm.permisoId);
			perm.asignado = showedPerm && showedPerm.asignado != perm.asignado ? showedPerm.asignado : perm.asignado;
		});
	}

	concatenateName(permiso: Permiso) {
		if (permiso.nombrePadre) {
			permiso.nombreCompuesto = permiso.nombrePadre + " | " + permiso.nombre;
		} else {
			permiso.nombreCompuesto = permiso.nombre;
		}
		return permiso;
	}
}
