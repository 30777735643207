import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { MsalUserLandingComponent } from "./components/layout/msal-user-landing/msal-user-landing.component";
import { AuthGuard } from "./modules/auth/_services/auth.guard";
import { RoleGuard } from "./modules/auth/_services/role.guard";

export const routes: Routes = [
	{
		path: "auth",
		loadChildren: () => import("./modules/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "error",
		loadChildren: () => import("./modules/errors/errors.module").then((m) => m.ErrorsModule),
	},
	{
		path: "",
		canActivate: [AuthGuard],
		loadChildren: () => import("./pages/layout.module").then((m) => m.LayoutModule),
	},
	{ path: "**", redirectTo: "error/404" },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
