import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectionType } from "@swimlane/ngx-datatable";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { DetalleDteImpuestoAdicional } from "src/app/models/Dte/DetalleDteImpuestoAdicional";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { Impuesto } from "src/app/models/Impuesto";
import { Producto } from "src/app/models/Producto/Producto";
import { ProductoFilter } from "src/app/models/Producto/ProductoFilter";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { ImpuestoService } from "src/app/services/impuesto.service";
import { ProductoService } from "src/app/services/producto.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigProductosDetalle } from "src/app/components/facturacion/facturador-individual/cuadro-producto-detalle/config-productos-detalle";
import { PermissionService } from "src/app/services/permission.service";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { Observable } from "rxjs";
@Component({
   selector: "app-cuadro-producto-detalle",
   templateUrl: "./cuadro-producto-detalle.component.html",
   styleUrls: ["./cuadro-producto-detalle.component.scss"],
})
export class CuadroProductoDetalleComponent implements OnInit {
   public loading: boolean = false;
   public searchCatalog: Producto[] = [];
   public searchLoading: boolean = false;
   public currentProduct: Producto = new Producto();
   public detalleProductoFormGroup: FormGroup;
   public detalleDte: DetalleDte = new DetalleDte();
   public currentInvoiceType: InvoiceType = InvoiceType.Factura;
   private currentEmisorId = this.tokenService.getCompany();
   private sucursalId: string = this.tokenService.getSubsidiary();
   public workWithProduct: boolean = false;
   public productRows: Producto[] = [];
   public pageProducts = new Page();
   private productFilter = new ProductoFilter();
   private productListModalRef: any;
   public SelectionType = SelectionType;
   public configLocal = ConfigProductosDetalle;
   private createProductModal: any;
   public opciones: OptionsReferenceDteEmitido = new OptionsReferenceDteEmitido();
   private opcionesObservable$: Observable<OptionsReferenceDteEmitido>;
   public consultandoProducto: boolean = false;
   constructor(private impuestoService: ImpuestoService, private ref: ChangeDetectorRef, private formBuilder: FormBuilder, private facturador: FacturacionIndividualService, private tokenService: TokenStorageService, private productoService: ProductoService, private utilities: UtilitiesService, private modalService: NgbModal, public permissions: PermissionService) {
      this.productFilter.emisorId = this.currentEmisorId;
      this.productFilter.sucursalId = this.sucursalId;
      this.pageProducts.pageSize = 10;
      this.opcionesObservable$ = this.facturador.opcionesReferenciaObservable;
   }

   ngOnInit(): void {
      this.setForm();
      this.opcionesObservable$.subscribe((opciones) => {
         this.opciones = opciones;
         //this.onSubmit()
      });
   }

   refreshItemDetail(event: Producto) {
      //set selected item to form
      this.currentProduct = event;
      if (event && event.productoId) {
         this.getTaxesOfProduct(event);
         this.selectedProductToForm(this.currentProduct);
      }
   }
   clearProduct($event?) {
      this.currentProduct = new Producto();
      this.detalleProductoFormGroup.controls.codigo.patchValue(this.currentProduct.codigoBarra);
      this.detalleProductoFormGroup.controls.nombreItem.patchValue(this.currentProduct.nombre);
   }
   getTaxesOfProduct(prod: Producto) {
      this.consultandoProducto = true;
      this.impuestoService.taxesByProduct(prod).subscribe(
         (res) => {
            if (this.currentProduct) {
               this.currentProduct.impuestos = res.data as Impuesto[];
               this.selectedProductToForm(this.currentProduct);
               this.consultandoProducto = false;
               this.ref.detectChanges();
               //this.onSubmit();
            }
         },
         (error) => {
            Swal.fire({
               icon: "error",
               title: "Error en impuestos",
               text: "No fue posible obtener los impuestos del producto seleccionado",
            });
         }
      );
   }

   selectedProductToForm(selectedProduct: Producto) {
      if (selectedProduct) {
         this.detalleProductoFormGroup.controls.productId.patchValue(selectedProduct.productoId);
         this.detalleProductoFormGroup.controls.codigo.patchValue(selectedProduct.codigoBarra);
         this.detalleProductoFormGroup.controls.precioUnitario.patchValue(selectedProduct.precio);
         let ExentoValue = selectedProduct.exento;
         this.detalleProductoFormGroup.controls.exento.patchValue(ExentoValue);
         this.detalleProductoFormGroup.controls.nombreItem.patchValue(selectedProduct.nombre);
         this.detalleDte.createCurrentProduct = false;
         if (selectedProduct.impuestos != undefined) {
            let detalleDteImpuestoAdicionalList: DetalleDteImpuestoAdicional[] = [];
            let impuestoFromProduct = [...selectedProduct.impuestos];
            impuestoFromProduct = [...impuestoFromProduct];
            detalleDteImpuestoAdicionalList = impuestoFromProduct.map((i) => {
               let impuestoAdicional = new DetalleDteImpuestoAdicional();
               impuestoAdicional.codigoSii = i.tipoImpuesto;
               impuestoAdicional.nombreImp = i.nombre;
               impuestoAdicional.valor = Math.round((i.valor / 100) * this.detalleDte.total);
               impuestoAdicional.nroImp = selectedProduct.impuestos.indexOf(i) + 1;
               impuestoAdicional.tasa = i.valor;
               impuestoAdicional.impuestoIdRelated = i.impuestoId;
               return impuestoAdicional;
            });
            this.detalleDte.impuestosAdicionales = [...detalleDteImpuestoAdicionalList];
            this.detalleDte.impuestosAdicionales = [...this.detalleDte.impuestosAdicionales];
         } else {
            this.detalleDte.impuestosAdicionales = [];
         }

         if (selectedProduct.unidadMedida != undefined && selectedProduct.unidadMedida != "") {
            this.detalleProductoFormGroup.controls.unidadMedida.patchValue(selectedProduct.unidadMedida);
            //this.detalleFormGroup.controls.haveDescripcion.patchValue(true);
         }
         this.searchCatalog = [];
         this.ref.detectChanges();
      }
   }

   onSubmit(formLocalRef?: FormGroupDirective) {
      if (this.detalleProductoFormGroup.valid && !this.consultandoProducto) {
         let newDetail = new DetalleDte();
         this.detalleDte = newDetail;
         this.detalleDte.cantidad = this.detalleProductoFormGroup.controls.cantidad.value;
         this.detalleDte.codigoInterno = this.detalleProductoFormGroup.controls.codigo.value;
         this.detalleDte.nombre = this.detalleProductoFormGroup.controls.nombreItem.value;
         //this.detalleDte.descripcion = this.detalleDte.nombre;
         this.detalleDte.unidadMedida = this.detalleProductoFormGroup.controls.unidadMedida.value;
         this.detalleDte.precioUnitario = this.detalleProductoFormGroup.controls.precioUnitario.value;
         this.detalleDte.relatedProductId = this.detalleProductoFormGroup.controls.productId.value;
         this.detalleDte.relatedProduct = { ...this.currentProduct };
         if (this.detalleDte.relatedProduct && this.currentProduct.impuestos != undefined) {
            let detalleDteImpuestoAdicionalList: DetalleDteImpuestoAdicional[] = [];
            let impuestoFromProduct = [...this.currentProduct.impuestos];
            impuestoFromProduct = [...impuestoFromProduct];
            detalleDteImpuestoAdicionalList = impuestoFromProduct.map((i) => {
               let impuestoAdicional = new DetalleDteImpuestoAdicional();
               impuestoAdicional.codigoSii = i.tipoImpuesto;
               impuestoAdicional.nombreImp = i.nombre;
               impuestoAdicional.valor = Math.round((i.valor / 100) * this.detalleDte.total);
               impuestoAdicional.nroImp = this.currentProduct.impuestos.indexOf(i) + 1;
               impuestoAdicional.tasa = i.valor;
               impuestoAdicional.impuestoIdRelated = i.impuestoId;
               return impuestoAdicional;
            });
            this.detalleDte.impuestosAdicionales = [...detalleDteImpuestoAdicionalList];
            this.detalleDte.impuestosAdicionales = [...this.detalleDte.impuestosAdicionales];
         } else {
            this.detalleDte.impuestosAdicionales = [];
         }
         this.facturador.addDetailProductSubject.next(this.detalleDte);
         formLocalRef.reset();
         formLocalRef.resetForm();
         this.clearProduct();
         this.detalleProductoFormGroup.reset();
         this.detalleProductoFormGroup.controls.cantidad.patchValue(1);
         this.detalleDte = new DetalleDte();
         this.detalleProductoFormGroup.updateValueAndValidity();
      }
   }

   searchItem(event, propertyName) {
      this.searchLoading = true;
      this.workWithProduct = false;
      this.facturador.searchProducts(propertyName, event.term, this.currentEmisorId).subscribe(
         (res) => {
            this.searchCatalog = [...res.data] as Producto[];
            this.ref.detectChanges();
            this.searchLoading = false;
         },
         (error) => {
            this.searchLoading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error en productos",
               text: "No fue posible obtener los productos concidentes con el termino buscado",
            });
         }
      );
   }

   AddProductByName = (prod: string) => {
      this.detalleProductoFormGroup.get("nombreItem").patchValue(prod);
      if (!this.detalleProductoFormGroup.get("codigo").value || this.detalleProductoFormGroup.get("codigo").value == "") {
         Swal.fire({
            icon: "question",
            title: "¿Agregar nuevo producto?",
            text: 'Para agregar el nuevo producto de nombre "' + prod + '", ingrese además un código de producto en el Selector de producto por Código y presione la opción de "Agregar Producto" en la pestaña de dicho selector. Este producto será agregado si la transacción se realiza con éxito. En caso contrario, realice una nueva búsqueda y seleccione un producto existente.',
         });
         return;
      }
      this.detalleDte.nombre = prod;
      //this.detalleDte.codigoInterno = "";
      this.detalleDte.codigoInterno = this.detalleProductoFormGroup.get("codigo").value;
      this.detalleDte.nombre = this.detalleProductoFormGroup.get("nombreItem").value;
      this.detalleDte.esExento = this.detalleProductoFormGroup.controls.exento.value;
      this.detalleDte.createCurrentProduct = true;
      this.workWithProduct = null;
   };

   setForm() {
      this.detalleProductoFormGroup = this.formBuilder.group({
         codigo: [],
         nombreItem: [,],
         precioUnitario: [,],
         exento: [],
         unidadMedida: [],
         cantidad: [1],
         productId: [],
      });
   }

   AddProductByCode = (code: string) => {
      this.detalleProductoFormGroup.get("codigo").patchValue(code);
      if (!this.detalleProductoFormGroup.get("nombreItem").value || this.detalleProductoFormGroup.get("nombreItem").value == "") {
         Swal.fire({
            icon: "question",
            title: "¿Agregar nuevo producto?",
            text: 'Para agregar el nuevo producto de código "' + code + '", ingrese además un nombre nuevo para el producto en el Selector de producto por Nombre y presione la opción de "Agregar Producto" en la pestaña de dicho selector. Este producto será agregado si la transacción se realiza con éxito. En caso contrario, realice una nueva búsqueda y seleccione un producto existente.',
         });
         return;
      }
      this.detalleDte.codigoInterno = code;
      this.detalleDte.nombre = this.detalleProductoFormGroup.get("nombreItem").value;
      this.detalleDte.esExento = this.detalleProductoFormGroup.controls.exento.value;
      this.detalleDte.createCurrentProduct = true;
      this.workWithProduct = null;
   };

   setPageProducts(pageInfo) {
      this.loading = true;
      this.pageProducts.pageNumber = pageInfo.offset;
      this.pageProducts.pageNumber += 1;

      this.productoService
         .getDataByPage(this.productFilter, this.pageProducts)
         .subscribe(
            (pagedData) => {
               let elements = pagedData.data as Producto[];
               // this.ref.detectChanges();
               // elements.forEach((row) => {});
               // this.productRows = [...elements];
               this.productRows = elements;
               this.setPagefromSucursalResponse(this.pageProducts, pagedData);
               this.loading = false;
               this.ref.detectChanges();
            },
            (error) => {
               this.loading = false;
               Swal.fire({
                  icon: "error",
                  title: "Error en la solicitud",
                  text: this.utilities.setErrorMessageFromArray(error.error),
               });
            },
            () => {
               this.loading = false;
               this.ref.detectChanges();
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
         });
   }

   setPagefromSucursalResponse(currentPage: Page, data: PagedResponse<Producto>) {
      currentPage.pageSize = data.pageSize;
      currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
      currentPage.totalPages = data.totalPages;
      currentPage.totalElements = data.totalRecords;
   }

   openProducListModal(modalRef) {
      this.productFilter.nombre = null;
      this.productFilter.codigoBarra = null;
      this.setPageProducts({ offset: 0 });
      this.productListModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "xl",
      });
   }

   onActivate(event) {
      if (event.type == "click") {
         this.currentProduct = event.row;
         this.getTaxesOfProduct(event.row);
         this.selectedProductToForm(event.row);
         this.productListModalRef.close();
      }
   }

   searchElementsByFilter(event) {
      let filterLocal = new ProductoFilter();
      this.productFilter = { ...filterLocal };
      this.productFilter[event.criterio] = event.busqueda;
      this.productFilter.activo = event.registroActivo;
      this.productFilter.exento = event.productoExento;
      this.productFilter.emisorId = this.currentEmisorId;
      this.productFilter.sucursalId = this.sucursalId;
      this.setPageProducts({ offset: 0 });
   }

   openCreateProductModal(modalRef) {
      this.createProductModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
      });
   }
   closeCreateModal() {
      this.createProductModal.close();
   }

   productWithTaxesRequest(event: Producto) {
      if (!event.impuestos || event.impuestos.length <= 0) {
         this.sendNewProductOnly(event);
      } else {
         this.sendNewProductWithTaxes(event);
      }
   }

   sendNewProductOnly(event: Producto) {
      event.emisorId = this.currentEmisorId;
      event.sucursalId = this.sucursalId;
      this.loading = true;
      this.ref.detectChanges();
      this.productoService
         .sendProductRequest(event)
         .subscribe(
            (result) => {
               this.ref.detectChanges();

               this.setPageProducts({ offset: 0 });
               this.ref.detectChanges();
               Swal.fire({
                  icon: "success",
                  title: "Ingreso de Producto",
                  text: "Se ha guardado el producto ingresado exitosamente.",
               });
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible guardar el producto ingresado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.closeCreateModal();
            this.ref.detectChanges();
         });
   }

   sendNewProductWithTaxes(event: Producto) {
      event.emisorId = this.currentEmisorId;
      event.sucursalId = this.sucursalId;

      this.loading = true;
      this.ref.detectChanges();
      this.productoService
         .sendProductRequest(event)
         .subscribe(
            (result) => {
               this.ref.detectChanges();
               let newProduct = result.data as Producto;
               if (event.impuestos) {
                  newProduct.impuestos = event.impuestos;
                  this.loading = true;
                  this.productoService.updateTaxes(newProduct).subscribe(
                     (result) => {
                        this.loading = false;
                        this.setPageProducts({ offset: 0 });
                        this.ref.detectChanges();
                        Swal.fire({
                           icon: "success",
                           title: "Ingreso de Producto",
                           text: "Se ha guardado el producto ingresado exitosamente.",
                        });
                     },
                     (error) => {
                        let errorText = error.error.message;
                        this.loading = false;
                        Swal.fire({
                           icon: "error",
                           title: "Error en Producto",
                           text: "No ha sido posible guardar el producto ingresado, intente nuevamente. " + errorText,
                        });
                     }
                  );
               }
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible guardar el producto ingresado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.closeCreateModal();
            this.ref.detectChanges();
         });
   }
}
