<form [formGroup]="clientForm" (ngSubmit)="onSubmit()" #clientFormRef="ngForm">
	<div class="modal-body">
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Rut</label>
				<div class="col-9">
					<input type="text" name="rut" id="rut" formControlName="rutString" class="form-control" [attr.disabled]="clientToEdit || editMode" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('rutString').invalid || clientForm.get('rutString').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('rutString').hasError('VerificacionRutValidator')">Rut inválido</div>
						<div *ngIf="clientForm.get('rutString').errors?.required">Campo requerido</div>
						<div *ngIf="clientForm.get('rutString').errors?.maxlength">Rut es demasiado largo</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Razón Social</label>
				<div class="col-9">
					<input type="text" name="razonSocial" id="razonSocial" formControlName="razonSocial" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('razonSocial').invalid || clientForm.get('razonSocial').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('razonSocial').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Giro</label>
				<div class="col-9">
					<input type="text" name="giro" id="giro" formControlName="giro" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('giro').invalid || clientForm.get('giro').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('giro').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Dirección de Facturación</label>
				<div class="col-9">
					<input type="text" name="dirFact" id="dirFact" formControlName="dirFact" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('dirFact').invalid || clientForm.get('dirFact').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('dirFact').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Correo Electrónico Facturación</label>
				<div class="col-9">
					<input type="text" name="correoFact" id="correoFact" formControlName="correoFact" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('correoFact').invalid || clientForm.get('correoFact').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('correoFact').errors?.required">Campo requerido</div>
						<div *ngIf="clientForm.get('correoFact').hasError('wrongEmail')">Correo inválido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Correo Electrónico Particular</label>
				<div class="col-9">
					<input type="text" name="correoPar" id="correoPar" formControlName="correoPar" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('correoPar').invalid || clientForm.get('correoPar').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('correoPar').hasError('wrongEmail')">Correo inválido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Ciudad</label>
				<div class="col-9">
					<input type="text" name="ciudad" id="ciudad" formControlName="ciudad" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('ciudad').invalid || clientForm.get('ciudad').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('ciudad').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Comuna</label>
				<div class="col-9">
					<input type="text" name="comuna" id="comuna" formControlName="comuna" class="form-control" [attr.disabled]="!editMode ? editMode : null" />
					<div *ngIf="clientFormRef.submitted && (clientForm.get('comuna').invalid || clientForm.get('comuna').dirty)" class="invalid-feedback">
						<div *ngIf="clientForm.get('comuna').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" *ngIf="editMode || editMode === null">
			<i class="la la-paper-plane-o d-lg-none"></i>
			<span class="d-none d-lg-block">{{ clientToEdit ? "Guardar" : "Generar" }}</span>
		</button>
		<!-- <button type="button" id="add-contact-item" class="btn btn-warning add-contact-item" (click)="onSubmitThenContract(clientFormRef,$event)" *ngIf="!clientToEdit && permissions.getPermission('30-20-16').asignado">
        <i class="la la-paper-plane-o d-lg-none"></i>
        <span class="d-none d-lg-block">Contrato</span>
     </button> -->
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
