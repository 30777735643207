import { Component, OnInit } from "@angular/core";

@Component({
   selector: "app-dropdown-menu1",
   templateUrl: "./dropdown-menu1.component.html",
})
export class DropdownMenu1Component implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
