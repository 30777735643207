<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' ,fullScreenBackdrop:true}"
    [template]="customLoadingTemplate"></ngx-loading>
<ngx-loading [show]="loadingDivisas$ | async" [config]="{ backdropBorderRadius: '3px' ,fullScreenBackdrop:true}"
    [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<!-- container -->
<div class="app-content content">
    <div class="content-wrapper">
        <div class="container-header row mb-1"></div>
        <div class="content-detached">
            <div class="content-body">
                <!-- boton envio dte -->
                <div class="row">
                    <div class="col-12">

                    </div>
                </div>
                <!-- detalles cliente -->
                <div class="row">
                    <div class="col-12">
                        <app-buscador-cliente (clientForInvoice)="addClientToInvoiceRequest($event)"></app-buscador-cliente>
                    </div>
                </div>
                <!-- fin detalles cliente -->

                <!-- opciones y referencias -->
                <div class="row mt-5">
                    <div class="col-12">
                        <app-opciones-referencias-dte></app-opciones-referencias-dte>
                    </div>
                </div>
                <!-- fin opciones y referencias -->
                <!-- lista de detalles -->
                <div class="row mt-5">
                    <div class="col-12">
                        <app-lista-item-detalle-dte></app-lista-item-detalle-dte>
                    </div>
                </div>
                <!-- fin lista de detalles -->
                <!-- lista de detalles comisiones -->
                <div class="row mt-5" *ngIf="showInSettlement">
                    <div class="col-12">
                        <app-lista-item-detalle-comision></app-lista-item-detalle-comision>
                    </div>
                </div>
                <!-- fin lista de detalles -->

                <!-- resumen lista detalles -->
                <div class="row mt-5">
                    <div class="col-12">
                        <app-resumen-lista-detalles></app-resumen-lista-detalles>
                    </div>
                </div>
                <!-- fin resumen lista detalles -->
                <!-- botonera -->
                <!-- <div class="row mt-5">
                    <div class="col-4  text-center">
                        <div class="text-center">
                            <button class="btn btn-success mb-5 mr-5" (click)="consolidateDte(true)">
                                <i class="flaticon-file-2"></i>
                                Generar
                            </button>
                            <button class="btn btn-primary mb-5 mr-5" (click)="consolidateDte(false)">
                                <i class="flaticon-search"></i>
                                Previsualizar
                            </button>
                            <button class="btn btn-danger mb-5 mr-5" (click)="returnToMaintainer()">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- end container -->
<swal #currentSwal title="Operación" text="Operación realizada" icon="info" style="z-index: 900000 !important;"></swal>
<ng-template #GuidesListToInvoices let-d="dismiss" let-c="close">
    <div class="modal-lg">
        <div class="modal-header">
            <div class="modal-wizard-label">
                <div class="modal-wizard-title">Guias de Despacho a facturar</div>
                <div class="modal-wizard-desc">Esta es la lista de guias de despacho del cliente seleccionado</div>
            </div>
        </div>
        <div class="modal-body">
            <ngx-datatable #tableGuides class="material" [rows]="listaGuiasFacturables" [columnMode]="'flex'"
                [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [perfectScrollbar]="configScroll"
                [selectionType]="'checkbox'" [selected]="selectedGuides" (selected)="onSelect($event)"
                [messages]="{emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false}">
                <ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value">
                        <input type="checkbox" class="customCheckbox" [checked]="selectedAllItemsInPage[0]"
                            (change)="selectAll($event)" />
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                        let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <input type="checkbox" class="customCheckbox" [checked]="isSelected"
                            (change)="onCheckboxChangeFn($event)" />
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Folio" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row"><span>{{row.folio}}</span></ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Monto" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row"><span>{{row.total | currency : '$ ' :
                            'symbol':'1.0-0':'es'}}</span></ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Fecha" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row"><span>{{row.fecha | date:
                            "dd/MM/yyyy"}}</span></ng-template>

                </ngx-datatable-column>
            </ngx-datatable>
        </div>
        <div class="modal-footer">
            <div class="form-group position-relative has-icon-left mb-0">
                <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item"
                    (click)="processGuidesIntoInvoice()">
                    <i class="la la-paper-plane-o d-lg-none"></i>
                    <span class="d-none d-lg-block">Enviar</span>
                </button>
                <button type="button" class="btn btn-secondary ml-3" (click)="c('Cross click')">
                    <span class="d-none d-lg-block">Cerrar</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>