import { Directive, ElementRef } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";
import { columnTotalWidth } from "@swimlane/ngx-datatable";

export function validarRut(rut: string): boolean {
	if (rut == null) return null;
	rut = rut.toUpperCase().trim().replace(".", "").replace("-", "");
	let digitoVerificador: string = rut.slice(-1);
	let arregloVerificadores: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "K"];
	if (!arregloVerificadores.includes(digitoVerificador)) return false;
	let mantisa = rut.substring(0, rut.length - 1);
	if (!mantisa.match(/^\d+$/)) return false;
	if (mantisa.length > 8 || mantisa.length < 7) return false;
	let suma = 0,
		multiplo = 2,
		index = 0;
	for (let i = 1; i <= mantisa.length; i++) {
		index = multiplo * parseInt(rut.charAt(mantisa.length - i));
		suma = suma + index;
		if (multiplo < 7) {
			multiplo = multiplo + 1;
		} else {
			multiplo = 2;
		}
	}

	let dvEsperado = (11 - (suma % 11)).toString();

	digitoVerificador = digitoVerificador == "K" ? "10" : digitoVerificador;
	digitoVerificador = digitoVerificador == "0" ? "11" : digitoVerificador;
	if (dvEsperado != digitoVerificador) return false;

	return true;
}

//TODO: Deprecar appVerificaRut
@Directive({
	selector: "[appVerificaRut]",
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: VerificacionRutValidatorDirective,
			multi: true,
		},
	],
})
export class VerificacionRutValidatorDirective implements Validator {
	constructor(private el: ElementRef) {}
	validate(control: AbstractControl): { [key: string]: any } | null {
		if (control.value.length > 7) {
			var valido = validarRut(control.value);
			if (!valido && control.value != "") {
				return { rutNoValido: true };
			} else if (valido) {
				let valueWithoutHyphen = this.el.nativeElement.value.replace("-", "").replace(".", "");
				this.el.nativeElement.value = valueWithoutHyphen.slice(0, -1) + "-" + valueWithoutHyphen.slice(-1);
			}
		}
		return null;
	}
}

export function VerificacionRutValidator(control: AbstractControl): { [key: string]: any } | null {
	let rutValido = validarRut(control.value);
	if (!rutValido && control.value != "") {
		return { VerificacionRutValidator: true };
	}
	return null;
}
