import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { EsquemaCobro } from "src/app/models/EsquemaCobro";
import { Sucursal } from "src/app/models/Sucursal";
import { Usuario } from "src/app/models/Usuario/Usuario";
import { AuthService } from "src/app/modules/auth";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { FormrulesConfigService } from "src/app/shared/configuration/formrules-config.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-user-panel",
	templateUrl: "./user-panel.component.html",
	styleUrls: ["./user-panel.component.scss"],
})
export class UserPanelComponent implements OnInit {
	public loading: boolean = false;
	public currentUser: Usuario = new Usuario();
	public currentId: string = "";
	private fullListPermission: boolean = false;
	private userIdState: Observable<object>;
	public configScroll: PerfectScrollbarConfigInterface = {};
	public listaSucursales: Sucursal[] = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
	public emisores: Emisor[] = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
	public emisorId: string;
	public usuarioForm: FormGroup;
	public passForm: FormGroup;
	public usuarioObj: Usuario = new Usuario();
	private usuarioModalRef: NgbModalRef;
	private passModalRef: NgbModalRef;
	constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private tokenService: TokenStorageService, private userService: UsuarioService, private modalService: NgbModal, public permissions: PermissionService, public authService: AuthService, private formConfig: FormrulesConfigService, private localStorage: LocalStorageService, private ref: ChangeDetectorRef) {
		this.emisorId = tokenService.getCompany();
		this.fullListPermission = this.tokenService.getUser().superAdmin; //this.permissions.getPermission("10-10").asignado;
	}

	ngOnInit(): void {
		//this.setEmisoresToSucursales();
		this.createForm();
		this.createPassForm();
		this.checkUserByRoute();
		this.getUserInRoute();
	}

	checkUserByRoute() {
		this.userIdState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));
	}

	getUserInRoute() {
		this.loading = true;
		this.userIdState.subscribe((result) => {
			this.currentId = result["userId"];
			this.userService.getUser(this.currentId).subscribe(
				(response) => {
					this.currentUser = response.data;
					this.setFormData(this.currentUser);
					this.userService.getSucursalesByUser(this.currentUser.usuarioId).subscribe(
						(result) => {
							this.listaSucursales = result.data;
							this.listaSucursales = [...this.listaSucursales];
							this.listaSucursales = this.listaSucursales.filter((s) => s.emisorId == this.emisorId);
							//this.setEmisoresToSucursales();
							this.loading = false;
							this.ref.detectChanges();
						},
						(error) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								title: "Error en datos",
								text: "No fue posible obtener las sucursales del usuario actual, favor intente nuevamente",
								icon: "error",
							});
						}
					);
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();

					Swal.fire({
						title: "Error en datos",
						text: "No fue posible obtener los datos del usuario actual, favor intente nuevamente",
						icon: "error",
					});
				}
			);
		});
	}

	setEmisoresToSucursales() {
		this.listaSucursales.map((suc) => {
			suc.emisorNombre = this.emisores.find((emisor) => emisor.emisorId == suc.emisorId).razonSocial;
		});
	}

	createForm() {
		this.usuarioForm = this.formBuilder.group({
			rutString: ["", [Validators.required, VerificacionRutValidator]],
			nombre: ["", Validators.required],
			apellidos: ["", Validators.required],
			cargo: ["", Validators.required],
			comuna: ["", Validators.required],
			direccion: ["", Validators.required],
			telefono: [0, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
			email: ["", [Validators.required, SingleEmailValidator]],
		});
	}
	onSubmit() {
		if (this.usuarioForm.valid) {
			this.loading = true;
			this.getFormData();
			//console.log("default submit",this.cesionarioObj);
			this.userService.updateUser(this.usuarioObj).subscribe(
				(res) => {
					this.loading = false;
					this.ref.detectChanges();
					this.getUserInRoute();
					Swal.fire({
						icon: "success",
						title: "Usuario Actualizado",
						text: "Datos de usuario actualizados exitosamente",
					});
					this.sendCloseRequest();
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						title: "Error al Actualizar Usuario",
						text: "No fue posible actualizar datos del usuario, favor intente nuevamente",
						icon: "error",
					});
				}
			);
		}
	}
	getFormData() {
		var rut = this.usuarioForm.controls.rutString.value;
		this.usuarioObj.nombre = this.usuarioForm.controls.nombre.value;
		this.usuarioObj.direccion = this.usuarioForm.controls.direccion.value;
		this.usuarioObj.apellidos = this.usuarioForm.controls.apellidos.value;
		this.usuarioObj.telefono = this.usuarioForm.controls.telefono.value;
		this.usuarioObj.direccion = this.usuarioForm.controls.direccion.value;
		this.usuarioObj.comuna = this.usuarioForm.controls.comuna.value;
		this.usuarioObj.email = this.usuarioForm.controls.email.value;
	}

	setFormData(usuario: Usuario) {
		this.usuarioObj.rut = usuario.rut;
		this.usuarioObj.dv = usuario.dv;
		this.usuarioObj.cargo = usuario.cargo;
		this.usuarioObj.activo = usuario.activo;
		this.usuarioForm.get("rutString").patchValue(usuario.rut + "-" + usuario.dv);
		this.usuarioForm.get("nombre").patchValue(usuario.nombre);
		this.usuarioForm.get("apellidos").patchValue(usuario.apellidos);
		this.usuarioForm.get("cargo").patchValue(usuario.cargo);
		this.usuarioForm.get("comuna").patchValue(usuario.comuna);
		this.usuarioForm.get("direccion").patchValue(usuario.direccion);
		this.usuarioForm.get("telefono").patchValue(usuario.telefono);
		this.usuarioForm.get("email").patchValue(usuario.email);
		this.usuarioObj.usuarioId = usuario.usuarioId;
	}

	newUserModal(modalRef) {
		this.usuarioModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	sendCloseRequest() {
		this.usuarioModalRef.close();
	}

	createPassForm() {
		this.passForm = this.formBuilder.group({
			currentPass: ["", Validators.compose([Validators.required, Validators.minLength(this.formConfig.passwordMinLength), Validators.maxLength(this.formConfig.passwordMaxLength), Validators.pattern(this.formConfig.passwordPatternRegex)])],
			pass: ["", Validators.compose([Validators.required, Validators.minLength(this.formConfig.passwordMinLength), Validators.maxLength(this.formConfig.passwordMaxLength), Validators.pattern(this.formConfig.passwordPatternRegex)])],
			repeatPass: ["", Validators.compose([Validators.required, Validators.minLength(this.formConfig.passwordMinLength), Validators.maxLength(this.formConfig.passwordMaxLength), Validators.pattern(this.formConfig.passwordPatternRegex)])],
		});
	}

	changePassModal(modalRef) {
		this.passModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	sendClosePassRequest() {
		this.passModalRef.close();
	}

	changePass() {
		var currentPass = this.passForm.controls.currentPass.value;
		var pass = this.passForm.controls.pass.value;
		var repeatPass = this.passForm.controls.repeatPass.value;
		if (pass != repeatPass) {
			Swal.fire({
				title: "Las Contraseñas no Coinciden",
				text: "La nueva contraseña no coincide con la de confirmación",
				icon: "info",
			});
		} else {
			this.loading = true;
			this.authService.changePass(this.currentUser.usuarioId, currentPass, pass).subscribe(
				(res) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Cambio de Contraseña Exitoso",
						text: res.message,
					});
					this.passForm.reset();
					this.sendClosePassRequest();
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						title: "Error al Actualizar Contraseña",
						text: "No fue posible actualizar la contraseña del usuario, " + err.error.message,
						icon: "error",
					});
				}
			);
		}
	}
}
