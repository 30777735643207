import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { ConfiguracionEmision } from "src/app/models/ConfiguracionEmision";
import { FormatoPapel } from "src/app/models/FormatoPapel";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { TipoDte } from "src/app/models/tipoDte";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import Swal from "sweetalert2";

@Component({
   selector: "app-configuracion-emision-entry",
   templateUrl: "./configuracion-emision-entry.component.html",
   styleUrls: ["./configuracion-emision-entry.component.scss"],
})
export class ConfiguracionEmisionEntryComponent implements OnInit {
   public configForm: FormGroup;
   public emisorId: string;
   @Input() public detailMode: boolean = false;
   @Input() public editMode: boolean = false;
   @Input() public configToEdit: ConfiguracionEmision = undefined;
   @Input() public noEditTypeDte: boolean = false;
   @Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
   @Output() private sendConfigRequest: EventEmitter<any> = new EventEmitter<any>();
   public tipoDteSource: TipoDte[] = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
   public formatoPapelSource: FormatoPapel[] = JSON.parse(this.localStorage.getFormatoPapel()) as FormatoPapel[];
   public files: File[] = [];
   private configObj: ConfiguracionEmision = new ConfiguracionEmision();
   constructor(private localStorage: LocalStorageService, private formBuilder: FormBuilder, private tokenService: TokenStorageService) {
      this.emisorId = this.tokenService.getCompany();
   }

   ngOnInit(): void {
      this.setConfigForm();
      if (this.configToEdit) {
         this.setConfigDataToForm(this.configToEdit);
      }
   }

   setConfigForm() {
      this.configForm = this.formBuilder.group({
         lineas: ["", [Validators.required, PositiveNumberValidator, Validators.min(1), Validators.max(30)]],
         tipoDocumento: [, Validators.required],
      });
   }

   onSubmit() {
      if (this.configForm.valid) {
         this.getFormData();
         this.sendConfigRequest.emit(this.configObj);
      }
   }

   sendCloseRequest() {
      this.sendCloseRequestEmitter.emit();
   }

   getFormData() {
      this.configObj.maxLineasDetalle = this.configForm.controls.lineas.value;
      let tipoDteObj: TipoDte = this.configForm.controls.tipoDocumento.value;
      this.configObj.tipoDteId = tipoDteObj.tipoDteId;
      this.configObj.codigoTipoDte = tipoDteObj.codigosii;
      this.configObj.nombreTipoDte = tipoDteObj.nombre;
      this.configObj.emisorId = this.emisorId;
      this.configObj.configEmisionId = this.editMode ? this.configToEdit.configEmisionId : undefined;
   }

   setConfigDataToForm(config: ConfiguracionEmision) {
      this.configForm.patchValue(config);
      let tipoDteObj: TipoDte = this.tipoDteSource.find((tipo) => tipo.tipoDteId == config.tipoDteId);
      this.configForm.get("tipoDocumento").patchValue(tipoDteObj);
      this.configForm.get("lineas").patchValue(config.maxLineasDetalle);
      //this.plantillaForm.updateValueAndValidity();
   }
   addTipoDocToList() {}
   filtro() {
      if (this.configForm.get("tipoDocumento").value) {
         let selected = this.configForm.get("tipoDocumento").value as TipoDte;
         this.tipoDteSource = this.tipoDteSource.filter((td) => td.tipoDteId == selected.tipoDteId);
      } else {
         this.getTipoDteList();
      }
   }
   getTipoDteList() {
      this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
   }
}
