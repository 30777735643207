import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Receptor } from "../models/Receptor/Receptor";
import { ReceptorFilter } from "../models/Receptor/ReceptorFilter";
import { ReceptorRequest } from "../models/Receptor/ReceptorRequest";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Response } from "../shared/models/Response";
@Injectable({
	providedIn: "root",
})
export class ClienteService {
	private REST_API_SERVER = environment.backend_server + "/clientes";
	constructor(private http: HttpClient) {}

	getList(emisorId: string): Observable<Response<Receptor[]>> {
		return this.http.get<Response<Receptor[]>>(this.REST_API_SERVER + "/buscar?pattern=");
	}

	getDataByPage(filtro: ReceptorFilter, page: Page): Observable<PagedResponse<Receptor>> {
		let stringRut = filtro.rutReceptorDv && filtro.rutReceptorDv != undefined ? filtro.rutReceptorDv : null;
		let razonSocial = filtro.razonSocial != undefined ? filtro.razonSocial : "";
		let giro = filtro.giro ? filtro.giro : "";
		let emisorId = filtro.emisorId ? filtro.emisorId : "";
		let activo = filtro.activo;
		return this.http.get<PagedResponse<Receptor>>(this.REST_API_SERVER + "/list/filter" + "?rutReceptorDv=" + stringRut + "&razonSocial=" + razonSocial + "&giro=" + giro + "&activo=" + activo + "&EmisorId=" + emisorId + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	sendClientRequest(cliente: Receptor): Observable<Response<boolean>> {
		cliente.dirPart = "";
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/Crear", cliente);
	}

	getFromSii(rut: string, emisorId: string): Observable<Response<Receptor>> {
		return this.http.get<Response<Receptor>>(this.REST_API_SERVER + "/get-from-sii?rut=" + rut + "&emisorId=" + emisorId);
	}

	editClientRequest(cliente: ReceptorRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/Actualizar", cliente);
	}

	deactivateClient(receptorId, emisorId): Observable<Response<Receptor>> {
		return this.http.post<Response<Receptor>>(this.REST_API_SERVER + "/" + receptorId + "/Desactivar?emisorId=" + emisorId, null);
	}
}
