import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Response } from "src/app/shared/models/Response";
import { Observable } from "rxjs";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Page } from "../shared/models/Page";
import { Permiso } from "../models/Perfil/Permiso";
import { Perfil } from "../models/Perfil/Perfil";
import { PerfilFilter } from "../models/Perfil/PerfilFilter";
import { PermisoFilter } from "../models/Perfil/PermisoFilter";
import { Emisor } from "../models/Emisor/Emisor";
import { PerfilEmisor } from "../models/Perfil/PerfilEmisor";

@Injectable({
	providedIn: "root",
})
export class PerfilService {
	private REST_API_SERVER = environment.backend_server + "/Perfil";
	private auth = {};
	constructor(private http: HttpClient) {}

	public getPermissions(by: string, id: string): Observable<Response<Permiso[]>> {
		//console.log(by,id);
		return this.http.get<Response<Permiso[]>>(this.REST_API_SERVER + "/getPermissions/" + by + "/" + id);
	}

	public getProfilesDataByPage(filtro: PerfilFilter, page: Page): Observable<PagedResponse<Perfil>> {
		return this.http.get<PagedResponse<Perfil>>(this.REST_API_SERVER + "/listProfile/filter?Nombre=" + (filtro.nombre != undefined ? filtro.nombre : "") + "&EmisorId=" + (filtro.emisorid != undefined ? filtro.emisorid : "") + "&UsuarioId=" + filtro.usuarioId + "&Activo=" + filtro.activo + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	public getPermissionsDataByPage(filtro: PermisoFilter, page: Page): Observable<PagedResponse<Permiso>> {
		return this.http.get<PagedResponse<Permiso>>(this.REST_API_SERVER + "/listPermission/filter?Nombre=" + (filtro.nombre != undefined ? filtro.nombre : "") + "&Activo=" + filtro.activo + "&Codigo=" + (filtro.codigo != undefined ? filtro.codigo : "") + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	updatePermissionsProfile(request: Perfil): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updatePermissionsProfile", request);
	}
	updateProfile(request: Perfil): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateProfile", request);
	}
	updatePermission(request: Permiso): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updatePermission", request);
	}

	insertProfile(request: Perfil, emisorId: String): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/addProfile/" + emisorId, request);
	}
	insertPermission(request: Permiso): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/addPermission", request);
	}

	deactivateProfile(profileId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivateProfile/" + profileId);
	}
	deactivatePermission(permissionId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivatePermission/" + permissionId);
	}
	activateProfile(profileId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activateProfile/" + profileId);
	}
	activatePermission(permissionId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activatePermission/" + permissionId);
	}

	getProfiles(): Observable<Response<Perfil[]>> {
		return this.http.get<Response<Perfil[]>>(this.REST_API_SERVER + "/");
	}
	emisorListOfProfile(perfil: Perfil): Observable<Response<Emisor[]>> {
		return this.http.get<Response<Emisor[]>>(this.REST_API_SERVER + "/emisoresparaelperfil?perfilId=" + perfil.perfilId);
	}

	assignEmisorToPerfil(emisor: Emisor, perfil: Perfil): Observable<Response<string>> {
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/emisor/insert?emisorId=" + emisor.emisorId + "&perfilId=" + perfil.perfilId, undefined);
	}
	assignEmisorToPerfilById(emisorId: String, perfil: Perfil): Observable<Response<string>> {
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/emisor/insert?emisorId=" + emisorId + "&perfilId=" + perfil.perfilId, undefined);
	}

	unassigEmisorToPerfil(emisor: Emisor, perfil: Perfil): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER + "/emisor/desactivarperfil?emisorId=" + emisor.emisorId + "&perfilId=" + perfil.perfilId);
		// return this.http.get<Response<any>>(this.REST_API_SERVER+'/emisor/desactivarperfil?perfilEmisorEnt='+JSON.stringify(perfEm));
	}
}
