<div
	class="row"
	[ngClass]="{
		'change-company-subsidiary': sucursalesOfSelectedEmisorSource.length > 1,
		'change-company-only': sucursalesOfSelectedEmisorSource.length <= 1
	}">
	<div
		class=""
		[ngClass]="{
			'col-12': sucursalesOfSelectedEmisorSource.length <= 1,
			'col-6': sucursalesOfSelectedEmisorSource.length > 1
		}">
		<ng-select *ngIf="permissions.getPermission('60').asignado" [items]="emisoresSource" placeholder="Empresa" bindLabel="razonSocial" (change)="SetNewCompany()" [(ngModel)]="currentEmisorObject" [clearable]="false"> </ng-select>
		<div *ngIf="!permissions.getPermission('60').asignado">
			<span>{{ currentEmisorObject.razonSocial }}</span>
		</div>
	</div>
	<div class="col-6" *ngIf="sucursalesOfSelectedEmisorSource.length > 1">
		<ng-select [items]="sucursalesOfSelectedEmisorSource" placeholder="Sucursal" bindLabel="nombre" [clearable]="false" [(ngModel)]="currentSucursalObject" (change)="SetNewSubsidiary($event)"></ng-select>
	</div>
</div>

<swal #currentSwal icon="info" title="Cambio de Empresa" text=""></swal>
