<form [formGroup]="plantillaForm" (ngSubmit)="onSubmit()" #plantillaFormRef="ngForm">
	<div class="modal-body">
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-3 label-control">Nombre Plantilla</label>
				<div class="col-9">
					<input
						type="text"
						class="form-control"
						id="descripcion"
						formControlName="descripcion"
						[attr.disabled]="detailMode ? detailMode : null"
						placeholder="Ingrese nombre de plantilla"
						[ngClass]="{
							'is-invalid': plantillaFormRef.submitted && plantillaForm.get('descripcion').invalid
						}" />
					<div *ngIf="plantillaFormRef.submitted && (plantillaForm.get('descripcion').invalid || plantillaForm.get('descripcion').dirty)" class="invalid-feedback">
						<div *ngIf="plantillaForm.get('descripcion').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-3 label-control">Tipo de documento</label>
				<div class="col-9">
					<ng-select
						[items]="tipoDteSource"
						[multiple]="false"
						placeholder="Seleccione Tipo de documento"
						formControlName="tipoDocumento"
						[clearable]="false"
						bindLabel="nombre"
						[ngClass]="{
							'is-invalid': plantillaFormRef.submitted && plantillaForm.get('tipoDocumento').invalid
						}"></ng-select>
					<div *ngIf="plantillaFormRef.submitted && (plantillaForm.get('tipoDocumento').invalid || plantillaForm.get('tipoDocumento').dirty || plantillaForm.get('tipoDocumento').touched || plantillaForm.get('tipoDocumento').value == null)" class="invalid-feedback">
						<div *ngIf="plantillaForm.get('tipoDocumento').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-3 label-control">Tipo Papel</label>
				<div class="col-9">
					<!-- <input type="text" class="form-control" id="tipoPapel" formControlName="tipoPapel" [attr.disabled]="detailMode?detailMode:null" placeholder="Ingrese tipo de papel"> -->
					<ng-select
						[items]="formatoPapelSource"
						[multiple]="false"
						placeholder="Seleccione Tipo de Papel"
						formControlName="formatoPapel"
						[clearable]="false"
						bindLabel="name"
						[ngClass]="{
							'is-invalid': plantillaFormRef.submitted && plantillaForm.get('formatoPapel').invalid
						}"></ng-select>
					<div *ngIf="plantillaFormRef.submitted && (plantillaForm.get('formatoPapel').invalid || plantillaForm.get('formatoPapel').dirty)" class="invalid-feedback">
						<div *ngIf="plantillaForm.get('formatoPapel').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-5 form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-3 label-control">Max Lineas</label>
				<div class="col-9">
					<input
						type="number"
						min="0"
						max="300"
						class="form-control"
						id="lineas"
						formControlName="lineas"
						[attr.disabled]="detailMode ? detailMode : null"
						placeholder="Ingrese máximo de lineas"
						[ngClass]="{
							'is-invalid': plantillaFormRef.submitted && plantillaForm.get('lineas').invalid
						}" />
					<div *ngIf="plantillaFormRef.submitted && (plantillaForm.get('lineas').invalid || plantillaForm.get('lineas').dirty)" class="invalid-feedback">
						<div *ngIf="plantillaForm.get('lineas').errors?.required">Campo requerido</div>
						<div *ngIf="plantillaForm.get('lineas').errors?.min">min 1 linea</div>
						<!-- <div *ngIf="plantillaForm.get('lineas').errors?.max">max 30 lineas</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-3 label-control">Plantilla HTML</label>
				<div class="col-9">
					<ngx-dropzone class="ml-5" (change)="onSelect($event)" maxFileSize="2097152" [accept]="'.html'" multiple="false">
						<ngx-dropzone-label class="px-5">
							<i class="flaticon-upload"></i>
							<p>Arrastra o selecciona tu archivo de plantilla de impresión.</p>

							<p>El formato del archivo debe ser .html</p>
						</ngx-dropzone-label>
						<!-- <ngx-dropzone-label class="d-inline">
                           
                        </ngx-dropzone-label> -->
						<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
							<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
						</ngx-dropzone-preview>
					</ngx-dropzone>
					<div *ngIf="plantillaFormRef.submitted && (plantillaForm.get('file').invalid || plantillaForm.get('file').dirty || plantillaForm.get('file').touched || plantillaForm.get('file').value == null)" class="invalid-feedback mx-auto drop-feedback px-40">
						<div *ngIf="plantillaForm.get('file').errors?.required" class="px-10">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-5 form-group position-relative mx-auto">
			<div class="row">
				<label for="defectoCheckbox" class="col-3 label-control">Plantilla por Defecto</label>
				<div class="col-9">
					<input type="checkbox" name="defectoCheckbox" formControlName="defectoCheckbox" class="input-checkbox" />
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" *ngIf="!detailMode">
			<i class="la la-paper-plane-o d-lg-none"></i>
			<span class="d-none d-lg-block">{{ plantillaToEdit ? "Guardar" : "Generar" }}</span>
		</button>
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
