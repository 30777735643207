import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Impuesto } from "src/app/models/Impuesto";
import { Producto } from "src/app/models/Producto/Producto";
import { LocalStorageService } from "src/app/services/local-storage.service";
import Swal from "sweetalert2";

@Component({
   selector: "app-product-entry",
   templateUrl: "./product-entry.component.html",
   styleUrls: ["./product-entry.component.scss"],
})
export class ProductEntryComponent implements OnInit {
   public productForm: FormGroup;
   public taxesList: Impuesto[] = [];
   private productObj: Producto = new Producto();
   @Input() public editMode: boolean = false;
   @Input() public detailMode: boolean = false;
   @Input() public productToEdit: Producto = undefined;
   @Input() public useTaxes: boolean = false;
   @Input() public useProductAndTaxes = false;
   @Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
   @Output() private sendProductRequest: EventEmitter<any> = new EventEmitter<any>();
   public taxesSource: Impuesto[] = JSON.parse(this.localStorage.getImpuestos()) as Impuesto[];
   constructor(private formBuilder: FormBuilder, private ref: ChangeDetectorRef, private localStorage: LocalStorageService) {}

   ngOnInit(): void {
      this.setProductForm();
      if (this.productToEdit) {
         this.setProductDataToForm(this.productToEdit);
      }
   }

   onSubmit() {
      if (this.productForm.valid) {
         this.getFormData();
         this.sendProductRequest.emit(this.productObj);
      }
   }

   getFormData() {
      this.productObj.nombre = this.productForm.controls.nombre.value;
      this.productObj.codigoBarra = this.productForm.controls.codigoBarra.value;
      this.productObj.precio = this.productForm.controls.precio.value;
      this.productObj.exento = this.productForm.controls.exento.value ? this.productForm.controls.exento.value : false;
      if (!this.productObj.exento) {
         this.productObj.impuestos = this.taxesList;
      } else {
         this.productObj.impuestos = [];
      }
      this.productObj.productoId = this.productToEdit ? this.productToEdit.productoId : "";
      this.productObj.emisorId = this.productToEdit ? this.productToEdit.emisorId : undefined;
      this.productObj.sucursalId = this.productToEdit ? this.productToEdit.sucursalId : undefined;
      this.productObj.activo = this.productToEdit ? this.productToEdit.activo : true;
      this.productObj.unidadMedida = this.productForm.controls.unidadMedida.value;
   }
   setProductForm() {
      this.productForm = this.formBuilder.group({
         nombre: ["", Validators.required],
         precio: [, Validators.required],
         codigoBarra: ["", Validators.required],
         exento: [false],
         impuesto: [],
         unidadMedida: [, [Validators.required, Validators.pattern(/^([^0-9]*)$/)]],
      });
   }

   addTaxToList() {
      let imp = this.productForm.get("impuesto").value as Impuesto;
      if (imp) {
         let coincidence = this.taxesList.some((tax) => tax.impuestoId == imp.impuestoId);
         if (this.taxesList.includes(imp) || coincidence) {
            Swal.fire({
               icon: "error",
               titleText: "Impuesto repetido",
               text: "El impuesto que desea agregar ya se encuentra asociado al producto.",
            });
         } else if (this.taxesList.length < 2) {
            this.taxesList.push(imp);
         }
      }
      this.ref.detectChanges();
   }

   deleteTax(index) {
      this.taxesList.splice(index, 1);
   }

   sendCloseRequest() {
      this.sendCloseRequestEmitter.emit();
   }

   setProductDataToForm(receivedProduct: Producto) {
      this.productForm.patchValue(receivedProduct);
      this.taxesList = receivedProduct.impuestos;
      if (!receivedProduct.unidadMedida) {
         this.productForm.controls.unidadMedida.setValidators([]);
      }
   }

   getTaxList() {
      this.taxesSource = JSON.parse(this.localStorage.getImpuestos()) as Impuesto[];
   }
}
