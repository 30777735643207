import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DynamicHeaderMenuConfig } from "../../configs/dynamic-header-menu.config";

const emptyMenuConfig = {
   items: [],
};

@Injectable({
   providedIn: "root",
})
export class DynamicHeaderMenuService {
   private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
   menuConfig$: Observable<any>;
   constructor() {
      this.menuConfig$ = this.menuConfigSubject.asObservable();
      this.loadMenu();
   }

   // Here you able to load your menu from server/data-base/localeStorage
   // Default => from DynamicHeaderMenuConfig
   private loadMenu() {
      this.setMenu(DynamicHeaderMenuConfig);
   }

   private setMenu(menuConfig) {
      this.menuConfigSubject.next(menuConfig);
   }

   private getMenu(): any {
      return this.menuConfigSubject.value;
   }
}
