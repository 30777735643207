import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faGrinTongueSquint } from "@fortawesome/free-solid-svg-icons";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import * as moment from "moment";
import { Observable } from "rxjs";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { ReferenciaDte } from "src/app/models/Dte/ReferenciaDte";
import { ReferenciaDteEmitido } from "src/app/models/Dte/ReferenciaDteEmitido";
import { TipoReferenciaEnum } from "src/app/models/Dte/TipoReferencia.enum";
import { TipoDocumentoReferencia } from "src/app/models/TipoDocRef";
import { TipoDte } from "src/app/models/tipoDte";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
   selector: "app-references-list",
   templateUrl: "./references-list.component.html",
   styleUrls: ["./references-list.component.scss"],
})
export class ReferencesListComponent implements OnInit {
   public referencesForm: FormGroup;
   public referencesArray: FormArray;
   private referencesList: ReferenciaDteEmitido[] = [];
   public tipoDteReferenciaSource: TipoDocumentoReferencia[];
   public tipoReferenciaEnumSource: any[] = [];
   public validDatesArray: boolean[] = [];
   locale = {
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
      applyLabel: "ok",
      clearLabel: "Limpiar",
      format: "DD/MM/YYYY",
   };
   @Output() referencesListEmitter: EventEmitter<ReferenciaDteEmitido[]> = new EventEmitter<ReferenciaDteEmitido[]>();
   private referencesFromGuides: ReferenciaDteEmitido[] = [];
   //private currentRefsFromGuide:ReferenciaDteEmitido[] = [];
   private refFromGuides$: Observable<ReferenciaDteEmitido[]>;
   private opcionesRefDte$: Observable<OptionsReferenceDteEmitido>;
   public globalReferenceForGuideRefs: boolean = false;
   constructor(private formBuilder: FormBuilder, private localStorage: LocalStorageService, private facturador: FacturacionIndividualService) {
      this.refFromGuides$ = this.facturador.referencesListFromGuidesObservable;
      this.opcionesRefDte$ = this.facturador.opcionesReferenciaObservable;
   }

   ngOnInit(): void {
      this.tipoDteReferenciaSource = JSON.parse(this.localStorage.getTipoDocReferencias()) as TipoDocumentoReferencia[];
      this.tipoReferenciaEnumSource = Object.keys(TipoReferenciaEnum).map((key) => ({ key, name: TipoReferenciaEnum[key] }));
      this.tipoReferenciaEnumSource = this.tipoReferenciaEnumSource.splice(0, 3);
      this.setReferencesForm();
      this.refFromGuides$.subscribe((res) => {
         this.referencesFromGuides = res;
         this.passGuideRefToReferenceFormGroup(this.referencesFromGuides);
      });
      this.opcionesRefDte$.subscribe((res) => {
         this.globalReferenceForGuideRefs = res.globalReferenceForGuideRefs;
      });
   }

   setReferencesForm() {
      this.referencesForm = this.formBuilder.group({
         referencesArray: this.formBuilder.array([this.createReferenceItemForm()]),
      });
   }

   createReferenceItemForm(): FormGroup {
      this.validDatesArray.push(true);
      return this.formBuilder.group({
         tipoDte: [, [Validators.required]],
         rutEmisor: [""],
         folioRef: ["", [Validators.required]],
         fechaRef: [
            {
               startDate: moment().toDate(),
               endDate: moment().toDate(),
            },
         ],
         razonRef: ["", [Validators.required]],
      });
   }

   addReferenceFormItem() {
      const currentRefsArray = this.referencesForm.controls.referencesArray as FormArray;
      if (currentRefsArray.length < 5) {
         let newReferenceItemForm = this.createReferenceItemForm();
         currentRefsArray.push(newReferenceItemForm);
         //this.checkIfReferenceDocumentTypeIsSelected(61);
      }
   }

   removeReferenceFormItem(index) {
      const currentArray = this.referencesForm.controls.referencesArray as FormArray;
      if (this.referencesList[index]) {
         this.referencesList.splice(index, 1);
         this.validDatesArray.splice(index, 1);
      }
      if (currentArray.length > 1) {
         currentArray.removeAt(index);
         this.validDatesArray.splice(index, 1);
      } else {
         currentArray.reset();
         this.validDatesArray = [];
      }
      this.onSubmit();
   }

   onSubmit() {
      const currentArray = this.referencesForm.controls.referencesArray as FormArray;
      //this.checkIfReferenceDocumentTypeIsSelected(61);
      if (this.referencesForm.valid && this.validDatesArray.every((value) => value == true)) {
         currentArray.controls.forEach((refForm, index) => {
            if (this.referencesList[index]) {
               this.referencesList[index] = this.getReferenceItemData(refForm as FormGroup);
            } else {
               this.referencesList.push(this.getReferenceItemData(refForm as FormGroup));
            }
         });
         this.referencesListEmitter.emit(this.referencesList);
      }
   }

   // checkIfReferenceDocumentTypeIsSelected(codeSii:number){
   //   //Disable TipoRazonRef control based on tipoDte Selected code
   //   (this.referencesForm.controls.referencesArray as FormArray).controls.forEach(control => {
   //     let tipoDte:TipoDocumentoReferencia = (control as FormGroup).controls.tipoDte.value as TipoDocumentoReferencia;
   //     if(!tipoDte || tipoDte.codigo != codeSii){
   //       (control as FormGroup).controls.tipoRazonRef.patchValue(undefined);
   //       (control as FormGroup).controls.tipoRazonRef.disable();
   //     }else{
   //       (control as FormGroup).controls.tipoRazonRef.setValidators([Validators.required]);
   //     }
   //   });
   // }

   getReferenceItemData(referenceItemForm: FormGroup): ReferenciaDteEmitido {
      let ref: ReferenciaDteEmitido = new ReferenciaDteEmitido();
      let tipoDteSelected: TipoDocumentoReferencia = referenceItemForm.controls.tipoDte.value;
      ref.codigoTipoDteReferencia = tipoDteSelected ? tipoDteSelected.codigo : "0";
      ref.folioReferencia = referenceItemForm.controls.folioRef.value;
      ref.fechaDteReferenciado = referenceItemForm.controls.fechaRef ? moment(referenceItemForm.controls.fechaRef.value.startDate).toDate() : undefined;
      ref.razonReferencia = referenceItemForm.controls.razonRef.value;
      return ref;
   }

   detectChangeReferenceDate(event, i) {
      if (event.startDate != null) {
         this.referencesForm.get("referencesArray")["controls"][i].controls.fechaRef.patchValue(event);
      } else if (event.target != null && event.target.value != null) {
         this.validDatesArray[i] = moment(event.target.value, "DD/MM/YYYY", true).isValid();
         if (this.validDatesArray[i]) {
            let obj: any = new Object();
            obj.startDate = moment(event.target.value, "DD-MM-YYYY");
            this.referencesForm.get("referencesArray")["controls"][i].controls.fechaRef.patchValue(obj);
         }
      }
   }

   passGuideRefToReferenceFormGroup(list: ReferenciaDteEmitido[]) {
      let formsArray = this.referencesForm.controls.referencesArray as FormArray;
      formsArray.controls = [];
      list.forEach((ref, i) => {
         this.addReferenceFormItem();
         this.guideRefToFormItem(ref, i);
      });
      this.onSubmit();
   }

   guideRefToFormItem(guideRef: ReferenciaDteEmitido, index: number) {
      let formsArray = this.referencesForm.controls.referencesArray as FormArray;
      if (formsArray.controls[index] as FormGroup) {
         this.updateFormControlsWithReference(formsArray.controls[index] as FormGroup, guideRef);
      } else {
         this.addReferenceFormItem();
         this.updateFormControlsWithReference(formsArray.controls[index] as FormGroup, guideRef);
      }
   }

   updateFormControlsWithReference(form: FormGroup, item: ReferenciaDteEmitido) {
      form.controls.folioRef.patchValue(item.folioReferencia);
      let dateObj = {
         startDate: moment(item.fechaDteReferenciado).toDate(),
         endDate: moment().toDate(),
      };
      form.controls.fechaRef.patchValue(dateObj);
      form.controls.razonRef.patchValue(item.razonReferencia);
      let tipoDoc = this.tipoDteReferenciaSource.find((tipo) => tipo.codigo == item.codigoTipoDteReferencia);
      form.controls.tipoDte.patchValue(tipoDoc);
   }
}
