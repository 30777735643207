<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"> </ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<section class="row">
	<div class="col-12">
		<div class="card card-custom">
			<div class="card-header">
				<h4 class="card-title">Busqueda de Clientes</h4>
			</div>
			<div class="card-head">
				<app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal" [config]="configLocal.barraBusqueda" (busqueda)="searchElementsByFilter($event)" [insideModal]="true"></app-search-bar>
			</div>
			<div class="card-content">
				<div class="card-body">
					<ngx-datatable
						class="material rows contacts-table"
						[rows]="rowsMantenedor"
						[columnMode]="'flex'"
						[headerHeight]="50"
						[footerHeight]="50"
						[rowHeight]="70"
						fxFlex="auto"
						[externalPaging]="true"
						[count]="page.totalElements"
						[offset]="page.pageNumber"
						[limit]="page.pageSize"
						(page)="setPage($event)"
						[selected]="selected"
						[selectionType]="SelectionType.single"
						(activate)="onActivate($event)"
						[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos' | translate, selectedMessage: false }">
						<ngx-datatable-column name="Rut" [flexGrow]="3" [sortable]="false" [resizeable]="false">
							<ng-template let-column="column" ngx-datatable-header-template>
								<span class="text-table-alignment">Rut</span>
							</ng-template>
							<ng-template ngx-datatable-cell-template let-row="row"
								><span class="text-table-alignment">{{ row.rutString }}</span>
							</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column name="razonSocial" [flexGrow]="4" [sortable]="false" [resizeable]="false">
							<ng-template let-column="column" ngx-datatable-header-template>
								<span class="text-table-alignment">Razón Social</span>
							</ng-template>
							<ng-template ngx-datatable-cell-template let-row="row"
								><span class="text-table-alignment">{{ row.razonSocial }}</span></ng-template
							>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
</section>
