<ngx-loading [show]="loading" [config]="{backdropBorderRadius: '3px'}" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-detached">
            <div class="content-body">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-custom" *ngIf="currentProfile">
                            <div class="card-header">
                                <h4 class="card-title">Permisos para el perfil {{currentProfile.nombre}}</h4>
                                <div class="card-toolbar">
                                    <button class="btn btn-success float-right mr-5 mt-5"
                                        (click)="sendPermissionsList()">
                                        <i class="flaticon-add-circular-button"></i>
                                        Guardar Cambios
                                    </button>
                                    <button class="btn btn-secondary float-right mr-5 mt-5"
                                        [routerLink]="[this.returnURL]">
                                        <i class="flaticon-reply"></i>
                                        Volver
                                    </button>
                                </div>
                            </div>
                            <div class="d-block">
                                <div class="mt-0 row">
                                    <div class="col-auto">
                                        <app-fast-search [arrayBuscar]="currentProfile.permisos"
                                            [arrayCriterios]="searchCriteria" [usingEmisorFilter]="false"
                                            [showLegacyBar]="true" (arrayresultadoEnviado)="changeSearchedRows($event)">
                                        </app-fast-search>
                                    </div>
                                    <div class="col-auto mt-7">
                                        <div class="row">
                                            <label class="col-10 label-control">Seleccionar Todo</label>
                                            <div class="col-2">
                                                <input type="checkbox" (click)="markAll($event)" class="input-checkbox">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <ngx-datatable #table class="material" [rows]="rowsToShow" [columnMode]="'flex'"
                                        [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto"
                                        [messages]="{emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false}">


                                        <ngx-datatable-column name="nombrePadre" [flexGrow]="3" [sortable]="true"
                                            [resizeable]="true" [draggable]="true">
                                            <ng-template let-column="column" ngx-datatable-header-template>
                                                <span class="text-table-alignment">Módulo</span>
                                            </ng-template>
                                            <ng-template ngx-datatable-cell-template let-row="row"><span
                                                    class="text-table-alignment">{{row.nombrePadre?row.nombrePadre:"Base"}}</span>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="Nombre" [flexGrow]="3" [sortable]="true"
                                            [resizeable]="true" [draggable]="true">
                                            <ng-template let-column="column" ngx-datatable-header-template>
                                                <span class="text-table-alignment">Nombre</span>
                                            </ng-template>
                                            <!-- <ng-template ngx-datatable-cell-template let-row="row"><span class="text-table-alignment">{{row.nombre}}{{row.nombrePadre?" | "+row.nombrePadre:""}}</span></ng-template> -->
                                            <!-- <ng-template ngx-datatable-cell-template let-row="row"><span class="text-table-alignment">{{row.nombreCompuesto}}</span></ng-template> -->
                                            <ng-template ngx-datatable-cell-template let-row="row"><span
                                                    class="text-table-alignment">{{row.nombre}}</span></ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="asignado" [flexGrow]="3" [sortable]="false"
                                            [resizeable]="true">
                                            <ng-template let-column="column" ngx-datatable-header-template>
                                                <span class="text-table-alignment">Activado</span>
                                            </ng-template>
                                            <ng-template ngx-datatable-cell-template let-row="row"
                                                let-rowIndex="rowIndex" let-value="value">
                                                <!-- <span class="text-table-alignment">{{row.asignado}}</span> -->
                                                <input type="checkbox" [value]="value" [checked]="value"
                                                    (click)="updateValue($event,row)" class="mx-1" />
                                            </ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>