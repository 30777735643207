import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SortType } from "@swimlane/ngx-datatable";
import { timingSafeEqual } from "crypto";
import { CrossedRangeValidator } from "src/app/directives/max-min-range-numbers.directive";
import { Ambiente } from "src/app/models/Ambiente";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { AnularFolios } from "src/app/models/Folio/AnularFolios";
import { Folio } from "src/app/models/Folio/Folio";
import { FolioFileObject } from "src/app/models/Folio/FolioFileObject";
import { FolioFileRequest } from "src/app/models/Folio/FolioFileRequest";
import { FolioFilter } from "src/app/models/Folio/FolioFilter";
import { FolioScrapperRequest } from "src/app/models/Folio/FolioRequest";
import { FoliosAnulables } from "src/app/models/Folio/FoliosAnulables";
import { ObtenerFolios } from "src/app/models/Folio/ObtenerFolios";
import { Sucursal } from "src/app/models/Sucursal";
import { TipoDte } from "src/app/models/tipoDte";
import { EmisorService } from "src/app/services/emisor.service";
import { FoliosService } from "src/app/services/folios.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigFolios } from "./config-folios";

@Component({
	selector: "app-mantenedor-folios-definitivo",
	templateUrl: "./mantenedor-folios-definitivo.component.html",
	styleUrls: ["./mantenedor-folios-definitivo.component.scss"],
})
export class MantenedorFoliosDefinitivoComponent implements OnInit {
	@ViewChild("obtieneFolios") private obtenerFoliosModalElementRef: any;
	@ViewChild("cargarFolios") private cargarFoliosModalElementRef: any;
	@ViewChild("tableMobile") tableRef: any;
	@ViewChild("anulaFolios") private anulacionModalElementRef: any;
	@ViewChild("consultaFolios") private consultaFoliosModalElementRef: any;
	SortType = SortType;
	public loading: boolean = false;
	public rowsMantenedor: Folio[] = [];
	public page: Page = new Page();
	public filter: FolioFilter = new FolioFilter();
	public configLocal = ConfigFolios;
	public cantidadDisponibleObtener: number = undefined;
	public consultaFoliosForm: FormGroup;
	private consultaFoliosRef: NgbModalRef;
	private obtenerFoliosRef: NgbModalRef;
	private anulacionFoliosRef: NgbModalRef;

	empresaSource: Emisor[];
	sucursalesSource: Sucursal[];
	tipoDteSource: TipoDte[];
	public emisorSource: Emisor[];
	public obtenerFoliosForm: FormGroup;
	public anularFoliosForm: FormGroup;
	public cargarFoliosForm: FormGroup;

	public superAdm = false;
	private cargarFoliosRef: any;
	public sessionEmisor: Emisor;

	public sessionEmisorId: string;
	public sessionUserId: string;
	public files: File[] = [];
	public fileIsValid: boolean = false;
	currentObject: FolioFileObject = new FolioFileObject();
	public fileToUpload: File = null;
	public filenameToUpload: string = "";
	public componentStarted: boolean = false;
	public respuestaFoliosAnulables: FoliosAnulables[] = undefined;
	public minDesdeAnulacion: number;
	public maxHastaAnulacion: number;
	private selectedTimbrajeAnular: Folio = undefined;

	private currentSucursalId: string;
	private currentInvoiceEnvironment: Ambiente;
	private invoiceEnvironmentSource: Ambiente[];

	public limitedAmount: boolean = false;
	constructor(private foliosService: FoliosService, public utilities: UtilitiesService, private modalService: NgbModal, private formBuilder: FormBuilder, private localStorage: LocalStorageService, private ref: ChangeDetectorRef, private tokenService: TokenStorageService, private emisorService: EmisorService, public permissions: PermissionService) {
		this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		this.tipoDteSource = this.tipoDteSource.filter((x) => x.codigosii > 0);
		this.empresaSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		this.emisorSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		this.sucursalesSource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
		this.sessionEmisorId = this.tokenService.getCompany();
		this.sessionEmisor = this.emisorSource.find((emisor) => emisor.emisorId == this.sessionEmisorId);
		this.sessionUserId = this.tokenService.getUser().id;
		this.currentSucursalId = this.tokenService.getSubsidiary();
		//this.currentInvoiceEnvironment
		this.invoiceEnvironmentSource = JSON.parse(this.localStorage.getTipoAmbiente()) as Ambiente[];
		this.sessionEmisor.ambienteEnt = this.invoiceEnvironmentSource.find((env) => env.ambienteId == this.sessionEmisor.ambiente);
	}

	ngOnInit(): void {
		this.filter.emisorId = this.sessionEmisorId;
		this.filter.sucursalId = this.currentSucursalId;
		this.filter.ambienteId = this.sessionEmisor.ambiente;
		this.filter.activo = true;

		this.superAdm = this.tokenService.getUser().superAdmin; //this.permissionService.getPermission("10-10").asignado;
		if (!this.superAdm) {
			this.empresaSource = this.empresaSource.filter((s) => s.emisorId == this.sessionEmisor.emisorId);
			this.sucursalesSource = this.sucursalesSource.filter((s) => s.emisorId == this.sessionEmisor.emisorId && s.sucursalId == this.currentSucursalId);
		} else {
			this.getEmisoresFullList();
		}
		this.setConsultaFoliosForm();
		this.setObtenerFoliosForm();
		this.setAnulaFoliosForm();
		this.updateSucursal();
		this.setCargarFoliosForm();
		this.setPage({ offset: 0 });
	}
	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	openConsultaFoliosModal(modalRef) {
		this.setConsultaFoliosForm();
		this.setObtenerFoliosForm();
		this.setAnulaFoliosForm();
		this.updateSucursal();
		this.cantidadDisponibleObtener = undefined;
		this.consultaFoliosForm.controls.tipoDteConsulta.patchValue(undefined);
		this.consultaFoliosRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	setConsultaFoliosForm() {
		var empresa = this.empresaSource.filter((x) => x.emisorId == this.sessionEmisorId);
		var sucursales = this.sucursalesSource.filter((x) => x.emisorId == this.sessionEmisorId);
		this.consultaFoliosForm = this.formBuilder.group({
			tipoDteConsulta: [this.tipoDteSource[0], Validators.required],
			sucursal: [sucursales[0], Validators.required],
			empresa: [empresa[0], Validators.required],
		});
	}

	setCargarFoliosForm() {
		var empresa = this.empresaSource.find((x) => x.emisorId == this.sessionEmisorId);
		var sucursal = this.sucursalesSource.find((x) => x.emisorId == this.sessionEmisorId);
		this.cargarFoliosForm = this.formBuilder.group({
			file: [null, Validators.required],
			empresa: [empresa, Validators.required],
			sucursal: [sucursal, Validators.required],
		});
	}

	setObtenerFoliosForm() {
		var sucursal = this.consultaFoliosForm.controls.sucursal.value as Sucursal;
		var empresa = this.consultaFoliosForm.controls.empresa.value as Emisor;
		this.obtenerFoliosForm = this.formBuilder.group({
			tipoDte: [this.tipoDteSource[0], Validators.required],
			numeroFolios: [1, Validators.required],
			sucursal: [sucursal, Validators.required],
			empresa: [empresa, Validators.required],
		});
	}

	setAnulaFoliosForm() {
		this.anularFoliosForm = this.formBuilder.group(
			{
				tipoDteAnulacion: [, Validators.required],
				rangoAnulacion: [, Validators.required],
				desdeFolio: [, Validators.required],
				hastaFolio: [, Validators.required],
				motivoAnulacion: [, Validators.required],
				sucursal: [, Validators.required],
			},
			{ validators: CrossedRangeValidator }
		);
	}

	selectRango(event) {
		this.anularFoliosForm.controls.desdeFolio.patchValue(event.desde);
		this.anularFoliosForm.controls.hastaFolio.patchValue(event.hasta);
		this.anularFoliosForm.controls.desdeFolio.setValidators([Validators.required, Validators.min(event.desde), Validators.max(event.hasta)]);
		this.anularFoliosForm.controls.hastaFolio.setValidators([Validators.required, Validators.max(event.hasta), Validators.min(event.desde)]);
		this.anularFoliosForm.controls.desdeFolio.updateValueAndValidity();
		this.anularFoliosForm.controls.hastaFolio.updateValueAndValidity();
		this.anularFoliosForm.updateValueAndValidity();
		//console.log(event, this.anularFoliosForm.controls.desdeFolio.value, this.anularFoliosForm.controls.hastaFolio.value);
	}
	sendConsultaAnulacionRequest($event: Folio) {
		this.respuestaFoliosAnulables = undefined;
		this.loading = true;
		this.selectedTimbrajeAnular = undefined;
		let folioConsulta = $event;
		this.foliosService.consultarFoliosAnular(folioConsulta).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				this.respuestaFoliosAnulables = result.data as FoliosAnulables[];
				this.selectedTimbrajeAnular = $event;
				this.anularFoliosForm.controls.rangoAnulacion.patchValue(this.respuestaFoliosAnulables[0]);
				this.selectRango(this.respuestaFoliosAnulables[0]);
				this.openAnularFoliosModal(folioConsulta, this.respuestaFoliosAnulables);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Consulta para Anulación",
					text: "No fue posible consultar respecto de la anulación de este conjunto de folios " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	openAnularFoliosModal(dteCodigo: Folio, foliosAnular: FoliosAnulables[]) {
		this.minDesdeAnulacion = Math.min.apply(
			Math,
			foliosAnular.map(function (o) {
				return o.desde;
			})
		);
		this.maxHastaAnulacion = Math.max.apply(
			Math,
			foliosAnular.map(function (o) {
				return o.hasta;
			})
		);

		let selectedTipoDte: TipoDte = this.tipoDteSource.filter((t) => t.tipoDteId == dteCodigo.tipoDteId)[0] as TipoDte;
		let currentSucursal: Sucursal = this.sucursalesSource.find((s) => s.sucursalId == dteCodigo.sucursalId);
		if (selectedTipoDte) {
			this.anularFoliosForm.controls.tipoDteAnulacion.patchValue(selectedTipoDte);
			this.anularFoliosForm.controls.tipoDteAnulacion.disable();
		}
		if (currentSucursal) {
			this.anularFoliosForm.controls.sucursal.patchValue(currentSucursal);
			this.anularFoliosForm.controls.sucursal.disable();
		}
		// this.anularFoliosForm.controls.desdeFolio.patchValue(this.minDesdeAnulacion);

		// this.anularFoliosForm.controls.desdeFolio.setValidators([
		//   Validators.required,
		//   Validators.min(this.minDesdeAnulacion),
		//   Validators.max(this.maxHastaAnulacion),
		// ]);
		// this.anularFoliosForm.controls.hastaFolio.patchValue(this.maxHastaAnulacion);
		// this.anularFoliosForm.controls.hastaFolio.setValidators([
		//   Validators.required,
		//   Validators.max(this.maxHastaAnulacion),
		//   Validators.min(this.minDesdeAnulacion),
		// ]);
		this.anularFoliosForm.controls.motivoAnulacion.patchValue(undefined);
		this.anulacionFoliosRef = this.modalService.open(this.anulacionModalElementRef, {
			windowClass: "",
			size: "lg",
		});
	}

	sendAnulaFoliosRequest() {
		if (this.anularFoliosForm.valid) {
			let anulacionRequest = new AnularFolios();
			anulacionRequest.emisorId = this.tokenService.getCompany();
			anulacionRequest.sucursalId = this.tokenService.getSubsidiary();
			let type = this.anularFoliosForm.controls.tipoDteAnulacion.value as TipoDte;
			anulacionRequest.tipo = type.codigosii;
			anulacionRequest.desde = this.anularFoliosForm.controls.desdeFolio.value;
			anulacionRequest.hasta = this.anularFoliosForm.controls.hastaFolio.value;
			anulacionRequest.motivoAnulacion = this.anularFoliosForm.controls.motivoAnulacion.value;
			anulacionRequest.timbrajeId = this.selectedTimbrajeAnular.timbrajeId;
			this.loading = true;
			this.foliosService.anularFolios(anulacionRequest).subscribe(
				(result) => {
					this.loading = false;
					this.ref.detectChanges();
					if (result) {
						this.setPage({ offset: 0 });
						if (result.data[0].exito) {
							Swal.fire({
								icon: "success",
								title: result.message,
								text: result.data[0].mensaje,
							});
						} else {
							Swal.fire({
								icon: "warning",
								title: result.message,
								text: result.data[0].mensaje,
							});
						}

						this.anulacionFoliosRef.close();
						this.anularFoliosForm.reset();
					}
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al anular",
						text: "No fue posible anular los folios indicados, " + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			);
		}
	}
	updateSucursal() {
		var type = this.consultaFoliosForm.controls.empresa.value as Emisor;
		if (type != null && this.superAdm) {
			var sucursales = (JSON.parse(this.localStorage.getSucursales()) as Sucursal[]).filter((x) => x.emisorId == type.emisorId);
			this.sucursalesSource = sucursales;
			this.obtenerFoliosForm.controls.empresa.setValue(type);

			///Necesito obtener la sucursal que coincida con la variable this.currentSucursalId
			var sucursal = sucursales.find((x) => x.sucursalId == this.currentSucursalId);
			this.consultaFoliosForm.controls.sucursal.setValue(sucursal);
			this.obtenerFoliosForm.controls.sucursal.setValue(sucursal);
		}
	}

	updateSucursalCarga() {
		var type = this.cargarFoliosForm.controls.empresa.value as Emisor;
		if (type != null && this.superAdm) {
			var sucursales = (JSON.parse(this.localStorage.getSucursales()) as Sucursal[]).filter((x) => x.emisorId == type.emisorId);
			this.sucursalesSource = sucursales;

			this.cargarFoliosForm.controls.empresa.setValue(type);
			var sucursal = sucursales.find((x) => x.sucursalId == this.currentSucursalId);

			this.cargarFoliosForm.controls.sucursal.setValue(sucursal);
		}
	}

	sendConsultarFoliosDisponibles() {
		if (this.consultaFoliosForm.valid) {
			let consultaRequest: FolioScrapperRequest = new FolioScrapperRequest();
			consultaRequest.emisorId = (this.consultaFoliosForm.controls.empresa.value as Emisor).emisorId;
			consultaRequest.sucursalId = (this.consultaFoliosForm.controls.sucursal.value as Sucursal).sucursalId;
			consultaRequest.usuarioId = this.sessionUserId;
			let tipoDteSelected = this.consultaFoliosForm.controls.tipoDteConsulta.value as TipoDte;
			consultaRequest.tipo = tipoDteSelected.codigosii;
			if (this.checkForDirectFoliosRequest(consultaRequest.tipo)) {
				this.consultaFoliosRef.close();
				setTimeout(() => {
					this.openObtenerFoliosModal(this.obtenerFoliosModalElementRef, 0, tipoDteSelected);
					this.obtenerFoliosForm.controls.sucursal.setValue(this.consultaFoliosForm.controls.sucursal.value as Sucursal);
					this.consultaFoliosForm.reset();
				}, 100);
			} else {
				this.loading = true;
				this.foliosService.consultarFoliosDisponibles(consultaRequest).subscribe(
					(result) => {
						this.loading = false;
						this.ref.detectChanges();
						let available = result.data;
						this.consultaFoliosRef.close();
						setTimeout(() => {
							this.openObtenerFoliosModal(this.obtenerFoliosModalElementRef, available, tipoDteSelected);
							this.obtenerFoliosForm.controls.sucursal.setValue(this.consultaFoliosForm.controls.sucursal.value as Sucursal);
							this.consultaFoliosForm.reset();
						}, 100);
					},
					(error) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Consulta de Folios",
							text: "No fue posible consultar los folios disponibles para el tipo de documento seleccionado " + this.utilities.setErrorMessageFromArray(error.error),
						});
					}
				);
			}
		}
	}

	sendObtenerFoliosRequest() {
		if (this.obtenerFoliosForm.valid && !this.obtenerFoliosForm.controls.numeroFolios.disabled) {
			this.loading = true;
			let obtenerRequest: ObtenerFolios = new ObtenerFolios();
			obtenerRequest.emisorId = (this.obtenerFoliosForm.controls.empresa.value as Emisor).emisorId;
			obtenerRequest.sucursalId = (this.obtenerFoliosForm.controls.sucursal.value as Sucursal).sucursalId;
			obtenerRequest.usuarioId = this.sessionUserId;
			let tipoDteSelected = this.obtenerFoliosForm.controls.tipoDte.value as TipoDte;
			obtenerRequest.tipo = tipoDteSelected.codigosii;
			obtenerRequest.cantidad = this.obtenerFoliosForm.controls.numeroFolios.value;
			this.foliosService.obtenerFolios(obtenerRequest).subscribe(
				(result) => {
					this.loading = false;
					this.ref.detectChanges();
					if (result.data) {
						this.setPage({ offset: 0 });
					}
					this.cantidadDisponibleObtener = undefined;
					Swal.fire({
						icon: "success",
						title: "Folios Obtenidos",
						text: result.message,
					});
					this.obtenerFoliosRef.close();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Obtención de Folios",
						text: "No fue posible obtener los folios solicitados. " + this.utilities.setErrorMessageFromArray(error.error),
					});
					this.obtenerFoliosRef.close();
				}
			);
		}

		//refresh de lista
	}

	openObtenerFoliosModal(modalRef, amount, tipoDte: TipoDte) {
		if (this.checkForDirectFoliosRequest(tipoDte.codigosii)) {
			this.limitedAmount = false;
			this.obtenerFoliosForm.controls.numeroFolios.setValidators([Validators.required, Validators.min(1)]);
		} else if (amount > 0) {
			this.limitedAmount = true;
			this.obtenerFoliosForm.controls.numeroFolios.setValidators([Validators.required, Validators.max(amount), Validators.min(1)]);
		} else if (amount == 0) {
			this.limitedAmount = true;
			this.obtenerFoliosForm.controls.numeroFolios.setValidators([Validators.required, Validators.max(1), Validators.min(1)]);
			this.obtenerFoliosForm.controls.numeroFolios.patchValue(0);
			this.obtenerFoliosForm.controls.numeroFolios.disable();
		}
		this.obtenerFoliosForm.controls.tipoDte.patchValue(tipoDte);
		this.obtenerFoliosForm.controls.tipoDte.disable();
		this.cantidadDisponibleObtener = amount;
		this.obtenerFoliosRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	checkForDirectFoliosRequest(reqTipo: number) {
		//execute request
		return reqTipo == 34 || reqTipo == 39 || reqTipo == 41 || reqTipo == 52 || reqTipo == 110 || reqTipo == 112 || reqTipo == 111;
		//return
	}

	openCargarFoliosModalFromEmitter() {
		var empresa = this.empresaSource.find((x) => x.emisorId == this.sessionEmisorId);
		var sucursal = this.sucursalesSource.find((x) => x.emisorId == this.sessionEmisorId);
		this.files = [];
		this.cargarFoliosForm.controls.empresa.patchValue(empresa);
		this.cargarFoliosForm.controls.sucursal.patchValue(sucursal);
		this.updateSucursalCarga();
		this.fileIsValid = false;
		this.currentObject = new FolioFileObject();
		this.cargarFoliosRef = this.modalService.open(this.cargarFoliosModalElementRef, {
			windowClass: "",
			size: "lg",
		});
	}

	getFormToCargaFolioRequest() {
		if (this.cargarFoliosForm.valid && this.fileIsValid) {
			let empresa = this.cargarFoliosForm.controls.empresa.value as Emisor;
			let sucursal = this.cargarFoliosForm.controls.sucursal.value as Sucursal;
			this.currentObject.emisorId = empresa.emisorId;
			this.currentObject.sucursalId = sucursal.sucursalId;
			let request: FolioFileRequest = new FolioFileRequest();
			request.requestObject = this.currentObject;
			request.file = this.fileToUpload;

			this.sendCargaFoliosRequest(request);
		}
	}

	sendCargaFoliosRequest(req: FolioFileRequest) {
		this.loading = true;
		this.foliosService.addWithFile(req, req.file).subscribe(
			(result) => {
				this.loading = false;
				this.setPage({ offset: 0 });
				Swal.fire({
					icon: "success",
					title: "Folios agregados",
					text: "Se ha agregado correctamente los folios ingresados mediante archivo.",
				});
				this.cargarFoliosRef.close();
				this.cargarFoliosForm.reset();
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al cargar",
					text: "Ha ocurrido un error en la carga de folios a través de archivo. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	setPageSize() {
		this.setPage({ offset: 0 });
	}

	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		this.foliosService
			.getDataByPage(this.filter, localPage)
			.subscribe(
				(result) => {
					this.loading = false;
					this.rowsMantenedor = result.data as Folio[];
					this.rowsMantenedor.forEach((f) => (f.nombreTipoDte = this.setTipoDocumentoToFolio(f)));
					this.rowsMantenedor = [...this.rowsMantenedor];
					this.ref.detectChanges();
					this.setPageFromResponse(this.page, result);
				},
				(error) => {
					this.loading = false;
					Swal.fire({
						icon: "error",
						title: "Error en lista",
						text: "No fue posible obtener la lista de folios. Intente nuevamente",
					});
				}
			)
			.add(() => {
				this.componentStarted = true;
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	setTipoDocumentoToFolio(row: Folio): string {
		let tipo = this.tipoDteSource.find((tipo) => tipo.tipoDteId == row.tipoDteId);
		return tipo.nombre;
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<Folio>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	stringDateToDate(fechaVencimiento: string): Date {
		return new Date(fechaVencimiento);
	}
	onFileChangeCAF(files) {
		if (files.addedFiles[0].type != "text/xml") {
			this.cargarFoliosForm.get("file").reset();
			this.fileToUpload = null;
			this.filenameToUpload = "Subir CAF";
			Swal.fire({ icon: "error", text: "El archivo debe ser xml" });
			return;
		}

		let parser = require("fast-xml-parser");
		try {
			let fileReader = new FileReader();
			fileReader.onload = (e) => {
				try {
					let jsonCaf = parser.parse(fileReader.result);
					this.currentObject.codigoSii = jsonCaf.AUTORIZACION.CAF.DA.TD;
					let tipoDte = this.tipoDteSource.find((t) => t.codigosii == this.currentObject.codigoSii);
					//this.setTipoDte(tipoDte);
					this.currentObject.tipoDte = tipoDte.nombre;
					this.currentObject.fechaCaf = jsonCaf.AUTORIZACION.CAF.DA.FA as Date;
					this.currentObject.desde = jsonCaf.AUTORIZACION.CAF.DA.RNG.D;
					this.currentObject.hasta = jsonCaf.AUTORIZACION.CAF.DA.RNG.H;
					this.currentObject.rutEmisor = jsonCaf.AUTORIZACION.CAF.DA.RE;
					this.filenameToUpload = files.addedFiles[0].name;
					this.cargarFoliosForm.patchValue({ file: files.addedFiles[0] });
					this.fileIsValid = true;
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Archivo no tiene el formato correcto",
						text: "Por favor suba un archivo CAF válido. " + error,
					});
					this.filenameToUpload = "Subir CAF";
					this.fileToUpload = null;
					this.fileIsValid = false;
				}
			};
			fileReader.readAsText(files.addedFiles[0]);
			this.files.push(...files.addedFiles);
			this.fileToUpload = files.addedFiles[0];
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Archivo no tiene el formato correcto",
				text: "Por favor suba un archivo CAF válido.",
			});
			this.filenameToUpload = "Subir CAF";
			this.fileToUpload = null;
			this.fileIsValid = false;
		}
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	getEmisoresFullList() {
		this.emisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				this.emisorSource = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	searchElementsByFilter(event) {
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.tipoDteId = event.tipoDteId;
		this.filter.activo = event.registroActivo != null ? !event.registroActivo : true;
		this.filter.emisorId = event.emisorId ? event.emisorId : this.sessionEmisorId;
		this.filter.sucursalId = event.sucursalId ? event.sucursalId : this.currentSucursalId;
		this.setPage({ offset: 0 });
	}

	deleteBlocked(timbraje) {
		this.loading = true;
		this.foliosService.deleteBlocked(timbraje.timbrajeId).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Folios Desbloqueados",
					text: "Se desbloquearon los folios del timbraje",
				});
				this.setPage({ offset: 0 });
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Desbloquear Folios",
					text: "No fue posible desbloquear folios del timbraje." + this.utilities.setErrorMessageFromArray(err.error),
				});
			}
		);
	}
}
