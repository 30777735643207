import { Component, OnInit } from "@angular/core";

@Component({
   selector: "app-dropdown-menu4",
   templateUrl: "./dropdown-menu4.component.html",
})
export class DropdownMenu4Component implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
