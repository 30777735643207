export class PlantillaCorreoEnvio {
   plantillaEnvioCorreoId: string;
   nombre: string;
   envioCorreoId: string;
   templateCode: string;
   tipoPlantillaEnvioCorreoId: number;
   nombreTipoPlantilla: string;
   activo: boolean;
   file: File;
}
