<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<section class="row">
					<div class="col-md-12 col-lg-6">
						<!-- basic content of company -->
						<div class="card card-custom mb-5">
							<div class="card-content">
								<div class="card-header">
									<h4 class="card-title mb-0">Detalles de Empresa</h4>
								</div>
								<div class="card-body">
									<div class="row">
										<table class="detail-table mx-5" *ngIf="filteredEmisor">
											<tr>
												<td>Rut</td>
												<td>{{ filteredEmisor.rutString }}</td>
											</tr>
											<tr>
												<td>Razón Social</td>
												<td>{{ filteredEmisor.razonSocial }}</td>
											</tr>
											<tr>
												<td>Rut de Representante Legal</td>
												<td>{{ filteredEmisor.representanteLegalRut }}-{{ filteredEmisor.representanteLegalDv }}</td>
											</tr>
											<tr>
												<td style="width: 190px">Giro</td>
												<td>{{ filteredEmisor.giro }}</td>
											</tr>
											<tr>
												<td>Ciudad</td>
												<td>{{ filteredEmisor.ciudad }}</td>
											</tr>
											<tr>
												<td>Comuna</td>
												<td>{{ filteredEmisor.comuna }}</td>
											</tr>
											<tr>
												<td>Telefono</td>
												<td>{{ filteredEmisor.telefono }}</td>
											</tr>
											<tr>
												<td>Dirección Facturación</td>
												<td>{{ filteredEmisor.dirFact }}</td>
											</tr>
											<tr>
												<td>Correo Facturación</td>
												<td>{{ filteredEmisor.correoFact }}</td>
											</tr>
											<tr>
												<td>Fecha de Resolución</td>
												<td>{{ filteredEmisor.fechaResol | date : "dd/MM/yyyy" }}</td>
											</tr>
											<tr>
												<td>Numero de Resolución</td>
												<td>{{ filteredEmisor.nroResol }}</td>
											</tr>
											<tr>
												<td>Ambiente</td>
												<td>{{ filteredEmisor.nombreAmbiente }}</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
						<!-- end basic content of company -->
					</div>

					<div class="col-md-12 col-lg-6">
						<!-- branches of company -->
						<div class="card card-custom mb-5">
							<div class="card-content">
								<div class="card-header">
									<div class="modal-header p-0" style="border: none">
										<h4 class="card-title mb-0">Sucursales de Empresa</h4>
										<button class="btn btn-warning float-right" (click)="addBranchOfficeModal(addSucursalM)" *ngIf="permissions.getPermission('30-90-10').asignado">
											<i class="flaticon-add-circular-button"></i>
											Agregar Sucursal
										</button>
									</div>
								</div>
								<div class="card-body">
									<ngx-datatable #table class="material" [rows]="branches" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [externalPaging]="true" [count]="pageSucursal.totalElements" [offset]="pageSucursal.pageNumber" [limit]="pageSucursal.pageSize" (page)="setPageSucursal($event)" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>
										<ngx-datatable-column name="Nombre" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombre }} </span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Direccion" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Dirección</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.direccion }} </span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Detalles Sucursal" class="primary edit mr-1 mrless" id="company-panel.component_Detalles_Sucursal" (click)="detailBranchOfficeModal(detailSucursalM, row)" *ngIf="permissions.getPermission('30-90-14').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Editar Sucursal" class="primary edit mr-1 mrless" id="company-panel.component_Editar_Sucursal" (click)="editBranchOfficeModal(editSucursalM, row)" *ngIf="permissions.getPermission('30-90-12').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Desactivar Sucursal" class="primary edit mr-1 mrless" id="company-panel.component_Desactivar_Sucursal" (click)="deactivateBranchOffice(row)" *ngIf="row.activa && permissions.getPermission('30-90-16').asignado">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Activar Sucursal" class="primary edit mr-1 mrless" id="company-panel.component_Activar_Sucursal" (click)="activateBranchOffice(row)" *ngIf="!row.activa && permissions.getPermission('30-90-16').asignado">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
									</ngx-datatable>
								</div>
							</div>
						</div>
						<!-- end branches of company -->
					</div>

					<div class="col-md-12 col-lg-6">
						<!-- activities of company -->
						<div class="card card-custom mb-5">
							<div class="card-content">
								<div class="card-header">
									<div class="modal-header p-0" style="border: none">
										<h4 class="card-title mb-0">Actividades Económicas</h4>
										<button class="btn btn-warning float-right" (click)="openActivitiesModal(updateActivities)" *ngIf="permissions.getPermission('30-80-19').asignado">
											<i class="flaticon-add-circular-button"></i>
											Agregar Actividad
										</button>
									</div>
								</div>
								<div class="card-body pt-0">
									<table class="table" *ngIf="actividadesEconomicas.length > 0">
										<thead>
											<tr>
												<td>Descripcion</td>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let item of actividadesEconomicas; let index = index">
												<td>{{ item.descripcion }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<!-- end activities of company -->
					</div>

					<div class="col-md-12 col-lg-6">
						<!-- persons of company -->
						<div class="card card-custom mb-5">
							<div class="card-content">
								<div class="card-header">
									<div class="modal-header p-0" style="border: none">
										<h4 class="card-title mb-0">Personas Autorizadas para Cesionar</h4>
										<button class="btn btn-warning float-right" (click)="openAddAuthorizedPersonModal(addAuthorizedPerson)" *ngIf="permissions.getPermission('30-80-19').asignado">
											<i class="flaticon-add-circular-button"></i>
											Agregar Persona
										</button>
									</div>
								</div>
								<div class="card-body">
									<table class="table" *ngIf="personasAutorizadas.length > 0">
										<thead>
											<tr>
												<td>Rut</td>
												<td>Nombre</td>
												<td>Correo</td>
												<td>Acciones</td>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let item of personasAutorizadas; let index = index">
												<td>{{ item.rutFormateado }}</td>
												<td>{{ item.nombre }}</td>
												<td>{{ item.correo }}</td>
												<td>
													<a *ngIf="permissions.getPermission('30-80-21-12').asignado" ngbTooltip="Editar" class="primary edit mr-1 mrless" (click)="openUpdateAuthorizedPersonModal(updateAuthorizedPerson, item)">
														<div class="icon-circle bg-info text-center align-middle pt-info-button">
															<fa-icon icon="pencil-alt" class="fa-lg text-white"> </fa-icon>
														</div>
													</a>
													<a *ngIf="permissions.getPermission('30-80-21-14').asignado" ngbTooltip="Eliminar" class="primary edit mr-1 mrless" (click)="deleteAuthorizedPerson(item)">
														<div class="icon-circle bg-danger text-center align-middle pt-info-button">
															<fa-icon icon="times-circle" class="fa-lg text-white"> </fa-icon>
														</div>
													</a>
												</td>
											</tr>
										</tbody>
									</table>
									<span *ngIf="personasAutorizadas.length == 0">El emisor no tiene personas autorizadas para la cesión de documentos registradas.</span>
								</div>
							</div>
						</div>
						<!-- end persons of company -->
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<!-- BRANCHES ELEMENTS -->
<ng-template #detailSucursalM let-d="dismiss">
	<form #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Detalles de la Sucursal {{ branchToShow.nombre }}</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="nombre"
							placeholder="Ingresar nombre"
							class="form-control"
							#editNombre="ngModel"
							[(ngModel)]="branchToShow.nombre"
							[ngClass]="{
								'is-invalid': editForm.submitted && editNombre.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editNombre.invalid" class="invalid-feedback-group">
							<div *ngIf="editNombre.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="direccion"
							placeholder="Ingresar dirección"
							class="form-control"
							#editDireccion="ngModel"
							[(ngModel)]="branchToShow.direccion"
							[ngClass]="{
								'is-invalid': editForm.submitted && editDireccion.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editDireccion.invalid" class="invalid-feedback-group">
							<div *ngIf="editDireccion.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>

				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #editSucursalM let-d="dismiss">
	<form (ngSubmit)="editBranchOffice(editForm)" #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Editar Sucursal</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombre"
							placeholder="Ingresar nombre"
							class="form-control"
							#editNombre="ngModel"
							[(ngModel)]="branchToEdit.nombre"
							[ngClass]="{
								'is-invalid': editForm.submitted && editNombre.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editNombre.invalid" class="invalid-feedback-group">
							<div *ngIf="editNombre.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección</label>
					<div class="col-9">
						<input
							type="text"
							name="direccion"
							placeholder="Ingresar dirección"
							class="form-control"
							#editDireccion="ngModel"
							[(ngModel)]="branchToEdit.direccion"
							[ngClass]="{
								'is-invalid': editForm.submitted && editDireccion.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editDireccion.invalid" class="invalid-feedback-group">
							<div *ngIf="editDireccion.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="editForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #addSucursalM let-d="dismiss">
	<form (ngSubmit)="addBranchOffice(addForm)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Agregar Sucursal</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombre"
							placeholder="Ingresar nombre"
							class="form-control"
							#newNombre="ngModel"
							[(ngModel)]="branchToAdd.nombre"
							[ngClass]="{
								'is-invalid': addForm.submitted && newNombre.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newNombre.invalid" class="invalid-feedback-group">
							<div *ngIf="newNombre.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección</label>
					<div class="col-9">
						<input
							type="text"
							name="direccion"
							placeholder="Ingresar dirección"
							class="form-control"
							#newDireccion="ngModel"
							[(ngModel)]="branchToAdd.direccion"
							[ngClass]="{
								'is-invalid': addForm.submitted && newDireccion.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newDireccion.invalid" class="invalid-feedback-group">
							<div *ngIf="newDireccion.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="addForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
<!-- ECONOMIC ACTIVITIES ELEMENTS -->
<ng-template #updateActivities let-d="dismiss">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Actividades Económicas del Emisor</div>
				<div class="modal-wizard-desc">Se muestra el selector de Actividades Económicas disponibles y las ya Asignadas</div>
			</div>
		</div>
		<div class="modal-body p-0">
			<app-actividad-entry [emisor]="this.currentEmisor" (sendEmisorRequest)="updateEconomicActivitiesRequest($event)" (sendCloseRequestEmitter)="closeEconomicActivitiesModal()"></app-actividad-entry>
		</div>
	</div>
</ng-template>

<!-- AHOTRIZED PERSONS ELEMENTS-->
<ng-template #updateAuthorizedPerson let-d="dismiss">
	<form [formGroup]="updateAuthorizedPersonForm" (ngSubmit)="updatePerson()" #updateAuthorizedPersonFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Agregar Persona Autorizada</div>
					<div class="modal-wizard-desc">Ingresa datos de persona autorizada para cesion</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Rut</label>
						<div class="col-9">
							<input type="text" name="rutString" id="rutString" formControlName="rutString" class="form-control" />
							<div *ngIf="updateAuthorizedPersonFormRef.submitted && (updateAuthorizedPersonForm.get('rutString').invalid || updateAuthorizedPersonForm.get('rutString').dirty)" class="invalid-feedback">
								<div *ngIf="updateAuthorizedPersonForm.get('rutString').hasError('VerificacionRutValidator')">Rut inválido</div>
								<div *ngIf="updateAuthorizedPersonForm.get('rutString').errors?.required">Campo requerido</div>
								<div *ngIf="updateAuthorizedPersonForm.get('rutString').errors?.maxLength">Mucho numero</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Nombre</label>
						<div class="col-9">
							<input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control" />
							<div *ngIf="updateAuthorizedPersonFormRef.submitted && (updateAuthorizedPersonForm.get('nombre').invalid || updateAuthorizedPersonForm.get('nombre').dirty)" class="invalid-feedback">
								<div *ngIf="updateAuthorizedPersonForm.get('nombre').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Correo Electrónico</label>
						<div class="col-9">
							<input type="text" name="correo" id="correo" formControlName="correo" class="form-control" />
							<div *ngIf="updateAuthorizedPersonFormRef.submitted && (updateAuthorizedPersonForm.get('correo').invalid || updateAuthorizedPersonForm.get('correo').dirty)" class="invalid-feedback">
								<div *ngIf="updateAuthorizedPersonForm.get('correo').errors?.required">Campo requerido</div>
								<div *ngIf="updateAuthorizedPersonForm.get('correo').hasError('wrongEmail')">Correo inválido</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Guardar</span>
				</button>
				<button type="button" class="btn btn-secondary ml-3" (click)="closeUpdateAuthorizedPersonModal()">
					<span class="d-none d-lg-block">Cerrar</span>
				</button>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #addAuthorizedPerson let-d="dismiss">
	<form [formGroup]="addAuthorizedPersonForm" (ngSubmit)="addPerson()" #addAuthorizedPersonFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Agregar Persona Autorizada</div>
					<div class="modal-wizard-desc">Ingresa datos de persona autorizada para cesión</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Rut</label>
						<div class="col-9">
							<input type="text" name="rutString" id="rutString" formControlName="rutString" class="form-control" />
							<div *ngIf="addAuthorizedPersonFormRef.submitted && (addAuthorizedPersonForm.get('rutString').invalid || addAuthorizedPersonForm.get('rutString').dirty)" class="text-danger">
								<div *ngIf="addAuthorizedPersonForm.get('rutString').hasError('VerificacionRutValidator')">Rut inválido</div>
								<div *ngIf="addAuthorizedPersonForm.get('rutString').errors?.required">Campo requerido</div>
								<div *ngIf="addAuthorizedPersonForm.get('rutString').errors?.maxLength">Mucho numero</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Nombre</label>
						<div class="col-9">
							<input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control" />
							<div *ngIf="addAuthorizedPersonFormRef.submitted && (addAuthorizedPersonForm.get('nombre').invalid || addAuthorizedPersonForm.get('nombre').dirty)" class="text-danger">
								<div *ngIf="addAuthorizedPersonForm.get('nombre').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Correo Electrónico</label>
						<div class="col-9">
							<input type="text" name="correo" id="correo" formControlName="correo" class="form-control" />
							<div *ngIf="addAuthorizedPersonFormRef.submitted && (addAuthorizedPersonForm.get('correo').invalid || addAuthorizedPersonForm.get('correo').dirty)" class="text-danger">
								<div *ngIf="addAuthorizedPersonForm.get('correo').errors?.required">Campo requerido</div>
								<div *ngIf="addAuthorizedPersonForm.get('correo').hasError('wrongEmail')">Correo inválido</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Guardar</span>
				</button>
				<button type="button" class="btn btn-secondary ml-3" (click)="closeAddAuthorizedPersonModal()">
					<span class="d-none d-lg-block">Cerrar</span>
				</button>
			</div>
		</div>
	</form>
</ng-template>
