<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal" [config]="configLocal.barraBusqueda" [useAccordionMode]="true" [registroActivacion]="true" [mostrarListaCompletaEmisores]="true" [listaCompletaEmisores]="emisoresSource" [filtroEmisor]="true" [filtroSucursal]="false" [useInlineSearchButton]="true" [useInlineDeactivatedCheckbox]="true" (busqueda)="searchElementsByFilter($event)" [emisorClearable]="false" [sucursalClearable]="false"> </app-search-bar>
				<div class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Certificados Digitales</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0" *ngIf="permissions.getPermission('20-20-10').asignado">
									<button class="btn btn-success float-right mr-5 mt-5" (click)="openCreateCertificateModal(createCertificateModal)">
										<i class="flaticon-add-circular-button"></i>
										Cargar
									</button>
								</div>
							</div>
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{
											emptyMessage: 'Sin datos para mostrar' | translate,
											totalMessage: 'Elementos totales' | translate,
											selectedMessage: false
										}"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column name="friendlyName" [flexGrow]="5" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.friendlyName }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="rutString" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Rut</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.rutString }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="fechaSubida" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Carga</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.fechaSubida | date : "dd/MM/yyyy" }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="fechaVenc" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Vencimiento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.fechaVenc | date : "dd/MM/yyyy" }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="mesesRestantes" [flexGrow]="2" [sortable]="true" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Meses Restantes</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.mesesRestantes }} Meses</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a *ngIf="permissions.getPermission('20-20-16').asignado" class="primary edit mr-1 mrless" ngbTooltip="Descargar Certificado" (click)="CertificateDownloadRequest(row)">
													<div class="icon-circle bg-warning text-center align-middle pt-file-button">
														<fa-icon icon="file-code" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a *ngIf="superAdm" class="primary edit mr-1 mrless" ngbTooltip="Ver Password" (click)="showPass(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<i class="fa-sharp fa-solid fa-key"></i>
													</div>
												</a>
												<a *ngIf="permissions.getPermission('20-20-14').asignado && !row.activo" class="primary edit mr-1 mrless" ngbTooltip="Activar Certificado" (click)="activateCertificate(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="permissions.getPermission('20-20-14').asignado && row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Certificado"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar el certificado?',
														confirmButtonText: 'Desactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateCertificate(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="permissions.getPermission('20-20-12').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Eliminar Certificado"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de eliminar el certificado?',
														confirmButtonText: 'Eliminar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deleteCertificate(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="trash" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="85"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{
											emptyMessage: 'Sin datos para mostrar' | translate,
											totalMessage: 'Elementos totales' | translate,
											selectedMessage: false
										}">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">
													Nombre: {{ row.friendlyName }} , Rut: {{ row.rutString }}, Meses Restantes:
													{{ row.mesesRestantes }}
												</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="carga" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Carga</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.fechaSubida }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Vencimiento" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Vencimiento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.fechaVenc }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="3.3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Descargar Certificado" id="mantenedor-certificados.component_Descargar_Certificado" (click)="CertificateDownloadRequest(row)">
													<div class="icon-circle bg-warning text-center align-middle pt-file-button">
														<fa-icon icon="file-code" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Activar Certificado" id="mantenedor-certificados.component_Activar_Certificado" (click)="activateCertificate(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Certificado"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar el certificado?',
														confirmButtonText: 'Eliminar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateCertificate(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													class="primary edit mr-1 mrless"
													ngbTooltip="Eliminar Certificado"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de eliminar el certificado?',
														confirmButtonText: 'Eliminar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deleteCertificate(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #createCertificateModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Ingreso de Certificados Digitales</div>
				<div class="modal-wizard-desc">Ingrese los datos y seleccione el archivo .pfx del certificado digital a ingresar</div>
			</div>
		</div>
		<div class="modal-body">
			<form [formGroup]="certificateForm" (ngSubmit)="addCertificate()" #certificateFormRef="ngForm">
				<div class="modal-body">
					<div class="mt-5 form-group position-relative mx-auto">
						<div class="row">
							<label for="" class="col-3 label-control">Rut</label>
							<div class="col-9">
								<input type="text" name="rutString" id="rutString" formControlName="rutString" class="form-control" maxlength="10" />
								<div *ngIf="certificateFormRef.submitted && (certificateForm.get('rutString').invalid || certificateForm.get('rutString').dirty)" class="text-danger">
									<div *ngIf="certificateForm.get('rutString').hasError('VerificacionRutValidator')">Rut inválido</div>
									<div *ngIf="certificateForm.get('rutString').errors?.required">Campo requerido</div>
									<div *ngIf="certificateForm.get('rutString').errors?.maxLength">Mucho numero</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-5 form-group position-relative mx-auto">
						<div class="row">
							<label for="" class="col-3 label-control">Contraseña</label>
							<div class="col-9">
								<input
									type="password"
									class="form-control"
									id="password"
									formControlName="password"
									placeholder="Ingrese máximo de password"
									[ngClass]="{
										'is-invalid': certificateFormRef.submitted && certificateForm.get('password').invalid
									}" />
								<div *ngIf="certificateFormRef.submitted && (certificateForm.get('password').invalid || certificateForm.get('password').dirty)" class="text-danger">
									<div *ngIf="certificateForm.get('password').errors?.required">Campo requerido</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group position-relative mx-auto">
						<div class="row">
							<label for="" class="col-3 label-control">Empresa</label>
							<div class="col-9">
								<ng-select
									[items]="emisoresSource"
									[multiple]="false"
									placeholder="Seleccione Empresa"
									formControlName="emisor"
									[clearable]="false"
									bindLabel="razonSocial"
									[ngClass]="{
										'is-invalid': certificateFormRef.submitted && certificateForm.get('emisor').invalid
									}"></ng-select>
								<div *ngIf="certificateFormRef.submitted && (certificateForm.get('emisor').invalid || certificateForm.get('emisor').dirty || certificateForm.get('emisor').touched || certificateForm.get('emisor').value == null)" class="text-danger">
									<div *ngIf="certificateForm.get('emisor').errors?.required">Campo requerido</div>
								</div>
							</div>
						</div>
					</div>

					<div class="form-group position-relative mx-auto">
						<div class="row">
							<label for="" class="col-3 label-control">Certificado Digital</label>
							<div class="col-9">
								<ngx-dropzone #drop (change)="onFileChangeCER($event)" [multiple]="false">
									<ngx-dropzone-label class="px-5">
										<i class="flaticon-upload"></i>

										<p>Arrastra o selecciona el certificado digital.</p>

										<p>El formato del archivo debe ser .pfx</p>
									</ngx-dropzone-label>
									<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemoveCertificaddoArray(f)">
										<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
									</ngx-dropzone-preview>
								</ngx-dropzone>
								<div *ngIf="certificateFormRef.submitted && (certificateForm.get('file').invalid || certificateForm.get('file').dirty || certificateForm.get('file').touched || certificateForm.get('file').value == null)" class="text-danger">
									<div *ngIf="certificateForm.get('file').errors?.required">Campo requerido</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
					<button type="button" class="btn btn-secondary ml-3" (click)="closeCreateCertificateModal()">
						<span class="d-none d-lg-block">Cerrar</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</ng-template>
