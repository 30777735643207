import { NumberSymbol } from "@angular/common";
import { DteEmitido } from "./Dte/DteEmitido";

export class NotaDebito {
   notaId: string;
   dteReferenciaId: string;
   dteReferencia: DteEmitido;
   itemsNota: any[];
   //recalcular neto iva y total
   neto: number = 0;
   exento: number = 0;
   iva: number = 0;
   total: number = 0;
   motivoString: string;
   razonReferencia: number;
   comentario: string; //comentario generico
   comentarioCorreccionTexto: string;
   usuarioId: string; //se necesita la referencia individual
   folio: number;
   consumoFolioId: string;
   fechaNota: Date;
   tipoNota: number;
}
