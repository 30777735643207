import { ReturnStatement } from "@angular/compiler";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { AlertService } from "src/app/alerts/alert.service";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { TipoDte } from "src/app/models/tipoDte";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import Swal from "sweetalert2";
import { runInThisContext } from "vm";

@Component({
  selector: "app-lista-item-detalle-dte",
  templateUrl: "./lista-item-detalle-dte.component.html",
  styleUrls: ["./lista-item-detalle-dte.component.scss"],
})
export class ListaItemDetalleDteComponent implements OnInit {
  public listaDetalles: DetalleDte[];
  private subscriptions: Subscription[] = [];

  private listaDetallesObservable$: Observable<DetalleDte[]>;
  private formatSizeObservable$: Observable<number>;
  private invalidSizeObservable$: Observable<boolean>;
  private invoiceType$: Observable<InvoiceType>;
  public showingInInvoice: boolean = false;
  private minItemsInList: number = 0;
  private maxItemsInList: number = 5;
  public maxItemError: boolean = false;
  public showInInvoiceTicket = false;
  public emisorId = "";
  public sizeMessage: string =
    "El formato actual solo acepta " + this.maxItemsInList;
  public currentInvoiceType: InvoiceType = InvoiceType.Factura;
  private detailsFromGuides$: Observable<DetalleDte[]>;
  private addedDetailProduct$: Observable<DetalleDte>;
  private addedDetailDocument$: Observable<DetalleDte>;
  private tipoDteSource: TipoDte[] = JSON.parse(
    this.localStorage.getDteTypes()
  ) as TipoDte[];
  constructor(
    private facturador: FacturacionIndividualService,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
    private tokenService: TokenStorageService,
    private ref: ChangeDetectorRef
  ) {
    this.listaDetallesObservable$ = this.facturador.detalleDteListObservable;
    this.formatSizeObservable$ = this.facturador.formatSizeObservable;
    this.invalidSizeObservable$ = this.facturador.invalidSizeObservable;
    this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
    this.detailsFromGuides$ = this.facturador.detailsListFromGuidesObservable;
    this.addedDetailProduct$ = this.facturador.addDetailProductObservable;
    this.addedDetailDocument$ = this.facturador.addDetailDocumentObservable;
    this.emisorId = this.tokenService.getCompany();
  }

  ngOnInit(): void {
    const listaSub = this.listaDetallesObservable$.subscribe((list) => {
      this.listaDetalles = list;
    });

    const invoiceTypeSub = this.invoiceType$.subscribe((type) => {
      this.currentInvoiceType = type;
      if (type == InvoiceType.Boleta) {
        this.showingInInvoice = true;
        this.showInInvoiceTicket = true;
      } else if (type == InvoiceType.Factura) {
        this.showingInInvoice = true;
        this.showInInvoiceTicket = true;
      } else if (type == InvoiceType.GuiaDespacho) {
        this.showingInInvoice = true;
      } else {
        this.showingInInvoice = true;
      }
    });

    const detailsList$ = this.detailsFromGuides$.subscribe((details) => {
      if (details.length > 0) {
        this.facturador.deleteItem(0);
        details.forEach((d) => {
          this.addDetailFromGuide(d);
        });
      }
    });
    const formatSub = this.formatSizeObservable$.subscribe((size) => {
      this.ref.detectChanges();
      this.maxItemsInList = size;
      this.setSizeMessage();
      // if(this.listaDetalles.length > 0 ){
      //    if(this.listaDetalles[0].cantidad >0){
      //       this.validateMaxItems();
      //    }
      // }
    });

    const invalidSizeSub = this.invalidSizeObservable$.subscribe(
      (invalidBool) => {
        this.maxItemError = invalidBool;
        if (this.maxItemError) {
          this.alertService.clear("alert-size");
          let maxSizeMessage =
            " Ha utilizado " +
            this.facturador.currentSize +
            " líneas de detalle. Cada item utiliza una línea de detalle, si el item mantiene un campo de descripción utilizará dos líneas de detalle.";
          window.scroll(0, 300);
          return this.alertService.error(this.sizeMessage + maxSizeMessage, {
            id: "alert-size",
            autoClose: true,
          });
        }
      }
    );
    const detailProductSub = this.addedDetailProduct$.subscribe(
      (detailProduct) => {
        if (detailProduct == undefined) return;
        // if(this.listaDetalles.length > 0 && this.listaDetalles.some(d=> d.relatedProductId != undefined && d.relatedProductId == detailProduct.relatedProductId)){
        //   Swal.fire({
        //     icon:'error',
        //     title:'Producto ya ingresado',
        //     text:'El producto seleccionado ya fue ingresado como detalle del documento a emitir. Si lo desea, favor edite la línea que lo contiene.'
        //   })
        //   return;
        // }
        // if (this.listaDetalles[0] && (this.listaDetalles[0].precioUnitario == undefined || this.listaDetalles[0].precioUnitario == null || this.listaDetalles[0].precioUnitario == 0)) {
        //    this.facturador.deleteItem(0);
        // }
        this.addDetailFromProductBox(detailProduct);
      }
    );

    const detailDocumentSub = this.addedDetailDocument$.subscribe(
      (detailDoc) => {
        if (detailDoc == undefined) return;

        if (
          this.listaDetalles[0] &&
          this.listaDetalles[0].codigoInterno == undefined
        ) {
          this.facturador.deleteItem(0);
        }
        this.addDetailFromDocumentBox(detailDoc);
      }
    );

    this.subscriptions.push(
      detailProductSub,
      detailsList$,
      invoiceTypeSub,
      listaSub,
      formatSub,
      invalidSizeSub,
      detailDocumentSub
    );
  }

  // validateMaxItems(){
  //    var afecto = this.listaDetalles.filter(x => !x.esExento).length > 0 ? true:false;
  //    var codigoDte = 0;
  //    if(this.currentInvoiceType == InvoiceType.Factura){
  //       if(afecto)  codigoDte = 33;
  //       else  codigoDte = 34;
  //    }
  //    else if(this.currentInvoiceType == InvoiceType.Boleta){
  //       if(afecto)  codigoDte = 39;
  //       else  codigoDte = 41;
  //    }
  //    else{
  //       switch (this.currentInvoiceType) {
  //          case  InvoiceType.FacturaCompra:
  //             codigoDte = 46;
  //            break;
  //            case  InvoiceType.FacturaExportacion:
  //             codigoDte = 110;
  //            break;
  //            case  InvoiceType.GuiaDespacho:
  //             codigoDte = 52;
  //            break;
  //            case  InvoiceType.LiquidacionFactura:
  //             codigoDte = 43;
  //            break;
  //          default:
  //             codigoDte = 0;
  //        }
  //    }
  //    if(codigoDte > 0 ){
  //       var tipoDte = this.tipoDteSource.filter(x => x.codigosii == codigoDte);
  //       //console.log("max item in size",this.maxItemsInList)
  //       this.facturador.getMaxItems(this.emisorId, tipoDte[0].tipoDteId).subscribe(
  //          (res) =>{
  //             this.maxItemsInList = res.data;
  //             this.setSizeMessage();
  //             this.checkSizeInList();
  //          },
  //          (err) =>{
  //             var x = "";
  //          }
  //       );
  //    }
  // }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  agregarDetalle() {
    this.facturador.setNewItem();
  }

  addDetailFromGuide(d: DetalleDte) {
    this.facturador.setNewItem(d);
  }

  addDetailFromProductBox(d: DetalleDte) {
    if (this.checkIfProductAlreadyInList(d)) {
      Swal.fire({
        icon: "error",
        title: "Producto ya ingresado",
        text: "El producto seleccionado ya fue ingresado como detalle del documento a emitir. Si lo desea, favor edite la línea que lo contiene.",
      });
      return;
    }
    this.facturador.setNewItem(d);
  }

  addDetailFromDocumentBox(d: DetalleDte) {
    if (this.checkIfDocumentsAlreadyInList(d)) {
      Swal.fire({
        icon: "error",
        title: "Documento ya agregado",
        text: "Intenta ingresar un documento ya presente en los detalles.",
      });
      return;
    }
    this.facturador.setNewItem(d);
  }

  deleteItem(index) {
    if (this.listaDetalles.length > this.minItemsInList) {
      this.facturador.deleteItem(index);
    }
  }

  checkSizeInList() {
    this.maxItemError = this.listaDetalles.length > this.maxItemsInList;
  }
  setSizeMessage() {
    this.sizeMessage =
      "El formato actual solo acepta " + this.maxItemsInList + " elementos.";
  }

  checkIfDocumentsAlreadyInList(item: DetalleDte): boolean {
    return this.listaDetalles.some((d) =>
      d.relatedDocumentsIds.some((id) => item.relatedDocumentsIds.includes(id))
    );
  }
  checkIfProductAlreadyInList(item: DetalleDte): boolean {
    return (
      item.relatedProductId != null &&
      this.listaDetalles.some(
        (d) => d.relatedProductId == item.relatedProductId
      )
    );
  }
  checkIfListHasEmptyItem(item: DetalleDte) {
    return (
      item.relatedProductId == null &&
      this.listaDetalles.some(
        (d) =>
          d.relatedProductId == null &&
          (d.precioUnitario == undefined || d.nombre == "")
      )
    );
  }
}
