import { Injectable, EventEmitter } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Sucursal } from "../models/Sucursal";
import { UserModel } from "../modules/auth";
import { AuthenticatedResponse } from "../shared/models/AuthenticatedResponse";
import { MsalUserProfile } from "../shared/models/MsalUserProfile";
import { SessionUser } from "../shared/models/sessionUser";
import { LocalStorageService } from "./local-storage.service";
import { EmisorService } from "./emisor.service";

@Injectable({
	providedIn: "root",
})
export class TokenStorageService {
	private currentUser: SessionUser;
	private TOKEN_KEY = "auth-token";
	private USER_KEY = "auth-user";
	private COMPANY_KEY = "auth-company"; //emisor id
	private SUBSIDIARY_KEY = "auth-subsidiary"; // sucursal id
	private USER_NAME_KEY = "auth-username";
	private USER_FIRST_NAME_KEY = "auth-firstname";
	private USER_LAST_NAME_KEY = "auth-lastname";
	private USER_MSAL_KEY = "msal-user";
	private MSAL_SESSION_ACTIVE = "msal-active";
	private RESELLER = "auth-reseller";
	// private USER_RESELLER_ID = "auth-reseller-id";
	// private USER_RESELLER_FONO = "auth-reseller-fono";
	// private USER_RESELLER_LOGO = "auth-reseller-logo";

	userIsLogged = new EventEmitter<boolean>();
	currentUser$: Observable<UserModel>;
	currentUserSubject: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(undefined);
	public currentEmisorObservable: Observable<string>;
	currentEmisorSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");
	msalUser$: Observable<MsalUserProfile>;
	profile: MsalUserProfile;
	msalUserSubject: BehaviorSubject<MsalUserProfile> = new BehaviorSubject<MsalUserProfile>(undefined);
	private loggedLocal: boolean = false;
	private loggedMsal: boolean = false;
	private sucursalHasChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public sucursalHasChangedObservable: Observable<boolean>;
	get currentUserValue(): UserModel {
		return this.currentUserSubject.value;
	}

	set currentUserValue(user: UserModel) {
		this.currentUserSubject.next(user);
	}
	//private TOKEN_EXPIRATION = 'auth-exp';
	currentEmisorIdEmitter = new EventEmitter<string>();

	constructor(private localStorage: LocalStorageService, private http: HttpClient, private msalService: MsalService) {
		//this.currentUserSubject = ;
		this.currentUser$ = this.currentUserSubject.asObservable();
		this.msalUser$ = this.msalUserSubject.asObservable();
		this.currentEmisorSubject = new BehaviorSubject<string>("");
		this.currentEmisorObservable = this.currentEmisorSubject.asObservable();
		this.sucursalHasChangedSubject = new BehaviorSubject<boolean>(false);
		this.sucursalHasChangedObservable = this.sucursalHasChangedSubject.asObservable();
	}

	authContainer = window.sessionStorage;

	signOut(): void {
		this.authContainer.clear();
	}

	public saveToken(token: string, expiration?: string): void {
		this.authContainer.removeItem(this.TOKEN_KEY);
		//this.authContainer.removeItem(this.TOKEN_EXPIRATION);
		this.authContainer.setItem(this.TOKEN_KEY, token);
		//this.authContainer.setItem(this.TOKEN_EXPIRATION,expiration);
	}

	public getToken(): string | null {
		return this.authContainer.getItem(this.TOKEN_KEY);
	}

	// public getExpiration():string | null{
	//   return this.authContainer.getItem(this.TOKEN_EXPIRATION);
	// }

	public saveUserData(response: AuthenticatedResponse): void {
		this.authContainer.removeItem(this.USER_KEY);
		this.authContainer.removeItem(this.COMPANY_KEY);
		this.authContainer.removeItem(this.SUBSIDIARY_KEY);
		this.authContainer.removeItem(this.USER_NAME_KEY);
		this.authContainer.removeItem(this.USER_FIRST_NAME_KEY);
		this.authContainer.removeItem(this.USER_LAST_NAME_KEY);
		this.currentUser = new SessionUser(response.id, response.firstName, response.lastName, response.identifiers, response.temporaryPassword, response.superAdmin, response.configured, response.alertDemo,  response.email, response.cargo, response.isPartner);
		this.authContainer.setItem(this.COMPANY_KEY, response.emisores[0].emisorId);
		this.authContainer.setItem(this.SUBSIDIARY_KEY, response.sucursales[0].sucursalId);
		this.authContainer.setItem(this.USER_KEY, JSON.stringify(this.currentUser));
		this.authContainer.setItem(this.USER_NAME_KEY, response.firstName + " " + response.lastName);
		this.authContainer.setItem(this.USER_FIRST_NAME_KEY, response.firstName);
		this.authContainer.setItem(this.USER_LAST_NAME_KEY, response.lastName);
		let userFullName = new UserModel();
		userFullName.fullname = response.firstName + " " + response.lastName;
		userFullName.firstname = response.firstName;
		userFullName.lastname = response.lastName;
		this.currentUserSubject = new BehaviorSubject<UserModel>(userFullName);
	}

	public getUser(): any {
		const user = this.authContainer.getItem(this.USER_KEY);
		if (user) {
			return JSON.parse(user);
		}
		return {};
	}

	public getCompany() {
		const companyId = this.authContainer.getItem(this.COMPANY_KEY);
		this.currentEmisorIdEmitter.emit(companyId);
		return companyId;
	}

	public getSubsidiary() {
		return this.authContainer.getItem(this.SUBSIDIARY_KEY);
	}

	public getUserName() {
		return this.authContainer.getItem(this.USER_NAME_KEY);
	}

	public getUserFirstName() {
		return this.authContainer.getItem(this.USER_FIRST_NAME_KEY);
	}
	public getUserLastName() {
		return this.authContainer.getItem(this.USER_LAST_NAME_KEY);
	}

	public setCompany(newCompanyId: string) {
		this.authContainer.removeItem(this.COMPANY_KEY);
		this.authContainer.setItem(this.COMPANY_KEY, newCompanyId);
		this.currentEmisorIdEmitter.emit(newCompanyId);
		this.currentEmisorSubject.next(newCompanyId);
		this.setSubsidiaryFromCompany(newCompanyId);
	}

	private setSubsidiaryFromCompany(companyId: string) {
		const subsidiarySource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
		let newSubsidiaryOfCompany = subsidiarySource.find((subsidiary) => subsidiary.emisorId == companyId);
		if (newSubsidiaryOfCompany) {
			this.authContainer.removeItem(this.SUBSIDIARY_KEY);
			this.authContainer.setItem(this.SUBSIDIARY_KEY, newSubsidiaryOfCompany.sucursalId);
		}
	}

	public setSubsidiaryDirectly(sucursalId: string) {
		this.authContainer.removeItem(this.SUBSIDIARY_KEY);
		this.authContainer.setItem(this.SUBSIDIARY_KEY, sucursalId);
	}

	public getSubsidiaryFromCompany(companyId: string) {
		const subsidiarySource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
		let newSubsidiaryOfCompany = subsidiarySource.find((subsidiary) => subsidiary.emisorId == companyId);
		return newSubsidiaryOfCompany.sucursalId;
	}

	updateOwnSucursal(suc: Sucursal) {
		let subsidiarySource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
		let subsidiaryIncluded: Sucursal = subsidiarySource.find((s) => s.sucursalId == suc.sucursalId);
		if (subsidiaryIncluded) {
			let index = subsidiarySource.indexOf(subsidiaryIncluded);
			subsidiarySource[index] = suc;
			this.localStorage.saveSucursales(subsidiarySource);
			this.sucursalHasChangedSubject.next(true);
		}
	}

	setMsalUser() {
		this.http.get(environment.msal_graph_endpoint).subscribe((profile) => {
			this.profile = profile as MsalUserProfile;
			this.authContainer.setItem(this.USER_MSAL_KEY, JSON.stringify(this.profile));
			this.msalUserSubject.next(this.profile);
			// console.log("Profile obtained:",profile);
		});
	}

	public getMsalUser() {
		let user = this.authContainer.getItem(this.USER_MSAL_KEY);
		// console.log(user);
		this.msalUserSubject.next(JSON.parse(user) as MsalUserProfile);
		this.loggedMsal = true;
		return user;
	}

	msalLogin() {
		this.msalService.loginRedirect({
			scopes: ["user.read"],
		});
	}

	msalLogout() {
		this.msalService.instance.logoutRedirect({
			postLogoutRedirectUri: environment.msal_postlogout_redirect_uri,
		});
		this.loggedMsal = false;
	}

	msalUserIsLogged() {
		let estaLogeado = JSON.parse(this.authContainer.getItem(this.MSAL_SESSION_ACTIVE));
		this.loggedMsal = estaLogeado;
		// console.log("usuario msal logeado? ",this.loggedMsal)
		return this.loggedMsal;
	}

	msalUserHasLogged() {
		this.loggedMsal = true;
		this.authContainer.removeItem(this.MSAL_SESSION_ACTIVE);
		this.authContainer.setItem(this.MSAL_SESSION_ACTIVE, JSON.stringify(this.loggedMsal));
	}

	updateAmbientOfCompany(emisorId: string, ambiente: number) {
		let emisorReturned = this.localStorage.updateAmbienteOfEmisor(emisorId, ambiente);
		if (emisorReturned == this.getCompany()) {
			this.currentEmisorSubject.next(this.getCompany());
		}
	}	
}
