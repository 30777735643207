<form [formGroup]="economicActivityForm" (ngSubmit)="onSubmit()" #productFormRef="ngForm">
	<div class="modal-body">
		<div>
			<h4>Asignar Actividades Económicas</h4>
			<div class="mt-5 form-group position-relative mx-auto">
				<div class="row pt-5">
					<label for="" class="col-3 label-control">Actividades Económicas</label>
					<div class="col-7">
						<!-- <ng-select
                    [clearable]="true" 
                    bindLabel="nombre" 
                    [searchable]="false"
                    [selectOnTab]="false"
                    [items]="economicActivitiesSource"
                    formControlName="actividadEconomica"
                    placeholder="Seleccione actividad económica">
                    </ng-select> -->
						<ng-select formControlName="actividadEconomica" [items]="economicActivitiesSource" [clearable]="true" (blur)="filtro()" placeholder="Seleccione actividad económica" bindLabel="descripcion"></ng-select>
					</div>
					<div class="ml-5">
						<button *ngIf="permissions.getPermission('30-80-19-10').asignado" type="button" class="btn btn-success" (click)="addEconomicActivityToList()">Agregar</button>
					</div>
				</div>
			</div>
			<div *ngIf="economicActivitiesList.length > 0">
				<h4>Actividades Económicas Asignadas</h4>
				<table class="table">
					<thead>
						<tr>
							<td>descripcion</td>
							<td>Acción</td>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of economicActivitiesList; let index = index">
							<td>{{ item.descripcion }}</td>
							<td>
								<a *ngIf="permissions.getPermission('30-80-19-12').asignado" ngbTooltip="Quitar " class="primary edit mr-1 mrless" (click)="deleteItem(index)">
									<div class="icon-circle bg-danger text-center align-middle pt-info-button">
										<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
									</div>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button *ngIf="permissions.getPermission('30-80-19-14').asignado" type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
			<i class="la la-paper-plane-o d-lg-none"></i>
			<span class="d-none d-lg-block">Guardar</span>
		</button>
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
