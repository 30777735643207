import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Observable } from "rxjs";
import { LayoutService } from "../../../../core";
import { SubheaderService } from "../_services/subheader.service";
import { BreadcrumbItemModel } from "../_models/breadcrumb-item.model";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { InvoicingButton } from "src/app/shared/models/InvoicingButton.enum";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { FoliosService } from "src/app/services/folios.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: "app-subheader1",
	templateUrl: "./subheader1.component.html",
	styleUrls: ["../../../../../../assets/deployAssets/theme/onvision.scss"],
})
export class Subheader1Component implements OnInit {
	subheaderCSSClasses = "";
	subheaderContainerCSSClasses = "";
	subheaderMobileToggle = false;
	subheaderDisplayDesc = false;
	subheaderDisplayDaterangepicker = false;
	title$: Observable<string>;
	breadcrumbs$: Observable<BreadcrumbItemModel[]>;
	breadcrumbs: BreadcrumbItemModel[] = [];
	@Input() title: string;
	public showFacturacionButtonGroup: boolean = false;
	public showPlanOptions: boolean = false;
	invoicingClientDisabled$: Observable<boolean>;
	private payPlanModal: NgbModalRef;
	invoiceType$: Observable<InvoiceType>;
	currentType: InvoiceType;
	showFactGuiasButton: boolean = false;
	public disableButton = false;
	public showBheButtonGroup: boolean = false;
	public loadingFoliosSummary$: Observable<boolean>;
	public tipoCantidadFolio$: Observable<[string, number]>;
	public nombreTipoDteDisponible: string;
	public cantidadTipoDteDisponible: number;
	public currentTipoCantidad: any;

	constructor(private layout: LayoutService, private subheader: SubheaderService, private cdr: ChangeDetectorRef, private router: Router, private facturaService: FacturacionIndividualService, private foliosService: FoliosService, private tokenService: TokenStorageService) {
		this.title$ = this.subheader.titleSubject.asObservable();
		this.invoicingClientDisabled$ = this.facturaService.clientIsUnloadedObservable;
		this.invoiceType$ = this.facturaService.currentInvoiceTypeObservable;
		this.loadingFoliosSummary$ = this.foliosService.isLoadingFolios;
		this.tipoCantidadFolio$ = this.facturaService.tipoCantidadFolioObservable;
	}

	ngOnInit() {
		this.title$ = this.subheader.titleSubject.asObservable();
		this.breadcrumbs$ = this.subheader.breadCrumbsSubject.asObservable();
		this.subheaderCSSClasses = this.layout.getStringCSSClasses("subheader");
		this.subheaderContainerCSSClasses = this.layout.getStringCSSClasses("subheader_container");
		this.subheaderMobileToggle = this.layout.getProp("subheader.mobileToggle");
		this.subheaderDisplayDesc = this.layout.getProp("subheader.displayDesc");
		this.subheaderDisplayDaterangepicker = this.layout.getProp("subheader.displayDaterangepicker");
		this.breadcrumbs$.subscribe((res) => {
			this.breadcrumbs = res;
			this.cdr.detectChanges();
		});
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((val) => {
			if ((val as NavigationEnd).urlAfterRedirects == "/facturador-individual") {
				this.subheader.setTitle("");
				this.subheader.setBreadcrumbs([]);
				this.showFacturacionButtonGroup = true;
				this.showBheButtonGroup = false;
				this.nombreTipoDteDisponible = "";
			} else if ((val as NavigationEnd).urlAfterRedirects == "/emisor-boletas-honorarios") {
				this.subheader.setTitle("");
				this.subheader.setBreadcrumbs([]);
				this.showBheButtonGroup = true;
				this.showFacturacionButtonGroup = false;
			} else if ((val as NavigationEnd).urlAfterRedirects == "/plan") {
				this.subheader.setTitle("Mi cuenta");
				this.showFacturacionButtonGroup = false;
				this.showBheButtonGroup = false;
			} else {
				this.showFacturacionButtonGroup = false;
				this.showBheButtonGroup = false;
			}
			this.showFacturacionButtonGroup = (val as NavigationEnd).urlAfterRedirects == "/facturador-individual";
			this.showPlanOptions = (val as NavigationEnd).urlAfterRedirects == "/plan";
		});
		this.invoicingClientDisabled$.subscribe((val) => (this.disableButton = val));
		this.invoiceType$.subscribe((type) => {
			this.currentType = type;
			if (this.currentType == InvoiceType.Factura) {
				this.showFactGuiasButton = true;
			} else {
				this.showFactGuiasButton = false;
			}
		});
		this.tipoCantidadFolio$.subscribe((tipoCantidad) => {
			this.currentTipoCantidad = tipoCantidad;
			if (tipoCantidad) {
				this.nombreTipoDteDisponible = tipoCantidad[0];
				this.cantidadTipoDteDisponible = tipoCantidad[1];
			} else {
				this.nombreTipoDteDisponible = "";
				this.cantidadTipoDteDisponible = 0;
			}
			this.cdr.detectChanges();
		});
	}

	sendEmitAction() {
		this.facturaService.sendEmitAction();
	}

	sendPreviewAction() {
		this.facturaService.sendPreviewAction();
	}

	sendCancelAction() {
		this.facturaService.sendCancelAction();
	}

	sendFacturaGuiasAction() {
		this.facturaService.sendFacturaGuiaAction();
	}

	closeExtendModal() {
		this.payPlanModal.close();
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;

		const newWindow = window.open(
			url,
			title,
			`
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}
}
