<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
	<!--begin::Logo-->
	<a routerLink="/" [ngClass]="parentLogoClass">
		<img alt="Logo" [attr.src]="headerLogo" [ngClass]="logoClasses" />
		<!-- <h2 class="text-dark">PORTAL DTE</h2> -->
	</a>
	<!--end::Logo-->

	<ng-container *ngIf="asideSelfMinimizeToggle">
		<button style="margin-left: 10" class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" (click)="collapsed()">
			<span [inlineSVG]="'./assets/media/svg/icons/Navigation/Exchange.svg'" cacheSVG="true" class="svg-icon svg-icon-xl"></span>
		</button>
	</ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
	<ng-container *ngIf="disableAsideSelfDisplay">
		<!--begin::Header Logo-->
		<a routerLink="/">
			<img alt="Logo" [attr.src]="headerLogo" />
		</a>
		<!--end::Header Logo-->
	</ng-container>
	<!--begin::Menu Container-->
	<div id="kt_aside_menu" class="aside-menu d-flex align-items-start flex-column" style="height: 100%; width: 100%" [ngClass]="asideMenuCSSClasses" data-menu-vertical="1" [attr.data-menu-scroll]="asideMenuScroll" [attr.data-menu-dropdown]="asideMenuDropdown" data-menu-dropdown-timeout="500">
		<!-- begin::Menu Nav -->
		<ul class="menu-nav" style="width: 100%" [ngClass]="ulCSSClasses">
			<!-- PANEL DE CONTROL  -->

			<!-- begin::section -->
			<li class="menu-section" *ngIf="isSuperAdmin">
				<h4 class="menu-text">Panel de Control</h4>
				<i class="menu-icon ki ki-bold-more-hor icon-md"></i>
			</li>
			<!-- end::section -->

			<!--begin::1 Level -->
			<li *ngIf="isSuperAdmin" class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">
				<a class="menu-link menu-toggle" routerLinkActive="active" href="">
					<span [inlineSVG]="'./assets/media/svg/icons/General/Settings-2.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
					<span class="menu-text">Configuración</span>
					<i class="menu-arrow"></i>
				</a>

				<div class="menu-submenu" *ngIf="isSuperAdmin">
					<i class="menu-arrow"></i>
					<ul class="menu-subnav">
						<li class="menu-item menu-item-parent" aria-haspopup="true">
							<span class="menu-link">
								<span class="menu-text">Configuración</span>
							</span>
						</li>
						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/plantillas" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Plantillas Impresión</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/logs" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Registro de Transacciones</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/correoEnvio" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Correos de Envio</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/impuestos" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Impuestos</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/resellers" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Resellers</span>
							</a>
						</li>
						<!-- end::2 Level -->
					</ul>
				</div>
			</li>
			<!--end::1 Level-->

			<!-- begin::section -->
			<li class="menu-section" *ngIf="isSuperAdmin || (permissions.getPermission('30').asignado && (permissions.getPermission('30-10').asignado || permissions.getPermission('30-30').asignado || permissions.getPermission('30-20').asignado))">
				<h4 class="menu-text">Administración</h4>
				<i class="menu-icon ki ki-bold-more-hor icon-md"></i>
			</li>
			<!-- begin::section -->

			<!--begin::1 Level -->
			<li class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" *ngIf="isSuperAdmin || (permissions.getPermission('30').asignado && (permissions.getPermission('30-10').asignado || permissions.getPermission('30-30').asignado || permissions.getPermission('30-20').asignado))" routerLinkActive="menu-item-open">
				<a class="menu-link menu-toggle" routerLinkActive="active" href="">
					<span [inlineSVG]="'./assets/media/svg/icons/Clothes/Briefcase.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
					<span class="menu-text">Mantenedores</span>
					<i class="menu-arrow"></i>
				</a>

				<div class="menu-submenu" *ngIf="isSuperAdmin || (permissions.getPermission('30').asignado && (permissions.getPermission('30-10').asignado || permissions.getPermission('30-30').asignado || permissions.getPermission('30-20').asignado))">
					<i class="menu-arrow"></i>
					<ul class="menu-subnav">
						<li class="menu-item menu-item-parent" aria-haspopup="true">
							<span class="menu-link">
								<span class="menu-text">Mantenedores</span>
							</span>
						</li>

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('30-15').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/cesionarios" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Cesionarios</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('30-20').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/clientes" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Clientes</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('30-20').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/clientes-internacionales" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Clientes Internacionales</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('30-60').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/productos" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Productos</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('30-30').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/proveedores" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Proveedores</span>
							</a>
						</li>
						<!-- end::2 Level -->
					</ul>
				</div>
			</li>
			<!--end::1 Level-->

			<!--begin::1 Level -->
			<li class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" *ngIf="isSuperAdmin || permissions.getPermission('30').asignado" routerLinkActive="menu-item-open">
				<a class="menu-link menu-toggle" routerLinkActive="active" href="">
					<span [inlineSVG]="'./assets/media/svg/icons/Clothes/Briefcase.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
					<span class="menu-text">Gestión</span>
					<i class="menu-arrow"></i>
				</a>

				<div class="menu-submenu" *ngIf="isSuperAdmin || permissions.getPermission('30').asignado">
					<i class="menu-arrow"></i>
					<ul class="menu-subnav">
						<li class="menu-item menu-item-parent" aria-haspopup="true">
							<span class="menu-link">
								<span class="menu-text">Gestión</span>
							</span>
						</li>

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('20-20').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/certificados" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Certificados Digitales</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('20-10').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/folios" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Folios</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<!-- begin::2 Level -->

						<li *ngIf="isSuperAdmin || permissions.getPermission('30-50').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/perfiles" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Perfiles</span>
							</a>
						</li>
						<!-- begin::2 Level -->

						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('30-40').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/usuarios" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Usuarios</span>
							</a>
						</li>
						<!-- end::2 Level -->

						<li *ngIf="isSuperAdmin || permissions.getPermission('30-80').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/emisores" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Empresas</span>
							</a>
						</li>

						<li *ngIf="isSuperAdmin || permissions.getPermission('30-110').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/alertas" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Alertas</span>
							</a>
						</li>
						<!-- end::2 Level -->
					</ul>
				</div>
			</li>
			<!--end::1 Level-->

			<!-- Facturación DTE -->
			<!-- begin::section -->
			<li *ngIf="permissions.getPermission('40').asignado || permissions.getPermission('50').asignado" class="menu-section">
				<h4 class="menu-text">Documentos Electronicos</h4>
				<!-- <i class="menu-icon flaticon-more-v2"></i> -->
				<i class="menu-icon ki ki-bold-more-hor icon-md"></i>
			</li>
			<!-- end::section -->

			<!--begin::1 Level -->

			<li *ngIf="isSuperAdmin || permissions.getPermission('40').asignado" class="menu-item menu-item-submenu" aria-haspopup="true" #facturacionesMenu data-menu-toggle="hover" routerLinkActive="menu-item-open">
				<a class="menu-link menu-toggle" routerLinkActive="active" href="">
					<span [inlineSVG]="'./assets/media/svg/icons/Clothes/Briefcase.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
					<span class="menu-text">Facturación Emitida</span>
					<i class="menu-arrow"></i>
				</a>

				<div class="menu-submenu" *ngIf="isSuperAdmin || permissions.getPermission('40').asignado">
					<i class="menu-arrow"></i>
					<ul class="menu-subnav">
						<li class="menu-item menu-item-parent" aria-haspopup="true">
							<span class="menu-link">
								<span class="menu-text">Facturación Emitida</span>
							</span>
						</li>
						<!-- begin::2 Level *ngIf="permissions.getPermission('40-10').asignado" -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('40-10').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/factura-masiva" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Facturación Masiva</span>
							</a>
						</li>
						<!-- end::2 Level -->
						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('40-18').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/documentos-emitidos" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Documentos Emitidos</span>
							</a>
						</li>
						<!-- end::2 Level -->
						<!-- begin::2 Level -->

						<li *ngIf="isSuperAdmin || permissions.getPermission('40-10').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/guias-despacho" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Guias Emitidas</span>
							</a>
						</li>
						<!-- end::2 Level -->
						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('40-18').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/exportaciones" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Documentos Exportación</span>
							</a>
						</li>
						<!-- end::2 Level -->
						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('40-18-32').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/rvd" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Registro de Ventas diarias</span>
							</a>
						</li>
						<!-- end::2 Level -->
						<!-- begin::2 Level -->
						<li *ngIf="isSuperAdmin || permissions.getPermission('40-18').asignado" class="menu-item d-none" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/facturador-individual" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Documentos Emitidos</span>
							</a>
						</li>
						<!-- end::2 Level -->
					</ul>
				</div>
			</li>
			<!--begin::1 Level -->

			<li *ngIf="isSuperAdmin || permissions.getPermission('45').asignado" class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">
				<a class="menu-link menu-toggle" routerLinkActive="active" href="">
					<span [inlineSVG]="'./assets/media/svg/icons/Communication/Group.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
					<span class="menu-text">Documentos Cesión</span>
					<i class="menu-arrow"></i>
				</a>

				<div class="menu-submenu" *ngIf="isSuperAdmin || permissions.getPermission('45').asignado">
					<i class="menu-arrow"></i>
					<ul class="menu-subnav">
						<li class="menu-item menu-item-parent" aria-haspopup="true">
							<span class="menu-link">
								<span class="menu-text">Documentos Cesión</span>
							</span>
						</li>

						<!-- begin::2 Level -->

						<li *ngIf="isSuperAdmin || permissions.getPermission('45-10').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/cesiones" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Cesiones Emitidas</span>
							</a>
						</li>
						<!-- end::2 Level -->
					</ul>
				</div>
			</li>

			<li *ngIf="isSuperAdmin || permissions.getPermission('50').asignado" class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">
				<a class="menu-link menu-toggle" routerLinkActive="active" href="">
					<span [inlineSVG]="'./assets/media/svg/icons/Shopping/Box1.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
					<span class="menu-text">Facturación Recibida</span>
					<i class="menu-arrow"></i>
				</a>

				<div class="menu-submenu" *ngIf="isSuperAdmin || permissions.getPermission('50').asignado">
					<i class="menu-arrow"></i>
					<ul class="menu-subnav">
						<li class="menu-item menu-item-parent" aria-haspopup="true">
							<span class="menu-link">
								<span class="menu-text">Facturación Recibida</span>
							</span>
						</li>

						<!-- begin::2 Level -->

						<li *ngIf="isSuperAdmin || permissions.getPermission('50-10').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/documentos-recibidos" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Documentos Recibidos</span>
							</a>
						</li>
						<!-- end::2 Level -->
						<!-- begin::2 Level -->

						<li *ngIf="isSuperAdmin || permissions.getPermission('50-10').asignado" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
							<a class="menu-link" routerLink="/compras" routerLinkActive="active">
								<i class="menu-bullet menu-bullet-dot">
									<span></span>
								</i>
								<span class="menu-text">Facturación Compra</span>
							</a>
						</li>
						<!-- end::2 Level -->
					</ul>
				</div>
			</li>

			<!--end::1 Level-->
		</ul>
		<!-- end::Menu Nav -->
		<!--Ambient Showing-->

		<!-- version string  -->
		<div class="text-muted mt-auto mx-auto" *ngIf="!pressCollapsed">
			<!-- <div class="mx-auto mt-auto d-block mb-2" style="text-align: center!important;">
        <app-company-ambient class="ml-5" style="text-align: center!important;"></app-company-ambient>

      </div> -->

			<div class="text-muted align-self-baseline mx-auto mb-3" style="text-align: center !important">Version {{ appVersion }}</div>
		</div>
		<div class="text-muted mt-auto mx-auto" *ngIf="pressCollapsed" style="text-align: center !important">
			<div class="text-muted align-self-baseline mx-auto mb-3">
				{{ appVersion }}
			</div>
		</div>
	</div>
	<!--end::Menu Container-->
</div>
<!--end::Aside Menu-->
