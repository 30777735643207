import * as moment from "moment";

export class CertificadoDigital {
   public certificadoDigitalId: string;
   public emisorId: string;
   public fechaSubida: Date;
   public fechaVenc: Date;
   public friendlyName: string;
   public rut: string;
   public password: string;
   public pfx: File;
   public rutString: string;
   public today: number = Date.now();
   private _DiasRestantes: number;
   public activo: boolean;
   //public sucursales: Sucursal[];

   public mesesRestantes: number;
   public get diasRestantes() {
      let fechaVenc = this.fechaVenc;
      let diff: number = fechaVenc.valueOf() - this.today;
      let diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));
      return diffDays > 0 ? diffDays : 0;
   }

   public set diasRestantes(fechaVencim) {
      let fechaVenc = fechaVencim;
      let diff: number = fechaVenc.valueOf() - this.today;
      let diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));
      this._DiasRestantes = diffDays > 0 ? diffDays : 0;
   }
}
