import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { LayoutService } from "../../../../../_metronic/core";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";

function getCurrentURL(location) {
   return location.split(/[?#]/)[0];
}

@Component({
   selector: "app-header-menu",
   templateUrl: "./header-menu.component.html",
   styleUrls: ["./header-menu.component.scss"],
})
export class HeaderMenuComponent implements OnInit {
   ulCSSClasses: string;
   rootArrowEnabled: boolean;
   location: Location;
   headerMenuDesktopToggle: string;
   public isSuperAdmin: boolean = false;
   constructor(private layout: LayoutService, private loc: Location, public permissions: PermissionService, private tokenService: TokenStorageService) {
      this.location = this.loc;
      this.isSuperAdmin = this.tokenService.getUser().superAdmin;
   }

   ngOnInit(): void {
      this.ulCSSClasses = this.layout.getStringCSSClasses("header_menu_nav");
      this.rootArrowEnabled = this.layout.getProp("header.menu.self.rootArrow");
      this.headerMenuDesktopToggle = this.layout.getProp("header.menu.desktop.toggle");
   }

   getMenuItemActive(url) {
      return this.checkIsActive(url) ? "menu-item-active" : "";
   }

   checkIsActive(url) {
      const location = this.location.path();
      const current = getCurrentURL(location);
      if (!current || !url) {
         return false;
      }

      if (current === url) {
         return true;
      }

      if (current.indexOf(url) > -1) {
         return true;
      }

      return false;
   }
}
