import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { FormatoPapel } from "src/app/models/FormatoPapel";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { TipoDte } from "src/app/models/tipoDte";
import { LocalStorageService } from "src/app/services/local-storage.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-plantilla-entry",
	templateUrl: "./plantilla-entry.component.html",
	styleUrls: ["./plantilla-entry.component.scss"],
})
export class PlantillaEntryComponent implements OnInit {
	public plantillaForm: FormGroup;
	@Input() public detailMode: boolean = false;
	@Input() public editMode: boolean = false;
	@Input() public plantillaToEdit: PlantillaImpresion = undefined;
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendPlantillaRequest: EventEmitter<any> = new EventEmitter<any>();
	public tipoDteSource: TipoDte[] = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
	public formatoPapelSource: FormatoPapel[] = JSON.parse(this.localStorage.getFormatoPapel()) as FormatoPapel[];
	public files: File[] = [];
	private plantillaObj: PlantillaImpresion = new PlantillaImpresion();
	constructor(private localStorage: LocalStorageService, private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.setPlantillaForm();
		if (this.plantillaToEdit) {
			this.setPlantillaDataToForm(this.plantillaToEdit);
		}
	}

	setPlantillaForm() {
		this.plantillaForm = this.formBuilder.group({
			descripcion: ["", Validators.required],
			formatoPapel: [, Validators.required],
			tipoDocumento: [, Validators.required],
			lineas: ["", [Validators.required, PositiveNumberValidator, Validators.min(1)]],
			defectoCheckbox: [, []],
			file: [, Validators.required],
		});
	}

	onSubmit() {
		if (this.plantillaForm.valid) {
			this.getFormData();
			this.sendPlantillaRequest.emit(this.plantillaObj);
		}
	}

	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}

	onSelect(event) {
		if (event.rejectedFiles[0] || this.getName(event) != "html") {
			Swal.fire({
				icon: "error",
				title: "Error de Archivo",
				text: "El archivo ingresado no es de formato html, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo",
			});
		}

		this.files.push(...event.addedFiles);
		if (this.files.length > 1) {
			this.files.shift();
		}
		this.plantillaForm.patchValue({ file: this.files[0] });
	}

	getName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	getFormData() {
		this.plantillaObj.descripcion = this.plantillaForm.controls.descripcion.value;
		let tipoDteObj: TipoDte = this.plantillaForm.controls.tipoDocumento.value;
		this.plantillaObj.tipoDteId = tipoDteObj.tipoDteId;
		this.plantillaObj.codigoTipoDte = tipoDteObj.codigosii;
		this.plantillaObj.nombreTipoDte = tipoDteObj.nombre;
		let formatoPapelObj: FormatoPapel = this.plantillaForm.controls.formatoPapel.value;
		this.plantillaObj.tipoPapel = formatoPapelObj.name;
		this.plantillaObj.maxLineasDetalle = this.plantillaForm.controls.lineas.value;
		this.plantillaObj.isDefault = this.plantillaForm.controls.defectoCheckbox.value;
		this.plantillaObj.file = this.files[0];
		if (this.plantillaToEdit != undefined) this.plantillaObj.tipoPlantillaId = this.plantillaToEdit.tipoPlantillaId;
	}

	setPlantillaDataToForm(receivedPlantilla: PlantillaImpresion) {
		this.plantillaForm.patchValue(receivedPlantilla);
		let tipoDteObj: TipoDte = this.tipoDteSource.find((tipo) => tipo.tipoDteId == receivedPlantilla.tipoDteId);
		this.plantillaForm.get("tipoDocumento").patchValue(tipoDteObj);
		let formatoPapelObj: FormatoPapel = this.formatoPapelSource.find((tipo) => tipo.name == receivedPlantilla.tipoPapel);
		this.plantillaForm.get("formatoPapel").patchValue(formatoPapelObj);
		this.plantillaForm.get("file").setValidators(null);
		this.plantillaForm.get("lineas").setValue(receivedPlantilla.maxLineasDetalle);
		this.plantillaForm.get("defectoCheckbox").setValue(receivedPlantilla.isDefault);
		//this.plantillaForm.updateValueAndValidity();
	}
}
