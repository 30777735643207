import { CurrencyPipe, DatePipe } from "@angular/common";
import { SplitInterpolation, ThrowStmt } from "@angular/compiler";
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { errorMonitor } from "events";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { element } from "protractor";
import { Observable, Subscription, zip } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { DteEmitido } from "src/app/models/Dte/DteEmitido";
import { DteFilter } from "src/app/models/Dte/DteFilter";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { TipoDte } from "src/app/models/tipoDte";
import { Traza } from "src/app/models/Traza";
import { DteEmitidosService } from "src/app/services/dte-emitidos.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import { ConfigMantenedorDTE } from "./config-mantenedor-dte";
import * as FileSaver from "file-saver";
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { FormrulesConfigService } from "src/app/shared/configuration/formrules-config.service";
import { MultipleEmailValidator } from "src/app/directives/multi-email.directive";
import { NotaCredito } from "src/app/models/NotaCredito";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { Response } from "src/app/shared/models/Response";
import * as JSZip from "jszip";
import { SameWordEmailValidator } from "src/app/directives/same-word-email.directive";
import { PermissionService } from "src/app/services/permission.service";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { NotaDebito } from "src/app/models/NotaDebito";
import { IDte } from "src/app/models/Dte/IDte";
import { EmisorService } from "src/app/services/emisor.service";
import { SessionUser } from "src/app/shared/models/sessionUser";
import { ReferenciaDte } from "src/app/models/Dte/ReferenciaDte";
import { ConsoleDevService } from "src/app/services/console-service.service";
import { SortType } from "@swimlane/ngx-datatable";
import { ActualizarEstadoSiiRequest } from "src/app/models/Dte/ActualizarEstadoSiiRequest";
import { ReenvioEmailRequest } from "src/app/models/Dte/ReenvioEmailRequest";
import { Conciliacion } from "src/app/models/Dte/Conciliacion";
import * as moment from "moment";
import { ReutilizacionMasivaRequest } from "src/app/models/Dte/ReutilzacionMasivaRequest";
import { FacturadorMasivoService } from "src/app/services/facturador-masivo.service";
import { SendInvoiceRequest } from "src/app/models/CorreoEnvio/SendInvoiceRequest";
import { CorreoEnvioService } from "src/app/services/CorreoEnvio.service";
import { PersonasAutorizadasService } from "../../../services/paseronas-autorizadas.service";

@Component({
	selector: "app-mantenedor-dte-emitidos",
	templateUrl: "./mantenedor-dte-emitidos.component.html",
	styleUrls: ["./mantenedor-dte-emitidos.component.scss"],
})
export class MantenedorDteEmitidosComponent implements OnInit {
	SortType = SortType;
	public tracesModal: NgbModalRef;
	public anuladorModal: NgbModalRef;
	public senderDteModal: NgbModalRef;
	public detailModal: NgbModalRef;
	private cesionDteModal: NgbModalRef;
	loading: boolean = false;
	public dateConciliacion: any = { startDate: null, endDate: null };
	public todayString = moment();
	public validDate: boolean = true;
	private modalImportedDteSii: NgbModalRef;
	public cantidadDTE: number;
	public maxDTE: number;
	locale = {
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "MM/YYYY",
	};
	public configLocal = ConfigMantenedorDTE;
	public rowsMantenedor: DteEmitido[] = [];
	public selected: DteEmitido[] = []; //dtes seleccionados por checkbox
	public selectedItem: DteEmitido; //dte seleccionado para envio individual y despliegue en modal
	public selectedAllItemsInPage: any = {}; // bool para la seleccion total de elementos en tabla
	public searchRows: any[] = [];
	public searchCriteria;
	fileIdState: Observable<object>;
	fileIdValue: string;
	registrationIdValue: string;
	receptorRutString: string;
	public configScroll: PerfectScrollbarConfigInterface = {};
	public emisorId: string;
	public usuarioId: string;
	public sucursalId: string;
	public empresasSource: Emisor[];
	public tipoDteSource: TipoDte[];
	public page = new Page();
	public filter = new DteFilter();
	public tracesToShow: Traza[] = [];
	public dteToShow: DteEmitido = new DteEmitido();
	public dteToNullify: DteEmitido = new DteEmitido();
	public dteToNullifyReferences: IDte[] = [];
	public senderForm: FormGroup;
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	@ViewChild("notesSwal") private notesSwal: SwalComponent;
	@ViewChild("tableMobile") tableRef: any;
	@ViewChild("table") table: any;

	public filterDescription: string;
	public nombreTipoNota: string = "Anulación";
	expanded: any = {};
	public componentStarted: boolean = false;
	public isSuperAdmin: boolean = this.tokenService.getUser().superAdmin;
	constructor(
		public activatedRoute: ActivatedRoute,
		private dteService: DteEmitidosService,
		private facturadorService: FacturadorMasivoService,
		private ref: ChangeDetectorRef,
		private tokenService: TokenStorageService,
		private localStorage: LocalStorageService,
		public utilities: UtilitiesService,
		private modalService: NgbModal,
		private datePipe: DatePipe,
		private currencyPipe: CurrencyPipe,
		private formBuilder: FormBuilder,
		private personasAutorizadasService: PersonasAutorizadasService,
		private formConfig: FormrulesConfigService,
		public permissions: PermissionService,
		private router: Router,
		private emisorService: EmisorService,
		private correoEnvioService: CorreoEnvioService,
		private logService: ConsoleDevService
	) {
		this.emisorId = this.tokenService.getCompany();
		this.usuarioId = (this.tokenService.getUser() as SessionUser).id;
		this.sucursalId = this.tokenService.getSubsidiary();
		this.filter.importacionMasivaId = "";

		this.empresasSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		if (this.isSuperAdmin) {
			this.getEmisoresFullList();
		}

		this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		this.filter.emisorId = this.emisorId;
		this.filter.sucursalId = this.sucursalId;
		this.checkStateId();
		this.starterPage();
		if (this.fileIdState) {
			this.getFileIdInState();
		}
		this.filter.tipoDteId = "";
		this.filter.reutilizado = 0;
		this.createSenderForm();

		let sessionEmisor = this.empresasSource.find((emisor) => emisor.emisorId == this.emisorId);
	}
	checkStateId() {
		this.fileIdState = this.activatedRoute.paramMap.pipe(
			map(() => {
				return window.history.state;
			})
		);
	}
	getFileIdInState() {
		this.loading = true;
		this.fileIdState
			.subscribe((result) => {
				this.fileIdValue = result["paramId"];
				if (this.fileIdValue) {
					this.filter.importacionMasivaId = this.fileIdValue ? this.fileIdValue : "";
				}
				this.receptorRutString = result["receptorRut"];
				this.filterDescription = result["filterDescription"];
				let emisorImportado = result["filterEmisor"];
				this.filter.emisorId = emisorImportado ? emisorImportado : this.emisorId;
				this.filter.sucursalId = emisorImportado ? "" : this.sucursalId;
				this.filter.rutReceptor = this.receptorRutString ? this.receptorRutString : "";

				let fechaEmision = result["date"];
				if (fechaEmision) {
					this.filter.fechaDesde = fechaEmision;
					this.filter.fechaHasta = fechaEmision;
				}

				let codigoSii = result["codigoSii"];
				if (codigoSii) {
					this.filter.codigoSii = codigoSii;
				}

				let nulo = result["nulos"];
				if (nulo != undefined) {
					this.filter.nulos = nulo;
				}

				this.loading = false;
			})
			.add(() => {
				this.loading = false;
			});
	}

	ngOnInit(): void {
		this.setPage({ offset: 0 });
	}

	downloadFile() {}
	detailOpenModal(modalRef, row: DteEmitido) {
		this.dteToShow = row;
		console.log("dte to show", this.dteToShow);
		this.loading = true;
		if (row.codigoTipoDte == 56 || row.codigoTipoDte == 61) {
			//calling dte references for shiwing its details
			this.dteService
				.getReferenciasDte(row.dteId)
				.subscribe(
					(result) => {
						let dteReferences = result.data as ReferenciaDte[];
						dteReferences.forEach((ref) => {
							if (ref.dte) {
								this.setEmisorToElement(ref.dte);
								this.setTipoDteToElement(ref.dte);
							}
						});
						row.listaReferencias = dteReferences;
						this.logService.log(row.listaReferencias, result.data);
					},
					(error) => {
						this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador" + this.utilities.setErrorMessageFromArray(error.error));
					}
				)
				.add(() => {
					this.dteService
						.getReceptorDte(row.dteId)
						.subscribe(
							(res) => {
								this.dteToShow.receptorAsociado = res.data;
							},
							(err) => {
								this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador" + this.utilities.setErrorMessageFromArray(err.error));
							}
						)
						.add(() => {
							this.dteService
								.getDetalleDte(row.dteId)
								.subscribe(
									(res) => {
										this.dteToShow.detalleDte = res.data;
									},
									(err) => {
										this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador" + this.utilities.setErrorMessageFromArray(err.error));
									}
								)
								.add(() => {
									this.loading = false;
									this.ref.detectChanges();
									this.detailModal = this.modalService.open(modalRef, {
										windowClass: "",
										size: "lg",
										centered: true,
									});
								});
						});
				});
		} else {
			this.dteService
				.getInfoDetalleDte(row.dteId)
				.subscribe(
					(result) => {
						console.log("result get detalle", result);
						this.dteToShow = result.dteEmitido;
						this.dteToShow.receptorAsociado = result.receptor;
						this.dteToShow.importado = result.dteEmitido.importado;

						//this.dteToShow.descuentoGlobal = result.descuentoRecargos != null && result.descuentoRecargos.length > 0 ? result.descuentoRecargos[0].valor : undefined;
						this.dteToShow.receptorAsociado.razonSocial = result.receptor.razonSocial;
						this.dteToShow.receptorAsociado.rutString = result.receptor.rutString;
						this.dteToShow.detalleDte = result.detalleDtes as DetalleDte[];
						this.dteToShow.impuestos = this.dteToShow.detalleDte.reduce((suma, detalle) => (suma += detalle.totalImpuestosAdicionales), 0);
						//this.dteToShow = { ...this.dteToShow } as DteEmitido;

						this.ref.detectChanges();
					},
					(error) => {
						this.loading = false;
						this.ref.detectChanges();
						this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador" + this.utilities.setErrorMessageFromArray(error.error));
					}
				)
				.add(() => {
					this.loading = false;
					this.ref.detectChanges();
					this.detailModal = this.modalService.open(modalRef, {
						windowClass: "",
						size: "lg",
						centered: true,
					});
				});
		}
	}

	// checkBoletaForImpuestos(dte: DteEmitido): number | undefined {
	// 	//check if the total value of Boleta is greater than the sum of his details and tax, if not get the difference as others taxes(impuestos)
	// 	if (dte.impuestos != undefined) {
	// 		return dte.impuestos;
	// 	} else {
	// 		return undefined;
	// 	}
	// }

	searchElements(emisorId: string) {
		this.loading = true;
		this.dteService.getDataByPage(this.filter, this.page).subscribe(
			(result) => {
				let elements = result.data as DteEmitido[];
				elements.forEach((row) => {
					this.setEmisorToElement(row);
					this.setTipoDteToElement(row);
				});
				this.rowsMantenedor = [...elements];
			},
			(error) => {},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	ngOnDestroy() {
		this.fileIdState = undefined;
	}

	changeSearchedRows(data) {
		if (data != null) {
			this.rowsMantenedor = data;
		}
	}

	onSelect({ selected }) {
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	getId(row) {
		return row.dteId;
	}

	selectAll(event) {
		if (!this.selectedAllItemsInPage[this.page.pageNumber]) {
			if (this.selected.length > 0) {
				this.rowsMantenedor.map((dte) => {
					this.selected = this.selected.filter((selectedDte) => selectedDte.dteId !== dte.dteId);
				});
			}
			this.selected.push(...this.rowsMantenedor);
			this.selected = [...this.selected];
			this.selectedAllItemsInPage[this.page.pageNumber] = true;
		} else {
			this.rowsMantenedor.map((dte) => {
				this.selected = this.selected.filter((selectedDte) => selectedDte.dteId !== dte.dteId);
			});
			this.selectedAllItemsInPage[this.page.pageNumber] = false;
		}
	}

	setEmisorToElement(row: IDte) {
		if (row.emisorId) {
			let empresaAsociada = this.empresasSource.find((Emisor) => Emisor.emisorId == row.emisorId);
			row.emisorAsociado = empresaAsociada;
		}
	}

	setTipoDteToElement(row: IDte) {
		if (row.tipoDteId) {
			let tipoDteAsociado = this.tipoDteSource.find((tipo) => tipo.tipoDteId == row.tipoDteId);
			row.nombreTipoDte = tipoDteAsociado.abreviacion;
		}
	}

	starterPage() {
		this.page.pageNumber = 0;
		this.page.pageSize = 10;
		this.selectedAllItemsInPage[this.page.pageNumber] = false;
	}
	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	searchElementsByFilter(event) {
		//se toman los valores del filtro que necesite el endpoint de filtro para dte
		this.filter = this.filterConcordance(this.configLocal.barraBusqueda.criteriosKeys, this.filter, event);
		this.filter.tipoDteId = event.tipoDteId != null ? event.tipoDteId : "";
		this.filter.fechaDesde = event.fechaInicio;
		this.filter.fechaHasta = event.fechaFin;
		this.filter.importado = event.importado ? 1 : 0;
		this.filter.reutilizado = event.reutilizado ? 1 : 0;
		this.filter.estadoAcuse = event.estadoAcuseEmitido;
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.estadoSii = event.estadoDte;
		this.filter.sucursalId = event.sucursalId ? event.sucursalId : this.sucursalId;
		this.filter.emisorId = event.emisorId ? event.emisorId : this.emisorId;
		this.filter.nulos = event.nulos == null ? null : event.nulos;
		this.setPage({ offset: 0 });
	}

	filterConcordance(configCriterias, dteFilter: DteFilter, eventFilter): DteFilter {
		//vaciar del dteFilter aquellos campos distinto al presnetado en el eventFilter actual, segun el arreglo configCriterias
		configCriterias.forEach((criteria) => {
			if (criteria != eventFilter.criterio) {
				dteFilter[criteria] = "";
			}
		});
		return dteFilter;
	}

	setPage(pageInfo) {
		//console.log(pageInfo)
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		//console.log(this.page);
		if (this.fileIdValue && this.filter.importacionMasivaId) {
			//llamada con fileIdValue al endpoint de lista paginada
			this.dteService
				.getDataByPage(this.filter, localPage)
				.subscribe(
					(pagedData) => {
						let elements = pagedData.data as DteEmitido[];
						this.ref.detectChanges();
						elements.forEach((row) => {
							this.setEmisorToElement(row);
							this.setTipoDteToElement(row);
						});
						this.rowsMantenedor = [...elements];
						this.ref.detectChanges();
						this.setPagefromResponse(this.page, pagedData);
						this.loading = false;
						this.componentStarted = true;
						this.ref.detectChanges();
					},
					(error) => {
						this.currentSwal.icon = "error";
						this.currentSwal.title = "Error en la solicitud";
						this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
						this.currentSwal.fire();
					},
					() => {
						this.loading = false;
						this.ref.detectChanges();
					}
				)
				.add(() => {
					this.loading = false;
					this.ref.detectChanges();
				});
		} else {
			//llamada generica a los dte paginados
			this.dteService
				.getDataByPage(this.filter, localPage)
				.subscribe(
					(pagedData) => {
						console.log("paged data", pagedData);
						let elements = pagedData.data as DteEmitido[];
						this.ref.detectChanges();
						elements.forEach((row) => {
							this.setEmisorToElement(row);
							this.setTipoDteToElement(row);
						});
						this.rowsMantenedor = [...elements];
						this.componentStarted = true;
						this.ref.detectChanges();
						this.setPagefromResponse(this.page, pagedData);
					},
					(error) => {
						this.currentSwal.icon = "error";
						this.currentSwal.title = "Error en la solicitud";
						this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
						this.currentSwal.fire();
					},
					() => {
						this.loading = false;
						this.ref.detectChanges();
					}
				)
				.add(() => {
					this.loading = false;
					this.ref.detectChanges();
				});
		}
	}

	setPagefromResponse(currentPage: Page, data: PagedResponse<DteEmitido>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	downloadXml(dte: DteEmitido) {
		this.dteService.getXml(dte.dteId).subscribe(
			(result) => {
				this.loading = true;
				let b64Response = result.data.xml;
				const blobXml = this.utilities.convertB64ToBlob(b64Response, "text/xml");
				FileSaver.saveAs(blobXml, dte.folio + "_" + "dte.xml");
				this.loading = false;
			},
			(error) => {
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error en XML";
				this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	downloadPDF(dte: DteEmitido) {
		this.loading = true;
		if (dte.codigoTipoDte == 43) {
			this.dteService.getPdfLiquidacion(dte.dteId).subscribe(
				(result) => {
					this.responseToPdfDownload(result.data, dte);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error al descargar pdf";
					this.currentSwal.text = "No se pudo generar el documento, intente mas tarde." + this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				}
			);
		} else {
			this.dteService.getPdf(dte.dteId).subscribe(
				(result) => {
					this.responseToPdfDownload(result.data, dte);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error al descargar pdf";
					this.currentSwal.text = "No se pudo generar el documento, intente mas tarde." + this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				}
			);
		}
	}
	downloadPDFCedible(dte: DteEmitido, cedible: boolean) {
		this.loading = true;
		this.dteService.getPdfCedible(dte.dteId, cedible).subscribe(
			(result) => {
				this.responseToPdfDownload(result.data, dte);
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al descargar pdf cedible";
				this.currentSwal.text = "No se pudo generar el documento, intente mas tarde." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			}
		);
	}
	multipleDocumentDownload(fileType) {
		//"pdf" | "xml"
		if (this.selected.length > 0) {
			let pdfSubscriptions: Observable<Response<any>>[] = [];
			this.selected.forEach((dte) => {
				if (fileType == "pdf") {
					pdfSubscriptions.push(this.getDownloadPdfObservable(dte.dteId, dte.codigoTipoDte));
				} else if (fileType == "xml") {
					pdfSubscriptions.push(this.getDownloadXmlObservable(dte.dteId));
				}
			});
			let groupedSubscription = zip(...pdfSubscriptions);
			this.loading = true;
			groupedSubscription
				.subscribe(
					(resultArray) => {
						let zipToDownload = new JSZip();
						resultArray.forEach((response, i) => {
							if (fileType == "pdf") {
								zipToDownload.file(this.selected[i].folio + "_" + this.selected[i].razonSocial + "_dte_" + this.selected[i].codigoTipoDte + "." + fileType, response.data, { base64: true });
							} else if (fileType == "xml") {
								zipToDownload.file(this.selected[i].folio + "_" + this.selected[i].razonSocial + "_dte_" + this.selected[i].codigoTipoDte + "." + fileType, response.data.xml, { base64: true });
							}
						});
						let dateOfDownLoad = this.datePipe.transform(Date.now(), "dd/MM/yyyy");
						zipToDownload.generateAsync({ type: "blob" }).then(function (content) {
							FileSaver.saveAs(content, "documentos_" + dateOfDownLoad + ".zip");
						});
						this.currentSwal.icon = "success";
						this.currentSwal.title = "Descarga de documentos";
						this.currentSwal.text = "Los documentos seleccionados se han descargado";
						this.currentSwal.fire();
					},
					(err) => {
						this.currentSwal.icon = "error";
						this.currentSwal.title = "Error en exportación";
						this.currentSwal.text = "No ha sido posible descargar los documentos seleccionados.\nFavor intentar mas tar de o contactar a su administrador.";
						if (err.error.errors) {
							this.currentSwal.text += this.utilities.setErrorMessageFromArray(err.error);
						}
						this.currentSwal.fire();
					},
					() => {
						this.loading = false;
					}
				)
				.add(() => {
					this.loading = false;
					this.ref.detectChanges();
				});
		} else {
			//show error message for not selecting elements
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error de Selección";
			this.currentSwal.text = "No se ha seleccionado ningún documento para envío.\nFavor seleccionar un documento con la columna selectora.";
			this.currentSwal.fire();
		}
	}

	getDocumentSubscription(dteId: string, docType: string): Subscription {
		switch (docType) {
			case "pdf":
				return this.dteService.getPdf(dteId).subscribe();
				break;
			case "pdf":
				break;
			case "both":
				break;
			default:
				break;
		}
	}
	getDownloadXmlObservable(id: string): Observable<Response<any>> {
		return this.dteService.getXml(id);
	}
	getDownloadPdfObservable(id: string, tipoDte: number): Observable<Response<any>> {
		if (tipoDte == 43) return this.dteService.getPdfLiquidacion(id);
		else return this.dteService.getPdf(id);
	}

	responseToPdfDownload(responseB64, currentDte: DteEmitido) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "application/pdf");
		FileSaver.saveAs(pdfBlob, currentDte.folio + "_dte.pdf");
		this.loading = false;
		this.ref.detectChanges();
	}
	convertStreamToFileDownload(responseB64, currentDte: DteEmitido, fileType: string, extensionFile: string) {
		const docBlob = this.utilities.convertB64ToBlob(responseB64, fileType);
		FileSaver.saveAs(docBlob, currentDte.folio + "_" + currentDte.razonSocial + "_dte." + extensionFile);
	}

	openDocumentSenderModal(modalRef, row?: DteEmitido) {
		if (row) {
			this.selectedItem = row;
		} else if (this.selected.length > 0) {
			this.selectedItem = undefined;
		} else {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error de Selección";
			this.currentSwal.text = "No se ha seleccionado ningún documento para envío.\nFavor seleccionar un documento con la columna selectora o usar la emisión individual desde el icono de 'Enviar Correo'";
			this.currentSwal.fire();
			return;
		}

		this.senderDteModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});

		this.senderDteModal.closed.subscribe((res) => {
			this.senderForm.reset();
		});
		this.senderDteModal.dismissed.subscribe((res) => {
			this.senderForm.reset();
		});
	}

	createSenderForm() {
		this.senderForm = this.formBuilder.group(
			{
				receiverEmail: [
					"",
					[
						Validators.required,
						//Validators.pattern(this.formConfig.emailPatternRegex),
						MultipleEmailValidator,
						Validators.maxLength(320),
					],
				],
				ccEmail: [
					"",
					[
						//Validators.pattern(this.formConfig.emailPatternRegex),
						MultipleEmailValidator,
						Validators.maxLength(320),
					],
				],
				ccoEmail: [
					"",
					[
						//Validators.email,
						//Validators.pattern(this.formConfig.emailPatternRegex),
						MultipleEmailValidator,
						Validators.maxLength(320),
					],
				],
				sendPdfCheckbox: [true],
				sendXmlCheckbox: [true],
			},
			{ validators: SameWordEmailValidator }
		);
	}

	onSubmitSenderForm() {
		if (this.senderForm.valid) {
			let emailReceivers: string[] = this.senderForm.controls.receiverEmail.value.toString().split(",");
			let emailsCCs: string[] = this.senderForm.controls.ccEmail.value ? this.senderForm.controls.ccEmail.value.toString().split(",") : [];
			let emailCCOs: string[] = this.senderForm.controls.ccoEmail.value ? this.senderForm.controls.ccoEmail.value.toString().split(",") : [];
			var mailRequest = new SendInvoiceRequest();
			mailRequest.ccos = emailCCOs;
			mailRequest.ccs = emailsCCs;
			mailRequest.dteId = this.selectedItem.dteId;
			mailRequest.dteType = this.selectedItem.codigoTipoDte;
			mailRequest.to = emailReceivers;
			mailRequest.emailType = 3;
			mailRequest.pdf = this.senderForm.controls.sendPdfCheckbox.value ? true : false;
			mailRequest.xml = this.senderForm.controls.sendXmlCheckbox.value ? true : false;
			mailRequest.IssuingId = this.selectedItem.emisorId;
			if (mailRequest.pdf || mailRequest.xml) {
				if (!this.selectedItem && this.selected.length == 1) {
					this.sendDteDocuments(mailRequest);
				} else if (this.selectedItem) {
					this.sendDteDocuments(mailRequest);
				} else if (this.selected.length != 1) {
					//opcion temporal para solicitar solo un elemento
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error de Selección";
					this.currentSwal.text = "Solo puede seleccionar un elemento para el envío.\nFavor seleccionar un documento con la columna selectora o usar la emisión individual desde el icono de 'Enviar Correo'";
					this.currentSwal.fire();
					return;
				}
			} else {
				Swal.fire({
					title: "Documentos No Seleccionados",
					text: "Favor indicar por lo menos un documento a enviar",
					icon: "info",
				});
				return;
			}
		}
	}

	onSubmitEmail() {
		// email request to backend handling
		//valid form data
		if (this.senderForm.valid) {
			//get dtes id array
			let dteIdList: Array<string> = [];
			if (this.selectedItem) {
				dteIdList.push(this.selectedItem.dteId);
			} else if (this.selected.length > 0) {
				this.selected.forEach((dte) => {
					dteIdList.push(dte.dteId);
				});
			}
			//attach xml pdf or both seing boolean in request
			let sendingPdf: boolean = this.senderForm.controls.sendPdfCheckbox.value;
			let sendingXml: boolean = this.senderForm.controls.sendXmlCheckbox.value;
			//attach array of email addresses
			let arrayReceiversEmails: string[] = this.senderForm.controls.receiverEmail.value.toString().split(",");
			let arrayCopyEmails: string[] = this.senderForm.controls.ccEmail.value.toString().split(",");
			let arrayBlindCopyEmails: string[] = this.senderForm.controls.ccoEmail.value.toString().split(",");
		}
	}

	// setDataAttachmentToRequest(
	//   req: SendInvoiceRequest,
	//   dteToSend: DteEmitido,
	//   fileString: string,
	//   extensionString: string
	// ) {
	//   let docDate = this.datePipe.transform(dteToSend.fecha, "dd/MM/yyyy");
	//   let docName =
	//     dteToSend.folio +
	//     "_" +
	//     dteToSend.rutReceptor +
	//     "_" +
	//     docDate +
	//     "_" +
	//     dteToSend.razonSocial +
	//     extensionString;
	//   let nombreCompletoTipoDte:TipoDte = this.tipoDteSource.find(tipo => tipo.tipoDteId == dteToSend.tipoDteId);
	//   req.data = new EmailData(docName, docDate,dteToSend.razonSocial,nombreCompletoTipoDte.nombre,dteToSend.folio.toString(),dteToSend.rutReceptorDv,dteToSend.total);
	//   req.attachments = [new EmailAttachment(docName, fileString)];
	// }

	sendDteDocuments(request: SendInvoiceRequest) {
		this.loading = true;
		this.ref.detectChanges();
		this.correoEnvioService.sendEmail(request).subscribe(
			(result) => {
				let resumenEnvioCorreo: string = "";
				resumenEnvioCorreo = "Se envió el documento a las siguientes direcciones:<br>" + request.to + "<br>";
				if (request.ccs[0] != "") {
					if (request.ccs.length > 0) {
						resumenEnvioCorreo += "<br> Con copia a las siguientes direcciones:<br>" + request.ccs + "<br>";
					}
				}
				if (request.ccos[0] != "") {
					if (request.ccos.length > 0) {
						resumenEnvioCorreo += "<br> Con copia oculta para las siguientes direcciones:<br>" + request.ccos;
					}
				}
				Swal.fire({
					icon: "success",
					title: "Envío exitoso",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + resumenEnvioCorreo + "</p></div>",
				});
				this.senderDteModal.close();
				this.senderForm.reset();
				this.senderForm.controls.ccEmail.patchValue("");
				this.senderForm.controls.ccoEmail.patchValue("");
				this.senderForm.controls.receiverEmail.patchValue("");
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error en el envío";
				this.currentSwal.text = "No fue posible enviar el documento." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	async getPdfForEmailRequest(dteId: string, pdfStringRef: string, tipoDte: number) {
		var pdfStringRef = "";
		if (tipoDte == 43) {
			pdfStringRef = await (await this.dteService.getPdfLiquidacion(dteId).toPromise()).data;
		} else {
			pdfStringRef = await (await this.dteService.getPdf(dteId).toPromise()).data;
		}

		return pdfStringRef;
	}

	getXml(dte: DteEmitido) {
		this.dteService.getXml(dte.dteId).subscribe(
			(result) => {
				this.loading = true;
				let b64Response = result.data.xml;
				const blobXml = this.utilities.convertB64ToBlob(b64Response, "text/xml");
				let blob = new Blob([blobXml], { type: "text/xml" });
				let fileURL = URL.createObjectURL(blobXml);
				this.popupCenter(fileURL, "Documentos Tributario Electrónico", 600, 800);
				this.loading = false;
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error en XML";
				this.currentSwal.text = "No fue posible obtener el documento." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			},
			() => {
				this.loading = false;
			}
		);
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const newWindow = window.open(
			url,
			title,
			`
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}

	//trazabilidad
	openTraceShowModal(modalRef, dte: DteEmitido) {
		this.dteToShow = dte;
		this.loading = true;
		this.dteService
			.getTrace(this.dteToShow.dteId)
			.subscribe(
				(result) => {
					this.tracesToShow = result.data as Traza[];
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en Trazabilidad";
					this.currentSwal.text = "No es posible obtener la trazabilidad de este documento. Intente mas tarde. ";
					this.currentSwal.text += this.utilities.setErrorMessageFromArray(error.error);

					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
				}
			)
			.add(() => {
				this.loading = false;
				this.tracesModal = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			});
	}

	openAnularDteModal(modalRef, dte: DteEmitido) {
		this.dteToNullify = dte;
		this.loading = true;
		this.dteService
			.getDetalleDte(dte.dteId)
			.subscribe(
				(result) => {
					this.ref.detectChanges();
					this.dteToNullify.detalleDte = result.data as DetalleDte[];
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.notesSwal.icon = "error";
					this.notesSwal.title = "Error al Obtener Detalles";
					this.notesSwal.text = "No es posible obtener los detalles del documento a anular";
					this.notesSwal.fire();
				},
				() => {}
			)
			.add(() => {
				this.dteService
					.getReferencias(dte.dteId)
					.subscribe(
						(result) => {
							let dteReferences = result.data.map((ref) => {
								return ref.dte;
							});
							dteReferences.forEach((row) => {
								this.setEmisorToElement(row);
								this.setTipoDteToElement(row);
							});
							this.dteToNullifyReferences = dteReferences;
						},
						(error) => {
							this.loading = false;
							this.ref.detectChanges();
							this.notesSwal.icon = "error";
							this.notesSwal.title = "Error al Obtener Referencias";
							this.notesSwal.text = "No es posible obtener referencias del documento a anular." + this.utilities.setErrorMessageFromArray(error.error);
							this.notesSwal.fire();
						}
					)
					.add(() => {
						this.dteService.getReceptorDte(dte.dteId).subscribe(
							(result) => {
								this.loading = false;
								this.ref.detectChanges();
								this.dteToNullify.receptorAsociado = result.data;
								this.anuladorModal = this.modalService.open(modalRef, {
									windowClass: "",
									size: "lg",
								});
							},
							(error) => {
								this.loading = false;
								this.ref.detectChanges();
								this.notesSwal.icon = "error";
								this.notesSwal.title = "Error al Obtener Receptor";
								this.notesSwal.text = "No es posible obtener los detalles del receptor del documento a anular." + this.utilities.setErrorMessageFromArray(error.error);
								this.notesSwal.fire();
							}
						);
					});
			});
	}

	closeAnularDteModal() {
		this.anuladorModal.close();
	}

	getTraza(dte: DteEmitido) {}

	//EXCEL EXPORT METHODS
	//Los metodos son DERCARGAR TODO, DESCARGAR LO FILTRADO(filtro aplicado en una sola hoja), DESCARGAR SELECCION
	exportToExcel() {
		if (this.checkCleanFilter() && this.page.totalElements > 25) {
			Swal.fire({
				icon: "warning",
				title: "Exportación completa",
				text: "Los datos no están filtrados, por lo que se exportarán " + this.page.totalElements + " registros pudiendo tomar más tiempo de lo normal. ¿Desea continuar?",
				confirmButtonText: "Continuar",
				confirmButtonColor: "#1BC5BD",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				cancelButtonColor: "#F64E60",
			}).then((result) => {
				if (result.isConfirmed) {
					this.loading = true;
					this.getAllRowsByFilter(this.page, this.filter);
				} else {
					return;
				}
			});
		} else {
			this.loading = true;
			this.getAllRowsByFilter(this.page, this.filter);
		}
	}

	getAllRowsByFilter(currentPageRef: Page, currentFilter: DteFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.dteService.getDataByPage(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as DteEmitido[];
				elements.forEach((row) => {
					this.setEmisorToElement(row);
					this.setTipoDteToElement(row);
				});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al exportar";
				this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. ";
				this.currentSwal.text += this.utilities.setErrorMessageFromArray(error.error);
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<DteEmitido>): void {
		let arregloExportar = [];
		arrayToExport.forEach((row) => {
			let elementToExport = {};
			elementToExport["Tipo DTE"] = row.nombreTipoDte;
			elementToExport["Folio"] = row.folio;
			elementToExport["Emisor"] = row.emisorAsociado.razonSocial;
			elementToExport["Rut Cliente"] = row.rutReceptorDv;
			elementToExport["Razon Social Cliente"] = row.razonSocial;
			elementToExport["Fecha de Emisión"] = this.datePipe.transform(row.fecha, "dd/MM/yyyy");
			// if (row.nombreTipoDte === "Nota de Crédito Electrónica") {
			//   elementToExport["Monto"] = Number(row.total * -1);
			// } else {
			//   elementToExport["Monto"] = row.total;
			// }
			elementToExport["Neto"] = row.neto;
			elementToExport["Iva"] = row.iva;
			elementToExport["Exento"] = row.exento;
			elementToExport["Impuesto"] = row.impuestos;
			elementToExport["Total"] = row.total;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Dte_emitidos");
	}

	commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
		const emails = control.value.split(",").map((e) => e.trim());
		const forbidden = emails.some((email) => this.formConfig.emailPatternRegex.test(email));
		return forbidden ? { toAddress: { value: control.value } } : null;
	};

	newCreditNote(data: NotaCredito) {
		var type = "";
		let notaCreditoDte = new DteEmitido();
		notaCreditoDte.nombreTipoDte = "Nota de Crédito Electrónica";
		notaCreditoDte.folio = 1000;
		notaCreditoDte.emisorAsociado = data.dteReferencia.emisorAsociado;
		notaCreditoDte.rutReceptorDv = data.dteReferencia.rutReceptorDv;
		notaCreditoDte.razonSocial = data.dteReferencia.razonSocial;
		notaCreditoDte.fecha = new Date();
		if (data.tipoNota == 0) {
			notaCreditoDte.total = data.dteReferencia.total;
			type = "Anulación";
		} else if (data.tipoNota == 2) {
			notaCreditoDte.total = 0;
			type = "Corrección de Texto";
		} else {
			notaCreditoDte.total = data.total;
			type = "Corrección de Monto";
		}
		this.loading = true;
		if (data.dteReferencia.codigoTipoDte == 110) {
			this.dteService.creditNoteDteExportNullification(data).subscribe(
				(result) => {
					let dteResult = result.data as DteEmitido;
					notaCreditoDte.dteId = dteResult.dteId;
					notaCreditoDte.folio = dteResult.folio;
					notaCreditoDte.estadoSii = dteResult.estadoSii;
					notaCreditoDte.nombreTipoDte = "NC.EX.E";
					notaCreditoDte.codigoTipoDte = 112;
					notaCreditoDte.anulado = false;
					notaCreditoDte.notaCreditoHabilitada = false;
					notaCreditoDte.notaDebitoHabilitada = true;
					notaCreditoDte.rutReceptor = data.dteReferencia.rutReceptor;
					notaCreditoDte.rutReceptorDv = data.dteReferencia.rutReceptorDv;
					notaCreditoDte.neto = data.tipoNota == 0 ? data.dteReferencia.neto : data.neto;
					notaCreditoDte.iva = data.tipoNota == 0 ? data.dteReferencia.iva : data.iva;
					notaCreditoDte.exento = data.dteReferencia.exento;
					//this.setTipoDteToElement(notaCreditoDte);
					// this.rowsMantenedor.unshift(notaCreditoDte);
					// this.rowsMantenedor = [...this.rowsMantenedor];
					this.setPage({ offset: 0 });
					this.loading = false;
					this.ref.detectChanges();
					this.notesSwal.icon = "success";
					this.notesSwal.title = "Documento Generado";
					this.notesSwal.text = "Se ha generado el documento correctamente";
					this.notesSwal.fire();
					if (data.tipoNota == 0) {
						this.updateConditionOfDte(data.dteReferenciaId, "anulado", true);
					}
					this.anuladorModal.close();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.notesSwal.icon = "error";
					this.notesSwal.title = "Error en Nota de Credito de tipo  " + type;
					this.notesSwal.text = "Ha ocurrido un error al intentar generar el documento.\n";
					if (error.error.errors) {
						error.error.errors.forEach((errorMsg) => {
							this.notesSwal.text += "\n" + errorMsg + "\n";
						});
					}
					this.notesSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			);
		} else {
			this.dteService.creditNoteNullification(data).subscribe(
				(result) => {
					let dteResult = result.data as DteEmitido;
					notaCreditoDte.dteId = dteResult.dteId;
					notaCreditoDte.folio = dteResult.folio;
					notaCreditoDte.estadoSii = dteResult.estadoSii;
					notaCreditoDte.nombreTipoDte = "N.C.E";
					notaCreditoDte.codigoTipoDte = 61;
					notaCreditoDte.anulado = false;
					notaCreditoDte.notaCreditoHabilitada = false;
					notaCreditoDte.notaDebitoHabilitada = true;
					notaCreditoDte.rutReceptor = data.dteReferencia.rutReceptor;
					notaCreditoDte.rutReceptorDv = data.dteReferencia.rutReceptorDv;
					notaCreditoDte.neto = data.tipoNota == 0 ? data.dteReferencia.neto : data.neto;
					notaCreditoDte.iva = data.tipoNota == 0 ? data.dteReferencia.iva : data.iva;
					notaCreditoDte.exento = data.dteReferencia.exento;
					//this.setTipoDteToElement(notaCreditoDte);
					// this.rowsMantenedor.unshift(notaCreditoDte);
					// this.rowsMantenedor = [...this.rowsMantenedor];
					this.loading = false;
					this.ref.detectChanges();
					this.setPage({ offset: 0 });
					this.notesSwal.icon = "success";
					this.notesSwal.title = "Documento Generado";
					this.notesSwal.text = "Se ha generado el documento correctamente";
					this.notesSwal.fire();
					if (data.tipoNota == 0) {
						this.updateConditionOfDte(data.dteReferenciaId, "anulado", true);
					}
					this.anuladorModal.close();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.notesSwal.icon = "error";
					this.notesSwal.title = "Error en Nota de Credito de tipo " + type;
					this.notesSwal.text = "Ha ocurrido un error al intentar generar el documento.\n";
					if (error.error.errors) {
						error.error.errors.forEach((errorMsg) => {
							this.notesSwal.text += "\n" + errorMsg + "\n";
						});
					}
					this.notesSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			);
		}
	}
	newDebitNoteNullification(data: NotaDebito) {
		let dte = new DteEmitido();
		dte.nombreTipoDte = "Nota de Debito Electrónica";
		dte.folio = 1000;
		dte.emisorAsociado = data.dteReferencia.emisorAsociado;
		dte.rutReceptorDv = data.dteReferencia.rutReceptorDv;
		dte.razonSocial = data.dteReferencia.razonSocial;
		dte.fecha = new Date();
		if (data.tipoNota == 0) {
			dte.total = data.dteReferencia.total;
		} else if (data.tipoNota == 2) {
			dte.total = 0;
		} else {
			dte.total = data.total;
		}
		this.loading = true;
		if (data.dteReferencia.codigoTipoDte == 112) {
			this.dteService.debitNoteDteExportNullification(data).subscribe(
				(result) => {
					let dteResult = result.data as DteEmitido;
					dte.dteId = dteResult.dteId;
					dte.folio = dteResult.folio;
					dte.estadoSii = dteResult.estadoSii;
					dte.nombreTipoDte = "ND.EX.E";
					dte.codigoTipoDte = 111;
					dte.anulado = false;
					dte.notaCreditoHabilitada = false;
					dte.notaDebitoHabilitada = false;
					//this.setTipoDteToElement(dte);
					// this.rowsMantenedor.unshift(dte);
					// this.rowsMantenedor = [...this.rowsMantenedor];
					this.loading = false;
					this.ref.detectChanges();
					this.setPage({ offset: 0 });
					this.notesSwal.icon = "success";
					this.notesSwal.title = "Documento Generado";
					this.notesSwal.text = "Se ha generado el documento correctamente";
					this.notesSwal.fire();
					if (data.tipoNota == 0) {
						this.updateConditionOfDte(data.dteReferenciaId, "anulado", true);
					}
					this.anuladorModal.close();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.notesSwal.icon = "error";
					this.notesSwal.title = "Error en anulación";
					this.notesSwal.text = "Ha ocurrido un error al intentar generar el documento.\n";
					if (error.error.errors) {
						error.error.errors.forEach((errorMsg) => {
							this.notesSwal.text += "\n" + errorMsg + "\n";
						});
					}
					this.notesSwal.fire();
				}
			);
		} else {
			this.dteService.debitNoteNullification(data).subscribe(
				(result) => {
					let dteResult = result.data as DteEmitido;
					dte.dteId = dteResult.dteId;
					dte.folio = dteResult.folio;
					dte.estadoSii = dteResult.estadoSii;
					dte.nombreTipoDte = "N.D.E";
					dte.codigoTipoDte = 56;
					dte.anulado = false;
					dte.notaCreditoHabilitada = false;
					dte.notaDebitoHabilitada = false;
					//this.setTipoDteToElement(dte);
					// this.rowsMantenedor.unshift(dte);
					// this.rowsMantenedor = [...this.rowsMantenedor];
					this.loading = false;
					this.ref.detectChanges();
					this.setPage({ offset: 0 });
					this.notesSwal.icon = "success";
					this.notesSwal.title = "Documento Generado";
					this.notesSwal.text = "Se ha generado el documento correctamente";
					this.notesSwal.fire();
					if (data.tipoNota == 0) {
						this.updateConditionOfDte(data.dteReferenciaId, "anulado", true);
					}
					this.anuladorModal.close();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					this.notesSwal.icon = "error";
					this.notesSwal.title = "Error en anulación";
					this.notesSwal.text = "Ha ocurrido un error al intentar generar el documento.\n";
					if (error.error.errors) {
						error.error.errors.forEach((errorMsg) => {
							this.notesSwal.text += "\n" + errorMsg + "\n";
						});
					}
					this.notesSwal.fire();
				}
			);
		}
	}
	newDebitNoteInterest(data: NotaDebito) {
		let dte = new DteEmitido();
		dte.nombreTipoDte = "Nota de Crédito Electrónica";
		dte.folio = 1000;
		dte.emisorAsociado = data.dteReferencia.emisorAsociado;
		dte.rutReceptorDv = data.dteReferencia.rutReceptorDv;
		dte.razonSocial = data.dteReferencia.razonSocial;
		dte.fecha = new Date();
		if (data.tipoNota == 0) {
			dte.total = data.dteReferencia.total;
		} else if (data.tipoNota == 2) {
			dte.total = 0;
		} else {
			dte.total = data.total;
		}
		this.loading = true;
		this.dteService.debitNoteNullificationInterests(data).subscribe(
			(result) => {
				let dteResult = result.data as DteEmitido;
				dte.dteId = dteResult.dteId;
				dte.folio = dteResult.folio;
				dte.estadoSii = dteResult.estadoSii;
				dte.nombreTipoDte = "N.D.E";
				dte.codigoTipoDte = 56;
				dte.anulado = false;
				dte.notaCreditoHabilitada = false;
				dte.notaDebitoHabilitada = false;
				//this.setTipoDteToElement(dte);
				// this.rowsMantenedor.unshift(dte);
				// this.rowsMantenedor = [...this.rowsMantenedor];
				this.loading = false;
				this.ref.detectChanges();
				this.setPage({ offset: 0 });
				this.notesSwal.icon = "success";
				this.notesSwal.title = "Documento Generado";
				this.notesSwal.text = "Se ha generado el documento correctamente";
				this.notesSwal.fire();
				if (data.tipoNota == 0) {
					this.updateConditionOfDte(data.dteReferenciaId, "anulado", true);
				}
				this.anuladorModal.close();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.notesSwal.icon = "error";
				this.notesSwal.title = "Error en anulación";
				this.notesSwal.text = "Ha ocurrido un error al intentar generar el documento.\n";
				if (error.error.errors) {
					error.error.errors.forEach((errorMsg) => {
						this.notesSwal.text += "\n" + errorMsg + "\n";
					});
				}
				this.notesSwal.fire();
			}
		);
	}

	getCellClass({ row, column, value }): any {
		return row.codigoTipoDte === 61 ? "is-monto-nota-credito number-table-alignment" : "number-table-alignment";
	}

	updateConditionOfDte(dteId: string, dteProperty: string, propertyValue: any) {
		let currentDte = this.rowsMantenedor.find((dte) => dte.dteId == dteId);
		if (currentDte) {
			currentDte[dteProperty] = propertyValue;
		}
	}

	setSwalAndFire(swalReference: SwalComponent, iconString: SweetAlertIcon, titleString: string, textString: string) {
		swalReference.icon = iconString;
		swalReference.title = titleString;
		swalReference.text = textString;
		swalReference.fire();
	}

	navigateToFacturador(type: InvoiceType) {
		this.router.navigateByUrl("facturador-individual", {
			state: { invoiceType: type },
		});
	}

	openCesionDte(modalRef, row) {
		this.selectedItem = row;
		this.loading = true;
		this.personasAutorizadasService.getAuthorizedPersonsByEmisor(row.emisorId).subscribe(
			(result) => {
				if (result.data.length > 0) {
					this.loading = false;
					row.emisorAsociado.personasAutorizadas = result.data;
					this.ref.detectChanges();
					this.cesionDteModal = this.modalService.open(modalRef, {
						windowClass: "",
						size: "lg",
					});
				} else {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						title: "Error en Personas Autorizadas",
						text: "El emisor actual no posee personas autorizadas, favor ingresar por lo menos una persona autorizada para realizar la Cesión del documento.",
						icon: "error",
					});
				}
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					title: "Error en Personas Autorizadas",
					text: "No se pudo obtener la lista de personas  autirizadas, favor intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
					icon: "error",
				});
			}
		);
	}

	sortDate(propA, propB, rowA, rowB) {
		if (new Date(propA))
			if (propA instanceof Date && propB instanceof Date) {
				if (propA < propB) return -1;
				if (propA > propB) return 1;
			}
	}

	getNullifyTypeName(event) {
		this.nombreTipoNota = event;
		this.ref.detectChanges();
	}

	resendDte(dte: DteEmitido) {
		this.loading = true;
		var request = new ActualizarEstadoSiiRequest();
		request.Dtes = [dte.dteId];
		request.usuarioId = this.tokenService.getUser().id;
		request.emisorId = this.emisorId;
		this.dteService.resendDte(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Estado Sii Actualizado",
					text: "El documento volverá a ser enviado al servicio de impuestos internos",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Actualizar Estado Sii",
					text: "No fue posible actualizar el estado sii del documento. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	resendEmailSii(dte: DteEmitido) {
		this.loading = true;
		var request = new ReenvioEmailRequest();
		request.dteId = dte.dteId;
		request.usuarioId = this.tokenService.getUser().id;
		request.emisorId = this.emisorId;
		this.dteService.resendEmailSii(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Reenvio de Email Validación Sii",
					text: "La validación del documento desde el sii fue reenviada al email registrado en el servicio de impuestos internos",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al solicitar reenvio de correo de validación",
					text: "No fue posible solicitar el reenvio del correo de validación desde el sii. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	openDatePickerImportedDte(modalRef, month: boolean) {
		if (month) this.locale.format = "MM/YYYY";
		else this.locale.format = "DD/MM/YYYY";
		//this.dateConciliacion = null;
		this.dateConciliacion.startDate = moment().startOf("day");
		this.dateConciliacion.endDate = moment().subtract(1, "days").startOf("day");
		this.modalImportedDteSii = this.modalService.open(modalRef, {
			windowClass: "",
			size: "md",
			centered: true,
		});
	}
	importDteSii(conciliacionRequest: NgForm) {
		if (conciliacionRequest.valid && this.validDate) {
			Swal.fire({
				title: "¿Desea importar dtes desde SII?",
				text: "Los dtes importados no tendrán opción de descargar archivos",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Si, importar",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.loading = true;
					let conciliacion = new Conciliacion();
					conciliacion.emisorId = this.emisorId;
					conciliacion.usuarioId = this.usuarioId;
					conciliacion.sucursalId = this.sucursalId;
					conciliacion.dateString = moment(this.dateConciliacion.startDate).format("L");
					conciliacion.mensual = this.locale.format == "MM/YYYY" ? true : false;
					this.dteService.importDteSii(conciliacion).subscribe(
						(res) => {
							//console.log(res);
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "success",
								title: "Importar Dtes desde Sii",
								showCloseButton: true,
								html: "<div style='text-align:center;'><p>" + res.message + "</p></div>",
							});
							this.modalImportedDteSii.close();
							this.setPage({ offset: 0 });
						},
						(err) => {
							//console.log(err);
							this.loading = false;
							this.ref.detectChanges();
							this.currentSwal.icon = "error";
							this.currentSwal.title = "Error al Importar Dtes desde Sii";
							this.currentSwal.text = "No es posible realizar la importación de dtes desde el sii. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(err.error);
							this.currentSwal.fire();
						}
					);
				}
			});
		}
	}
	detectChangeDate(event) {
		this.validDate = true;
		let fromDate: any;
		if (event.startDate != null) {
			this.dateConciliacion = event;
		} else {
			if (event.target != null && event.target.value != null) {
				this.validDate = moment(event.target.value, this.locale.format, true).isValid();
				if (this.validDate) {
					fromDate = new Object();
					fromDate.startDate = moment(event.target.value, this.locale.format);
					this.dateConciliacion = fromDate;
				}
				//console.log("Fecha a conciliar",this.dateConciliacion);
			}
		}
	}

	checkCleanFilter() {
		return this.filter.tipoDteId == null || this.filter.fechaDesde === null || this.filter.fechaHasta == null || this.filter.reutilizado == null || this.filter.importado == null || this.filter["razonSocialReceptor"] == null || this.filter["rutReceptor"] == null || this.filter["folio"] == null || this.filter.estadoSii == null;
	}

	reuse(dte: DteEmitido) {
		this.loading = true;
		this.dteService.reuse(dte.dteId).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Reutilización de Folio",
					text: res.message,
				});
				this.setPage({ offset: 0 });
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Solicitar Reutilización de Folio",
					text: "No fue posible solicitar la reutilización de folio. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	tryMassiveRouseConfirm() {
		if (this.selected.length > 0) {
			var valid = false;
			var ids: string[] = [];
			var invalidList = this.selected.filter((x) => x.estadoSii == 6 || x.estadoSii == 7);
			if (invalidList.length > 0) {
				Swal.fire({
					icon: "error",
					title: "Error de Selección",
					text: "Solo se pueden reutilizar folios de documentos que no estén aceptados por el sii",
				});
				return;
			}
			Swal.fire({
				icon: "info",
				title: "Reutilizar folios",
				text: "¿Está seguro de reutilizar los folios de los documentos seleccionados?",
				confirmButtonText: "Continuar",
				confirmButtonColor: "#1BC5BD",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				cancelButtonColor: "#F64E60",
			}).then((res) => {
				if (res.isConfirmed) {
					this.massiveReuse();
				} else {
					return;
				}
			});
		} else {
			Swal.fire({
				icon: "error",
				title: "Error de Selección",
				text: "No se ha seleccionado ningún documento válido para reutilizar.\nFavor seleccionar un documento con la columna selectora.",
			});
		}
	}
	massiveReuse() {
		var ids: string[] = [];
		this.selected.forEach((dte) => {
			ids.push(dte.dteId);
		});
		this.loading = true;
		var request = new ReutilizacionMasivaRequest();
		request.ids = ids;
		request.usuarioId = this.usuarioId;
		this.dteService.massivereuse(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Reutilización de Folios Masivo",
					text: res.message,
				});
				this.setPage({ offset: 0 });
				this.selected = [];
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Solicitar Reutilización de Folios Masivo",
					text: "No fue posible solicitar la reutilización de folios masivo. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	downloadMassiveFile(dte: DteEmitido) {
		var valid = dte.importacionMasivaId ? true : false;
		if (valid) {
			this.loading = true;
			this.facturadorService
				.getFile(dte.importacionMasivaId)
				.subscribe(
					(res) => {
						let uint8Array = res.data as Uint8Array;
						let b64Response = res.data;
						//obtained  8bit array from response that need to be transformed into a Blob
						try {
							const blobCsv = this.utilities.convertB64ToBlob(b64Response, "text/csv");
							FileSaver.saveAs(blobCsv, "lista_de_facturas.csv");
						} catch (error) {
							this.setSwalAndFire(this.currentSwal, "error", "Error en Archivo", "No fue posible obtener el archivo CSV, favor contactar al administrador\n" + error);
						}
					},
					(error) => {
						this.setSwalAndFire(this.currentSwal, "error", "Error", "Error al obtener el archivo. Intente más tarde." + this.utilities.setErrorMessageFromArray(error.error));
					}
				)
				.add(() => {
					this.loading = false;
					this.ref.detectChanges();
				});
		} else {
			Swal.fire({
				icon: "error",
				title: "Error al Obtener archivo",
				text: "El documento seleccionado no posee un archivo de importación masiva",
			});
		}
	}

	getEmisoresFullList() {
		this.emisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				this.empresasSource = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
}
