import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Impuesto } from "src/app/models/Impuesto";
import { TipoImpuesto } from "src/app/models/TipoImpuesto";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
   selector: "app-tax-entry",
   templateUrl: "./tax-entry.component.html",
   styleUrls: ["./tax-entry.component.scss"],
})
export class TaxEntryComponent implements OnInit {
   @Input() public detailMode: boolean = false;
   @Input() public editMode: boolean = false;
   @Input() public taxToEdit: Impuesto = undefined;
   @Output() public sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
   @Output() public sendTaxRequest: EventEmitter<any> = new EventEmitter<any>();
   public taxForm: FormGroup;
   private taxObj: Impuesto = new Impuesto();
   public taxTypeSource = JSON.parse(this.localStorage.getTipoImpuestos()) as TipoImpuesto[];
   // [
   //   {id:0,nombre:"Licores",valorMaximo:0.15,unidadMedida:"%",tipoCambio:'CLP'},
   //   {id:1,nombre:"Vinos",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:2,nombre:"Cervezas",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:3,nombre:"Bebidas Analcoholicas y Minerales",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:4,nombre:"Bebidas Analcoholicas y Minerales Alta Azucar",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:5,nombre:"Impuesto Art 37 E-L",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:6,nombre:"Impuesto Art 37 J",valorMaximo:0.5,unidadMedida:"%"}
   // ]
   constructor(private formBuilder: FormBuilder, private localStorage: LocalStorageService) {}

   ngOnInit(): void {
      this.setTaxForm();
      if (this.taxToEdit) {
         this.setTaxDataToForm(this.taxToEdit);
      }
      if (this.detailMode) {
         this.taxForm.get("tipo").disable();
      }
   }

   setTaxForm() {
      this.taxForm = this.formBuilder.group({
         nombre: [, Validators.required],
         valor: [, [Validators.required, Validators.min(1), Validators.max(99)]],
         tipo: [, Validators.required],
      });
   }

   onSubmit() {
      //console.log(this.taxForm)
      if (this.taxForm.valid) {
         this.getFormData();
         this.sendTaxRequest.emit(this.taxObj);
      }
   }

   sendCloseRequest() {
      this.sendCloseRequestEmitter.emit();
   }

   getFormData() {
      this.taxObj.nombre = this.taxForm.controls.nombre.value;
      this.taxObj.valor = this.taxForm.controls.valor.value;
      this.taxObj.tipoImpuesto = this.taxForm.controls.tipo.value.id;
      this.taxObj.impuestoId = this.taxToEdit ? this.taxToEdit.impuestoId : null;
      this.taxObj.isRetencion = this.taxToEdit ? this.taxToEdit.isRetencion : null;
      this.taxObj.activo = this.taxToEdit ? this.taxToEdit.activo : null;
   }

   setTaxDataToForm(receivedTax: Impuesto) {
      this.taxForm.patchValue(receivedTax);
      let type = this.taxTypeSource.find((type) => type.id == receivedTax.tipoImpuesto);

      //console.log(type,receivedTax);
      this.taxForm.get("tipo").patchValue(type);
   }
}
