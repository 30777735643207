import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Response } from "src/app/shared/models/Response";
import { Observable } from "rxjs";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Page } from "../shared/models/Page";
import { Usuario } from "../models/Usuario/Usuario";
import { UsuarioRequest } from "../models/Usuario/UsuarioRequest";
import { UsuarioFilter } from "../models/Usuario/UsuarioFilter";
import { SucursalFilter } from "../models/Usuario/SucursalFilter";
import { Permiso } from "../models/Perfil/Permiso";
import { Perfil } from "../models/Perfil/Perfil";
import { Sucursal } from "../models/Sucursal";

@Injectable({
	providedIn: "root",
})
export class UsuarioService {
	private REST_API_SERVER_USER = environment.backend_server + "/Usuario";
	private REST_API_SERVER_PROFILE = environment.backend_server + "/Perfil";
	private auth = {};
	constructor(private http: HttpClient) {}

	getFullList(): Observable<Response<Usuario[]>> {
		return this.http.get<Response<Usuario[]>>(this.REST_API_SERVER_USER + "/list");
	}

	getProfilesByUser(userId: string): Observable<Response<Perfil[]>> {
		return this.http.get<Response<Perfil[]>>(this.REST_API_SERVER_PROFILE + "/getProfiles/" + userId);
	}

	public getDataByPage(filtro: UsuarioFilter, page: Page): Observable<PagedResponse<Usuario>> {
		return this.http.get<PagedResponse<Usuario>>(
			this.REST_API_SERVER_USER +
				"/list/filter?Nombre=" +
				(filtro.nombre != undefined ? filtro.nombre : "") +
				"&Rut=" +
				(filtro.rut != undefined ? filtro.rut : "") +
				"&Comuna=" +
				(filtro.comuna != undefined ? filtro.comuna : "") +
				"&Activo=" +
				filtro.activo +
				"&EmisorId=" +
				(filtro.emisorId != undefined ? filtro.emisorId : "") +
				"&UsuarioId=" +
				filtro.usuarioId +
				"&SucursalId=" +
				(filtro.sucursalId != undefined ? filtro.sucursalId : "") +
				"&pageNumber=" +
				page.pageNumber +
				"&pageSize=" +
				page.pageSize
		);
	}
	public getSucursalDataByPage(filtro: SucursalFilter, page: Page): Observable<PagedResponse<Sucursal>> {
		//console.log(filtro);

		return this.http.get<PagedResponse<Sucursal>>(this.REST_API_SERVER_USER + "/listSucursal/filter?Nombre=" + (filtro.nombre != undefined ? filtro.nombre : "") + "&Direccion=" + (filtro.direccion != undefined ? filtro.direccion : "") + "&EmisorId=" + (filtro.emisorId != undefined ? filtro.emisorId : "") + "&UsuarioId=" + filtro.usuarioId + "&Activo=" + filtro.activo + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	updateUser(request: Usuario): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER_USER + "/updateUser", request);
	}
	updateUserProfiles(request: UsuarioRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER_USER + "/updateUserProfiles", request);
	}
	updateUserSucursales(request: UsuarioRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER_USER + "/updateUserSucursales", request);
	}

	addUser(request: UsuarioRequest): Observable<Response<Usuario>> {
		return this.http.post<Response<Usuario>>(this.REST_API_SERVER_USER + "/addUser", request);
	}
	// getSucursales(): Observable<Response<Sucursal[]>> {
	//    return this.http.get<Response<Sucursal[]>>(this.REST_API_SERVER_PROFILE + "/getSucursales");
	// }
	// getSucursalesByUserByEmisor(userId: String, emisorId: String): Observable<Response<Sucursal[]>> {
	//    return this.http.get<Response<Sucursal[]>>(this.REST_API_SERVER_USER + "/getSucursales/" + userId + "/" + emisorId);
	// }

	getSucursalesByUser(userId: String): Observable<Response<Sucursal[]>> {
		return this.http.get<Response<Sucursal[]>>(this.REST_API_SERVER_USER + "/getSucursales/" + userId);
	}

	getSucursalesByUserForUser(userIdSolicitante: String, userIdSolicitado: String): Observable<Response<Sucursal[]>> {
		return this.http.get<Response<Sucursal[]>>(this.REST_API_SERVER_USER + "/getSucursales/" + userIdSolicitante + "/" + userIdSolicitado);
	}

	deactivateUser(requestingUser: string, userId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER_USER + "/deactivate/" + requestingUser + "/" + userId);
	}

	activateUser(userId: string): Observable<Response<boolean>> {
		return this.http.get<Response<boolean>>(this.REST_API_SERVER_USER + "/activate/" + userId);
	}

	getUser(userId: string): Observable<Response<Usuario>> {
		return this.http.get<Response<Usuario>>(this.REST_API_SERVER_USER + "/findUser/" + userId);
	}
}
