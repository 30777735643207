<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<div class="d-flex flex-column-fluid">
				</div>
			</div>
		</div>
	</div>
</div>


<ng-template #changeCompanyForm let-d="dismiss" let-c="close">
	<div class="modal-xl">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Cambiar de empresa</div>
				<div class="modal-wizard-desc">Seleccione según corresponda</div>
			</div>
		</div>
		<div class="modal-body">
			<ng-select [items]="emisoresSource" placeholder="Empresa" bindLabel="razonSocial" (change)="cambiarEmpresa()" [(ngModel)]="currentEmisorObject" [clearable]="false"> </ng-select>
		</div>
	</div>
</ng-template>

<swal #currentSwal icon="info" title="Cambio de Empresa" text=""></swal>
