<form [formGroup]="sendEmailForm" (ngSubmit)="onSubmit()" #sendEmailFormRef="ngForm">
	<div class="modal-body">
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Tipo Correo</label>
				<div class="col-9">
					<ng-select *ngIf="!detailMode && !editMode" formControlName="tipo" [items]="emailSendingType" [clearable]="false" (change)="changeType()" placeholder="Seleccione tipo de correo de envio" bindLabel="nombre"></ng-select>
					<input *ngIf="detailMode || editMode" class="form-control" value="{{ correoEnvio.nombreTipoEnvio }}" disabled />
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Tipo Plantilla</label>
				<div class="col-9">
					<ng-select *ngIf="!detailMode && !editMode" formControlName="tipoPlantilla" [items]="templateTypes" [clearable]="false" (change)="changeType()" placeholder="Seleccione tipo de plantilla" bindLabel="nombre"></ng-select>
					<input *ngIf="detailMode || editMode" class="form-control" value="{{ correoEnvio.nombreTipoPlantillaEnvio }}" disabled />
				</div>
			</div>
		</div>
		<!-- <div class="form-group position-relative mx-auto">
            <div class="row">
                <label for="" class="col-2 label-control">Nombre</label>
                <div class="col-9">
                    <input type="text" name="nombre" id="nombre" placeholder="Ingresar nombre para identificar registro" formControlName="nombre" class="form-control" [attr.disabled]="detailMode?detailMode:null">
                    <div *ngIf="sendEmailFormRef.submitted &&( sendEmailForm.get('nombre').invalid || sendEmailForm.get('nombre').dirty )" class="invalid-feedback">
                        <div *ngIf="sendEmailForm.get('nombre').errors?.required">Campo requerido</div>
                    </div>
                </div>
               
            </div>
        </div> -->
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Remitente</label>
				<div class="col-9">
					<input type="text" name="remitente" id="remitente" placeholder="Ingresar nombre de remitente" formControlName="remitente" class="form-control" [attr.disabled]="detailMode ? detailMode : null" />
					<div *ngIf="sendEmailFormRef.submitted && (sendEmailForm.get('remitente').invalid || sendEmailForm.get('remitente').dirty)" class="invalid-feedback">
						<div *ngIf="sendEmailForm.get('remitente').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label class="col-2 label-control">Correo</label>
				<div class="col-9">
					<input pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" type="email" name="correo" placeholder="Ingresar correo" class="form-control" [attr.disabled]="detailMode ? detailMode : null" formControlName="correo" required />
					<div *ngIf="sendEmailFormRef.submitted && sendEmailForm.controls.base.get('correo').invalid" class="invalid-feedback">
						<div *ngIf="sendEmailForm.controls.base.get('correo').errors.required">Campo obligatorio</div>
						<div *ngIf="sendEmailForm.controls.base.get('correo').hasError('wrongEmail')">Correo inválido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto" *ngIf="!sengridtype">
			<div class="row">
				<label class="col-2 label-control">Servidor</label>
				<div class="col-9">
					<input type="text" name="servidor" id="servidor" placeholder="Ingresar servidor" formControlName="servidor" class="form-control" [attr.disabled]="detailMode ? detailMode : null" />
					<div *ngIf="sendEmailFormRef.submitted && (sendEmailForm.get('servidor').invalid || sendEmailForm.get('servidor').dirty)" class="invalid-feedback">
						<div *ngIf="sendEmailForm.get('servidor').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto" *ngIf="sengridtype">
			<div class="row">
				<label for="" class="col-2 label-control">Api Key</label>
				<div class="col-9">
					<textarea name="apikey" id="apikey" placeholder="Ingresar api key" formControlName="apikey" class="form-control" [attr.disabled]="detailMode ? detailMode : null"></textarea>
					<div *ngIf="sendEmailFormRef.submitted && (sendEmailForm.get('apikey').invalid || sendEmailForm.get('apikey').dirty)" class="invalid-feedback">
						<div *ngIf="sendEmailForm.get('apikey').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto" *ngIf="!sengridtype">
			<div class="row">
				<label for="" class="col-2 label-control">Puerto</label>
				<div class="col-9">
					<input type="number" name="puerto" id="puerto" placeholder="Ingresar puerto" formControlName="puerto" class="form-control" [attr.disabled]="detailMode ? detailMode : null" />
					<div *ngIf="sendEmailFormRef.submitted && (sendEmailForm.get('puerto').invalid || sendEmailForm.get('puerto').dirty)" class="invalid-feedback">
						<div *ngIf="sendEmailForm.get('puerto').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group position-relative mx-auto" *ngIf="!sengridtype">
			<div class="row">
				<label for="" class="col-2 label-control">Contraseña</label>
				<div class="col-9">
					<input type="password" name="password" id="password" placeholder="Ingresar contraseña" formControlName="password" class="form-control" [attr.disabled]="detailMode ? detailMode : null" />
					<div *ngIf="sendEmailFormRef.submitted && (sendEmailForm.get('password').invalid || sendEmailForm.get('apikey').dirty)" class="invalid-feedback">
						<div *ngIf="sendEmailForm.get('password').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto" *ngIf="!sengridtype">
			<div class="row">
				<label class="col-2 label-control">Usa SSL</label>
				<div class="col-9">
					<input type="checkbox" name="ssl" id="ssl" formControlName="ssl" class="input-checkbox" (change)="changeSsl($event)" [attr.disabled]="detailMode ? detailMode : null" />
					<div *ngIf="sendEmailFormRef.submitted && (sendEmailForm.get('ssl').invalid || sendEmailForm.get('ssl').dirty)" class="invalid-feedback">
						<div *ngIf="sendEmailForm.get('ssl').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="form-group position-relative mx-auto" *ngIf="!sengridtype && detailMode">
            <div class="row">
                <label class="col-2 label-control">Usa SSL</label>
                <div class="col-9" >
                    <input disabled type="checkbox" name="ssl" id="ssl" formControlName="ssl" class="input-checkbox" (change)="changeSsl($event)">
                    <div *ngIf="sendEmailFormRef.submitted &&( sendEmailForm.get('ssl').invalid || sendEmailForm.get('ssl').dirty )" class="invalid-feedback">
                        <div *ngIf="sendEmailForm.get('ssl').errors?.required">Campo requerido</div>
                    </div>
                </div>
            </div>
        </div> -->
	</div>
	<div class="modal-footer">
		<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" *ngIf="!detailMode">
			<i class="la la-paper-plane-o d-lg-none"></i>
			<span class="d-none d-lg-block">Guardar</span>
		</button>
		<!-- <button type="button" id="add-contact-item" class="btn btn-warning add-contact-item" (click)="onSubmitThenContract(sendEmailFormRef,$event)" *ngIf="!clientToEdit && permissions.getPermission('30-20-16').asignado">
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">Contrato</span>
         </button> -->
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
