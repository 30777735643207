import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { Observable } from "rxjs";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { Sucursal } from "src/app/models/Sucursal";
import { ChangeCompanyService } from "src/app/services/changecompany.service";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-change-company",
	templateUrl: "./change-company.component.html",
	styleUrls: ["./change-company.component.scss"],
})
export class ChangeCompanyComponent implements OnInit, OnDestroy {
	@ViewChild("currentSwal") public currentSwal: SwalComponent;
	public emisoresSource: Emisor[];
	private fullSucursalesSource: Sucursal[];
	public sucursalesOfSelectedEmisorSource: Sucursal[];
	private currentSucursalId: string;
	public currentEmisorObject: Emisor;
	public currentSucursalObject: Sucursal;
	private sucursalChanged$: Observable<boolean>;
	private previousEmisorObject: Emisor;

	constructor(private localStorage: LocalStorageService, private ref: ChangeDetectorRef, private changeCompanyService: ChangeCompanyService, private tokenService: TokenStorageService, private emisorService: EmisorService, private router: Router, public permissions: PermissionService) {
		this.emisoresSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];

		this.fullSucursalesSource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];

		//this.currentEmisorId = this.emisoresSource
		this.currentEmisorObject = this.emisoresSource.find((emisor) => emisor.actual);
		this.previousEmisorObject = this.currentEmisorObject;
		this.tokenService.setCompany(this.currentEmisorObject.emisorId);
		this.refreshSucursalOfEmisor();
		this.sucursalChanged$ = this.tokenService.sucursalHasChangedObservable;
	}

	ngOnInit(): void {
		this.sucursalChanged$.subscribe((cambioSucursal) => {
			if (cambioSucursal) {
				this.fullSucursalesSource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
				this.refreshSucursalOfEmisor();
			}
		});
		this.changeCompanyService.companyChanged.subscribe((newEmisor: Emisor) => {
			this.currentEmisorObject = newEmisor;
			this.refreshSucursalOfEmisor();
		});
	}
	ngOnDestroy(): void {}

	SetNewCompany() {
		this.previousEmisorObject = this.currentEmisorObject;
		this.tokenService.setCompany(this.currentEmisorObject.emisorId);
		this.refreshSucursalOfEmisor();
		this.changeCompanyService.SetNewCompany(this.currentEmisorObject, this.currentSucursalId, this.currentSwal);
	}

	refreshSucursalOfEmisor() {
		this.currentSucursalId = this.tokenService.getSubsidiary();
		this.sucursalesOfSelectedEmisorSource = this.fullSucursalesSource.filter((suc) => suc.emisorId == this.currentEmisorObject.emisorId);
		this.currentSucursalObject = this.sucursalesOfSelectedEmisorSource.find((suc) => suc.sucursalId == this.currentSucursalId);
	}

	SetNewSubsidiary(event) {
		this.tokenService.setSubsidiaryDirectly(this.currentSucursalObject.sucursalId);
		this.router.navigateByUrl("/bloqueado", { skipLocationChange: true }).then(() => this.router.navigate(["/"]));
		Swal.fire({
			title: "Cambio de Sucursal",
			icon: "info",
			html: "<h5>Ahora opera en la sucursal " + this.currentSucursalObject.nombre + "</h5>",
			showCloseButton: false,
			showCancelButton: false,
			focusConfirm: true,
			confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendido',
			customClass: {
				confirmButton: "btn btn-success",
			},
		});
	}
}
