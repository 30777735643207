import { HashLocationStrategy } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AnularFolios } from "../models/Folio/AnularFolios";
import { Folio } from "../models/Folio/Folio";
import { FolioFileRequest } from "../models/Folio/FolioFileRequest";
import { FolioFilter } from "../models/Folio/FolioFilter";
import { FoliosAnulables } from "../models/Folio/FoliosAnulables";
import { ObtenerFolios } from "../models/Folio/ObtenerFolios";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Response } from "../shared/models/Response";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
   providedIn: "root",
})
export class FoliosService {
   private REST_API_SERVER = environment.backend_server + "/timbraje";
   isLoadingFolios: Observable<boolean>;
   private loadingFoliosSubject: BehaviorSubject<boolean>;
   constructor(private http: HttpClient, private tokenService: TokenStorageService) {
      this.loadingFoliosSubject = new BehaviorSubject<boolean>(true);
      this.isLoadingFolios = this.loadingFoliosSubject.asObservable();
   }

   public getData(emisorId, sucursalId): Observable<Response<Array<Folio>>> {
      this.loadingFoliosSubject.next(true);
      return this.http.get<Response<Array<Folio>>>(this.REST_API_SERVER + "/list?emisorId=" + emisorId + "&sucursalId=" + sucursalId).pipe(finalize(() => this.loadingFoliosSubject.next(false)));
   }

   public getDataByPage(filter: FolioFilter, page: Page): Observable<PagedResponse<Folio>> {
      let tipoDte = filter.tipoDteId ? filter.tipoDteId : "";
      let ambiente = filter.ambienteId != undefined || filter.ambienteId != null ? filter.ambienteId : "";
      let sucursal = filter.sucursalId ? filter.sucursalId : "";
      let activo = filter.activo ? filter.activo : "false";
      let emisor = filter.emisorId ? filter.emisorId : "";
      return this.http.get<PagedResponse<Folio>>(this.REST_API_SERVER + "/list/filter" + "?TipoDteId=" + tipoDte + "&AmbienteId=" + ambiente + "&SucursalId=" + sucursal + "&Activo=" + activo + "&EmisorId=" + emisor + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
   }

   mapActivesFromResponse(resp: Response<Array<Folio>>) {
      let response = resp;
      response.data = response.data.filter((f) => f.activo == true);
      return response;
   }

   // public add(data):Observable<any>{
   //   data.object["sucursalId"] = this.tokenService.getSubsidiaryFromCompany(data.object.emisorId);
   //   let formData = new FormData();
   //     formData.append("timbrajeString", JSON.stringify(data.object));
   //     formData.append("file", data.file);
   //     formData.append("usuarioId", this.tokenService.getUser().id);
   //   return this.http.post<any>(this.REST_API_SERVER + "/upload",formData);
   // }

   public addWithFile(data: FolioFileRequest, file): Observable<Response<string>> {
      let formData = new FormData();
      formData.append("timbrajeString", JSON.stringify(data.requestObject));
      formData.append("file", data.file);
      formData.append("usuarioId", this.tokenService.getUser().id);
      return this.http.post<any>(this.REST_API_SERVER + "/upload", formData);
   }

   public delete(folio: Folio): Observable<Response<boolean>> {
      return this.http.post<any>(this.REST_API_SERVER + "/delete?timbrajeId=" + folio.timbrajeId, null);
   }

   public obtenerFolios(req: ObtenerFolios): Observable<Response<boolean>> {
      return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/obtenerFolios", req);
   }

   public consultarFoliosDisponibles(req): Observable<Response<number>> {
      return this.http.post<Response<number>>(this.REST_API_SERVER + "/consultarFoliosDisponibles", req);
   }

   public consultarFoliosAnular(folio: Folio): Observable<Response<FoliosAnulables[]>> {
      return this.http.get<Response<any>>(this.REST_API_SERVER + "/ConsultarFoliosAnulables?timbrajeId=" + folio.timbrajeId);
   }

   public anularFolios(anularReq: AnularFolios): Observable<Response<any>> {
      return this.http.post<Response<any>>(this.REST_API_SERVER + "/anularFolios", anularReq);
   }

   public deleteBlocked(id: string): Observable<Response<boolean>> {
      return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deleteBlocked/" + id);
   }
}
