import { JsonpClientBackend } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectionType } from "@swimlane/ngx-datatable";
import { Receptor } from "src/app/models/Receptor/Receptor";
import { ReceptorFilter } from "src/app/models/Receptor/ReceptorFilter";
import { ClienteService } from "src/app/services/cliente.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";

export const ConfigClientes = {
	barraBusqueda: {
		criterios: ["Razon Social", "Rut Cliente"],
		criteriosKeys: ["razonSocial", "rutReceptorDv"],
		criterioPrincipal: "Razon Social",
	},
};
export const ConfigClientesInternacionales = {
	barraBusqueda: {
		criterios: ["Razon Social", "Identificador"],
		criteriosKeys: ["razonSocial", "codigoInterno"],
		criterioPrincipal: "Razon Social",
	},
};

export const ConfigProveedores = {
	barraBusqueda: {
		criterios: ["Razon Social", "Rut Proveedor"],
		criteriosKeys: ["razonSocial", "rut", "giro"],
		criterioPrincipal: "Razon Social",
	},
};

@Component({
	selector: "app-client-list",
	templateUrl: "./client-list.component.html",
	styleUrls: ["./client-list.component.scss"],
})
export class ClientListComponent implements OnInit {
	@Output() private clientToPass: EventEmitter<Receptor> = new EventEmitter<Receptor>();
	public loading: boolean = false;
	public rowsMantenedor: any[] = [];
	private clienteFilter: ReceptorFilter = new ReceptorFilter();
	private filter: any = undefined;
	public page: Page = new Page();
	public configLocal = ConfigClientes;
	public selected = [];
	public SelectionType = SelectionType;
	private emisorId = this.tokenService.getCompany();

	constructor(private clientesService: ClienteService, private tokenService: TokenStorageService, private localStorage: LocalStorageService, private ref: ChangeDetectorRef, private utilities: UtilitiesService) {}

	ngOnInit(): void {
		this.clienteFilter.emisorId = this.emisorId;
			this.filter = this.clienteFilter;
			this.configLocal = ConfigClientes;
		
		this.filter.activo = true;
		// if(this.filter.hasOwnProperty('emisorId')){
		//   this.filter.emisorId = this.emisorId;
		// }
		this.page.pageSize = 5;
		this.setPage({ offset: 0 });
	}

	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;

		this.clientesService.getDataByPage(this.filter, localPage).subscribe(
			(result) => {
				this.rowsMantenedor = [...result.data];
				this.setPageFromResponse(this.page, result);
				this.loading = false;
			},
			(error) => {
				Swal.fire({
					title: "Error en lista",
					text: "No fue posible traer la lista de clientes",
					icon: "error",
				});
			}
		);		
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<any>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	searchElementsByFilter(event) {
		let filterLocal = new ReceptorFilter();
		this.filter = { ...filterLocal };
		this.filter.emisorId = this.emisorId;
		if (this.filter.hasOwnProperty("emisorId")) {
			this.filter.emisorId = this.emisorId;
		}
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.activo = true;
		this.filter.emisorId = this.emisorId;
		this.setPage({ offset: 0 });
	}

	onActivate(event) {
		//console.log("Activated Receptor",event, event.type,event.type=="click");
		if (event.type == "click") {
			this.clientToPass.emit(event.row);
		}
	}
}
