<!--begin::Topbar-->
<ng-container *ngIf="extraSearchDisplay">
  <ng-container *ngIf="extrasSearchLayout === 'offcanvas'">
    <!--begin::Search-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_search_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"></span>
      </div>
    </div>
    <!--end::Search-->
  </ng-container>

  <ng-container *ngIf="extrasSearchLayout === 'dropdown'" id="kt_quick_search_toggle">
    <div class="dropdown" id="kt_quick_search_toggle" autoClose="outside" ngbDropdown>
      <!--begin::Toggle-->
      <div class="topbar-item" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
          <span [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"></span>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <div class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg" ngbDropdownMenu>
        <app-search-dropdown-inner></app-search-dropdown-inner>
      </div>
      <!--end::Dropdown-->
    </div>
  </ng-container>
</ng-container>

<!--app session ambient -->
<ng-container>
  <div class="topbar-item">
    <app-company-ambient [headerMode]="true" class="mr-2" style="text-align: center!important;"></app-company-ambient>
  </div>
</ng-container>
<!--end app session ambient-->
<ng-container *ngIf="extrasNotificationsDisplay">
  <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">
    <!--begin::Notifications-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-icon-mobile btn-clean btn-lg mr-1 pulse pulse-primary"
        id="kt_quick_notifications_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"></span>
        <span class="pulse-ring"></span>
      </div>
    </div>
    <!--end::Notifications-->
  </ng-container>

  <ng-container *ngIf="extrasNotificationsLayout === 'dropdown'">
    <!--begin::Notifications-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary" style="pointer-events: none"></span>
          <fa-icon *ngIf="showAlertExclamation" icon="exclamation" class="fa-xl text-danger alert-icon"></fa-icon>
          <ng-container *ngIf="newNotificationAlert$ | async">
            <span class="pulse-ring border-danger"></span>
          </ng-container>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <!--end::Dropdown-->
    </div>
    <!--end::Notifications-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasQuickActionsDisplay">
  <ng-container *ngIf="extrasQuickActionsLayout === 'offcanvas'">
    <!--begin::Quick Actions-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" id="kt_quick_actions_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"></span>
      </div>
    </div>
    <!--end::Quick Actions-->
  </ng-container>
  <ng-container *ngIf="extrasQuickActionsLayout === 'dropdown'">
    <!--begin::Quick Actions-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span [inlineSVG]="'./assets/media/svg/icons/Media/Equalizer.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"></span>
        </div>
      </div>
      <!--end::Toggle-->
      <!--begin::Dropdown-->
      <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg" ngbDropdownMenu>
        <app-quick-actions-dropdown-inner></app-quick-actions-dropdown-inner>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Quick Actions-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasCartDisplay">
  <ng-container *ngIf="extrasCartLayout === 'offcanvas'">
    <!--begin::Cart-->
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1" id="kt_quick_cart_toggle">
        <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'" cacheSVG="true"
          class="svg-icon svg-icon-xl svg-icon-primary"></span>
      </div>
    </div>
    <!--end::Cart-->
  </ng-container>
  <ng-container *ngIf="extrasCartLayout === 'dropdown'">
    <!--begin::Cart-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span [inlineSVG]="'./assets/media/svg/icons/Shopping/Cart3.svg'" cacheSVG="true"
            class="svg-icon svg-icon-xl svg-icon-primary"></span>
        </div>
      </div>
      <!--end::Toggle-->
      <!--begin::Dropdown-->
      <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-xl dropdown-menu-anim-up">
        <form>
          <app-cart-dropdown-inner></app-cart-dropdown-inner>
        </form>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Cart-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasQuickPanelDisplay">
  <!--begin::Quick panel-->
  <div class="topbar-item">
    <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
      <span [inlineSVG]="'./assets/media/svg/icons/Layout/Layout-4-blocks.svg'" cacheSVG="true"
        class="svg-icon svg-icon-xl svg-icon-primary"></span>
    </div>
  </div>
  <!--end::Quick panel-->
</ng-container>

<ng-container *ngIf="extrasLanguagesDisplay">
  <app-language-selector style="margin-top: 10px;"></app-language-selector>
</ng-container>

<ng-container>
  <div class="topbar-item my-auto mr-5">
    <app-change-company></app-change-company>
  </div>
</ng-container>

<ng-container *ngIf="extrasUserDisplay">
  <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
    <!-- <ng-container *ngIf="user$ | async as _user"> -->
    <ng-container>
      <!--begin::User-->
      <div class="topbar-item">
        <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle">
          <!-- <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Bienvenido,</span> -->
          <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">

            <span *ngIf="user$ && !msalUserLogged" class="symbol-label font-size-h5 font-weight-bold">{{
              user$.fullname[0]
              }}</span>

            <span *ngIf="(msalUserObs$ | async) && msalUserLogged" class="symbol-label font-size-h5 font-weight-bold">{{
              msalUser.givenName[0]
              }}</span>

          </span>


          <span *ngIf="user$ && !msalUserLogged"
            class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 ml-3">{{ user$.firstname }}
            {{user$.lastname[0]| firstLetter}}.
          </span>

          <span *ngIf="(msalUserObs$ | async) && msalUserLogged"
            class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 ml-3">{{ msalUser.givenName }}
            {{msalUser.surname| firstLetter}}.
          </span>

        </div>
      </div>
      <!--end::User-->
    </ng-container>

    <!-- <ng-container *ngIf="(msalUserObs$ | async) && msalUserLogged">
        <div class="topbar-item">
          <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle">
            <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                msalUser.givenName
              }}</span>
            </span>
            <span
              class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 ml-3">{{ msalUser.givenName }} {{msalUser.surname| firstLetter}}.</span>
            
          </div>
        </div>
      </ng-container> -->
  </ng-container>

  <ng-container *ngIf="extrasUserLayout === 'dropdown'">
    <!--begin::User-->
    <!-- <ng-container *ngIf="user$ | async as _user"> -->
    <ng-container *ngIf="user$">
      <div class="dropdown" ngbDropdown placement="bottom-right">
        <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Bienvenido,</span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ user$.fullname
              }}</span>
            <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                user$.fullname | firstLetter
                }}</span>
            </span>
          </div>
        </div>
        <!--end::Toggle-->
        <!--begin::Dropdown-->
        <div ngbDropdownMenu
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <!--end::Dropdown-->

    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->