import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { ActividadEconomica } from "src/app/models/ActividadEconomica";
import { ActividadEconomicaEmisor } from "src/app/models/ActividadEconomicaEmisor";
import { Ambiente } from "src/app/models/Ambiente";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { EmisorFilter } from "src/app/models/Emisor/EmisorFilter";
import { PersonaAutorizada } from "src/app/models/PersonaAutorizada";
import { Sucursal } from "src/app/models/Sucursal";
import { SucursalFilter } from "src/app/models/Usuario/SucursalFilter";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import { SessionUser } from "src/app/shared/models/sessionUser";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import { PersonasAutorizadasService } from "../../../services/paseronas-autorizadas.service";

@Component({
	selector: "app-company-panel",
	templateUrl: "./company-panel.component.html",
	styleUrls: ["./company-panel.component.scss"],
})
export class CompanyPanelComponent implements OnInit {
	public loading: boolean = false;
	public currentEmisorList: Emisor[] = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
	public currentEmisorId = this.tokenStorage.getCompany();
	public currentEmisor = this.currentEmisorList.find((e) => e.emisorId == this.currentEmisorId);
	public page = new Page();
	public pageSucursal = new Page();
	public filter = new EmisorFilter();
	public filterSucursal = new SucursalFilter();
	public filteredEmisor: Emisor;
	public branches: Sucursal[] = [];
	public environmentType: Ambiente[] = JSON.parse(this.localStorage.getTipoAmbiente()) as Ambiente[];
	public actividadesEconomicas: ActividadEconomica[] = [];
	public personasAutorizadas: PersonaAutorizada[] = [];
	public branchToShow: Sucursal;
	public branchToEdit: Sucursal;
	public branchToAdd: Sucursal;
	public detailSucursalModal: any;
	private editSucursalModal: any;
	private addNewSucursalModal: any;
	private activitiesModal: any;
	public updateAuthorizedPersonForm: FormGroup;
	public addAuthorizedPersonForm: FormGroup;
	private updateAuthorizedPersonModal: any;
	private addAuthorizedPersonModal: any;
	public personToEdit: PersonaAutorizada;
	public addMailBoxForm: FormGroup;
	public updateMailBoxForm: FormGroup;
	private addMailBoxModal: any;
	public files: File[] = [];
	private updateMailBoxModal: any;
	private superAdmin = this.tokenService.getUser().superAdmin; //this.permissions.getPermission("10-10").asignado;
	constructor(
		private emisorService: EmisorService,
		private personasAutorizadasService: PersonasAutorizadasService,
		private localStorage: LocalStorageService,
		private tokenStorage: TokenStorageService,
		private ref: ChangeDetectorRef,
		private utilities: UtilitiesService,
		private modalService: NgbModal,
		public permissions: PermissionService,
		private formBuilder: FormBuilder,
		private tokenService: TokenStorageService
	) {}

	ngOnInit(): void {
		this.setPage();
		this.setPageSucursal({ offset: 0 });
		this.getEconomicActivities();
		this.getAuthorizedPersons();
		this.setPersonsForms();
	}

	setPage() {
		this.loading = true;
		this.filter.rut = this.currentEmisor.rutString;
		this.filter.activo = 1;
		this.page.pageNumber = 0;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		this.emisorService
			.getDataByPage(this.filter, localPage, this.tokenStorage.getUser().id)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Emisor[];
					this.ref.detectChanges();
					this.filteredEmisor = elements.find((e) => e.emisorId == this.currentEmisorId);

					this.filteredEmisor.ambienteEnt = this.environmentType[this.filteredEmisor.ambiente];
					this.filteredEmisor.ambiente = this.filteredEmisor.ambienteEnt.ambienteId;
					this.filteredEmisor.nombreAmbiente = this.filteredEmisor.ambienteEnt.nombre;

					//this.setPagefromResponse(this.page, pagedData);
					this.loading = false;
					//console.log(this.currentEmisor, this.filteredEmisor);

					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					Swal.fire({
						icon: "error",
						title: "Error en la solicitud",
						text: " error.error.message",
					});
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	setPageSucursal(pageInfo) {
		this.loading = true;
		this.pageSucursal.pageNumber = pageInfo.offset;
		this.pageSucursal.pageNumber += 1;
		this.filterSucursal.emisorId = this.currentEmisorId;
		this.filterSucursal.activo = 1;
		this.emisorService
			.getSucursalDataByPage(this.filterSucursal, this.pageSucursal)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Sucursal[];
					this.ref.detectChanges();
					this.setPagefromSucursalResponse(this.pageSucursal, pagedData);
					this.branches = [...elements];
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					Swal.fire({
						icon: "error",
						title: "Error en la solicitud",
						text: this.utilities.setErrorMessageFromArray(error.error),
					});
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	getEconomicActivities() {
		this.loading = true;
		this.emisorService.getEconomicActivitiesByEmisor(this.currentEmisorId).subscribe(
			(result) => {
				this.loading = false;
				this.actividadesEconomicas = result.data;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Actividades Económicas No Encontradas",
					text: "Error al buscar actividades económicas del emisor." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	setPagefromSucursalResponse(currentPage: Page, data: PagedResponse<Sucursal>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	getAuthorizedPersons() {
		this.loading = true;
		this.personasAutorizadasService.getAuthorizedPersonsByEmisor(this.currentEmisorId).subscribe(
			(result) => {
				this.loading = false;
				this.personasAutorizadas = result.data;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Personas Autorizadas para Cesion no encontradas",
					text: "Error al buscar personas autorizadas para cesión del emisor" + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	detailBranchOfficeModal(modalRef, row: Sucursal) {
		this.loading = true;
		this.branchToShow = row;
		this.loading = false;
		this.ref.detectChanges();
		this.detailSucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	editBranchOfficeModal(modalRef, row: Sucursal) {
		this.loading = true;
		this.branchToEdit = new Sucursal();
		this.branchToEdit.emisorId = row.emisorId;
		this.branchToEdit.sucursalId = row.sucursalId;
		this.branchToEdit.nombre = row.nombre;
		this.branchToEdit.direccion = row.direccion;
		this.loading = false;
		this.ref.detectChanges();
		this.editSucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	editBranchOffice(editForm) {
		this.loading = true;
		this.emisorService.updateBranchOffice(this.branchToEdit).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Sucursal Actualizada",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "La sucursal " + this.branchToEdit.nombre + " fue actualizada correctamente" + "</p></div>",
				});
				this.setPageSucursal({ offset: 0 });
				this.editSucursalModal.close();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Actualizar Sucursal",
					text: this.utilities.setErrorMessageFromArray(err.error),
				});
			}
		);
	}

	activateBranchOffice(row: Sucursal) {
		this.loading = true;
		this.emisorService.activateBranchOffice(row.sucursalId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Sucursal Activada",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Sucursal " + row.nombre + " Activado" + "</p></div>",
					});
					this.setPageSucursal({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Activar",
					text: "No fue posible activar la sucursal " + row.nombre,
				});
			}
		);
	}

	deactivateBranchOffice(row: Sucursal) {
		this.loading = true;
		this.emisorService.deactivateBranchOffice(row.sucursalId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Sucursal Desactivada",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Sucursal " + row.nombre + " Desactivada" + "</p></div>",
					});
					this.setPageSucursal({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Desactivar",
					text: "No fue posible desactivar la sucursal " + row.nombre + this.utilities.setErrorMessageFromArray(err.error),
				});
			}
		);
	}
	addBranchOfficeModal(modalRef) {
		this.loading = true;
		this.branchToAdd = new Sucursal();
		this.branchToAdd.emisorId = this.currentEmisorId;
		this.loading = false;
		this.ref.detectChanges();
		this.addNewSucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	addBranchOffice(modalRef) {
		this.loading = true;
		this.emisorService.addBranchOffice(this.branchToAdd).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				if (res.data) {
					Swal.fire({
						icon: "success",
						title: "Sucursal Agregada",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "La Sucursal " + this.branchToAdd.nombre + " fue agregada" + "</p></div>",
					});
					this.filterSucursal.emisorId = this.currentEmisorId;
					this.filterSucursal.activo = null;
					this.setPageSucursal({ offset: 0 });
					this.addNewSucursalModal.close();
				} else {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "info",
						title: "Nombre de la Sucursal Existente",
						text: res.message,
					});
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Agregar Sucursal",
					text: this.utilities.setErrorMessageFromArray(err.error),
				});
			}
		);
	}
	//ECONOMIC ACTIVITIES METHODS
	openActivitiesModal(modalRef) {
		let activities = [...this.actividadesEconomicas];
		this.currentEmisor.actividadesEconomicas = activities;
		this.activitiesModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	closeEconomicActivitiesModal() {
		this.activitiesModal.close();
	}

	updateEconomicActivitiesRequest(event: ActividadEconomicaEmisor[]) {
		this.loading = true;
		this.emisorService.toAssignEconomicActivity(event).subscribe(
			(result) => {
				this.loading = false;
				this.getEconomicActivities();
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Actividades Económicas Actualizadas",
					text: "Las actividades económicas fueron actualizadas exitosamente",
				});
				this.closeEconomicActivitiesModal();
			},
			(error) => {
				this.loading = false;
				let errorText = error.error.message;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error Al actualizar Actividades Económicas",
					text: "No ha sido posible actualizar las actividades económicas del emisor." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	//AUTHORIZED PERSONS METHODS
	setPersonsForms() {
		this.addAuthorizedPersonForm = this.formBuilder.group({
			rutString: [, [Validators.required, VerificacionRutValidator]],
			nombre: [, Validators.required],
			correo: [, [Validators.required, SingleEmailValidator]],
		});
		this.updateAuthorizedPersonForm = this.formBuilder.group({
			rutString: ["", [Validators.required, VerificacionRutValidator]],
			nombre: ["", Validators.required],
			correo: ["", [Validators.required, SingleEmailValidator]],
		});
	}
	closeUpdateAuthorizedPersonModal() {
		this.updateAuthorizedPersonModal.close();
	}
	updatePerson() {
		this.loading = false;
		if (this.updateAuthorizedPersonForm.valid) {
			var rut = this.updateAuthorizedPersonForm.get("rutString").value;
			this.personToEdit.rut = parseInt(rut.substring(0, rut.length - 2));
			this.personToEdit.dv = rut.substring(rut.length - 1);
			this.personToEdit.activo = true;
			this.personToEdit.correo = this.updateAuthorizedPersonForm.get("correo").value;
			this.personToEdit.nombre = this.updateAuthorizedPersonForm.get("nombre").value;
			this.personToEdit.emisorId = this.currentEmisorId;
			this.personasAutorizadasService
				.updateAuthorizedPersons(this.personToEdit)
				.subscribe(
					(res) => {
						Swal.fire({
							icon: "success",
							title: "Persona Autorizada Actualizada",
							text: "Se ha actualizado exitosamente a la persona autorizada " + this.personToEdit.nombre,
						});
						this.getAuthorizedPersons();
						this.loading = false;
						this.ref.detectChanges();
						this.closeUpdateAuthorizedPersonModal();
					},
					(err) => {
						this.loading = false;
						this.ref.detectChanges();
						if (err.error.data) {
							Swal.fire({
								icon: "info",
								title: "Persona Autorizada Existente",
								text: "Ya existe una persona autorizada con el rut " + this.personToEdit.rutFormateado,
							});
						} else {
							Swal.fire({
								icon: "error",
								title: "Error al Actualizar Persona Autorizada",
								text: "No se ha podido actualizar a la persona autorizada " + this.personToEdit.nombre + ", intente nuevamente",
							});
						}
					}
				)
				.add(() => {
					this.loading = false;
				});
		}
	}
	openUpdateAuthorizedPersonModal(modalRef, item) {
		this.loading = false;
		this.personToEdit = new PersonaAutorizada();
		this.personToEdit = item;
		this.setAutorizedPersonForm(item);
		this.updateAuthorizedPersonModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	setAutorizedPersonForm(authorizedPerson) {
		this.updateAuthorizedPersonForm.patchValue(authorizedPerson);
		this.updateAuthorizedPersonForm.get("rutString").patchValue(authorizedPerson.rutFormateado);
		this.updateAuthorizedPersonForm.get("nombre").patchValue(authorizedPerson.nombre);
		this.updateAuthorizedPersonForm.get("correo").patchValue(authorizedPerson.correo);
	}

	deleteAuthorizedPerson(item) {
		this.loading = true;
		this.personasAutorizadasService
			.deleteAuthorizedPersons(item)
			.subscribe(
				(res) => {
					Swal.fire({
						icon: "success",
						title: "Persona Autorizada Eliminada",
						text: "Se ha eliminado exitosamente a la persona autorizada " + item.nombre,
					});
					this.getAuthorizedPersons();
					this.loading = false;
					this.ref.detectChanges();
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "info",
						title: "Error al Eliminar",
						text: "No fue podible eliminar a la persona autorizada " + item.nombre,
					});
				}
			)
			.add(() => {
				this.loading = false;
			});
	}

	openAddAuthorizedPersonModal(modalRef) {
		this.addAuthorizedPersonModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	closeAddAuthorizedPersonModal() {
		this.addAuthorizedPersonModal.close();
	}

	addPerson() {
		if (this.addAuthorizedPersonForm.valid) {
			this.loading = true;
			var authorizedPerson = new PersonaAutorizada();
			var rut = this.addAuthorizedPersonForm.get("rutString").value;
			authorizedPerson.rut = parseInt(rut.substring(0, rut.length - 2));
			authorizedPerson.dv = rut.substring(rut.length - 1);
			authorizedPerson.activo = true;
			authorizedPerson.correo = this.addAuthorizedPersonForm.get("correo").value;
			authorizedPerson.nombre = this.addAuthorizedPersonForm.get("nombre").value;
			authorizedPerson.emisorId = this.currentEmisorId;
			this.personasAutorizadasService
				.toAssignAuthorizedPersons(authorizedPerson)
				.subscribe(
					(res) => {
						this.loading = false;
						Swal.fire({
							icon: "success",
							title: "Persona Autorizada Agregada",
							text: "Se ha agregado exitosamente a la persona autorizada " + authorizedPerson.nombre,
						});
						this.getAuthorizedPersons();
						this.ref.detectChanges();
						this.closeAddAuthorizedPersonModal();
					},
					(err) => {
						this.loading = false;
						this.ref.detectChanges();
						if (err.error.data) {
							Swal.fire({
								icon: "info",
								title: "Persona Autorizada Existente",
								text: "Ya existe una persona autorizada con el rut " + authorizedPerson.rut + "-" + authorizedPerson.dv,
							});
						} else {
							Swal.fire({
								icon: "error",
								title: "Error al Agregar Persona Autorizada",
								text: "No se ha podido agregar a la persona autorizada " + authorizedPerson.nombre + ", intente nuevamente",
							});
						}
					}
				)
				.add(() => {
					this.loading = false;
				});
		}
	}

	cleanFiles() {
		this.files = [];
	}
	

	onSelect(event) {
		if (event.rejectedFiles[0] || this.getName(event) != "json") {
			Swal.fire({
				icon: "error",
				title: "Error de Archivo",
				text: "El archivo ingresado no es de formato CSV, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo",
			});
		}

		this.files.push(...event.addedFiles);
		if (this.files.length > 1) {
			this.files.shift();
		}
		this.addMailBoxForm.patchValue(this.files[0]);
		this.updateMailBoxForm.patchValue(this.files[0]);
	}
	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}
	getName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}

	closeUpdateMailBoxModal() {
		this.updateMailBoxModal.close();
	}
}
