import { TOUCH_BUFFER_MS } from "@angular/cdk/a11y";
import { ThisReceiver } from "@angular/compiler";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { i18nMetaToJSDoc } from "@angular/compiler/src/render3/view/i18n/meta";
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { Console } from "console";
import * as moment from "moment";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Observable, Subject, Subscription, zip } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Divisa } from "src/app/models/Divisa";
import { ComisionRecargo } from "src/app/models/Dte/ComisionRecargo";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { DteEmitido } from "src/app/models/Dte/DteEmitido";
import { IndicadorServicio } from "src/app/models/Dte/IndicadorServicio.enum";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { ReferenciaDteEmitido } from "src/app/models/Dte/ReferenciaDteEmitido";
import { SolicitudDte } from "src/app/models/Dte/SolicitudDte";
import { TotalDteEmitido } from "src/app/models/Dte/TotalDteEmitido";
import { Folio } from "src/app/models/Folio/Folio";
import { GuiaDespacho } from "src/app/models/GuiaDespacho";
import { Producto } from "src/app/models/Producto/Producto";
import { Receptor } from "src/app/models/Receptor/Receptor";
import { TipoDte } from "src/app/models/tipoDte";
import { DashboardService } from "src/app/services/dashboard.service";
import { DteEmitidosService } from "src/app/services/dte-emitidos.service";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { FoliosService } from "src/app/services/folios.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ProductoService } from "src/app/services/producto.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { InvoicingButton } from "src/app/shared/models/InvoicingButton.enum";
import { Response } from "src/app/shared/models/Response";
import { SessionUser } from "src/app/shared/models/sessionUser";
import { SubheaderService } from "src/app/_metronic/partials/layout/subheader/_services/subheader.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { runInThisContext } from "vm";
import { resourceLimits } from "worker_threads";
import { FacturaCompra } from "../../../models/Dte/FacturaCompra";
import { IDte } from "../../../models/Dte/IDte";
import { DocumentosEmitidosWidgetComponent } from "../../widgets/documentos-emitidos-widget/documentos-emitidos-widget.component";

@Component({
	selector: "app-facturador-individual",
	templateUrl: "./facturador-individual.component.html",
	styleUrls: ["./facturador-individual.component.scss"],
})
export class FacturadorIndividualComponent implements OnInit {
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	public loading: boolean = false;
	//private newDte: DteEmitido = new DteEmitido();
	private newDte: IDte = new SolicitudDte();
	private subscriptions: Subscription[] = [];
	public listaDetallesFacturador: DetalleDte[] = [];
	private listaDetallesObservable$: Observable<DetalleDte[]>;
	private listaComisionesObservable$: Observable<ComisionRecargo[]>;
	private clienteIdObservable$: Observable<string>;
	private optionsRefObservable$: Observable<OptionsReferenceDteEmitido>;
	private totalesDteObservable$: Observable<TotalDteEmitido>;
	public dteInvoice: DteEmitido = new DteEmitido();
	public dteSubmitted: boolean = false;
	public listDivisas: Divisa[] = [];
	public showInGuide: boolean = false;
	public dontShowInSettlement: boolean = false;
	public showInSettlement = false;
	private listElementsWithTax: boolean = false;
	public showInExportInvoice: boolean = false;
	public receptor: any = null;
	public porcentajeIva: number = environment.taxPercentage;
	private invoiceTypeByRoute: Observable<any>; //observable for parameter from route,
	private invoiceType: InvoiceType = InvoiceType.Factura;
	public loadingDivisas$: Observable<boolean>;
	public invalidSize$: Observable<boolean>;
	public invalidSize: boolean = false;
	public buttonAction$;
	private destroySubscriptions$: Subject<boolean> = new Subject<boolean>();
	public listaGuiasFacturables: DteEmitido[] = [];
	public configScroll: PerfectScrollbarConfigInterface = {};
	@ViewChild("GuidesListToInvoices") listaGuiasModalRef: any;
	private guiasModal: any;
	public selectedGuides: DteEmitido[] = []; //dtes seleccionados por checkbox
	private currentGlobaleCheckGuidRefs: boolean = false;
	public selectedAllItemsInPage: any = {}; // bool para la seleccion total de elementos en tabla
	private responseResult: string;
	private foliosData: Folio[] = [];
	private currentEmisorId: string = this.tokenService.getCompany();
	private currentSucursalId: string = this.tokenService.getSubsidiary();
	private groupedFolios: Object;
	private listFolioResumen: any[] = [];
	private tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
	private currentGrupoFolio: any;
	locale = {
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD/MM/YYYY",
	};
	constructor(
		private facturador: FacturacionIndividualService,
		private tokenService: TokenStorageService,
		private ref: ChangeDetectorRef,
		private utilities: UtilitiesService,
		private dashboard: DashboardService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private localStorage: LocalStorageService,
		private productService: ProductoService,
		private subheaderService: SubheaderService,
		private modalService: NgbModal,
		private dteService: DteEmitidosService,
		private foliosService: FoliosService
	) {
		this.listaDetallesObservable$ = this.facturador.detalleDteListObservable;
		this.listaComisionesObservable$ = this.facturador.detalleComisionDteListObservable;
		this.clienteIdObservable$ = this.facturador.clienteIdObservable;
		this.optionsRefObservable$ = this.facturador.opcionesReferenciaObservable;
		this.totalesDteObservable$ = this.facturador.resumenTotalesObservable;
		this.loadingDivisas$ = this.dashboard.isLoadingDailyDivisas;
		this.invalidSize$ = this.facturador.invalidSizeObservable;
		this.buttonAction$ = this.facturador.buttonActionsObservable;
	}

	ngOnInit(): void {
		this.dontShowInSettlement = false;
		this.showInSettlement = false;
		this.selectedGuides = [];
		this.getFoliosDisponibles();

		const detallesSubscription = this.listaDetallesObservable$.subscribe((list) => {
			this.newDte.detalleDte = list.filter((detail) => detail.precioUnitario > 0 && detail.cantidad > 0);
		});
		const comisionesSubscription = this.listaComisionesObservable$.subscribe((list) => {
			this.newDte.comisionesCargosDte = list.filter((detail) => detail.neto > 0 || detail.exento > 0);
		});
		const clienteSubscription = this.clienteIdObservable$.subscribe((rut) => {
			this.newDte.rutReceptor = rut ? rut.substring(0, rut.length - 2) : "66666666";
			this.newDte.rutReceptorDv = rut ? rut.substring(rut.length - 1) : "6";
		});
		const optionsRefSubscription = this.optionsRefObservable$.subscribe((optionsRef) => {
			this.listElementsWithTax = optionsRef.sonDetallesConIVA;
			this.newDte.indicadorMontosNetos = !optionsRef.sonDetallesConIVA;
			this.newDte.tipoMoneda = optionsRef.tipoMoneda;
			this.newDte.tipoMonedaAduana = optionsRef.tipoMonedaAduana;
			this.newDte.codigoMonedaAduana = optionsRef.codigoMonedaAduana;
			if (optionsRef.haveDteReference) {
				//compatible with old invoice generation
				if (optionsRef.listaReferencias && optionsRef.listaReferencias[0]) {
					this.newDte.razonReferencia = optionsRef.listaReferencias[0].razonReferencia;
					this.newDte.folioReferencia = optionsRef.listaReferencias[0].folioReferencia;
					this.newDte.fechaReferencia = optionsRef.listaReferencias[0].fechaDteReferenciado;
					this.newDte.codigoTipoDteReferencia = optionsRef.listaReferencias[0].codigoTipoDteReferencia;
				}
				//new multiple references invoice generation
				this.newDte.listaReferenciasDteEmitido = optionsRef.listaReferencias;
			} else if (!optionsRef.haveDteReference) {
				this.newDte.razonReferencia = undefined;
				this.newDte.folioReferencia = undefined;
				this.newDte.fechaReferencia = undefined;
				this.newDte.codigoTipoDteReferencia = undefined;
				this.newDte.rutEmisor = undefined;
			}
			//pass global reference to list of guide references
			if (optionsRef.globalReferenceForGuideRefs != this.currentGlobaleCheckGuidRefs && optionsRef.globalReferenceForGuideRefs != undefined) {
				this.currentGlobaleCheckGuidRefs = optionsRef.globalReferenceForGuideRefs;
				if (optionsRef.globalReferenceForGuideRefs && this.selectedGuides && this.selectedGuides.length > 0) {
					this.guideToGroupedReferenceItem(this.selectedGuides);
				} else if (!optionsRef.globalReferenceForGuideRefs && this.selectedGuides && this.selectedGuides.length > 0) {
					this.processGuideToReferences(this.selectedGuides);
				}
			}
		});
		const totalesDteSubscription = this.totalesDteObservable$.subscribe((totales) => {
			if (totales) {
				this.newDte.neto = totales.netoDte;
				this.newDte.exento = totales.exentoDte;
				this.newDte.iva = totales.ivaDte;
				this.newDte.total = totales.totalDte;
				this.newDte.tipoDteId = totales.tipoDteId;
				this.newDte.codigoTipoDte = totales.codigoTipoDte;
				this.sendTipoFolio(totales.codigoTipoDte);
				if (this.newDte.codigoTipoDte == 33 || this.newDte.codigoTipoDte == 34) {
					this.newDte.indicadorServicio = IndicadorServicio.BoletasVentaYServicios;
					this.showInGuide = false;
				}
				if (this.newDte.codigoTipoDte == 33 || this.newDte.codigoTipoDte == 43) {
					this.newDte.tieneIvaTerceros = totales.TieneIvaTerceros;
					this.newDte.ivaTerceros = totales.ivaTerceros;
					this.newDte.ivaPropio = totales.ivaPropio;
				}
				this.newDte.descuentoGlobal = +totales.descuentoGlobal;
				let dateMoment = moment(totales.fechaEmision, "DD/MM/YYYY", true);
				this.newDte.fecha = dateMoment.toDate();
				let objFormato = totales.formato;
				this.newDte.formato = totales.formato ? totales.formato.size : 0;
				this.newDte.diasVencimiento = totales.diasVencimiento;
				this.newDte.formaPagoEnum = totales.formaPago;
				this.newDte.FormaPagoExportacionEnum = totales.formaPagoExportacion;
				this.newDte.tipoMoneda = totales.otraMonedaEnt ? totales.otraMonedaEnt.tipoMoneda : "CLP";
				this.newDte.otraMonedaEnt = totales.otraMonedaEnt;
				this.newDte.DescuentoRecargoEnts = totales.descuentoRecargoEnt ? [totales.descuentoRecargoEnt] : undefined;
				this.newDte.impuestos = totales.montoImpuestos ? totales.montoImpuestos : undefined;
				//this.newDte.comisionCargoDte = totales.comisionCargos
				if (this.newDte instanceof FacturaCompra) {
					this.newDte.retencion = totales.retencionIva;
				}
				if (this.newDte.guiaDespacho) {
					this.newDte.guiaDespacho.tipoTraslado = totales.tipoTraslado;
					this.newDte.guiaDespacho.tipoDespacho = totales.tipoDespacho;
				}
				if (this.newDte.codigoTipoDte == 43) {
					this.newDte.comisionCargo = totales.totalComisionCargos + totales.exentoComisionCargos + totales.netoComisionCargos + totales.ivaComisionCargos;
					this.newDte.tipoMoneda = "CLP";
				}
			}
		});
		const divisasSubscription = this.dashboard.getDailyDivisas().subscribe((divisas) => {
			this.listDivisas = divisas.data;
			this.facturador.updateDivisas(this.listDivisas);
		});

		const invalidSizeSub = this.invalidSize$.subscribe((isInvalid) => {
			this.invalidSize = isInvalid;
		});
		const buttonActionSubscription = this.buttonAction$.pipe(takeUntil(this.destroySubscriptions$)).subscribe((action) => {
			if (action == InvoicingButton.Emitir) {
				this.consolidateDte(true);
			}
			if (action == InvoicingButton.Previsualizar) {
				this.consolidateDte(false);
			}
			if (action == InvoicingButton.Cancelar) {
				this.returnToMaintainer();
			}
			if (action == InvoicingButton.FacturarGuias) {
				this.consultaGuiasFacturablesCliente();
			}
		});

		this.subscriptions.push(detallesSubscription);
		this.subscriptions.push(clienteSubscription);
		this.subscriptions.push(optionsRefSubscription);
		this.subscriptions.push(totalesDteSubscription);
		this.subscriptions.push(divisasSubscription);
		this.subscriptions.push(comisionesSubscription);
		this.subscriptions.push(invalidSizeSub);
		this.subscriptions.push(buttonActionSubscription);
		this.getStateFromRoute();
		this.getDocTypeParamFromStateRoute();
	}

	getFormatSizes(emisorId, tipoDteId) {
		this.facturador.getFormatSizeItems(emisorId, tipoDteId).subscribe(
			(result) => {
				this.facturador.updateFormatoDte(result.data);
			},
			(error) => {
				console.log(error);
				Swal.fire({
					icon: "error",
					title: "No tiene habilitado este documento",
					text: "Es posible que no tenga la aprobación del SII aún. Contacte con soporte. Error 55.110.12",
					showConfirmButton: true,
				}).then(() => {
					this.returnToMaintainer();
				});
				//Swal.fire({icon:'error',title:'Error en plantilla',text:'No se obtuvo el registro de plantilla para el tipo de documento a emitir'})
			}
		);
	}
	ngAfterContentInit() {
		setTimeout(() => {
			this.subheaderService.setTitle("");
			this.subheaderService.setBreadcrumbs([]);
		}, 0.1);
	}

	addClientToInvoiceRequest(client: any) {
		this.dteInvoice.rutReceptor = client.rutString;
		this.newDte.razonSocial = client.razonSocial;

		this.receptor = client;
	}
	consolidateDte(sender: boolean) {
		if (this.newDte.detalleDte == null || (this.newDte.detalleDte.length <= 1 && this.newDte.detalleDte.some((detalle) => detalle.cantidad == 0 || detalle.precioUnitario == 0))) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Detalles vacios";
			this.currentSwal.text = "No se ha ingresado ningun detalle para la emisión del documento.";
			this.currentSwal.fire();
			return;
		}
		if (this.showInSettlement) {
			this.facturador.clearUnfilledComisionItems();
		} else {
			this.facturador.clearUnfilledItems();
		}
		//check if every component has send data for the new Dte object
		let usuarioId = (this.tokenService.getUser() as SessionUser).id;
		let sucursalId = this.tokenService.getSubsidiary();
		let emisorId = this.tokenService.getCompany();
		this.newDte.receptorEnt = this.receptor;
		this.newDte.emisorId = emisorId;

		this.newDte.detalleDte.forEach((detalle) => {
			detalle.nroItem = this.newDte.detalleDte.indexOf(detalle) + 1;
			detalle.totalImpuestosAdicionales = !detalle.esExento ? Math.round(detalle.totalImpuestosAdicionales) : 0;
			if (detalle.esExento) detalle.impuestosAdicionales = [];
			if (detalle.impuestosAdicionales && detalle.impuestosAdicionales.length > 0 && detalle.totalImpuestosAdicionales > 0) detalle.impuestosAdicionales.forEach((imp) => (imp.valor = Math.round(imp.valor)));
			// if(this.listElementsWithTax){
			//   detalle = JSON.parse(JSON.stringify({...this.setDetailWithoutTax(detalle)}));
			// }
		});
		if (this.showInSettlement) {
			this.newDte.comisionesCargosDte.forEach((detalle) => {
				detalle.nroItem = this.newDte.comisionesCargosDte.indexOf(detalle) + 1;
				// if(this.listElementsWithTax){

				//   detalle = JSON.parse(JSON.stringify({...this.setDetailWithoutTax(detalle)}));
				// }
			});
		}

		if (this.currentGrupoFolio == undefined) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Sin registro de folios";
			this.currentSwal.text = "No hay registro de folios disponibles para emitir el tipo de documento actual";
			this.currentSwal.fire();
			return;
		}
		if (this.currentGrupoFolio && this.currentGrupoFolio.codigoSii == this.newDte.codigoTipoDte && this.currentGrupoFolio.suma == 0) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Folios insuficientes";
			this.currentSwal.text = "No hay folios disponibles para emitir el tipo de documento " + this.currentGrupoFolio.nombre;
			this.currentSwal.fire();
			return;
		}
		if (!this.newDte.tipoDteId) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Omisión de Datos";
			this.currentSwal.text = "No se ha seleccionado el tipo de documento a generar";
			this.currentSwal.fire();
			return;
		}
		if (this.newDte.otraMonedaEnt && this.newDte.otraMonedaEnt.tipoMoneda == "OTHER" && this.newDte.otraMonedaEnt.tipoCambio == 0) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en valor de moneda";
			this.currentSwal.text = "El valor de moneda seleccionado debe ser superior a cero";
			this.currentSwal.fire();
			return;
		}
		if (this.getTotalOfDetails(this.newDte) <= 0) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Montos";
			this.currentSwal.text = "El total de detalles indicado no puede ser menor a cero.";
			this.currentSwal.fire();
			return;
		}
		if (this.someNullDetail(this.newDte)) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Montos";
			this.currentSwal.text = "Los elementos de detalle indicados no pueden tener valores menores a cero";
			this.currentSwal.fire();
			return;
		}
		if (this.newDte.neto <= 5 && this.newDte.exento <= 5 && this.newDte.total != this.newDte.exento && this.newDte.descuentoGlobal == 0) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Montos";
			this.currentSwal.text = "El neto total del documento debe ser mayor a $5";
			this.currentSwal.fire();
			return;
		}
		if (this.newDte.formato == 0) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Formato";
			this.currentSwal.text = "No ha asignado una plantilla para el tipo de documento seleccionado o el formato a utilizar considera 0 lineas de detalle";
			this.currentSwal.fire();
		}
		if (this.invalidSize) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Detalles";
			this.currentSwal.text = "Los elementos de detalle son mayores a los permitidos en el formato del documento. La cantidad máxima de líneas de detalle permitidas en la lista según el formato actualmente seleccionado es de " + this.newDte.formato + " y se están utilizando " + this.facturador.currentSize + " líneas de detalle.";
			this.currentSwal.fire();
			return;
		}
		if ((this.invoiceType != InvoiceType.Boleta && (this.newDte.rutReceptor == null || this.newDte.rutReceptor == undefined || this.newDte.rutReceptor == "" || this.newDte.rutReceptor == "66666666"))) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Cliente";
			this.currentSwal.text = "No se ha asignado correctamente el cliente asociado al documento, por favor intente nuevamente";
			this.currentSwal.fire();
			return;
		}
		if (!this.newDte.tipoMoneda) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Divisa";
			this.currentSwal.text = "No se ha asignado correctamente la divisa asociada al documento, por favor intente nuevamente";
			this.currentSwal.fire();
			return;
		}
		if (this.newDte.descuentoGlobal > this.getTotalOfDetails(this.newDte)) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en descuento";
			this.currentSwal.text = "El descuento ingresado es mayor al monto total de detalles del documento";
			this.currentSwal.fire();
			return;
		}
		if (this.newDte.indicadorMontosNetos && this.newDte.impuestos > 0) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en Montos con Impuestos";
			this.currentSwal.text = "Si los productos ingresados tienen impuestos especificos, no puede indicarse que sean productos con impuestos incluidos, solamente pueden ser a valor neto";
		}
		if (this.invoiceType == InvoiceType.GuiaDespacho) {
			if (this.newDte.guiaDespacho.rutChofer == 0 || this.newDte.guiaDespacho.rutChofer == undefined || this.newDte.guiaDespacho.rutTransporte == 0 || this.newDte.guiaDespacho.rutTransporte == 0 || this.newDte.guiaDespacho.nombreChofer == undefined || this.newDte.guiaDespacho.nombreChofer == undefined || this.newDte.guiaDespacho.patente == "" || this.newDte.guiaDespacho.patente == undefined) {
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Datos de Transporte Incompletos";
				this.currentSwal.text = "Ingrese todos los datos correspondientes al modulo de transporte";
				this.currentSwal.fire();
				return;
			}
		}
		if (this.invoiceType != InvoiceType.GuiaDespacho) this.newDte.guiaDespacho = null;

		this.cleanEmptyDetails();
		// if(this.listElementsWithTax){
		//   this.newDte.detalleDte = this.newDte.detalleDte.map(detalle =>{
		//     return detalle = this.setDetailWithoutTax(detalle);
		//   })
		// }
		if (sender) {
			switch (this.invoiceType) {
				case InvoiceType.Boleta: {
					this.sendDteBoleta(usuarioId, sucursalId, this.newDte);
					break;
				}
				case InvoiceType.Factura: {
					this.sendDte(usuarioId, sucursalId, this.newDte);
					break;
				}
				case InvoiceType.GuiaDespacho: {
					this.sendDteGuide(usuarioId, sucursalId, this.newDte);
					break;
				}
			}
		} else {
			this.previzualiseDte(usuarioId, sucursalId, this.newDte);
		}
	}

	sendDte(usuarioId: string, sucursalId: string, dteToSend: IDte) {
		this.loading = true;
		this.scrollToTop();
		if (this.newDte.listaReferenciasDteEmitido) {
			this.checkAndAddGuideReferences();
		}
		this.facturador.sendDte(usuarioId, sucursalId, dteToSend).subscribe(
			(result) => {
				this.loading = false;
				this.responseResult = result.data;
				this.getPdfOfInvoice(this.responseResult, this.invoiceType);

				this.createNewProductsFromMarkedItems(this.newDte.detalleDte, this.newDte.emisorId);
				this.ref.detectChanges();
				this.currentSwal.title = "Documento Emitido";
				this.currentSwal.text = "El documento se ha emitido correctamente";
				this.currentSwal.icon = "success";
				this.currentSwal.fire();
				this.newDte = new DteEmitido();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir el documento, intente nuevamente o contacte a su administrador " + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}
	sendDteGuide(usuarioId: string, sucursalId: string, dteToSend: IDte) {
		this.loading = true;
		this.scrollToTop();
		this.facturador.sendDteGuide(usuarioId, sucursalId, dteToSend).subscribe(
			(result) => {
				this.loading = false;
				this.responseResult = result.data;
				this.getPdfOfInvoice(this.responseResult, this.invoiceType);

				this.createNewProductsFromMarkedItems(this.newDte.detalleDte, this.newDte.emisorId);
				this.ref.detectChanges();
				this.currentSwal.title = "Documento Emitido";
				this.currentSwal.text = "El documento se ha emitido correctamente";
				this.currentSwal.icon = "success";
				this.currentSwal.fire();
				this.newDte = new DteEmitido();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir el documento, intente nuevamente o contacte a su administrador " + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}
	sendDteSettlement(usuarioId: string, sucursalId: string, dteToSend: IDte) {
		this.loading = true;
		this.scrollToTop();
		dteToSend.documentosLiquidados = [];
		dteToSend.detalleDte.forEach((detail) =>
			detail.relatedDocumentsIds.forEach((id) => {
				dteToSend.documentosLiquidados.push(id);
			})
		);
		this.facturador.sendDteSettlement(usuarioId, sucursalId, dteToSend).subscribe(
			(result) => {
				this.loading = false;
				this.responseResult = result.data;
				this.getPdfOfInvoice(this.responseResult, this.invoiceType);

				this.createNewProductsFromMarkedItems(this.newDte.detalleDte, this.newDte.emisorId);
				this.newDte = new DteEmitido();
				this.ref.detectChanges();
				this.currentSwal.title = "Documento Emitido";
				this.currentSwal.text = "El documento se ha emitido correctamente";
				this.currentSwal.icon = "success";
				this.currentSwal.fire();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir el documento, intente nuevamente o contacte a su administrador " + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}
	sendDteBoleta(usuarioId: string, sucursalId: string, dteToSend: IDte) {
		this.loading = true;
		this.scrollToTop();
		this.facturador.sendDteBoleta(usuarioId, sucursalId, dteToSend).subscribe(
			(result) => {
				this.loading = false;
				this.responseResult = result.data;
				this.ref.detectChanges();
				this.currentSwal.title = "Boleta Emitida";
				this.currentSwal.text = "La boleta se ha emitido correctamente";
				this.currentSwal.icon = "success";
				this.currentSwal.fire();
				this.getPdfOfInvoice(this.responseResult, this.invoiceType);

				this.createNewProductsFromMarkedItems(this.newDte.detalleDte, this.newDte.emisorId);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir la boleta, intente nuevamente o contacte a su administrador " + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}

	sendFacturaCompra(usuarioId: string, sucursalId: string, dteToSend: IDte) {
		this.loading = true;
		this.scrollToTop();
		//console.log(dteToSend instanceof FacturaCompra,dteToSend);
		this.facturador.sendFacturaCompraRequest(usuarioId, sucursalId, dteToSend).subscribe(
			(result) => {
				this.loading = false;
				this.responseResult = result.data;
				this.ref.detectChanges();
				this.currentSwal.title = "Factura de Compra Emitida";
				this.currentSwal.text = "La factura de compra se ha emitido correctamente";
				this.currentSwal.icon = "success";
				this.currentSwal.fire();
				this.getPdfOfInvoice(this.responseResult, this.invoiceType);

				this.createNewProductsFromMarkedItems(this.newDte.detalleDte, this.newDte.emisorId);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir la factura de compra, intente nuevamente o contacte a su administrador " + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}

	previzualiseDte(usuarioId: string, sucursalId: string, dteToSend: IDte) {
		this.loading = true;
		this.scrollToTop();
		this.facturador.previewDte(usuarioId, sucursalId, dteToSend).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				let pdfBlob = this.utilities.convertB64ToBlob(result.data, "application/pdf");
				let pdfUrl = URL.createObjectURL(pdfBlob);
				this.popupCenter(pdfUrl, "Muestra de Documento", 600, 800);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir el documento, intente nuevamente o contacte a su administrador " + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}

	getTotalOfDetails(dte: IDte) {
		let sumOfDetailsNeto: number = 0;
		let sumOfDetailsBruto: number = 0;
		let sumOfDetailsExento: number = 0;
		let globalDiscount: number = 0;
		globalDiscount = dte.descuentoGlobal;
		if (this.listElementsWithTax) {
			//resumme if tax are included in items so it needs to be backward calculated
			if (dte.impuestos > 0) {
				// if items dont contains specific taxes
				dte.detalleDte.forEach((detalle) => {
					if (!detalle.esExento) {
						let sumTaxRates = 0;
						if (detalle.impuestosAdicionales && detalle.impuestosAdicionales.length > 0) {
							sumTaxRates = detalle.impuestosAdicionales.reduce((sumTaxRates, tax) => sumTaxRates + tax.tasa, 0);
						}

						sumOfDetailsNeto += Math.round(detalle.total / (1 + sumTaxRates / 100 + this.porcentajeIva));
						//console.log("neto segun detalle", detalle.total, sumTaxRates, sumOfDetailsNeto);
					} else {
						sumOfDetailsExento += detalle.total;
					}
				});
			} else {
				//if items only contains mainTax (IVA)
				dte.detalleDte.forEach((detalle) => {
					if (!detalle.esExento) {
						sumOfDetailsBruto += detalle.total;
					} else {
						sumOfDetailsExento += detalle.total;
					}
				});
				if (this.invoiceType == InvoiceType.Boleta) {
					sumOfDetailsNeto = sumOfDetailsBruto > 0 ? (sumOfDetailsBruto - globalDiscount) / (1 + this.porcentajeIva) : 0;
					// console.log(sumOfDetailsBruto, globalDiscount, sumOfDetailsBruto - globalDiscount, sumOfDetailsNeto);
				} else {
					sumOfDetailsNeto = sumOfDetailsBruto / (1 + this.porcentajeIva);
				}
			}
		} else {
			//resumme if tax are excluded in items so they are simple calculated
			dte.detalleDte.forEach((detalle) => {
				if (!detalle.esExento) {
					sumOfDetailsNeto += detalle.total;
				} else {
					sumOfDetailsExento += detalle.total;
				}
			});
		}
		//console.log("comprobacion",sumOfDetailsNeto,sumOfDetailsExento,globalDiscount)
		let total = 0;
		if (this.invoiceType == InvoiceType.Boleta && dte.codigoTipoDte != 41) {
			total = Math.round(sumOfDetailsNeto) + sumOfDetailsExento - (dte.impuestos > 0 ? Math.round(globalDiscount / (1 + this.porcentajeIva)) : globalDiscount);
		} else if (dte.codigoTipoDte == 34 || dte.codigoTipoDte == 41) {
			total = Math.round(sumOfDetailsNeto) + sumOfDetailsExento - globalDiscount;
		} else {
			total = Math.round(sumOfDetailsNeto) + sumOfDetailsExento - globalDiscount;
		}
		return total;
	}

	someNullDetail(dte: IDte) {
		return dte.detalleDte.some((detalle) => {
			detalle.total == 0 || detalle.cantidad == 0 || detalle.precioUnitario == 0;
		});
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const newWindow = window.open(
			url,
			title,
			`
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}

	ngOnDestroy() {
		this.facturador.resetElements();
		this.destroySubscriptions$.next(true);
		this.destroySubscriptions$.unsubscribe();
		this.facturador.referencesListFromGuidesSubject.next([]);
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	cleanEmptyDetails() {
		this.newDte.detalleDte.forEach((detail) => {
			if (!detail.cantidad || detail.cantidad <= 0) {
				this.newDte.detalleDte = this.newDte.detalleDte.splice(this.newDte.detalleDte.indexOf(detail), 1);
			}
		});
	}

	reloadCurrentRoute() {
		let currentUrl = this.router.url;
		if (this.invoiceType == InvoiceType.GuiaDespacho) {
			this.router.navigateByUrl("/guias-despacho");
		} else {
			this.router.navigateByUrl("/documentos-emitidos");
		}
	}

	AfterInvoicing() {
		this.createNewProductsFromMarkedItems(this.newDte.detalleDte, this.newDte.emisorId);
	}

	scrollToTop() {
		window.scroll(0, 0);
	}

	createNewProductsFromMarkedItems(itemList: DetalleDte[], currentEmisorId: string) {
		let list = [...itemList];
		let emisorId = JSON.parse(JSON.stringify(currentEmisorId));
		let productsToCreate = list.filter((item) => item.createCurrentProduct == true);
		let requestList = [];
		if (!productsToCreate || productsToCreate.length <= 0) {
			this.facturador.resetElements();
			this.reloadCurrentRoute();
		} else {
			productsToCreate.forEach((item) => {
				let req = this.productService.sendProductRequestFromDetalleDte(item, emisorId);
				requestList.push(req);
			});
			let zippedProductRequests = zip(...requestList);
			this.loading = true;
			let zipsub = zippedProductRequests
				.subscribe(
					(zippedResult) => {
						let message = "<p>Los siguientes productos han sido ingresados: <p>";
						message += "<ul>";
						zippedResult.forEach((result, i) => {
							message += "<li>" + (result as Response<Producto>).data + ": " + productsToCreate[i].nombre + "</li>";
						});
						message += "</ul>";
						Swal.fire({
							icon: "success",
							title: "Productos Agregados",
							html: message,
						});
						this.loading = false;
						this.reloadCurrentRoute();
					},
					(error) => {
						this.loading = false;
						let errorMessage = "No fue posible ingresar los productos";
						Swal.fire({
							icon: "error",
							title: "Ingreso de Productos",
							text: errorMessage,
						});
						this.reloadCurrentRoute();
					}
				)
				.add(() => {
					this.loading = false;
					this.newDte = new DteEmitido();
					this.facturador.resetElements();
					this.ref.detectChanges();
				});
		}
	}

	setDetailWithoutTax(externalDetail) {
		let detail = JSON.parse(JSON.stringify(externalDetail)) as DetalleDte;
		detail.precioUnitario = detail.precioUnitario / (1 + this.porcentajeIva);
		detail.recargo = detail.recargo / (1 + this.porcentajeIva);
		detail.descuento = detail.descuento / (1 + this.porcentajeIva);
		detail.subtotal = detail.precioUnitario * detail.cantidad - detail.descuento + detail.recargo;
		detail.total = detail.subtotal;
		return detail;
	}

	getStateFromRoute() {
		//obtain the state object from current route
		this.invoiceTypeByRoute = this.activatedRoute.paramMap.pipe(map(() => window.history.state));
	}

	getDocTypeParamFromStateRoute() {
		this.loading = true;
		this.invoiceTypeByRoute.subscribe((result) => {
			let value: number = result.invoiceType;
			this.loading = false;
			this.modificationsByInvoiceType(result.invoiceType);
			this.facturador.setCurrentInvoiceType(result.invoiceType);
			this.invoiceType = result["invoiceType"];

			let tipoDTE = new TipoDte();
			switch (this.invoiceType) {
				case InvoiceType.Factura:
					tipoDTE = this.tipoDteSource.find((x) => x.codigosii == 33);
					break;
				case InvoiceType.Boleta:
					tipoDTE = this.tipoDteSource.find((x) => x.codigosii == 39);
					break;
				case InvoiceType.GuiaDespacho:
					tipoDTE = this.tipoDteSource.find((x) => x.codigosii == 52);
					break;
			}

			this.getFormatSizes(this.tokenService.getCompany(), tipoDTE.tipoDteId);

			// if (this.invoiceType == InvoiceType.GuiaDespacho) this.showInGuide = true;
			// else this.showInGuide = false;
			// if (this.invoiceType == InvoiceType.LiquidacionFactura) {
			//   this.dontShowInSettlement = true;
			//   this.showInSettlement = true;
			// }
			// else this.dontShowInSettlement = false;
		});
	}

	modificationsByInvoiceType(type: InvoiceType) {
		switch (type) {
			case InvoiceType.Boleta: {
				//console.log(InvoiceType[type])
				break;
			}
			case InvoiceType.GuiaDespacho: {
				this.showInGuide = true;
				break;
			}
		}
	}

	returnToMaintainer() {
		let returnTo = "/";
		returnTo = this.setReturnUrl();
		this.router.navigateByUrl(returnTo);
	}

	setReturnUrl() {
		switch (this.invoiceType) {
			case InvoiceType.GuiaDespacho: {
				return "guias-despacho";
			}
			default: {
				return "documentos-emitidos";
			}
		}
	}

	onSelect({ selected }) {
		this.selectedGuides.splice(0, this.selectedGuides.length);
		this.selectedGuides.push(...selected);
	}

	selectAll(event) {
		if (!this.selectedAllItemsInPage[0]) {
			if (this.selectedGuides.length > 0) {
				this.listaGuiasFacturables.map((dte) => {
					this.selectedGuides = this.selectedGuides.filter((selectedDte) => selectedDte.dteId !== dte.dteId);
				});
			}
			this.selectedGuides.push(...this.listaGuiasFacturables);
			this.selectedGuides = [...this.selectedGuides];
			this.selectedAllItemsInPage[0] = true;
		} else {
			this.listaGuiasFacturables.map((dte) => {
				this.selectedGuides = this.selectedGuides.filter((selectedDte) => selectedDte.dteId !== dte.dteId);
			});
			this.selectedAllItemsInPage[0] = false;
		}
	}
	consultaGuiasFacturablesCliente() {
		let usuarioId = (this.tokenService.getUser() as SessionUser).id;
		let emisorId = this.tokenService.getCompany();
		this.loading = true;
		if (this.facturador.selectedClient == undefined) {
			Swal.fire({
				icon: "error",
				title: "Error al consultar guias de despacho",
				text: "No ha seleccionado un cliente para consultar sus guias de despacho disponibles para facturar",
			});
			return;
		}
		this.facturador.checkForBillableGuides(emisorId, this.facturador.selectedClient.receptorId, usuarioId).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				if (result.data.length > 0) {
					this.listaGuiasFacturables = result.data;
					this.showGuideListInModal(this.listaGuiasModalRef);
				} else {
					Swal.fire({
						icon: "info",
						title: "Guias de Despacho Facturables",
						text: "No hay guias de despacho facturables para el cliente seleccionado",
					});
				}
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en Guias de Despacho",
					text: "Error al obtener guias de despacho facturables para el cliente seleccionado",
				});
			}
		);
	}

	showGuideListInModal(modalRef) {
		this.selectedGuides = [];
		this.guiasModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	processGuidesIntoInvoice() {
		this.guiasModal.close();
		//get references
		this.processGuideToReferences(this.selectedGuides);

		//get details
		this.processGuideToDetails(this.selectedGuides);
	}

	processGuideToReferences(list: DteEmitido[]) {
		if (list.length <= 40) {
			this.guideToIndividualReferencesList(list);
		} else {
			this.guideToGroupedReferenceItem(list);
		}
	}

	processGuideToDetails(list: DteEmitido[]) {
		if (list.length <= 40) {
			this.guidesToIndividualDetailList(list);
		} else {
			this.guidesToGroupedDetailList(list);
		}
	}

	guideToIndividualReferencesList(list: DteEmitido[]) {
		let referencesList: ReferenciaDteEmitido[] = [];
		list.forEach((dte) => {
			referencesList.push(this.guideToRef(dte));
		});

		this.facturador.referencesListFromGuidesSubject.next(referencesList);
	}

	guideToRef(item: DteEmitido): ReferenciaDteEmitido {
		let ref = new ReferenciaDteEmitido();
		ref.codigoTipoDteReferencia = item.codigoTipoDte.toString();
		ref.folioReferencia = item.folio.toString();
		ref.fechaDteReferenciado = item.fecha;
		ref.razonReferencia = "Guia de despacho " + ref.folioReferencia;
		return ref;
	}

	guideToGroupedReferenceItem(list: DteEmitido[]) {
		let ref = new ReferenciaDteEmitido();
		ref.codigoTipoDteReferencia = list[0].codigoTipoDte.toString();
		ref.fechaDteReferenciado = new Date();
		ref.razonReferencia = "FACTURACIÓN DE GUÍAS GLOBAL";
		ref.folioReferencia = "";
		list.forEach((item) => {
			ref.folioReferencia += item.folio.toString() + ", ";
		});
		this.facturador.referencesListFromGuidesSubject.next([ref]);
	}

	guidesToIndividualDetailList(list: DteEmitido[]) {
		let detailList: DetalleDte[] = [];
		list.forEach((g) => {
			g.detalleDte.forEach((d) => {
				this.adjustPrecioUnitarioRelatedToTotalItem(d);
				detailList.push(d);
			});
		});
		this.facturador.detailsListFromGuidesSubject.next(detailList);
	}

	adjustPrecioUnitarioRelatedToTotalItem(detalle: DetalleDte) {
		//if previoUNitario is slightly lower than total then adjust it, happen when getting details from guides
		if (detalle.precioUnidadMedida * detalle.cantidad < detalle.total && detalle.cantidad == 1) {
			detalle.precioUnitario = detalle.total;
		}
	}

	guidesToGroupedDetailList(list: DteEmitido[]) {
		let detailList: DetalleDte[] = [];
		list.forEach((g) => {
			if (g.detalleDte.some((d) => d.esExento)) {
				let exentosDetails = g.detalleDte.filter((d) => d.esExento);
				let afectoDetails = g.detalleDte.filter((d) => !d.esExento);
				detailList.push(this.guideDetailsToGroupedDetailItem(exentosDetails, g));
				detailList.push(this.guideDetailsToGroupedDetailItem(afectoDetails, g));
			} else {
				detailList.push(this.guideToGroupedDetailItem(g));
			}
		});
		this.facturador.detailsListFromGuidesSubject.next(detailList);
	}

	guideToGroupedDetailItem(guide: DteEmitido): DetalleDte {
		let detalle = new DetalleDte();
		detalle.dteId = guide.detalleDte[0].dteId;
		detalle.descripcion = "Guía Despacho Folio: " + guide.folio;
		detalle.cantidad = 1;
		detalle.precioUnitario = guide.neto;
		detalle.total = guide.neto;
		detalle.esExento = false;
		return detalle;
	}

	guideDetailsToGroupedDetailItem(details: DetalleDte[], guide: DteEmitido): DetalleDte {
		let detalle = new DetalleDte();
		detalle.dteId = details[0].dteId;
		details.forEach((d) => {
			detalle.precioUnitario += d.precioUnitario;
			detalle.total += d.total;
		});
		if (details[0].esExento) {
			detalle.descripcion = "Elementos Exentos Guía Folio: " + guide.folio;
			detalle.esExento = true;
			detalle.cantidad = 1;
		} else {
			detalle.descripcion = "Elementos Afectos Guía Folio: " + guide.folio;
			detalle.esExento = false;
			detalle.cantidad = 1;
		}
		return detalle;
	}

	getPdfOfInvoice(id: string, type: InvoiceType) {
		let req = this.getPdfRequest(id, type);
		req.subscribe(
			(res) => {
				let pdfBlob = this.utilities.convertB64ToBlob(res.data, "application/pdf");
				let pdfUrl = URL.createObjectURL(pdfBlob);
				this.popupCenter(pdfUrl, "Muestra de Documento", 600, 800);
			},
			(error) => {
				this.currentSwal.title = "Error en emisión";
				this.currentSwal.text = "No ha sido posible emitir el documento, intente obteniendo el pdf directamente desde el mantenedor. " + error.error ? this.utilities.setErrorMessageFromArray(error.error) : "";
				this.currentSwal.icon = "error";
				this.currentSwal.fire();
			}
		);
	}

	getPdfRequest(id: string, type: InvoiceType): Observable<Response<any>> {
		let pdfRequest;
		switch (type) {
			default: {
				pdfRequest = this.dteService.getPdf(id);
				return pdfRequest;
			}
		}
	}

	checkAndAddGuideReferences() {
		//if invoice is based in guide as references,add those references in the invoice request
		if (this.newDte.listaReferenciasDteEmitido.every((ref) => ref.codigoTipoDteReferencia == "52") && this.selectedGuides.length > 0) {
			this.newDte.guiasFacturadas = this.selectedGuides.map((guide) => guide.dteId);
		}
	}

	getFoliosDisponibles() {
		this.foliosService.getData(this.currentEmisorId, this.currentSucursalId).subscribe(
			(res) => {
				this.foliosData = res.data;
				this.groupedFolios = this.groupArrayOfObjects(this.foliosData, "tipoDteId");
				//foreach tipoDteId sum foliosDisponibles y vencimiento mas cercano
				for (const tipoDteGroup of Object.entries(this.groupedFolios)) {
					let sumOfDteType = 0;
					if (tipoDteGroup != null && tipoDteGroup.length > 0) {
						(tipoDteGroup[1] as any[]).forEach((currentObj) => {
							if (currentObj["activo"]) {
								sumOfDteType += currentObj["foliosDisponibles"];
							}
						});
					}
					tipoDteGroup["suma"] = sumOfDteType;
					tipoDteGroup["nombre"] = this.getDteTypeName(tipoDteGroup[0]);
					tipoDteGroup["codigoSii"] = tipoDteGroup[1][0]["codigoSii"];
					this.listFolioResumen.push(tipoDteGroup);
					this.ref.detectChanges();
					this.utilities.sortAlphabeth(this.listFolioResumen, "nombre");
				}

				//pass name of DteTypes
				this.informarTipoFolio(this.invoiceType);
			},
			(err) => {}
		);
	}

	groupArrayOfObjects(list, key) {
		return list.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	}

	sendTipoFolio(codigo: number) {
		let grupoFolio = this.listFolioResumen.find((resumenTipo) => resumenTipo.codigoSii == codigo);
		if (grupoFolio) {
			this.facturador.passTipoCantidadFolio([grupoFolio.nombre, grupoFolio.suma]);
		} else {
			this.facturador.passTipoCantidadFolio(grupoFolio);
		}
		this.currentGrupoFolio = grupoFolio;
	}

	getDteTypeName(dteTypeId): string {
		return this.tipoDteSource.find((type) => type.tipoDteId == dteTypeId).nombre;
	}

	informarTipoFolio(type: InvoiceType) {
		if (this.listFolioResumen && this.listFolioResumen.length > 0) {
			switch (type) {
				case InvoiceType.Factura:
					this.sendTipoFolio(33);
					break;
				case InvoiceType.Boleta:
					this.sendTipoFolio(39);
					break;
				case InvoiceType.GuiaDespacho:
					this.sendTipoFolio(52);

					break;
				default:
					break;
			}
		}
	}
}
