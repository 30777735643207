import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PermissionService } from "src/app/services/permission.service";
import { EmisorService } from "src/app/services/emisor.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TipoDte } from "src/app/models/tipoDte";
import { ConfigMantenedorConfiguracionEmision } from "./config-mantenedor-configuracion-emision";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { ConfiguracionEmision } from "src/app/models/ConfiguracionEmision";
import { ConfiguracionEmisionFilter } from "src/app/models/ConfiguracionEmisionFilter";
import { TokenStorageService } from "src/app/services/token-storage.service";

@Component({
   selector: "app-mantenedor-configuracion-emision",
   templateUrl: "./mantenedor-configuracion-emision.component.html",
   styleUrls: ["./mantenedor-configuracion-emision.component.scss"],
})
export class MantenedorConfiguracionEmisionComponent implements OnInit {
   public loading: boolean = false;
   public rowsMantenedor: ConfiguracionEmision[] = [];
   public page: Page = new Page();
   private filter: ConfiguracionEmisionFilter = new ConfiguracionEmisionFilter();
   private addConfigModalRef: NgbModalRef;
   private editConfigModalRef: NgbModalRef;
   private showConfigModalRef: NgbModalRef;
   private asignConfigModalRef: NgbModalRef;
   private addCesionConfigModalRef: NgbModalRef;
   public selectedConfig: ConfiguracionEmision = undefined;
   public emisorId: string;
   private tipoDteSource: TipoDte[] = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
   public configLocal = ConfigMantenedorConfiguracionEmision;

   constructor(public permissions: PermissionService, private EmisorService: EmisorService, private ref: ChangeDetectorRef, private modalService: NgbModal, private tokenService: TokenStorageService, private utilities: UtilitiesService, private localStorage: LocalStorageService) {
      (this.emisorId = this.tokenService.getCompany()), (this.filter.activo = 1);
      this.filter.emisorId = this.emisorId;
   }

   ngOnInit(): void {
      this.setPage({ offset: 0 });
   }
   setPageSize() {
      //this.page.currentPageSize = this.page.page
      this.setPage({ offset: 0 });
   }
   setPage(pageInfo) {
      this.loading = true;
      this.page.pageNumber = pageInfo.offset;
      let localPage = JSON.parse(JSON.stringify(this.page));
      localPage.pageNumber += 1;
      this.EmisorService.getDataConfigByPage(this.filter, localPage).subscribe(
         (result) => {
            this.loading = false;
            let rows = result.data as ConfiguracionEmision[];
            this.rowsMantenedor = [...rows];
            this.rowsMantenedor.forEach((row) => {
               this.setTipoDocumentoToPlantilla(row);
            });
            this.setPageFromResponse(this.page, result);
            this.ref.detectChanges();
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error en Configuración de emision",
               text: "No fue posible obtener la lista de configuración de emisión disponibles, recargue la pagina." + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }

   openAddConfigModal(modalRef) {
      this.addConfigModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }
   closeAddConfigModal() {
      this.addConfigModalRef.close();
   }
   openShowConfigModal(modalRef, row: ConfiguracionEmision) {
      this.selectedConfig = row;
      this.showConfigModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }
   closeShowConfigModal() {
      this.showConfigModalRef.close();
   }
   sendNewConfig(event: ConfiguracionEmision) {
      this.loading = true;
      this.EmisorService.toAssignConfig(event)
         .subscribe(
            (result) => {
               // let rows = JSON.parse(JSON.stringify(this.rowsMantenedor));
               // event.tipoPlantillaId = result.data;
               // rows.push(event);
               this.setPage({ offset: 0 });
               this.loading = false;
               this.ref.detectChanges();
               Swal.fire({
                  icon: "success",
                  title: "Configuración Asignada",
                  text: "Se ha asignado exitosamente la configuración del tipo de documento" + event.nombreTipoDte,
               });
            },
            (error) => {
               this.loading = false;
               this.ref.detectChanges();
               if (error.error.data) {
                  Swal.fire({
                     icon: "info",
                     title: "Configuración Existente",
                     text: "Ya existe una configuración para el tipo de documento." + this.utilities.setErrorMessageFromArray(error.error),
                  });
               } else {
                  Swal.fire({
                     icon: "error",
                     title: "Error al Asignar la Configuración",
                     text: "No se ha podido asignar la configuración del tipo de documento " + event.nombreTipoDte + ", intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
                  });
               }
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
            this.closeAddConfigModal();
         });
   }

   openEditConfigModal(modalRef, row: ConfiguracionEmision) {
      this.selectedConfig = row;
      this.editConfigModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   closeEditConfigModal() {
      this.editConfigModalRef.close();
   }

   editConfigRequest(event: ConfiguracionEmision) {
      this.loading = true;
      this.EmisorService.updateConfig(event)
         .subscribe(
            (result) => {
               // let rows = JSON.parse(JSON.stringify(this.rowsMantenedor));
               // event.tipoPlantillaId = result.data;
               // rows.push(event);
               // this.ref.detectChanges();
               // this.rowsMantenedor = JSON.parse(JSON.stringify(rows)) as ConfiguracionEmision[];
               this.setPage({ offset: 0 });
               this.loading = false;
               this.ref.detectChanges();
               Swal.fire({
                  icon: "success",
                  title: "Configuración Actualizada",
                  text: "Se ha actualizado exitosamente la configuración del tipo documento " + event.nombreTipoDte,
               });
            },
            (error) => {
               this.loading = false;
               this.ref.detectChanges();
               Swal.fire({
                  icon: "error",
                  title: "Error al Actualizar Configuración",
                  text: "No se ha podido actualizar la configuración del tipo  documento " + event.nombreTipoDte + ", intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
            this.closeEditConfigModal();
         });
   }

   deactivateConfig(row: ConfiguracionEmision) {
      this.loading = true;
      this.EmisorService.deactivateConfig(row).subscribe(
         (result) => {
            this.loading = false;
            // let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
            // this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
            this.setPage({ offset: 0 });
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Configuración Desactivada",
               text: "La configuración del tipo documento " + row.nombreTipoDte + " fue desactivada exitosamente",
            });
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error al Desactivar la Configuración",
               text: "No fue posible desactivar la configuración del tipo documento " + row.nombreTipoDte + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }
   activateConfig(row: ConfiguracionEmision) {
      this.loading = true;
      this.EmisorService.activateConfig(row).subscribe(
         (result) => {
            this.loading = false;
            // let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
            // this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
            this.setPage({ offset: 0 });
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Configuración Activada",
               text: "La configuración del tipo documento " + row.nombreTipoDte + " fue activada exitosamente",
            });
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error al Activar la Configuración",
               text: "No fue posible activar la configuración del tipo documento " + row.nombreTipoDte + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }

   setPageFromResponse(currentPage: Page, data: PagedResponse<ConfiguracionEmision>) {
      currentPage.pageSize = data.pageSize;
      currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
      currentPage.totalPages = data.totalPages;
      currentPage.totalElements = data.totalRecords;
   }

   popupCenter(url, title, w, h) {
      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      const top = (height - h) / 2 / systemZoom + dualScreenTop;
      const newWindow = window.open(
         url,
         title,
         `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
      );
      if (window.focus) {
         newWindow.focus();
      }
   }

   setTipoDocumentoToPlantilla(row: ConfiguracionEmision) {
      let tipo = this.tipoDteSource.find((tipo) => tipo.tipoDteId == row.tipoDteId);
      row.nombreTipoDte = tipo.nombre;
   }

   searchElementsByFilter(event) {
      // console.log(event);
      //this.filter[event.criterio] = event.busqueda;
      this.filter.tipoDteId = event.tipoDteId;
      this.filter.activo = event.activo;
      this.setPage({ offset: 0 });
   }

   exportToExcel() {
      this.getAllRowsByFilter(this.page, this.filter);
   }
   getAllRowsByFilter(currentPageRef: Page, currentFilter: ConfiguracionEmisionFilter) {
      let fullPage: Page = new Page();
      fullPage.pageSize = currentPageRef.totalElements;
      fullPage.pageNumber = 1;
      fullPage.totalElements = currentPageRef.totalElements;
      fullPage.totalPages = 1;
      this.EmisorService.getDataConfigByPage(currentFilter, fullPage).subscribe(
         (result) => {
            let elements = result.data as ConfiguracionEmision[];
            this.ref.detectChanges();
            elements.forEach((row) => {
               this.setTipoDocumentoToPlantilla(row);
            });
            this.loading = false;
            this.exportExcelData(elements);
         },
         (error) => {
            this.loading = false;
            let msg = "";
            if (error.error.message) {
               msg += error.error.message;
            }
            Swal.fire({
               icon: "error",
               title: "Error al exportar",
               text: "No es posible exportar los datos. Intente mas tarde. " + msg,
            });
         },
         () => {
            this.loading = false;
         }
      );
   }
   exportExcelData(arrayToExport: Array<ConfiguracionEmision>): void {
      let arregloExportar = [];
      arrayToExport.forEach((row) => {
         let elementToExport = {};
         elementToExport["Tipo DTE"] = row.nombreTipoDte;
         elementToExport["Codigo DTE"] = row.codigoTipoDte;
         elementToExport["Max Lineas"] = row.maxLineasDetalle;
         arregloExportar.push(elementToExport);
      });
      this.utilities.exportAsExcelFile(arregloExportar, "configuracionEmision");
   }
}
