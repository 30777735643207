import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg";
import { PagesRoutingModule } from "./pages-routing.module";
import { NgbDropdownModule, NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslationModule } from "../modules/i18n/translation.module";
import { LayoutComponent } from "./_layout/layout.component";
import { ScriptsInitComponent } from "./_layout/init/scipts-init/scripts-init.component";
import { HeaderMobileComponent } from "./_layout/components/header-mobile/header-mobile.component";
import { AsideComponent } from "./_layout/components/aside/aside.component";
import { FooterComponent } from "./_layout/components/footer/footer.component";
import { HeaderComponent } from "./_layout/components/header/header.component";
import { HeaderMenuComponent } from "./_layout/components/header/header-menu/header-menu.component";
import { TopbarComponent } from "./_layout/components/topbar/topbar.component";
import { ExtrasModule } from "../_metronic/partials/layout/extras/extras.module";
import { LanguageSelectorComponent } from "./_layout/components/topbar/language-selector/language-selector.component";
import { CoreModule } from "../_metronic/core";
import { SubheaderModule } from "../_metronic/partials/layout/subheader/subheader.module";
import { AsideDynamicComponent } from "./_layout/components/aside-dynamic/aside-dynamic.component";
import { HeaderMenuDynamicComponent } from "./_layout/components/header/header-menu-dynamic/header-menu-dynamic.component";
import { ChangeCompanyComponent } from "../components/layout/change-company/change-company.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CompanyAmbientComponent } from "../components/layout/company-ambient/company-ambient.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
   declarations: [LayoutComponent, ScriptsInitComponent, HeaderMobileComponent, AsideComponent, FooterComponent, HeaderComponent, HeaderMenuComponent, TopbarComponent, LanguageSelectorComponent, AsideDynamicComponent, HeaderMenuDynamicComponent, ChangeCompanyComponent, CompanyAmbientComponent],
   imports: [CommonModule, PagesRoutingModule, TranslationModule, InlineSVGModule, ExtrasModule, NgbDropdownModule, NgbProgressbarModule, CoreModule, SubheaderModule, NgSelectModule, FormsModule, SweetAlert2Module.forRoot(), FontAwesomeModule],
})
export class LayoutModule {}
