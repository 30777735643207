export class OtraMoneda {
   tipoMoneda: string;
   tipoCambio: number;
   montoNeto: number;
   montoExento: number;
   iva: number;
   imptoEspecifico: number;
   montoTotal: number;
   montoClausulaVenta: number;
   flete: number;
   seguro: number;
   tipoMonedaAduana: string;
   codigoMonedaAduana: number;
   montoTipoMoneda: number;
}
