export class TotalDte {
   public subTotal: number;
   public recargo: number;
   public descuento: number;
   public neto: number;
   public afecto: number;
   public exento: number;
   public iva: number;
   public total: number;
}
