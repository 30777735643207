import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MantenedorCertificadosComponent } from "src/app/components/configuracion-dte/mantenedor-certificados/mantenedor-certificados.component";
import { FacturadorMasivoComponent } from "src/app/components/facturacion/facturador-masivo/facturador-masivo.component";
import { MantenedorClientesComponent } from "../components/administracion/mantenedor-clientes/mantenedor-clientes.component";
import { MantenedorImpuestosComponent } from "../components/administracion/mantenedor-impuestos/mantenedor-impuestos.component";
import { MantenedorEmisoresComponent } from "../components/administracion/mantenedor-emisores/mantenedor-emisores.component";
import { MantenedorPerfilesComponent } from "../components/administracion/mantenedor-perfiles/mantenedor-perfiles.component";
import { PermissionListComponent } from "../components/administracion/mantenedor-perfiles/permission-list/permission-list.component";
import { MantenedorPlantillasImpresionComponent } from "../components/configuracion-dte/mantenedor-plantillas-impresion/mantenedor-plantillas-impresion.component";
import { MantenedorProductosComponent } from "../components/administracion/mantenedor-productos/mantenedor-productos.component";
import { MantenedorUsuariosComponent } from "../components/administracion/mantenedor-usuarios/mantenedor-usuarios.component";
import { FacturadorIndividualComponent } from "../components/facturacion/facturador-individual/facturador-individual.component";
import { MantenedorDteEmitidosComponent } from "../components/facturacion/mantenedor-dte-emitidos/mantenedor-dte-emitidos.component";
import { PermissionBlockComponent } from "../components/layout/permission-block/permission-block.component";
import { UserPanelComponent } from "../components/layout/user-panel/user-panel.component";
import { RoleGuard } from "../modules/auth/_services/role.guard";
import { LayoutComponent } from "./_layout/layout.component";
import { MantenedorGuiaDespachoComponent } from "../components/facturacion/mantenedor-guia-despacho/mantenedor-guia-despacho.component";
import { MantenedorConfiguracionEmisionComponent } from "../components/configuracion-dte/mantenedor-configuracion-emision/mantenedor-configuracion-emision.component";

import { WizardEmisorComponent } from "../components/administracion/mantenedor-emisores/wizard-emisor/wizard-emisor.component";
import { MantenedorLogTransaccionesComponent } from "../components/administracion/mantenedor-log-transacciones/mantenedor-log-transacciones.component";
import { MantenedorCorreoEnvioComponent } from "../components/administracion/mantenedor-correo-envio/mantenedor-correo-envio.component";
import { CompanyPanelComponent } from "../components/layout/company-panel/company-panel.component";
import { MantenedorFoliosDefinitivoComponent } from "../components/configuracion-dte/mantenedor-folios-definitivo/mantenedor-folios-definitivo.component";
import { ConsolidadoVentasComponent } from "../components/administracion/consolidados/consolidado-ventas/consolidado-ventas.component";
import { DashboardWidgetsComponent } from "../components/layout/dashboard-widgets/dashboard-widgets.component";

const routes: Routes = [
	{
		path: "",
		component: LayoutComponent,
		children: [
			{
				path: "dashboard",
				loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
			},
			{
				path: "builder",
				loadChildren: () => import("./builder/builder.module").then((m) => m.BuilderModule),
			},
			{
				path: "inicio",
				component: DashboardWidgetsComponent,
			},
			{
				path: "consolidado/ventas",
				canActivate: [RoleGuard],
				component: ConsolidadoVentasComponent,
			},			
			{
				path: "folios",
				canActivate: [RoleGuard],
				component: MantenedorFoliosDefinitivoComponent,
			},
			{
				path: "certificados",
				canActivate: [RoleGuard],
				component: MantenedorCertificadosComponent,
			},
			{
				path: "factura-masiva",
				canActivate: [RoleGuard],
				component: FacturadorMasivoComponent,
			},
			{
				path: "documentos-emitidos",
				canActivate: [RoleGuard],
				component: MantenedorDteEmitidosComponent,
			},
			{
				path: "facturador-individual",
				canActivate: [RoleGuard],
				component: FacturadorIndividualComponent,
			},
		
			{
				path: "clientes",
				canActivate: [RoleGuard],
				component: MantenedorClientesComponent,
			},
			
			{
				path: "usuarios",
				canActivate: [RoleGuard],
				component: MantenedorUsuariosComponent,
			},
		
			{
				path: "perfiles",
				canActivate: [RoleGuard],
				component: MantenedorPerfilesComponent,
			},
			{
				path: "emisores",
				canActivate: [RoleGuard],
				component: MantenedorEmisoresComponent,
			},
			{
				path: "perfiles/permisos",
				canActivate: [RoleGuard],
				component: PermissionListComponent,
			},
			{
				path: "cuenta",
				canActivate: [RoleGuard],
				component: UserPanelComponent,
			},
			{
				path: "correoEnvio",
				canActivate: [RoleGuard],
				component: MantenedorCorreoEnvioComponent,
			},
			{
				path: "productos",
				canActivate: [RoleGuard],
				component: MantenedorProductosComponent,
			},
			{
				path: "plantillas",
				canActivate: [RoleGuard],
				component: MantenedorPlantillasImpresionComponent,
			},
			{
				path: "impuestos",
				canActivate: [RoleGuard],
				component: MantenedorImpuestosComponent,
			},
			{
				path: "guias-despacho",
				canActivate: [RoleGuard],
				component: MantenedorGuiaDespachoComponent,
			},			
			{
				path: "config-emision",
				canActivate: [RoleGuard],
				component: MantenedorConfiguracionEmisionComponent,
			},
			{
				path: "crear-emisor",
				canActivate: [RoleGuard],
				component: WizardEmisorComponent,
			},
			{
				path: "logs",
				canActivate: [RoleGuard],
				component: MantenedorLogTransaccionesComponent,
			},
			{
				path: "empresa",
				canActivate: [RoleGuard],
				component: CompanyPanelComponent,
			},			
			{
				path: "bloqueado",
				component: PermissionBlockComponent,
			},
			{
				path: "",
				redirectTo: "/inicio",
				pathMatch: "full",
			},
			{
				path: "**",
				redirectTo: "error/404",
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PagesRoutingModule {}
