export class PlantillaImpresion {
	tipoPlantillaId: string;
	descripcion: string;
	tipoDteId: string;
	csHtml: any[];
	tipoPapel: string;
	codigoTipoDte: number;
	nombreTipoDte: string;
	file: File;
	usarPorDefecto: boolean;
	maxLineasDetalle: number;
	isDefault: boolean;
	resumen: string;
	existente: boolean;
	cantidadEmpresasAsociadas: number;
}
