import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DetalleDte } from "../models/Dte/DetalleDte";
import { Producto } from "../models/Producto/Producto";
import { ProductoFilter } from "../models/Producto/ProductoFilter";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Response } from "../shared/models/Response";

@Injectable({
   providedIn: "root",
})
export class ProductoService {
   private REST_API_SERVER = environment.backend_server + "/producto";

   constructor(private http: HttpClient) {}

   getDataByPage(filtro: ProductoFilter, page: Page): Observable<PagedResponse<Producto>> {
      let stringNombre = filtro.nombre && filtro.nombre != "" ? filtro.nombre : null;
      let codigoBarra = filtro.codigoBarra != undefined ? filtro.codigoBarra : "";
      let codigoInterno = filtro.codigoInterno ? filtro.codigoInterno : "";
      let nombre = filtro.nombre ? filtro.nombre : "";
      let emisorId = filtro.emisorId ? filtro.emisorId : "";
      let activo = filtro.activo != null ? (filtro.activo ? 0 : 1) : 1;
      let exento = filtro.exento != null ? (filtro.exento ? 1 : 0) : "";
      let sucursal = filtro.sucursalId != null ? filtro.sucursalId : "";
      return this.http.get<PagedResponse<Producto>>(this.REST_API_SERVER + "/list/filter" + "?codigoBarra=" + codigoBarra + "&codigoInterno=" + codigoInterno + "&nombre=" + nombre + "&activo=" + activo + "&exento=" + exento + "&EmisorId=" + emisorId + "&SucursalId=" + sucursal + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
   }

   sendProductRequest(productRequest: Producto): Observable<Response<Producto>> {
      delete productRequest.productoId;
      return this.http.post<Response<Producto>>(this.REST_API_SERVER + "/add", productRequest);
   }

   editProductRequest(productRequest: Producto): Observable<Response<boolean>> {
      return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/update", productRequest);
   }

   deactivateProduct(id: string): Observable<Response<boolean>> {
      return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivate/" + id);
   }

   reactivateProduct(id: string): Observable<Response<boolean>> {
      return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activate/" + id);
   }

   updateTaxes(productWithTaxes: Producto): Observable<Response<boolean>> {
      return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateTaxes", productWithTaxes);
   }

   sendProductRequestFromDetalleDte(detail: DetalleDte, currentEmisorId): Observable<Response<Producto>> {
      let productToSend: Producto = new Producto();
      productToSend.codigoBarra = detail.codigoInterno;
      productToSend.nombre = detail.nombre;
      productToSend.precio = detail.precioUnitario;
      productToSend.exento = detail.esExento ? detail.esExento : false;
      productToSend.emisorId = currentEmisorId;
      return this.http.post<Response<Producto>>(this.REST_API_SERVER + "/add", productToSend);
   }
}
