import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { InlineSVGModule } from "ng-inline-svg";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { SearchDropdownInnerComponent } from "./dropdown-inner/search-dropdown-inner/search-dropdown-inner.component";
import { QuickActionsDropdownInnerComponent } from "./dropdown-inner/quick-actions-dropdown-inner/quick-actions-dropdown-inner.component";
import { CartDropdownInnerComponent } from "./dropdown-inner/cart-dropdown-inner/cart-dropdown-inner.component";
import { UserDropdownInnerComponent } from "./dropdown-inner/user-dropdown-inner/user-dropdown-inner.component";
import { SearchOffcanvasComponent } from "./offcanvas/search-offcanvas/search-offcanvas.component";
import { SearchResultComponent } from "./dropdown-inner/search-dropdown-inner/search-result/search-result.component";
import { NotificationsOffcanvasComponent } from "./offcanvas/notifications-offcanvas/notifications-offcanvas.component";
import { QuickActionsOffcanvasComponent } from "./offcanvas/quick-actions-offcanvas/quick-actions-offcanvas.component";
import { CartOffcanvasComponent } from "./offcanvas/cart-offcanvas/cart-offcanvas.component";
import { QuickPanelOffcanvasComponent } from "./offcanvas/quick-panel-offcanvas/quick-panel-offcanvas.component";
import { UserOffcanvasComponent } from "./offcanvas/user-offcanvas/user-offcanvas.component";
import { CoreModule } from "../../../core";
import { ScrollTopComponent } from "./scroll-top/scroll-top.component";
import { MatExpansionModule } from "@angular/material/expansion";

import { ToolbarComponent } from "./toolbar/toolbar.component";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faBuilding, faCheckSquare, faDotCircle, faDoorOpen, faDoorClosed, faCalendarAlt, faFileExcel, faUserTie, faCog, faTags, faFileInvoiceDollar, faHandHoldingUsd, faIdCard, faStore, faIdBadge, faBriefcase, faFileContract, faEnvelope, faFilePdf, faFileCode, faBan, faListAlt, faInfoCircle, fas, faFileAlt, faBook, faEdit, faPencilAlt, faTimesCircle, faCheckCircle, faBookmark, faExclamationTriangle, faTrash, faPlusCircle, faFileImage, faExclamation } from "@fortawesome/free-solid-svg-icons";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};

@NgModule({
	declarations: [SearchDropdownInnerComponent, QuickActionsDropdownInnerComponent, CartDropdownInnerComponent, UserDropdownInnerComponent, SearchOffcanvasComponent, SearchResultComponent, NotificationsOffcanvasComponent, QuickActionsOffcanvasComponent, CartOffcanvasComponent, QuickPanelOffcanvasComponent, UserOffcanvasComponent, ScrollTopComponent, ToolbarComponent],
	imports: [CommonModule, InlineSVGModule, PerfectScrollbarModule, CoreModule, RouterModule, FontAwesomeModule, MatExpansionModule],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
	],
	exports: [SearchDropdownInnerComponent,  QuickActionsDropdownInnerComponent, CartDropdownInnerComponent, UserDropdownInnerComponent, SearchOffcanvasComponent, NotificationsOffcanvasComponent, QuickActionsOffcanvasComponent, CartOffcanvasComponent, QuickPanelOffcanvasComponent, UserOffcanvasComponent, ToolbarComponent, ScrollTopComponent],
})
export class ExtrasModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas);
		library.addIcons(faBuilding, faCheckSquare, faDotCircle, faDoorOpen, faDoorClosed, faCalendarAlt, faFileExcel, faUserTie, faCog, faTags, faFileInvoiceDollar, faHandHoldingUsd, faIdCard, faStore, faIdBadge, faBriefcase, faFileContract, faEnvelope, faFilePdf, faFileCode, faListAlt, faInfoCircle, faBan, faFileAlt, faBook, faEdit, faPencilAlt, faTimesCircle, faCheckCircle, faBookmark, faExclamationTriangle, faTrash, faPlusCircle, faFileImage, faExclamation);
	}
}
