import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { Observable } from "rxjs";
import { MaxNumberValidator } from "src/app/directives/max-number.directive";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { Divisa } from "src/app/models/Divisa";
import { ComisionRecargo } from "src/app/models/Dte/ComisionRecargo";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { DetalleDteRentencion } from "src/app/models/Dte/DetalleDteRentencion";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { OtraMoneda } from "src/app/models/Dte/OtraMoneda";
import { Retencion } from "src/app/models/Dte/Retencion";
import { Impuesto } from "src/app/models/Impuesto";
import { TipoComision } from "src/app/models/TipoComision";
import { TipoDocumentoReferencia } from "src/app/models/TipoDocRef";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { ImpuestoService } from "src/app/services/impuesto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { DetalleDteImpuestoAdicional } from "../../../../models/Dte/DetalleDteImpuestoAdicional";

@Component({
   selector: "[app-item-detalle-comision]",
   templateUrl: "./item-detalle-comision.component.html",
   styleUrls: ["./item-detalle-comision.component.scss"],
})
export class ItemDetalleComisionComponent implements OnInit {
   @Input() detalleDte: ComisionRecargo = new ComisionRecargo();
   @Input() detalleNumero: number = 0;
   public clpHelperDetalle: ComisionRecargo = new ComisionRecargo();
   @Output() deleteDetalle: EventEmitter<any> = new EventEmitter<any>();
   @ViewChild("localDetalleForm") detailForm;
   public detalleFormGroup: FormGroup;
   public otraMoneda: OtraMoneda = new OtraMoneda();
   public opciones: OptionsReferenceDteEmitido = new OptionsReferenceDteEmitido();
   private opcionesObservable$: Observable<OptionsReferenceDteEmitido>;
   private divisasObservable$: Observable<Divisa[]>;
   public divisaMessage: string = "Ingresar precio en base a la moneda seleccionada";
   private divisasList: Divisa[] = [];
   private currentEmisorId = this.tokenService.getCompany();
   subPrice: number = 0;
   subtotalForeign: number = 0; //used for holding the type dte of form values in foreing currency
   totalForeign: number = 0;
   totalCurrency: number = 0;
   subDiscount: number = 0;
   subRecharge: number = 0;
   public porcentajeIva: number = environment.taxPercentage;
   public searchCatalog: TipoDocumentoReferencia[] = [];
   //public types:string[] = ["Comisión","Otros Cargos"];
   public searchLoading: boolean = false;
   public currentTypeDTe: TipoDocumentoReferencia = new TipoDocumentoReferencia();
   private invoiceType$: Observable<InvoiceType>;
   public showingDetails: boolean = true;
   public currentInvoiceType: InvoiceType = InvoiceType.Factura;
   public showRetencionPercent: boolean = false;
   public retencionesSource: Retencion[] = JSON.parse(this.localStorage.getTiposRetenciones()) as Retencion[];
   public types: TipoComision[] = JSON.parse(this.localStorage.getTipoComisiones()) as TipoComision[];
   public tipoDteSource: TipoDocumentoReferencia[] = JSON.parse(this.localStorage.getTipoDocReferencias()) as TipoDocumentoReferencia[];
   constructor(private builder: FormBuilder, private facturador: FacturacionIndividualService, private ref: ChangeDetectorRef, private tokenService: TokenStorageService, private impuestoService: ImpuestoService, private localStorage: LocalStorageService) {
      this.opcionesObservable$ = this.facturador.opcionesReferenciaObservable;
      this.divisasObservable$ = this.facturador.divisasListObservable;
      this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
   }

   ngOnInit(): void {
      this.detalleDteForm();
      this.opcionesObservable$.subscribe((opciones) => {
         this.opciones = opciones;
         this.onSubmit();
      });
      this.divisasObservable$.subscribe((divisas) => {
         this.divisasList = divisas;
         if (this.divisasList[0]) {
            this.divisasList[0].nombre = "USD";
         }
         this.onSubmit();
      });
      this.invoiceType$.subscribe((res) => {
         this.modificationsByInvoiceType(res);
      });
   }
   deleteItem() {
      this.deleteDetalle.emit();
   }

   ngOnChanges() {
      //console.log(this.detalleDte);
   }

   checkFormSubmitted() {
      this.detailForm.nativeElement.submit();
   }
   detalleDteForm() {
      this.detalleFormGroup = this.builder.group(
         {
            tipo: [, { validators: Validators.required }],
            cantidad: [this.detalleDte.cantidad ? this.detalleDte.cantidad : 1, [Validators.required, PositiveNumberValidator]],
            neto: [0, [Validators.required, PositiveNumberValidator]],
            exento: [0, [Validators.required, PositiveNumberValidator]],
            tasa: [0, [Validators.required, PositiveNumberValidator, Validators.max(100)]],
            nombreItem: [, [Validators.maxLength(60), Validators.required]],
         },
         { updateOn: "blur" }
      );
   }

   onSubmit() {
      this.ref.detectChanges();
      if (this.detalleFormGroup.valid) {
         this.detalleDte.glosa = this.detalleFormGroup.controls.nombreItem.value;
         this.detalleDte.cantidad = 1;
         this.detalleDte.tipoId = (this.detalleFormGroup.controls.tipo.value as TipoComision).tipoComisionId;
         this.detalleDte.tipoSigla = (this.detalleFormGroup.controls.tipo.value as TipoComision).sigla;
         this.detalleDte.tipoNombre = (this.detalleFormGroup.controls.tipo.value as TipoComision).nombre;
         if (this.opciones.tipoMoneda == "CLP") {
            this.ref.detectChanges();
            this.detalleDte.neto = this.detalleFormGroup.controls.neto.value;
            this.detalleDte.tasa = this.detalleFormGroup.controls.tasa.value;
            this.detalleDte.exento = this.detalleFormGroup.controls.exento.value;
            this.detalleDte.iva = this.porcentajeIva * this.detalleDte.neto;
            this.detalleDte.total = this.detalleDte.exento + this.detalleDte.iva + this.detalleDte.neto;
            this.ref.detectChanges();
            //console.log("CLP calculation",this.detalleDte.total,this.detalleDte.subtotal,subMasCargos,this.detalleDte.descuento,this.detalleDte.recargo,this.detalleDte.descripcion);
         }

         // console.log("Impuesto del item",this.detalleDte.totalImpuestosAdicionales);
         this.facturador.updateComisionItem(this.detalleDte);
      }
   }

   changeHaveDescripcion($event) {
      this.ref.detectChanges();
      this.detalleFormGroup.controls.haveDescripcion.patchValue($event.target.checked);
   }

   changeHaveCodigo(event) {
      this.ref.detectChanges();
      this.detalleFormGroup.controls.haveCodigo.patchValue(event.target.checked);
   }

   searchItem(event, propertyName) {
      //console.log(event);
      this.searchLoading = true;
      this.searchCatalog = [...this.tipoDteSource] as TipoDocumentoReferencia[];
      this.ref.detectChanges();
      this.searchLoading = false;
   }
   refreshItemDetail(event: TipoDocumentoReferencia) {
      //console.log(event)
      //set selected item to form
      this.currentTypeDTe = event;
      if (event && event.codigo) {
         this.selectedTypeDteToForm(this.currentTypeDTe);
      }
      this.onSubmit();
   }

   selectedTypeDteToForm(selectedTypeDte: TipoDocumentoReferencia) {
      if (selectedTypeDte) {
         this.detalleFormGroup.controls.codigo.patchValue(selectedTypeDte.codigo);
         this.detalleFormGroup.controls.nombreItem.patchValue(selectedTypeDte.nombre);
      }
   }

   modificationsByInvoiceType(type: InvoiceType) {
      this.currentInvoiceType = type;
      switch (type) {
         case InvoiceType.Boleta: {
            this.showingDetails = false;
            //console.log(type,this.showingDetails);
            break;
         }
         case InvoiceType.Factura: {
            this.showingDetails = true;
            break;
         }
         case InvoiceType.GuiaDespacho: {
            this.showingDetails = true;
            break;
         }
      }
   }

   changeRetencionInput($event) {
      this.ref.detectChanges();
      this.detalleFormGroup.controls.haveRetencion.patchValue($event.target.checked);
      this.showRetencionPercent = this.detalleFormGroup.controls.haveRetencion.value;
   }
}
