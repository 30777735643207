<form action="" [formGroup]="dteNullifyForm" (ngSubmit)="onSubmit()" #localFormRef="ngForm">
	<div class="mt-4">
		<div *ngIf="receivedDte" class="text-center">
			<div class="row">
				<div class="col-12">
					<div class="row">
						<div class="col-3">Folio: {{ receivedDte.folio }}</div>
						<div class="col-3">Rut: {{ receivedDte.rutReceptorDv }}</div>
						<div class="col-3">Nombre: {{ receivedDte.razonSocial }}</div>
						<div class="col-3">Fecha: {{ receivedDte.fecha | date : "dd/MM/yyyy" }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-body" *ngIf="dteNullifyForm.controls.radioNullType.value != null && receivedDte.notaCreditoHabilitada && !DebitoMode">
		<!--Formulario de seleccion de tipo de anulación-->
		<div class="form-group position-relative mb-0" style="align-items: flex-start">
			<div class="row">
				<label for="typeOfNullifySelected" class="col-2 label-control">Tipo</label>
				<div class="col-9 radio-inline">
					<div *ngFor="let reason of typeOfNullifyValues; let i = index">
						<input type="radio" formControlName="radioNullType" [(checked)]="typeOfNullifySelected == typeOfNullifyValues[i]" [value]="reason" name="radioNullType" class="ml-5" *ngIf="arrayPermissions[i].asignado" (change)="sendNullType()" />
						<span *ngIf="arrayPermissions[i].asignado" class="align-top ml-2">{{ typeOfNullify[reason] }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- Variantes de formulario segun tipo de anulación-->
		<!-- <p>{{typeOfNullify[dteNullifyForm.controls.radioNullType.value]}} | {{dteNullifyForm.controls.radioNullType.value}}</p>
             -->

		<hr />
		<br />
		<div [ngSwitch]="typeOfNullify[dteNullifyForm.controls.radioNullType.value]">
			<ng-template ngSwitchCase="Corrección Monto">
				<div formGroupName="amountCorrectionGroup">
					<div>
						<!--Lista de detalles del dte original referenciado-->
						<h5>Lista de Detalles del documento referenciado</h5>
						<div *ngIf="totalDescontadoCorrecciones > 0">
							<table>
								<tr>
									<td>Descuentos vigentes:</td>
									<td></td>
									<td class="text-right">
										<b>{{ totalDescontadoCorrecciones | currency : "$ " : "symbol" : "1.0-0" : "es" }}</b>
									</td>
								</tr>
								<tr></tr>
								<tr>
									<td>Disponible para corregir:</td>
									<td></td>
									<td class="text-right">
										<b>{{ receivedDte.total - totalDescontadoCorrecciones | currency : "$ " : "symbol" : "1.0-0" : "es" }}</b>
									</td>
								</tr>
							</table>
						</div>
						<!-- {{noteToSend.total}} | {{receivedDte.total}} -->
						<ngx-datatable #table class="material" [rows]="rowsReceivedDte" [columnMode]="'flex'" [headerHeight]="30" [footerHeight]="0" [rowHeight]="30" fxFlex="auto" [perfectScrollbar]="configScroll" [limit]="10" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
							<ngx-datatable-column name="Descripcion" [flexGrow]="2" cellClass="text-center" headerClass="text-center" [sortable]="false">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Descripción</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-row="row"
									><span class="text-table-alignment">{{ row.nombre }}</span></ng-template
								>
							</ngx-datatable-column>

							<ngx-datatable-column name="Cantidad" [flexGrow]="1" [sortable]="false">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Cantidad</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-row="row"
									><span class="number-table-alignment">{{ row.cantidad | number : "1.0-3" : "es" }}</span>
								</ng-template>
							</ngx-datatable-column>

							<ngx-datatable-column name="Precio" [flexGrow]="1" [sortable]="false">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Precio</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-row="row"
									><span class="number-table-alignment">{{ row.precioUnitario | currency : "$ " : "symbol" : "1.0-3" : "es" }}</span></ng-template
								>
							</ngx-datatable-column>
							<ngx-datatable-column name="esExento" [sortable]="false" [flexGrow]="1">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Exento</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<span>
										{{ value ? "Exento" : "Afecto" }}
									</span>
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="Subtotal" [flexGrow]="1" [sortable]="false">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Subtotal</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-row="row"
									><span class="number-table-alignment">{{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
								>
							</ngx-datatable-column>
							<ngx-datatable-column name="Acción" [flexGrow]="1" [sortable]="false">
								<ng-template ngx-datatable-cell-template let-row="row">
									<a [routerLink]="" title="Item a corregir" ngbTooltip="Item a corregir" class="primary edit mr-1 mrless" (click)="passElementToNote(row)">
										<!-- <span title="Item a corregir" aria-label="Item a corregir"  [inlineSVG]="
                                                    './assets/media/svg/icons/Navigation/Arrow-down.svg'
                                                  " cacheSVG="true" class="svg-icon svg-icon-lg svg-icon-info"></span> -->
										<div class="icon-circle bg-info text-center align-middle pt-file-button">
											<fa-icon icon="plus-circle" class="fa-lg text-white"></fa-icon>
										</div>
									</a>
								</ng-template>
							</ngx-datatable-column>
						</ngx-datatable>
					</div>
					<br />
					<hr />
					<p>
						<i>Nota: Los elementos de detalle que no requieran corrección no deben agregarse a la nota de credito.</i>
					</p>
					<div>
						<!-- {{receivedDte.detalleDte}} -->
						<h5>Lista de detalles a corregir</h5>
						<ngx-datatable #table class="material" [rows]="rowsDetalles" [columnMode]="'flex'" [headerHeight]="30" [footerHeight]="0" [rowHeight]="35" fxFlex="auto" [perfectScrollbar]="configScroll" [limit]="10" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
							<!-- <ngx-datatable-column name="Descripcion" [flexGrow]="1" cellClass="text-center" headerClass="text-center" [sortable]="false"> 
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <span class="text-table-alignment">Descripción</span>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-row="row"><span class="text-table-alignment">{{row.descripcion }}</span></ng-template>
                                     </ngx-datatable-column> -->
							<ngx-datatable-column name="Nombre" [sortable]="false" [flexGrow]="2">
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<span title="Doble click para editar" (dblclick)="editing[rowIndex + '-nombre'] = true" *ngIf="!editing[rowIndex + '-nombre']">
										{{ value }}
									</span>
									<input autofocus (blur)="updateValue($event, 'nombre', rowIndex)" *ngIf="editing[rowIndex + '-nombre']" type="text" [value]="value" class="mx-1" />
								</ng-template>
							</ngx-datatable-column>

							<ngx-datatable-column name="Cantidad" [sortable]="false" [flexGrow]="1">
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<span title="Doble click para editar" (dblclick)="editing[rowIndex + '-cantidad'] = true" *ngIf="!editing[rowIndex + '-cantidad']">
										{{ value | number : "1.0-3" : "es" }}
									</span>
									<input autofocus (blur)="updateValue($event, 'cantidad', rowIndex)" *ngIf="editing[rowIndex + '-cantidad']" type="number" [value]="value" class="mx-1" />
								</ng-template>
							</ngx-datatable-column>

							<!-- <ngx-datatable-column name="Cantidad" [flexGrow]="1" [sortable]="false">
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <span class="text-table-alignment">Cantidad</span>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-row="row"><span  class="number-table-alignment">{{row.cantidad  | currency : '$ ' : 'symbol':'1.0-0':'es'}}</span></ng-template>
                                    </ngx-datatable-column> -->

							<ngx-datatable-column name="precioUnitario" [sortable]="false" [flexGrow]="1">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Precio</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<span title="Doble click para editar" (dblclick)="editing[rowIndex + '-precioUnitario'] = true" *ngIf="!editing[rowIndex + '-precioUnitario']">
										{{ value | number : "1.0-3" : "es" }}
									</span>
									<input autofocus pattern="^\d+(\.\d{1,6})?$" (blur)="updateValue($event, 'precioUnitario', rowIndex)" *ngIf="editing[rowIndex + '-precioUnitario']" type="number" [value]="value" class="mx-1" />
								</ng-template>
							</ngx-datatable-column>

							<!-- <ngx-datatable-column name="Precio" [flexGrow]="1" [sortable]="false">
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <span class="text-table-alignment">Precio</span>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-row="row"><span  class="number-table-alignment">{{row.precio  | currency : '$ ' : 'symbol':'1.0-0':'es'}}</span></ng-template>
                                    </ngx-datatable-column> -->
							<ngx-datatable-column name="esExento" [sortable]="false" [flexGrow]="1">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Exento</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<span title="Doble click para editar" (dblclick)="editing[rowIndex + '-esExento'] = true" *ngIf="!editing[rowIndex + '-esExento']">
										{{ value ? "Exento" : "Afecto" }}
									</span>
									<!-- <input 
                                                type="checkbox"
                                                class="customCheckbox" 
                                                (dblclick)="editing[rowIndex + '-indicadorExento'] = true"
                                                *ngIf="!editing[rowIndex + '-indicadorExento']"}
                                                [value]="value"
                                                [checked]="value"
                                            /> -->
									<input autofocus (blur)="updateValue($event, 'esExento', rowIndex)" *ngIf="editing[rowIndex + '-esExento']" type="checkbox" [value]="value" [checked]="value" class="mx-1" />
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="Subtotal" [flexGrow]="1" [sortable]="false">
								<ng-template let-column="column" ngx-datatable-header-template>
									<span class="text-table-alignment">Subtotal</span>
								</ng-template>
								<ng-template ngx-datatable-cell-template let-row="row"
									><span class="number-table-alignment">{{ row.total | currency : "$ " : "symbol" : "1.0-3" : "es" }}</span></ng-template
								>
							</ngx-datatable-column>
							<ngx-datatable-column name="Acción" [flexGrow]="1" [sortable]="false">
								<ng-template ngx-datatable-cell-template let-row="row">
									<a [routerLink]="" title="Quitar Item" ngbTooltip="Quitar Item" class="primary edit mr-1" (click)="deleteDetailElement(row)">
										<!-- <span title="Quitar Item" aria-label="Item a corregir"  [inlineSVG]="
                                                    './assets/media/svg/icons/Code/Error-circle.svg'
                                                  " cacheSVG="true" class="svg-icon svg-icon-lg svg-icon-danger"></span> -->
										<div class="icon-circle bg-danger text-center align-middle pt-file-button">
											<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
										</div>
									</a>
								</ng-template>
							</ngx-datatable-column>
						</ngx-datatable>
					</div>
					<div class="form-group row mx-auto mt-5">
						<div class="card offset-7 col-5 float-right">
							<div class="card-head mt-2">Total de Nota</div>
							<div class="card-content">
								<div class="row py-2" id="resumen">
									<div class="col-md-12" id="totales">
										<!-- <p class="lead">Totales</p> -->
										<div class="table-responsive">
											<table class="table" cellspacing="0" cellpadding="0">
												<tbody>
													<tr>
														<td>Subtotal</td>
														<td class="text-right">{{ totalesNota.subTotal | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
													</tr>
													<!-- <tr>
                                                       <td>Descuentos</td>
                                                       <td class="text-right">
                                                          $
                                                          {{ totalesNota.recargo - totalesNota.descuento | number: "1." }}
                                                       </td>
                                                    </tr>
                                                    <tr>
                                                       <td>Neto</td>
                                                       <td class="text-right">$ {{ totalesNota.neto | number: "1." }}</td>
                                                    </tr> -->
													<tr>
														<td>Afecto</td>
														<td class="text-right">{{ totalesNota.afecto | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
													</tr>
													<tr>
														<td>Exento</td>
														<td class="text-right">{{ totalesNota.exento | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
													</tr>
													<tr>
														<td>IVA</td>
														<td class="text-right">{{ totalesNota.iva | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
													</tr>
													<tr>
														<td class="text-bold-800">Total</td>
														<td class="text-bold-800 text-right">{{ totalesNota.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Comentario</label>
						<div class="col-10">
							<textarea
								name="comentarioDetalles"
								id="comentarioDetalles"
								cols="5"
								rows="4"
								formControlName="comentarioDetalles"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.amountCorrectionGroup.get('comentarioDetalles').invalid
								}"></textarea>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.amountCorrectionGroup.get('comentarioDetalles').invalid || dteNullifyForm.controls.amountCorrectionGroup.get('comentarioDetalles').dirty || dteNullifyForm.controls.amountCorrectionGroup.get('comentarioDetalles').touched || dteNullifyForm.controls.amountCorrectionGroup.get('comentarioDetalles').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.amountCorrectionGroup.get('comentarioDetalles').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template ngSwitchCase="Corrección Texto">
				<div formGroupName="radioTextGroup">
					<h6>Cliente</h6>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Rut:</label>
						<div class="col-10">
							<input
								name="rutReceptor"
								id="rutReceptor"
								formControlName="rutReceptor"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('rutReceptor').invalid
								}" />
							<!-- <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').invalid  || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').dirty || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').touched || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').errors?.required">Campo requerido</div>
                                </div> -->
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Razón Social:</label>
						<div class="col-10">
							<input
								name="razonSocialReceptor"
								id="razonSocialReceptor"
								formControlName="razonSocialReceptor"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('razonSocialReceptor').invalid
								}" />
							<!-- <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').invalid  || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').dirty || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').touched || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').errors?.required">Campo requerido</div>
                                </div> -->
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Giro:</label>
						<div class="col-10">
							<input
								name="giroReceptor"
								id="giroReceptor"
								formControlName="giroReceptor"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('giroReceptor').invalid
								}" />
							<!-- <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').invalid  || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').dirty || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').touched || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').errors?.required">Campo requerido</div>
                                </div> -->
						</div>
					</div>

					<hr />
					<br />
					<h6>Dirección</h6>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Dirección Particular:</label>
						<div class="col-10">
							<input
								name="direccionFacturacion"
								id="direccionFacturacion"
								formControlName="direccionFacturacion"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('direccionFacturacion').invalid
								}" />
							<!-- <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').invalid  || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').dirty || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').touched || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').errors?.required">Campo requerido</div>
                                </div> -->
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Comuna:</label>
						<div class="col-10">
							<input
								name="comunaReceptor"
								id="comunaReceptor"
								formControlName="comunaReceptor"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('comunaReceptor').invalid
								}" />
							<!-- <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').invalid  || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').dirty || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').touched || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').errors?.required">Campo requerido</div>
                                </div> -->
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Ciudad:</label>
						<div class="col-10">
							<input
								name="ciudadReceptor"
								id="ciudadReceptor"
								formControlName="ciudadReceptor"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('ciudadReceptor').invalid
								}" />
							<!-- <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').invalid  || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').dirty || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').touched || dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('dondeDiceText').errors?.required">Campo requerido</div>
                                </div> -->
						</div>
					</div>

					<hr />
					<br />
					<p>
						<i>Nota: Datos de uso interno, no se reflejan en el documento.</i>
					</p>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Motivo</label>
						<div class="col-10">
							<ng-select
								[items]="motivosCorrigeTextos"
								placeholder="Seleccione Motivo"
								formControlName="motivoCorrigeTexto"
								[clearable]="false"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('motivoCorrigeTexto').invalid
								}"></ng-select>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('motivoCorrigeTexto').invalid || dteNullifyForm.controls.radioTextGroup.get('motivoCorrigeTexto').dirty || dteNullifyForm.controls.radioTextGroup.get('motivoCorrigeTexto').touched || dteNullifyForm.controls.radioTextGroup.get('motivoCorrigeTexto').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.radioTextGroup.get('motivoCorrigeTexto').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>

					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Comentario</label>
						<div class="col-10">
							<textarea
								name="comentarioTextCorrection"
								id="comentarioTextCorrection"
								cols="5"
								rows="4"
								formControlName="comentarioTextCorrection"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('comentarioTextCorrection').invalid
								}"></textarea>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('comentarioTextCorrection').invalid || dteNullifyForm.controls.radioTextGroup.get('comentarioTextCorrection').dirty || dteNullifyForm.controls.radioTextGroup.get('comentarioTextCorrection').touched || dteNullifyForm.controls.radioTextGroup.get('comentarioTextCorrection').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.radioTextGroup.get('comentarioTextCorrection').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
					<!-- <div class="form-group row mx-auto">
                            <label for="motivo" class="col-2 label-control">Debe decir:</label>
                            <div class="col-10">
                                <textarea 
                                name="debeDecirText" 
                                id="debeDecirText" 
                                cols="5" 
                                rows="4" 
                                formControlName="debeDecirText"
                                class="form-control"
                                style="resize: none;"
                                [ngClass]="{
                                    'is-invalid': localFormRef.submitted && dteNullifyForm.controls.radioTextGroup.get('debeDecirText').invalid
                                }"
                                ></textarea>
                                <div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.radioTextGroup.get('debeDecirText').invalid  || dteNullifyForm.controls.radioTextGroup.get('debeDecirText').dirty || dteNullifyForm.controls.radioTextGroup.get('debeDecirText').touched || dteNullifyForm.controls.radioTextGroup.get('debeDecirText').value == null)" class="invalid-feedback">
                                    <div *ngIf="dteNullifyForm.controls.radioTextGroup.get('debeDecirText').errors?.required">Campo requerido</div>
                                </div>
                            </div>
                        </div> -->
				</div>
			</ng-template>

			<ng-template ngSwitchDefault>
				<!--radioNullDefault para motivo y comentario solamente-->
				<!-- <ng-template *ngTemplateOutlet="formDefaultNull">

                    </ng-template> -->
				<div formGroupName="defaultForm">
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Motivo</label>
						<div class="col-10">
							<ng-select
								[items]="motivos"
								placeholder="Seleccione Motivo"
								formControlName="motivo"
								[clearable]="false"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.defaultForm.get('motivo').invalid
								}"></ng-select>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.defaultForm.get('motivo').invalid || dteNullifyForm.controls.defaultForm.get('motivo').dirty || dteNullifyForm.controls.defaultForm.get('motivo').touched || dteNullifyForm.controls.defaultForm.get('motivo').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.defaultForm.get('motivo').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Comentario</label>
						<div class="col-10">
							<textarea
								name="comentario"
								id="comentarioDefault"
								cols="5"
								rows="4"
								formControlName="comentario"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.defaultForm.get('comentario').invalid
								}"></textarea>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.defaultForm.get('comentario').invalid || dteNullifyForm.controls.defaultForm.get('comentario').dirty || dteNullifyForm.controls.defaultForm.get('comentario').touched || dteNullifyForm.controls.defaultForm.get('comentario').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.defaultForm.get('comentario').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="modal-body" *ngIf="dteNullifyForm.controls.radioNullType.value != null && receivedDte.notaDebitoHabilitada && DebitoMode && showNullifyDebitForm">
		<!--Formulario de seleccion de tipo de anulación-->

		<div class="form-group position-relative mb-0" style="align-items: flex-start">
			<div class="row">
				<label for="typeOfNullifyDNForCNSelected" class="col-2 label-control">Tipo</label>
				<div class="col-9 radio-inline">
					<div *ngFor="let reason of typeOfNullifyDNForCNValues; let i = index">
						<input type="radio" formControlName="radioNullType" [(checked)]="typeOfNullifyDNForCNSelected == typeOfNullifyDNForCNValues[i]" [value]="reason" name="radioNullType" class="ml-5" *ngIf="arrayPermissions[i].asignado" />
						<span *ngIf="arrayPermissions[i].asignado" class="align-top ml-2">{{ typeOfNullifyDNForCN[reason] }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- Variantes de formulario segun tipo de anulación-->
		<!-- <p>{{typeOfNullify[dteNullifyForm.controls.radioNullType.value]}} | {{dteNullifyForm.controls.radioNullType.value}}</p>
             -->

		<hr />
		<br />
		<div [ngSwitch]="typeOfNullifyDNForCN[dteNullifyForm.controls.radioNullType.value]">
			<ng-template ngSwitchDefault>
				<!--radioNullDefault para motivo y comentario solamente-->
				<!-- <ng-template *ngTemplateOutlet="formDefaultNull">

                    </ng-template> -->
				<div formGroupName="defaultForm">
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Motivo</label>
						<div class="col-10">
							<ng-select
								[items]="motivos"
								placeholder="Seleccione Motivo"
								formControlName="motivo"
								[clearable]="false"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.defaultForm.get('motivo').invalid
								}"></ng-select>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.defaultForm.get('motivo').invalid || dteNullifyForm.controls.defaultForm.get('motivo').dirty || dteNullifyForm.controls.defaultForm.get('motivo').touched || dteNullifyForm.controls.defaultForm.get('motivo').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.defaultForm.get('motivo').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Comentario</label>
						<div class="col-10">
							<textarea
								name="comentario"
								id="comentarioDefault"
								cols="5"
								rows="4"
								formControlName="comentario"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.defaultForm.get('comentario').invalid
								}"></textarea>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.defaultForm.get('comentario').invalid || dteNullifyForm.controls.defaultForm.get('comentario').dirty || dteNullifyForm.controls.defaultForm.get('comentario').touched || dteNullifyForm.controls.defaultForm.get('comentario').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.defaultForm.get('comentario').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="modal-body" *ngIf="dteNullifyForm.controls.radioNullType.value != null && receivedDte.notaDebitoHabilitada && DebitoMode && showInterestForm">
		<!--Formulario de seleccion de tipo de anulación-->

		<div class="form-group position-relative mb-0" style="align-items: flex-start">
			<div class="row">
				<label for="typeOfNullifyNDInterestsSelected" class="col-2 label-control">Tipo</label>
				<div class="col-9 radio-inline">
					<div *ngFor="let reason of typeOfNullifyDNInterestsValues; let i = index">
						<input type="radio" formControlName="radioNullType" [(checked)]="typeOfNullifyDNInterestsSelected == typeOfNullifyDNInterestsValues[i]" [value]="reason" name="radioNullType" class="ml-5" *ngIf="arrayPermissions[i].asignado" />
						<span *ngIf="arrayPermissions[i].asignado" class="align-top ml-2">{{ typeOfNullifyDNInterests[reason] }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- Variantes de formulario segun tipo de anulación-->
		<!-- <p>{{typeOfNullify[dteNullifyForm.controls.radioNullType.value]}} | {{dteNullifyForm.controls.radioNullType.value}}</p>
             -->

		<hr />
		<br />
		<div [ngSwitch]="typeOfNullifyDNInterests[dteNullifyForm.controls.radioNullType.value]">
			<ng-template ngSwitchCase="Intereses">
				<!--radioNullDefault para motivo y comentario solamente-->
				<!-- <ng-template *ngTemplateOutlet="formDefaultNull">

                    </ng-template> -->
				<div class="col-12">
					<div class="row">
						<div class="col-3">Totales DTE Referenciado</div>
					</div>
				</div>
				<br />
				<div class="col-12">
					<div class="row">
						<div class="col-3">Neto: {{ receivedDte.neto | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
						<div class="col-3">Iva: {{ receivedDte.iva | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
						<div class="col-3">Exento: {{ receivedDte.exento | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
						<div class="col-3">Total: {{ receivedDte.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</div>
					</div>
				</div>
				<br />
				<div formGroupName="interestsForm">
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Motivo</label>
						<div class="col-10">
							<ng-select
								[items]="interestsMotive"
								placeholder="Seleccione Motivo"
								formControlName="motiveInterests"
								[clearable]="false"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.interestsForm.get('motivoInteres').invalid
								}"></ng-select>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.interestsForm.get('motivoInteres').invalid || dteNullifyForm.controls.interestsForm.get('motivo').dirty || dteNullifyForm.controls.interestsForm.get('motivo').touched || dteNullifyForm.controls.interestsForm.get('motivo').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.interestsForm.get('motivoInteres').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Comentario</label>
						<div class="col-10">
							<textarea
								name="commentInterests"
								id="commentInterests"
								cols="5"
								rows="4"
								formControlName="commentInterests"
								class="form-control"
								style="resize: none"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.interestsForm.get('comentarioInteres').invalid
								}"></textarea>
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.interestsForm.get('comentarioInteres').invalid || dteNullifyForm.controls.interestsForm.get('comentarioInteres').dirty || dteNullifyForm.controls.interestsForm.get('comentarioInteres').touched || dteNullifyForm.controls.interestsForm.get('comentarioInteres').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.interestsForm.get('comentarioInteres').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>

					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Valor Interes</label>
						<div class="col-10">
							<input
								type="number"
								name="valueInterests"
								value="0"
								id="valueInterests"
								formControlName="valueInterests"
								class="form-control"
								(input)="updateValueInterest($event.target.value)"
								style="resize: none"
								[ngModel]="newValueInterests"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteNullifyForm.controls.interestsForm.get('valorInteres').invalid
								}" />
							<div *ngIf="localFormRef.submitted && (dteNullifyForm.controls.interestsForm.get('valorInteres').invalid || dteNullifyForm.controls.interestsForm.get('valorInteres').dirty || dteNullifyForm.controls.interestsForm.get('valorInteres').touched || dteNullifyForm.controls.interestsForm.get('valorInteres').value == null)" class="invalid-feedback">
								<div *ngIf="dteNullifyForm.controls.interestsForm.get('valorInteres').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="receivedDte.codigoTipoDte != 34 && receivedDte.codigoTipoDte != 41">
						<label for="motivo" class="col-2 label-control">Iva</label>
						<div class="col-10">
							<input disabled type="text" name="ivaInterests" id="ivaInterest" formControlName="ivaInterests" [ngModel]="newIvaInterests" class="form-control" style="resize: none" />
						</div>
					</div>
					<div class="form-group row mx-auto" *ngIf="receivedDte.codigoTipoDte == 34 || receivedDte.codigoTipoDte == 41">
						<label for="motivo" class="col-2 label-control">Exento</label>
						<div class="col-10">
							<input disabled type="text" name="exemptInterests" id="exemptInterests" [ngModel]="newValueInterests" formControlName="exemptInterests" class="form-control" style="resize: none" />
						</div>
					</div>
					<div class="form-group row mx-auto">
						<label for="motivo" class="col-2 label-control">Total</label>
						<div class="col-10">
							<input disabled type="text" name="totalInterests" id="totalInterests" formControlName="totalInterests" [ngModel]="newTotalInterests" class="form-control" style="resize: none" />
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
	<!-- if no specific permission for each reason then block the form -->
	<!-- not permission for any kind of NotaCredito/debito -->
	<div class="modal-body" *ngIf="dteNullifyForm.controls.radioNullType.value == null">
		<h5>No tiene permisos para emitir una nota en particular</h5>
	</div>

	<div class="modal-footer">
		<div class="form-group position-relative has-icon-left mb-0">
			<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" [disabled]="!dteNullifyForm.valid" *ngIf="dteNullifyForm.controls.radioNullType.value != null">
				<i class="la la-paper-plane-o d-lg-none"></i>
				<span class="d-none d-lg-block">Generar</span>
			</button>
			<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
				<span class="d-none d-lg-block">Cerrar</span>
			</button>
		</div>
	</div>
</form>
<swal #anuladorSwal title="Operación" text="Operación realizada" icon="info"></swal>
<!-- <ng-template #formDefaultNull>
        <div class="form-group row mx-auto">
            <ng-select
                [items]=""
                [multiple]="false"
                placeholder="Seleccione Motivo"
                formControlName="motivo"
            ></ng-select>
        </div>
        <div class="form-group row mx-auto">
            
        </div>
       </ng-template> -->
