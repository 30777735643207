<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<p>Cliente</p>
		</div>
	</div>
	<div class="card-head"></div>
	<div class="card-content">
		<div class="card-body" [formGroup]="contraparteGroupForm">
			<div class="row">
				<div class="col-6 form-group mx-auto">
					<div class="row">
						<div class="col-4 label-control">
							Rut Cliente
						</div>

						<div class="col-8">
							<div class="input-group">
								<input
									type="text"
									name="rutBusqueda"
									placeholder="Sin ptos con guión"
									class="form-control"
									formControlName="rutBusqueda"
									maxlength="10"
									(input)="buscaCliente($event.target.value)"
									[ngClass]="{
										spinner: loadingClient$ | async
									}" />

								<div class="input-group-append">
									<ng-container class="text-right" style="position: absolute; z-index: -100" *ngIf="loadingClient$ | async">
										<span class="spinner spinner-primary spinner-sm ml-5"></span>
									</ng-container>
									<button class="btn btn-outline-secondary" type="button" (click)="openClientListModal(clientListModal)">
										<i class="fa fa-search"></i>
									</button>
								</div>
							</div>

							<div *ngIf="contraparteGroupForm.controls.rutBusqueda && (contraparteGroupForm.touched || contraparteGroupForm.dirty) && contraparteGroupForm.controls.rutBusqueda.invalid" class="text-danger">
								<div *ngIf="contraparteGroupForm.controls.rutBusqueda.errors?.required">Campo Requerido</div>
								<div *ngIf="contraparteGroupForm.get('rutBusqueda').hasError('VerificacionRutValidator')">Rut no válido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-6 form-group mx-auto">
					<div class="row">
						<div class="col-4 label-control">Razon Social</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="razonSocial" [attr.disabled]="true" />
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6 form-group mx-auto">
					<div class="row">
						<div class="col-4 label-control">Giro</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="giro" (change)="changeGiro($event.target.value)" />
						</div>
					</div>
				</div>
				<div class="col-6 form-group mx-auto">
					<div class="row">
						<div class="col-4 label-control">Dirección</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="dirFact" (change)="changeDireccion($event.target.value)" />
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6 form-group mx-auto">
					<div class="row">
						<div class="col-4 label-control">Comuna</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="comuna" (change)="changeComuna($event.target.value)" />
						</div>
					</div>
				</div>
				<div class="col-6 form-group mx-auto">
					<div class="row">
						<div class="col-4 label-control">Ciudad</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="ciudad" (change)="changeCiudad($event.target.value)" />
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div
					class="col-6 form-group mx-auto"
					[ngClass]="{
						'mx-auto': true
					}">
					<div class="row">
						<div class="col-4 label-control">Correo Electronico</div>
						<div class="col-8">
							<input type="text" class="form-control" formControlName="correoFact" (change)="changeCorreoFact($event.target.value)" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<swal #currentSwal icon="info" title="Operación" text="Operación realizada"></swal>

<!-- <swal #swalBuscandoCliente 
allowOutsideClick=false 
allowEscapeKey=false 
showConfirmButton=false 
title="Cargando" 
text="El cliente no está ingresado en la base de datos."></swal> -->

<ng-template #clientListModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-body">
			<app-client-list (clientToPass)="selectedReceptor($event)"></app-client-list>
		</div>
	</div>
</ng-template>
