<div>
	<div *ngIf="row.estadoSii == 10">
		<span class="error-dot" ngbTooltip="Mal generado"></span>
		<span class="void-dot"></span>
		<span class="void-dot"></span>
	</div>
	<div *ngIf="row.estadoSii == 1">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="void-dot"></span>
		<span class="void-dot"></span>
	</div>
	<div *ngIf="row.estadoSii == 2">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado al SII"></span>
		<span class="void-dot"></span>
	</div>
	<div *ngIf="row.estadoSii == 4">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado a Receptor"></span>
		<span class="void-dot"></span>
	</div>
	<div *ngIf="row.estadoSii == 5">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado al SII"></span>
		<span class="error-dot" ngbTooltip="Rechazo en SII"></span>
	</div>
	<div *ngIf="row.estadoSii == 6 && row.estadoAcuse != 3">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado al SII"></span>
		<span class="success-dot" ngbTooltip="Aceptado en SII"></span>
	</div>
	<div *ngIf="row.estadoSii == 6 && row.estadoAcuse == 3">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado al SII"></span>
		<span class="error-dot" ngbTooltip="Estado de Acuse: {{ row.descripcionEstadoAcuse }}"></span>
	</div>
	<div *ngIf="row.estadoSii == 7">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado al SII"></span>
		<span class="warning-dot" ngbTooltip="Aceptado en SII con reparos"></span>
	</div>
	<div *ngIf="row.estadoSii == 8">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="error-dot" ngbTooltip="Envío no llegó al SII"></span>
		<span class="void-dot"></span>
	</div>
	<div *ngIf="row.estadoSii == 9">
		<span class="success-dot" ngbTooltip="Generado"></span>
		<span class="success-dot" ngbTooltip="Enviado al SII"></span>
		<span class="error-dot" ngbTooltip="Rechazado por Receptor"></span>
	</div>
</div>
