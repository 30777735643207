<ngx-loading [show]="loading" [config]="{backdropBorderRadius: '3px'}" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<form [formGroup]="detalleProductoFormGroup" #productoDetalleForm="ngForm">
    <table>
        <tr>
            <td WIDTH="20%">
                <div class="form-group mr-5">
                    <div class="row">
                        <div class="col-12">
                            <ng-select formControlName="codigo" [items]="searchCatalog" [selectOnTab]="true"
                                [loading]="searchLoading" bindLabel="codigoBarra" placeholder="Ingrese código de barra"
                                (change)="refreshItemDetail($event)" (clear)="clearProduct($event)"
                                (search)="searchItem($event,'codigoBarra')" notFoundText="Sin resultado"></ng-select>
                        </div>
                    </div>
                </div>
            </td>
            <td WIDTH="35%">
                <div class="form-group mr-5">
                    <div class="row">
                        <div class="col-12">
                            <ng-select formControlName="nombreItem" [items]="searchCatalog" [selectOnTab]="true"
                                [loading]="searchLoading" bindLabel="nombre" placeholder="Ingrese nombre"
                                (change)="refreshItemDetail($event)" (clear)="clearProduct($event)"
                                (search)="searchItem($event,'nombre')" notFoundText="Sin resultado"></ng-select>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <button class="btn btn-default" (click)="openProducListModal(listaBuscaProducto)">
                    <i class="flaticon-search"></i>
                </button>
            </td>
            <td WIDTH="15%">
                <div class="form-group mr-5">
                    <div class="row">
                        <div class="col-12">
                            <input type="number" formControlName="cantidad" min="1" class="form-control">
                            <div *ngIf="detalleProductoFormGroup.controls.cantidad.invalid  && productoDetalleForm.submitted"
                                class="text-danget">
                                <div *ngIf="detalleProductoFormGroup.controls.cantidad.errors?.required">Campo
                                    Obligatorio</div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div class="text-right">
                    <div class="float-left"
                        *ngIf="opciones.tipoMoneda == 'CLP' && currentProduct && currentProduct.impuestos && currentProduct.impuestos.length>0">
                        <fa-icon icon="exclamation-triangle" class="fa-lg text-warning" [ngbTooltip]="list"></fa-icon>
                    </div>
                    <div class="float-right" *ngIf="currentProduct && currentProduct.precio">
                        $ {{currentProduct.precio | number:'1.0-2':'es'}}
                    </div>
                </div>
            </td>
            <td class="text-right">
                <button class="btn btn-success" (click)="onSubmit(productoDetalleForm)">Agregar
                    <i class="flaticon-add"></i>
                </button>
            </td>
        </tr>
    </table>
</form>
<ng-template #list>
    <div class="d-flex flex-column">
        <b class="text-danger" *ngIf="opciones.tipoMoneda=='USD'">No considerado</b>
        <b>Impuestos</b>
        <span *ngFor="let tax of currentProduct.impuestos">
            - {{tax.nombre}}, {{tax.valor}} %
        </span>
    </div>
</ng-template>
<ng-template #listaBuscaProducto let-d="dismiss">
    <div class="modal-xl">
        <div class="modal-header">
            <div class="modal-wizard-label">
                <div class="modal-wizard-title">Lista de productos disponibles</div>
                <div class="modal-wizard-desc">Seleccione un producto para asignar y luego agregar</div>
            </div>
            <button class="btn btn-success float-right" (click)="openCreateProductModal(createProductModal)"
                *ngIf="permissions.getPermission('30-60-10').asignado">
                <i class="flaticon-add-circular-button"></i>
                Agregar Producto</button>

        </div>
        <div class="headings-elements mt-0">
            <app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal"
                [config]="configLocal.barraBusqueda" (busqueda)="searchElementsByFilter($event)"
                [registroActivacion]="true" [productoExento]="true" [insideModal]="true"></app-search-bar>
        </div>
        <div class="modal-body">
            <ngx-datatable *ngIf="productRows.length>0" #productsTable [rows]="productRows" class="material"
                [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto"
                [externalPaging]="true" [count]="pageProducts.totalElements" [offset]="pageProducts.pageNumber"
                [limit]="pageProducts.pageSize" (page)="setPageProducts($event)" [selectionType]="SelectionType.single"
                (activate)="onActivate($event)"
                [messages]="{emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false}">
                <ngx-datatable-column name="nombre" [flexGrow]="3" [sortable]="false" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <span class="text-table-alignment">Nombre</span>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row"><span
                            class="text-table-alignment">{{row.nombre}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="precio" [flexGrow]="3" [sortable]="false" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <span class="number-table-alignment">Precio</span>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row"><span
                            class="number-table-alignment">{{row.precio | currency : '$ ' :
                            'symbol':'1.0-0':'es'}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="unidadMedida" [flexGrow]="1.8" [sortable]="false" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <span class="text-table-alignment">Unidad de Medida</span>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row"><span
                            class="text-table-alignment">{{row.unidadMedida?row.unidadMedida:"Sin unidad"}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="codigo" [flexGrow]="2" [sortable]="false" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <span class="text-table-alignment">Codigo</span>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row"><span
                            class="text-table-alignment">{{row.codigoBarra}}</span></ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="exento" [flexGrow]="2" [sortable]="false" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <span class="text-table-alignment">Exento</span>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row"><span
                            class="text-table-alignment">{{row.exento?"Exento":"Afecto"}}</span>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>

            <div *ngIf="productRows.length ==0">
                <span>No hay productos en la lista</span>
            </div>
        </div>
        <div class="modal-footer">

        </div>
    </div>
</ng-template>
<ng-template #createProductModal let-d="dismiss" let-c="close">
    <div class="modal-lg">
        <div class="modal-header">
            <div class="modal-wizard-label">
                <div class="modal-wizard-title">Ingreso de Producto</div>
                <div class="modal-wizard-desc">Ingrese los datos para un nuevo producto</div>
            </div>
        </div>
        <div class="modal-body">
            <app-product-entry (sendCloseRequestEmitter)="closeCreateModal()" [detailMode]="false"
                [useProductAndTaxes]="true" (sendProductRequest)="productWithTaxesRequest($event)"></app-product-entry>
        </div>
    </div>
</ng-template>