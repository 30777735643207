import { Injectable } from "@angular/core";
import { ActividadEconomica } from "../models/ActividadEconomica";
import { Ambiente } from "../models/Ambiente";
import { TipoCorreoEnvio } from "../models/CorreoEnvio/TipoCorreoEnvio";
import { TipoPlantillaCorreoEnvio } from "../models/CorreoEnvio/TipoPlantillaCorreoEnvio";
import { EstadoAcuseEmitido } from "../models/Dte/EstadoAcuseEmitido";
import { Retencion } from "../models/Dte/Retencion";
import { Emisor } from "../models/Emisor/Emisor";
import { EstadoSii } from "../models/EstadoSii";
import { Facturable } from "../models/Facturable";
import { Impuesto } from "../models/Impuesto";
import { OrigenImportacionMasiva } from "../models/MassInvoicing/OrigenImportacionMasiva";
import { Comuna } from "../models/Region/Comuna";
import { Region } from "../models/Region/Region";
import { Sucursal } from "../models/Sucursal";
import { TipoComision } from "../models/TipoComision";
import { TipoDespacho } from "../models/TipoDespacho";
import { TipoDocumentoReferencia } from "../models/TipoDocRef";
import { TipoDte } from "../models/tipoDte";
import { TipoImpuesto } from "../models/TipoImpuesto";
import { TipoTraslado } from "../models/TipoTraslado";
import { Usuario } from "../models/Usuario/Usuario";
import { UtilitiesService } from "./utilities.service";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LocalStorageService {
	private TIPODTE_AGRUPADO_KEY = "local-tipodte-agrupado";
	private TIPODTE_KEY = "local-tipodte";
	private EMISORES_KEY = "local-emisores";
	private SUCURSALES_KEY = "local-sucursales";
	private ORIGEN_IMPORTACION = "local-importacion";
	private TIPO_DOC_REFERENCIA = "local-tipo-doc-ref";
	private FORMATO_PAPEL = "local-formato-papel";
	private FORMA_PAGO = "local-forma-pago";
	private IMPUESTOS_PRODUCTO = "local-impuestos";
	private TIPO_IMPUESTOS_PRODUCTO = "local-tipo-impuestos";
	private TIPO_RETENCIONES = "local-tipo-retenciones";
	private TIPO_TRASLADOS = "local-tipo-traslados";
	private TIPO_DESPACHO = "local-tipo-despacho";
	private FACTURABLES = "local-facturables";
	private TIPO_COMISIONES = "local-tipo-comisiones";
	private ACTIVIDADES_ECONOMICAS = "local-actividades-economicas";
	private XML_TAGS = "local-xml-tags";
	private CODE_REFERENCES = "local-code-references";
	private TIPO_AMBIENTE = "local-tipo-ambiente";
	private TIPO_CORREO_ENVIO = "local-tipo-correo-envio";
	private TIPO_PLANTILLA_ENVIO_CORREO = "local-tipo-plantilla-correo-envio";
	private USUARIOS = "local-usuarios";
	private ESTADOS_SII = "local-estados-sii";
	private PROPIEDAD_LOGOS = "local-propiedad-logos";
	private SUCURSALES_COMPLETO = "local-sucursales-completo";
	private ESTADO_ACUSE_EMITIDOS = "local-acuse-emitidos";
	private REGIONES = "local-regiones";
	private COMUNAS = "local-comunas";
	localContainer = window.localStorage;
	private sizeFormat = [
		{ id: 0, name: "Carta", size: 16 },
		{ id: 1, name: "Semicarta", size: 8 },
		{ id: 2, name: "Ticket", size: 10000 },
	];
	private formaPago = [
		{ id: 1, nombreForma: "Contado" },
		{ id: 2, nombreForma: "Credito" },
		{ id: 3, nombreForma: "Sin Costo" },
	];

	environmentChanged: Subject<number> = new Subject();

	constructor(private utilities: UtilitiesService) {
		this.saveFormatoPapel();
		this.saveFormaPago();
	}

	public saveEstadosSii(estadoSii: EstadoSii[]) {
		this.localContainer.removeItem(this.ESTADOS_SII);
		this.localContainer.setItem(this.ESTADOS_SII, JSON.stringify(estadoSii));
	}

	public getEstadosSii() {
		return this.localContainer.getItem(this.ESTADOS_SII);
	}

	public saveTipoCorreoEnvio(correoEnvio: TipoCorreoEnvio[]) {
		this.localContainer.removeItem(this.TIPO_CORREO_ENVIO);
		this.localContainer.setItem(this.TIPO_CORREO_ENVIO, JSON.stringify(correoEnvio));
	}

	public getTipooCorreoEnvio() {
		return this.localContainer.getItem(this.TIPO_CORREO_ENVIO);
	}
	public saveTipoPlantillaoCorreoEnvio(plantilla: TipoPlantillaCorreoEnvio[]) {
		this.localContainer.removeItem(this.TIPO_PLANTILLA_ENVIO_CORREO);
		this.localContainer.setItem(this.TIPO_PLANTILLA_ENVIO_CORREO, JSON.stringify(plantilla));
	}

	public getTipoPlantillaoCorreoEnvio() {
		return this.localContainer.getItem(this.TIPO_PLANTILLA_ENVIO_CORREO);
	}
	public saveTipoAmbiente(ambiente: Ambiente[]) {
		this.localContainer.removeItem(this.TIPO_AMBIENTE);
		this.localContainer.setItem(this.TIPO_AMBIENTE, JSON.stringify(ambiente));
	}

	public getTipoAmbiente() {
		return this.localContainer.getItem(this.TIPO_AMBIENTE);
	}

	public saveActividadesEconomicas(actividades: ActividadEconomica[]) {
		this.localContainer.removeItem(this.ACTIVIDADES_ECONOMICAS);
		this.localContainer.setItem(this.ACTIVIDADES_ECONOMICAS, JSON.stringify(actividades));
	}

	public getActividadesEconomicas() {
		return this.localContainer.getItem(this.ACTIVIDADES_ECONOMICAS);
	}
	public saveTipoComisiones(tipoComisiones: TipoComision[]) {
		this.localContainer.removeItem(this.TIPO_COMISIONES);
		this.localContainer.setItem(this.TIPO_COMISIONES, JSON.stringify(tipoComisiones));
	}

	public getTipoComisiones() {
		return this.localContainer.getItem(this.TIPO_COMISIONES);
	}
	public saveFacturables(facturables: Facturable[]) {
		this.localContainer.removeItem(this.FACTURABLES);
		this.localContainer.setItem(this.FACTURABLES, JSON.stringify(facturables));
	}

	public getFacturables() {
		return this.localContainer.getItem(this.FACTURABLES);
	}
	public saveTipoTraslados(TipoTraslados: TipoTraslado[]) {
		this.localContainer.removeItem(this.TIPO_TRASLADOS);
		this.localContainer.setItem(this.TIPO_TRASLADOS, JSON.stringify(TipoTraslados));
	}

	public getTipoTraslados() {
		return this.localContainer.getItem(this.TIPO_TRASLADOS);
	}
	public saveTipoDespacho(tipoDespacho: TipoDespacho[]) {
		this.localContainer.removeItem(this.TIPO_DESPACHO);
		this.localContainer.setItem(this.TIPO_DESPACHO, JSON.stringify(tipoDespacho));
	}

	public getTipoDespacho() {
		return this.localContainer.getItem(this.TIPO_DESPACHO);
	}

	public saveTipoImpuestos(tipoImpuestos: TipoImpuesto[]) {
		this.localContainer.removeItem(this.TIPO_IMPUESTOS_PRODUCTO);
		this.localContainer.setItem(this.TIPO_IMPUESTOS_PRODUCTO, JSON.stringify(tipoImpuestos));
	}

	public getTipoImpuestos() {
		return this.localContainer.getItem(this.TIPO_IMPUESTOS_PRODUCTO);
	}

	public saveDteTypes(dteList: TipoDte[]) {
		//console.log(dteList);
		this.localContainer.removeItem(this.TIPODTE_KEY);
		this.localContainer.setItem(this.TIPODTE_KEY, JSON.stringify(dteList));
	}

	public getDteTypes() {
		return this.localContainer.getItem(this.TIPODTE_KEY);
	}

	public saveEmisores(emisoresList: Emisor[]) {
		this.utilities.sortAlphabeth(emisoresList, "razonSocial");
		this.localContainer.removeItem(this.EMISORES_KEY);
		this.localContainer.setItem(this.EMISORES_KEY, JSON.stringify(emisoresList));
	}
	public getEmisores() {
		return this.localContainer.getItem(this.EMISORES_KEY);
	}

	public saveSucursales(sucursalesList: Sucursal[]) {
		this.utilities.sortAlphabeth(sucursalesList, "nombre");
		this.localContainer.removeItem(this.SUCURSALES_KEY);
		this.localContainer.setItem(this.SUCURSALES_KEY, JSON.stringify(sucursalesList));
	}

	public getSucursales() {
		return this.localContainer.getItem(this.SUCURSALES_KEY);
	}

	public saveOrigenImportaciones(listaImportaciones: OrigenImportacionMasiva[]) {
		this.localContainer.removeItem(this.ORIGEN_IMPORTACION);
		this.localContainer.setItem(this.ORIGEN_IMPORTACION, JSON.stringify(listaImportaciones));
	}

	public getOrigenImportaciones() {
		return this.localContainer.getItem(this.ORIGEN_IMPORTACION);
	}

	public saveTipoDocReferencias(tipos: TipoDocumentoReferencia[]) {
		this.localContainer.removeItem(this.TIPO_DOC_REFERENCIA);
		this.localContainer.setItem(this.TIPO_DOC_REFERENCIA, JSON.stringify(tipos));
	}

	public getTipoDocReferencias() {
		return this.localContainer.getItem(this.TIPO_DOC_REFERENCIA);
	}

	public saveFormatoPapel() {
		this.localContainer.removeItem(this.FORMATO_PAPEL);
		this.localContainer.setItem(this.FORMATO_PAPEL, JSON.stringify(this.sizeFormat));
	}

	public getFormatoPapel() {
		return this.localContainer.getItem(this.FORMATO_PAPEL);
	}

	public saveFormaPago() {
		this.localContainer.removeItem(this.FORMA_PAGO);
		this.localContainer.setItem(this.FORMA_PAGO, JSON.stringify(this.formaPago));
	}

	public getFormaPago() {
		return this.localContainer.getItem(this.FORMA_PAGO);
	}

	public saveImpuestos(impuestos: Impuesto[]) {
		this.localContainer.removeItem(this.IMPUESTOS_PRODUCTO);
		this.localContainer.setItem(this.IMPUESTOS_PRODUCTO, JSON.stringify(impuestos));
	}

	public getImpuestos() {
		return this.localContainer.getItem(this.IMPUESTOS_PRODUCTO);
	}

	public saveTipoRetenciones(tiposRetenciones: Retencion[]) {
		this.localContainer.getItem(this.TIPO_RETENCIONES);
		this.localContainer.setItem(this.TIPO_RETENCIONES, JSON.stringify(tiposRetenciones));
	}

	public getTiposRetenciones() {
		return this.localContainer.getItem(this.TIPO_RETENCIONES);
	}

	public saveXmlTagList(xmlTagList) {
		this.localContainer.removeItem(this.XML_TAGS);
		this.localContainer.setItem(this.XML_TAGS, JSON.stringify(xmlTagList));
	}

	public saveCodeReferenceList(codeRefList) {
		this.localContainer.removeItem(this.CODE_REFERENCES);
		this.localContainer.setItem(this.CODE_REFERENCES, JSON.stringify(codeRefList));
	}

	public updateAmbienteOfEmisor(emisorId: string, ambiente: number) {
		let emisores: Emisor[] = JSON.parse(this.getEmisores()) as Emisor[];
		let index = emisores.findIndex((em) => em.emisorId == emisorId);
		if (emisores[index] != undefined) {
			emisores[index].ambiente = ambiente;
		}
		this.localContainer.removeItem(this.EMISORES_KEY);
		this.localContainer.setItem(this.EMISORES_KEY, JSON.stringify(emisores));

		this.environmentChanged.next(ambiente);
		return emisorId;
	}

	public saveListaUsuarios(lista: Usuario[]) {
		lista.forEach((u) => {
			u.nombreCompleto = u.nombre + " " + u.apellidos;
		});
		this.localContainer.removeItem(this.USUARIOS);
		this.localContainer.setItem(this.USUARIOS, JSON.stringify(lista));
	}

	public getListaUsuarios(): Usuario[] {
		return JSON.parse(this.localContainer.getItem(this.USUARIOS)) as Usuario[];
	}

	public saveAllSucursales(all: Sucursal[]) {
		this.localContainer.removeItem(this.SUCURSALES_COMPLETO);
		this.localContainer.setItem(this.SUCURSALES_COMPLETO, JSON.stringify(all));
	}

	public getAllSucursales(): Sucursal[] {
		return JSON.parse(this.localContainer.getItem(this.SUCURSALES_COMPLETO)) as Sucursal[];
	}

	public saveEstadoAcuseEmitidos(lista: EstadoAcuseEmitido[]) {
		this.localContainer.removeItem(this.ESTADO_ACUSE_EMITIDOS);
		this.localContainer.setItem(this.ESTADO_ACUSE_EMITIDOS, JSON.stringify(lista));
	}

	public getEstadoAcuseEmitidos(): EstadoAcuseEmitido[] {
		return JSON.parse(this.localContainer.getItem(this.ESTADO_ACUSE_EMITIDOS)) as EstadoAcuseEmitido[];
	}

	public saveRegionesComunas(lista: Region[]) {
		this.localContainer.removeItem(this.REGIONES);
		this.localContainer.setItem(this.REGIONES, JSON.stringify(lista));
		let comunasArray = lista.map((region) => region.comunas);
		let soloComunas = comunasArray.reduce((comunaA, comunaB) => [...comunaA, ...comunaB], []);
		this.localContainer.removeItem(this.COMUNAS);
		this.localContainer.setItem(this.COMUNAS, JSON.stringify(soloComunas));
	}

	public getRegiones(): Region[] {
		return JSON.parse(this.localContainer.getItem(this.REGIONES)) as Region[];
	}

	public getComunas(): Comuna[] {
		return JSON.parse(this.localContainer.getItem(this.COMUNAS)) as Comuna[];
	}

	public getRegionOfComuna(regionId: string): Region {
		let regiones = this.getRegiones();
		let currentRegion = regiones.find((region) => region.regionId == regionId);
		return currentRegion;
	}
}
