<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
<swal #notesSwal title="Operación" text="Operación realizada" icon="info"></swal>

<!-- <h1>{{fileIdValue ? fileIdValue: 'Sin id por ruta'}}</h1> -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal" [config]="configLocal.barraBusqueda" [useAccordionMode]="true" [useInlineDeactivatedCheckbox]="true" [useInlineSearchButton]="true" [listaCompletaEmisores]="emisoresList" [mostrarListaCompletaEmisores]="true" (busqueda)="searchElementsByProfileFilter($event)" [registroActivacion]="true" [filtroEmisor]="true" [emisorClearable]="isSuperAdmin" [sucursalClearable]="false"> </app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">{{ configLocal.table.title }}</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar" *ngIf="permissions.getPermission('30-50-12').asignado">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
								</div>

								<div class="heading-elements mt-0">
									<button class="btn btn-success float-right mr-5 mt-5" (click)="addProfileModal(addProfileM)" *ngIf="permissions.getPermission('30-50-10').asignado">
										<i class="flaticon-add-circular-button"></i>
										Agregar Perfil
									</button>
								</div>
								<div class="heading-elements mt-0">
									<button class="btn btn-info float-right mr-5 mt-5" (click)="MantenedorPermissionModal(mantenedorPermission)" *ngIf="isSuperAdmin">
										<i class="flaticon2-writing"></i>
										Mantenedor Permisos
									</button>
								</div>
							</div>
							<!-- <div class="col-1" >permissions.getPermission('30-50-20').asignado
                              <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change) ="setPageSize()">
                                 <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                              </ng-select>
                         </div> -->
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedorProfile"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPageProfile($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>

										<ngx-datatable-column name="nombre" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombre }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Editar Permisos" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Editar_Permisos" (click)="permissionList(row)" *ngIf="permissions.getPermission('30-50-14').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="id-card" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Editar Perfil" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Editar_Perfil" (click)="editModal(EditProfileModal, row)" *ngIf="permissions.getPermission('30-50-16').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Asignar Empresas" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Asignar_Emisores" (click)="openAsignarPerfilModal(assignPerfilModal, row)" *ngIf="permissions.getPermission('30-50-17').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="building" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Desactivar Perfil" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Desactivar_Perfil" (click)="deactivateProfile(row)" *ngIf="row.activo && permissions.getPermission('30-50-18').asignado">
													<div
														class="icon-circle bg-danger text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea desactivar este perfil?',
															confirmButtonText: 'Sí, desactivar',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="deactivateProfile(row)">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Activar Perfil" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Activar_Perfil" (click)="activateProfile(row)" *ngIf="!row.activo && permissions.getPermission('30-50-18').asignado">
													<div
														class="icon-circle bg-success text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea reactivar este perfil?',
															confirmButtonText: 'Sí, reactivar',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="activateProfile(row)">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla seccion tabla -->

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedorProfile"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPageProfile($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<ngx-datatable-column name="Razón Social" [flexGrow]="5.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombre }}</span></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Editar Permisos" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Editar_Permisos" (click)="permissionList(row)" *ngIf="permissions.getPermission('30-50-14').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="id-card" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Editar Perfil" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Editar_Perfil" (click)="editModal(EditProfileModal, row)" *ngIf="permissions.getPermission('30-50-16').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Asignar Emisores" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Asignar_Emisores" (click)="openAsignarPerfilModal(assignPerfilModal, row)" *ngIf="permissions.getPermission('30-50-16').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="building" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Desactivar Perfil" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Desactivar_Perfil" (click)="deactivateProfile(row)" *ngIf="row.activo && permissions.getPermission('30-50-18').asignado">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Activar Perfil" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Activar_Perfil" (click)="activateProfile(row)" *ngIf="!row.activo && permissions.getPermission('30-50-18').asignado">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #PermissionsModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Permisos del Perfil {{ profileToShow.nombre }}</h5>
		</div>
		<div class="modal-body">
			<h4>Permisos Asignados</h4>
			<form (ngSubmit)="updatePermissions(updateForm)" #updateForm="ngForm">
				<div class="modal-body">
					<ul>
						<li *ngFor="let item of profileToShow.permisos"><input type="checkbox" name="item.nombre" value="{{ item.nombre }}" [checked]="item.asignado" (change)="changeValueChbx($event, item)" /> {{ item.nombre }}</li>
					</ul>
				</div>
				<div class="modal-footer">
					<alert id="alert-errormodal"></alert>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
							<i class="la la-paper-plane-o d-lg-none"></i>
							<span class="d-none d-lg-block">Guardar</span>
						</button>
					</div>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>

<ng-template #mantenedorPermission let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Mantenedor Permisos</h5>
			<button class="btn btn-warning float-right mr-5 mt-5" (click)="addPermissionModal(addPermissionM)" *ngIf="permissions.getPermission('30-50-20-10').asignado">
				<i class="flaticon-add-circular-button"></i>
				Agregar Permiso
			</button>
		</div>

		<div class="heading-elements mt-0">
			<app-search-bar [criterioPrincipal]="configLocalPermission.barraBusqueda.criterioPrincipal" [config]="configLocalPermission.barraBusqueda" (busqueda)="searchElementsByPermissionFilter($event)" [registroActivacion]="true" [insideModal]="true"> </app-search-bar>
		</div>
		<div class="col-2"></div>
		<div class="modal-body">
			<ngx-datatable #tablePermissions class="material" [rows]="rowsMantenedorPermission" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" [externalPaging]="true" [count]="pagePermission.totalElements" [offset]="pagePermission.pageNumber" [limit]="pagePermission.pageSize" (page)="setPagePermission($event)" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos' | translate, selectedMessage: false }">
				<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>
				<ngx-datatable-column name="Codigo" [flexGrow]="2" [sortable]="false" [resizeable]="false">
					<ng-template let-column="column" ngx-datatable-header-template>
						<span class="text-table-alignment">Codigo</span>
					</ng-template>
					<ng-template ngx-datatable-cell-template let-row="row"
						><span class="text-table-alignment">{{ row.codigo }}</span></ng-template
					>
				</ngx-datatable-column>
				<ngx-datatable-column name="Nombre" [flexGrow]="4" [sortable]="false" [resizeable]="false">
					<ng-template let-column="column" ngx-datatable-header-template>
						<span class="text-table-alignment">Nombre</span>
					</ng-template>
					<ng-template ngx-datatable-cell-template let-row="row"
						><span class="text-table-alignment">{{ row.nombre }}</span></ng-template
					>
				</ngx-datatable-column>

				<ngx-datatable-column name="Acciones" [flexGrow]="2" [sortable]="false" [resizeable]="false">
					<ng-template ngx-datatable-cell-template let-row="row">
						<a [routerLink]="" ngbTooltip="Editar Permiso" class="primary edit mr-1 mrless" id="mantenedor-perfiles.component_Editar_Permiso" (click)="editPermissionModal(EditPermission, row)" *ngIf="permissions.getPermission('30-50-20-12').asignado">
							<div class="icon-circle bg-info text-center align-middle pt-info-button" *ngIf="row.idItemPlan != 0">
								<fa-icon icon="edit" class="fa-lg text-white"></fa-icon>
							</div>
						</a>
						<a
							[routerLink]=""
							ngbTooltip="Desactivar Permiso"
							class="primary edit mr-1 mrless"
							*ngIf="row.activo && permissions.getPermission('30-50-20-14').asignado"
							[swal]="{
								title: 'Confirmación',
								text: '¿Está seguro de desactivar este permiso?',
								confirmButtonText: 'Desactivar',
								cancelButtonText: 'Cancelar',
								showCancelButton: true,
								confirmButtonColor: '#F64E60'
							}"
							(confirm)="deactivatePermission(row)">
							<div class="icon-circle bg-danger text-center align-middle pt-info-button">
								<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
							</div>
						</a>
						<a
							[routerLink]=""
							ngbTooltip="Activar Permiso"
							class="primary edit mr-1 mrless"
							*ngIf="!row.activo && permissions.getPermission('30-50-20-14').asignado"
							[swal]="{
								title: 'Confirmación',
								text: '¿Está seguro de activar este permiso?',
								confirmButtonText: 'Activar',
								cancelButtonText: 'Cancelar',
								showCancelButton: true,
								confirmButtonColor: '#1BC5BD'
							}"
							(confirm)="activatePermission(row)">
							<div class="icon-circle bg-success text-center align-middle pt-info-button">
								<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
							</div>
						</a>
					</ng-template>
				</ngx-datatable-column>
				<ngx-datatable-footer>
					<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
						<div class="col-auto">Elementos por página</div>
						<div class="mr-0">
							<ng-select [(ngModel)]="pagePermission.pageSize" [items]="pagePermission.pageSizes" [clearable]="false" (change)="setPageSizePermission()" appendTo=".modal-body datatable-footer" style="overflow: visible; z-index: 1051">
								<option *ngFor="let option of pagePermission.pageSizes" [value]="option"></option>
							</ng-select>
						</div>
						<div class="ml-0 col-auto divisor-footer">
							<div class="page-count">{{ rowCount }} Elementos totales</div>
						</div>
						<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tablePermissions.onFooterPage($event)"> </datatable-pager>
					</ng-template>
				</ngx-datatable-footer>
			</ngx-datatable>
		</div>
		<div class="modal-footer">
			<alert id="alert-errormodal"></alert>

			<div class="form-group position-relative has-icon-left mb-0">
				<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #EditProfileModal let-d="dismiss">
	<form (ngSubmit)="editProfile(editForm)" #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Editar Perfil</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombreNuevo"
							placeholder="Ingrese un nombre"
							class="form-control"
							#nombrePerfil="ngModel"
							[(ngModel)]="profileToEdit.nombre"
							[ngClass]="{
								'is-invalid': editForm.submitted && nombrePerfil.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && nombrePerfil.invalid" class="invalid-feedback-group">
							<div *ngIf="nombrePerfil.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" *ngIf="fullListPermission">
					<label for="superAdminCBX" class="col-3 label-control">Super Administrador</label>
					<div class="col-9">
						<input type="checkbox" name="superAdminCheckbox" class="input-checkbox" id="superAdminCBX" [(ngModel)]="profileToEdit.isSuperAdmin" />
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="editForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #addProfileM let-d="dismiss">
	<form (ngSubmit)="addProfile(addForm)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Agregar Perfil</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombreNuevo"
							placeholder="Ingrese un nombre"
							class="form-control"
							#nombrePerfil="ngModel"
							[(ngModel)]="profileToAdd.nombre"
							[ngClass]="{
								'is-invalid': addForm.submitted && nombrePerfil.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && nombrePerfil.invalid" class="invalid-feedback-group">
							<div *ngIf="nombrePerfil.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" *ngIf="fullListPermission">
					<label for="superAdminCBX" class="col-3 label-control">Super Administrador</label>
					<div class="col-9">
						<input type="checkbox" name="superAdminCheckbox" class="input-checkbox" id="superAdminCBX" [(ngModel)]="profileToAdd.isSuperAdmin" />
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="addForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #EditPermission let-d="dismiss">
	<form (ngSubmit)="editPermission(editForm)" #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Editar Permiso</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Codigo</label>
					<div class="col-9">
						<input
							type="text"
							name="codigoNuevo"
							placeholder="Ingrese un codigo"
							class="form-control"
							#codigoPermiso="ngModel"
							[(ngModel)]="permissionToEdit.codigo"
							[ngClass]="{
								'is-invalid': editForm.submitted && codigoPermiso.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && codigoPermiso.invalid" class="invalid-feedback-group">
							<div *ngIf="codigoPermiso.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombreNuevo"
							placeholder="Ingrese un nombre"
							class="form-control"
							#nombrePermiso="ngModel"
							[(ngModel)]="permissionToEdit.nombre"
							[ngClass]="{
								'is-invalid': editForm.submitted && nombrePermiso.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && nombrePermiso.invalid" class="invalid-feedback-group">
							<div *ngIf="nombrePermiso.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="editForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #addPermissionM let-d="dismiss">
	<form (ngSubmit)="addPermission(addForm)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Agregar Permiso</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Codigo</label>
					<div class="col-9">
						<input
							type="text"
							name="codigoNuevo"
							placeholder="Ingrese un codigo"
							class="form-control"
							#codigoPermiso="ngModel"
							[(ngModel)]="permissionToAdd.codigo"
							[ngClass]="{
								'is-invalid': addForm.submitted && codigoPermiso.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && codigoPermiso.invalid" class="invalid-feedback-group">
							<div *ngIf="codigoPermiso.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombreNuevo"
							placeholder="Ingrese un nombre"
							class="form-control"
							#nombrePermiso="ngModel"
							[(ngModel)]="permissionToAdd.nombre"
							[ngClass]="{
								'is-invalid': addForm.submitted && nombrePermiso.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && nombrePermiso.invalid" class="invalid-feedback-group">
							<div *ngIf="nombrePermiso.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="addForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #assignPerfilModal let-d="dismiss">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Asignación de Perfil a Empresas</div>
				<div class="modal-wizard-desc">Seleccione un emisor para asignar el perfil actual</div>
			</div>
		</div>
		<div class="modal-body">
			<app-asignador-perfil [perfilSelected]="selectedPerfil" (sendCloseRequestEmitter)="closeAsignarPerfilModal()"> </app-asignador-perfil>
		</div>
	</div>
</ng-template>
