import { Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class FormrulesConfigService {
   //Login
   passwordMinLength: number = 8;
   passwordMaxLength: number = 32;
   passwordPatternRegex: RegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[\W_]).*$/);
   emailPatternRegex: RegExp = new RegExp(/[a-zA-Z0-9.!_~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/);
}
