<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"> </ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar [config]="configLocal.barraBusqueda" [filtroDTE]="true" [useAccordionMode]="true" [mostrarListaCompletaEmisores]="true" [registroActivacion]="true" [filtroEmisor]="true" [useInlineSearchButton]="true" [listaCompletaEmisores]="emisorSource" [useInlineDeactivatedCheckbox]="true" [filtroSucursal]="true" (busqueda)="searchElementsByFilter($event)" [emisorClearable]="false" [sucursalClearable]="false"> </app-search-bar>
				<div class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Timbrajes</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<div class="float-right mt-5" *ngIf="true">
										<button *ngIf="permissions.getPermission('20-10-10').asignado" class="btn btn-success float-right mr-5" (click)="openCargarFoliosModalFromEmitter()">
											<i class="flaticon-add-circular-button"></i>
											<span class="menu-text">Cargar Folios</span>
										</button>
									</div>
									<div class="float-right mt-5" *ngIf="true">
										<button *ngIf="permissions.getPermission('20-10-14').asignado" class="btn btn-info float-right mr-5" (click)="openConsultaFoliosModal(consultaFolios)">
											<i class="flaticon-add-circular-button"></i>
											<span class="menu-text">Obtener Folios</span>
										</button>
									</div>
								</div>
							</div>

							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										*ngIf="componentStarted"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column name="nombreTipoDte" [flexGrow]="5" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo Dte</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoDte }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="fechaCaf" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Fecha CAF</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.fechaCaf | date : "dd/MM/yyyy" }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="desde" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Desde</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.desde }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="hasta" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Hasta</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.hasta }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="foliosDisponibles" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Folios Disponibles</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row">
												<span class="text-table-alignment">
													{{ row.foliosDisponibles }}
												</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="foliosSinUsar" [flexGrow]="3" [sortable]="false" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Folios bloqueados</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row">
												<span class="text-table-alignment">
													{{ row.foliosSinUsar }}
												</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="2" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a
													*ngIf="row.activo && permissions.getPermission('20-10-18').asignado && row.borrarFolioBloqueado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desbloquear Folios Bloqueados"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desbloquear los folios bloqueados del timbraje?',
														confirmButtonText: 'Continuar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deleteBlocked(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="unlock" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="permissions.getPermission('20-10-16').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Anular Folios"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de anular estos folios?',
														confirmButtonText: 'Anular',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="sendConsultaAnulacionRequest(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="85"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Tipo Documento: {{ row.nombreTipoDte }} , Fecha CAF: {{ row.fechaCaf | date : "dd/MM/yyyy" }} ,Desde: {{ row.desde }}, Hasta: {{ row.hasta }}</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="tipo" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo Documento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.nombreTipoDte }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="disponibles" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Folios Restantes</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.foliosDisponibles }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="3.3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a
													*ngIf="permissions.getPermission('20-10-18').asignado && row.borrarFolioBloqueado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Eliminar Folios Bloqueados"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de eliminar los folios bloqueados del timbraje?',
														confirmButtonText: 'Anular',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deleteBlocked(row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="permissions.getPermission('20-10-16').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Anular Folios"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de anular estos folios?',
														confirmButtonText: 'Anular',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="sendConsultaAnulacionRequest(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #consultaFolios let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Consulta de Folios</div>
				<div class="modal-wizard-desc">Ingrese los datos del tipo de folios a buscar</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="consultaFoliosForm" #consultaFoliosFormRef="ngForm" (ngSubmit)="sendConsultarFoliosDisponibles()">
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Tipo de Documento</label>
						<div class="col-9">
							<ng-select
								[items]="tipoDteSource"
								[multiple]="false"
								placeholder="Seleccione Tipo de documento"
								formControlName="tipoDteConsulta"
								[clearable]="false"
								bindLabel="nombre"
								[ngClass]="{
									'is-invalid': consultaFoliosFormRef.submitted && consultaFoliosForm.get('tipoDteConsulta').invalid
								}"></ng-select>
							<div *ngIf="consultaFoliosFormRef.submitted && (consultaFoliosForm.get('tipoDteConsulta').invalid || consultaFoliosForm.get('tipoDteConsulta').dirty)" class="invalid-feedback">
								<div *ngIf="consultaFoliosForm.get('tipoDteConsulta').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Empresa</label>
						<div class="col-9" *ngIf="superAdm">
							<ng-select
								[items]="empresaSource"
								[multiple]="false"
								placeholder="Seleccione empresa"
								formControlName="empresa"
								[clearable]="false"
								(change)="updateSucursal()"
								bindLabel="razonSocial"
								[ngClass]="{
									'is-invalid': consultaFoliosFormRef.submitted && consultaFoliosForm.get('empresa').invalid
								}"></ng-select>
							<div *ngIf="consultaFoliosFormRef.submitted && (consultaFoliosForm.get('empresa').invalid || consultaFoliosForm.get('empresa').dirty)" class="invalid-feedback">
								<div *ngIf="consultaFoliosForm.get('empresa').errors?.required">Campo requerido</div>
							</div>
						</div>
						<div class="col-9" *ngIf="!superAdm">
							<input class="form-control" value="{{ consultaFoliosForm.get('empresa').value.razonSocial }}" disabled />
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Sucursal</label>
						<div class="col-9">
							<ng-select
								[items]="sucursalesSource"
								[multiple]="false"
								placeholder="Seleccione sucursal"
								formControlName="sucursal"
								[clearable]="false"
								bindLabel="nombre"
								[ngClass]="{
									'is-invalid': consultaFoliosFormRef.submitted && consultaFoliosForm.get('sucursal').invalid
								}"></ng-select>
							<div *ngIf="consultaFoliosFormRef.submitted && (consultaFoliosForm.get('sucursal').invalid || consultaFoliosForm.get('sucursal').dirty)" class="invalid-feedback">
								<div *ngIf="consultaFoliosForm.get('sucursal').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-success">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Consultar</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cross click')">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Cancelar</span>
				</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #cargarFolios let-d="dimiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Carga de Folios</div>
				<div class="modal-wizard-desc">Seleccione un archivo CAF de folios para la empresa y sucursal correspondiente</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="cargarFoliosForm" #cargaFoliosFormRef="ngForm" (ngSubmit)="getFormToCargaFolioRequest()">
			<div class="modal-body">
				<div class="form-group">
					<ngx-dropzone #drop (change)="onFileChangeCAF($event)" accept="text/xml" [multiple]="false">
						<ngx-dropzone-label class="px-5">
							<i class="flaticon-upload"></i>

							<p>Arrastra o selecciona el archivo de timbraje de folios.</p>

							<p>El formato del archivo debe ser .xml</p>
						</ngx-dropzone-label>
						<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
							<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
						</ngx-dropzone-preview>
					</ngx-dropzone>
					<div class="form-group row mx-auto" style="align-items: baseline" [hidden]="!fileIsValid">
						<div class="col-6">
							<div class="row form-group">
								<div class="col-6">
									<span>RUT EMISOR:</span>
								</div>
								<div class="col-6">
									<span>{{ currentObject?.rutEmisor }}</span>
								</div>
							</div>
							<div class="row form-group">
								<div class="col-6">
									<span>Tipo de DTE:</span>
								</div>
								<div class="col-6">
									<span>{{ currentObject?.tipoDte }}</span>
								</div>
							</div>
							<div class="row form-group">
								<div class="col-6">
									<span>Fecha CAF:</span>
								</div>
								<div class="col-6">
									<span>{{ currentObject?.fechaCaf }}</span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="row form-group">
								<div class="col-6">
									<span>Folio desde:</span>
								</div>
								<div class="col-6">
									<span>{{ currentObject?.desde }}</span>
								</div>
							</div>
							<div class="row form-group">
								<div class="col-6">
									<span>Folio hasta:</span>
								</div>
								<div class="col-6">
									<span>{{ currentObject?.hasta }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-3 label-control">Empresa</label>
						<div class="col-9" *ngIf="superAdm">
							<ng-select
								[items]="empresaSource"
								[multiple]="false"
								placeholder="Seleccione empresa"
								formControlName="empresa"
								[clearable]="false"
								(change)="updateSucursalCarga()"
								bindLabel="razonSocial"
								[ngClass]="{
									'is-invalid': cargaFoliosFormRef.submitted && cargarFoliosForm.get('empresa').invalid
								}"></ng-select>
							<div *ngIf="cargaFoliosFormRef.submitted && (cargarFoliosForm.get('empresa').invalid || cargarFoliosForm.get('empresa').dirty)" class="invalid-feedback">
								<div *ngIf="cargarFoliosForm.get('empresa').errors?.required">Campo requerido</div>
							</div>
						</div>
						<div class="col-9" *ngIf="!superAdm">
							<input class="form-control" value="{{ cargarFoliosForm.get('empresa').value.razonSocial }}" disabled />
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<label class="col-3 label-control">Sucursal</label>
						<div class="col-9" *ngIf="superAdm">
							<ng-select
								[items]="sucursalesSource"
								[multiple]="false"
								placeholder="Seleccione sucursal"
								formControlName="sucursal"
								[clearable]="false"
								bindLabel="nombre"
								[ngClass]="{
									'is-invalid': cargaFoliosFormRef.submitted && cargarFoliosForm.get('sucursal').invalid
								}"></ng-select>
							<div *ngIf="cargaFoliosFormRef.submitted && (cargarFoliosForm.get('sucursal').invalid || cargarFoliosForm.get('sucursal').dirty)" class="invalid-feedback">
								<div *ngIf="cargarFoliosForm.get('sucursal').errors?.required">Campo requerido</div>
							</div>
						</div>
						<div class="col-9" *ngIf="!superAdm">
							<input class="form-control" value="{{ cargarFoliosForm.get('sucursal').value.nombre }}" disabled />
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col-9">
							<small>El CAF a ingresar debe tener todos los folios disponibles, es decir, no debe haber sido utilizado por otro proveedor.</small>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-success">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Cargar</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="c('Cross click')">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Cancelar</span>
				</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #obtieneFolios let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Obtención de Folios</div>
				<div class="modal-wizard-desc">Ingrese los datos del tipo y numero de folios a obtener</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="obtenerFoliosForm" #obtenerFoliosFormRef="ngForm" (ngSubmit)="sendObtenerFoliosRequest()">
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Tipo de Documento</label>
						<div class="col-9">
							<ng-select
								[items]="tipoDteSource"
								[multiple]="false"
								placeholder="Seleccione Tipo de documento"
								formControlName="tipoDte"
								[clearable]="false"
								bindLabel="nombre"
								[ngClass]="{
									'is-invalid': obtenerFoliosFormRef.submitted && obtenerFoliosForm.get('tipoDte').invalid
								}"></ng-select>
							<div *ngIf="obtenerFoliosForm.get('tipoDte').invalid && (obtenerFoliosFormRef.submitted || obtenerFoliosForm.get('tipoDte').dirty)" class="invalid-feedback">
								<div *ngIf="obtenerFoliosForm.get('tipoDte').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Empresa</label>
						<div class="col-9">
							<!-- <ng-select [items]="empresaSource" [multiple]="false"
                                    placeholder="Seleccione empresa" formControlName="empresa" [clearable]="false"
                                    bindLabel="razonSocial" [ngClass]="{
                                    'is-invalid': obtenerFoliosFormRef.submitted && obtenerFoliosForm.get('empresa').invalid
                                }"></ng-select>
                                <div *ngIf="obtenerFoliosForm.get('empresa').invalid &&( obtenerFoliosFormRef.submitted || obtenerFoliosForm.get('empresa').dirty)"
                                    class="invalid-feedback">
                                    <div *ngIf="obtenerFoliosForm.get('empresa').errors?.required">Campo requerido</div>
                                </div> -->
							<input class="form-control" value="{{ obtenerFoliosForm.get('empresa').value.razonSocial }}" disabled />
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Sucursal</label>
						<div class="col-9">
							<!-- <ng-select [items]="sucursalesSource" [multiple]="false"
                                    placeholder="Seleccione sucursal" formControlName="sucursal" [clearable]="false"
                                    bindLabel="nombre" [ngClass]="{
                                    'is-invalid': obtenerFoliosFormRef.submitted && obtenerFoliosForm.get('sucursal').invalid
                                }"></ng-select>
                                <div *ngIf="obtenerFoliosForm.get('sucursal').invalid &&( obtenerFoliosFormRef.submitted || obtenerFoliosForm.get('sucursal').dirty)"
                                    class="invalid-feedback">
                                    <div *ngIf="obtenerFoliosForm.get('sucursal').errors?.required">Campo requerido</div>
                                </div> -->
							<input class="form-control" value="{{ obtenerFoliosForm.get('sucursal').value.nombre }}" disabled />
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="offset-md-3">
						<span class="font-weight-bold"
							>Folios disponibles a solicitar:
							<span *ngIf="cantidadDisponibleObtener && cantidadDisponibleObtener > 0">{{ cantidadDisponibleObtener }}</span>
							<span *ngIf="(!cantidadDisponibleObtener || cantidadDisponibleObtener == 0) && !limitedAmount">Sin límite</span>
							<span *ngIf="(!cantidadDisponibleObtener || cantidadDisponibleObtener == 0) && limitedAmount" class="text-danger">Sin folios disponibles</span>
						</span>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Numero de Folios</label>
						<div class="col-9">
							<input type="number" class="form-control" formControlName="numeroFolios" placeholder="Indique el numero de folios a solicitar" id="numeroFolios" />
							<div *ngIf="obtenerFoliosForm.get('numeroFolios').invalid && (obtenerFoliosFormRef.submitted || obtenerFoliosForm.get('numeroFolios').dirty)" class="invalid-feedback">
								<div *ngIf="obtenerFoliosForm.get('numeroFolios').errors?.required">Campo requerido</div>
								<div *ngIf="obtenerFoliosForm.get('numeroFolios').errors?.max">No puede superar la cantidad maxima de folios disponibles</div>
								<div *ngIf="obtenerFoliosForm.get('numeroFolios').errors?.min">Debe solicitar por lo menos un folio</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-success">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Solicitar</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cross click')">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Cancelar</span>
				</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #anulaFolios let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Anulación de Folios</div>
				<div class="modal-wizard-desc">Ingrese los datos del tipo y numero de folios a obtener</div>
			</div>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<form [formGroup]="anularFoliosForm" #anularFoliosFormRef="ngForm" (ngSubmit)="sendAnulaFoliosRequest()">
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Tipo de Documento</label>
						<div class="col-9">
							<ng-select
								[items]="tipoDteSource"
								[multiple]="false"
								placeholder="Seleccione Tipo de documento"
								formControlName="tipoDteAnulacion"
								[clearable]="false"
								bindLabel="nombre"
								[ngClass]="{
									'is-invalid': anularFoliosFormRef.submitted && anularFoliosForm.get('tipoDteAnulacion').invalid
								}"></ng-select>
							<div *ngIf="anularFoliosForm.get('tipoDteAnulacion').invalid && (anularFoliosFormRef.submitted || anularFoliosForm.get('tipoDteAnulacion').dirty)" class="invalid-feedback">
								<div *ngIf="anularFoliosForm.get('tipoDteAnulacion').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-group position-relative mx-auto">
					<div class="offset-md-3 pl-1">
						<span class="font-weight-bold pl-2">Folios disponibles a anular desde {{ minDesdeAnulacion }} al {{ maxHastaAnulacion }}: </span>
					</div>
					<div *ngFor="let rango of respuestaFoliosAnulables" class="offset-md-3 pl-1">
						<span class="font-weight-bold pl-2"> Rango disponible para anular desde {{ rango.desde }} al {{ rango.hasta }} </span>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Rango a anular</label>
						<div class="col-9">
							<ng-select [items]="respuestaFoliosAnulables" [clearable]="false" formControlName="rangoAnulacion" (change)="selectRango($event)">
								<ng-template ng-label-tmp let-item="item">
									<span class="font-weight-bold pl-2"> Rango disponible para anular desde {{ item.desde }} al {{ item.hasta }} </span>
								</ng-template>
								<ng-template ng-option-tmp let-item="item" let-index="index">
									<span class="font-weight-bold pl-2"> Rango disponible para anular desde {{ item.desde }} al {{ item.hasta }} </span>
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Folio Desde</label>
						<div class="col-9">
							<input type="number" class="form-control" formControlName="desdeFolio" placeholder="Indique el folio desde que comenzará a anular" id="desdeFolio" />
							<div *ngIf="anularFoliosForm.get('desdeFolio').invalid && (anularFoliosFormRef.submitted || anularFoliosForm.get('desdeFolio').dirty)" class="text-danger">
								<div *ngIf="anularFoliosForm.get('desdeFolio').errors?.required">Campo requerido</div>
								<div *ngIf="anularFoliosForm.get('desdeFolio').errors?.max">No puede superar la cantidad maxima de folios disponibles</div>
								<div *ngIf="anularFoliosForm.get('desdeFolio').errors?.min">No puede exceder la cantidad mínima de folios disponibles</div>
							</div>
							<div *ngIf="(anularFoliosFormRef.touched || anularFoliosFormRef.dirty) && anularFoliosForm.invalid" class="text-danger">
								<div *ngIf="anularFoliosForm.errors?.numberOutOfRange">El folio Desde debe ser menor o igual al folio Hasta</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Folio Hasta</label>
						<div class="col-9">
							<input type="number" class="form-control" formControlName="hastaFolio" placeholder="Indique el folio hasta donde finalizá la anulación" id="hastaFolio" />
							<div *ngIf="anularFoliosForm.get('hastaFolio').invalid && (anularFoliosFormRef.submitted || anularFoliosForm.get('hastaFolio').dirty)" class="text-danger">
								<div *ngIf="anularFoliosForm.get('hastaFolio').errors?.required">Campo requerido</div>
								<div *ngIf="anularFoliosForm.get('hastaFolio').errors?.max">No puede superar la cantidad maxima de folios disponibles</div>
								<div *ngIf="anularFoliosForm.get('hastaFolio').errors?.min">No puede exceder la cantidad mínima de folios disponibles</div>
							</div>
							<div *ngIf="(anularFoliosFormRef.touched || anularFoliosFormRef.dirty) && anularFoliosForm.invalid" class="text-danger">
								<div *ngIf="anularFoliosForm.errors?.numberOutOfRange">El folio Hasta debe ser mayor o igual al folio Desde</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label class="col-3 label-control">Motivo Anulación</label>
						<div class="col-9">
							<input type="text" class="form-control" formControlName="motivoAnulacion" placeholder="Indique la razón de la anulación de estos folios" id="motivoAnulacion" />
							<div *ngIf="anularFoliosForm.get('motivoAnulacion').invalid && (anularFoliosFormRef.submitted || anularFoliosForm.get('motivoAnulacion').dirty)" class="text-danger">
								<div *ngIf="anularFoliosForm.get('motivoAnulacion').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btn-success">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Anular</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cross click')">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Cancelar</span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
