<form [formGroup]="taxForm" #taxFormRef="ngForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div class="form-group position-relative mx-auto">
            <div class="row">
                <label for="" class="col-3 label-control">Nombre Impuesto</label>
                <div class="col-9">
                    <input type="text" class="form-control" id="nombre" formControlName="nombre"
                        [attr.disabled]="detailMode?detailMode:null">
                    <div *ngIf="taxFormRef.submitted &&( taxForm.get('nombre').invalid ||  taxForm.get('nombre').dirty)"
                        class="text-danger">
                        <div *ngIf="taxForm.get('nombre').errors?.required">Campo requerido</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group position-relative mx-auto">
            <div class="row">
                <label for="" class="col-3 label-control">Valor Impuesto (%)</label>
                <div class="col-9">
                    <input type="number" class="form-control" id="valor" formControlName="valor"
                        [attr.disabled]="detailMode?detailMode:null">
                    <div *ngIf="taxFormRef.submitted &&( taxForm.get('valor').invalid ||  taxForm.get('valor').dirty)"
                        class="text-danger">
                        <div *ngIf="taxForm.get('valor').errors?.required">Campo requerido</div>
                        <div *ngIf="taxForm.get('valor').errors?.min">Valor debe ser igual o mayor a 0</div>
                        <div *ngIf="taxForm.get('valor').errors?.max">Valor debe tener como máximo dos dígitos</div>
                    </div>
                    <div *ngIf="!taxFormRef.submitted &&( taxForm.get('valor').invalid &&  taxForm.get('valor').dirty)"
                        class="text-danger">
                        <div *ngIf="taxForm.get('valor').errors?.min">Valor debe ser igual o mayor a 0</div>
                        <div *ngIf="taxForm.get('valor').errors?.max">Valor debe tener como máximo dos dígitos</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group position-relative mx-auto">
            <div class="row">
                <label for="" class="col-3 label-control">Tipo Impuesto</label>
                <div class="col-9">
                    <ng-select [clearable]="false" bindLabel="nombre" [searchable]="true" [selectOnTab]="false"
                        [items]="taxTypeSource" formControlName="tipo" placeholder="Seleccione tipo de impuesto"
                        [ngClass]="{
                            'is-invalid': taxForm.get('tipo').invalid && (taxFormRef.submitted || taxForm.get('tipo').touched) 
                        }" [attr.disabled]="detailMode?detailMode:null"></ng-select>
                    <div *ngIf=" taxForm.get('tipo').invalid &&( taxFormRef.submitted  ||  taxForm.get('tipo').dirty)"
                        class="text-danger">
                        <div *ngIf="taxForm.get('tipo').errors?.required">Campo requerido</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" *ngIf="!detailMode">
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">{{taxToEdit?"Guardar":"Generar"}}</span>
        </button>
        <button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
            <span class="d-none d-lg-block">Cerrar</span>
        </button>
    </div>
</form>