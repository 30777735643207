export class LogoEmisorRequest {
   logoId: string;
   emisorId: string;
   nombre: string;
   fechaSubida: Date;
   logoFile: string;
   logoCompleteFile: File;
   activo: boolean;
   propiedadEnum: number;
   tipoPlantillaIdPreview: string;
   propiedadEnumNombre: string;
   propiedadElementObj: any;
   tipoPlantillaCodigo: number;
}
