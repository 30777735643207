import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MaxNumberValidator } from "src/app/directives/max-number.directive";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { Divisa } from "src/app/models/Divisa";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { DetalleDteRentencion } from "src/app/models/Dte/DetalleDteRentencion";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { OtraMoneda } from "src/app/models/Dte/OtraMoneda";
import { Retencion } from "src/app/models/Dte/Retencion";
import { Impuesto } from "src/app/models/Impuesto";
import { Producto } from "src/app/models/Producto/Producto";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { ImpuestoService } from "src/app/services/impuesto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { DetalleDteImpuestoAdicional } from "../../../../models/Dte/DetalleDteImpuestoAdicional";

@Component({
  selector: "[app-item-detalle-dte]",
  templateUrl: "./item-detalle-dte.component.html",
  styleUrls: ["./item-detalle-dte.component.scss"],
})
export class ItemDetalleDteComponent implements OnInit {
  @Input() detalleDte: DetalleDte = new DetalleDte();
  @Input() detalleNumero: number = 0;
  public clpHelperDetalle: DetalleDte = new DetalleDte();
  @Output() deleteDetalle: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("localDetalleForm") detailForm;
  public detalleFormGroup: FormGroup;
  public otraMoneda: OtraMoneda = new OtraMoneda();
  public opciones: OptionsReferenceDteEmitido =
    new OptionsReferenceDteEmitido();
  private opcionesObservable$: Observable<OptionsReferenceDteEmitido>;
  private divisasObservable$: Observable<Divisa[]>;
  public divisaMessage: string =
    "Ingresar precio en base a la moneda seleccionada";
  private divisasList: Divisa[] = [];
  private currentEmisorId = this.tokenService.getCompany();
  subPrice: number = 0;
  subtotalForeign: number = 0; //used for holding the product of form values in foreing currency
  totalForeign: number = 0;
  totalCurrency: number = 0;
  subDiscount: number = 0;
  subRecharge: number = 0;
  public porcentajeIva: number = environment.taxPercentage;
  public searchCatalog: Producto[] = [];
  public searchLoading: boolean = false;
  public currentProduct: Producto = new Producto();
  private invoiceType$: Observable<InvoiceType>;
  public showingDetails: boolean = true;
  public workWithProduct: boolean = false;
  public currentInvoiceType: InvoiceType = InvoiceType.Factura;
  public showRetencionPercent: boolean = false;
  public retencionesSource: Retencion[] = JSON.parse(
    this.localStorage.getTiposRetenciones()
  ) as Retencion[];
  public lockExento: boolean = undefined;
  public showInExport: boolean = false;
  private destroySubscriptions$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private builder: FormBuilder,
    private facturador: FacturacionIndividualService,
    private ref: ChangeDetectorRef,
    private tokenService: TokenStorageService,
    private impuestoService: ImpuestoService,
    private localStorage: LocalStorageService
  ) {
    this.opcionesObservable$ = this.facturador.opcionesReferenciaObservable;
    this.divisasObservable$ = this.facturador.divisasListObservable;
    this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
  }

  ngOnInit(): void {
    this.detalleDteForm();
    if (this.detalleDte.dteId) {
      this.detalleDteToForm();
    }
    this.opcionesObservable$.subscribe((opciones) => {
      this.opciones = opciones;
      this.onSubmit();
    });
    this.divisasObservable$.subscribe((divisas) => {
      this.divisasList = divisas;
      if (this.divisasList[0]) {
        this.divisasList[0].nombre = "USD";
      }
      this.onSubmit();
    });
    this.invoiceType$.subscribe((res) => {
      this.modificationsByInvoiceType(res);
    });
  }
  deleteItem() {
    this.deleteDetalle.emit();
  }

  ngOnChanges() {
    //console.log(this.detalleDte);
  }

  checkFormSubmitted() {
    this.detailForm.nativeElement.submit();
  }
  detalleDteForm() {
    this.detalleFormGroup = this.builder.group(
      {
        codigo: [
          this.detalleDte && this.detalleDte.codigoInterno
            ? this.detalleDte.codigoInterno
            : "",
          { updateOn: "blur" },
        ],
        descripcion: [
          this.detalleDte &&
          this.detalleDte.descripcion &&
          this.detalleDte.nombre
            ? this.detalleDte.descripcion
            : "",
        ],
        cantidad: [
          this.detalleDte.cantidad ? this.detalleDte.cantidad : 1,
          [Validators.required, PositiveNumberValidator],
        ],
        unidadMedida: [
          this.detalleDte && this.detalleDte.unidadMedida
            ? this.detalleDte.unidadMedida
            : "",
        ],
        precioUnitario: [
          this.detalleDte.precioUnitario
            ? this.detalleDte.precioUnitario
            : undefined,
          [Validators.required, PositiveNumberValidator],
        ],
        // descuento:["",[PositiveNumberValidator]],
        // recargo:["",[PositiveNumberValidator]],
        descuentoRecargo: [],
        exento: [this.lockExento],
        subtotal: [""],
        haveCodigo: [false],
        haveDescripcion: [false],
        nombreItem: [
          this.detalleDte.nombre
            ? this.detalleDte.nombre
            : this.detalleDte.descripcion
            ? this.detalleDte.descripcion
            : "",
          { validators: Validators.required },
        ],
        haveRetencion: [false],
        retencionPorcentaje: [
          19,
          [MaxNumberValidator(this.porcentajeIva * 100)],
        ],
        retencionTipo: [this.retencionesSource[0]],
      },
      { updateOn: "blur" }
    );
    if (this.detalleDte.relatedProduct) {
      this.selectedProductToForm(this.detalleDte.relatedProduct);
    }
  }

  onSubmit() {
    this.ref.detectChanges();
    if (this.detalleFormGroup.valid) {
      this.detalleDte.codigoInterno =
        this.detalleFormGroup.controls.codigo.value;
      this.detalleDte.descripcion = this.detalleFormGroup.controls
        .haveDescripcion.value
        ? this.detalleFormGroup.controls.descripcion.value
        : "";
      this.detalleDte.nombre = this.detalleFormGroup.controls.nombreItem.value;
      this.detalleDte.cantidad = this.detalleFormGroup.controls.cantidad.value;
      this.detalleDte.unidadMedida =
        this.detalleFormGroup.controls.unidadMedida.value;
      this.detalleDte.esExento = this.detalleFormGroup.controls.exento.value != undefined
          ? this.detalleFormGroup.controls.exento.value
          : false;
      if (this.opciones.tipoMoneda == "CLP") {
        this.ref.detectChanges();

        this.detalleDte.precioUnitario =
          this.detalleFormGroup.controls.precioUnitario.value;
        this.detalleDte.descuento =
          this.detalleFormGroup.controls.descuentoRecargo.value < 0
            ? this.detalleFormGroup.controls.descuentoRecargo.value * -1
            : undefined;
        this.detalleDte.recargo =
          this.detalleFormGroup.controls.descuentoRecargo.value > 0
            ? this.detalleFormGroup.controls.descuentoRecargo.value
            : undefined;
        //this.detalleDte.calcSubTotal();
        this.detalleDte.subtotal =
          this.detalleDte.cantidad * this.detalleDte.precioUnitario;
        let subMasCargos = this.detalleDte.recargo
          ? this.detalleDte.subtotal + this.detalleDte.recargo
          : this.detalleDte.subtotal;
        this.detalleDte.total = this.detalleDte.descuento
          ? subMasCargos - this.detalleDte.descuento
          : subMasCargos;
        this.ref.detectChanges();
        //console.log("CLP calculation",this.detalleDte.total,this.detalleDte.subtotal,subMasCargos,this.detalleDte.descuento,this.detalleDte.recargo,this.detalleDte.descripcion);
      }
     
      this.refreshImpuestoAdicionalInDetalle();
      this.detalleDte.totalImpuestosAdicionales = this.calculateTaxesInItem();
      this.detalleDte.porcentajeRetencion =
        this.detalleFormGroup.controls.retencionPorcentaje.value;

      //console.log("item enviado",this.detalleDte.esExento,this.detalleDte);
      this.facturador.updateItem(this.detalleDte);
    }
  }
  onExentoChange(event) {
    this.detalleFormGroup.controls.exento.patchValue(event.target.checked);
    this.onSubmit();
  }
  detalleDteToForm() {
    if (this.detalleDte.relatedProduct) {
      this.currentProduct = this.detalleDte.relatedProduct;
    }
    if (
      this.detalleDte.impuestosAsociados &&
      this.detalleDte.impuestosAsociados.length > 0
    ) {
      this.currentProduct.impuestos = [...this.detalleDte.impuestosAsociados];
    }
    // if(this.detalleDte.totalImpuestosAdicionales && this.detalleDte.totalImpuestosAdicionales>0){

    // }
    this.refreshImpuestoAdicionalInDetalle();
    let prod = new Producto();
    prod.nombre = this.detalleDte.nombre
      ? this.detalleDte.nombre
      : this.detalleDte.descripcion;
    this.searchCatalog.push(prod);
    this.detalleFormGroup.controls.nombreItem.patchValue(prod.nombre);
    this.detalleFormGroup.controls.codigo.setValidators([]);
    this.detalleFormGroup.controls.descripcion.patchValue(
      this.detalleDte.descripcion
    );
    this.detalleFormGroup.controls.cantidad.patchValue(
      this.detalleDte.cantidad
    );
    this.detalleFormGroup.controls.precioUnitario.patchValue(
      this.detalleDte.precioUnitario
    );
    this.detalleFormGroup.controls.subtotal.patchValue(this.detalleDte.total);
    this.detalleFormGroup.controls.exento.patchValue(this.detalleDte.esExento);
    this.ref.detectChanges();
  }

  exchangeBasedCalculation() {
    //work with de selected rate of exchange to CLP and show the values under the input tags
    if (this.opciones.tipoMoneda && this.opciones.tipoMoneda != "") {
    }
  }

  changeHaveDescripcion($event) {
    this.ref.detectChanges();
    this.detalleFormGroup.controls.haveDescripcion.patchValue(
      $event.target.checked
    );
  }

  changeHaveCodigo(event) {
    this.ref.detectChanges();
    this.detalleFormGroup.controls.haveCodigo.patchValue(event.target.checked);
  }

  searchItem(event, propertyName) {
    //console.log(event);
    this.searchLoading = true;
    this.workWithProduct = false;
    this.facturador
      .searchProducts(propertyName, event.term, this.currentEmisorId)
      .subscribe(
        (res) => {
          //console.log(res)
          this.searchCatalog = [...res.data] as Producto[];
          this.ref.detectChanges();
          this.searchLoading = false;
        },
        (error) => {
          ///console.log(error.error);
          this.searchLoading = false;
          this.ref.detectChanges();
          Swal.fire({
            icon: "error",
            title: "Error en productos",
            text: "No fue posible obtener los productos concidentes con el termino buscado",
          });
        }
      );
  }
  refreshItemDetail(event: Producto) {
    //console.log(event)
    //set selected item to form
    this.currentProduct = event;
    if (event && event.productoId) {
      this.getTaxesOfProduct(event);
      //this.selectedProductToForm(this.currentProduct);
    }
    //this.onSubmit();
  }

  AddProductByName = (prod: string) => {
    this.detalleFormGroup.get("nombreItem").patchValue(prod);
    if (
      !this.detalleFormGroup.get("codigo").value ||
      this.detalleFormGroup.get("codigo").value == ""
    ) {
      Swal.fire({
        icon: "question",
        title: "¿Agregar nuevo producto?",
        text:
          'Para agregar el nuevo producto de nombre "' +
          prod +
          '", ingrese además un código de producto en el Selector de producto por Código y presione la opción de "Agregar Producto" en la pestaña de dicho selector. Este producto será agregado si la transacción se realiza con éxito. En caso contrario, realice una nueva búsqueda y seleccione un producto existente.',
      });
      return;
    }
    this.detalleDte.nombre = prod;
    //this.detalleDte.codigoInterno = "";
    this.detalleDte.codigoInterno = this.detalleFormGroup.get("codigo").value;
    this.detalleDte.nombre = this.detalleFormGroup.get("nombreItem").value;
    this.detalleDte.esExento = this.detalleFormGroup.controls.exento.value;
    this.detalleDte.createCurrentProduct = true;
    this.workWithProduct = null;
  };

  AddProductByCode = (code: string) => {
    this.detalleFormGroup.get("codigo").patchValue(code);
    if (
      !this.detalleFormGroup.get("nombreItem").value ||
      this.detalleFormGroup.get("nombreItem").value == ""
    ) {
      Swal.fire({
        icon: "question",
        title: "¿Agregar nuevo producto?",
        text:
          'Para agregar el nuevo producto de código "' +
          code +
          '", ingrese además un nombre nuevo para el producto en el Selector de producto por Nombre y presione la opción de "Agregar Producto" en la pestaña de dicho selector. Este producto será agregado si la transacción se realiza con éxito. En caso contrario, realice una nueva búsqueda y seleccione un producto existente.',
      });
      return;
    }
    this.detalleDte.codigoInterno = code;
    this.detalleDte.nombre = this.detalleFormGroup.get("nombreItem").value;
    this.detalleDte.esExento = this.detalleFormGroup.controls.exento.value;
    this.detalleDte.createCurrentProduct = true;
    this.workWithProduct = null;
  };

  selectedProductToForm(selectedProduct: Producto) {
    if (selectedProduct) {
      if (this.detalleDte.relatedProduct) {
        this.currentProduct = { ...this.detalleDte.relatedProduct };
      }
      if (this.detalleDte.impuestosAsociados.length > 0) {
        this.currentProduct.impuestos = [...this.detalleDte.impuestosAsociados];
      }
      this.detalleFormGroup.controls.codigo.patchValue(
        selectedProduct.codigoBarra
      );
      this.detalleFormGroup.controls.precioUnitario.patchValue(
        selectedProduct.precio
      );
      let ExentoValue = selectedProduct.exento;
      this.detalleFormGroup.controls.exento.patchValue(ExentoValue);
      this.detalleFormGroup.controls.nombreItem.patchValue(
        selectedProduct.nombre
      );
      this.detalleDte.createCurrentProduct = false;
      if (selectedProduct.impuestos != undefined) {
        let detalleDteImpuestoAdicionalList: DetalleDteImpuestoAdicional[] = [];
        let impuestoFromProduct = [...selectedProduct.impuestos];
        impuestoFromProduct = [...impuestoFromProduct];
        detalleDteImpuestoAdicionalList = selectedProduct.impuestos.map((i) => {
          let impuestoAdicional = new DetalleDteImpuestoAdicional();
          impuestoAdicional.codigoSii = i.tipoImpuesto;
          impuestoAdicional.nombreImp = i.nombre;
          impuestoAdicional.valor = Math.round(
            (i.valor / 100) * this.detalleDte.total
          );
          impuestoAdicional.nroImp = selectedProduct.impuestos.indexOf(i) + 1;
          impuestoAdicional.tasa = i.valor;
          impuestoAdicional.impuestoIdRelated = i.impuestoId;
          return impuestoAdicional;
        });
        this.detalleDte.impuestosAdicionales = [
          ...detalleDteImpuestoAdicionalList,
        ];
        this.detalleDte.impuestosAdicionales = [
          ...this.detalleDte.impuestosAdicionales,
        ];
      } else {
        this.detalleDte.impuestosAdicionales = [];
      }
      if (
        selectedProduct.unidadMedida != undefined &&
        selectedProduct.unidadMedida != ""
      ) {
        this.detalleFormGroup.controls.unidadMedida.patchValue(
          selectedProduct.unidadMedida
        );
        //this.detalleFormGroup.controls.haveDescripcion.patchValue(true);
      }
    }
  }

  refreshImpuestoAdicionalInDetalle() {
    if (
      this.detalleDte.impuestosAdicionales &&
      this.detalleDte.impuestosAdicionales.length > 0
    ) {
      this.detalleDte.impuestosAdicionales.forEach((impuestoAdicional) => {
        let relatedImpuestoInProduct = this.currentProduct.impuestos.find(
          (impuesto) =>
            impuesto.impuestoId == impuestoAdicional.impuestoIdRelated
        );
        //impuestoAdicional.valor = Math.round((relatedImpuestoInProduct.valor/100) * Math.round(this.detalleDte.total/this.opciones.valorTipoMoneda));
        let valueTax: number = 0;
        if (this.opciones.tipoMoneda != "CLP") {
          valueTax =
            (relatedImpuestoInProduct.valor / 100) *
            (this.detalleDte.total / this.opciones.valorTipoMoneda);
        } else {
          valueTax =
            (relatedImpuestoInProduct.valor / 100) * this.detalleDte.total;
        }
        impuestoAdicional.valor = +valueTax.toFixed(2);
      });
    }
  }

  modificationsByInvoiceType(type: InvoiceType) {
    this.currentInvoiceType = type;
    switch (type) {
      case InvoiceType.Boleta: {
        this.showingDetails = true;
        //console.log(type,this.showingDetails);
        break;
      }
      case InvoiceType.Factura: {
        this.showingDetails = true;
        break;
      }
      case InvoiceType.GuiaDespacho: {
        this.showingDetails = true;
        break;
      }
    }
  }

  calculateTaxesInItem() {
    let taxAmount = 0;
    if (this.detalleDte.impuestosAdicionales != undefined) {
      taxAmount = this.detalleDte.impuestosAdicionales.reduce(
        (acc, curr) => acc + curr.valor,
        0
      );
    }
    //if item already has TOtalImpuestos adicionales from Guide Items to new Invoice
    if (
      this.detalleDte.totalImpuestosAdicionales &&
      this.detalleDte.totalImpuestosAdicionales > 0
    ) {
      taxAmount = this.detalleDte.totalImpuestosAdicionales;
    }
    return taxAmount;
  }

  getTaxesOfProduct(prod: Producto) {
    this.impuestoService.taxesByProduct(prod).subscribe(
      (res) => {
        if (this.currentProduct) {
          this.currentProduct.impuestos = res.data as Impuesto[];
          this.selectedProductToForm(this.currentProduct);
          this.ref.detectChanges();
          //console.log(this.currentProduct.impuestos);
          this.onSubmit();
        }
      },
      (error) => {
        //console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error en impuestos",
          text: "No fue posible obtener los impuestos del producto seleccionado",
        });
      }
    );
  }

  changeRetencionInput($event) {
    this.ref.detectChanges();
    this.detalleFormGroup.controls.haveRetencion.patchValue(
      $event.target.checked
    );
    this.showRetencionPercent =
      this.detalleFormGroup.controls.haveRetencion.value;
  }

  ngOnDestroy() {
    this.destroySubscriptions$.next(true);
    this.destroySubscriptions$.unsubscribe();
  }
}
