import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigMantenedorEmisores } from "./config-mantenedor-emisores";
import { validarRut, VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { PerfilFilter } from "src/app/models/Perfil/PerfilFilter";
import { Perfil } from "src/app/models/Perfil/Perfil";
import { PerfilService } from "src/app/services/perfil.service";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { Permiso } from "src/app/models/Perfil/Permiso";
import { PermisoFilter } from "src/app/models/Perfil/PermisoFilter";
import { ActivatedRoute, Router } from "@angular/router";
import { Route } from "@angular/compiler/src/core";
import { PermissionService } from "src/app/services/permission.service";
import { EmisorFilter } from "src/app/models/Emisor/EmisorFilter";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { EmisorService } from "src/app/services/emisor.service";
import { DatePipe } from "@angular/common";
import { NgbDateStruct, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Sucursal } from "src/app/models/Sucursal";
import { SucursalFilter } from "src/app/models/Usuario/SucursalFilter";
import { ConfigMantenedorSucursales } from "../mantenedor-usuarios/config-mantenedor-sucursales";
import { ActividadEconomicaEmisor } from "src/app/models/ActividadEconomicaEmisor";
import { Observable } from "rxjs";
import { Response } from "src/app/shared/models/Response";
import { Ambiente } from "src/app/models/Ambiente";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LogoEmisorRequest } from "src/app/models/Emisor/LogoEmisorRequest";
import { LogoEmisor } from "src/app/models/Emisor/LogoEmisor";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { SortType } from "@swimlane/ngx-datatable";
import { EmisorPassRequest } from "src/app/models/Emisor/EmisorPassRequest";
import { SameWordValidator } from "src/app/directives/same-word.directive";
import { UpdateEmisorEnvironment } from "src/app/models/Emisor/UpdateEmisorEnvironment";
import { PersonasAutorizadasService } from "src/app/services/paseronas-autorizadas.service";

@Component({
	selector: "app-mantenedor-emisores",
	templateUrl: "./mantenedor-emisores.component.html",
	styleUrls: ["./mantenedor-emisores.component.scss"],
})
export class MantenedorEmisoresComponent implements OnInit {
	SortType = SortType;
	public loading = false;
	public page = new Page();
	public pageSucursal = new Page();
	public filter = new EmisorFilter();
	public filterSucursal = new SucursalFilter();
	public rowsMantenedor: Emisor[] = [];
	public rowsMantenedorSucursal: Sucursal[] = [];
	public configLocal = ConfigMantenedorEmisores;
	public configLocalSucursal = ConfigMantenedorSucursales;
	public searchRows: any[] = [];
	public emisorId: string;
	public emisorToShow: Emisor = new Emisor();
	public emisorToEdit: Emisor = new Emisor();
	public emisorToAdd: Emisor = new Emisor();
	public sucursalToShow: Sucursal = new Sucursal();
	public sucursalToEdit: Sucursal = new Sucursal();
	public sucursalToAdd: Sucursal = new Sucursal();
	private saveRows: boolean = false;
	public detailEmisorModal: NgbModalRef;
	public addNewEmisorModal: NgbModalRef;
	public editEmisorModal: NgbModalRef;
	public admSucursalesModal: NgbModalRef;
	public detailSucursalModal: NgbModalRef;
	public addNewSucursalModal: NgbModalRef;
	public editSucursalModal: NgbModalRef;
	private economicActivitiesModal: NgbModalRef;
	private authorizedPersonsModal: NgbModalRef;
	private mailBoxesModal: NgbModalRef;
	private addCesionConfigModalRef: NgbModalRef;
	private siiUsersModal: NgbModalRef;
	public searchCriterio;
	private fullListPermission: boolean = false;
	public selected: any = { startDate: null, endDate: null };
	//public environmentType = ["Certificacion","Produccion"];
	public environmentType: Ambiente[] = JSON.parse(this.localStorage.getTipoAmbiente()) as Ambiente[];
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	@ViewChild("notesSwal") private notesSwal: SwalComponent;
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	public validDate: boolean = true;
	private addLogoModalRef: NgbModalRef;
	private logoListModalRef: NgbModalRef;
	public logoEmisorToEdit: LogoEmisor;
	//logo emisor integrated to new emisor modal
	public logoRequest: LogoEmisorRequest = new LogoEmisorRequest();
	public files: File[] = [];
	public passwordEditForm: FormGroup;
	private editPasswordModalRef: NgbModalRef;
	constructor(private modalService: NgbModal, private datepipe: DatePipe, public utilities: UtilitiesService, private personaAutorizadaService: PersonasAutorizadasService, private emisorService: EmisorService, private tokenService: TokenStorageService, private ref: ChangeDetectorRef, private localStorage: LocalStorageService, private modal: NgbModal, public router: Router, public permissions: PermissionService, private route: ActivatedRoute, private formBuilder: FormBuilder) {
		(this.emisorId = this.tokenService.getCompany()), (this.filter.activo = 1);
		this.filter.razonSocial = "";
		this.filter.rut = "";
		this.filter.comuna = "";
		this.filter.ciudad = "";
		this.filter.giro = "";
		this.fullListPermission = this.tokenService.getUser().superAdmin; //this.permissions.getPermission("10-10").asignado;
		this.selected.startDate = moment().subtract(4, "days").startOf("day");
		this.selected.endDate = moment().subtract(1, "days").startOf("day");
		this.createPasswordEditForm();
	}
	locale = {
		startDate: this.emisorToEdit.fechaResol,
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD/MM/YYYY",
	};
	ngOnInit(): void {
		this.setPage({ offset: 0 });
	}
	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		this.emisorService
			.getDataByPage(this.filter, localPage, this.tokenService.getUser().id)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Emisor[];
					this.ref.detectChanges();
					this.rowsMantenedor = [...elements];
					elements.forEach((row, i) => {
						this.rowsMantenedor[i].ambienteEnt = this.environmentType[row.ambiente];
						this.rowsMantenedor[i].ambiente = this.rowsMantenedor[i].ambienteEnt.ambienteId;
						this.rowsMantenedor[i].nombreAmbiente = this.rowsMantenedor[i].ambienteEnt.nombre;
					});
					this.setPagefromResponse(this.page, pagedData);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = error.error.message;
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.componentStarted = true;
				this.ref.detectChanges();
			});
	}
	setPagefromResponse(currentPage: Page, data: PagedResponse<Emisor>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	SearchElements(nombre: string) {
		this.loading = true;
		this.emisorService.getDataByPage(this.filter, this.page, this.tokenService.getUser().id).subscribe(
			(result) => {
				this.loading = false;
				let elements = result.data as Emisor[];
				this.rowsMantenedor = [...elements];
				elements.forEach((row, i) => {
					this.rowsMantenedor[i].ambienteEnt = this.environmentType[row.ambiente];
					this.rowsMantenedor[i].ambiente = this.rowsMantenedor[i].ambienteEnt.ambienteId;
					this.rowsMantenedor[i].nombreAmbiente = this.rowsMantenedor[i].ambienteEnt.nombre;
				});
			},
			(error) => {
				this.loading = false;
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error";
				this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	changeSearchedRows(data) {
		if (data != null) {
			this.rowsMantenedor = data;
		}
	}

	filterConcordance(configCriterias, filter: EmisorFilter, eventFilter): EmisorFilter {
		configCriterias.forEach((criterio) => {
			if (criterio != eventFilter.criterio) {
				filter[criterio] = "";
			}
		});
		return filter;
	}

	searchElementsByFilter(event) {
		this.filter = this.filterConcordance(this.configLocal.barraBusqueda.criteriosKeys, this.filter, event);
		this.filter.activo = event.registroActivo ? 0 : 1;
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.setPage({ offset: 0 });
	}

	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}

	getAllRowsByFilter(currentPageRef: Page, currentFilter: EmisorFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.emisorService.getDataByPage(currentFilter, fullPage, this.tokenService.getUser().id).subscribe(
			(result) => {
				let elements = result.data as Emisor[];
				this.ref.detectChanges();
				elements.forEach((row, i) => {
					row.nombreAmbiente = this.environmentType[row.ambiente].nombre;
				});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al exportar";
				this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error);
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<Emisor>): void {
		let arregloExportar = [];
		arrayToExport.forEach((item) => {
			let elementToExport = {};
			elementToExport["Razon Social"] = item.razonSocial;
			elementToExport["Rut"] = item.rut;
			elementToExport["Dv"] = item.dv;
			elementToExport["Direccion Facturacion"] = item.dirFact;
			elementToExport["Ciudad"] = item.ciudad;
			elementToExport["Comuna"] = item.comuna;
			elementToExport["Telefono"] = item.telefono;
			elementToExport["Correo Facturacion"] = item.correoFact;
			elementToExport["Giro"] = item.giro;
			elementToExport["Fecha Resolucion"] = this.datepipe.transform(item.fechaResol, "dd/MM/yyyy");
			elementToExport["N° Resolucion"] = item.nroResol;
			elementToExport["Ambiente"] = item.nombreAmbiente;
			elementToExport["Rut Representante Legal"] = item.representanteLegalRut + "-" + item.representanteLegalDv;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Emisores");
	}

	detailModal(modalRef, row: Emisor) {
		this.loading = true;
		this.emisorToShow = new Emisor();
		this.emisorToShow = row;
		this.emisorToShow.representanteLegalRutString = row.representanteLegalRut + "-" + row.representanteLegalDv;
		var m = moment(this.datepipe.transform(this.emisorToShow.fechaResol, "yyyy-MM-dd"));
		this.selected.startDate = m;
		this.selected.endDate = m;
		this.loading = false;
		this.ref.detectChanges();
		this.detailEmisorModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	activate(row: Emisor) {
		this.loading = true;
		this.emisorService.activateEmisor(row.emisorId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Emisor Activado",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Emisor " + row.razonSocial + " Activado" + "</p></div>",
					});
					this.setPage({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Activar";
				this.currentSwal.text = "No fue posible activar el emisor " + row.razonSocial + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	deactivate(row: Emisor) {
		this.loading = true;
		this.emisorService.deactivateEmisor(row.emisorId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Emisor Desactivado",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Emisor " + row.razonSocial + " Desactivado" + "</p></div>",
					});
					this.setPage({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Desactivar";
				this.currentSwal.text = "No fue posible desactivar al emisor " + row.razonSocial + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	addEmisorModal(modalRef) {
		this.loading = true;
		this.emisorToAdd = new Emisor();
		this.emisorToAdd.nroResol = undefined;
		this.emisorToAdd.ambienteEnt = this.environmentType[0];
		var m = moment(this.datepipe.transform(new Date(), "yyyy-MM-dd"));
		this.selected.startDate = m;
		this.selected.endDate = m;
		this.emisorToAdd.fechaResol = undefined;
		this.loading = false;
		this.logoRequest = new LogoEmisorRequest();
		this.files = [];
		this.ref.detectChanges();
		this.addNewEmisorModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	addEmisor(addForm) {
		if (!addForm.valid || !this.validDate) {
			return;
		}
		this.loading = true;
		this.emisorToAdd.rut = parseInt(this.emisorToAdd.rutString.substring(0, this.emisorToAdd.rutString.length - 2));
		this.emisorToAdd.dv = this.emisorToAdd.rutString.substring(this.emisorToAdd.rutString.length - 1);
		this.emisorToAdd.representanteLegalRutString = this.emisorToAdd.representanteLegalRutString;
		this.emisorToAdd.representanteLegalRut = parseInt(this.emisorToAdd.representanteLegalRutString.substring(0, this.emisorToAdd.rutString.length - 2));
		this.emisorToAdd.representanteLegalDv = this.emisorToAdd.representanteLegalRutString.substring(this.emisorToAdd.representanteLegalRutString.length - 1);
		this.emisorToAdd.dirPart = this.emisorToAdd.dirFact;
		this.emisorToAdd.telefono = this.emisorToAdd.telefono;
		this.emisorToAdd.correoPar = this.emisorToAdd.correoFact;
		this.emisorToAdd.nroResol = this.emisorToAdd.nroResol;
		this.emisorToAdd.ambiente = this.emisorToAdd.ambienteEnt.ambienteId;
		this.emisorService.addEmisor(this.emisorToAdd).subscribe(
			(res) => {
				if (res.data) {
					if (this.logoRequest.logoCompleteFile && this.logoRequest.logoFile) {
						this.prepareAndSendLogoEmisorRequest(res.data);
					} else {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "success",
							title: "Emisor Agregado",
							showCloseButton: true,
							html: "<div style='text-align:center;'><p>" + "El Emisor " + this.emisorToAdd.razonSocial + " fue agregado" + "</p></div>",
						});
						this.setPage({ offset: 0 });
						this.addNewEmisorModal.close();
					}
				} else {
					this.loading = false;
					this.ref.detectChanges();
					this.currentSwal.icon = "info";
					this.currentSwal.title = "Rut del Emisor Existente";
					this.currentSwal.text = res.message;
					this.currentSwal.fire();
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				let errorTxt = this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Agregar Emisor";
				this.currentSwal.text = "No fue posible agregar el emisor " + this.emisorToAdd.razonSocial + ". Intenta más tarde" + errorTxt;
				this.currentSwal.fire();
			}
		);
	}
	editModal(modalRef, row: Emisor) {
		this.loading = true;
		this.emisorToEdit = new Emisor();
		this.emisorToEdit.unidadSII = row.unidadSII;
		this.emisorToEdit.emisorId = row.emisorId;
		this.emisorToEdit.fechaResol = row.fechaResol;
		this.emisorToEdit.rut = row.rut;
		this.emisorToEdit.razonSocial = row.razonSocial;
		this.emisorToEdit.dv = row.dv;
		this.emisorToEdit.rutString = row.rutString;
		this.emisorToEdit.giro = row.giro;
		this.emisorToEdit.ciudad = row.ciudad;
		this.emisorToEdit.comuna = row.comuna;
		this.emisorToEdit.dirFact = row.dirFact;
		this.emisorToEdit.dirPart = row.dirPart;
		this.emisorToEdit.telefono = row.telefono;
		this.emisorToEdit.correoFact = row.correoFact;
		this.emisorToEdit.correoPar = row.correoPar;
		this.emisorToEdit.nroResol = row.nroResol;
		this.emisorToEdit.ambiente = row.ambiente;
		this.emisorToEdit.nombreAmbiente = row.nombreAmbiente;
		this.emisorToEdit.ambienteEnt = row.ambienteEnt;
		this.emisorToEdit.activo = row.activo;
		this.emisorToEdit.representanteLegalRutString = row.representanteLegalRut + "-" + row.representanteLegalDv;
		var m = moment(this.datepipe.transform(this.emisorToEdit.fechaResol, "yyyy-MM-dd"));
		this.selected.startDate = m;
		this.selected.endDate = m;
		this.loading = false;
		this.ref.detectChanges();
		this.editEmisorModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	editEmisor(addForm) {
		if (!addForm.valid || !this.validDate) {
			return;
		}
		this.loading = true;
		this.emisorToEdit.ambiente = this.emisorToEdit.ambienteEnt.ambienteId;
		this.emisorToEdit.representanteLegalRut = parseInt(this.emisorToEdit.representanteLegalRutString.substring(0, this.emisorToEdit.rutString.length - 2));
		this.emisorToEdit.representanteLegalDv = this.emisorToEdit.representanteLegalRutString.substring(this.emisorToEdit.representanteLegalRutString.length - 1);
		this.emisorService.updateEmisor(this.emisorToEdit).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Emisor Actualizado",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "El Emisor " + this.emisorToEdit.razonSocial + " fue actualizado correctamente" + "</p></div>",
				});
				this.editEmisorModal.close();
				this.setPage({ offset: 0 });
				this.tokenService.updateAmbientOfCompany(this.emisorToEdit.emisorId, this.emisorToEdit.ambiente);
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Actualizar Emisor";
				this.currentSwal.text = "No fue posible actualizar al emisor " + this.emisorToEdit.razonSocial + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	getBranchOfficesByEmisor(modalRef, row: Emisor) {
		this.rowsMantenedorSucursal = [];
		this.loading = true;
		this.emisorToEdit = new Emisor();
		this.emisorToEdit = row;
		this.filterSucursal.emisorId = this.emisorToEdit.emisorId;
		this.filterSucursal.activo = 1;
		this.setPageSucursal({ offset: 0 });
		this.admSucursalesModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	detectChangeReferenceDateAdd(event) {
		this.validDate = true;
		if (event.startDate != null && event.endDate != null) {
			this.emisorToAdd.fechaResol = moment(event.startDate).toDate();
		} else {
			if (event.target && event.target.value) {
				this.validDate = moment(event.target.value, "DD/MM/YYYY", true).isValid();
				if (this.validDate) {
					let newDate = moment(event.target.value, "DD-MM-YYYY").format("L");
					if (moment(this.emisorToAdd.fechaResol).format("L") != newDate) {
						let dateToSave = moment(event.target.value, "DD-MM-YYYY");
						this.emisorToAdd.fechaResol = dateToSave.toDate();
					}
				}
			}
		}
	}
	detectChangeReferenceDateEdit(event) {
		this.validDate = true;
		if (event.startDate != null && event.endDate != null) {
			this.emisorToEdit.fechaResol = moment(event.startDate).toDate();
		} else {
			if (event.target && event.target.value) {
				this.validDate = moment(event.target.value, "DD/MM/YYYY", true).isValid();
				if (this.validDate) {
					let newDate = moment(event.target.value, "DD-MM-YYYY").format("L");
					if (moment(this.emisorToEdit.fechaResol).format("L") != newDate) {
						let dateToSave = moment(event.target.value, "DD-MM-YYYY");
						this.emisorToEdit.fechaResol = dateToSave.toDate();
					}
				}
			}
		}
	}

	detailBranchOfficeModal(modalRef, row: Sucursal) {
		this.loading = true;
		this.sucursalToShow = row;
		this.loading = false;
		this.ref.detectChanges();
		this.detailSucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	addBranchOfficeModal(modalRef, emisor: Emisor) {
		this.loading = true;
		this.sucursalToAdd = new Sucursal();
		this.sucursalToAdd.emisorId = emisor.emisorId;
		this.loading = false;
		this.ref.detectChanges();
		this.addNewSucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	addBranchOffice(modalRef) {
		this.loading = true;
		this.emisorService.addBranchOffice(this.sucursalToAdd).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				if (res.data) {
					Swal.fire({
						icon: "success",
						title: "Sucursal Agregada",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "La Sucursal " + this.sucursalToAdd.nombre + " fue agregada" + "</p></div>",
					});
					this.filterSucursal.emisorId = this.emisorToEdit.emisorId;
					this.filterSucursal.activo = 1;
					this.setPageSucursal({ offset: 0 });
					this.addNewSucursalModal.close();
				} else {
					this.loading = false;
					this.ref.detectChanges();
					this.currentSwal.icon = "info";
					this.currentSwal.title = "Nombre de la Sucursal Existente";
					this.currentSwal.text = res.message;
					this.currentSwal.fire();
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Agregar Sucursal";
				this.currentSwal.text = this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	setPageSucursal(pageInfo) {
		this.loading = true;
		this.pageSucursal.pageNumber = pageInfo.offset;
		this.pageSucursal.pageNumber += 1;

		this.emisorService
			.getSucursalDataByPage(this.filterSucursal, this.pageSucursal)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Sucursal[];
					this.ref.detectChanges();
					elements.forEach((row) => {});
					this.rowsMantenedorSucursal = [...elements];
					this.setPagefromSucursalResponse(this.pageSucursal, pagedData);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}
	setPagefromSucursalResponse(currentPage: Page, data: PagedResponse<Sucursal>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	searchSucursalElements(nombre: string) {
		this.loading = true;
		this.emisorService.getSucursalDataByPage(this.filterSucursal, this.pageSucursal).subscribe(
			(result) => {
				this.loading = false;
				let elements = result.data as Sucursal[];
				elements.forEach((row) => {});
				this.rowsMantenedorSucursal = [...elements];
			},
			(error) => {
				this.loading = false;
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error";
				this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	changeSearchedRowsBySucursal(data) {
		if (data != null) {
			this.rowsMantenedorSucursal = data;
		}
	}

	filterConcordanceBySucursal(configCriterias, sucursalFilter: SucursalFilter, eventFilter): SucursalFilter {
		configCriterias.forEach((criterio) => {
			if (criterio != eventFilter.criterio) {
				sucursalFilter[criterio] = "";
			}
		});
		return sucursalFilter;
	}
	searchElementsBySucursalFilter(event) {
		this.filterSucursal = this.filterConcordanceBySucursal(this.configLocal.barraBusqueda.criteriosKeys, this.filterSucursal, event);
		this.filterSucursal.nombre = event.nombre != null ? event.nombre : "";
		this.filterSucursal.direccion = event.direccion != null ? event.direccion : "";
		this.filterSucursal.activo = event.activo != null ? event.activo : "";
		this.filterSucursal[event.criterio] = event.busqueda;
		this.setPageSucursal({ offset: 0 });
	}

	activateBranchOffice(row: Sucursal) {
		this.loading = true;
		this.emisorService.activateBranchOffice(row.sucursalId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Sucursal Activada",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Sucursal " + row.nombre + " Activado" + "</p></div>",
					});
					this.setPageSucursal({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Activar";
				this.currentSwal.text = "No fue posible activar la sucursal " + row.nombre;
				this.currentSwal.fire();
			}
		);
	}

	deactivateBranchOffice(row: Sucursal) {
		this.loading = true;
		this.emisorService.deactivateBranchOffice(row.sucursalId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Sucursal Desactivada",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Sucursal " + row.nombre + " Desactivada" + "</p></div>",
					});
					this.setPageSucursal({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Desactivar";
				this.currentSwal.text = "No fue posible desactivar la sucursal " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}
	editBranchOfficeModal(modalRef, row: Sucursal) {
		this.loading = true;
		this.sucursalToEdit = new Sucursal();
		this.sucursalToEdit.emisorId = row.emisorId;
		this.sucursalToEdit.sucursalId = row.sucursalId;
		this.sucursalToEdit.nombre = row.nombre;
		this.sucursalToEdit.direccion = row.direccion;
		this.loading = false;
		this.ref.detectChanges();
		this.editSucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	editBranchOffice(editForm) {
		this.loading = true;
		this.emisorService.updateBranchOffice(this.sucursalToEdit).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Sucursal Actualizada",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "La sucursal " + this.sucursalToEdit.nombre + " fue actualizada correctamente" + "</p></div>",
				});
				this.tokenService.updateOwnSucursal(this.sucursalToEdit);
				this.setPageSucursal({ offset: 0 });
				this.editSucursalModal.close();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Actualizar Sucursal";
				this.currentSwal.text = this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}
	openEconomicActivitiesModal(modalRef, row: Emisor) {
		this.loading = true;
		this.emisorService.getEconomicActivitiesByEmisor(row.emisorId).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				row.actividadesEconomicas = result.data;
				this.emisorToEdit = { ...row };
				this.economicActivitiesModal = this.modal.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Actividades Económicas No Encontradas",
					text: "Error al buscar actividades económicas del emisor." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	closeEconomicActivitiesModal() {
		this.economicActivitiesModal.close();
	}
	updateEconomicActivitiesRequest(event: ActividadEconomicaEmisor[]) {
		this.loading = true;
		this.emisorService.toAssignEconomicActivity(event).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Actividades Económicas Actualizadas",
					text: "Las actividades económicas fueron actualizadas exitosamente",
				});
				this.closeEconomicActivitiesModal();
			},
			(error) => {
				this.loading = false;
				let errorText = error.error.message;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error Al actualizar Actividades Económicas",
					text: "No ha sido posible actualizar las actividades económicas del emisor." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	openAuthorizedPersonsModal(modalRef, row: Emisor) {
		this.loading = true;
		this.personaAutorizadaService.getAuthorizedPersonsByEmisor(row.emisorId).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				row.personasAutorizadas = result.data;
				this.emisorToEdit = { ...row };
				this.authorizedPersonsModal = this.modal.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Personas Autorizadas para Cesion No Encontradas",
					text: "Error al buscar personas autorizadas para cesion del emisor" + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	closeAuthorizedPersonsModal() {
		this.authorizedPersonsModal.close();
	}

	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	openAddLogo(modalRef, row) {
		this.emisorToEdit = row as Emisor;
		this.addLogoModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	openEmisorLogosList(modalRef, row) {
		this.emisorToEdit = row as Emisor;
		this.logoEmisorToEdit = undefined;
		// this.logoListModalRef = this.modalService.open(modalRef,{
		//    windowClass:'',
		//    size:'lg'
		// })
		this.loading = true;
		this.emisorService.emisorLogosList(this.emisorToEdit).subscribe(
			(result) => {
				if (result.data.length > 0) {
					this.logoEmisorToEdit = result.data[0] as LogoEmisor;
					if (result.data.length > 1) {
						let sobrantes = result.data.slice(-2);
						this.deleteExtraLogos(sobrantes);
					}
				}
				//check for pdf templates availables before opening AddLogo/EditLogo modal
				this.getPlantillaTemplatesOfEmisor(modalRef, row);
				//this.openAddLogo(modalRef,row);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al obtener logo",
					text: "No fue posible obtener el logo actual del emisor. Intente nuevamente",
				});
			}
		);
	}

	deleteExtraLogos(logoList: LogoEmisor[]) {
		//since only one logo can exist for emisor, if the response of current logos is creater than 1, delete all the others
		logoList.forEach((logo) => {
			this.emisorService.deleteLogo(logo).subscribe();
		});
	}

	getPlantillaTemplatesOfEmisor(templateRef, currentEmisor: Emisor) {
		//checks if there are templates for previewing a new logo then open modal
		this.loading = true;
		this.emisorService.getTipoPlantillasDisponibles(currentEmisor).subscribe(
			(result) => {
				if (result.data.length > 0) {
					this.emisorToEdit.plantillasPdfOfEmisor = result.data as PlantillaImpresion[];
				}
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al obtener plantillas de pdf disponibles",
					text: "No fue posible obtener la lista de plantillas de pdf disponibles para el emisor seleccionado, puede continuar agregando un logo pero no podrá realizar la previsualización de dicho logo por el momento.",
				});
			},
			() => {
				this.openAddLogo(templateRef, this.emisorToEdit);
			}
		);
	}

	sendLogoRequest(event: LogoEmisorRequest, refreshTable?: boolean, fromCreatedEmisor?: boolean) {
		this.loading = true;
		if (this.logoEmisorToEdit) {
			event.logoId = this.logoEmisorToEdit.logoId;
			this.emisorService.updateLogo(event).subscribe(
				(result) => {
					if (result.data) {
						Swal.fire({
							icon: "success",
							title: "Logo agregado",
							text: "Se ha actualizado el logo para la empresa seleccionada",
						});
						this.loading = false;
						this.ref.detectChanges();
						if (refreshTable) {
							this.setPage({ offset: 0 });
						}
						this.modalService.dismissAll();
					}
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al agregar logo",
						text: "Ha ocurrido un  error al actualizar el logo para la empresa seleccionada",
					});
				}
			);
		} else {
			this.emisorService.addLogo(event).subscribe(
				(result) => {
					if (result.data) {
						if (fromCreatedEmisor) {
							Swal.fire({
								icon: "success",
								title: "Empresa agregada",
								showCloseButton: true,
								html: "<div style='text-align:center;'><p>" + "El Emisor " + this.emisorToAdd.razonSocial + " fue agregado" + "</p></div>",
							});
						} else {
							Swal.fire({
								icon: "success",
								title: "Logo agregado",
								text: "Se ha agregado un nuevo logo para la empresa seleccionada",
							});
						}
						this.loading = false;
						this.ref.detectChanges();
						if (refreshTable) {
							this.setPage({ offset: 0 });
						}
						this.modalService.dismissAll();
					}
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al agregar logo",
						text: "Ha ocurrido un  error al agregar el nuevo logo para el emisor seleccionado",
					});
				}
			);
		}
	}

	closeAddLogoModal() {
		this.addLogoModalRef.close();
	}

	onSelect(event) {
		var fileExtensionName = this.getName(event);
		if (event.rejectedFiles[0] || (fileExtensionName.toLowerCase() != "png" && fileExtensionName.toLowerCase() != "jpg" && fileExtensionName.toLowerCase() != "jpeg")) {
			Swal.fire({
				icon: "error",
				title: "Error de Archivo",
				text: "El archivo ingresado no es de formato de imagen .JPG o .PNG, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo",
			});
		}

		this.files.push(...event.addedFiles);
		if (this.files.length > 1) {
			this.files.shift();
		}
		this.logoRequest.logoCompleteFile = this.files[0];
		this.logoRequest.nombre = event.addedFiles[0].name;
		this.loading = true;
		this.utilities
			.convertFileToB64String(this.logoRequest.logoCompleteFile)
			.then((data) => {
				this.logoRequest.logoFile = data.split(",")[1];
				this.loading = false;
				this.ref.detectChanges();
			})
			.catch(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}
	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
		this.logoRequest.logoCompleteFile = undefined;
	}

	getName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}
	prepareAndSendLogoEmisorRequest(emisorId: string) {
		this.logoRequest.emisorId = emisorId;
		this.logoRequest.fechaSubida = new Date();
		this.logoRequest.propiedadEnum = this.logoRequest.propiedadElementObj ? this.logoRequest.propiedadElementObj.id : 0;
		this.sendLogoRequest(this.logoRequest, true, true);
	}

	createPasswordEditForm() {
		this.passwordEditForm = this.formBuilder.group(
			{
				oldPassword: [],
				password: [, [Validators.required]],
				repeatPassword: [, [Validators.required]],
			},
			{
				validators: SameWordValidator,
			}
		);
	}

	openPasswordEditModal(modalRef, row: Emisor) {
		this.emisorToEdit = row as Emisor;
		this.passwordEditForm.reset();

		// if (row.tienePassSii) {
		//    this.passwordEditForm.addControl("oldPassword", new FormControl());
		//    this.passwordEditForm.controls.oldPassword.setValidators(Validators.required);
		// } else if (!row.tienePassSii && this.passwordEditForm.contains("oldPassword")) {
		//    this.passwordEditForm.removeControl("oldPassword");
		// }

		// if(this.emisorToEdit.passwordSii){
		//    this.passwordEditForm.controls.oldPassword.patchValue(this.emisorToEdit.passwordSii);
		// }
		this.editPasswordModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	onSubmitPassForm() {
		if (this.passwordEditForm.valid) {
			let passRequest = new EmisorPassRequest();
			passRequest.emisorId = this.emisorToEdit.emisorId;
			passRequest.newPass = this.passwordEditForm.controls.password.value;

			this.loading = true;
			this.emisorService.updatePasswordSii(passRequest).subscribe(
				(result) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Password de SII modificado",
						text: "La actualización del password de S.I.I ha sido exitosa.",
					});
					this.editPasswordModalRef.close();
					this.setPage({ offset: 0 });
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al actualizar password",
						text: "No fue posible actualizar el password del Servicio de Impuestos internos. " + this.utilities.setErrorMessageFromArray(error.error),
					});
					this.editPasswordModalRef.close();
				}
			);
		}
	}

	changeAmbient(row: Emisor) {
		Swal.fire({
			icon: "error",
			title: "Cambio de Ambiente",
			text: "Se cambiará el ambiente del emisor " + row.razonSocial + " que actualmente se encuentra en ambiente " + row.nombreAmbiente + ". ¿Desea continuar?",
			confirmButtonText: "Continuar",
			confirmButtonColor: "#1BC5BD",
			showCancelButton: true,
			cancelButtonText: "Cancelar",
			cancelButtonColor: "#F64E60",
		}).then((result) => {
			if (result.isConfirmed) {
				this.loading = true;
				this.ref.detectChanges();
				let request: UpdateEmisorEnvironment = new UpdateEmisorEnvironment();
				request.emisorId = row.emisorId;
				request.usuarioId = this.tokenService.getUser().id;
				request.ambienteId = row.ambiente == 0 ? 1 : 0;
				this.emisorService.changeAmbient(request).subscribe(
					(result) => {
						if (result.data) {
							this.loading = false;
							this.ref.detectChanges();
							row.ambiente = request.ambienteId;
							this.tokenService.updateAmbientOfCompany(request.emisorId, request.ambienteId);
							Swal.fire({
								icon: "success",
								title: "Cambio de Ambiente",
								text: "Cambio de ambiente exitoso",
							});
							this.setPage({ offset: 0 });
						}
					},
					(error) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Error en cambio",
							text: "No fue posible cambiar el ambiente de la empresa seleccionada. " + this.utilities.setErrorMessageFromArray(error.error),
						});
					}
				);
			} else {
				return;
			}
		});
	}
}
