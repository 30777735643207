export enum typeOfNullifyCN {
	"Anulación",
	"Corrección Monto",
	"Corrección Texto",
}

export enum typeOfNullifyDNForCN {
	"Anulación",
}

export enum typeOfNullifyDNInterests {
	"Intereses",
}
export enum typeOfNullifyCNForDN {
	"Anulación",
}

export enum typeOfNullifyCNForDteExport {
	"Anulación",
}
export enum typeOfNullifyDNForDteExport {
	"Anulación",
}
