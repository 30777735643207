import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SortType } from "@swimlane/ngx-datatable";
import { Impuesto } from "src/app/models/Impuesto";
import { ImpuestoFilter } from "src/app/models/ImpuestoFilter";
import { TipoImpuesto } from "src/app/models/TipoImpuesto";
import { ImpuestoService } from "src/app/services/impuesto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigMantenedorImpuesto } from "./config-impuestos";

@Component({
   selector: "app-mantenedor-impuestos",
   templateUrl: "./mantenedor-impuestos.component.html",
   styleUrls: ["./mantenedor-impuestos.component.scss"],
})
export class MantenedorImpuestosComponent implements OnInit {
   SortType = SortType;
   public loading: boolean = false;
   public rowsMantenedor: Impuesto[] = [];
   public page: Page = new Page();
   private filter = new ImpuestoFilter();
   public selectedTax: Impuesto = undefined;
   private createTaxModalRef: NgbModalRef;
   public configLocal = ConfigMantenedorImpuesto;
   private editTaxModalRef: NgbModalRef;
   private detailTaxModalRef: NgbModalRef;
   public taxTypeSource = JSON.parse(this.localStorage.getTipoImpuestos()) as TipoImpuesto[];
   // [
   //   {id:0,nombre:"Licores",valorMaximo:0.15,unidadMedida:"%",tipoCambio:'CLP'},
   //   {id:1,nombre:"Vinos",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:2,nombre:"Cervezas",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:3,nombre:"Bebidas Analcoholicas y Minerales",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:4,nombre:"Bebidas Analcoholicas y Minerales Alta Azucar",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:5,nombre:"Impuesto Art 37 E-L",valorMaximo:0.15,unidadMedida:"%"},
   //   {id:6,nombre:"Impuesto Art 37 J",valorMaximo:0.5,unidadMedida:"%"}
   // ]
   public componentStarted: boolean = false;
   @ViewChild("tableMobile") tableRef: any;
   constructor(public permissions: PermissionService,
       private impuestoService: ImpuestoService,
        private modalService: NgbModal,
         private ref: ChangeDetectorRef,
          private localStorage: LocalStorageService,
           public utilities: UtilitiesService) 
           {
            this.filter.activo = true;
           }

   ngOnInit(): void {
      this.setPage({ offset: 0 });
   }
   setPageSize() {
      //this.page.currentPageSize = this.page.page
      this.setPage({ offset: 0 });
   }
   setPage(pageInfo, event?) {
      this.loading = true;
      this.page.pageNumber = pageInfo.offset;
      let localPage = JSON.parse(JSON.stringify(this.page));
      localPage.pageNumber += 1;
      this.impuestoService
         .getDataByPage(this.filter, localPage)
         .subscribe(
            (result) => {
               let rows = result.data as Impuesto[];
               rows.forEach((tax) => {
                  tax.tipoImpuestoObj = this.getTipoImpuesto(tax);
               });
               this.rowsMantenedor = [...rows];
               this.setPageFromResponse(this.page, result);
               this.loading = false;
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Impuestos",
                  text: "No ha sido posible ob tener la lista de impuestos, recargue la página." + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
            this.componentStarted = true;
         });
   }

   openCreateTaxModal(modalRef) {
      this.createTaxModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
      });
   }
   closeCreateModal() {
      this.createTaxModalRef.close();
   }

   sendNewTax(event: Impuesto) {
      if (this.checkIfTaxRepeated(event, this.rowsMantenedor)) {
         Swal.fire({
            icon: "error",
            title: "Código en uso",
            text: "el código para el tipo de impuesto a ingresar está siendo utilizado por otro impuesto en la lista, cambie el código del impuesto a crear e intente nuevamente. En caso de que necesite crear un impuesto del tipo seleccionado, active y/o edite el impuesto ya existente.",
         });
         return;
      }
      this.loading = true;
      event.activo = true;
      event.impuestoId = 0;
      event.isRetencion = false;
      this.impuestoService.sendTaxRequest(event).subscribe(
         (result) => {
            let rows = this.rowsMantenedor;
            event.activo = true;
            rows.unshift(event);
            this.rowsMantenedor = [...rows];
            this.setPage({ offset: 0 });
            this.loading = false;
            this.createTaxModalRef.close();
            this.ref.markForCheck();
            Swal.fire({
               icon: "success",
               title: "Impuesto agregado",
               text: "Se ha agregado correctamente el impuesto " + event.nombre,
            });
         },
         (error) => {
            this.loading = false;
            this.createTaxModalRef.close();
            this.ref.detectChanges();
            let errorMsg = this.utilities.setErrorMessageFromArray(error.error);
            Swal.fire({
               icon: "error",
               title: "Error al agregar impuesto",
               text: "No ha sido posible agregar el impuesto " + event.nombre + ", intente nuevamente." + (errorMsg ? " Detalle: " + errorMsg : ""),
            });
         }
      );
   }
   openEditTaxModal(modalRef, row: Impuesto) {
      this.selectedTax = { ...row };
      this.editTaxModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
      });
   }
   closeEditModal() {
      this.editTaxModalRef.close();
   }

   editTaxRequest(event) {
      this.loading = true;
      this.impuestoService.editTaxRequest(event).subscribe(
         (result) => {
            let rows = this.rowsMantenedor;
            rows.unshift(event);
            //this.rowsMantenedor = [...rows];
            this.setPage({ offset: 0 });
            this.loading = false;
            this.editTaxModalRef.close();
            this.ref.markForCheck();
            Swal.fire({
               icon: "success",
               title: "Impuesto editado",
               text: "Se ha editado correctamente el impuesto " + event.nombre,
            });
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            this.editTaxModalRef.close();
            let errorMsg = this.utilities.setErrorMessageFromArray(error.error);
            Swal.fire({
               icon: "error",
               title: "Error al editar impuesto",
               text: "No ha sido posible editar el impuesto " + event.nombre + ", intente nuevamente." + (errorMsg ? " Detalle: " + errorMsg : ""),
            });
         }
      );
   }

   openDetailsTaxModal(modalRef, row: Impuesto) {
      this.selectedTax = { ...row };
      this.detailTaxModalRef = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
      });
   }
   closeDetailsModal() {
      this.selectedTax = undefined;
      this.detailTaxModalRef.close();
   }
   reactivateTax(row: Impuesto) {
      this.loading = true;
      this.impuestoService.activateTax(row).subscribe(
         (res) => {
            row.activo = true;
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Impuesto reactivado",
               text: "Se ha reactivado correctamente el impuesto " + row.nombre,
            });
            this.setPage({ offset: 0 });
         },
         (error) => {
            this.loading = false;
            Swal.fire({
               icon: "error",
               title: "Error de reactivación",
               text: "Se ha reactivado correctamente el impuesto " + row.nombre + ". " + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }
   deactivateTax(row: Impuesto) {
      this.loading = true;
      this.impuestoService.deactivateTax(row).subscribe(
         (res) => {
            row.activo = false;
            this.loading = false;
            Swal.fire({
               icon: "success",
               title: "Impuesto desactivado",
               text: "Se ha desactivado correctamente el impuesto " + row.nombre,
            });
            this.setPage({ offset: 0 });
         },
         (error) => {
            this.loading = false;
            Swal.fire({
               icon: "error",
               title: "Error de desactivación",
               text: "Se ha desactivado correctamente el impuesto " + row.nombre + ". " + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }

   getTipoImpuesto(tax: Impuesto) {
      return this.taxTypeSource.find((type) => type.id == tax.tipoImpuesto);
   }

   setPageFromResponse(currentPage: Page, data: PagedResponse<Impuesto>) {
      currentPage.pageSize = data.pageSize;
      currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
      currentPage.totalPages = data.totalPages;
      currentPage.totalElements = data.totalRecords;
   }

   checkIfTaxRepeated(entering: Impuesto, currentList: Impuesto[]) {
      let isPresent: boolean = false;
      isPresent = currentList.some((tax) => tax.tipoImpuesto == entering.tipoImpuesto);
      return isPresent;
   }
   searchElementsByFilter(event) {
      //console.log(event);
      this.filter[event.criterio] = event.busqueda;
      this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
      this.filter.activo = event.registroActivo ? false : true;
      this.setPage({ offset: 0 });
   }

   toggleExpandRow(row) {
      this.tableRef.rowDetail.toggleExpandRow(row);
   }
}
