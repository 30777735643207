<section class="row mb-5" #classicBar *ngIf="!useAccordionMode">
	<div class="col-12">
		<div class="card">
			<div class="card-body">
				<div class="sidebar-toggle d-block d-lg-none" (click)="sidebar($event)">
					<i class="feather ft-menu font-large-1"></i>
				</div>
				<form [formGroup]="filtrosBusqueda" (ngSubmit)="filtrar()">
					<div class="row">
						<div class="col-criterio-filtro-dte" *ngIf="filtroDTE">
							<ng-select formControlName="tiposDTE" [items]="tipoDteSource" placeholder="Tipo de DTE" bindLabel="nombre"> </ng-select>
						</div>
						<div class="col-auto mt-3" *ngIf="filtroImportado">
							<div class="row">
								<div class="col-2 pl-0">
									<input type="checkbox" formControlName="importado" />
								</div>
								<div class="col-10 pr-0" style="padding-left: 2px">Importado Sii</div>
							</div>
						</div>
						<div class="col-auto mt-3" *ngIf="filtroReutilizado">
							<div class="row">
								<div class="col-2 pl-0">
									<input type="checkbox" formControlName="reutilizado" />
								</div>
								<div class="col-10 pr-0" style="margin-left: 0.5rem">Dados de Baja</div>
							</div>
						</div>
						<div class="col-auto mt-3" *ngIf="filtroAnulados">
							<div class="row">
								<div class="col-2 pl-0">
									<tri-state-checkbox formControlName="anulados"></tri-state-checkbox>
								</div>
								<div class="col-10 pr-0" style="margin-left: 0.5rem">Nulos</div>
							</div>
						</div>

						<div class="col-3" *ngIf="filtroEstadoSii">
							<ng-select formControlName="estadoDte" [items]="EstadosDte" placeholder="Estado del DTE" bindLabel="descripcion"></ng-select>
						</div>

						<div *ngIf="criterioPrincipal">
							<div
								class="col-auto input-group"
								[ngClass]="{
									'pr-3': false
								}">
								<div class="input-group-prepend">
									<select class="form-control ng-select-custom" formControlName="criterios" [(ngModel)]="criterioPrincipal" (change)="changeValidation($event)" [ngClass]="{ 'is-invalid': submitted && f.criterios.errors }">
										<option *ngFor="let criterio of config.criterios" [value]="criterio">
											{{ criterio }}
										</option>
									</select>
									<small class="form-text text-muted danger" *ngIf="submitted && f.criterios.errors" class="invalid-feedback">
										<div *ngIf="f.criterios.errors.required">Se requiere un criterio</div>
									</small>
								</div>
								<ng-template [ngIf]="!criterioPrincipal.toString().includes('Rut')">
									<input type="text" autocomplete="off" id="projectinput1" class="form-control" formControlName="campoBusqueda" placeholder="Escribe para buscar" />
								</ng-template>
								<ng-template [ngIf]="criterioPrincipal.toString().includes('Rut')">
									<input ngbTooltip="Ingresar RUT sin puntos y con guión" type="text" autocomplete="off" id="projectinput1" class="form-control" formControlName="campoBusqueda" placeholder="Escribe el rut" pattern="^0*(\d{1,3}(\.?\d{3})*)\-([\dkK])$" maxlength="11" />
									<div class="invalid-rut col-12 text-danger" *ngIf="submitted && f.campoBusqueda.invalid">
										<div *ngIf="f.campoBusqueda.errors.required">Campo obligatorio</div>
										<div *ngIf="f.campoBusqueda.errors.pattern">Favor ingresar RUT sin puntos y con guión.</div>
										<div *ngIf="f.campoBusqueda.errors?.VerificacionRutValidator">Rut no válido</div>
									</div>
								</ng-template>
							</div>
						</div>
						<div class="col-3 date-range pl-0 form-group" [hidden]="!config.datePicker">
							<span class="fa fa-calendar-alt form-control-icon-2 mt-1"></span>
							<input type="text" ngxDaterangepickerMd formControlName="selectedRangeDate" class="form-control form-control-calendar" [locale]="locale" [placeholder]="nombreCampoDatePicker" [showClearButton]="true" #mainPicker id="mainPicker" />
						</div>

						<div class="col-12 col-lg-6 mb-3" [hidden]="!filtroEmisor" *ngIf="emisoresSource != undefined && emisoresSource.length > 0">
							<!-- permissions.getPermission('30-40-27').asignado -->
							<ng-select formControlName="emisor" [items]="emisoresSource" (change)="filterSucursalesByEmisor()" placeholder="Empresas Asociadas" bindLabel="razonSocial" [clearable]="emisorClearable">
								<ng-template ng-option-tmp let-item="item">
									<div [ngbTooltip]="item.razonSocial" placement="bottom" container="body">{{ item.razonSocial }}</div>
								</ng-template>
							</ng-select>
						</div>
						<div class="col-12 col-lg-3 mb-3" [hidden]="!filtroEmisor && !filtroSucursal" *ngIf="emisoresSource != undefined && emisoresSource.length > 0 && sucursalesSource != undefined && sucursalesSource.length > 1 && filtroSucursal">
							<ng-select formControlName="sucursal" [items]="sucursalesSource" placeholder="Sucursales Asociadas" bindLabel="nombre" [clearable]="sucursalClearable" [(ngModel)]="sucursalSeleccionada">
								<ng-template ng-option-tmp let-item="item">
									<div [ngbTooltip]="item.razonSocial" placement="bottom" container="body">{{ item.nombre }}</div>
								</ng-template>
							</ng-select>
						</div>
						<!-- <div class="col-2" [hidden]="!filtroEstadoDte"
                     [ngClass]="{
                        'col-criterio-estado': filtroEstadoDte && !filtroClausulaVenta,
                        'col-criterio-estado-corto':filtroClausulaVenta
                     }"   
                  >
                     
                  </div> -->
						<div class="col-2" *ngIf="filtroTipoEnvio">
							<ng-select formControlName="tipoEnvioCorreo" [items]="tipoEnvioSource" [clearable]="true" placeholder="Filtro Tipo Envío" bindLabel="nombre"></ng-select>
						</div>

						<div class="col-3" *ngIf="filtroTipoPlantillaEnvio">
							<ng-select formControlName="tipoPlantillaEnvioCorreo" [items]="tipoPlantillaEnvioSource" [clearable]="true" placeholder="Filtro Tipo Plantilla" bindLabel="nombre"> </ng-select>
						</div>

						<div class="col-1 pr-0 ml-5 col-checkbox" [hidden]="!productoExento">
							<div class="row mt-3">
								<div class="col-1">
									<input type="checkbox" formControlName="productoExento" />
								</div>
								<div class="col-9 pr-0" style="padding-left: 0.5rem">Exento</div>
							</div>
						</div>
						<div class="col-3" [hidden]="!filtroOrigenImportacion">
							<ng-select formControlName="origenImportacion" [items]="origenImportacionesSource" placeholder="Origen de Importación" bindLabel="descripcion"></ng-select>
						</div>
						<div class="col-3" [hidden]="!filtroTipoTraslados">
							<ng-select formControlName="tipoTraslado" [items]="tipoTrasladosSource" placeholder="Tipo Traslado" bindLabel="nombre"></ng-select>
						</div>
						<div
							class="col-3"
							[hidden]="!filtroTipoDespacho"
							[ngClass]="{
								'': filtroTipoDespacho
							}">
							<ng-select formControlName="tipoDespacho" [items]="tipoDespachoSource" placeholder="Tipo Despacho" bindLabel="nombre"></ng-select>
						</div>

						<div
							class="col-3"
							[hidden]="!filtroFacturables"
							[ngClass]="{
								'': filtroFacturables
							}">
							<ng-select formControlName="facturable" [items]="facturablesSource" placeholder="Facturable" bindLabel="nombre"></ng-select>
						</div>
						<div
							class="col-auto mt-3"
							*ngIf="filtroEstadoPlan"
							[ngClass]="{
								'col-2': insideModal
							}">
							<!-- <ng-select formControlName="estadosPlan" [items]="estadosPlanSource" [multiple]="false"
                     placeholder="Estado Registros">

                  </ng-select> -->
							<div class="row">
								<div class="col-2 pl-0">
									<input type="checkbox" formControlName="estadosPlan" />
								</div>
								<div class="col-10 pr-0" style="padding-left: 0">Desactivado</div>
							</div>
						</div>
						<div
							class="col-1 pr-0"
							[hidden]="!registroActivacion"
							[ngClass]="{
								'col-2': insideModal,
								'col-1 col-checkbox': !insideModal
							}">
							<div class="row mt-3">
								<div class="col-1 pl-0">
									<input type="checkbox" formControlName="registroActivo" />
								</div>
								<div class="col-10 pr-0" style="padding-left: 0.5rem">Desactivado</div>
							</div>
						</div>
						<div
							class="pl-0 py-auto"
							*ngIf="!filtroProductoModal"
							[ngClass]="{
								'col-2': insideModal,
								'col-1 ml-4 adjust-search-button': !insideModal && !filtroDTE,
								'mt-2 offset-md-10': filtroEmisor && insideModal && mostrarListaCompletaEmisores
							}">
							<div class="form-group row mx-auto pull-right my-auto">
								<button
									type="submit"
									class="btn-block btn btn-primary btn-sm right_space"
									[ngClass]="{
										'p-sm-button': !insideModal && !(filtroDTE && filtroEstadoDte && !filtroEmisor),
										'': filtroTipoDespacho && filtroFacturables,
										'my-auto': !(filtroTipoDespacho && filtroFacturables),
										'btn-mt-5': filtroDTE && filtroEstadoDte && !filtroEmisor
									}">
									<i class="flaticon-search-1"></i> Buscar
								</button>
							</div>
						</div>
						<div *ngIf="filtroProductoModal" class="ml-auto pl-0 py-auto col-auto">
							<button type="submit" class="btn btn-primary btn-sm right_space" [ngClass]="{}"><i class="flaticon-search-1"></i> Buscar</button>
						</div>
					</div>
					<div class="row">
						<div *ngIf="filtrosBusqueda.errors?.fechaInicioMayor && (filtrosBusqueda.touched || filtrosBusqueda.dirty)" class="cross-validation-error-message alert alert-danger">"Fecha Desde" debe ser anterior a "Fecha hasta"</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>
<ngb-accordion class="mb-5" #acc="ngbAccordion" *ngIf="useAccordionMode">
	<ngb-panel>
		<ng-template ngbPanelHeader>
			<button ngbPanelToggle class="accordion-button bg-white btn btn-block text-left d-flex" (click)="clickedAccordionButton()">
				<p class="h5 d-inline">Filtros de Busqueda</p>
				<span *ngIf="clicked" title="Item a corregir" aria-label="Item a corregir" [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-down.svg'" cacheSVG="true" class="ml-auto svg-icon svg-icon-xl svg-icon-primary"></span>
				<span *ngIf="!clicked" title="Item a corregir" aria-label="Item a corregir" [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-right.svg'" cacheSVG="true" class="ml-auto svg-icon svg-icon-xl svg-icon-primary"></span>
			</button>
		</ng-template>
		<ng-template ngbPanelContent>
			<ng-container *ngTemplateOutlet="searchBarAccordion"></ng-container>
		</ng-template>
	</ngb-panel>
</ngb-accordion>

<!-- FILTRO para mantenedores en ACCORDION-->
<ng-template #searchBarAccordion>
	<form [formGroup]="filtrosBusqueda" (ngSubmit)="filtrar()">
		<!-- filtros  base-->
		<div class="row">
			<div
				*ngIf="criterioPrincipal"
				class="col-12 col-lg-3 mb-3"
				[ngClass]="{
					'col-lg-6': extraLengthGenericInput
				}">
				<div class="input-group">
					<div class="input-group-prepend">
						<select class="px-1 form-control ng-select-custom" formControlName="criterios" [(ngModel)]="criterioPrincipal" (change)="changeValidation($event)" [ngClass]="{ 'is-invalid': submitted && f.criterios.errors }">
							<option *ngFor="let criterio of config.criterios" [value]="criterio">
								{{ criterio }}
							</option>
						</select>
						<small class="form-text text-muted danger" *ngIf="submitted && f.criterios.errors" class="invalid-feedback">
							<div *ngIf="f.criterios.errors.required">Se requiere un criterio</div>
						</small>
					</div>
					<ng-template [ngIf]="criterioPrincipal.toString().includes('Track') || criterioPrincipal.toString().includes('Folio')">
						<input type="number" min="0" autocomplete="off" id="projectinput1" class="px-1 form-control" formControlName="campoBusqueda" placeholder="Ingrese número" aria-haspopup="false" />
					</ng-template>
					<ng-template [ngIf]="!criterioPrincipal.toString().includes('Rut') && !criterioPrincipal.toString().includes('Track') && !criterioPrincipal.toString().includes('Folio')">
						<input type="text" autocomplete="off" id="projectinput1" class="px-1 form-control" formControlName="campoBusqueda" placeholder="Escriba busqueda" />
					</ng-template>

					<ng-template [ngIf]="criterioPrincipal.toString().includes('Rut')">
						<input ngbTooltip="Ingresar RUT sin puntos y con guión" type="text" autocomplete="off" id="projectinput1" class="px-1 form-control" formControlName="campoBusqueda" placeholder="Escriba rut" pattern="^0*(\d{1,3}(\.?\d{3})*)\-([\dkK])$" maxlength="11" />
						<div class="invalid-rut col-12 text-danger" *ngIf="submitted && f.campoBusqueda.invalid">
							<div *ngIf="f.campoBusqueda.errors.required">Campo obligatorio</div>
							<div *ngIf="f.campoBusqueda.errors.pattern">Favor ingresar RUT sin puntos y con guión.</div>
							<div *ngIf="f.campoBusqueda.errors?.VerificacionRutValidator">Rut no válido</div>
						</div>
					</ng-template>
				</div>
			</div>
			<div class="col-12 col-lg-3 mb-3" *ngIf="filtroDTE">
				<ng-select formControlName="tiposDTE" [items]="tipoDteSource" placeholder="Tipo de DTE" bindLabel="nombre"></ng-select>
			</div>

			<div class="col-12 col-lg-3 mb-3" *ngIf="filtroEstadoSii">
				<ng-select formControlName="estadoDte" [items]="EstadosDte" placeholder="Estado del DTE" bindLabel="descripcion"></ng-select>
			</div>

			<!-- <div class="col-auto" *ngIf="filtroEstadoPlan" [ngClass]="{
                        'col-3':insideModal
                     }">
            <ng-select formControlName="estadosPlan" [items]="estadosPlanSource" [multiple]="false"
               placeholder="Estado Registros"></ng-select>
         </div> -->
			<div
				class="col-auto mt-3"
				*ngIf="filtroEstadoPlan"
				[ngClass]="{
					'col-2': insideModal
				}">
				<div class="row">
					<div class="col-2 pl-0">
						<input type="checkbox" formControlName="estadosPlan" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0">Desactivado</div>
				</div>
			</div>

			<div class="col-12 col-lg-3 mb-3 date-range form-group w-auto" [hidden]="!config.datePicker">
				<span class="fa fa-calendar-alt form-control-icon-2 mt-1"></span>
				<input autocomplete="off" type="text" ngxDaterangepickerMd formControlName="selectedRangeDate" class="form-control form-control-calendar" [locale]="locale" [placeholder]="nombreCampoDatePicker" [showClearButton]="true" #mainPicker id="mainPicker" />
			</div>

			<div class="col-12 col-lg-6 mb-3" [hidden]="!filtroEmisor" *ngIf="emisoresSource != undefined && emisoresSource.length > 0">
				<!-- permissions.getPermission('30-40-27').asignado -->
				<ng-select formControlName="emisor" [items]="emisoresSource" (change)="filterSucursalesByEmisor()" placeholder="Empresas Asociadas" bindLabel="razonSocial" [clearable]="emisorClearable">
					<ng-template ng-option-tmp let-item="item">
						<div [ngbTooltip]="item.razonSocial" placement="bottom" container="body">{{ item.razonSocial }}</div>
					</ng-template>
				</ng-select>
			</div>
			<div class="col-12 col-lg-3 mb-3" [hidden]="!filtroEmisor && !filtroSucursal" *ngIf="emisoresSource != undefined && emisoresSource.length > 0 && sucursalesSource != undefined && sucursalesSource.length > 1 && filtroSucursal">
				<ng-select formControlName="sucursal" [items]="sucursalesSource" placeholder="Sucursales Asociadas" bindLabel="nombre" [clearable]="sucursalClearable" [(ngModel)]="sucursalSeleccionada">
					<ng-template ng-option-tmp let-item="item">
						<div [ngbTooltip]="item.razonSocial" placement="bottom" container="body">{{ item.nombre }}</div>
					</ng-template>
				</ng-select>
			</div>
			<div class="col-12 col-lg-3 mb-3" [hidden]="!filtroAcuseEmitidos" *ngIf="estadoAcuseEmitidosSource != undefined && estadoAcuseEmitidosSource.length > 1">
				<ng-select formControlName="estadoAcusoEmitidos" [items]="estadoAcuseEmitidosSource" placeholder="Estado de Acuse del Documento" bindLabel="nombre" [clearable]="true"></ng-select>
			</div>
			<div
				class="col-2"
				[hidden]="!filtroEstadoDte"
				[ngClass]="{
					'col-criterio-estado': filtroEstadoDte,
					'col-criterio-estado-corto': false
				}"></div>

			<div class="col-12 col-lg-2 mb-3" *ngIf="filtroTipoEnvio">
				<ng-select formControlName="tipoEnvioCorreo" [items]="tipoEnvioSource" [clearable]="true" placeholder="Filtro Tipo Envío" bindLabel="nombre"></ng-select>
			</div>

			<div class="col-12 col-lg-3 mb-3" *ngIf="filtroTipoPlantillaEnvio">
				<ng-select formControlName="tipoPlantillaEnvioCorreo" [items]="tipoPlantillaEnvioSource" [clearable]="true" placeholder="Filtro Tipo Plantilla" bindLabel="nombre"></ng-select>
			</div>

			<div class="col-12 col-lg-3 mb-3" [hidden]="!filtroOrigenImportacion">
				<ng-select formControlName="origenImportacion" [items]="origenImportacionesSource" placeholder="Origen de Importación" bindLabel="descripcion"></ng-select>
			</div>
			<div class="col-12 col-lg-3 mb-3" [hidden]="!filtroTipoTraslados">
				<ng-select formControlName="tipoTraslado" [items]="tipoTrasladosSource" placeholder="Tipo Traslado" bindLabel="nombre"></ng-select>
			</div>
			<div
				class="col-12 col-lg-3 mb-3"
				[hidden]="!filtroTipoDespacho"
				[ngClass]="{
					'': filtroTipoDespacho
				}">
				<ng-select formControlName="tipoDespacho" [items]="tipoDespachoSource" placeholder="Tipo Despacho" bindLabel="nombre"></ng-select>
			</div>

			<div
				class="col-12 col-lg-3 mb-3"
				[hidden]="!filtroFacturables"
				[ngClass]="{
					'': filtroFacturables
				}">
				<ng-select formControlName="facturable" [items]="facturablesSource" placeholder="Facturable" bindLabel="nombre"></ng-select>
			</div>

			<div class="col-12 col-lg-3 mb-3" *ngIf="showRegiones">
				<ng-select formControlName="region" [items]="regionesSource" placeholder="Seleccione una región" bindLabel="nombre" (change)="loadComunasOfRegion($event)"> </ng-select>
				<span><small>No aplican a las boletas importadas</small></span>
			</div>
			<div class="col-12 col-lg-3 mb-3" *ngIf="showRegiones && showComunas">
				<ng-select formControlName="comuna" [items]="comunasSource" placeholder="Seleccione una comuna de la región" bindLabel="nombre"> </ng-select>
			</div>
			<!--checkbox inline -->
			<div
				class="col-2 pr-0 ml-4 ml-lg-4"
				[hidden]="!registroActivacion || !useInlineDeactivatedCheckbox"
				[ngClass]="{
					'col-2': insideModal,
					'col-2 col-checkbox': !insideModal
				}">
				<div class="row mt-3">
					<div class="col-1 pl-0">
						<input type="checkbox" formControlName="registroActivo" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0.5rem">Desactivado</div>
				</div>
			</div>
			<div
				class="col-2 pr-0 ml-4 ml-lg-4"
				[hidden]="!useDespliegueCheckbox && !useInlineDespliegueCheckbox"
				[ngClass]="{
					'col-2 col-checkbox': !insideModal
				}">
				<div class="row mt-3">
					<div class="col-1 pl-0">
						<input type="checkbox" formControlName="registroDespliegue" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0.5rem">Desplegado</div>
				</div>
			</div>
			<div class="col-2 pr-0 ml-5 col-checkbox" [hidden]="!productoExento || (!productoExento && !useInlineDeactivatedCheckbox)">
				<div class="row mt-3">
					<div class="col-1">
						<input type="checkbox" formControlName="productoExento" />
					</div>
					<div class="col-9 pr-0" style="padding-left: 0.5rem">Exento</div>
				</div>
			</div>
			<!-- boton buscar inline-->
			<div class="col" *ngIf="useInlineSearchButton">
				<div class="row">
					<button [disabled]="loading" type="submit" class="ml-auto btn btn-primary mr-4 p-2"><i class="flaticon-search-1"></i> Buscar</button>
				</div>
			</div>
		</div>

		<!-- checkboxes y boton-->
		<div class="row pl-4">
			<div
				class="col-1 pr-0"
				[hidden]="!registroActivacion || useInlineDeactivatedCheckbox"
				[ngClass]="{
					'col-2': insideModal,
					'col-1 col-checkbox': !insideModal
				}">
				<div class="row mt-3">
					<div class="col-1 pl-0">
						<input type="checkbox" formControlName="registroActivo" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0.5rem">Desactivado</div>
				</div>
			</div>
			<div
				class="col-1 pr-0"
				[hidden]="!useDespliegueCheckbox || useInlineDespliegueCheckbox"
				[ngClass]="{
					'col-2': insideModal,
					'col-1 col-checkbox': !insideModal
				}">
				<div class="row mt-3">
					<div class="col-1 pl-0">
						<input type="checkbox" formControlName="registroDespliegue" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0.5rem">Desplegado</div>
				</div>
			</div>
			<div class="col-auto mt-3" *ngIf="filtroImportado">
				<div class="row">
					<div class="col-2 pl-0">
						<input type="checkbox" formControlName="importado" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0.2rem">Importado Sii</div>
				</div>
			</div>

			<div class="col-auto mt-3 ml-3" *ngIf="filtroReutilizado">
				<div class="row">
					<div class="col-2 pl-0">
						<input type="checkbox" formControlName="reutilizado" />
					</div>
					<div class="col-10 pr-0" style="padding-left: 0.2rem">Dados de Baja</div>
				</div>
			</div>
			<div class="row col-auto mt-3 ml-3" *ngIf="filtroAnulados">
				<div class="col-2 pl-0">
					<tri-state-checkbox formControlName="anulados"></tri-state-checkbox>
				</div>
				<div class="col-10 pr-0" style="padding-left: 0.5rem">Anulados</div>
			</div>

			<div class="ml-auto mr-5" *ngIf="!filtroProductoModal && !useInlineSearchButton">
				<div class="form-group row mx-auto float-right my-auto">
					<button
						[disabled]="loading"
						type="submit"
						class="btn btn-primary btn-sm right_space"
						[ngClass]="{
							'p-sm-button': !insideModal && !(filtroDTE && filtroEstadoDte && !filtroEmisor),
							'': filtroTipoDespacho && filtroFacturables,
							'my-auto': !(filtroTipoDespacho && filtroFacturables),
							'btn-mt-5': filtroDTE && filtroEstadoDte && !filtroEmisor
						}">
						<i class="flaticon-search-1"></i> Buscar
					</button>
				</div>
			</div>
			<div *ngIf="filtroProductoModal && !useInlineSearchButton" class="float-right ml-auto pl-0 py-auto col-auto">
				<button [disabled]="loading" type="submit" class="btn btn-primary btn-sm right_space" [ngClass]="{}"><i class="flaticon-search-1"></i> Buscar</button>
			</div>
		</div>
	</form>
</ng-template>
