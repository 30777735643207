<tr [formGroup]="detalleFormGroup" #localDetalleForm="ngForm">
    <td WIDTH="1%">{{detalleNumero}}</td>
    <td WIDTH="12%">
        <div class="form-group">

            <div class="row">

                <div class="col-12" style="overflow: visible;">
                    <!-- <input type="text" class="form-control"  name="codigo" formControlName="codigo" (blur)="onSubmit()"> -->
                    <ng-select formControlName="tipo" [items]="types" placeholder="Ingrese tipo" bindLabel="nombre"
                        notFoundText="Sin resultado"></ng-select>

                </div>
            </div>
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.tipo.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.tipo.errors?.required">Campo Requerido</div>
            </div>
        </div>
    </td>

    <td WIDTH="48%">
        <div class="form-group">
            <input type="text" class="form-control" required placeholder="Ingrese glosa" name="nombreItem"
                formControlName="nombreItem" (blur)="onSubmit()">
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.nombreItem.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.nombreItem.errors?.required">Campo Requerido</div>
                <div *ngIf="detalleFormGroup.controls.nombreItem.errors?.maxlength">Max 60 Caracteres</div>
            </div>
        </div>
    </td>

    <!-- <td *ngIf="showingDetails">
            <div class="form-group">
                <div class="row pl-5 mb-4">
                    <div class="ml-5">
                        <input type="checkbox" class="form-check-input" name="haveDescripcion" formControlName="haveDescripcion" (change)="changeHaveDescripcion($event)" (blur)="onSubmit()">
                    </div>
                </div>
               
              
            </div>
        </td> -->

    <td WIDTH="12%">
        <div class="form-group">
            <input type="number" class="form-control" min="0" max="100" name="tasa" formControlName="tasa"
                (blur)="onSubmit()">

            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.tasa.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.tasa.errors?.required">Campo Requerido</div>
                <div *ngIf="detalleFormGroup.controls.tasa.errors?.PositiveNumber">Número debe ser positivo </div>
                <div *ngIf="detalleFormGroup.controls.tasa.errors?.max">El maximo es 100%</div>
            </div>
        </div>
    </td>
    <td WIDTH="12%">
        <div class="form-group">
            <input type="number" class="form-control" min="1" name="neto" formControlName="neto" (blur)="onSubmit()">
            <div class="clp-equivalent text-right mt-3" *ngIf="opciones.tipoMoneda!='CLP'">

                <div class="float-left">
                    CLP
                </div>
                <div class="float right">
                    {{this.subPrice | number:'1.0-2':'es'}}
                </div>
            </div>
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.neto.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.neto.errors?.required">Campo Requerido</div>
                <div *ngIf="detalleFormGroup.controls.neto.errors?.PositiveNumber">Número debe ser positivo
                </div>
            </div>
        </div>
    </td>


    <td WIDTH="12%">
        <div class="form-group">
            <input type="number" class="form-control" min="1" name="exento" formControlName="exento"
                (blur)="onSubmit()">
            <div class="clp-equivalent text-right mt-3" *ngIf="opciones.tipoMoneda!='CLP'">

                <div class="float-left">
                    CLP
                </div>
                <div class="float right">
                    {{this.subPrice | number:'1.0-2':'es'}}
                </div>
            </div>
            <div *ngIf="localDetalleForm.touched && detalleFormGroup.controls.exento.invalid" class="text-danger">
                <div *ngIf="detalleFormGroup.controls.exento.errors?.required">Campo Requerido</div>
                <div *ngIf="detalleFormGroup.controls.exento.errors?.PositiveNumber">Número debe ser positivo
                </div>
            </div>
        </div>
    </td>

    <!-- <td WIDTH="12%">
        <div class="float-right" style="width:100%">
            <div class="alter-equivalent text-right mb-5 pt-2" *ngIf="opciones.tipoMoneda != 'CLP'">
                <div class="mb-5 pt-2">

                    <div class="float-right">
                        {{totalForeign | number:'1.0-2':'es'}}

                    </div>
                </div>
                <div class="text-right mt-5 pt-5 pt-1">
                    <div class="float-left mt-1">
                        CLP
                    </div>
                    <div class="float-right mt-1">
                        {{detalleDte.total | number:'1.0-2':'es'}}
                    </div>
                </div>
            </div>
            
            <div class="text-right" *ngIf="opciones.tipoMoneda == 'CLP'">


                <div class="float-right">
                    {{detalleDte.total | number:'1.0-2':'es'}}
                </div>
            </div>
        </div>
    </td> -->
    <td>
        <button class="btn btn-danger" (click)="deleteItem()">
            <i class="flaticon-delete"></i>
        </button>
    </td>

</tr>