<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<form>
	<div class="modal-header">
		<div class="modal-wizard-label">
			<div class="modal-wizard-title">Personas Autorizadas</div>
			<div class="modal-wizard-desc">Se muestran las personas autorizadas para distintos procesos</div>
		</div>
		<div class="mt-5 form-group position-relative mx-auto">
			<div class="row pt-5">
				<div class="ml-5">
					<div ngbDropdown>
						<button ngbDropdownToggle id="dropdownPersonasAutorizadas" class="btn btn-primary float-right mr-5 mt-5" *ngIf="permissions.getPermission('30-80-21-10').asignado">Agregar Persona</button>
						<div ngbDropdownMenu aria-labelledby="dropdownPersonasAutorizadas">
							<button ngbDropdownItem *ngIf="permissions.getPermission('30-80-21-10').asignado" type="button" (click)="openAddAuthorizedPersonModal(addAuthorizedPerson, 1)">Persona Autorizada para Cesión</button>
							<button ngbDropdownItem *ngIf="permissions.getPermission('30-80-21-10').asignado" type="button" (click)="openAddAuthorizedPersonModal(addAuthorizedPerson, 2)">Persona Autorizada para BHE</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-body">
		<h4>Personas Autorizadas para Cesión de documentos</h4>
		<div *ngIf="authorizedPersonsCesionList.length > 0">
			<table class="table">
				<thead>
					<tr>
						<td>Rut</td>
						<td>Nombre</td>
						<td>Correo</td>
						<td>Acción</td>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of authorizedPersonsCesionList; let index = index">
						<td>{{ item.rutFormateado }}</td>
						<td>{{ item.nombre }}</td>
						<td>{{ item.correo }}</td>
						<td>
							<a *ngIf="permissions.getPermission('30-80-21-12').asignado" ngbTooltip="Editar" class="primary edit mr-1 mrless" (click)="openUpdateAuthorizedPersonModal(updateAuthorizedPerson, item, 1)">
								<div class="icon-circle bg-info text-center align-middle pt-info-button">
									<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
							<a
								*ngIf="permissions.getPermission('30-80-21-14').asignado"
								ngbTooltip="Eliminar"
								class="primary edit mr-1 mrless"
								[swal]="{
									title: 'Confirmación',
									text: '¿Está seguro de eliminar esta persona autorizada?',
									confirmButtonText: 'Eliminar',
									cancelButtonText: 'Cancelar',
									showCancelButton: true,
									confirmButtonColor: '#F64E60'
								}"
								(confirm)="deleteAuthorizedPerson(item)">
								<div class="icon-circle bg-danger text-center align-middle pt-info-button">
									<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="authorizedPersonsCesionList.length == 0">
			<p>No hay registro de personas autorizadas peara cesión de documentos.</p>
		</div>
		<h4>Personas Autorizadas para emisión de BHE</h4>
		<div *ngIf="authorizedPersonsBHEList.length > 0">
			<table class="table">
				<thead>
					<tr>
						<td>Rut</td>
						<td>Nombre</td>
						<td>Acción</td>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of authorizedPersonsBHEList; let index = index">
						<td>{{ item.rutFormateado }}</td>
						<td>{{ item.nombre }}</td>
						<td>
							<a *ngIf="permissions.getPermission('30-80-21-12').asignado" ngbTooltip="Editar" class="primary edit mr-1 mrless" (click)="openUpdateAuthorizedPersonModal(updateAuthorizedPerson, item, 2)">
								<div class="icon-circle bg-info text-center align-middle pt-info-button">
									<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
							<a
								*ngIf="permissions.getPermission('30-80-21-14').asignado"
								ngbTooltip="Eliminar"
								class="primary edit mr-1 mrless"
								[swal]="{
									title: 'Confirmación',
									text: '¿Está seguro de eliminar esta persona autorizada?',
									confirmButtonText: 'Eliminar',
									cancelButtonText: 'Cancelar',
									showCancelButton: true,
									confirmButtonColor: '#F64E60'
								}"
								(confirm)="deleteAuthorizedPerson(item)">
								<div class="icon-circle bg-danger text-center align-middle pt-info-button">
									<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="authorizedPersonsBHEList.length == 0">
			<p>No hay registro de personas autorizadas para emisión de BHE de terceros</p>
		</div>
	</div>
	<div class="modal-footer">
		<!-- <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" >
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">Guardar</span>
         </button> -->
		<button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>

<ng-template #addAuthorizedPerson let-d="dismiss">
	<form [formGroup]="addAuthorizedPersonForm" (ngSubmit)="add()" #addAuthorizedPersonFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Agregar Persona Autorizada</div>
					<div class="modal-wizard-desc">Ingresa datos de persona autorizada para {{ newType == 1 ? "cesión" : "BHE de terceros" }}</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Rut</label>
						<div class="col-9">
							<input type="text" name="rutString" id="rutString" formControlName="rutString" class="form-control" />
							<div *ngIf="addAuthorizedPersonFormRef.submitted && (addAuthorizedPersonForm.get('rutString').invalid || addAuthorizedPersonForm.get('rutString').dirty)" class="invalid-feedback">
								<div *ngIf="addAuthorizedPersonForm.get('rutString').hasError('VerificacionRutValidator')">Rut inválido</div>
								<div *ngIf="addAuthorizedPersonForm.get('rutString').errors?.required">Campo requerido</div>
								<div *ngIf="addAuthorizedPersonForm.get('rutString').errors?.maxLength">Mucho numero</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Nombre</label>
						<div class="col-9">
							<input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control" />
							<div *ngIf="addAuthorizedPersonFormRef.submitted && (addAuthorizedPersonForm.get('nombre').invalid || addAuthorizedPersonForm.get('nombre').dirty)" class="invalid-feedback">
								<div *ngIf="addAuthorizedPersonForm.get('nombre').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Correo Electrónico</label>
						<div class="col-9">
							<input type="text" name="correo" id="correo" formControlName="correo" class="form-control" />
							<div *ngIf="addAuthorizedPersonFormRef.submitted && (addAuthorizedPersonForm.get('correo').invalid || addAuthorizedPersonForm.get('correo').dirty)" class="invalid-feedback">
								<div *ngIf="addAuthorizedPersonForm.get('correo').errors?.required">Campo requerido</div>
								<div *ngIf="addAuthorizedPersonForm.get('correo').hasError('wrongEmail')">Correo inválido</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Guardar</span>
				</button>
				<button type="button" class="btn btn-secondary ml-3" (click)="closeAddAuthorizedPersonModal()">
					<span class="d-none d-lg-block">Cerrar</span>
				</button>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #updateAuthorizedPerson let-d="dismiss">
	<form [formGroup]="updateAuthorizedPersonForm" (ngSubmit)="update()" #updateAuthorizedPersonFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Actualizar Persona Autorizada</div>
					<div class="modal-wizard-desc">Ingresa datos de persona autorizada</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Rut</label>
						<div class="col-9">
							<input type="text" name="rutString" id="rutString" formControlName="rutString" class="form-control" />
							<div *ngIf="updateAuthorizedPersonFormRef.submitted && (updateAuthorizedPersonForm.get('rutString').invalid || updateAuthorizedPersonForm.get('rutString').dirty)" class="invalid-feedback">
								<div *ngIf="updateAuthorizedPersonForm.get('rutString').hasError('VerificacionRutValidator')">Rut inválido</div>
								<div *ngIf="updateAuthorizedPersonForm.get('rutString').errors?.required">Campo requerido</div>
								<div *ngIf="updateAuthorizedPersonForm.get('rutString').errors?.maxLength">Exceso de carácteres</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Nombre</label>
						<div class="col-9">
							<input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control" />
							<div *ngIf="updateAuthorizedPersonFormRef.submitted && (updateAuthorizedPersonForm.get('nombre').invalid || updateAuthorizedPersonForm.get('nombre').dirty)" class="invalid-feedback">
								<div *ngIf="updateAuthorizedPersonForm.get('nombre').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="col-2 label-control">Correo Electrónico</label>
						<div class="col-9">
							<input type="text" name="correo" id="correo" formControlName="correo" class="form-control" />
							<div *ngIf="updateAuthorizedPersonFormRef.submitted && (updateAuthorizedPersonForm.get('correo').invalid || updateAuthorizedPersonForm.get('correo').dirty)" class="invalid-feedback">
								<div *ngIf="updateAuthorizedPersonForm.get('correo').errors?.required">Campo requerido</div>
								<div *ngIf="updateAuthorizedPersonForm.get('correo').hasError('wrongEmail')">Correo inválido</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Password</label>
                        <div class="col-9">
                            <input type="password" autocomplete="off" name="password" id="password"
                                formControlName="password" class="form-control">
                            <div *ngIf="updateAuthorizedPersonFormRef.submitted &&( updateAuthorizedPersonForm.get('password').invalid ||  updateAuthorizedPersonForm.get('password').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="updateAuthorizedPersonForm.get('password').errors?.required">Campo requerido
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
			</div>
			<div class="modal-footer">
				<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
					<i class="la la-paper-plane-o d-lg-none"></i>
					<span class="d-none d-lg-block">Guardar</span>
				</button>
				<button type="button" class="btn btn-secondary ml-3" (click)="closeUpdateAuthorizedPersonModal()">
					<span class="d-none d-lg-block">Cerrar</span>
				</button>
			</div>
		</div>
	</form>
</ng-template>
