export const ConfigFacturaMasiva = {
	barraBusqueda: {
		criterios: ["Origen"],
		criteriosKeys: ["origen"],
		criterioPrincipal: "Origen",
		filiales: [] as any[],
		datePicker: true,
		nombreCampoDatePicker: "Fecha Carga (Desde - Hasta)",
	},
	table: {
		title: "Facturación Masiva",
		add: "Carga Documento",
		options: [
			{
				name: "Importar",
				active: true,
				class: "ft-upload",
			},
			{
				name: "Exportar",
				active: true,
				class: "ft-download",
			},
			{
				name: "Solicitar Soporte",
				active: true,
				class: "ft-shuffle",
			},
		],
	},
};
