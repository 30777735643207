import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { LayoutService } from "../../../../_metronic/core";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { ThrottlingConstants } from "@azure/msal-common/dist/utils/Constants";
import { environment } from "src/environments/environment";
const CHILE_SYSTEM_LOGO = "assets/deployAssets/logo/logo_pequenno.png";
const DEFAULT_LOGO = "assets/deployAssets/logo/logo_pequenno.png";
@Component({
	selector: "app-aside",
	templateUrl: "./aside.component.html",
	styleUrls: ["./aside.component.scss"],
})
export class AsideComponent implements OnInit {
	disableAsideSelfDisplay: boolean;
	headerLogo: string;
	brandSkin: string;
	ulCSSClasses: string;
	location: Location;
	asideMenuHTMLAttributes: any = {};
	asideMenuCSSClasses: string;
	asideMenuDropdown;
	brandClasses: string;
	asideMenuScroll = 1;
	asideSelfMinimizeToggle = false;
	appVersion = 1;
	pressCollapsed = false;
	currentEmisorId: string = "";
	currentEmisor: Emisor;
	private emisorObservable$: Observable<string>;
	private emisoresList: Emisor[] = [];
	public logoClasses = "img-fluid logo-full";
	public parentLogoClass = "brand-logo pt-1";
	@ViewChild("facturacionesMenu") factMenuRef: ElementRef<any>;
	public isSuperAdmin: boolean = false;
	constructor(private layout: LayoutService, private loc: Location, public permissions: PermissionService, private tokenService: TokenStorageService, private localStorage: LocalStorageService, private ref: ChangeDetectorRef, private router: Router, private renderer: Renderer2) {
		this.isSuperAdmin = this.tokenService.getUser().superAdmin;
	}

	ngOnInit(): void {
		// load view settings
		this.disableAsideSelfDisplay = this.layout.getProp("aside.self.display") === false;
		this.brandSkin = this.layout.getProp("brand.self.theme");
		this.headerLogo = this.getLogo();
		this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
		this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
		this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
		this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown") ? "1" : "0";
		this.brandClasses = this.layout.getProp("brand");
		this.asideSelfMinimizeToggle = this.layout.getProp("aside.self.minimize.toggle");
		this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
		// this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
		// Routing
		this.location = this.loc;
		this.emisorObservable$ = this.tokenService.currentEmisorObservable;
		this.emisoresList = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		this.currentEmisorId = this.tokenService.getCompany();
		this.currentEmisor = this.getEmisorInList(this.currentEmisorId);
		//this.changeLogo();
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			if (event.urlAfterRedirects == "/documentos-emitidos") {
				this.renderer.addClass(this.factMenuRef.nativeElement, "menu-item-open");
			}
		});
	}

	ngAfterContentInit() {
		this.emisorObservable$.subscribe((emisorId) => {
			this.currentEmisorId = emisorId;
			this.currentEmisor = this.getEmisorInList(this.currentEmisorId);
			//this.changeLogo();
		});
		this.currentEmisorId = this.tokenService.getCompany();
		this.currentEmisor = this.getEmisorInList(this.currentEmisorId);
		//this.changeLogo();
	}

	collapsed() {
		if (this.pressCollapsed) this.pressCollapsed = false;
		else this.pressCollapsed = true;
	}
	private getLogo() {
		//NO BORRAR EN CASO DE RECUPERAR FUNCIONALIDAD DE TEMAS CLARO/OSCURO
		// if (this.brandSkin === "light") {
		//    // return './assets/media/logos/logo-dark.png';
		//    return "assets/deployAssets/logo/logo_web.png";
		// } else {
		//    // return './assets/media/logos/logo-light.png';
		//    return "assets/deployAssets/logo/logo_web.png";
		// }
			return "assets/deployAssets/logo/logo_pequenno.png";
		
	}

	getEmisorInList(id: string): Emisor {
		return this.emisoresList.find((e) => e.emisorId == id);
	}

	// changeLogo() {
	// 	if (this.currentEmisor && this.currentEmisor.rutString == "76269769-6") {
	// 		this.headerLogo = CHILE_SYSTEM_LOGO;
	// 		this.logoClasses = "img-fluid logo-full";
	// 		this.parentLogoClass = "brand-logo pt-1";
	// 	} else {
	// 		this.headerLogo = DEFAULT_LOGO;
	// 		this.logoClasses = "img-fluid logo-full";
	// 		this.parentLogoClass = "brand-logo pt-1";
	// 	}
	// 	this.ref.detectChanges();
	// }
}
