import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "../services/local-storage.service";
import { EmisorService } from "../services/emisor.service";
import { TokenStorageService } from "../services/token-storage.service";
import Swal from "sweetalert2";
import { Emisor } from "../models/Emisor/Emisor";
import { Subject } from "rxjs";
import { RoleGuard } from "../modules/auth/_services/role.guard";

@Injectable({
	providedIn: "root",
})
export class ChangeCompanyService {
	companyChanged = new Subject<Emisor>();

	constructor(private router: Router, private localStorage: LocalStorageService, private tokenService: TokenStorageService, private emisorService: EmisorService) {}

	SetNewCompany(currentEmisorObject: Emisor, currentSucursalId: string, currentSwal: any): void {
		this.emisorService.setActualBranchOffice(currentSucursalId, this.tokenService.getUser().id).subscribe((res) => {});

		currentSwal.text = "Ahora opera en " + currentEmisorObject.razonSocial;
		currentSwal.buttonsStyling = false;

		Swal.fire({
			title: "Cambio de Empresa",
			icon: "info",
			html: "<h5>Ahora opera en " + currentEmisorObject.razonSocial + "</h5>",
			showCloseButton: false,
			showCancelButton: false,
			focusConfirm: true,
			confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendido',
			customClass: {
				confirmButton: "btn btn-success",
			},
		}).then((result) => {
			RoleGuard.reload$.next();
			let currentUrl = this.router.url;
			this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
				this.router.navigateByUrl(currentUrl);
			});
		});

		this.companyChanged.next(currentEmisorObject);
	}
}
