import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Response } from "../shared/models/Response";
import { CertificadoDigital } from "src/app/models/CertificadoDigital/CertificadoDigital";
import { TokenStorageService } from "./token-storage.service";
import { finalize } from "rxjs/operators";
import { CertificadoRequest } from "../models/Emisor/EmisorWizardRequest";
import { CertificadoDigitalFilter } from "../models/CertificadoDigital/CertificadoDigitalFilter";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { filter } from "jszip";
import { CertificadoFileObject } from "../models/CertificadoDigital/CertificadoFileObject";

@Injectable({
	providedIn: "root",
})
export class CertificadosDigitalesService {
	private REST_API_SERVER = environment.backend_server + "/certificado";
	isLoadingCertificateSummary: Observable<boolean>;
	private loadingCertificateSummarySubject: BehaviorSubject<boolean>;
	constructor(private http: HttpClient, private tokenService: TokenStorageService) {
		this.loadingCertificateSummarySubject = new BehaviorSubject<boolean>(true);
		this.isLoadingCertificateSummary = this.loadingCertificateSummarySubject.asObservable();
	}

	getDataByPage(filtro: CertificadoDigitalFilter, page: Page): Observable<PagedResponse<CertificadoDigital>> {
		let emisorId = filtro.emisorId ? filtro.emisorId : "";
		return this.http.get<PagedResponse<CertificadoDigital>>(this.REST_API_SERVER + "/list/filter/?emisorId=" + emisorId + "&Rut=" + (filtro.rut != undefined ? filtro.rut : "") + "&Nombre=" + (filtro.nombre != undefined ? filtro.nombre : "") + "&Activo=" + filtro.activo + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}
	public getData(emisorId): Observable<any> {
		this.loadingCertificateSummarySubject.next(true);
		return this.http.get<any>(this.REST_API_SERVER + "/list?emisorId=" + emisorId).pipe(finalize(() => this.loadingCertificateSummarySubject.next(false)));
	}

	public addCertificate(data: CertificadoFileObject): Observable<Response<string>> {
		data.sucursalId = this.tokenService.getSubsidiary();
		let formData = new FormData();
		formData.append("certificadoString", JSON.stringify(data));
		formData.append("file", data.file);
		return this.http.post<any>(this.REST_API_SERVER + "/upload", formData);
	}

	public delete(certificado: CertificadoDigital): Observable<Response<any>> {
		return this.http.post<any>(this.REST_API_SERVER + "/delete?certificadoId=" + certificado.certificadoDigitalId, null);
	}
	public validateCertificate(certificado: CertificadoRequest): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/validateCertificate", certificado);
	}

	public downloadCertificateRequest(row: CertificadoDigital): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER + "/downloadCertificate?CertificateId=" + row.certificadoDigitalId);
	}

	public getPassword(row: CertificadoDigital): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER + "/getPassword?CertificateId=" + row.certificadoDigitalId);
	}

	public deactivateCertificate(row: CertificadoDigital): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/deactivate?certificadoId=" + row.certificadoDigitalId, null);
	}

	public reactivateCertificate(row: CertificadoDigital): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/activate?certificadoId=" + row.certificadoDigitalId, null);
	}
}
