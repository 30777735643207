import { Component, ChangeDetectionStrategy, OnDestroy, OnInit, HostListener } from "@angular/core";
import { TranslationService } from "./modules/i18n/translation.service";
// language list
import { locale as enLang } from "./modules/i18n/vocabs/en";
import { locale as chLang } from "./modules/i18n/vocabs/ch";
import { locale as esLang } from "./modules/i18n/vocabs/es";
import { locale as jpLang } from "./modules/i18n/vocabs/jp";
import { locale as deLang } from "./modules/i18n/vocabs/de";
import { locale as frLang } from "./modules/i18n/vocabs/fr";
import { SplashScreenService } from "./_metronic/partials/layout/splash-screen/splash-screen.service";
import { Router, NavigationEnd, NavigationError, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { TableExtendedService } from "./_metronic/shared/crud-table";
import { TokenStorageService } from "./services/token-storage.service";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { filter, timeout } from "rxjs/operators";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import Swal from "sweetalert2";
import { AuthService } from "./modules/auth";
import { environment } from "src/environments/environment";
@Component({
	// tslint:disable-next-line:component-selector
	//selector: 'body[root]',
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	constructor(private translationService: TranslationService, private splashScreenService: SplashScreenService, private router: Router, private tableService: TableExtendedService, private tokenService: TokenStorageService, private msalBroadcastService: MsalBroadcastService, private msalService: MsalService, private authService: AuthService) {
		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
	}

	ngOnInit() {
		const routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// clear filtration paginations and others
				this.tableService.setDefaults();
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add("page-loaded");
				}, 500);
			}
			if (event instanceof NavigationStart) {
			}
		});

		window.onpopstate = function (event) {};

		this.unsubscribe.push(routerSubscription);

		if (this.tokenService.getToken() == null && !this.tokenService.msalUserIsLogged()) {
			//checking for local token
			this.router.navigate(["/auth/login"]);
		}
	}

	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}

	callSyncSwal(): Promise<any> {
		return Swal.fire({
			icon: "warning",
			title: "Cierre de sesión",
			confirmButtonText: "Mantener Sesión",
			showConfirmButton: true,
			denyButtonText: "Cerrar Sesión",
			showDenyButton: true,
		}).then((result) => {
			if (result.isDenied) {
				this.authService.logout();
			} else if (result.isConfirmed) {
			}
		});
	}
	doBeforeUnload() {
		if (environment.checkSession) return false;
	}

	doUnload() {
		if (environment.checkSession) sessionStorage.clear();
	}
}
