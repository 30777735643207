<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
<swal #notesSwal title="Operación" text="Operación realizada" icon="info"></swal>

<!-- <h1>{{fileIdValue ? fileIdValue: 'Sin id por ruta'}}</h1> -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"> </ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal" [config]="configLocal.barraBusqueda" (busqueda)="searchElementsByFilter($event)" [registroActivacion]="true" [filtroEmisor]="true" [mostrarListaCompletaEmisores]="isSuperAdmin" [listaCompletaEmisores]="emisores" [useAccordionMode]="true" [useInlineSearchButton]="true" [useInlineDeactivatedCheckbox]="true" [filtroSucursal]="true"></app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">{{ configLocal.table.title }}</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" *ngIf="permissions.getPermission('30-40-12').asignado" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
								</div>

								<div class="heading-elements mt-0">
									<button class="btn btn-success float-right mr-5 mt-5" (click)="newUserModal(addUserModal)" *ngIf="permissions.getPermission('30-40-10').asignado">
										<i class="flaticon-add-circular-button"></i>
										Agregar Usuario
									</button>
								</div>
							</div>
							<!-- <div class="col-1" >
                              <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change) ="setPageSize()">
                                 <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                              </ng-select>
                         </div> -->
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="80"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column name="rut" [flexGrow]="2" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Rut</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.rutFormateado
														? row.rutFormateado
														: "No
                                       disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="nombre" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.nombre }} {{ row.apellidos }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="DirFact" [flexGrow]="3" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Direccion</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{
													row.direccion && row.comuna
														? row.direccion + ", " + row.comuna
														: row.direccion && !row.comuna
														? row.direccion
														: "No
                                       disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Ciudad" [flexGrow]="3.2" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Email</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment" [ngbTooltip]="row.email">{{ row.email ? row.email : "No disponible" }}</span></ng-template
											>
										</ngx-datatable-column>
										<!-- <ngx-datatable-column name="Comuna" [flexGrow]="1.8" [sortable]="false" [resizeable]="false">
                                 <ng-template let-column="column" ngx-datatable-header-template>
                                    <span class="text-table-alignment">Comuna</span>
                                 </ng-template>
                                 <ng-template ngx-datatable-cell-template let-row="row"><span class="text-table-alignment">{{ row.comuna ? row.comuna : "No disponible" }}</span></ng-template>
                              </ngx-datatable-column> -->
										<ngx-datatable-column name="Correo" [flexGrow]="2.0" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Telefono</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.telefono ? row.telefono : "No disponible" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="3.5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Detalles Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Detalles_Usuario" (click)="detailModal(detailUserModal, row)" *ngIf="permissions.getPermission('30-40-14').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Editar Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Editar_Usuario" (click)="editModal(editUserModal, row)" *ngIf="permissions.getPermission('30-40-16').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Perfiles" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Perfiles" (click)="userProfilesModal(ProfilesModal, row)" *ngIf="permissions.getPermission('30-40-18').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="id-badge" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Desbloquear Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Desbloquear_Usuario" *ngIf="(isSuperAdmin || permissions.getPermission('30-40-30').asignado) && row.bloqueado">
													<div
														class="icon-circle bg-success text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea desbloquear y enviar un correo con la contraseña temporal al usuario?',
															confirmButtonText: 'Sí, desbloquear',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="unlockUser(row)">
														<fa-icon icon="unlock" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<!-- <a [routerLink]="" ngbTooltip="Desbloquear Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Desbloquear_Usuario"
                                       (click)="unlockUser( row)"
                                       *ngIf="isSuperAdmin && permissions.getPermission('30-40-30').asignado && row.bloqueado">
                                       <div class="icon-circle bg-success text-center align-middle pt-info-button">
                                          <fa-icon icon="unlock" class="fa-lg text-white"></fa-icon>
                                       </div>
                                    </a> -->
												<a [routerLink]="" ngbTooltip="Sucursales" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Sucursales" (click)="userSucursalesModal(SucursalesModal, row)" *ngIf="permissions.getPermission('30-40-20').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="store" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Desactivar Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Desactivar_Usuario" *ngIf="row.activo && permissions.getPermission('30-40-26').asignado">
													<div
														class="icon-circle bg-danger text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea desactivar este usuario?',
															confirmButtonText: 'Sí, desactivar',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="deactivateUser(row)">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Activar Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Activar_Usuario" *ngIf="!row.activo && permissions.getPermission('30-40-26').asignado">
													<div
														class="icon-circle bg-success text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea reactivar este usuario?',
															confirmButtonText: 'Sí, reactivar',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="activateUser(row)">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">
													Rut:
													{{
														row.rutFormateado
															? row.rutFormateado
															: "No
                                       disponible"
													}}
													, Dirección: {{ row.direccion ? row.direccion : "No disponible" }} , Email: {{ row.email ? row.email : "No disponible" }} , Comuna: {{ row.comuna ? row.comuna : "No disponible" }}, Telefono: {{ row.telefono ? row.telefono : "No disponible" }}
												</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="5.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.nombre }} {{ row.apellidos }}</span></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="5.5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Detalles Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Detalles_Usuario" (click)="detailModal(detailUserModal, row)" *ngIf="permissions.getPermission('30-40-14').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Editar Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Editar_Usuario" (click)="editModal(editUserModal, row)" *ngIf="permissions.getPermission('30-40-16').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Perfiles" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Perfiles" (click)="userProfilesModal(ProfilesModal, row)" *ngIf="permissions.getPermission('30-40-18').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="id-badge" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Sucursales" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Sucursales" (click)="userSucursalesModal(SucursalesModal, row)" *ngIf="permissions.getPermission('30-40-20').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="store" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Desactivar Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Desactivar_Usuario" (click)="deactivateUser(row)" *ngIf="row.activo && permissions.getPermission('30-40-26').asignado">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Activar Usuario" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Activar_Usuario" (click)="activateUser(row)" *ngIf="!row.activo && permissions.getPermission('30-40-26').asignado">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #detailUserModal let-d="dismiss" id="detail-modal">
	<div class="modal-content">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Detalles del Usuario {{ userToShow.nombre }}</div>
			</div>
		</div>
		<div class="modal-body">
			<div class="row form-group mx-auto" id="details-client">
				<div class="col-12">
					<div class="row">
						<div class="col-4">
							<span class="d-block">Rut</span>
							<span class="d-block">Nombre</span>
							<span class="d-block">Apellidos</span>
							<span class="d-block">Telefono</span>
							<span class="d-block">Comuna</span>
							<span class="d-block">Perfil</span>
							<!-- <span class="d-block">Dirección Particular</span> -->
							<span class="d-block">Dirección</span>
							<span class="d-block">Email</span>
						</div>
						<div class="col-8">
							<span class="d-block text-right">{{
								userToShow.rutFormateado
									? userToShow.rutFormateado
									: "No
                        disponible"
							}}</span>
							<span class="d-block text-right">{{ userToShow.nombre ? userToShow.nombre : "No disponible" }}</span>
							<span class="d-block text-right">{{ userToShow.apellidos ? userToShow.apellidos : "No disponible" }}</span>
							<span class="d-block text-right">{{ userToShow.telefono ? userToShow.telefono : "No disponible" }}</span>
							<span class="d-block text-right">{{ userToShow.comuna ? userToShow.comuna : "No disponible" }}</span>
							<span class="d-block text-right">{{
								userToShow.perfilUsuario
									? userToShow.perfilUsuario
									: "No
                        disponible"
							}}</span>
							<span class="d-block text-right">{{ userToShow.direccion ? userToShow.direccion : "No disponible" }}</span>
							<span class="d-block text-right">{{ userToShow.email ? userToShow.email : "No disponible" }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #editUserModal let-d="dismiss">
	<form (ngSubmit)="editUser(addForm)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Actualizar Usuario {{ userToEdit.nombre }}</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">RUT :</label>
					<div class="col-4">
						{{ userToEdit.rutFormateado }}
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Nombre :</label>
					<div class="col-4">
						<input
							type="text"
							name="newNombre"
							placeholder="Ingresa el nombre del usuario"
							class="form-control"
							#nombreUsuario="ngModel"
							[(ngModel)]="userToEdit.nombre"
							[ngClass]="{
								'is-invalid': addForm.submitted && nombreUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && nombreUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="nombreUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Apellidos :</label>
					<div class="col-4">
						<input
							type="text"
							name="newApellidos"
							placeholder="Ingresa los apellidos del usuario"
							class="form-control"
							#apellidosUsuario="ngModel"
							[(ngModel)]="userToEdit.apellidos"
							[ngClass]="{
								'is-invalid': addForm.submitted && apellidosUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && apellidosUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="apellidosUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>

				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Comuna: </label>
					<div class="col-4">
						<input
							type="text"
							name="newComuna"
							placeholder="Ingresa la comuna del usuario"
							class="form-control"
							#comunaUsuario="ngModel"
							[(ngModel)]="userToAdd.comuna"
							[ngClass]="{
								'is-invalid': addForm.submitted && comunaUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && comunaUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="comunaUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Dirección :</label>
					<div class="col-4">
						<input
							type="text"
							name="newDireccion"
							placeholder="Ingresa la dirección del usuario"
							class="form-control"
							#direccionUsuario="ngModel"
							[(ngModel)]="userToEdit.direccion"
							[ngClass]="{
								'is-invalid': addForm.submitted && direccionUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && direccionUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="direccionUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Teléfono :</label>
					<div class="col-4">
						<div class="input-group">
							<div class="input-group-append">
								<span class="input-group-text">+56</span>
							</div>
							<input
								type="number"
								name="newTelefono"
								oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
								maxlength="10"
								min="1"
								placeholder="962212767"
								class="form-control"
								#telefonoUsuario="ngModel"
								[(ngModel)]="userToEdit.telefono"
								[ngClass]="{
									'is-invalid': addForm.submitted && telefonoUsuario.invalid
								}"
								required />
						</div>
						<span class="form-text text-muted">Cod. Pais + Telefono</span>
						<div *ngIf="addForm.submitted && telefonoUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="telefonoUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">WhatsApp :</label>
					<div class="col-4">
						<div class="input-group">
							<div class="input-group-append">
								<span class="input-group-text">+56</span>
							</div>
							<input
								type="number"
								name="newWhatsapp"
								oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
								maxlength="10"
								min="1"
								placeholder="962212767"
								class="form-control"
								#whatsappUsuario="ngModel"
								[(ngModel)]="userToEdit.whatsapp"
								[ngClass]="{
									'is-invalid': addForm.submitted && whatsappUsuario.invalid
								}" />
						</div>

						<span class="form-text text-muted">Aquí se enviarán alertas y notificaciones</span>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Cargo :</label>
					<div class="col-4">
						<input
							type="text"
							name="newCargo"
							placeholder="Ingresa el cargo del usuario"
							class="form-control"
							#cargoUsuario="ngModel"
							[(ngModel)]="userToEdit.cargo"
							[ngClass]="{
								'is-invalid': addForm.submitted && cargoUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && cargoUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="cargoUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Email :</label>
					<div class="col-10">
						<input
							type="email"
							name="newEmail"
							placeholder="Ingresa el email del usuario"
							class="form-control"
							#correoUsuario="ngModel"
							[(ngModel)]="userToEdit.email"
							[ngClass]="{
								'is-invalid': addForm.submitted && correoUsuario.invalid
							}"
							required />
						<span class="form-text text-muted">Aquí se enviarán alertas y notificaciones</span>
						<div *ngIf="addForm.submitted && correoUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="correoUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="addForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #PermissionsModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Permisos del usuario {{ userToEdit.nombre }}</h5>
		</div>
		<div class="modal-body">
			<h4>Permisos Asignados</h4>
			<form #ViewForm="ngForm">
				<div class="modal-body">
					<ul>
						<li *ngFor="let item of userToEdit.permisos"><input type="checkbox" name="item.nombre" value="{{ item.nombre }}" [checked]="item.asignado" /> {{ item.nombre }}</li>
					</ul>
				</div>
				<div class="modal-footer">
					<alert id="alert-errormodal"></alert>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>

<ng-template #ProfilesModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Perfiles del usuario {{ userToEdit.nombre }}</h5>

			<button class="btn btn-warning float-right mr-5 mt-5" (click)="findProfileModal(FindProfileModal)" *ngIf="permissions.getPermission('30-40-18-10').asignado">
				<i class="flaticon-search"></i>
				Asignar Perfil
			</button>
		</div>
		<div class="heading-elements mt-0"></div>
		<div class="modal-body">
			<h4>Perfiles Asignados</h4>

			<form (ngSubmit)="editProfiles(EditForm)" #EditForm="ngForm">
				<div class="modal-body">
					<div class="form-group row mx-auto" style="align-items: baseline">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of userToEdit.perfiles">
									<td>
										{{ item.nombre }}
									</td>
									<td>
										<!-- <a [routerLink]="" ngbTooltip="Editar Permisos" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Editar_Permisos" (click)="permissionList(item)" *ngIf="permissions.getPermission('30-40-18-12').asignado">
											<div class="icon-circle bg-primary text-center align-middle pt-info-button">
												<fa-icon icon="id-card" class="fa-lg text-white"></fa-icon>
											</div>
										</a> -->
										<a [routerLink]="" ngbTooltip="Quitar Perfil" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Quitar_Perfil" (click)="deleteUserProfile(item)" *ngIf="permissions.getPermission('30-40-18-14').asignado">
											<div class="icon-circle bg-danger text-center align-middle pt-info-button">
												<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
											</div>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<alert id="alert-errormodal"></alert>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
							<i class="la la-paper-plane-o d-lg-none"></i>
							<span class="d-none d-lg-block">Guardar</span>
						</button>
					</div>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>

<ng-template #FindProfileModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Selecciona el Perfil para el Usuario {{ userToEdit.nombre }}</h5>
		</div>
		<div class="modal-body">
			<div class="heading-elements mt-0">
				<app-search-bar [criterioPrincipal]="configLocalProfile.barraBusqueda.criterioPrincipal" [config]="configLocalProfile.barraBusqueda" (busqueda)="searchElementsByProfileFilter($event)" [insideModal]="true"> ></app-search-bar>
				<ngx-datatable #table class="material" [rows]="rowsMantenedorProfile" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [externalPaging]="true" [count]="pageProfile.totalElements" [offset]="pageProfile.pageNumber" [limit]="pageProfile.pageSize" (page)="setPageProfile($event)" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
					<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>

					<ngx-datatable-column name="Nombre" [flexGrow]="3" [sortable]="false" [resizeable]="false">
						<ng-template let-column="column" ngx-datatable-header-template>
							<span class="text-table-alignment">Nombre</span>
						</ng-template>
						<ng-template ngx-datatable-cell-template let-row="row"
							><span class="text-table-alignment">{{ row.nombre }} {{ row.apellidos }}</span></ng-template
						>
					</ngx-datatable-column>

					<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
						<ng-template ngx-datatable-cell-template let-row="row">
							<a [routerLink]="" ngbTooltip="Asignar" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Asignar" (click)="addProfiletoUser(row)">
								<div class="icon-circle bg-success text-center align-middle pt-info-button">
									<fa-icon icon="plus-circle" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
				<div class="modal-footer">
					<alert id="alert-errormodal"></alert>

					<div class="form-group position-relative has-icon-left mb-0">
						<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #SucursalesModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Sucursales del usuario {{ userToEdit.nombre }}</h5>

			<button class="btn btn-warning float-right mr-5 mt-5" (click)="findSucursalModal(FindSucursalModal)" *ngIf="permissions.getPermission('30-40-22').asignado">
				<i class="flaticon-search"></i>
				Asignar Sucursal
			</button>
		</div>
		<div class="heading-elements mt-0"></div>
		<div class="modal-body">
			<h4>Sucursales Asignadas</h4>

			<form (ngSubmit)="editSucursales(EditForm)" #EditForm="ngForm">
				<div class="modal-body">
					<div class="form-group row mx-auto" style="align-items: baseline">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Emisor</th>
									<th>Dirección</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of userToEdit.sucursales">
									<td>
										{{ item.nombre }}
									</td>
									<td>
										{{ item.emisorNombre }}
									</td>
									<td>
										{{ item.direccion }}
									</td>
									<td>
										<a [routerLink]="" ngbTooltip="Quitar Sucursal" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Quitar_Sucursal" (click)="deleteUserSucursal(item)" *ngIf="permissions.getPermission('30-40-24').asignado">
											<div class="icon-circle bg-danger text-center align-middle pt-info-button">
												<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
											</div>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
					<alert id="alert-errormodal"></alert>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
							<i class="la la-paper-plane-o d-lg-none"></i>
							<span class="d-none d-lg-block">Guardar</span>
						</button>
					</div>
					<div class="form-group position-relative has-icon-left mb-0">
						<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-template>

<ng-template #FindSucursalModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Selecciona una Sucursal para el Usuario {{ userToEdit.nombre }}</h5>
		</div>
		<div class="modal-body">
			<div class="heading-elements mt-0">
				<app-search-bar [criterioPrincipal]="configLocalSucursal.barraBusqueda.criterioPrincipal" [config]="configLocalSucursal.barraBusqueda" (busqueda)="searchElementsBySucursalFilter($event)" [insideModal]="true" [filtroEmisor]="true" [mostrarListaCompletaEmisores]="true" [listaCompletaEmisores]="emisores" [emisorBusquedaDefault]="this.filterSucursal.emisorId">
					<!-- permissions.getPermission('30-40-28').asignado -->
					>
				</app-search-bar>
				<ngx-datatable #table class="material" [rows]="rowsMantenedorSucursal" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [externalPaging]="true" [count]="pageSucursal.totalElements" [offset]="pageSucursal.pageNumber" [limit]="pageSucursal.pageSize" (page)="setPageSucursal($event)" [messages]="{ emptyMessage: 'Sin datos' | translate, totalMessage: 'Elementos' | translate, selectedMessage: false }">
					<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>

					<ngx-datatable-column name="Nombre" [flexGrow]="3" [sortable]="false" [resizeable]="false">
						<ng-template let-column="column" ngx-datatable-header-template>
							<span class="text-table-alignment">Nombre</span>
						</ng-template>
						<ng-template ngx-datatable-cell-template let-row="row"
							><span class="text-table-alignment">{{ row.nombre }} </span></ng-template
						>
					</ngx-datatable-column>
					<ngx-datatable-column name="emisorNombre" [flexGrow]="3" [sortable]="false" [resizeable]="false">
						<ng-template let-column="column" ngx-datatable-header-template>
							<span class="text-table-alignment">Emisor</span>
						</ng-template>
						<ng-template ngx-datatable-cell-template let-row="row"
							><span class="text-table-alignment">{{ row.emisorNombre }} </span></ng-template
						>
					</ngx-datatable-column>
					<ngx-datatable-column name="Direccion" [flexGrow]="4" [sortable]="false" [resizeable]="false">
						<ng-template let-column="column" ngx-datatable-header-template>
							<span class="text-table-alignment">Dirección</span>
						</ng-template>
						<ng-template ngx-datatable-cell-template let-row="row"
							><span class="text-table-alignment">{{ row.direccion }} </span></ng-template
						>
					</ngx-datatable-column>

					<ngx-datatable-column name="Acciones" [flexGrow]="1" [sortable]="false" [resizeable]="false">
						<ng-template ngx-datatable-cell-template let-row="row">
							<a [routerLink]="" ngbTooltip="Asignar Sucursal" class="primary edit mr-1 mrless" id="mantenedor-usuarios.component_Asignar_Sucursal" (click)="addSucursalToUser(row)">
								<div class="icon-circle bg-success text-center align-middle pt-info-button">
									<fa-icon icon="plus-circle" class="fa-lg text-white"></fa-icon>
								</div>
							</a>
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>
				<div class="modal-footer">
					<alert id="alert-errormodal"></alert>

					<div class="form-group position-relative has-icon-left mb-0">
						<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #addUserModal let-d="dismiss">
	<form (ngSubmit)="addUser(addForm, SucursalesModal)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Agregar Usuario</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">RUT :</label>
					<div class="col-4">
						<input
							type="text"
							name="rut"
							placeholder="Ingresa el rut del usuario"
							class="form-control"
							#rutUsuario="ngModel"
							[(ngModel)]="userToAdd.rutFormateado"
							[ngClass]="{
								'is-invalid': addForm.submitted && rutUsuario.invalid
							}"
							maxlength="10"
							appVerificaRut
							required />
						<div *ngIf="addForm.submitted && rutUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="rutUsuario.errors.required">Campo obligatorio</div>
						</div>
						<div *ngIf="addForm.submitted && rutUsuario.errors?.rutNoValido" class="invalid-feedback-group">Rut Invalido</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Nombre :</label>
					<div class="col-4">
						<input
							type="text"
							name="newNombre"
							placeholder="Ingresa el nombre del usuario"
							class="form-control"
							#nombreUsuario="ngModel"
							[(ngModel)]="userToAdd.nombre"
							[ngClass]="{
								'is-invalid': addForm.submitted && nombreUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && nombreUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="nombreUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Apellidos :</label>
					<div class="col-4">
						<input
							type="text"
							name="newApellidos"
							placeholder="Ingresa los apellidos del usuario"
							class="form-control"
							#apellidosUsuario="ngModel"
							[(ngModel)]="userToAdd.apellidos"
							[ngClass]="{
								'is-invalid': addForm.submitted && apellidosUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && apellidosUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="apellidosUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Comuna: </label>
					<div class="col-4">
						<input
							type="text"
							name="newComuna"
							placeholder="Ingresa la comuna del usuario"
							class="form-control"
							#comunaUsuario="ngModel"
							[(ngModel)]="userToAdd.comuna"
							[ngClass]="{
								'is-invalid': addForm.submitted && comunaUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && comunaUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="comunaUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Dirección :</label>
					<div class="col-4">
						<input
							type="text"
							name="newDireccion"
							placeholder="Ingresa la dirección del usuario"
							class="form-control"
							#direccionUsuario="ngModel"
							[(ngModel)]="userToAdd.direccion"
							[ngClass]="{
								'is-invalid': addForm.submitted && direccionUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && direccionUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="direccionUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>

				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Teléfono :</label>
					<div class="col-4">
						<div class="input-group">
							<div class="input-group-append">
								<span class="input-group-text">+56</span>
							</div>
							<input
								type="number"
								name="newTelefono"
								oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
								maxlength="10"
								min="1"
								placeholder="962212767"
								class="form-control"
								#telefonoUsuario="ngModel"
								[(ngModel)]="userToAdd.telefono"
								[ngClass]="{
									'is-invalid': addForm.submitted && telefonoUsuario.invalid
								}"
								required />
						</div>
						<span class="form-text text-muted">Cod. Pais + Telefono</span>
						<div *ngIf="addForm.submitted && telefonoUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="telefonoUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">WhatsApp :</label>
					<div class="col-4">
						<div class="input-group">
							<div class="input-group-append">
								<span class="input-group-text">+56</span>
							</div>
							<input
								type="number"
								name="newWhatsapp"
								oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
								maxlength="10"
								min="1"
								placeholder="962212767"
								class="form-control"
								#whatsappUsuario="ngModel"
								[(ngModel)]="userToAdd.whatsapp"
								[ngClass]="{
									'is-invalid': addForm.submitted && whatsappUsuario.invalid
								}" />
						</div>

						<span class="form-text text-muted">Aquí se enviarán alertas y notificaciones</span>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Cargo :</label>
					<div class="col-4">
						<input
							type="text"
							name="newCargo"
							placeholder="Ingresa el cargo del usuario"
							class="form-control"
							#cargoUsuario="ngModel"
							[(ngModel)]="userToAdd.cargo"
							[ngClass]="{
								'is-invalid': addForm.submitted && cargoUsuario.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && cargoUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="cargoUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
					<label class="col-2 col-form-label text-lg-right">Perfil :</label>
					<div class="col-4">
						<ng-select
							[items]="perfilesDelEmisor"
							[(ngModel)]="userToAdd.perfilInicial"
							placeholder="Seleccione un perfíl para el usuario"
							[clearable]="false"
							bindLabel="nombre"
							name="perfilInicial"
							#perfilInicial="ngModel"
							required
							[ngClass]="{
								'is-invalid': addForm.submitted && perfilInicial.invalid
							}"></ng-select>
						<div *ngIf="addForm.submitted && perfilInicial.invalid" class="invalid-feedback-group">
							<div *ngIf="perfilInicial.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-2 col-form-label text-lg-right" for="projectinput1">Email :</label>
					<div class="col-10">
						<input
							type="email"
							name="newEmail"
							placeholder="Ingresa el email del usuario"
							class="form-control"
							#correoUsuario="ngModel"
							[(ngModel)]="userToAdd.email"
							[ngClass]="{
								'is-invalid': addForm.submitted && correoUsuario.invalid
							}"
							required />
						<span class="form-text text-muted">Aquí se enviarán alertas y notificaciones</span>
						<div *ngIf="addForm.submitted && correoUsuario.invalid" class="invalid-feedback-group">
							<div *ngIf="correoUsuario.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>

				<div class="form-group row mx-auto" style="align-items: baseline"></div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
