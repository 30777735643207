import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../_models/user.model";
import { AuthModel } from "../_models/auth.model";
import { AuthHTTPService } from "./auth-http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AuthenticatedResponse } from "src/app/shared/models/AuthenticatedResponse";
import { Identifiers } from "src/app/shared/models/Identifiers";
import { AuthenticationRequest } from "src/app/shared/models/AuthenticationRequest";
import { AuthConfigService } from "./auth-config.service";
import { HttpClient } from "@angular/common/http";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { Response } from "src/app/shared/models/Response";
import { MsalUserProfile } from "src/app/shared/models/MsalUserProfile";
import { MsalCredentials } from "src/app/shared/models/MsalCredentials";
import { PermissionService } from "src/app/services/permission.service";
@Injectable({
	providedIn: "root",
})
export class AuthService implements OnDestroy {
	// private fields
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

	// public fields
	currentUser$: Observable<UserModel>;
	isLoading$: Observable<boolean>;
	currentUserSubject: BehaviorSubject<UserModel>;
	isLoadingSubject: BehaviorSubject<boolean>;
	isLoadingRecoverPassword$: Observable<boolean>;
	isLoadingRecoverPasswordSubject: BehaviorSubject<boolean>;

	get currentUserValue(): UserModel {
		return this.currentUserSubject.value;
	}

	set currentUserValue(user: UserModel) {
		this.currentUserSubject.next(user);
	}

	constructor(private authHttpService: AuthHTTPService, private config: AuthConfigService, private tokenStorage: TokenStorageService, private http: HttpClient, private router: Router, private permissionStorage: PermissionService) {
		this.isLoadingSubject = new BehaviorSubject<boolean>(false);
		this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
		this.currentUser$ = this.currentUserSubject.asObservable();
		this.isLoading$ = this.isLoadingSubject.asObservable();
		this.isLoadingRecoverPasswordSubject = new BehaviorSubject<boolean>(false);
		this.isLoadingRecoverPassword$ = this.isLoadingRecoverPasswordSubject.asObservable();
		//const subscr = this.getUserByToken().subscribe();
		//this.unsubscribe.push(subscr);
	}

	tokenLogin(email: string, password: string): Observable<AuthenticatedResponse> {
		this.isLoadingSubject.next(true);
		let identifier: Identifiers = new Identifiers();
		identifier.email = email;
		let loginRequest: AuthenticationRequest = new AuthenticationRequest(identifier, password);
		return this.http.post<AuthenticatedResponse>(this.config.wepApiRoute + this.config.loginEndpoint, loginRequest).pipe(finalize(() => this.isLoadingSubject.next(false)));
	}

	activeDirectoryLogin(userProfile: MsalUserProfile, email: string, token: string): Observable<AuthenticatedResponse> {
		let credentials = new MsalCredentials();
		credentials.email = email;
		credentials.token = token;
		credentials.name = userProfile.givenName;
		credentials.surname = userProfile.surname;
		credentials.displayName = userProfile.displayName; // example: "EIG  Atacama Management SpA"
		return this.http.post<AuthenticatedResponse>(this.config.wepApiRoute + this.config.activeDirectoryEndpoint, credentials); //ruta nueva
		//return this.http.post<AuthenticatedResponse>(this.config.wepApiRoute + this.config.activeDirectoryEndpoint+"?email="+email+"&token="+token,null)
	}

	tokenList(): Observable<any> {
		return this.http.get<any>(this.config.wepApiRoute + "/authentication/list");
	}

	recoverPassword(email: string, rut: string): Observable<Response<any>> {
		this.isLoadingRecoverPasswordSubject.next(true);
		return this.http.post<any>(this.config.wepApiRoute + this.config.recoverPassEndpoint + email + "&rut=" + rut, null).pipe(finalize(() => this.isLoadingRecoverPasswordSubject.next(false)));
	}
	changePass(usuarioId, currentPass, newPass): Observable<any> {
		let formData: FormData = new FormData();
		formData.append("usuarioId", usuarioId);
		formData.append("currentPass", currentPass);
		formData.append("newPass", newPass);
		return this.http.post<any>(this.config.wepApiRoute + "/authentication/changePassword", formData);
	}
	changeTempPassword(usuarioId: string, newPassword: string, tempPassword: string): Observable<Response<string>> {
		let formData: FormData = new FormData();
		formData.append("usuarioId", usuarioId);
		formData.append("nuevoPassword", newPassword);
		formData.append("passwordTemporal", tempPassword);
		this.isLoadingSubject.next(true);
		return this.http.post<Response<string>>(this.config.wepApiRoute + this.config.changePasswordEndpoint, formData).pipe(finalize(() => this.isLoadingSubject.next(false)));
	}

	blockAccount(email: string, ipAddress): Observable<any> {
		let formData: FormData = new FormData();
		formData.append("email", email);
		formData.append("ipAddress", ipAddress);
		return this.http.post<any>(this.config.wepApiRoute + this.config.blockAccountEndpoint, formData);
	}

	getIPAddress() {
		return this.http.get("http://api.ipify.org/?format=json");
	}

	logout() {
		this.tokenStorage.signOut();
		this.permissionStorage.signOut();
		this.router.navigate(["/auth/login"], {
			queryParams: {},
		});
	}

	getUserByToken(): Observable<UserModel> {
		const auth = this.getAuthFromLocalStorage();
		if (!auth || !auth.authToken) {
			return of(undefined);
		}

		this.isLoadingSubject.next(true);
		return this.authHttpService.getUserByToken(auth.authToken).pipe(
			map((user: UserModel) => {
				if (user) {
					this.currentUserSubject = new BehaviorSubject<UserModel>(user);
				} else {
					this.logout();
				}
				return user;
			}),
			finalize(() => this.isLoadingSubject.next(false))
		);
	}

	forgotPassword(email: string): Observable<boolean> {
		this.isLoadingSubject.next(true);
		return this.http.post<any>(this.config.wepApiRoute + this.config.recoverPassEndpoint + email, null).pipe(finalize(() => this.isLoadingSubject.next(false)));
	}

	private getAuthFromLocalStorage(): AuthModel {
		try {
			const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
			return authData;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}
}
