import { Directive } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FormrulesConfigService } from "../shared/configuration/formrules-config.service";

@Directive({
   selector: "[appMultiEmail]",
})
export class MultiEmailDirective {
   constructor(private formConfig: FormrulesConfigService) {}
}
export function MultipleEmailValidator(control: AbstractControl): { [key: string]: any } | null {
   if (control.value) {
      const emails = control.value.split(",").map((e) => e.trim());
      const forbidden = emails.some((email) => !new RegExp(/[a-zA-Z0-9.!_~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/).test(email));
      return forbidden ? { toAddress: { value: control.value } } : null;
   } else {
      return;
   }
}
export function SingleEmailValidator(control: AbstractControl): { [key: string]: any } | null {
   if (control.value) {
      const forbidden = !new RegExp(/^[A-Za-z0-9.!_~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/).test(control.value);
      return forbidden ? { wrongEmail: { value: control.value } } : null;
   } else {
      return;
   }
}
