export const ConfigMantenedorLogs = {
	barraBusqueda: {
		criterios: ["Mensaje", "Resumen"],
		criteriosKeys: ["mensaje", "titulo"],
		criterioPrincipal: "Mensaje",
		datePicker: true,
		nombreCampoDatePicker: "Fecha Creación (Desde - Hasta)",
	},
	table: {
		title: "Registro de Transacciones",
	},
};
