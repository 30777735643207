import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenStorageService } from "../services/token-storage.service";
import { tap } from "rxjs/operators";

const TOKEN_HEADER_KEY = "Authorization";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   constructor(private tokenStorage: TokenStorageService) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      let authReq = request;
      const token = this.tokenStorage.getToken();
      if (token) {
         authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, "Bearer " + token) });
      }
      return next.handle(authReq).pipe(
         tap(
            () => {},
            (error: any) => {}
         )
      );
   }
}

export const authIntercetorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
