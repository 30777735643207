export class PersonaAutorizada {
	personaAutorizadaCesionId: string;
	rut: number;
	dv: string;
	nombre: string;
	correo: string;
	emisorId: string;
	activo: boolean;
	rutFormateado;
	tipoPersona: number;
}
