<!--begin::Subheader-->
<div class="subheader py-2 py-lg-6" [ngClass]="subheaderCSSClasses" id="kt_subheader">
	<div [ngClass]="subheaderContainerCSSClasses" class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
		<!--begin::Info-->
		<div class="d-flex align-items-center flex-wrap mr-1">
			<ng-container *ngIf="subheaderMobileToggle">
				<!--begin::Mobile Toggle-->
				<button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
					<span></span>
				</button>
				<!--end::Mobile Toggle-->
			</ng-container>

			<!--begin::Page Heading-->
			<div class="d-flex align-items-baseline flex-wrap mr-5">
				<!--begin::Page Title-->
				<ng-container *ngIf="title$ | async as _title">
					<h5 class="text-dark font-weight-bold my-1 mr-5">
						{{ _title.length > 50 ? (_title | slice : 0 : 50) + "..." : _title }}
					</h5>
				</ng-container>
				<!--end::Page Title-->

				<!--begin::Breadcrumb-->
				<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
					<li class="breadcrumb-item" *ngFor="let bc of breadcrumbs" routerLinkActive="active">
						<!-- <a [routerLink]="bc.linkPath" class="text-muted">
              {{ bc.linkText }}
            </a> -->
						<a [routerLink]="" class="text-muted" style="color: #b5b5c3 !important">
							{{ bc.linkText }}
						</a>
					</li>
				</ul>
				<!--end::Breadcrumb-->

				<!-- factura guias -->
				<div class="" *ngIf="showFactGuiasButton && showFacturacionButtonGroup">
					<div class="sub-container-factura-guias">
						<button class="btn btn-warning mr-5" (click)="sendFacturaGuiasAction()" [disabled]="disableButton">Facturar Guías</button>
					</div>
				</div>
				<div *ngIf="showFacturacionButtonGroup" style="align-self: start" class="">
					<ng-container *ngIf="!(loadingFoliosSummary$ | async)">
						<span
							style="cursor: default"
							ngbToolTip="Folios disponibles"
							*ngIf="nombreTipoDteDisponible != ''"
							class="btn text-light col-auto badge-pill"
							[ngClass]="{
								'badge-danger': cantidadTipoDteDisponible == 0,
								'badge-primary': cantidadTipoDteDisponible != 0
							}"
							>Folios {{ nombreTipoDteDisponible }}: {{ cantidadTipoDteDisponible }}</span
						>
						<ng-container *ngIf="nombreTipoDteDisponible != '' && cantidadTipoDteDisponible == 0">
							<span class="pulse pulse-ring border-danger"></span>
						</ng-container>

						<span style="cursor: default" ngbToolTip="Folios no disponibles" *ngIf="(currentTipoCantidad && nombreTipoDteDisponible == '' && cantidadTipoDteDisponible == 0) || !currentTipoCantidad" class="btn text-light col-auto badge-pill badge-danger">Sin folios</span>
					</ng-container>

					<ng-container *ngIf="loadingFoliosSummary$ | async">
						<div class="row">
							<div class="col-1">
								<span class="spinner spinner-primary spinner-lg"></span>
							</div>
						</div>
					</ng-container>
				</div>

				<!-- end factura guias -->
			</div>
			<!--end::Page Heading-->
		</div>
		<!--end::Info-->

		<!--begin::FOLIOS-->
		<!-- <div class="mr-0" *ngIf="showFacturacionButtonGroup">
         <div class="row justify-content-center pulse">
            <ng-container *ngIf="!(loadingFoliosSummary$ | async)">
               <span
                  style="cursor: default"
                  ngbToolTip="Folios disponibles"
                  *ngIf="nombreTipoDteDisponible != ''"
                  class="btn text-light col-auto badge-pill"
                  [ngClass]="{
                     'badge-danger': cantidadTipoDteDisponible == 0,
                     'badge-primary': cantidadTipoDteDisponible != 0
                  }"
                  >Folios {{ nombreTipoDteDisponible }}: {{ cantidadTipoDteDisponible }}</span
               >
               <ng-container *ngIf="nombreTipoDteDisponible != '' && cantidadTipoDteDisponible == 0">
                  <span class="pulse pulse-ring border-danger"></span>
               </ng-container>

               <span style="cursor: default" ngbToolTip="Folios no disponibles" *ngIf="(currentTipoCantidad && nombreTipoDteDisponible == '' && cantidadTipoDteDisponible == 0) || !currentTipoCantidad" class="btn text-light col-auto badge-pill badge-danger">Sin folios</span>
            </ng-container>

            <ng-container *ngIf="loadingFoliosSummary$ | async">
               <div class="row">
                  <div class="col-1">
                     <span class="spinner spinner-primary spinner-lg"></span>
                  </div>
               </div>
            </ng-container>
         </div>
      </div> -->
		<!--end::FOLIOS-->
		<!--begin::Toolbar-->
		<div class="d-flex align-items-center">
			<div
				class="mt-5"
				[ngClass]="{
					'espacio-boton-soporte': showFacturacionButtonGroup || showBheButtonGroup
				}">

				<!-- <button class="btn btn-outline-success mb-5 mr-5">
               <i class="fa-regular fa-headset"></i>
               Contacto con Soporte
            </button> -->
			</div>
			<ng-container *ngIf="subheaderDisplayDaterangepicker">
				<!--begin::Daterange-->
				<a class="btn btn-light-primary btn-sm font-weight-bold mr-2 cursor-pointer" id="kt_dashboard_daterangepicker" data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
					<span class="opacity-60 font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
					<span class="font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
				</a>
				<!--end::Daterange-->
			</ng-container>
			<ng-container *ngIf="!subheaderDisplayDaterangepicker">
				<!--begin::Actions-->
				<!-- <a
          class="btn btn-light-primary font-weight-bolder btn-sm cursor-pointer"
        >
          Actions
        </a> -->
				<!--end::Actions-->
			</ng-container>
			<ng-container *ngIf="showFacturacionButtonGroup">
				<div class="mt-5">
					<button class="btn btn-success mb-5 mr-5" (click)="sendEmitAction()">
						<i class="flaticon-file-2"></i>
						Generar
					</button>
					<button class="btn btn-primary mb-5 mr-5" (click)="sendPreviewAction()">
						<i class="flaticon-search"></i>
						Previsualizar
					</button>
					<button class="btn btn-danger mb-5 mr-5" (click)="sendCancelAction()">Cancelar</button>
				</div>
			</ng-container>
		</div>
		<!--end::Toolbar-->
	</div>
</div>
<!--end::Subheader-->
