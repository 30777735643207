<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal" [config]="configLocal.barraBusqueda" [filtroDTE]="true" [useAccordionMode]="true" [mostrarListaCompletaEmisores]="true" [filtroEmisor]="true" [useInlineSearchButton]="true" [listaCompletaEmisores]="emisoresList" [useInlineDeactivatedCheckbox]="true" [filtroDTEProveedor]="true" (busqueda)="searchElementsByFilter($event)"> </app-search-bar>
				<div class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Plantillas de Impresión</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-success float-right mr-5 mt-5" (click)="openCreatePlantillaModal(createPlantillaModal)">
										<i class="flaticon-add-circular-button"></i>
										Ingresar Plantilla
									</button>
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
									<button class="btn btn-success float-right mr-5 mt-5" (click)="downloadLabelsPdf()">
										<i class="flaticon-download-1"></i>
										Descargar Pdf con Etiquetas
									</button>
								</div>
							</div>
							<!-- <div class="col-1" >
                                <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change) ="setPageSize()">
                                   <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                                </ng-select>
                           </div> -->
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column name="descripcion" [flexGrow]="5" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Descripción</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row">
												<span *ngIf="row.isDefault" class="inline-span badge badge-success ml-2">Defecto</span>
												<span class="inline-span text-table-alignment">{{ row.descripcion }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="nombreTipoDte" [flexGrow]="4" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo de Documento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoDte }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="tipoPapel" [flexGrow]="1" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Papel</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.tipoPapel }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="maxLineasDetalle" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Max Lineas</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.maxLineasDetalle }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="cantidadEmpresasAsociadas" [flexGrow]="1.3" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Empresas</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">
													<span
														class="badge badge-pill px-4"
														[ngClass]="{
															'badge-primary': row.cantidadEmpresasAsociadas && row.cantidadEmpresasAsociadas > 0,
															'badge-secondary': !row.cantidadEmpresasAsociadas || row.cantidadEmpresasAsociadas <= 0
														}">
														<b>{{ row.cantidadEmpresasAsociadas ? row.cantidadEmpresasAsociadas : 0 }}</b></span
													>
												</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Descarga de Plantilla" id="mantenedor-plantillas-impresion.component_Descarga_de_Plantilla" (click)="download(row)">
													<div class="icon-circle bg-warning text-center align-middle pt-file-button">
														<fa-icon icon="file-code" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Muestra de Plantilla" id="mantenedor-plantillas-impresion.component_Muestra_de_Plantilla" (click)="getSample(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="file-pdf" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Plantilla" id="mantenedor-plantillas-impresion.component_Editar_Plantilla" (click)="openEditPlantillaModal(editPlantillaModal, row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a class="primary edit mr-1 mrless" ngbTooltip="Asignar Plantilla" id="mantenedor-plantillas-impresion.component_Asignar_Plantilla" (click)="openAsignaPlantillaModal(asignaPlantillaModal, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="file-invoice-dollar" class="fa-lg text-white"> </fa-icon>
													</div>
												</a>

												<a
													class="primary edit mr-1 mrless"
													ngbTooltip="Eliminar Plantilla"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de eliminar esta plantilla?',
														confirmButtonText: 'Eliminar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deletePlantilla(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="85"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Tipo Documento: {{ row.nombreTipoDte }} , Papel: {{ row.tipoPapel }} , Max Lineas: {{ row.maxLineasDetalle }}</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="descripcion" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Descripción</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.descripcion }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="tipoDte" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo de Documento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.nombreTipoDte }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="3.3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Descarga de Plantilla" id="mantenedor-plantillas-impresion.component_Descarga_de_Plantilla" (click)="download(row)">
													<div class="icon-circle bg-warning text-center align-middle pt-file-button">
														<fa-icon icon="file-code" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Muestra de Plantilla" id="mantenedor-plantillas-impresion.component_Muestra_de_Plantilla" (click)="getSample(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="file-pdf" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Plantilla" id="mantenedor-plantillas-impresion.component_Editar_Plantilla" (click)="openEditPlantillaModal(editPlantillaModal, row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a class="primary edit mr-1 mrless" ngbTooltip="Asignar Plantilla" id="mantenedor-plantillas-impresion.component_Asignar_Plantilla" (click)="openAsignaPlantillaModal(asignaPlantillaModal, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="file-invoice-dollar" class="fa-lg text-white"> </fa-icon>
													</div>
												</a>

												<a
													class="primary edit mr-1 mrless"
													ngbTooltip="Eliminar Plantilla"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de eliminar esta plantilla?',
														confirmButtonText: 'Eliminar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deletePlantilla(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #createPlantillaModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Ingreso de Plantilla de Impresión</div>
				<div class="modal-wizard-desc">Ingrese los datos y plantilla html para crear una nueva plantilla de impresión</div>
			</div>
		</div>
		<div class="modal-body">
			<app-plantilla-entry [detailMode]="false" (sendCloseRequestEmitter)="closeCreatePlantillaModal()" (sendPlantillaRequest)="sendNewPlantilla($event)"></app-plantilla-entry>
		</div>
	</div>
</ng-template>
<ng-template #editPlantillaModal>
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Edición de Plantilla de Impresión</div>
				<div class="modal-wizard-desc">Ingrese los datos y plantilla html para modificar la plantilla de impresión seleccionada</div>
			</div>
		</div>
		<div class="modal-body">
			<app-plantilla-entry [detailMode]="false" [editMode]="true" [plantillaToEdit]="selectedPlantilla" (sendCloseRequestEmitter)="closeEditPlantillaModal()" (sendPlantillaRequest)="editPlantillaRequest($event)"></app-plantilla-entry>
		</div>
	</div>
</ng-template>

<ng-template #asignaPlantillaModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Asignación de Plantilla de Impresión</div>
				<div class="modal-wizard-desc">Seleccione un emisor para asignar la plantilla de impresión actual</div>
			</div>
		</div>
		<div class="modal-body">
			<app-asignador-plantillas [plantillaSelected]="selectedPlantilla" (sendCloseRequestEmitter)="closeAsignaPlantillaModal()"></app-asignador-plantillas>
		</div>
	</div>
</ng-template>
