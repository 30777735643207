<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
   <div class="content-wrapper">
      <div class="content-detached">
         <div class="content-body">
            <section class="row">
               <div class="col-12">
                  <!-- content user view -->
                  <div class="card card-custom">
                     <div class="card-content">
                        <div class="card">
                           <div class="card-header">
                              <div class="heading-elements mt-0">
                                 <button *ngIf="permissions.getPermission('70-10-10').asignado" class="btn btn-warning float-right mr-5 mt-5" (click)="changePassModal(passModal)">
                                    <i class="flaticon-refresh"></i>
                                    Cambiar Contraseña
                                 </button>

                                 <button *ngIf="permissions.getPermission('70-10-20').asignado" class="btn btn-success float-right mr-5 mt-5" (click)="newUserModal(editUserModal)">
                                    <i class="flaticon-edit"></i>
                                    Modificar Datos
                                 </button>
                              </div>
                              <h3 class="text-muted">Perfil de Usuario</h3>
                              <h1 class="font-weight-bold">{{ currentUser.nombre }} {{ currentUser.apellidos }}</h1>
                           </div>
                           <div class="m-5">
                              <div style="display: flex" id="col-titulo-perfil">
                                 <div class="col-8 col-sm-8" id="col-nombre-cargo">
                                    <b class="span-cargo text-muted">Cargo: </b><span class="span-cargo">{{ currentUser.cargo }}</span>
                                 </div>
                                 <div class="col-4 col-sm-3 offset-md-1 offset-sm-1" id="col-acceso">
                                    <span>Ultimo acceso: </span>

                                    <span>{{ currentUser.ultimoAcceso | date : "dd/MM/yyyy HH:mm" }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- null user view -->
               </div>
            </section>
            <section class="row mt-5">
               <div class="col-12">
                  <div class="row">
                     <div class="col-6">
                        <div class="card card-custom">
                           <div class="card-header">
                              <div class="card-title">Datos del Usuario</div>
                           </div>
                           <div class="card-body">
                              <table class="table tabla-datos-usuario">
                                 <tr>
                                    <td>Nombre:</td>
                                    <td>{{ currentUser.nombre }} {{ currentUser.apellidos }}</td>
                                 </tr>
                                 <tr>
                                    <td>RUT:</td>
                                    <td>{{ currentUser.rutFormateado }}</td>
                                 </tr>
                                 <tr>
                                    <td>E-mail:</td>
                                    <td>{{ currentUser.email }}</td>
                                 </tr>
                                 <tr>
                                    <td>Perfil:</td>
                                    <td>{{ currentUser.perfilUsuario }}</td>
                                 </tr>
                                 <tr>
                                    <td>Teléfono</td>
                                    <td>{{ currentUser.telefono }}</td>
                                 </tr>
                                 <tr>
                                    <td>Dirección</td>
                                    <td>{{ currentUser.direccion }}</td>
                                 </tr>
                                 <tr>
                                    <td>Comuna</td>
                                    <td>{{ currentUser.comuna }}</td>
                                 </tr>
                              </table>
                           </div>
                        </div>
                     </div>
                     <div class="col-6">
                        <div class="card">
                           <div class="card-header">
                              <h4 class="card-title mb-0">Sucursales Asignadas</h4>
                           </div>
                           <div class="card-body">
                              <ngx-datatable #table class="material contacs-table" [rows]="listaSucursales" [columnMode]="'flex'" [headerHeight]="30" [footerHeight]="50" [rowHeight]="42" [limit]="4" fxFlex="auto" [perfectScrollbar]="configScroll" [messages]="{ emptyMessage: 'Sin sucursales asignadas' | translate, totalMessage: 'Sucursales' | translate, selectedMessage: false }">
                                 <!--Nombre Sucursal-->
                                 <ngx-datatable-column name="Nombre Sucursal" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                       <span>{{ row.nombre }}</span>
                                    </ng-template>
                                 </ngx-datatable-column>
                                 <!--Direccion sucursal-->
                                 <ngx-datatable-column name="Dirección Sucursal" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                       <span>{{ row.direccion }}</span>
                                    </ng-template>
                                 </ngx-datatable-column>
                              </ngx-datatable>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </div>
   </div>
</div>

<ng-template #editUserModal let-d="dismiss">
   <form [formGroup]="usuarioForm" (ngSubmit)="onSubmit()" #usuarioFormRef="ngForm">
      <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel1">Actualizar Usuario</h5>
         <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
         </button>
      </div>
      <div class="modal-body">
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Rut</label>
               <div class="col-9">
                  <input type="text" name="rutString" id="rutString" formControlName="rutString" class="form-control" disabled />
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Nombre</label>
               <div class="col-9">
                  <input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control" />
                  <div *ngIf="usuarioFormRef.submitted && (usuarioForm.get('nombre').invalid || usuarioForm.get('nombre').dirty)" class="invalid-feedback">
                     <div *ngIf="usuarioForm.get('nombre').errors?.required">Campo requerido</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Apellidos</label>
               <div class="col-9">
                  <input type="text" name="apellidos" id="apellidos" formControlName="apellidos" class="form-control" />
                  <div *ngIf="usuarioFormRef.submitted && (usuarioForm.get('apellidos').invalid || usuarioForm.get('apellidos').dirty)" class="invalid-feedback">
                     <div *ngIf="usuarioForm.get('apellidos').errors?.required">Campo requerido</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Telefono</label>
               <div class="col-9">
                  <input type="number" name="telefono" id="telefono" formControlName="telefono" class="form-control" maxlength="9" minlength="9" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                  <div *ngIf="usuarioFormRef.submitted && (usuarioForm.get('telefono').invalid || usuarioForm.get('telefono').dirty)" class="invalid-feedback">
                     <div *ngIf="usuarioForm.get('telefono').errors?.required">Campo requerido</div>
                     <div *ngIf="usuarioForm.get('telefono').errors?.maxLength">9 numeros requeridos</div>
                     <div *ngIf="usuarioForm.get('telefono').errors?.minLength">9 numeros requeridos</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Dirección</label>
               <div class="col-9">
                  <input type="text" name="direccion" id="direccion" formControlName="direccion" class="form-control" />
                  <div *ngIf="usuarioFormRef.submitted && (usuarioForm.get('direccion').invalid || usuarioForm.get('direccion').dirty)" class="invalid-feedback">
                     <div *ngIf="usuarioForm.get('direccion').errors?.required">Campo requerido</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Comuna</label>
               <div class="col-9">
                  <input type="text" name="comuna" id="comuna" formControlName="comuna" class="form-control" />
                  <div *ngIf="usuarioFormRef.submitted && (usuarioForm.get('comuna').invalid || usuarioForm.get('comuna').dirty)" class="invalid-feedback">
                     <div *ngIf="usuarioForm.get('comuna').errors?.required">Campo requerido</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Correo Electrónico</label>
               <div class="col-9">
                  <input type="text" name="email" id="email" formControlName="email" class="form-control" />
                  <div *ngIf="usuarioFormRef.submitted && (usuarioForm.get('email').invalid || usuarioForm.get('email').dirty)" class="invalid-feedback">
                     <div *ngIf="usuarioForm.get('email').errors?.required">Campo requerido</div>
                     <div *ngIf="usuarioForm.get('email').hasError('wrongEmail')">Correo inválido</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer">
         <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">Guardar</span>
         </button>
         <!-- <button type="button" id="add-contact-item" class="btn btn-warning add-contact-item" (click)="onSubmitThenContract(usuarioFormRef,$event)" *ngIf="!clientToEdit && permissions.getPermission('30-20-16').asignado">
                <i class="la la-paper-plane-o d-lg-none"></i>
                <span class="d-none d-lg-block">Contrato</span>
             </button> -->
         <button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
            <span class="d-none d-lg-block">Cerrar</span>
         </button>
      </div>
   </form>
</ng-template>

<ng-template #passModal let-d="dismiss">
   <form [formGroup]="passForm" (ngSubmit)="changePass()" #passFormRef="ngForm">
      <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel1">Actualizar Usuario</h5>
         <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
         </button>
      </div>
      <div class="modal-body">
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Contraseña Actual</label>
               <div class="col-9">
                  <input type="password" name="currentPass" id="currentPass" formControlName="currentPass" class="form-control" />
                  <div *ngIf="!passFormRef.submitted && (passForm.get('currentPass').invalid || passForm.get('currentPass').dirty)" class="invalid-feedback">
                     <div *ngIf="passForm.get('currentPass').errors?.required">Campo requerido</div>
                     <div *ngIf="passForm.get('currentPass').errors?.minLength">La contraseña debe contener al menos 8 carácteres</div>
                     <div *ngIf="passForm.get('currentPass').errors?.maxLength">La contraseña puede tener como máximo 32 carácteres</div>
                     <div *ngIf="passForm.get('currentPass').errors?.pattern">La contraseña debe contener por lo menos 1 minúscula, 1 mayúscula y 1 un número</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Contraseña</label>
               <div class="col-9">
                  <input type="password" name="pass" id="pass" formControlName="pass" class="form-control" autocomplete="off" />
                  <div *ngIf="!passFormRef.submitted && (passForm.get('pass').invalid || passForm.get('pass').dirty)" class="invalid-feedback">
                     <div *ngIf="passForm.get('pass').errors?.required">Campo requerido</div>
                     <div *ngIf="passForm.get('pass').errors?.minLength">La contraseña debe contener al menos 8 carácteres</div>
                     <div *ngIf="passForm.get('pass').errors?.maxLength">La contraseña puede tener como máximo 32 carácteres</div>
                     <div *ngIf="passForm.get('pass').errors?.pattern">La contraseña debe contener por lo menos 1 minúscula, 1 mayúscula y 1 un número</div>
                  </div>
               </div>
            </div>
         </div>
         <div class="form-group position-relative mx-auto">
            <div class="row">
               <label for="" class="col-2 label-control">Confirmar Contraseña</label>
               <div class="col-9">
                  <input type="password" name="repeatPass" id="repeatPass" formControlName="repeatPass" class="form-control" autocomplete="off" />
                  <div *ngIf="!passFormRef.submitted && (passForm.get('repeatPass').invalid || passForm.get('repeatPass').dirty)" class="invalid-feedback">
                     <div *ngIf="passForm.get('repeatPass').errors?.required">Campo requerido</div>
                     <div *ngIf="passForm.get('pass').errors?.minLength">La contraseña debe contener al menos 8 carácteres</div>
                     <div *ngIf="passForm.get('pass').errors?.maxLength">La contraseña puede tener como máximo 32 carácteres</div>
                     <div *ngIf="passForm.get('pass').errors?.pattern">La contraseña debe contener por lo menos 1 minúscula, 1 mayúscula y 1 un número</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer">
         <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">Guardar</span>
         </button>
         <button type="button" class="btn btn-secondary ml-3" (click)="sendClosePassRequest()">
            <span class="d-none d-lg-block">Cerrar</span>
         </button>
      </div>
   </form>
</ng-template>
