import { Perfil } from "../Perfil/Perfil";
import { Permiso } from "../Perfil/Permiso";
import { Sucursal } from "../Sucursal";

export class Usuario {
	public usuarioId: string;
	public rut: number;
	public dv: string;
	public nombre: string;
	public apellidos: string;
	public direccion: string;
	public comuna: string;
	public telefono: number;
	public whatsapp: number;
	public cargo: string;
	public email: string;
	public password: string;
	public passwordTemporal: boolean;
	public perfilUsuario: string;
	public activo: boolean;
	public fechaNacimiento: string;
	public rutFormateado: string;
	public permisos: Permiso[];
	public perfiles: Perfil[];
	public sucursales: Sucursal[];
	public ultimoAcceso: Date;
	public perfilInicialId: string;
	public perfilInicial: Perfil;
	public nombreCompleto: string;
	public bloqueado: boolean;
	public sucursalIds: string[];
}
