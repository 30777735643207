import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ConfigConsolidados } from "../config-consolidados";
import { DteFilter } from "src/app/models/Dte/DteFilter";
import { Page } from "src/app/shared/models/Page";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Consolidado, ConsolidadoDTE, ConsolidadoEstadistica } from "src/app/models/Dte/Consolidado";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { ConsolidadoService } from "src/app/services/consolidado.service";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import * as moment from "moment";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { groupBy } from "lodash";
import { DatatableRowDetailDirective } from "@swimlane/ngx-datatable";
import { DatePipe } from "@angular/common";
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexTitleSubtitle, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexYAxis, ChartComponent } from "ng-apexcharts";
import { PageSizes } from "../../../../_metronic/shared/crud-table/models/paginator.model";
import { concatMap } from "rxjs/operators";
import { Router } from "@angular/router";

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	yaxis: ApexYAxis;
	xaxis: ApexXAxis;
	fill: ApexFill;
	tooltip: ApexTooltip;
	stroke: ApexStroke;
	legend: ApexLegend;
};

@Component({
	selector: "app-consolidado-ventas",
	templateUrl: "./consolidado-ventas.component.html",
	styleUrls: ["./consolidado-ventas.component.scss"],
})
export class ConsolidadoVentasComponent implements OnInit {
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	@ViewChild("tableConsolidado") tableRef: any;
	@ViewChild("myDetailRow") myDetailRow: DatatableRowDetailDirective;

	@ViewChild("chart") chart: ChartComponent;
	public chartOptions: Partial<ChartOptions>;

	public configLocal = ConfigConsolidados;
	public rowsMantenedor: Consolidado[] = [];

	public rowsDTEs: ConsolidadoDTE[] = [];

	public groupedRows: any[];
	public selected: Consolidado[] = []; //dtes seleccionados por checkbox
	public selectedItem: Consolidado; //dte seleccionado para envio individual y despliegue en modal
	public selectedAllItemsInPage: any = {}; // bool para la seleccion total de elementos en tabla
	public searchRows: any[] = [];
	public searchCriteria;
	public configScroll: PerfectScrollbarConfigInterface = {};
	public filter = new DteFilter();
	public page = new Page();
	loading: boolean = false;
	public emisorId: string;
	public usuarioId: string;
	public sucursalId: string;
	fileIdValue: string;
	public filterDescription: string;
	public componentStarted: boolean = false;
	public totalDTE: number = 0;
	public cantidadDTE: number = 0;
	public maxPlan: number = 0;
	constructor(public utilities: UtilitiesService, private router: Router, private datePipe: DatePipe, public tokenService: TokenStorageService, public consolidadoService: ConsolidadoService, private ref: ChangeDetectorRef) {
		this.emisorId = this.tokenService.getCompany();
		this.page.pageSize = 5;
		this.chartOptions = {
			series: [],
			chart: {
				type: "bar",
				height: 230,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "15%",
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			xaxis: {
				type: "category",
				categories: [],
			},
			yaxis: {
				title: {
					text: "Montos en CLP",
				},
				labels: {
					formatter: function (val) {
						return "$" + val.toLocaleString("es-CL");
					},
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$" + val.toLocaleString("es-CL") + " Pesos";
					},
				},
			},
		};
	}

	setDataGraphic(consolidado: ConsolidadoEstadistica) {
		this.chartOptions.xaxis = {
			type: "category",
			categories: consolidado.fechas,
		};
		this.chartOptions.series = consolidado.series.map((serie) => ({
			name: serie.name,
			data: serie.data,
		}));
	}

	rowHeightValue: number = 100;
	expandedRows = new Set<any>();
	expandedRow: any;

	toggleExpandRow(row) {
		this.rowHeightValue = 60 + 30 * row.length;
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	isRowExpanded(row): boolean {
		return this.expandedRows.has(row);
	}

	ngOnInit(): void {
		ConfigConsolidados.barraBusqueda.fechaInicio = moment().startOf("month");
		ConfigConsolidados.barraBusqueda.fechaFin = moment();

		let fechaInicio = new Date();
		fechaInicio.setDate(1); // Primer día del mes en curso
		fechaInicio.setHours(0, 0, 0, 0); // Asegurar que la hora es 00:00:00.000

		let fechaFin = new Date(); // Día actual
		fechaFin.setHours(23, 59, 59, 999); // Asegurar que la hora es 23:59:59.999

		this.filter.tipoDteId = "";
		this.filter.fechaDesde = fechaInicio;
		this.filter.fechaHasta = fechaFin;
		this.filter.emisorId = this.emisorId;
		this.filter.sucursalId = this.sucursalId;

		this.setPage({ offset: 0 });
	}

	searchElementsByFilter(event) {
		//se toman los valores del filtro que necesite el endpoint de filtro para dte
		this.filter = this.filterConcordance(this.configLocal.barraBusqueda.criteriosKeys, this.filter, event);
		this.filter.tipoDteId = event.tipoDteId != null ? event.tipoDteId : "";
		this.filter.fechaDesde = new Date(event.fechaInicio);
		this.filter.fechaHasta = new Date(event.fechaFin);
		this.filter.importado = event.importado ? 1 : 0;
		this.filter.reutilizado = event.reutilizado ? 1 : 0;
		this.filter.estadoAcuse = event.estadoAcuseEmitido;
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.estadoSii = event.estadoDte;
		this.filter.sucursalId = event.sucursalId ? event.sucursalId : this.sucursalId;
		this.filter.emisorId = event.emisorId ? event.emisorId : this.emisorId;
		this.setPage({ offset: 0 });
	}

	filterConcordance(configCriterias, dteFilter: DteFilter, eventFilter): DteFilter {
		//vaciar del dteFilter aquellos campos distinto al presnetado en el eventFilter actual, segun el arreglo configCriterias
		configCriterias.forEach((criteria) => {
			if (criteria != eventFilter.criterio) {
				dteFilter[criteria] = "";
			}
		});
		return dteFilter;
	}

	exportToExcel() {
		this.prepareCallExcel();
	}

	prepareCallExcel() {
		let fullPage: Page = new Page();
		fullPage.pageSize = this.page.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = this.page.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.consolidadoService.getDataByPage(this.filter, fullPage).subscribe(
			(result) => {
				let elements = result.data.reduce((acc, consolidado) => {
					return acc.concat(consolidado.detalle);
				}, [] as ConsolidadoDTE[]);

				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al exportar";
				this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. ";
				this.currentSwal.text += this.utilities.setErrorMessageFromArray(error.error);
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<ConsolidadoDTE>): void {
		let arregloExportar = [];
		arrayToExport.forEach((row) => {
			let elementToExport = {};
			elementToExport["Fecha"] = this.datePipe.transform(row.fecha, "dd/MM/yyyy");
			elementToExport["Tipo DTE"] = row.tipoDTE;
			elementToExport["Codigo DTE"] = row.codigoSii;
			elementToExport["Cantidad Emitidos"] = row.cantidadEmitidos;
			elementToExport["Cantidad Anulados"] = row.cantidadAnulados;
			elementToExport["Total Neto"] = row.totalNeto;
			elementToExport["Total Exento"] = row.totalExento;
			elementToExport["Total IVA"] = row.totalIva;
			elementToExport["Total"] = row.total;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Consolidado_Ventas");
	}

	getId(row) {
		return row.fecha;
	}
	onSelect({ selected }) {
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	setPage(pageInfo) {
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		if (isNaN(this.filter.fechaDesde.getTime()) || isNaN(this.filter.fechaHasta.getTime())) {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error en la solicitud";
			this.currentSwal.text = "La fecha desde y hasta son requeridas";
			this.currentSwal.fire();
			return;
		}
		this.loading = true;
		this.consolidadoService
			.getDataByPage(this.filter, localPage)
			.pipe(
				concatMap((pagedData) => {
					let elements = pagedData.data as Consolidado[];
					this.ref.detectChanges();
					this.rowsMantenedor = [...elements];
					//this.groupedRows = this.rowsMantenedor;
					this.rowsDTEs = this.rowsMantenedor.reduce((acc, consolidado) => {
						return acc.concat(consolidado.detalle);
					}, [] as ConsolidadoDTE[]);
					this.setPagefromResponse(this.page, pagedData);
					return this.consolidadoService.getTotals(this.filter);
				})
			)
			.subscribe(
				(data) => {
					this.setDataGraphic(data);
					this.totalDTE = data.totalVentas;
					this.cantidadDTE = data.cantidadDTE;
					this.maxPlan = data.maxPlan;
					this.componentStarted = true;
					this.ref.detectChanges();
				},
				(error) => {
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}

	setPagefromResponse(currentPage: Page, data: PagedResponse<Consolidado>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	toMantenedorFromDetail(item: ConsolidadoDTE, nulos: boolean) {
		this.router.navigateByUrl("/documentos-emitidos", {
			state: {
				date: item.fecha,
				codigoSii: item.codigoSii,
				nulos: nulos,
				filterDescription: "Filtrado desde Consolidado de Ventas",
				filterEmisor: this.filter.emisorId,
			},
		});
	}

	toMantenedorFromGeneral(item: Consolidado, nulos: boolean) {
		this.router.navigateByUrl("/documentos-emitidos", {
			state: {
				date: item.fecha,
				nulos: nulos,
				filterDescription: "Filtrado desde Consolidado de Ventas",
				filterEmisor: this.filter.emisorId,
			},
		});
	}
}
