<div [formGroup]="referencesForm" class="mt-5 mx-5" #localFormRef="ngForm">
    <div class="row" formArrayName="referencesArray">
        <table class="table">
            <thead>
                <tr>
                    <th>N°</th>
                    <th>Tipo Documento</th>
                    <th>Folio Referencia </th>
                    <th>Fecha Referencia</th>
                    <th>Razón Referencia</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngFor="let ref of referencesForm.get('referencesArray')['controls'];let i=index">
                <tr [formGroupName]="i">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        <ng-select [items]="tipoDteReferenciaSource" [multiple]="false"
                            [placeholder]="'Seleccionar tipo de documento'" bindLabel="nombre" formControlName="tipoDte"
                            (blur)="onSubmit()"></ng-select>
                        <div *ngIf="(localFormRef.touched || localFormRef.dirty) && referencesForm.get('referencesArray')['controls'][i].invalid"
                            class="text-danger">
                            <div
                                *ngIf="referencesForm.get('referencesArray')['controls'][i].get('tipoDte').errors?.required">
                                Campo Requerido</div>
                        </div>
                    </td>
                    <td>
                        <input type="text" [min]="0" class="form-control" formControlName="folioRef"
                            (blur)="onSubmit()">
                        <div *ngIf="(localFormRef.touched || localFormRef.dirty) && referencesForm.get('referencesArray')['controls'][i].invalid"
                            class="text-danger">
                            <div
                                *ngIf="referencesForm.get('referencesArray')['controls'][i].get('folioRef').errors?.required">
                                Campo Requerido</div>
                        </div>
                    </td>
                    <td>
                        <input type="text" class="form-control" formControlName="fechaRef" (blur)="onSubmit()"
                            [opens]="'center'" name="fechaRef" ngxDaterangepickerMd [locale]="locale"
                            [singleDatePicker]="true" [autoApply]="true" (change)="detectChangeReferenceDate($event,i)">
                        <div *ngIf="(localFormRef.touched || localFormRef.dirty) && (referencesForm.get('referencesArray')['controls'][i].invalid || !validDatesArray[i])"
                            class="text-danger">
                            <div
                                *ngIf="referencesForm.get('referencesArray')['controls'][i].get('fechaRef').errors?.required">
                                Campo Requerido</div>
                            <div *ngIf="!validDatesArray[i]">Fecha inválida, usar formato dd/mm/yyyy</div>
                        </div>
                    </td>
                    <td>
                        <input type="text" class="form-control" formControlName="razonRef" (blur)="onSubmit()">
                        <div *ngIf="(localFormRef.touched || localFormRef.dirty) && referencesForm.get('referencesArray')['controls'][i].invalid"
                            class="text-danger">
                            <div
                                *ngIf="referencesForm.get('referencesArray')['controls'][i].get('razonRef').errors?.required">
                                Campo Requerido</div>
                        </div>
                    </td>
                    <!-- <td>
                        <ng-select
                            [items]="tipoReferenciaEnumSource"
                            [multiple]="false"
                            [placeholder]="'Seleccionar tipo de documento'"
                            bindLabel="name"
                            formControlName="tipoRazonRef"
                            (blur)="onSubmit()"
                        ></ng-select>
                        <div *ngIf="(localFormRef.touched || localFormRef.dirty) && referencesForm.get('referencesArray')['controls'][i].invalid" class="text-danger">
                            <div *ngIf="referencesForm.get('referencesArray')['controls'][i].get('tipoDte').errors?.required" >Campo Requerido</div>
                        </div>
                    </td> -->
                    <td>
                        <button class="btn btn-danger" (click)="removeReferenceFormItem(i)"> <i
                                class="flaticon-delete text-white"></i></button>

                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mb-5 mx-auto">
            <div class="d-flex justify-content-center">
                <button class="btn btn-success" (click)="addReferenceFormItem() "
                    [disabled]="globalReferenceForGuideRefs"><i class="flaticon-add-circular-button"></i>Agregar
                    Referencia</button>

            </div>
        </div>
    </div>
</div>