import { Component, Input, OnInit } from "@angular/core";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";

@Component({
	selector: "app-company-ambient",
	templateUrl: "./company-ambient.component.html",
	styleUrls: ["./company-ambient.component.scss"],
})
export class CompanyAmbientComponent implements OnInit {
	public ambientColor;
	public ambientIcon;
	public ambientName;
	public pillName;
	private currentEmisor;
	private currentEmisorId;
	private currentEmisorObs$: Observable<string>;
	private destroyObs$: Subject<boolean> = new Subject<boolean>();
	public isOffline: boolean = false;
	@Input() headerMode: boolean = false;
	constructor(private tokenStorage: TokenStorageService, private localStorage: LocalStorageService) {
		this.currentEmisorObs$ = this.tokenStorage.currentEmisorObservable;
	}

	ngOnInit(): void {
		this.currentEmisorId = this.tokenStorage.getCompany();
		this.currentEmisor = (JSON.parse(this.localStorage.getEmisores()) as Emisor[]).find((em) => em.emisorId == this.currentEmisorId);
		this.setAmbientData(this.currentEmisor);
		this.currentEmisorObs$.subscribe((newEmisorId) => {
			if (newEmisorId != "") {
				this.currentEmisorId = newEmisorId;
				this.currentEmisor = (JSON.parse(this.localStorage.getEmisores()) as Emisor[]).find((em) => em.emisorId == this.currentEmisorId);
				this.setAmbientData(this.currentEmisor);
			}
		});
	}

	emisorAmbientToColor(emisor: Emisor) {
		return emisor.ambiente == 1 ? "bg-success" : "bg-danger";
	}
	emisorAmbientToIcon(emisor: Emisor) {
		return emisor.ambiente == 1 ? "door-open" : "door-closed";
	}
	emisorAmbientToName(emisor: Emisor) {
		return emisor.ambiente == 1 ? "Producción" : "Certificación";
	}
	emisorAmbientToBadgeName(emisor: Emisor) {
		return emisor.ambiente == 1 ? "bg-success" : "bg-danger";
	}

	setAmbientData(emisor: Emisor) {
		if (emisor != undefined) {
			this.ambientColor = this.emisorAmbientToColor(emisor);
			this.ambientIcon = this.emisorAmbientToIcon(emisor);
			this.ambientName = this.emisorAmbientToName(emisor);
			this.pillName = this.emisorAmbientToBadgeName(emisor);
		}
	}

	ngOnDestroy() {
		this.destroyObs$.next(true);
		this.destroyObs$.unsubscribe();
	}
}
