export class DteFilter {
	importacionMasivaId: string = "";
	emisorId: string;
	sucursalId: string;
	receptorId: string;
	tipoDteId: string;
	fechaDesde: Date;
	fechaHasta: Date;
	estadoSii: number;
	nulos: boolean;
	rutReceptor: string = "";
	razonSocialReceptor: string = "";
	folio: string = "";
	rutEmisor: string;
	codigoSii: string;
	tipoTraslado: string = "";
	tipoDespacho: string = "";
	facturable: string;
	reutilizado: number;
	trackId: number;
	importado: number;
	razonSocialProveedor: string;
	estadoAcuse: number;
	ambienteId: number;
}
