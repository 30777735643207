import { DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import * as moment from "moment";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { Sucursal } from "src/app/models/Sucursal";
import { AuthService } from "src/app/modules/auth";
import { ChangeCompanyService } from "src/app/services/changecompany.service";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { SessionUser } from "src/app/shared/models/sessionUser";
import Swal from "sweetalert2";

@Component({
	selector: "app-dashboard-widgets",
	templateUrl: "./dashboard-widgets.component.html",
	styleUrls: ["./dashboard-widgets.component.scss"],
})
export class DashboardWidgetsComponent implements OnInit, AfterViewInit {
	@ViewChild("currentSwal") public currentSwal: SwalComponent;
	public user: SessionUser;
	public loading: boolean = false;
	private modalRef: NgbModalRef;
	private modalRefChangeCompany: NgbModalRef;
	@ViewChild("extendPlanForm", { static: true }) extendPlanForm: TemplateRef<any>;
	@ViewChild("changeCompanyForm", { static: true }) changeCompanyForm: TemplateRef<any>;
	public currentEmisorObject: Emisor;
	public emisoresSource: Emisor[];

	public nuevaFechaCaducidad: moment.Moment;
	public fechaActual = moment();

	constructor(
		public utilities: UtilitiesService,
		private userService: UsuarioService,
		private changeCompanyService: ChangeCompanyService,
		private localStorage: LocalStorageService,
		private authService: AuthService,
		private tokenService: TokenStorageService,
		public emisorService: EmisorService,
		public modalService: NgbModal,
		private datePipe: DatePipe,
		private router: Router
	) {
		this.user = this.tokenService.getUser() as SessionUser;

		this.emisoresSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
	}
	ngOnInit(): void {
		this.changeCompanyService.companyChanged.subscribe((newEmisor: Emisor) => {
			this.currentEmisorObject = newEmisor;
			this.ngAfterViewInit();
		});
	}

	ngAfterViewInit(): void {
	}

	handleError(error): void {
		Swal.fire({
			title: "Error en datos",
			text: "No fue posible obtener los datos del usuario actual, favor intente nuevamente",
			icon: "error",
		});
	}

	openCambiarEmpresa() {
		this.modalRefChangeCompany = this.modalService.open(this.changeCompanyForm, {
			windowClass: "",
			keyboard: false,
		});
	}

	cambiarEmpresa() {
		this.tokenService.setCompany(this.currentEmisorObject.emisorId);
		const currentSucursalId = this.tokenService.getSubsidiary();
		this.changeCompanyService.SetNewCompany(this.currentEmisorObject, currentSucursalId, this.currentSwal);
		this.modalRef.close();
		this.modalRefChangeCompany.close();
	}

	// actualizarCertificaciones(certificaciones) {
	// 	this.certificaciones = certificaciones;
	// }
	// actualizarPago(resumen) {
	// 	this.resumenCuenta = resumen;
	// }

	
}
