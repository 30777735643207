import { Identifiers } from "@angular/compiler";

export class SessionUser {
	id: string;
	firstName: string;
	lastName: string;
	identifier: Identifiers;
	temporaryPassword: boolean;
	superAdmin: boolean;
	configured: boolean;
	isPartner: boolean;
	alertDemo: boolean;
	email: string;
	cargo: string;
	constructor(idInput: string, nameInput: string, lastNameInput: string, identifierInput: Identifiers, temporaryPassword: boolean, superAdmin: boolean, configured: boolean, alertDemo: boolean,email: string, cargo: string, isPartner: boolean) {
		this.id = idInput;
		this.firstName = nameInput;
		this.lastName = lastNameInput;
		this.identifier = identifierInput;
		this.temporaryPassword = temporaryPassword;
		this.superAdmin = superAdmin;
		this.configured = configured;
		this.alertDemo = alertDemo;
		this.cargo = cargo;
		this.email = email;
		this.isPartner = isPartner;
	}
}
