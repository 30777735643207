import { Component, Input, OnInit } from "@angular/core";
import { DteEmitido } from "src/app/models/Dte/DteEmitido";
import { AnuladorDteComponent } from "../../facturacion/anulador-dte/anulador-dte.component";

@Component({
   selector: "app-semaforo-estado-dte",
   templateUrl: "./semaforo-estado-dte.component.html",
   styleUrls: ["./semaforo-estado-dte.component.scss"],
})
export class SemaforoEstadoDteComponent implements OnInit {
   public statusAcuse: boolean = false;
   @Input() row: DteEmitido;
   constructor() {}

   ngOnInit(): void {
      if (this.row != undefined) {
         if (this.row.estadoAcuse != null && this.row.estadoAcuse != undefined && this.row.estadoAcuse == 3) this.statusAcuse = true;
      }
   }
}
