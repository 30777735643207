import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TipoDte } from "src/app/models/tipoDte";
import { ConfigCorreoEnvio } from "./config-correo-envio";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { CorreoEnvio } from "src/app/models/CorreoEnvio/CorreoEnvio";
import { CorreoEnvioService } from "src/app/services/CorreoEnvio.service";
import { CorreoEnvioFilter } from "src/app/models/CorreoEnvio/CorreoEnvioFilter";
import { PlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/PlantillaCorreoEnvio";
import { TipoCorreoEnvio } from "src/app/models/CorreoEnvio/TipoCorreoEnvio";
import { TipoPlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/TipoPlantillaCorreoEnvio";
import { PlantillaEjemploRequest } from "src/app/models/CorreoEnvio/PlantillaEjemploRequest";
import { SortType } from "@swimlane/ngx-datatable";

@Component({
	selector: "app-mantenedor-correo-envio",
	templateUrl: "./mantenedor-correo-envio.component.html",
	styleUrls: ["./mantenedor-correo-envio.component.scss"],
})
export class MantenedorCorreoEnvioComponent implements OnInit {
	SortType = SortType;
	public loading: boolean = false;
	public rowsMantenedor: CorreoEnvio[] = [];
	public page: Page = new Page();
	private filter: CorreoEnvioFilter = new CorreoEnvioFilter();
	public correoEnvioToEdit: CorreoEnvio = new CorreoEnvio();
	public correoEnvioExample: CorreoEnvio = new CorreoEnvio();
	private addCorreoEnvioModalRef: NgbModalRef;
	private editCorreoEnvioModalRef: NgbModalRef;
	private showCorreoEnvioModalRef: NgbModalRef;
	private templatesModalRef: NgbModalRef;
	public selectedCorreoEnvio: CorreoEnvio = undefined;
	public emisorId: string;
	public configLocal = ConfigCorreoEnvio;
	public templateTypesEdit: TipoPlantillaCorreoEnvio[] = [];
	public emailSendingType: TipoCorreoEnvio[] = JSON.parse(this.localStorage.getTipooCorreoEnvio()) as TipoCorreoEnvio[];
	public emailSendingTemplateType: TipoPlantillaCorreoEnvio[] = JSON.parse(this.localStorage.getTipoPlantillaoCorreoEnvio()) as TipoPlantillaCorreoEnvio[];
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	constructor(private CorreoEnvioService: CorreoEnvioService, private ref: ChangeDetectorRef, private modalService: NgbModal, private tokenService: TokenStorageService, public utilities: UtilitiesService, public permissions: PermissionService, private localStorage: LocalStorageService) {
		(this.emisorId = this.tokenService.getCompany()), (this.filter.activo = 1);
		this.filter.tipoEnvioCorreo = "";
		this.filter.tipoPlantillaEnvioCorreo = "";
	}

	ngOnInit(): void {
		this.setPage({ offset: 0 });
	}
	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		this.CorreoEnvioService.getDataByPage(this.filter, localPage)
			.subscribe(
				(result) => {
					this.loading = false;
					let rows = result.data as CorreoEnvio[];
					this.rowsMantenedor = [...rows];
					this.rowsMantenedor.forEach((row) => {
						this.emailSendingType.forEach((item) => {
							if (item.tipoEnvioCorreoId == row.tipoEnvioCorreoId) row.tipoEnvioCorreo = item;
						});
						this.emailSendingTemplateType.forEach((item) => {
							if (item.tipoPlantillaEnvioCorreoId == row.tipoPlantillaId) row.tipoPlantillaEnvioCorreo = item;
						});
					});
					this.setPageFromResponse(this.page, result);
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error en Lista de Correo de Envios",
						text: "No fue posible obtener la lista de correo de envios disponibles, recargue la pagina." + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			)
			.add(() => (this.componentStarted = true));
	}

	openAddCorreoEnvioModal(modalRef) {
		this.addCorreoEnvioModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	closeAddCorreoEnvioModal() {
		this.addCorreoEnvioModalRef.close();
	}
	openShowCorreoEnvioModal(modalRef, row: CorreoEnvio) {
		this.selectedCorreoEnvio = row;
		this.showCorreoEnvioModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}
	closeShowCorreoEnvioModal() {
		this.showCorreoEnvioModalRef.close();
	}
	sendNewCorreoEnvio(event: CorreoEnvio) {
		this.loading = true;
		this.CorreoEnvioService.addCorreoEnvio(event).subscribe(
			(result) => {
				// let rows = JSON.parse(JSON.stringify(this.rowsMantenedor));
				// event.tipoPlantillaId = result.data;
				// rows.push(event);
				this.setPage({ offset: 0 });
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Correo de envio Agregado",
					text: result.message,
				});
				this.closeAddCorreoEnvioModal();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Agregar Correo de Envio",
					text: "No se ha podido agregar el correo de envio " + event.nombre + ", intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
		// ).add(()=>{
		//   this.loading=false;
		//   this.ref.detectChanges();
		//   this.closeAddCorreoEnvioModal();
		// });
	}

	openEditCorreoEnvioModal(modalRef, row: CorreoEnvio) {
		this.selectedCorreoEnvio = row;
		this.editCorreoEnvioModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	closeEditCorreoEnvioModal() {
		this.editCorreoEnvioModalRef.close();
	}

	editCorreoEnvioRequest(event: CorreoEnvio) {
		this.loading = true;
		this.CorreoEnvioService.updateCorreoEnvio(event).subscribe(
			(result) => {
				// let rows = JSON.parse(JSON.stringify(this.rowsMantenedor));
				// event.tipoPlantillaId = result.data;
				// rows.push(event);
				// this.ref.detectChanges();
				// this.rowsMantenedor = JSON.parse(JSON.stringify(rows)) as CorreoEnvio[];
				this.setPage({ offset: 0 });
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Correo de Envio Actualizado",
					text: "Se ha actualizado exitosamente el correo de envio  " + event.nombre,
				});
				this.closeEditCorreoEnvioModal();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Actualizar Correo de Envio",
					text: "No se ha podido actualizar el correo de envio " + event.nombre + ", intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
		// ).add(()=>{
		//   this.loading=false;
		//   this.ref.detectChanges();
		//   this.closeEditCorreoEnvioModal();
		// });
	}

	deactivateCorreoEnvio(row: CorreoEnvio) {
		this.loading = true;
		this.CorreoEnvioService.deactivateEnvioCorreo(row.envioCorreoId).subscribe(
			(result) => {
				this.loading = false;
				// let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
				// this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
				this.setPage({ offset: 0 });
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Correo de envio Desactivado",
					text: "El correo de envio " + row.nombre + " fue desactivado exitosamente",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Desactivar Correo de Envio",
					text: "No fue posible desactivar el correo de envio " + row.nombre + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	activateCorreoEnvio(row: CorreoEnvio) {
		this.loading = true;
		this.CorreoEnvioService.activateCorreoEnvio(row.envioCorreoId).subscribe(
			(result) => {
				this.loading = false;
				// let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
				// this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
				this.setPage({ offset: 0 });
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Correo de Envio Activado",
					text: "El correo de envio " + row.nombre + " fue activado exitosamente",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Activar Correo de Envio",
					text: "No fue posible activar el correo de envio " + row.nombre + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<CorreoEnvio>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const newWindow = window.open(
			url,
			title,
			`
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}

	searchElementsByFilter(event) {
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.activo = event.registroActivo ? 0 : 1;
		this.filter.tipoEnvioCorreo = event.tipoEnvioCorreo ? event.tipoEnvioCorreo : "";
		this.filter.tipoPlantillaEnvioCorreo = event.tipoPlantillaEnvioCorreo ? event.tipoPlantillaEnvioCorreo : "";
		this.setPage({ offset: 0 });
	}

	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}
	getAllRowsByFilter(currentPageRef: Page, currentFilter: CorreoEnvioFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 1;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.CorreoEnvioService.getDataByPage(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as CorreoEnvio[];
				elements.forEach((row) => {});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				let msg = "";
				if (error.error.message) {
					msg += error.error.message;
				}
				Swal.fire({
					icon: "error",
					title: "Error al exportar",
					text: "No es posible exportar los datos. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}
	exportExcelData(arrayToExport: Array<CorreoEnvio>): void {
		let arregloExportar = [];
		arrayToExport.forEach((row) => {
			let elementToExport = {};
			elementToExport["Nombre"] = row.nombre;
			elementToExport["Correo"] = row.correo;
			elementToExport["Api Key"] = row.apiKey;
			elementToExport["Password"] = row.password;
			elementToExport["Puerto"] = row.puerto;
			elementToExport["Nombre Remitente"] = row.senderName;
			elementToExport["Servidor"] = row.servidor;
			elementToExport["SSL"] = row.ssl ? "Si" : "No";
			elementToExport["Tipo"] = row.nombreTipoEnvio;
			elementToExport["Tipo Plantilla"] = row.nombreTipoPlantillaEnvio;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "CorreoEnvio");
	}

	openTemplatesModal(modalRef, row: CorreoEnvio) {
		this.loading = true;
		this.CorreoEnvioService.getTemplates(row.envioCorreoId).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				row.Plantillas = result.data;
				this.correoEnvioToEdit = { ...row };
				this.templatesModalRef = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "plantillas No Encontradas",
					text: "Error al buscar plantillas",
				});
			}
		);
	}

	closeTemplatesModal() {
		this.templatesModalRef.close();
	}

	openExampleTemplateModal(modalRef) {
		this.loading = true;
		this.correoEnvioExample = new CorreoEnvio();
		this.CorreoEnvioService.getExampleTemplates().subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				this.correoEnvioExample.Plantillas = result.data.plantillasEjemploEnvio;
				this.correoEnvioExample.tipoPlantillaEnvioCorreo = result.data.tipoPlantilla;
				this.correoEnvioExample.nombre = "Plantillas ejemplo";
				this.correoEnvioExample.nombreTipoEnvio = result.data.tipoPlantilla.nombre;
				this.correoEnvioExample.nombreTipoPlantillaEnvio = result.data.tipoPlantilla.nombre;
				this.templatesModalRef = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Ejemplos de Plantillas No Encontradas",
					text: "Error al buscar ejemplos de plantillas",
				});
			}
		);
	}

	closeExampleTemplateModal() {
		this.templatesModalRef.close();
	}

	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}
}
