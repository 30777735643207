import { ChangeDetectorRef, Component, KeyValueDiffers, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigMantenedorPerfiles } from "./config-mantenedor-perfiles";
import { validarRut, VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { PerfilFilter } from "src/app/models/Perfil/PerfilFilter";
import { Perfil } from "src/app/models/Perfil/Perfil";
import { PerfilService } from "src/app/services/perfil.service";
import { NgForm } from "@angular/forms";
import { Permiso } from "src/app/models/Perfil/Permiso";
import { PermisoFilter } from "src/app/models/Perfil/PermisoFilter";
import { ConfigMantenedorPermisos } from "./config-mantenedor-permisos";
import { ActivatedRoute, Router } from "@angular/router";
import { Route } from "@angular/compiler/src/core";
import { PermissionService } from "src/app/services/permission.service";
import { ThisReceiver } from "@angular/compiler";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { EmisorService } from "src/app/services/emisor.service";
import { SortType } from "@swimlane/ngx-datatable";

@Component({
   selector: "app-mantenedor-perfiles",
   templateUrl: "./mantenedor-perfiles.component.html",
   styleUrls: ["./mantenedor-perfiles.component.scss"],
})
export class MantenedorPerfilesComponent implements OnInit {
   SortType = SortType;
   public loading = false;
   public page = new Page();
   public pagePermission = new Page();
   public profileFilter = new PerfilFilter();
   public permissionFilter = new PermisoFilter();
   public rowsMantenedorProfile: Perfil[] = [];
   public rowsMantenedorPermission: Permiso[] = [];
   public configLocal = ConfigMantenedorPerfiles;
   public configLocalPermission = ConfigMantenedorPermisos;
   public searchRows: any[] = [];
   public emisorId: string;
   public userId: string;
   public profileToShow: Perfil = new Perfil();
   public profileToEdit: Perfil = new Perfil();
   public profileToAdd: Perfil = new Perfil();
   public permissionToShow: Permiso = new Permiso();
   public permissionToEdit: Permiso = new Permiso();
   public permissionToAdd: Permiso = new Permiso();
   private saveRows: boolean = false;
   public fullListPermission: boolean = false;
   public detailProfileModal: NgbModalRef;
   public addNewProfileModal: NgbModalRef;
   public editProfileModal: NgbModalRef;
   public addNewPermissionModal: NgbModalRef;
   public editPermissionsModal: NgbModalRef;
   public PermissionsModal: NgbModalRef;
   public ProfilesModal: NgbModalRef;
   public searchCriterio;
   @ViewChild("currentSwal") private currentSwal: SwalComponent;
   @ViewChild("notesSwal") private notesSwal: SwalComponent;
   private asignarPerfilModal: NgbModalRef;
   public selectedPerfil: Perfil = undefined;
   public componentStarted: boolean = false;
   @ViewChild("tableMobile") tableRef: any;
   public emisoresList: Emisor[] = [];
   public isSuperAdmin: boolean = false;
   constructor(private modalService: NgbModal, public utilities: UtilitiesService, private perfilService: PerfilService, private tokenService: TokenStorageService, private ref: ChangeDetectorRef, private router: Router, private route: ActivatedRoute, public permissions: PermissionService, private emisoresService: EmisorService) {
      this.userId = this.tokenService.getUser().id;
      this.fullListPermission = this.tokenService.getUser().superAdmin; //this.permissions.getPermission("10-10").asignado;
      (this.emisorId = this.tokenService.getCompany()), (this.profileFilter.activo = 1);
      this.profileFilter.nombre = "";
      this.profileFilter.emisorid = this.emisorId;
      this.profileFilter.usuarioId = this.userId;
      if (this.fullListPermission) {
         this.isSuperAdmin = this.tokenService.getUser().superAdmin;
         this.getFullEmisoresList();
      }
   }

   ngOnInit(): void {
      this.setPageProfile({ offset: 0 });
   }
   setPageSize() {
      //this.page.currentPageSize = this.page.page
      this.setPageProfile({ offset: 0 });
   }
   setPageProfile(pageInfo) {
      this.loading = true;
      this.page.pageNumber = pageInfo.offset;
      this.page.pageNumber += 1;

      this.perfilService
         .getProfilesDataByPage(this.profileFilter, this.page)
         .subscribe(
            (pagedData) => {
               let elements = pagedData.data as Perfil[];
               //console.log(elements);
               this.ref.detectChanges();
               elements.forEach((row) => {});
               this.rowsMantenedorProfile = [...elements];
               this.setPageProfilefromResponse(this.page, pagedData);
               this.loading = false;
               this.ref.detectChanges();
            },
            (error) => {
               this.loading = false;
               this.currentSwal.icon = "error";
               this.currentSwal.title = "Error en la solicitud";
               this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
               this.currentSwal.fire();
            },
            () => {
               this.loading = false;
               this.componentStarted = true;
               this.ref.detectChanges();
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
         });
   }
   setPageProfilefromResponse(currentPage: Page, data: PagedResponse<Perfil>) {
      currentPage.pageSize = data.pageSize;
      currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
      currentPage.totalPages = data.totalPages;
      currentPage.totalElements = data.totalRecords;
   }

   profileSearchElements(nombre: string) {
      this.loading = true;
      this.perfilService.getProfilesDataByPage(this.profileFilter, this.page).subscribe(
         (result) => {
            this.loading = false;
            let elements = result.data as Perfil[];
            //console.log(elements);
            elements.forEach((row) => {});
            this.rowsMantenedorProfile = [...elements];
         },
         (error) => {
            this.loading = false;
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error";
            this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
            this.currentSwal.fire();
         },
         () => {
            this.loading = false;
            this.ref.detectChanges();
         }
      );
   }

   changeSearchedRowsByProfile(data) {
      if (data != null) {
         this.rowsMantenedorProfile = data;
      }
   }

   filterConcordanceByProfile(configCriterias, planFilter: PerfilFilter, eventFilter): PerfilFilter {
      configCriterias.forEach((criterio) => {
         if (criterio != eventFilter.criterio) {
            planFilter[criterio] = "";
         }
      });
      return planFilter;
   }

   searchElementsByProfileFilter(event) {
      this.profileFilter = this.filterConcordanceByProfile(this.configLocal.barraBusqueda.criteriosKeys, this.profileFilter, event);
      this.profileFilter.nombre = event.nombre != null ? event.nombre : "";
      this.profileFilter.activo = event.registroActivo ? 0 : 1;
      this.profileFilter[event.criterio] = event.busqueda;
      this.profileFilter = this.utilities.clearOtherFilterCriterias(event.criterio, this.profileFilter, this.configLocal);
      this.profileFilter.emisorid = event.emisorId;
      this.profileFilter.usuarioId = this.userId;
      this.setPageProfile({ offset: 0 });
   }
   setPageSizePermission() {
      //this.page.currentPageSize = this.page.page
      this.setPagePermission({ offset: 0 });
   }
   setPagePermission(pageInfo) {
      this.loading = true;
      this.pagePermission.pageNumber = pageInfo.offset;
      this.pagePermission.pageNumber += 1;

      this.perfilService
         .getPermissionsDataByPage(this.permissionFilter, this.pagePermission)
         .subscribe(
            (pagedData) => {
               let elements = pagedData.data as Permiso[];
               //console.log(elements);
               this.ref.detectChanges();
               elements.forEach((row) => {});
               this.rowsMantenedorPermission = [...elements];
               this.setPagePermissionfromResponse(this.pagePermission, pagedData);
               this.loading = false;
               this.ref.detectChanges();
            },
            (error) => {
               this.loading = false;
               this.currentSwal.icon = "error";
               this.currentSwal.title = "Error en la solicitud";
               this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
               this.currentSwal.fire();
            },
            () => {
               this.loading = false;
               this.ref.detectChanges();
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
         });
   }
   setPagePermissionfromResponse(currentPage: Page, data: PagedResponse<Permiso>) {
      currentPage.pageSize = data.pageSize;
      currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
      currentPage.totalPages = data.totalPages;
      currentPage.totalElements = data.totalRecords;
   }

   permissionSearchElements(nombre: string) {
      this.loading = true;
      this.perfilService.getPermissionsDataByPage(this.permissionFilter, this.pagePermission).subscribe(
         (result) => {
            this.loading = false;
            let elements = result.data as Permiso[];
            //console.log(elements);
            elements.forEach((row) => {});
            this.rowsMantenedorPermission = [...elements];
         },
         (error) => {
            this.loading = false;
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error";
            this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
            this.currentSwal.fire();
         },
         () => {
            this.loading = false;
            this.ref.detectChanges();
         }
      );
   }

   changeSearchedRowsByPermission(data) {
      if (data != null) {
         this.rowsMantenedorPermission = data;
      }
   }

   filterConcordanceByPermission(configCriterias, permissionFilter: PermisoFilter, eventFilter): PermisoFilter {
      configCriterias.forEach((criterio) => {
         if (criterio != eventFilter.criterio) {
            permissionFilter[criterio] = "";
         }
      });
      return permissionFilter;
   }

   searchElementsByPermissionFilter(event) {
      this.permissionFilter = this.filterConcordanceByPermission(this.configLocal.barraBusqueda.criteriosKeys, this.permissionFilter, event);
      this.permissionFilter.nombre = event.nombre != null ? event.nombre : "";
      this.permissionFilter.codigo = event.codigo != null ? event.codigo : "";
      this.permissionFilter.activo = event.registroActivo ? 0 : 1;
      this.permissionFilter[event.criterio] = event.busqueda;
      this.setPagePermission({ offset: 0 });
   }

   exportToExcel() {
      this.getAllRowsByFilter(this.page, this.profileFilter);
   }

   getAllRowsByFilter(currentPageRef: Page, currentFilter: PerfilFilter) {
      let fullPage: Page = new Page();
      fullPage.pageSize = currentPageRef.totalElements;
      fullPage.pageNumber = 0;
      fullPage.totalElements = currentPageRef.totalElements;
      fullPage.totalPages = 1;
      this.loading = true;
      this.perfilService.getProfilesDataByPage(currentFilter, fullPage).subscribe(
         (result) => {
            let elements = result.data as Perfil[];
            this.ref.detectChanges();
            elements.forEach((row) => {});
            this.loading = false;
            this.ref.detectChanges();
            this.exportExcelData(elements);
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al exportar";
            this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. ";
            if (error.error.message) {
               this.currentSwal.text += this.utilities.setErrorMessageFromArray(error.error);
            }
         },
         () => {
            this.loading = false;
            this.ref.detectChanges();
         }
      );
   }

   exportExcelData(arrayToExport: Array<Perfil>): void {
      let arregloExportar = [];
      arrayToExport.forEach((item) => {
         let elementToExport = {};
         elementToExport["Nombre"] = item.nombre;
         arregloExportar.push(elementToExport);
      });
      this.utilities.exportAsExcelFile(arregloExportar, "Perfiles");
   }
   detailModal(modalRef, row: Perfil) {
      this.loading = true;
      this.profileToShow = row;
      this.loading = false;
      this.ref.detectChanges();
      this.detailProfileModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }
   profilePermissionModal(modalRef, row: Perfil) {
      this.loading = true;
      this.perfilService.getPermissions("profile", row.perfilId).subscribe(
         (success) => {
            this.loading = false;
            this.ref.detectChanges();
            this.profileToShow.perfilId = row.perfilId;
            this.profileToShow.nombre = row.nombre;
            this.profileToShow.permisos = success.data;
            this.PermissionsModal = this.modalService.open(modalRef, {
               windowClass: "",
               size: "lg",
               centered: true,
            });
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Obtener Permisos";
            this.currentSwal.text = "No fue posible obtener permisos del usuario " + this.profileToShow.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }
   updatePermissions(addform: NgForm) {
      this.loading = true;
      this.perfilService.updatePermissionsProfile(this.profileToShow).subscribe(
         (res) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Perfil Actualizado",
               showCloseButton: true,
               html: "<div style='text-align:center;'><p>" + "El perfil " + this.profileToShow.nombre + " fue actualizado" + "</p></div>",
            });
            this.PermissionsModal.close();
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Actualizar Permisos";
            this.currentSwal.text = "No fue posible actualizar los permisos del perfil " + this.profileToShow.nombre + ". Intenta más tarde" + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }
   changeValueChbx(e, item: Permiso) {
      if (e.target.checked) {
         item.asignado = true;
      }
      if (!e.target.checked) {
         item.asignado = false;
      }
      //console.log(e.target.checked,item);
   }

   MantenedorPermissionModal(modalRef) {
      this.loading = true;
      this.permissionFilter.nombre = "";
      this.permissionFilter.codigo = "";
      this.permissionFilter.activo = 1;
      this.loading = false;
      this.ref.detectChanges();

      this.setPagePermission({ offset: 0 });
      this.PermissionsModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   deactivateProfile(row: Perfil) {
      this.loading = true;
      this.perfilService.deactivateProfile(row.perfilId).subscribe(
         (res) => {
            if (res.data) {
               this.loading = false;
               this.ref.detectChanges();
               Swal.fire({
                  icon: "success",
                  title: "Perfil Desactivado",
                  showCloseButton: true,
                  html: "<div style='text-align:center;'><p>" + "Perfil " + row.nombre + " Desactivado" + "</p></div>",
               });

               this.setPageProfile({ offset: 0 });
            }
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Desactivar";
            this.currentSwal.text = "No fue posible desactivar el perfil " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   activateProfile(row: Perfil) {
      this.loading = true;
      this.perfilService.activateProfile(row.perfilId).subscribe(
         (res) => {
            if (res.data) {
               Swal.fire({
                  icon: "success",
                  title: "Perfil Activado",
                  showCloseButton: true,
                  html: "<div style='text-align:center;'><p>" + "Perfil " + row.nombre + " Activado" + "</p></div>",
               });
               this.setPageProfile({ offset: 0 });

               this.loading = false;
               this.ref.detectChanges();
            }
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Activar";
            this.currentSwal.text = "No fue posible activar el perfil " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   deactivatePermission(row: Permiso) {
      this.loading = true;
      this.perfilService.deactivatePermission(row.permisoId).subscribe(
         (res) => {
            if (res.data) {
               this.loading = false;
               this.ref.detectChanges();
               Swal.fire({
                  icon: "success",
                  title: "Permiso Desactivado",
                  showCloseButton: true,
                  html: "<div style='text-align:center;'><p>" + "Permiso " + row.nombre + " Desactivado" + "</p></div>",
               });

               this.setPagePermission({ offset: 0 });
            }
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Desactivar";
            this.currentSwal.text = "No fue posible desactivar el permiso " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   activatePermission(row: Permiso) {
      this.loading = true;
      this.perfilService.activatePermission(row.permisoId).subscribe(
         (res) => {
            if (res.data) {
               Swal.fire({
                  icon: "success",
                  title: "Permiso Activado",
                  showCloseButton: true,
                  html: "<div style='text-align:center;'><p>" + "Permiso " + row.nombre + " Activado" + "</p></div>",
               });
               this.setPagePermission({ offset: 0 });

               this.loading = false;
               this.ref.detectChanges();
            }
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Activar";
            this.currentSwal.text = "No fue posible activar el permiso " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }
   editPermissionModal(modalRef, item: Permiso) {
      this.loading = true;
      this.permissionToEdit = new Permiso();
      this.permissionToEdit.activo = item.activo;
      this.permissionToEdit.asignado = item.asignado;
      this.permissionToEdit.codigo = item.codigo;
      this.permissionToEdit.nombre = item.nombre;
      this.permissionToEdit.permisoId = item.permisoId;
      this.loading = false;
      this.ref.detectChanges();
      this.editPermissionsModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   editPermission(form: NgForm) {
      this.loading = true;
      this.perfilService.updatePermission(this.permissionToEdit).subscribe(
         (res) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Permiso Actualizado",
               showCloseButton: true,
               html: "<div style='text-align:center;'><p>" + "El permiso " + this.permissionToEdit.nombre + " fue actualizado" + "</p></div>",
            });
            this.setPagePermission({ offset: 0 });
            this.editPermissionsModal.close();
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Actualizar Permiso";
            this.currentSwal.text = "No fue posible actualizar el permiso " + this.permissionToEdit.nombre + ", " + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   addPermissionModal(modalRef) {
      this.loading = true;
      this.permissionToAdd = new Permiso();
      this.loading = false;
      this.ref.detectChanges();
      this.addNewPermissionModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   addPermission(modalRef) {
      this.loading = true;
      this.perfilService.insertPermission(this.permissionToAdd).subscribe(
         (res) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Permiso Agregado",
               showCloseButton: true,
               html: "<div style='text-align:center;'><p>" + "El permiso " + this.permissionToAdd.nombre + " fue agregado" + "</p></div>",
            });
            this.setPagePermission({ offset: 0 });
            this.addNewPermissionModal.close();
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al agregar Permiso";
            this.currentSwal.text = "No fue posible agregar el permiso " + this.permissionToAdd.nombre + ", " + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   editModal(modalRef, item: Perfil) {
      this.loading = true;
      this.profileToEdit = new Perfil();
      this.profileToEdit.activo = item.activo;
      this.profileToEdit.nombre = item.nombre;
      this.profileToEdit.perfilId = item.perfilId;
      this.profileToEdit.isSuperAdmin = item.isSuperAdmin;
      this.loading = false;
      this.ref.detectChanges();
      this.editProfileModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   editProfile(form: NgForm) {
      this.loading = true;
      this.perfilService.updateProfile(this.profileToEdit).subscribe(
         (res) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Perfil Actualizado",
               showCloseButton: true,
               html: "<div style='text-align:center;'><p>" + "El perfil " + this.profileToEdit.nombre + " fue actualizado" + "</p></div>",
            });
            this.setPageProfile({ offset: 0 });
            this.editProfileModal.close();
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Actualizar Perfil";
            this.currentSwal.text = "No fue posible actualizar el perfil " + this.profileToEdit.nombre + ", " + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   addProfileModal(modalRef) {
      this.loading = true;
      this.profileToAdd = new Perfil();
      this.loading = false;
      this.ref.detectChanges();
      this.addNewProfileModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   addProfile(modalRef) {
      this.loading = true;
      this.perfilService.insertProfile(this.profileToAdd, this.emisorId).subscribe(
         (res) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Perfil Agregado",
               showCloseButton: true,
               html: "<div style='text-align:center;'><p>" + "El perfil " + this.profileToAdd.nombre + " fue agregado" + "</p></div>",
            });
            this.setPageProfile({ offset: 0 });
            this.addNewProfileModal.close();
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al agregar Perfil";
            this.currentSwal.text = "No fue posible agregar el perfil " + this.profileToAdd.nombre + ", " + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   permissionList(row: Perfil) {
      //this.router.navigate(['../',row.nombre,'permisos'])
      this.loading = true;
      this.perfilService.getPermissions("profile", row.perfilId).subscribe(
         (success) => {
            this.loading = false;
            this.ref.detectChanges();
            row.perfilId = row.perfilId;
            row.nombre = row.nombre;
            row.permisos = success.data;
            this.router.navigateByUrl("/perfiles/permisos", {
               state: { rowProfile: JSON.stringify(row) },
            });
         },
         (err) => {
            this.loading = false;
            this.ref.detectChanges();
            this.currentSwal.icon = "error";
            this.currentSwal.title = "Error al Obtener Permisos";
            this.currentSwal.text = "No fue posible obtener permisos del usuario " + this.profileToShow.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
            this.currentSwal.fire();
         }
      );
   }

   openAsignarPerfilModal(modalRef, row) {
      this.selectedPerfil = row;
      this.asignarPerfilModal = this.modalService.open(modalRef, {
         windowClass: "",
         size: "lg",
         centered: true,
      });
   }

   closeAsignarPerfilModal() {
      this.asignarPerfilModal.close();
   }

   toggleExpandRow(row) {
      this.tableRef.rowDetail.toggleExpandRow(row);
   }

   getFullEmisoresList() {
      this.loading = true;
      this.emisoresService.getData().subscribe(
         (result) => {
            let emisores = result.data;
            // let emisorService = new Emisor();
            // emisorService.razonSocial = "SERVICES";
            // emisorService.emisorId = null;
            // emisores.push(emisorService);
            this.emisoresList = emisores;
            this.ref.detectChanges();
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error en emisores",
               text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }
}
