<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
<swal #notesSwal title="Operación" text="Operación realizada" icon="info"></swal>

<!-- <h1>{{fileIdValue ? fileIdValue: 'Sin id por ruta'}}</h1> -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"> </ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar [criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal" [config]="configLocal.barraBusqueda" (busqueda)="searchElementsByFilter($event)" [registroActivacion]="true" [useAccordionMode]="true" [useInlineDeactivatedCheckbox]="true" [useInlineSearchButton]="true"></app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">{{ configLocal.table.title }}</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar" *ngIf="permissions.getPermission('30-80-14').asignado">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
								</div>

								<!-- <div class="heading-elements mt-0">                                   
                                    <button class="btn btn-success float-right mr-5 mt-5" 
                                    (click)="addEmisorModal(addEmisorM)"
                                       *ngIf="permissions.getPermission('30-80-10').asignado">
                                        <i class="flaticon-add-circular-button"></i>
                                        Agregar Emisor
                                    </button>
                                </div> -->
								<div class="heading-elements mt-0">
									<button class="btn btn-info float-right mr-5 mt-5" (click)="router.navigate(['/crear-emisor'])" *ngIf="permissions.getPermission('30-80-22').asignado">
										<i class="flaticon-add-circular-button"></i>
										Agregar Empresa
									</button>
								</div>
							</div>
							<!-- <div class="col-1" >
                              <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change) ="setPageSize()">
                                 <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                              </ng-select>
                         </div> -->
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="80"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>

										<ngx-datatable-column name="rutString" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Rut</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.rutString
														? row.rutString
														: "No
                                       disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="RazonSocial" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Razón Social</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.razonSocial }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Giro" [flexGrow]="4" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Giro</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{
													row.giro
														? row.giro
														: "No
                                       disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Ciudad" [flexGrow]="1.8" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Ciudad</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.ciudad ? row.ciudad : "No disponible" }}</span>
											</ng-template>
										</ngx-datatable-column>
										<!-- <ngx-datatable-column name="Comuna" [flexGrow]="1.8" [sortable]="false" [resizeable]="false">
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <span class="text-table-alignment">Comuna</span>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-row="row"><span class="text-table-alignment">{{row.comuna?row.comuna:"No disponible"}}</span></ng-template>
                                    </ngx-datatable-column> -->
										<ngx-datatable-column name="Ambiente" [flexGrow]="2.5" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Ambiente</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.ambiente != null
														? row.nombreAmbiente
														: "No
                                       disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Detalles Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Detalles_Emisor" (click)="detailModal(detailEmisorModal, row)" *ngIf="permissions.getPermission('30-80-16').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Editar Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Editar_Emisor" (click)="editModal(editEmisorM, row)" *ngIf="permissions.getPermission('30-80-12').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<div ngbDropdown class="dropdownBotoneraLinea" container="body">
													<a ngbDropdownToggle id="dropdownConfig" container="body" [routerLink]="" class="primary edit mr-1 mrless" ngbTooltip="Configuración Emisor" *ngIf="permissions.getPermission('30-80-18').asignado || permissions.getPermission('30-80-19').asignado || permissions.getPermission('30-80-21').asignado || permissions.getPermission('30-80-23').asignado">
														<div class="icon-circle bg-success text-center align-middle pt-info-button">
															<fa-icon icon="cog" class="fa-lg text-white"></fa-icon>
														</div>
													</a>

													<div ngbDropdownMenu aria-labelledby="dropdownConfig">
														<button ngbDropdownItem (click)="getBranchOfficesByEmisor(SucursalesModal, row)" *ngIf="permissions.getPermission('30-80-18').asignado">Sucursales</button>
														<button ngbDropdownItem (click)="openEconomicActivitiesModal(economicActivitiesEmisor, row)" *ngIf="permissions.getPermission('30-80-19').asignado">Actividades Economicas</button>
														<button ngbDropdownItem (click)="openAuthorizedPersonsModal(authorizedPersonsEmisor, row)" *ngIf="permissions.getPermission('30-80-21').asignado">Personas Autorizadas</button>
														<button ngbDropdownItem (click)="openPasswordEditModal(editPasswordModal, row)" *ngIf="permissions.getPermission('30-80-30')">{{ row.tienePassSii ? "Modificar" : "Agregar" }} Password de S.I.I.</button>
														<button ngbDropdownItem (click)="changeAmbient(row)" *ngIf="permissions.getPermission('30-80-32')">Cambio de Ambiente S.I.I.</button>
													</div>
												</div>
												<a [routerLink]="" class="success edit mr-1 mrless" ngbTooltip="Agregar Logo" (click)="openEmisorLogosList(addLogoModal, row)" *ngIf="permissions.getPermission('30-80-28').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="file-image" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<!-- <a [routerLink]="" class="primary edit mr-1 mrless"
                                          ngbToolTip="Modificar Password"
                                          (click)="openPasswordEditModal(editPasswordModal,row)"
                                          >
                                          <div class="icon-circle bg-primary text-center align-middle pt-info-button">
                                             <fa-icon icon="key" class="fa-lg text-white"></fa-icon>
                                          </div>
                                       </a> -->

												<a [routerLink]="" ngbTooltip="Desactivar Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Desactivar_Emisor" *ngIf="row.activo && permissions.getPermission('30-80-20').asignado">
													<div
														class="icon-circle bg-danger text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea desactivar este emisor?',
															confirmButtonText: 'Sí, desactivar',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="deactivate(row)">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Activar Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Activar_Emisor" *ngIf="!row.activo && permissions.getPermission('30-80-20').asignado">
													<div
														class="icon-circle bg-success text-center align-middle pt-info-button"
														[swal]="{
															title: 'Confirmación',
															text: '¿Está seguro que desea reactivar este emisor?',
															confirmButtonText: 'Sí, reactivar',
															cancelButtonText: 'Cancelar',
															showCancelButton: true,
															confirmButtonColor: '#3699FF',
															cancelButtonColor: '#f4516c'
														}"
														(confirm)="activate(row)">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<span class="badge badge-light font-italix" ngbTooltip="Gestionado por: {{ row.reseller.razonSocial }}" *ngIf="row.resellerId && row.reseller">Reseller</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">
													Rut: {{ row.rutString ? row.rutString : "No disponible" }} ,Giro: {{ row.giro ? row.giro : "No disponible" }} , Ciudad:
													{{
														row.ciudad
															? row.ciudad
															: "No
                                       disponible"
													}}
													, Comuna: {{ row.comuna ? row.comuna : "No disponible" }} , Ambiente: {{ row.ambiente != null ? row.nombreAmbiente : "No disponible" }}
												</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Razón Social</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.razonSocial }}</span></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="9.5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Detalles Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Detalles_Emisor" (click)="detailModal(detailEmisorModal, row)" *ngIf="permissions.getPermission('30-80-16').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a [routerLink]="" ngbTooltip="Editar Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Editar_Emisor" (click)="editModal(editEmisorM, row)" *ngIf="permissions.getPermission('30-80-12').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Sucursales" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Sucursales" (click)="getBranchOfficesByEmisor(SucursalesModal, row)" *ngIf="permissions.getPermission('30-80-18').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="store" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Actividades Económicas" id="mantenedor-emisores.component_Actividades_Económicas" (click)="openEconomicActivitiesModal(economicActivitiesEmisor, row)" *ngIf="permissions.getPermission('30-80-19').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="hand-holding-usd" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Personas Autorizadas" id="mantenedor-emisores.component_Personas_Autorizadas" (click)="openAuthorizedPersonsModal(authorizedPersonsEmisor, row)" *ngIf="permissions.getPermission('30-80-21').asignado">
													<div class="icon-circle bg-primary text-center align-middle pt-info-button">
														<fa-icon icon="id-badge" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<!-- <a [routerLink]="" ngbTooltip="Desactivar Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Desactivar_Emisor"
                                                                              (click)="deactivate(row)"
                                                                               *ngIf="row.activo && permissions.getPermission('30-80-20').asignado">
                                                                              <div class="icon-circle bg-danger text-center align-middle pt-info-button">
                                                                                 <fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
                                                                              </div>
                                                                           </a>
                                                                           <a [routerLink]="" ngbTooltip="Activar Emisor" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Activar_Emisor"
                                                                           (click)="activate(row)"
                                                                               *ngIf="!row.activo && permissions.getPermission('30-80-20').asignado">
                                                                              <div class="icon-circle bg-success text-center align-middle pt-info-button">
                                                                                 <fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
                                                                              </div>
                                                                           </a> -->
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #detailEmisorModal let-d="dismiss">
	<form #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Detalles del Emisor {{ emisorToShow.razonSocial }}</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Rut</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="rut"
							placeholder="Ingresar rut"
							class="form-control"
							#newRut="ngModel"
							[(ngModel)]="emisorToShow.rutString"
							[ngClass]="{
								'is-invalid': editForm.submitted && newRazonSocial.invalid
							}"
							required />
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Razón Social</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="razonSocial"
							placeholder="Ingresar una razón social"
							class="form-control"
							#newRazonSocial="ngModel"
							[(ngModel)]="emisorToShow.razonSocial"
							[ngClass]="{
								'is-invalid': editForm.submitted && newRazonSocial.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newRazonSocial.invalid" class="invalid-feedback-group">
							<div *ngIf="newRazonSocial.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Rut de Representante Legal</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="rutRepresentanteLegal"
							placeholder="Ingresar rut de representante"
							class="form-control"
							#newRutRepresentante="ngModel"
							[(ngModel)]="emisorToShow.representanteLegalRutString"
							[ngClass]="{
								'is-invalid': editForm.submitted && newRazonSocial.invalid
							}"
							required />
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Giro</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="giro"
							placeholder="Ingresar giro"
							class="form-control"
							#newGiro="ngModel"
							[(ngModel)]="emisorToShow.giro"
							[ngClass]="{
								'is-invalid': editForm.submitted && newGiro.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newGiro.invalid" class="invalid-feedback-group">
							<div *ngIf="newGiro.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Ciudad</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="ciudad"
							placeholder="Ingresar ciudad"
							class="form-control"
							#newCiudad="ngModel"
							[(ngModel)]="emisorToShow.ciudad"
							[ngClass]="{
								'is-invalid': editForm.submitted && newCiudad.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newCiudad.invalid" class="invalid-feedback-group">
							<div *ngIf="newCiudad.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Comuna</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="comuna"
							placeholder="Ingresar comuna"
							class="form-control"
							#newComuna="ngModel"
							[(ngModel)]="emisorToShow.comuna"
							[ngClass]="{
								'is-invalid': editForm.submitted && newComuna.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newComuna.invalid" class="invalid-feedback-group">
							<div *ngIf="newComuna.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Telefono</label>
					<div class="col-9">
						<input
							disabled
							oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
							maxlength="9"
							min="1"
							type="number"
							name="telefono"
							placeholder="Ingresar telefono"
							class="form-control"
							#newTelefono="ngModel"
							[(ngModel)]="emisorToShow.telefono"
							[ngClass]="{
								'is-invalid': editForm.submitted && newTelefono.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newTelefono.invalid" class="invalid-feedback-group">
							<div *ngIf="newTelefono.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección Facturación</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="dirFact"
							placeholder="Ingresar dirección facturación"
							class="form-control"
							#newDirFact="ngModel"
							[(ngModel)]="emisorToShow.dirFact"
							[ngClass]="{
								'is-invalid': editForm.submitted && newDirFact.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newDirFact.invalid" class="invalid-feedback-group">
							<div *ngIf="newDirFact.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Correo Facturación</label>
					<div class="col-9">
						<input
							disabled
							pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
							type="email"
							name="correoFact"
							placeholder="Ingresar correo facturación"
							class="form-control"
							#newCorreoFact="ngModel"
							[(ngModel)]="emisorToShow.correoFact"
							[ngClass]="{
								'is-invalid': editForm.submitted && newCorreoFact.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newCorreoFact.invalid" class="invalid-feedback-group">
							<div *ngIf="newCorreoFact.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Fecha Resolución</label>
					<div class="col-9">
						<input disabled [(ngModel)]="selected" [showDropdowns]="true" type="text" class="form-control" [opens]="'center'" name="fechaResol" ngxDaterangepickerMd [locale]="locale" [singleDatePicker]="true" [autoApply]="true" />
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">N° Resolución</label>
					<div class="col-9">
						<input
							disabled
							type="number"
							name="nroResol"
							placeholder="Ingresar correo facturación"
							class="form-control"
							#newNroResol="ngModel"
							[(ngModel)]="emisorToShow.nroResol"
							[ngClass]="{
								'is-invalid': editForm.submitted && newNroResol.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && newNroResol.invalid" class="invalid-feedback-group">
							<div *ngIf="newNroResol.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Ambiente</label>
					<div class="col-9">
						<ng-select [disabled]="true" [items]="environmentType" [clearable]="false" [searchable]="false" bindLabel="nombre" [selectOnTab]="false" [(ngModel)]="emisorToShow.ambienteEnt" [ngModelOptions]="{ standalone: true }"></ng-select>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>

				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #addEmisorM let-d="dismiss">
	<form (ngSubmit)="addEmisor(addForm)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Agregar Emisor</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Rut</label>
					<div class="col-9">
						<input type="text" name="rut" placeholder="Ingresar rut con formato XXXXXXXX-X" class="form-control" #newRut="ngModel" [(ngModel)]="emisorToAdd.rutString" maxlength="10" appVerificaRut required />
						<div *ngIf="(addForm.submitted && newRut.invalid) || (newRut.invalid && newRut.touched)" class="invalid-feedback-group">
							<div *ngIf="newRut.errors.required">Campo obligatorio</div>
							<div *ngIf="newRut.errors.rutNoValido" class="invalid-feedback-group">Rut Invalido</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Razón Social</label>
					<div class="col-9">
						<input
							type="text"
							name="razonSocial"
							placeholder="Ingresar una razón social"
							class="form-control"
							#newRazonSocial="ngModel"
							[(ngModel)]="emisorToAdd.razonSocial"
							[ngClass]="{
								'is-invalid': addForm.submitted && newRazonSocial.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newRazonSocial.invalid" class="invalid-feedback-group">
							<div *ngIf="newRazonSocial.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Rut Representante Legal</label>
					<div class="col-9">
						<input type="text" name="rut" placeholder="Ingresar rut con formato XXXXXXXX-X" class="form-control" #newRutRepresentante="ngModel" [(ngModel)]="emisorToAdd.representanteLegalRutString" maxlength="10" appVerificaRut required />
						<div *ngIf="(addForm.submitted && newRutRepresentante.invalid) || (newRutRepresentante.invalid && newRutRepresentante.touched)" class="invalid-feedback-group">
							<div *ngIf="newRutRepresentante.errors.required">Campo obligatorio</div>
							<div *ngIf="newRutRepresentante.errors.rutNoValido" class="invalid-feedback-group">Rut Inválido</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Giro</label>
					<div class="col-9">
						<input
							type="text"
							name="giro"
							placeholder="Ingresar giro"
							class="form-control"
							#newGiro="ngModel"
							[(ngModel)]="emisorToAdd.giro"
							[ngClass]="{
								'is-invalid': addForm.submitted && newGiro.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newGiro.invalid" class="invalid-feedback-group">
							<div *ngIf="newGiro.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Ciudad</label>
					<div class="col-9">
						<input
							type="text"
							name="ciudad"
							placeholder="Ingresar ciudad"
							class="form-control"
							#newCiudad="ngModel"
							[(ngModel)]="emisorToAdd.ciudad"
							[ngClass]="{
								'is-invalid': addForm.submitted && newCiudad.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newCiudad.invalid" class="invalid-feedback-group">
							<div *ngIf="newCiudad.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Comuna</label>
					<div class="col-9">
						<input
							type="text"
							name="comuna"
							placeholder="Ingresar comuna"
							class="form-control"
							#newComuna="ngModel"
							[(ngModel)]="emisorToAdd.comuna"
							[ngClass]="{
								'is-invalid': addForm.submitted && newComuna.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newComuna.invalid" class="invalid-feedback-group">
							<div *ngIf="newComuna.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Telefono</label>
					<div class="col-9">
						<input
							oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
							maxlength="9"
							min="1"
							type="number"
							name="telefono"
							placeholder="Ingresar telefono"
							class="form-control"
							#newTelefono="ngModel"
							[(ngModel)]="emisorToAdd.telefono"
							[ngClass]="{
								'is-invalid': addForm.submitted && newTelefono.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newTelefono.invalid" class="invalid-feedback-group">
							<div *ngIf="newTelefono.errors.required">Campo Obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección Facturación</label>
					<div class="col-9">
						<input
							type="text"
							name="dirFact"
							placeholder="Ingresar dirección facturación"
							class="form-control"
							#newDirFact="ngModel"
							[(ngModel)]="emisorToAdd.dirFact"
							[ngClass]="{
								'is-invalid': addForm.submitted && newDirFact.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newDirFact.invalid" class="invalid-feedback-group">
							<div *ngIf="newDirFact.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Correo Facturación</label>
					<div class="col-9">
						<input
							pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
							type="email"
							name="correoFact"
							placeholder="Ingresar correo facturación"
							class="form-control"
							#newCorreoFact="ngModel"
							[(ngModel)]="emisorToAdd.correoFact"
							[ngClass]="{
								'is-invalid': addForm.submitted && newCorreoFact.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newCorreoFact.invalid" class="invalid-feedback-group">
							<div *ngIf="newCorreoFact.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Fecha Resolución</label>
					<div class="col-9">
						<input autocomplete="off" placeholder="Ingresar fecha de resolución" [showDropdowns]="true" type="text" class="form-control" [opens]="'center'" name="fechaResol" ngxDaterangepickerMd [locale]="locale" [singleDatePicker]="true" [autoApply]="true" (change)="detectChangeReferenceDateAdd($event)" />
						<div *ngIf="addForm.submitted && emisorToAdd.fechaResol == undefined" class="invalid-feedback-group">
							<div *ngIf="emisorToAdd.fechaResol == undefined">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">N° Resolución</label>
					<div class="col-9">
						<input
							autocomplete="off"
							type="number"
							name="nroResol"
							placeholder="Ingresar numero de resolución"
							class="form-control"
							#newNroResol="ngModel"
							[(ngModel)]="emisorToAdd.nroResol"
							min="1"
							required
							[ngClass]="{
								'is-invalid': addForm.submitted && newNroResol.invalid
							}" />
						<div *ngIf="addForm.submitted && newNroResol.invalid" class="invalid-feedback-group">
							<div *ngIf="newNroResol.errors.required">Campo obligatorio</div>
							<div *ngIf="newNroResol.errors?.min">Ingrese valor mayor a 0</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Ambiente</label>
					<div class="col-9">
						<ng-select [items]="environmentType" [clearable]="false" [searchable]="false" bindLabel="nombre" [selectOnTab]="false" [(ngModel)]="emisorToAdd.ambienteEnt" aria-required="true" #newEnvironment="ngModel" [ngModelOptions]="{ standalone: true }"></ng-select>
						<div *ngIf="addForm.submitted && newEnvironment.invalid">
							<div *ngIf="newEnvironment.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>

				<div class="row new-logo-container mb-3" *ngIf="logoRequest.logoFile">
					<div class="col-9 offset-sm-3">
						<img [src]="'data:image/png;base64,' + logoRequest.logoFile" alt="logo cargado" class="img-thumbnail" />
					</div>
				</div>
				<div class="form-group position-relative mx-auto">
					<div class="row">
						<label for="" class="pl-6 col-3 label-control">Logo</label>
						<div class="col-9">
							<ngx-dropzone class="mx-auto" (change)="onSelect($event)" maxFileSize="2097152" accept="image/jpg,image/jpeg,image/png" multiple="false">
								<ngx-dropzone-label class="px-5">
									<i class="flaticon-upload"></i>
									<p>Arrastra o selecciona un archivo con la imagen del logo a utilizar</p>

									<p>El formato del archivo debe ser .png o .jpg</p>
								</ngx-dropzone-label>
								<!-- <ngx-dropzone-label class="d-inline">
                                      
                                   </ngx-dropzone-label> -->
								<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
									<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
								</ngx-dropzone-preview>
							</ngx-dropzone>

							<!-- <div *ngIf="addForm.submitted  && logoRequest.logoCompleteFile == undefined"
                           class="invalid-feedback">
                           <div *ngIf="logoRequest.logoCompleteFile == undefined">Campo requerido</div>
                       </div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #editEmisorM let-d="dismiss">
	<form (ngSubmit)="editEmisor(editForm)" #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Editar Emisor</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Rut</label>
					<div class="col-9">
						{{ emisorToEdit.rutString }}
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Razón Social</label>
					<div class="col-9">
						<input
							type="text"
							name="razonSocial"
							placeholder="Ingresar una razón social"
							class="form-control"
							#newRazonSocial="ngModel"
							[(ngModel)]="emisorToEdit.razonSocial"
							[ngClass]="{
								'is-invalid': editForm.submitted && newRazonSocial.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newRazonSocial.invalid" class="invalid-feedback-group">
							<div *ngIf="newRazonSocial.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Rut Representante Legal</label>
					<div class="col-9">
						<input
							type="text"
							name="representanteLegal"
							placeholder="Ingresar rut representante legal"
							class="form-control"
							#newRutRepresentante="ngModel"
							[(ngModel)]="emisorToEdit.representanteLegalRutString"
							[ngClass]="{
								'is-invalid': editForm.submitted && newRutRepresentante.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newRutRepresentante.invalid" class="invalid-feedback-group">
							<div *ngIf="newRutRepresentante.errors.required">Campo obligatorio</div>
							<div *ngIf="newRutRepresentante.errors.rutNoValido" class="invalid-feedback-group">Rut Inválido</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Giro</label>
					<div class="col-9">
						<input
							type="text"
							name="giro"
							placeholder="Ingresar giro"
							class="form-control"
							#newGiro="ngModel"
							[(ngModel)]="emisorToEdit.giro"
							[ngClass]="{
								'is-invalid': editForm.submitted && newGiro.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newGiro.invalid" class="invalid-feedback-group">
							<div *ngIf="newGiro.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Ciudad</label>
					<div class="col-9">
						<input
							type="text"
							name="ciudad"
							placeholder="Ingresar ciudad"
							class="form-control"
							#newCiudad="ngModel"
							[(ngModel)]="emisorToEdit.ciudad"
							[ngClass]="{
								'is-invalid': editForm.submitted && newCiudad.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newCiudad.invalid" class="invalid-feedback-group">
							<div *ngIf="newCiudad.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Comuna</label>
					<div class="col-9">
						<input
							type="text"
							name="comuna"
							placeholder="Ingresar comuna"
							class="form-control"
							#newComuna="ngModel"
							[(ngModel)]="emisorToEdit.comuna"
							[ngClass]="{
								'is-invalid': editForm.submitted && newComuna.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newComuna.invalid" class="invalid-feedback-group">
							<div *ngIf="newComuna.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Telefono</label>
					<div class="col-9">
						<input
							oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
							maxlength="9"
							min="1"
							type="number"
							name="telefono"
							placeholder="Ingresar telefono"
							class="form-control"
							required
							#editTelefono="ngModel"
							[(ngModel)]="emisorToEdit.telefono"
							[ngClass]="{
								'is-invalid': editForm.submitted && editTelefono.invalid
							}" />
						<div *ngIf="editForm.submitted && editTelefono.invalid" class="invalid-feedback-group">
							<div *ngIf="editTelefono.errors.required">Campo Obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección Facturación</label>
					<div class="col-9">
						<input
							type="text"
							name="dirFact"
							placeholder="Ingresar dirección facturación"
							class="form-control"
							#newDirFact="ngModel"
							[(ngModel)]="emisorToEdit.dirFact"
							[ngClass]="{
								'is-invalid': editForm.submitted && newDirFact.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newDirFact.invalid" class="invalid-feedback-group">
							<div *ngIf="newDirFact.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Correo Facturación</label>
					<div class="col-9">
						<input
							pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
							type="email"
							name="correoFact"
							placeholder="Ingresar correo facturación"
							class="form-control"
							#newCorreoFact="ngModel"
							[(ngModel)]="emisorToEdit.correoFact"
							[ngClass]="{
								'is-invalid': editForm.submitted && newCorreoFact.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newCorreoFact.invalid" class="invalid-feedback-group">
							<div *ngIf="newCorreoFact.errors.required">Campo obligatorio</div>
							<div *ngIf="newCorreoFact.errors.pattern">Correo no válido</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Fecha Resolución</label>
					<div class="col-9">
						<input [(ngModel)]="selected" #editedResolDate="ngModel" [showDropdowns]="true" type="text" class="form-control" [opens]="'center'" name="fechaResol" ngxDaterangepickerMd [locale]="locale" [singleDatePicker]="true" [autoApply]="true" (change)="detectChangeReferenceDateEdit($event)" />
						<div *ngIf="(editForm.submitted || editForm.touched) && !validDate" class="text-danger">
							<div>Fecha inválida</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">N° Resolución</label>
					<div class="col-9">
						<input
							type="number"
							name="nroResol"
							placeholder="Ingresar correo facturación"
							class="form-control"
							#newNroResol="ngModel"
							[(ngModel)]="emisorToEdit.nroResol"
							[ngClass]="{
								'is-invalid': editForm.submitted && newNroResol.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && newNroResol.invalid" class="invalid-feedback-group">
							<div *ngIf="newNroResol.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Unidad SII</label>
					<div class="col-9">
						<input
							type="text"
							name="unidadSII"
							placeholder="Unidad SII al que pertenece la empresa"
							class="form-control"
							#editUnidadSII="ngModel"
							[(ngModel)]="emisorToEdit.unidadSII"
							[ngClass]="{
								'is-invalid': editForm.submitted && editUnidadSII.invalid
							}"
							required />
						<div *ngIf="(editForm.submitted || editForm.touched) && editUnidadSII.invalid" class="invalid-feedback-group">
							<div *ngIf="editUnidadSII.errors.required">Campo obligatorio</div>
							<div *ngIf="editUnidadSII.errors.pattern">Correo no válido</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="editForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #SucursalesModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Sucursales del Emisor {{ emisorToEdit.razonSocial }}</h5>

			<button class="btn btn-warning float-right mr-5 mt-5" (click)="addBranchOfficeModal(addSucursalM, emisorToEdit)" *ngIf="permissions.getPermission('30-90-10').asignado">
				<i class="flaticon-add-circular-button"></i>
				Agregar Sucursal
			</button>
		</div>
		<div class="heading-elements mt-0">
			<app-search-bar [criterioPrincipal]="configLocalSucursal.barraBusqueda.criterioPrincipal" [config]="configLocalSucursal.barraBusqueda" (busqueda)="searchElementsBySucursalFilter($event)" [filtroEstadoPlan]="true" [insideModal]="true"></app-search-bar>
		</div>
		<div class="modal-body">
			<!-- <h4>Sucursales Asignadas</h4> -->

			<ngx-datatable #table class="material" [rows]="rowsMantenedorSucursal" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [externalPaging]="true" [count]="pageSucursal.totalElements" [offset]="pageSucursal.pageNumber" [limit]="pageSucursal.pageSize" (page)="setPageSucursal($event)" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
				<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false"> </ngx-datatable-column>

				<ngx-datatable-column name="Nombre" [flexGrow]="3" [sortable]="false" [resizeable]="false">
					<ng-template let-column="column" ngx-datatable-header-template>
						<span class="text-table-alignment">Nombre</span>
					</ng-template>
					<ng-template ngx-datatable-cell-template let-row="row"
						><span class="text-table-alignment">{{ row.nombre }} </span></ng-template
					>
				</ngx-datatable-column>
				<ngx-datatable-column name="Direccion" [flexGrow]="5" [sortable]="false" [resizeable]="false">
					<ng-template let-column="column" ngx-datatable-header-template>
						<span class="text-table-alignment">Dirección</span>
					</ng-template>
					<ng-template ngx-datatable-cell-template let-row="row"
						><span class="text-table-alignment">{{ row.direccion }} </span></ng-template
					>
				</ngx-datatable-column>

				<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
					<ng-template ngx-datatable-cell-template let-row="row">
						<a [routerLink]="" ngbTooltip="Detalles Sucursal" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Detalles_Sucursal" (click)="detailBranchOfficeModal(detailSucursalM, row)" *ngIf="permissions.getPermission('30-90-14').asignado">
							<div class="icon-circle bg-warning text-center align-middle pt-info-button">
								<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
							</div>
						</a>
						<a [routerLink]="" ngbTooltip="Editar Sucursal" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Editar_Sucursal" (click)="editBranchOfficeModal(editSucursalM, row)" *ngIf="permissions.getPermission('30-90-12').asignado">
							<div class="icon-circle bg-info text-center align-middle pt-info-button">
								<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
							</div>
						</a>
						<a [routerLink]="" ngbTooltip="Desactivar Sucursal" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Desactivar_Sucursal" (click)="deactivateBranchOffice(row)" *ngIf="row.activa && permissions.getPermission('30-90-16').asignado">
							<div class="icon-circle bg-danger text-center align-middle pt-info-button">
								<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
							</div>
						</a>
						<a [routerLink]="" ngbTooltip="Activar Sucursal" class="primary edit mr-1 mrless" id="mantenedor-emisores.component_Activar_Sucursal" (click)="activateBranchOffice(row)" *ngIf="!row.activa && permissions.getPermission('30-90-16').asignado">
							<div class="icon-circle bg-success text-center align-middle pt-info-button">
								<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
							</div>
						</a>

						<span class="badge badge-light imported-badge font-italic" *ngIf="row.offline">Offline</span>
					</ng-template>
				</ngx-datatable-column>
			</ngx-datatable>
		</div>
		<div class="modal-footer">
			<alert id="alert-errormodal"></alert>
			<div class="form-group position-relative has-icon-left mb-0">
				<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
			</div>
		</div>
	</div>
</ng-template>

<!-- <ng-template #detailSucursalM let-d="dismiss" id="detail-modal">
   <div class="modal-content">
       <div class="modal-header">
           <div class="modal-wizard-label">
               <div class="modal-wizard-title">Detalles de la Sucursal {{sucursalToShow.nombre}}</div>
           </div>
       </div>
       <div class="modal-body">
           <div class="row form-group mx-auto" id="details-client">
               <div class="col-12">
                   <div class="row">
                       <div class="col-4">
                           <span class="d-block">Nombre</span>
                           <span class="d-block">Dirección</span>
                       </div>
                       <div class="col-8">
                           <span class="d-block text-right">{{sucursalToShow.nombre?sucursalToShow.nombre:"No disponible"}}</span>
                           <span class="d-block text-right">{{sucursalToShow.direccion?sucursalToShow.direccion:"No disponible"}}</span>
                           </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</ng-template> -->
<ng-template #detailSucursalM let-d="dismiss">
	<form #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Detalles de la Sucursal {{ sucursalToShow.nombre }}</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="nombre"
							placeholder="Ingresar nombre"
							class="form-control"
							#editNombre="ngModel"
							[(ngModel)]="sucursalToShow.nombre"
							[ngClass]="{
								'is-invalid': editForm.submitted && editNombre.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editNombre.invalid" class="invalid-feedback-group">
							<div *ngIf="editNombre.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección</label>
					<div class="col-9">
						<input
							disabled
							type="text"
							name="direccion"
							placeholder="Ingresar dirección"
							class="form-control"
							#editDireccion="ngModel"
							[(ngModel)]="sucursalToShow.direccion"
							[ngClass]="{
								'is-invalid': editForm.submitted && editDireccion.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editDireccion.invalid" class="invalid-feedback-group">
							<div *ngIf="editDireccion.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>

				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #addSucursalM let-d="dismiss">
	<form (ngSubmit)="addBranchOffice(addForm)" #addForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Agregar Sucursal</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombre"
							placeholder="Ingresar nombre"
							class="form-control"
							#newNombre="ngModel"
							[(ngModel)]="sucursalToAdd.nombre"
							[ngClass]="{
								'is-invalid': addForm.submitted && newNombre.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newNombre.invalid" class="invalid-feedback-group">
							<div *ngIf="newNombre.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección</label>
					<div class="col-9">
						<input
							type="text"
							name="direccion"
							placeholder="Ingresar dirección"
							class="form-control"
							#newDireccion="ngModel"
							[(ngModel)]="sucursalToAdd.direccion"
							[ngClass]="{
								'is-invalid': addForm.submitted && newDireccion.invalid
							}"
							required />
						<div *ngIf="addForm.submitted && newDireccion.invalid" class="invalid-feedback-group">
							<div *ngIf="newDireccion.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="addForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #editSucursalM let-d="dismiss">
	<form (ngSubmit)="editBranchOffice(editForm)" #editForm="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel1">Editar Sucursal</h5>
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Nombre</label>
					<div class="col-9">
						<input
							type="text"
							name="nombre"
							placeholder="Ingresar nombre"
							class="form-control"
							#editNombre="ngModel"
							[(ngModel)]="sucursalToEdit.nombre"
							[ngClass]="{
								'is-invalid': editForm.submitted && editNombre.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editNombre.invalid" class="invalid-feedback-group">
							<div *ngIf="editNombre.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Dirección</label>
					<div class="col-9">
						<input
							type="text"
							name="direccion"
							placeholder="Ingresar dirección"
							class="form-control"
							#editDireccion="ngModel"
							[(ngModel)]="sucursalToEdit.direccion"
							[ngClass]="{
								'is-invalid': editForm.submitted && editDireccion.invalid
							}"
							required />
						<div *ngIf="editForm.submitted && editDireccion.invalid" class="invalid-feedback-group">
							<div *ngIf="editDireccion.errors.required">Campo obligatorio</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<alert id="alert-errormodal"></alert>
				<div class="form-group position-relative has-icon-left mb-0">
					<button [disabled]="editForm.invalid" type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
<ng-template #economicActivitiesEmisor let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Actividades Económicas del Emisor</div>
				<div class="modal-wizard-desc">Se muestra el selector de Actividades Económicas disponibles y las ya Asignadas</div>
			</div>
		</div>
		<div class="modal-body p-0">
			<app-actividad-entry [emisor]="emisorToEdit" (sendEmisorRequest)="updateEconomicActivitiesRequest($event)" (sendCloseRequestEmitter)="closeEconomicActivitiesModal()"></app-actividad-entry>
		</div>
	</div>
</ng-template>
<ng-template #authorizedPersonsEmisor let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-body p-0">
			<app-persona-autorizada-entry [emisor]="emisorToEdit" (sendCloseRequestEmitter)="closeAuthorizedPersonsModal()"></app-persona-autorizada-entry>
		</div>
	</div>
</ng-template>

<ng-template #addLogoModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Logo de Empresa</div>
				<div class="modal-wizard-desc">Agregue una imagen para utilizar ocmo logo en la empresa seleccionada</div>
			</div>
		</div>

		<div class="modal-body">
			<app-logo-emisor-entry [relatedEmisor]="emisorToEdit" [currentLogoEmisor]="logoEmisorToEdit" (sendlogoEmisorRequest)="sendLogoRequest($event)" (sendCloseRequestEmitter)="closeAddLogoModal()"> </app-logo-emisor-entry>
		</div>
	</div>
</ng-template>

<ng-template #editPasswordModal let-d="dismiss" let-c="close">
	<form [formGroup]="passwordEditForm" (ngSubmit)="onSubmitPassForm()" #editPasswordFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">{{ emisorToEdit.tienePassSii ? "Modificar" : "Agregar" }} contraseña</div>
					<div class="modal-wizard-desc">
						{{
							emisorToEdit.tienePassSii
								? "Ingrese la clave actual y la nueva
                  clave"
								: "Ingrese la clave"
						}}
						para acceder al Servicio de Impuestos Internos
					</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto">
					<label for="projInput1" class="label-control col-3">Password Nuevo</label>
					<div class="col-9">
						<input type="password" class="form-control" formControlName="password" />
						<div *ngIf="editPasswordFormRef.submitted && passwordEditForm.get('password').invalid" class="text-danger">
							<div *ngIf="passwordEditForm.get('password').errors?.required">Campo Requerido</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<label for="projInput1" class="label-control col-3">Repetir Password</label>
					<div class="col-9">
						<input type="password" class="form-control" formControlName="repeatPassword" />
						<div *ngIf="editPasswordFormRef.submitted && passwordEditForm.get('repeatPassword').invalid" class="text-danger">
							<div *ngIf="passwordEditForm.get('repeatPassword').errors?.required">Campo Requerido</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<div class="col-12">
						<small>Permite acceso al listado de compras y para la emisión de Boletas de Honorarios. Si usted no requiere o utiliza ninguna de estas funcionalidades, no requiere ingresar su contraseña.</small>
					</div>
				</div>
				<div class="form-group row mx-auto" *ngIf="editPasswordFormRef.submitted && passwordEditForm.invalid">
					<div class="col-9 offset-sm-3">
						<div *ngIf="passwordEditForm.errors?.differentPassword" class="text-danger">La contraseña debe coincidir</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-info add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
				</div>
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
