export const ConfigMantenedorUsuario = {
   barraBusqueda: {
      criterios: ["Rut", "Nombre", "Comuna"],
      criteriosKeys: ["rut", "nombre", "comuna"],
      criterioPrincipal: "Rut",
   },
   table: {
      title: "Usuarios",
      add: "Agregar Usuario",
      options: [
         {
            name: "Importar",
            active: true,
            class: "ft-upload",
         },
         {
            name: "Exportar",
            active: true,
            class: "ft-download",
         },
         {
            name: "Solicitar Soporte",
            active: true,
            class: "ft-shuffle",
         },
      ],
   },
};
