import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { FastSearchResponse } from "src/app/shared/models/FastSearchResponse";

@Component({
   selector: "app-fast-search",
   templateUrl: "./fast-search.component.html",
   styleUrls: ["./fast-search.component.scss"],
})
export class FastSearchComponent implements OnInit {
   public cadenaBuscada: string;
   public arregloBusqueda: Array<any>;
   public arregloCriterios: [];
   public arregloResultado: Array<any>;
   public arregloBusquedaOriginal: Array<any>;
   inputBusqueda: FormGroup;
   @Input() showLegacyBar: boolean = false;
   @Input() cadenaDeEntrada: string;
   @Input() arrayBuscar: Array<any>;
   @Input() arrayCriterios: [];
   @Output() arrayresultadoEnviado: EventEmitter<any> = new EventEmitter<any>();
   @Input() usingCheckbox: boolean = false;
   @Input() usingEmisorFilter: boolean = false;
   @Input() completeEmisoresList: Emisor[];
   checkboxValue: boolean = true;
   private fastSearchResult: FastSearchResponse = new FastSearchResponse();
   public currentEmisoresList: Emisor[] = [];
   private currentEmisor: Emisor = undefined;
   private currentEmisorId: string;
   public clicked: boolean = false;

   constructor(private formBuilder: FormBuilder, private localStorage: LocalStorageService, private tokenStorage: TokenStorageService) {
      this.inputBusqueda = this.formBuilder.group({
         stringABuscar: "",
      });
   }

   ngOnInit(): void {
      if (this.usingEmisorFilter) this.getEmisoresList();
      this.inputBusqueda = this.formBuilder.group({
         stringABuscar: "",
         emisorSelected: this.currentEmisor,
      });
   }

   get f() {
      return this.inputBusqueda.controls;
   }
   buscar_enviar($event) {
      this.cadenaBuscada = this.f.stringABuscar.value;
      this.arregloBusqueda = this.arrayBuscar;
      this.arregloCriterios = this.arrayCriterios;
      this.arregloResultado = this.filtrarConsulta(this.cadenaBuscada, this.arregloBusqueda, this.arregloCriterios);
      if (this.usingCheckbox) {
         this.fastSearchResult.searchResult = this.arregloResultado;
         this.fastSearchResult.optionalCheckBoxValue = this.checkboxValue;
      } else {
         this.fastSearchResult.searchResult = this.arregloResultado;
         this.fastSearchResult.optionalCheckBoxValue = undefined;
      }
      this.fastSearchResult.emisorIdSelected = this.f.emisorSelected && this.f.emisorSelected.value ? this.f.emisorSelected.value.emisorId : undefined;
      this.arrayresultadoEnviado.emit(this.fastSearchResult);
   }

   ngOnChanges(changes: { [propName: string]: SimpleChange }) {
      if (changes["arrayBuscar"]) {
         this.ngOnSubmit(event);
      }
      if (changes["completeEmisoresList"]) {
         this.currentEmisoresList = this.completeEmisoresList;
      }
   }
   applyFilter(event) {
      this.ngOnSubmit(event);
   }
   ngAfterViewChecked() {}

   ngOnSubmit($event) {
      this.buscar_enviar($event);
   }

   filtrarConsulta(cadenaBuscada: string, arregloBusqueda: Array<any>, arregloCriteriosEntrante: []) {
      let busqueda = cadenaBuscada;
      var arregloJson = arregloBusqueda;
      var arregloCriteriosBusqueda = [];

      if (arregloCriteriosEntrante != null && arregloCriteriosEntrante.length > 0) {
         arregloCriteriosBusqueda = arregloCriteriosEntrante; // [{key: 'ciudad'}, {key: 'nombre'}]
      } // Si no recibe criterios de busqueda, los obtiene del arregloJson
      else {
         if (arregloJson != null && arregloJson.length > 0) {
            var objetoJson = arregloJson[0];

            for (var prop in objetoJson) {
               arregloCriteriosBusqueda.push({ key: prop });
            }
         }
      }

      let arregloFiltrado = [];

      if (busqueda != null && busqueda != "") {
         for (let i = 0; i < arregloCriteriosBusqueda.length; i++) {
            let identificador = arregloCriteriosBusqueda[i].key; // nombre, rut, fechaNacimiento
            let arregloProvisorio: any[];
            //ver si key identificadora trae punto '.', aplicar criterio a subelemento
            if (identificador.includes(".")) {
               let nombreSubElemento = identificador.split(".")[0];
               let criterioSubelemento = identificador.split(".")[1];
               arregloProvisorio = arregloJson.filter((c) => {
                  if (c[nombreSubElemento] != undefined && c[nombreSubElemento][criterioSubelemento] != undefined) return c[nombreSubElemento][criterioSubelemento].toString().toLowerCase().includes(busqueda.toLowerCase());
                  return false;
               });
            } else {
               // Aplica criterios de busqueda
               arregloProvisorio = arregloJson.filter((c) => c[identificador] != null && c[identificador].toString().toLowerCase().includes(busqueda.toLowerCase()));
            }

            if (arregloProvisorio.length > 0) {
               arregloProvisorio.forEach((c) => {
                  if (!arregloFiltrado.some((x) => Object.is(x, c))) {
                     arregloFiltrado.push(c);
                  }
                  //arregloFiltrado.push(c);
               });
            }
         }
      } else {
         arregloFiltrado = arregloJson;
      }
      return arregloFiltrado;
   }

   getEmisoresList() {
      if (this.usingEmisorFilter) {
         this.currentEmisoresList = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
         this.currentEmisorId = this.tokenStorage.getCompany();
         this.currentEmisor = this.currentEmisoresList.find((em) => em.emisorId == this.currentEmisorId);
      }
   }

   clickedAccordionButton() {
      this.clicked = !this.clicked;
   }
}
