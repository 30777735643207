<ngx-loading [show]="loading" [config]="{backdropBorderRadius: '3px'}" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<form>
    <div class="modal-header">
        <div class="modal-wizard-label">
            <div *ngIf="!exampleModal" class="modal-wizard-title">Plantillas Asociadas</div>
            <div *ngIf="!exampleModal" class="modal-wizard-desc">Se muestran las plantillas asociadas al tipo de correo
            </div>
            <div *ngIf="exampleModal" class="modal-wizard-title">Plantillas de Ejemplo</div>
            <div *ngIf="exampleModal" class="modal-wizard-desc">Se muestran las plantillas de ejemplo </div>
        </div>
        <div class="mt-5 form-group position-relative mx-auto">
            <div class="row pt-5">
                <div class="ml-5">
                    <button *ngIf="permissions.getPermission('30-95-18-12').asignado" type="button"
                        class="btn btn-success" (click)="openAddTemplateModal(addTemplate)">Agregar</button>
                </div>
                <!-- <div class="ml-5">
                    <button *ngIf="permissions.getPermission('30-95-18-14').asignado && !isSengrid" type="button" class="btn btn-warning"  (click)="getExamples()" >Descargar Ejemplos</button>
                </div> -->
            </div>

        </div>

    </div>
    <div class="modal-body" *ngIf="permissions.getPermission('30-95-18-10').asignado">
        <div>
            <h4>Plantillas</h4>
            <div *ngIf="templateList.length > 0 ">

                <table class="table">
                    <thead>
                        <tr>
                            <td>Nombre</td>
                            <td>Estado</td>
                            <td>Acción</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of templateList;let index=index">
                            <td>{{item.nombre}}</td>
                            <td>{{item.activo?"Activada":"Desactivada"}}</td>
                            <td>
                                <a *ngIf="permissions.getPermission('30-95-18-16').asignado"
                                    ngbTooltip="Detalle de Plantilla" class="primary edit mr-1 mrless"
                                    (click)="openDetailTemplateModal(detailTemplate,item)">
                                    <div class="icon-circle bg-warning text-center align-middle pt-info-button">
                                        <fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
                                    </div>
                                </a>
                                <a *ngIf="permissions.getPermission('30-95-18-18').asignado"
                                    ngbTooltip="Editar Plantilla" class="primary edit mr-1 mrless"
                                    (click)="openUpdateTemplateModal(updateTemplate,item)">
                                    <div class="icon-circle bg-info text-center align-middle pt-info-button">
                                        <fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
                                    </div>
                                </a>
                                <a *ngIf="permissions.getPermission('30-95-18-20').asignado && !isSengrid"
                                    ngbTooltip="Descargar Plantilla" class="primary edit mr-1 mrless"
                                    (click)="downloadTemplate(item)">
                                    <div class="icon-circle bg-success text-center align-middle pt-info-button">
                                        <fa-icon class="fas fa-download"></fa-icon>
                                    </div>
                                </a>
                                <a *ngIf="permissions.getPermission('30-95-18-24').asignado && item.activo && !exampleModal"
                                    class="primary edit mr-1 mrless" ngbTooltip="Desactivar Plantilla" [swal]="{
                                                    title: 'Confirmación',
                                                    text: '¿Está seguro de desactivar esta plantilla?',
                                                    confirmButtonText: 'Desactivar',
                                                    cancelButtonText: 'Cancelar',
                                                    showCancelButton: true,
                                                    confirmButtonColor:'#F64E60'
                                                }" (confirm)="deactivateTemplate(item)">
                                    <div class="icon-circle bg-danger text-center align-middle pt-info-button">
                                        <fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
                                    </div>
                                </a>
                                <a *ngIf="permissions.getPermission('30-95-18-24').asignado && !item.activo && !exampleModal"
                                    class="primary edit mr-1 mrless" ngbTooltip="Activar Plantilla" [swal]="{
                                                    title: 'Confirmación',
                                                    text: '¿Está seguro de activar esta plantilla?',
                                                    confirmButtonText: 'Activar',
                                                    cancelButtonText: 'Cancelar',
                                                    showCancelButton: true,
                                                    confirmButtonColor:'#F64E60'
                                                }" (confirm)="activateTemplate(item)">
                                    <div class="icon-circle bg-success text-center align-middle pt-info-button">
                                        <fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
                                    </div>
                                </a>
                                <a *ngIf="permissions.getPermission('30-95-18-22').asignado"
                                    class="primary edit mr-1 mrless" ngbTooltip="Eliminar Plantilla" [swal]="{
                                                    title: 'Confirmación',
                                                    text: '¿Está seguro de eliminar esta plantilla?',
                                                    confirmButtonText: 'Eliminar',
                                                    cancelButtonText: 'Cancelar',
                                                    showCancelButton: true,
                                                    confirmButtonColor:'#F64E60'
                                                }" (confirm)="deleteTemplate(item)">
                                    <div class="icon-circle bg-info text-center align-middle pt-info-button">
                                        <fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
                                    </div>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item" >
            <i class="la la-paper-plane-o d-lg-none"></i>
            <span class="d-none d-lg-block">Guardar</span>
         </button> -->
        <button type="button" class="btn btn-secondary ml-3" (click)="sendCloseRequest()">
            <span class="d-none d-lg-block">Cerrar</span>
        </button>
    </div>
</form>

<ng-template #addTemplate let-d="dismiss">

    <form [formGroup]="templateForm" (ngSubmit)="add()" #templateFormRef="ngForm">
        <div class="modal-lg">
            <div class="modal-header">
                <div class="modal-wizard-label">
                    <div class="modal-wizard-title">Agregar Plantilla</div>
                    <div class="modal-wizard-desc">Ingresa datos de la plantilla</div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Tipo</label>
                        <div class="col-9">
                            <input class="form-control" value="{{correoEnvio.nombreTipoPlantillaEnvio}}" disabled>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Nombre</label>
                        <div class="col-9">
                            <input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control">
                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('nombre').invalid ||  templateForm.get('nombre').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('nombre').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto" *ngIf="isSengrid">
                    <div class="row">
                        <label for="" class="col-2 label-control">Codigo Sengrid</label>
                        <div class="col-9">
                            <textarea type="text" name="code" id="code" formControlName="code"
                                class="form-control"></textarea>
                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('code').invalid ||  templateForm.get('code').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('code').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto" *ngIf="!isSengrid">
                    <div class="row">
                        <label for="" class="col-2 label-control">HTML</label>
                        <div class="col-9">
                            <ngx-dropzone class="mx-auto" (change)="onSelect($event)" maxFileSize="2097152"
                                [accept]="'.html'" multiple=false>
                                <ngx-dropzone-label class="px-5">
                                    <i class="flaticon-upload"></i>
                                    <p>Arrastra o selecciona un archivo html con la plantilla</p>

                                    <p>El formato del archivo debe ser .html</p>
                                </ngx-dropzone-label>
                                <!-- <ngx-dropzone-label class="d-inline">
                                   
                                </ngx-dropzone-label> -->
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                    (removed)="onRemove(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>

                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('file').invalid ||  templateForm.get('file').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('file').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
                    <i class="la la-paper-plane-o d-lg-none"></i>
                    <span class="d-none d-lg-block">Guardar</span>
                </button>
                <button type="button" class="btn btn-secondary ml-3" (click)="closeAddTemplateModal()">
                    <span class="d-none d-lg-block">Cerrar</span>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #updateTemplate let-d="dismiss">

    <form [formGroup]="templateForm" (ngSubmit)="update()" #templateFormRef="ngForm">
        <div class="modal-lg">
            <div class="modal-header">
                <div class="modal-wizard-label">
                    <div class="modal-wizard-title">Actualizar Plantilla</div>
                    <div class="modal-wizard-desc">Actualiza datos de la plantilla </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Tipo</label>
                        <div class="col-9">
                            <input class="form-control" value="{{templateToEdit.nombreTipoPlantilla}}" disabled>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Nombre</label>
                        <div class="col-9">
                            <input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control">
                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('nombre').invalid ||  templateForm.get('nombre').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('nombre').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto" *ngIf="isSengrid">
                    <div class="row">
                        <label for="" class="col-2 label-control">Codigo Sengrid</label>
                        <div class="col-9">
                            <textarea type="text" name="code" id="code" formControlName="code"
                                class="form-control"></textarea>
                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('code').invalid ||  templateForm.get('code').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('code').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto" *ngIf="!isSengrid">
                    <div class="row">
                        <label for="" class="col-2 label-control">HTML</label>
                        <div class="col-9">
                            <ngx-dropzone class="mx-auto" (change)="onSelect($event)" maxFileSize="2097152"
                                [accept]="'.html'" multiple=false>
                                <ngx-dropzone-label class="px-5">
                                    <i class="flaticon-upload"></i>
                                    <p>Arrastra o selecciona un archivo html con la plantilla</p>

                                    <p>El formato del archivo debe ser .html</p>
                                </ngx-dropzone-label>
                                <!-- <ngx-dropzone-label class="d-inline">
                                   
                                </ngx-dropzone-label> -->
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                    (removed)="onRemove(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>

                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('file').invalid ||  templateForm.get('file').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('file').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
                    <i class="la la-paper-plane-o d-lg-none"></i>
                    <span class="d-none d-lg-block">Guardar</span>
                </button>
                <button type="button" class="btn btn-secondary ml-3" (click)="closeUpdateTemplateModal()">
                    <span class="d-none d-lg-block">Cerrar</span>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #detailTemplate let-d="dismiss">

    <form [formGroup]="templateForm" #templateFormRef="ngForm">
        <div class="modal-lg">
            <div class="modal-header">
                <div class="modal-wizard-label">
                    <div class="modal-wizard-title">Detalles de Plantilla</div>
                    <div class="modal-wizard-desc">Se muestran datos de la plantilla </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Tipo</label>
                        <div class="col-9">
                            <input class="form-control" value="{{templateDetail.nombreTipoPlantilla}}" disabled>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto">
                    <div class="row">
                        <label for="" class="col-2 label-control">Nombre</label>
                        <div class="col-9">
                            <input type="text" name="nombre" id="nombre" formControlName="nombre" class="form-control"
                                disabled>
                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('nombre').invalid ||  templateForm.get('nombre').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('nombre').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group position-relative mx-auto" *ngIf="isSengrid">
                    <div class="row">
                        <label for="" class="col-2 label-control">Codigo Sengrid</label>
                        <div class="col-9">
                            <textarea type="text" name="code" id="code" formControlName="code" class="form-control"
                                disabled></textarea>
                            <div *ngIf="templateFormRef.submitted &&( templateForm.get('code').invalid ||  templateForm.get('code').dirty)"
                                class="invalid-feedback">
                                <div *ngIf="templateForm.get('code').errors?.required">Campo requerido</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-secondary ml-3" (click)="closeDetailTemplateModal()">
                    <span class="d-none d-lg-block">Cerrar</span>
                </button>
            </div>
        </div>
    </form>
</ng-template>