<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
<swal #notesSwal title="Operación" text="Operación realizada" icon="info"></swal>

<!-- <h1>{{fileIdValue ? fileIdValue: 'Sin id por ruta'}}</h1> -->
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar
					[criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal"
					[config]="configLocal.barraBusqueda"
					[filtroEstadoSii]="true"
					[filtroTipoTraslados]="true"
					[filtroTipoDespacho]="true"
					[filtroReutilizado]="true"
					[filtroFacturables]="true"
					[nombreCampoDatePicker]="configLocal.barraBusqueda.nombreCampoDatePicker"
					[useAccordionMode]="true"
					(busqueda)="searchElementsByFilter($event)"
					[filtroEmisor]="true"
					[filtroSucursal]="true"
					[listaCompletaEmisores]="empresasSource"
					[mostrarListaCompletaEmisores]="true"></app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">
									{{ configLocal.table.title
									}}{{
										filterDescription
											? " -
                                    " + filterDescription
											: ""
									}}
								</h4>
								<div class="card-toolbar">
									<!-- insertar botonera -->
									<!-- <button class="btn btn-success" (click)="openAddDteFileModal(loadFileModal)">
                                        <i class="flaticon-upload"></i>
                                        <span>{{configLocal.table.add}}</span> 
                                    </button> -->
								</div>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-primary float-right mr-5 mt-5" (click)="openDocumentSenderModal(dteSenderModal)" *ngIf="permissions.getPermission('40-18-18').asignado">
										<i class="flaticon-mail-1"></i>
										Enviar
									</button>
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="tryMassiveRouseConfirm()" ngbTooltip="Solo se pueden reutilizar folios de documentos que no estén aceptados por el sii" *ngIf="permissions.getPermission('40-18-46').asignado">
										<i class="flaticon-download-1"></i>
										Reutilizar Folios Masivo
									</button>
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar" *ngIf="permissions.getPermission('40-18-16').asignado">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>

									<div ngbDropdown>
										<button ngbDropdownToggle id="dropdownDescargas" class="btn btn-primary float-right mr-5 mt-5" *ngIf="permissions.getPermission('40-18-12').asignado">
											<i class="flaticon-download"></i>
											Descarga Documentos
										</button>
										<div ngbDropdownMenu aria-labelledby="dropdownDescargas">
											<button ngbDropdownItem (click)="multipleDocumentDownload('pdf')">Descarga PDF</button>
											<button ngbDropdownItem (click)="multipleDocumentDownload('xml')">Descarga XML</button>
										</div>
									</div>
									<!-- <button class="btn btn-success float-right mr-5 mt-5 menu-item" routerLinkActive="menu-item-active"  [routerLink]="'/facturador-individual'" routerLinkActive="active"
                                        *ngIf="permissions.getPermission('40-18-10').asignado"
                                    >
                                        <i class="flaticon-doc"></i>
                                        <span class="menu-text">Factura Individual</span>
                                    </button> -->
									<button class="btn btn-success float-right mr-5 mt-5 menu-item" (click)="navigateToFacturador(2)" *ngIf="permissions.getPermission('40-18-10').asignado">
										<i class="flaticon-add-circular-button"></i>
										<span class="menu-text">Nueva Guía de Despacho</span>
									</button>
									<button class="btn btn-warning float-right mr-5 mt-5 menu-item" (click)="sendGuidesToInvoiceModal(GuidesListToInvoices)">
										<i class="flaticon-add-circular-button"></i>
										<span class="menu-text">Facturar Guías</span>
									</button>
								</div>
							</div>
							<!-- <div class="col-1" >
                                <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" change="setPageSize()">
                                   <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                                </ng-select>
                           </div> -->
							<div class="card-content">
								<div class="card-body">
									<!-- inicion seccion botones agregar y exportar -->
									<!-- <app-exportacion-datos [dataArray]="rowsMantenedor" [exportCriteria]="criteriosEnBase"></app-exportacion-datos> -->

									<!-- fin seccion botones -->

									<!-- seccion tabla -->
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="80"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[selectionType]="'checkbox'"
										[selected]="selected"
										(selected)="onSelect($event)"
										[rowIdentity]="getId"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false" [flexGrow]="0.2">
											<ng-template ngx-datatable-header-template let-value="value">
												<input type="checkbox" class="customCheckbox" [checked]="selectedAllItemsInPage[page.pageNumber]" (change)="selectAll($event)" />
											</ng-template>
											<ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
												<input type="checkbox" class="customCheckbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="folio" [flexGrow]="1.4" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Folio</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.folio }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Cliente" [flexGrow]="2" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Cliente</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.rutReceptorDv
														? row.rutReceptorDv
														: "No
                                                    disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="4" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Razón Social</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{
													row.razonSocial
														? row.razonSocial
														: "No
                                                    disponible"
												}}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="fecha" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="date-table-alignment">Fecha</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="date-table-alignment">{{ row.fecha | date : "dd/MM/yyyy" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="Traslado" [flexGrow]="2.5" [sortable]="false" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo Traslado</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.guiaDespacho.nombreTipoTraslado }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="total" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Monto</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column [cellTemplate]="semaforo" [sortable]="false" name="Estado" [flexGrow]="2" [resizeable]="false"></ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="4.5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Enviar correo" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Enviar_correo" (click)="openDocumentSenderModal(dteSenderModal, row)" *ngIf="permissions.getPermission('40-18-20').asignado">
													<!-- <span aria-label="Enviar correo" [inlineSVG]="'./assets/media/svg/icons/Communication/Outgoing-mail.svg'" cacheSVG="true" class="svg-icon svg-icon-3x svg-icon-success"></span> -->
													<div class="icon-circle bg-success text-center align-middle pt-email-button">
														<fa-icon icon="envelope" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<div ngbDropdown class="dropdownBotoneraLinea" container="body">
													<a [routerLink]="" title="Descarga Documento" ngbTooltip="Descarga Documento" class="success edit mr-1 mrless" ngbDropdownToggle id="dropdownDoc" container="body" *ngIf="permissions.getPermission('40-18-22').asignado">
														<div class="icon-circle bg-danger text-center align-middle pt-file-button">
															<fa-icon icon="file-pdf" class="fa-lg text-white"></fa-icon>
														</div>
													</a>

													<div ngbDropdownMenu aria-labelledby="dropdownDoc">
														<button ngbDropdownItem (click)="downloadPDF(row)" *ngIf="permissions.getPermission('40-18-22').asignado">Descarga PDF</button>
														<button ngbDropdownItem (click)="downloadPDFCedible(row, true)" *ngIf="permissions.getPermission('40-18-23').asignado && row.cedibleHabilitado">Descarga PDF Cedible</button>
														<button ngbDropdownItem (click)="downloadXml(row)" *ngIf="permissions.getPermission('40-18-24').asignado">Descargar XML</button>
													</div>
												</div>
												<a [routerLink]="" ngbTooltip="Trazabilidad" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Trazabilidad" (click)="openTraceShowModal(dteTracesModal, row)" *ngIf="permissions.getPermission('40-18-26').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-list-button">
														<fa-icon icon="list-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Detalles" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Detalles" (click)="detailOpenModal(dteDetailModal, row)" *ngIf="permissions.getPermission('40-18-28').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Reenviar Documento al SII" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Reenviar_Documento_al_SII" (click)="resendDte(row)" *ngIf="permissions.getPermission('40-18-38').asignado && row.estadoSii == 8 && !row.folioReutilizado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="permissions.getPermission('40-18-39').asignado && row.estadoSii == 5"
													class="primary edit mr-1 mrless"
													ngbTooltip="Reenviar Email Validación"
													[swal]="{
														title: 'Confirmación',
														text: 'El correo de validación se enviará a la casilla registrada en el sii, ¿Desea Continuar?',
														confirmButtonText: 'Continuar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="resendEmailSii(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="!row.importado && !row.folioReutilizado && permissions.getPermission('40-18-44').asignado && row.reutilizarFoliohabilitado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Reutilizar Folio"
													[swal]="{
														title: 'Confirmación',
														text: 'El folio volvera a estar disponible, ¿Desea Continuar?',
														confirmButtonText: 'Continuar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="reuse(row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<span class="badge badge-light imported-badge font-italic" *ngIf="row.folioReutilizado">Folio reutilizado</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla seccion tabla -->

									<!-- tabla movil -->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[selectionType]="'checkbox'"
										[selected]="selected"
										(selected)="onSelect($event)"
										[rowIdentity]="getId"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Cliente: {{ row.rutReceptorDv ? row.rutReceptorDv : "No disponible" }} - {{ row.razonSocial ? row.razonSocial : "No disponible" }} , Folio: {{ row.folio }} , Monto: {{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }} , Fecha: {{ row.fecha | date : "dd/MM/yyyy" }} , Tipo Traslado: {{ row.guiaDespacho.nombreTipoTraslado }} , Tipo Despacho: {{ row.guiaDespacho.nombreTipoDespacho }}</div>
											</ng-template>
										</ngx-datatable-row-detail>

										<ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
											<ng-template ngx-datatable-header-template let-value="value">
												<input type="checkbox" class="customCheckbox" [checked]="selectedAllItemsInPage[page.pageNumber]" (change)="selectAll($event)" />
											</ng-template>
											<ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
												<input type="checkbox" class="customCheckbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="3.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Documento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment"> Folio: {{ row.folio }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column [cellTemplate]="semaforo" [sortable]="false" name="Estado" [flexGrow]="2" [resizeable]="false"></ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Enviar correo" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Enviar_correo" (click)="openDocumentSenderModal(dteSenderModal, row)" *ngIf="permissions.getPermission('40-18-20').asignado">
													<!-- <span aria-label="Enviar correo" [inlineSVG]="'./assets/media/svg/icons/Communication/Outgoing-mail.svg'" cacheSVG="true" class="svg-icon svg-icon-3x svg-icon-success"></span> -->
													<div class="icon-circle bg-success text-center align-middle pt-email-button">
														<fa-icon icon="envelope" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<div ngbDropdown class="dropdownBotoneraLinea" container="body">
													<a [routerLink]="" title="Descarga Documento" ngbTooltip="Descarga Documento" class="success edit mr-1 mrless" ngbDropdownToggle id="dropdownDoc" container="body" *ngIf="permissions.getPermission('40-18-22').asignado">
														<div class="icon-circle bg-danger text-center align-middle pt-file-button">
															<fa-icon icon="file-pdf" class="fa-lg text-white"></fa-icon>
														</div>
													</a>

													<div ngbDropdownMenu aria-labelledby="dropdownDoc">
														<button ngbDropdownItem (click)="downloadPDF(row)" *ngIf="permissions.getPermission('40-18-22').asignado">Descarga PDF</button>
														<button ngbDropdownItem (click)="downloadPDFCedible(row, true)" *ngIf="permissions.getPermission('40-18-23').asignado && row.cedibleHabilitado">Descarga PDF Cedible</button>
														<button ngbDropdownItem (click)="downloadXml(row)" *ngIf="permissions.getPermission('40-18-24').asignado">Descargar XML</button>
													</div>
												</div>
												<a [routerLink]="" ngbTooltip="Trazabilidad" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Trazabilidad" (click)="openTraceShowModal(dteTracesModal, row)" *ngIf="permissions.getPermission('40-18-26').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-list-button">
														<fa-icon icon="list-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Detalles" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Detalles" (click)="detailOpenModal(dteDetailModal, row)" *ngIf="permissions.getPermission('40-18-28').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" ngbTooltip="Reenviar Documento al SII" class="primary edit mr-1 mrless" id="mantenedor-guia-despacho.component_Reenviar_Documento_al_SII" (click)="resendDte(row)" *ngIf="permissions.getPermission('40-18-38').asignado && row.estadoSii == 8 && !row.folioReutilizado">
													>

													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<span class="badge badge-light imported-badge font-italic" *ngIf="row.folioReutilizado">Folio reutilizado</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #semaforo let-row="row">
	<app-semaforo-estado-dte [row]="row"></app-semaforo-estado-dte>
</ng-template>

<ng-template #dteDetailModal let-d="dismiss" id="detail-modal">
	<div class="modal-content">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Detalle de {{ dteToShow.nombreTipoDte }} - N° {{ dteToShow.folio }}</div>
				<div class="modal-wizard-desc">Detalles del documento seleccionado</div>
			</div>
		</div>
		<div class="modal-body">
			<div class="row form-group mx-auto" id="details-client">
				<h4>Datos del Cliente</h4>
				<div class="col-12">
					<div class="row">
						<table class="detail-table">
							<tr>
								<td>Rut</td>
								<td>{{ dteToShow.rutReceptor ? dteToShow.rutReceptorDv : "No disponible" }}</td>
							</tr>
							<tr>
								<td>Razón Social</td>
								<td>{{ dteToShow.razonSocial ? dteToShow.razonSocial : "No disponible" }}</td>
							</tr>
							<tr>
								<td>Giro</td>
								<td>{{ dteToShow.receptorAsociado.giro ? dteToShow.receptorAsociado.giro : "No disponible" }}</td>
							</tr>
							<tr>
								<td>Comuna</td>
								<td>
									{{
										dteToShow.receptorAsociado.comuna
											? dteToShow.receptorAsociado.comuna
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Ciudad</td>
								<td>
									{{
										dteToShow.receptorAsociado.ciudad
											? dteToShow.receptorAsociado.ciudad
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Dirección</td>
								<td>
									{{
										dteToShow.receptorAsociado.dirFact
											? dteToShow.receptorAsociado.dirFact
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>
									{{
										dteToShow.receptorAsociado.correoFact
											? dteToShow.receptorAsociado.correoFact
											: "No
                                    disponible"
									}}
								</td>
							</tr>
							<tr>
								<td>Fecha Emisión</td>
								<td>{{ dteToShow.fecha | date : "dd/MM/yyyy" }}</td>
							</tr>
							<tr *ngIf="dteToShow.fechaVencimiento">
								<td>Fecha Vencimiento</td>
								<td>{{ dteToShow.fechaVencimiento | date : "dd/MM/yyyy" }}</td>
							</tr>
							<tr *ngIf="dteToShow.estadoAcuse">
								<td>Estado Acuse</td>
								<td>{{ dteToShow.descripcionEstadoAcuse }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<hr />
			<div class="row form-group mx-auto">
				<h4>Detalles de Cargos</h4>
				<div class="col-12">
					<div class="row" id="details-dte">
						<ng-container *ngFor="let detalle of dteToShow.detalleDte" class="col-12">
							<div class="col-9">
								<p>
									{{ detalle.nombre }}
								</p>
							</div>
							<div class="col-3">
								<p class="text-right">
									{{ detalle.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}
								</p>
							</div>
						</ng-container>
					</div>
					<hr />
					<div class="row" id="details-total-dte">
						<div class="offset-7 col-2">
							<p class="text-right font-weight-bold">Neto</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.neto | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-7 col-2">
							<p class="text-right font-weight-bold">Iva</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.iva | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-4 col-5" *ngIf="dteToShow.impuestos > 0">
							<p class="text-right font-weight-bold">Impuestos Adicionales</p>
						</div>
						<div class="col-3" *ngIf="dteToShow.impuestos > 0">
							<p class="text-right font-weight-bold">
								{{ dteToShow.impuestos | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-7 col-2">
							<p class="text-right font-weight-bold">Exento</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.exento | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
						<div class="offset-7 col-2">
							<p class="text-right font-weight-bold">Total</p>
						</div>
						<div class="col-3">
							<p class="text-right font-weight-bold">
								{{ dteToShow.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #dteTracesModal let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="exampleModalLabel1">Trazabilidad</h5>
		</div>
		<form #TrazaForm="ngForm">
			<div class="modal-body">
				<div class="form-group row mx-auto" style="align-items: baseline">
					<label class="col-3 label-control" for="projectinput1">Documento:</label>
					<div class="col-9">
						<input type="text" value="{{ dteToShow.nombreTipoDte }} N° {{ dteToShow.folio }}" disabled name="ngModel" class="form-control" id="fecha" />
					</div>
				</div>
				<div class="form-group row mx-auto" style="align-items: baseline">
					<table class="table table-bordered" [hidden]="tracesToShow.length == 0">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Descripción</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let traza of tracesToShow">
								<td>
									{{ traza.fecha | date : "dd/MM/yyyy HH:mm" }}
								</td>
								<td style="overflow-wrap: anywhere">{{ traza.descripcion }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-success" data-dismiss="modal" (click)="d('Cross click')">Volver</button>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #dteSenderModal let-d="dismiss" let-c="close">
	<form [formGroup]="senderForm" (ngSubmit)="onSubmitSenderForm()" #senderFormRef="ngForm">
		<div class="modal-lg">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Envío de documentos por Correo</div>
					<div class="modal-wizard-desc">Indique correo y seleccione los documentos a enviar</div>
				</div>
				<!-- <h5 class="modal-title" id="titleModalSender"></h5> -->
				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" *ngIf="selected.length > 0 && selectedItem == undefined">
				<table class="table">
					<tr>
						<th>Folio</th>
						<th>Cliente</th>
						<th>Monto</th>
					</tr>
					<tr *ngFor="let dte of selected">
						<td>{{ dte.folio }}</td>
						<td>{{ dte.razonSocial }}</td>
						<td>{{ dte.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
				</table>
			</div>
			<div class="modal-body" *ngIf="selectedItem != undefined">
				<table class="table">
					<tr>
						<th>Folio</th>
						<th>Cliente</th>
						<th>Monto</th>
					</tr>
					<tr>
						<td>{{ selectedItem.folio }}</td>
						<td>{{ selectedItem.razonSocial }}</td>
						<td>{{ selectedItem.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
				</table>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto">
					<label for="email" class="col-3 label-control">Dirección de Correo</label>
					<div class="col-9">
						<input
							type="email"
							name="email"
							class="form-control"
							formControlName="receiverEmail"
							autocomplete="off"
							[ngClass]="{
								'is-invalid': (senderFormRef.submitted && senderForm.controls['receiverEmail'].invalid) || (senderForm.controls['receiverEmail'].touched && senderForm.controls['receiverEmail'].invalid)
							}" />
						<div class="invalid-feedback" *ngIf="senderFormRef.submitted && (senderForm.controls['receiverEmail'].invalid || senderForm.controls['receiverEmail'].touched || senderForm.controls['receiverEmail'].dirty)">
							<div *ngIf="senderForm.get('receiverEmail').errors?.required">Campo Obligatorio</div>
							<!-- <div *ngIf="senderForm.get('receiverEmail').errors?.email||senderForm.get('receiverEmail').errors?.pattern">Ingrese un email válido</div> -->
							<div *ngIf="senderForm.get('receiverEmail').hasError('toAddress')">Ingrese un email válido</div>
							<div *ngIf="senderForm.get('receiverEmail').errors?.maxlength">Ingrese menos de 320 carácteres</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<label for="ccEmail" class="col-3 label-control">CC</label>
					<div class="col-9">
						<input
							type="email"
							name="ccEmail"
							class="form-control"
							formControlName="ccEmail"
							autocomplete="new-password"
							[ngClass]="{
								'is-invalid': (senderFormRef.submitted && senderForm.controls['ccEmail'].invalid) || (senderForm.controls['ccEmail'].touched && senderForm.controls['ccEmail'].invalid)
							}" />
						<div class="invalid-feedback" *ngIf="senderFormRef.submitted && (senderForm.controls['ccEmail'].invalid || senderForm.controls['ccEmail'].touched || senderForm.controls['ccEmail'].dirty)">
							<div *ngIf="senderForm.get('ccEmail').errors?.required">Campo Obligatorio</div>
							<!-- <div *ngIf="senderForm.get('ccEmail').errors?.email||senderForm.get('ccEmail').errors?.pattern">Ingrese un email válido</div> -->
							<div *ngIf="senderForm.get('ccEmail').hasError('toAddress')">Ingrese un email válido</div>
							<div *ngIf="senderForm.get('ccEmail').errors?.maxLength">Ingrese menos de 320 carácteres</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<label for="ccoEmail" class="col-3 label-control">CCO</label>
					<div class="col-9">
						<input
							type="email"
							name="ccoEmail"
							class="form-control"
							formControlName="ccoEmail"
							autocomplete="new-password"
							[ngClass]="{
								'is-invalid': (senderFormRef.submitted && senderForm.controls['ccoEmail'].invalid) || (senderForm.controls['ccoEmail'].touched && senderForm.controls['ccoEmail'].invalid)
							}" />
						<div class="invalid-feedback" *ngIf="senderFormRef.submitted && (senderForm.controls['ccoEmail'].invalid || senderForm.controls['ccoEmail'].touched || senderForm.controls['ccoEmail'].dirty)">
							<div *ngIf="senderForm.get('ccoEmail').errors?.required">Campo Obligatorio</div>
							<!-- <div *ngIf="senderForm.get('ccoEmail').errors?.email||senderForm.get('ccoEmail').errors?.pattern">Ingrese un email válido</div> -->
							<div *ngIf="senderForm.get('ccoEmail').hasError('toAddress')">Ingrese un email válido</div>
							<div *ngIf="senderForm.get('ccoEmail').errors?.maxLength">Ingrese menos de 320 carácteres</div>
						</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<div class="col-6">
						<div class="row">
							<div class="col-6 label-control mr-5" for="pdfCheckbox">Enviar PDF</div>
							<div class="col-5">
								<input type="checkbox" formControlName="sendPdfCheckbox" class="form-check-input" id="pdfCheckbox" />
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="row">
							<div class="col-5 label-control">Enviar XML</div>
							<div class="col-7">
								<input type="checkbox" formControlName="sendXmlCheckbox" class="form-check-input" id="xmlCheckbox" />
							</div>
						</div>
					</div>
					<div class="offset-3 col-9 pl-4">
						<!-- <div class="invalid-feedback-group invalid-feedback row pl-3" *ngIf="senderFormRef.submitted && (senderForm.controls['sendPdfCheckbox'].value == false && senderForm.controls['sendXmlCheckbox'].value == false)">
                            <div>Favor indicar por lo menos un documento a enviar</div>
                       </div> -->
						<div class="invalid-feedback-group invalid-feedback row pl-3" *ngIf="senderForm.errors?.mailRepeated && (senderForm.touched || senderForm.dirty || senderFormRef.submitted)">
							<div>Favor no repetir direcciones de correo</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Enviar</span>
					</button>
					<button type="button" class="btn btn-secondary ml-3" (click)="c('Cross click')">
						<span class="d-none d-lg-block">Cerrar</span>
					</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #GuidesListToInvoices let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<form (ngSubmit)="sendGuidesToInvoicing(factGuiasFormRef)" #factGuiasFormRef="ngForm">
			<div class="modal-header">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Guias de Despacho a facturar</div>
					<div class="modal-wizard-desc">Esta es la lista de guias de despacho que ha seleccionado para ser facturadas</div>
				</div>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="form-inline pl-5">
						<div class="form-group ml-3">
							<label>Forma de Pago</label>
							<ng-select class="ml-5" [items]="formaPagoSource" required [placeholder]="'Selecccionar forma de pago'" bindLabel="nombreForma" [(ngModel)]="formaPagoFactGuias" name="formaPagoFactGuias" #formaPago="ngModel"> </ng-select>
						</div>
						<div *ngIf="!formaPago.valid && factGuiasFormRef.submitted" class="invalid-feedback ml-3">Campo requerido</div>
					</div>
				</div>
				<ngx-datatable #tableGuides class="material" [rows]="selected" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [perfectScrollbar]="configScroll" [limit]="5">
					<ngx-datatable-column name="Folio" [flexGrow]="4" [sortable]="false" [resizeable]="false">
						<ng-template ngx-datatable-cell-template let-row="row"
							><span>{{ row.folio }}</span></ng-template
						>
					</ngx-datatable-column>
					<ngx-datatable-column name="Monto" [flexGrow]="4" [sortable]="false" [resizeable]="false">
						<ng-template ngx-datatable-cell-template let-row="row"
							><span>{{ row.total | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span></ng-template
						>
					</ngx-datatable-column>
					<ngx-datatable-column name="Fecha" [flexGrow]="4" [sortable]="false" [resizeable]="false">
						<ng-template ngx-datatable-cell-template let-row="row"
							><span>{{ row.fecha | date : "dd/MM/yyyy" }}</span></ng-template
						>
					</ngx-datatable-column>
				</ngx-datatable>
			</div>
			<div class="modal-footer">
				<div class="form-group position-relative has-icon-left mb-0">
					<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
						<i class="la la-paper-plane-o d-lg-none"></i>
						<span class="d-none d-lg-block">Enviar</span>
					</button>
					<button type="button" class="btn btn-secondary ml-3" (click)="c('Cross click')">
						<span class="d-none d-lg-block">Cerrar</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-template>
