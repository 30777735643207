import { ActividadEconomicaEmisor } from "../ActividadEconomicaEmisor";
import { ConfiguracionEmision } from "../ConfiguracionEmision";
import { CuentaSii } from "../CuentaSii";
import { PlantillaImpresion } from "../PlantillaImpresion/PlantillaImpresion";
import { Sucursal } from "../Sucursal";
import { Emisor } from "./Emisor";
import { LogoWizardRequest } from "./LogoWizardRequest";

export class EmisorWizardRequest {
	usuarioId: string;
	emisorBase: Emisor;
	sucursal: Sucursal;
	actividades: ActividadEconomicaEmisor[];
	certificado: CertificadoRequest;
	logoEmisor: LogoWizardRequest;
	firstTime: boolean;
	//configuraciones:ConfiguracionEmision[];
}

export interface FileRequest {
	sucursalId: string;
	emisorId: string;
	fileB64: string;
}

export class FolioRequest implements FileRequest {
	sucursalId: string;
	emisorId: string;
	timbrajeString: string;
	fileB64: string;
	consumeFolio: boolean;
	consumoFolio: ConsumoFolio;
}
export class ConsumoFolio {
	desde: number;
	hasta: number;
}
export class CertificadoRequest implements FileRequest {
	fileB64: string;
	sucursalId: string;
	emisorId: string;
	certificadoString: string;
}

export class PlantillaFileRequest {
	public tipoPlantillaString: string;
	fileB64: string;
}
