import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { NgbAccordion, NgbAccordionModule, NgbAlertModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./modules/auth/_services/auth.service";
import { environment } from "src/environments/environment";
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { SplashScreenModule } from "./_metronic/partials/layout/splash-screen/splash-screen.module";
// #fake-start#
import { FakeAPIService } from "./_fake/fake-api.service";
import { PruebaComponenteComponent } from "./components/prueba-componente/prueba-componente.component";
import { VerificacionRutValidatorDirective } from "./directives/verifica-rut.directive";
import { AuthInterceptor } from "./helpers/auth.interceptor";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AlertModule } from "./alerts/alert.module";
import { MantenedorCertificadosComponent } from "./components/configuracion-dte/mantenedor-certificados/mantenedor-certificados.component";
import { SearchBarComponent } from "./components/layout/search-bar/search-bar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TablaMantenedorComponent } from "./components/layout/layout-mantenedor/tabla-mantenedor/tabla-mantenedor.component";
import { FormularioMantenedorComponent } from "./components/layout/layout-mantenedor/formulario-mantenedor/formulario-mantenedor.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MantenedorBaseComponent } from "./components/layout/layout-mantenedor/mantenedor-base/mantenedor-base.component";
import { FastSearchComponent } from "./components/layout/fast-search/fast-search.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgApexchartsModule } from "ng-apexcharts";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgxDropzoneModule } from "ngx-dropzone";
import { FacturadorMasivoComponent } from "./components/facturacion/facturador-masivo/facturador-masivo.component";
import { MatTableModule } from "@angular/material/table/";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { MantenedorDteEmitidosComponent } from "./components/facturacion/mantenedor-dte-emitidos/mantenedor-dte-emitidos.component";
import { UtilitiesService } from "./services/utilities.service";
// #fake-end#
//localizacion
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es-CL";
registerLocaleData(localeEs, "es");
import { MomentModule } from "ngx-moment";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { LayoutModule } from "./pages/layout.module";
import { LocalStorageService } from "./services/local-storage.service";
import { DatePipe } from "@angular/common";
import { AnuladorDteComponent } from "./components/facturacion/anulador-dte/anulador-dte.component";
import { MultiEmailDirective } from "./directives/multi-email.directive";
import { ToastFrameComponent } from "./components/layout/toast-management/toast-frame/toast-frame.component";
import { DashboardWidgetsComponent } from "./components/layout/dashboard-widgets/dashboard-widgets.component";
import { FoliosWidgetComponent } from "./components/widgets/folios-widget/folios-widget.component";
import { CertificadosWidgetComponent } from "./components/widgets/certificados-widget/certificados-widget.component";
import { ConexionesWidgetComponent } from "./components/widgets/conexiones-widget/conexiones-widget.component";
import { DocumentosEmitidosWidgetComponent } from "./components/widgets/documentos-emitidos-widget/documentos-emitidos-widget.component";
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { SameWordEmailValidator } from "./directives/same-word-email.directive";
import { FacturadorIndividualComponent } from "./components/facturacion/facturador-individual/facturador-individual.component";
import { BuscadorClienteComponent } from "./components/facturacion/facturador-individual/buscador-cliente/buscador-cliente.component";
import { ItemDetalleDteComponent } from "./components/facturacion/facturador-individual/item-detalle-dte/item-detalle-dte.component";
import { ListaItemDetalleDteComponent } from "./components/facturacion/facturador-individual/lista-item-detalle-dte/lista-item-detalle-dte.component";
import { ResumenListaDetallesComponent } from "./components/facturacion/facturador-individual/resumen-lista-detalles/resumen-lista-detalles.component";
import { OpcionesReferenciasDteComponent } from "./components/facturacion/facturador-individual/opciones-referencias-dte/opciones-referencias-dte.component";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { NgxCurrencyModule } from "ngx-currency";
import { MsalBroadcastService, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { MsalUserLandingComponent } from "./components/layout/msal-user-landing/msal-user-landing.component";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
//import { Library, library } from '@fortawesome/fontawesome-svg-core';
import {
	faBuilding,
	faCheckSquare,
	faDotCircle,
	faDoorOpen,
	faDoorClosed,
	faCalendarAlt,
	faFileExcel,
	faUserTie,
	faCog,
	faTags,
	faFileInvoiceDollar,
	faHandHoldingUsd,
	faIdCard,
	faStore,
	faIdBadge,
	faBriefcase,
	faFileContract,
	faEnvelope,
	faFilePdf,
	faFileCode,
	faBan,
	faListAlt,
	faInfoCircle,
	fas,
	faFileAlt,
	faBook,
	faEdit,
	faPencilAlt,
	faTimesCircle,
	faCheckCircle,
	faBookmark,
	faExclamationTriangle,
	faTrash,
	faPlusCircle,
	faFileImage,
	faExclamation,
	faKey,
	faServer,
	faRecycle,
	faRedo,
	faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";
import { MantenedorClientesComponent } from "./components/administracion/mantenedor-clientes/mantenedor-clientes.component";
import { ClientEntryComponent } from "./components/administracion/mantenedor-clientes/client-entry/client-entry.component";
import { MantenedorUsuariosComponent } from "./components/administracion/mantenedor-usuarios/mantenedor-usuarios.component";
import { MantenedorPerfilesComponent } from "./components/administracion/mantenedor-perfiles/mantenedor-perfiles.component";
import { PermissionBlockComponent } from "./components/layout/permission-block/permission-block.component";
import { PermissionListComponent } from "./components/administracion/mantenedor-perfiles/permission-list/permission-list.component";
import { ClientListComponent } from "./components/facturacion/facturador-individual/buscador-cliente/client-list/client-list.component";
import { UserPanelComponent } from "./components/layout/user-panel/user-panel.component";
import { MantenedorEmisoresComponent } from "./components/administracion/mantenedor-emisores/mantenedor-emisores.component";
import { MantenedorProductosComponent } from "./components/administracion/mantenedor-productos/mantenedor-productos.component";
import { ProductEntryComponent } from "./components/administracion/mantenedor-productos/product-entry/product-entry.component";

import { MantenedorPlantillasImpresionComponent } from "./components/configuracion-dte/mantenedor-plantillas-impresion/mantenedor-plantillas-impresion.component";
import { PlantillaEntryComponent } from "./components/configuracion-dte/mantenedor-plantillas-impresion/plantilla-entry/plantilla-entry.component";

import { MantenedorImpuestosComponent } from "./components/administracion/mantenedor-impuestos/mantenedor-impuestos.component";
import { TaxEntryComponent } from "./components/administracion/mantenedor-impuestos/tax-entry/tax-entry.component";
import { MantenedorGuiaDespachoComponent } from "./components/facturacion/mantenedor-guia-despacho/mantenedor-guia-despacho.component";
import { AsignadorPlantillasComponent } from "./components/configuracion-dte/mantenedor-plantillas-impresion/asignador-plantillas/asignador-plantillas.component";
import { MaxNumberDirective } from "./directives/max-number.directive";
import { ItemDetalleDocumentoComponent } from "./components/facturacion/facturador-individual/item-detalle-documento/item-detalle-documento.component";
import { ItemDetalleComisionComponent } from "./components/facturacion/facturador-individual/item-detalle-comision/item-detalle-comision.component";
import { ListaItemDetalleComisionComponent } from "./components/facturacion/facturador-individual/lista-item-detalle-comision/lista-item-detalle-comision.component";
import { ActividadEntryComponent } from "./components/administracion/mantenedor-emisores/actividad-entry/actividad-entry.component";
import { MantenedorConfiguracionEmisionComponent } from "./components/configuracion-dte/mantenedor-configuracion-emision/mantenedor-configuracion-emision.component";
import { ConfiguracionEmisionEntryComponent } from "./components/configuracion-dte/mantenedor-configuracion-emision/configuracion-emision-entry/configuracion-emision-entry.component";
import { PersonaAutorizadaEntryComponent } from "./components/administracion/mantenedor-emisores/persona-autorizada-entry/persona-autorizada-entry.component";
import { WizardEmisorComponent } from "./components/administracion/mantenedor-emisores/wizard-emisor/wizard-emisor.component";
import { MantenedorLogTransaccionesComponent } from "./components/administracion/mantenedor-log-transacciones/mantenedor-log-transacciones.component";
import { MantenedorUsuariosSiiComponent } from "./components/administracion/mantenedor-emisores/mantenedor-usuarios-sii/mantenedor-usuarios-sii.component";
import { TokenStorageService } from "./services/token-storage.service";
import { ReferencesListComponent } from "./components/facturacion/facturador-individual/opciones-referencias-dte/references-list/references-list.component";
import { CompanyAmbientComponent } from "./components/layout/company-ambient/company-ambient.component";
import { SemaforoEstadoDteComponent } from "./components/layout/semaforo-estado-dte/semaforo-estado-dte.component";
import { MantenedorCorreoEnvioComponent } from "./components/administracion/mantenedor-correo-envio/mantenedor-correo-envio.component";
import { CorreoEnvioEntryComponent } from "./components/administracion/mantenedor-correo-envio/correo-envio-entry/correo-envio-entry.component";
import { PlantillaCorreoEnvioEntryComponent } from "./components/administracion/mantenedor-correo-envio/plantilla-correo-envio-entry/plantilla-correo-envio-entry.component";
import { AsignadorPerfilComponent } from "./components/administracion/mantenedor-perfiles/assigned-emisores/asignador-perfil.component";
import { CompanyPanelComponent } from "./components/layout/company-panel/company-panel.component";
import { CuadroProductoDetalleComponent } from "./components/facturacion/facturador-individual/cuadro-producto-detalle/cuadro-producto-detalle.component";
//import { BnNgIdleService } from 'bn-ng-idle';
import { NgIdleModule } from "@ng-idle/core";
import { CuadroDocumentoDetalleComponent } from "./components/facturacion/facturador-individual/cuadro-documento-detalle/cuadro-documento-detalle.component";
import { LogoEmisorEntryComponent } from "./components/administracion/mantenedor-emisores/logo-emisor-entry/logo-emisor-entry.component";
import { MantenedorFoliosDefinitivoComponent } from "./components/configuracion-dte/mantenedor-folios-definitivo/mantenedor-folios-definitivo.component";
import { ConsolidadoVentasComponent } from "./components/administracion/consolidados/consolidado-ventas/consolidado-ventas.component";
import { TriStateCheckboxComponent } from "./components/layout/tri-state-checkbox/tri-state-checkbox/tri-state-checkbox.component";
function appInitializer(authService: AuthService) {
	return () => {
		return new Promise((resolve) => {
			authService.getUserByToken().subscribe().add(resolve);
		});
	};
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msal_client_key,
			authority: environment.msal_authority,
			redirectUri: environment.msal_return_url,
			navigateToLoginRequestUrl: false,
			postLogoutRedirectUri: environment.msal_postlogout_redirect_uri,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: true, // set to true for IE 11
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Verbose,
				piiLoggingEnabled: false,
			},
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

	return {
		interactionType: InteractionType.Popup,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ["user.read"],
		},
	};
}

@NgModule({
	declarations: [
		AppComponent,
		PruebaComponenteComponent,
		VerificacionRutValidatorDirective,
		MantenedorCertificadosComponent,
		SearchBarComponent,
		TablaMantenedorComponent,
		FormularioMantenedorComponent,
		MantenedorBaseComponent,
		FastSearchComponent,
		FacturadorMasivoComponent,
		MantenedorDteEmitidosComponent,
		AnuladorDteComponent,
		MultiEmailDirective,
		ToastFrameComponent,
		FoliosWidgetComponent,
		CertificadosWidgetComponent,
		ConexionesWidgetComponent,
		DocumentosEmitidosWidgetComponent,
		FacturadorIndividualComponent,
		BuscadorClienteComponent,
		ItemDetalleDteComponent,
		ListaItemDetalleDteComponent,
		ResumenListaDetallesComponent,
		OpcionesReferenciasDteComponent,
		MsalUserLandingComponent,
		MantenedorClientesComponent,
		ClientEntryComponent,
		MantenedorUsuariosComponent,
		MantenedorPerfilesComponent,
		PermissionBlockComponent,
		PermissionListComponent,
		ClientListComponent,
		UserPanelComponent,
		MantenedorEmisoresComponent,
		MantenedorProductosComponent,
		ProductEntryComponent,
		MantenedorPlantillasImpresionComponent,
		PlantillaEntryComponent,
		MantenedorImpuestosComponent,
		TaxEntryComponent,
		MantenedorGuiaDespachoComponent,
		AsignadorPlantillasComponent,
		MaxNumberDirective,
		ItemDetalleDocumentoComponent,
		DashboardWidgetsComponent,
		ItemDetalleComisionComponent,
		ListaItemDetalleComisionComponent,
		ActividadEntryComponent,
		MantenedorConfiguracionEmisionComponent,
		ConfiguracionEmisionEntryComponent,
		PersonaAutorizadaEntryComponent,
		WizardEmisorComponent,
		MantenedorLogTransaccionesComponent,
		MantenedorUsuariosSiiComponent,
		ReferencesListComponent,
		SemaforoEstadoDteComponent,
		MantenedorCorreoEnvioComponent,
		CorreoEnvioEntryComponent,
		PlantillaCorreoEnvioEntryComponent,
		AsignadorPerfilComponent,
		CompanyPanelComponent,
		CuadroProductoDetalleComponent,
		CuadroDocumentoDetalleComponent,
		LogoEmisorEntryComponent,
		MantenedorFoliosDefinitivoComponent,
		ConsolidadoVentasComponent,
		TriStateCheckboxComponent
	],

	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SplashScreenModule,
		TranslateModule.forRoot(),
		HttpClientModule,
		HighlightModule,
		ClipboardModule,
		NgbAlertModule,
		AlertModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		PerfectScrollbarModule,
		NgSelectModule,
		NgApexchartsModule,
		FormsModule,
		CommonModule,
		NgxDropzoneModule,
		MatTableModule,
		NgbAccordionModule,
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.wanderingCubes,
			fullScreenBackdrop: true,
			// backdropBackgroundColour: 'rgba(0,0,0,0.1)',
			// backdropBorderRadius: '4px',
			// primaryColour: '#ffffff',
			// secondaryColour: '#ffffff',
			// tertiaryColour: '#ffffff'
		}),
		// #fake-start#
		environment.isMockEnabled
			? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
					passThruUnknownUrl: true,
					dataEncapsulation: false,
			  })
			: [],
		// #fake-end#
		AppRoutingModule,
		InlineSVGModule.forRoot(),
		NgbModule,
		SweetAlert2Module.forRoot(),
		MomentModule,
		NgxDaterangepickerMd.forRoot(),
		LayoutModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		MsalModule,
		FontAwesomeModule,
		NgIdleModule.forRoot(),
		NgxCurrencyModule,
	],
	providers: [
		// {
		//   provide: ErrorHandler,
		//   useClass: ErrorHandlingInterceptor,
		//   multi:false
		// },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		{ provide: localeEs, useValue: "es" },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			multi: true,
			deps: [AuthService],
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import("highlight.js/lib/core"),
				languages: {
					xml: () => import("highlight.js/lib/languages/xml"),
					typescript: () => import("highlight.js/lib/languages/typescript"),
					scss: () => import("highlight.js/lib/languages/scss"),
					json: () => import("highlight.js/lib/languages/json"),
				},
			},
		},

		UtilitiesService,
		LocalStorageService,
		DatePipe,
		CurrencyPipe,
		MsalService,
		MsalBroadcastService,
		//BnNgIdleService
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas);
		library.addIcons(
			faObjectGroup,
			faBuilding,
			faCheckSquare,
			faDotCircle,
			faDoorOpen,
			faDoorClosed,
			faCalendarAlt,
			faFileExcel,
			faUserTie,
			faCog,
			faTags,
			faFileInvoiceDollar,
			faHandHoldingUsd,
			faIdCard,
			faStore,
			faIdBadge,
			faBriefcase,
			faFileContract,
			faEnvelope,
			faFilePdf,
			faFileCode,
			faListAlt,
			faInfoCircle,
			faBan,
			faFileAlt,
			faBook,
			faEdit,
			faPencilAlt,
			faTimesCircle,
			faCheckCircle,
			faBookmark,
			faExclamationTriangle,
			faTrash,
			faPlusCircle,
			faFileImage,
			faExclamation,
			faKey,
			faServer,
			faRecycle,
			faRedo
		);
	}
}
