import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { Observable, Subscription } from "rxjs";
import { Divisa } from "src/app/models/Divisa";
import { OptionsReferenceDteEmitido } from "src/app/models/Dte/OptionsReferenceDteEmitido";
import { ReferenciaDteEmitido } from "src/app/models/Dte/ReferenciaDteEmitido";
import { TipoReferenciaEnum } from "src/app/models/Dte//TipoReferencia.enum";
import { TipoDte } from "src/app/models/tipoDte";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TipoDocumentoReferencia } from "src/app/models/TipoDocRef";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import Swal from "sweetalert2";
import { PositiveNumberValidator } from "src/app/directives/positive-number.directive";
import { ThrowStmt } from "@angular/compiler";
type FormGroupAttach = [string, FormGroup];
@Component({
	selector: "app-opciones-referencias-dte",
	templateUrl: "./opciones-referencias-dte.component.html",
	styleUrls: ["./opciones-referencias-dte.component.scss"],
})
export class OpcionesReferenciasDteComponent implements OnInit {
	public haveDteReference: boolean = true;
	public dteReference: ReferenciaDteEmitido = new ReferenciaDteEmitido();
	public tipoDteSource: TipoDocumentoReferencia[];
	public tipoReferenciaEnumSource: any[];
	private optionsReference: OptionsReferenceDteEmitido = new OptionsReferenceDteEmitido();
	public optionsReferenceFormGroup: FormGroup;
	public eferenceDteFormGroup: FormGroup;
	public divisasValueFormGroup: FormGroup;
	public divisasList: Divisa[] = [];
	private divisasObservable$: Observable<Divisa[]>;
	public resetingDte$: Observable<boolean>;
	public invoiceType$: Observable<InvoiceType>;
	public showingCurrencyOptions: boolean = true;
	private subscriptions: Subscription[] = [];
	public detailWithTax$: Observable<boolean>;
	public currentInvoiceType: InvoiceType;
	private referencesList: ReferenciaDteEmitido[] = [];
	private refFromGuides$: Observable<ReferenciaDteEmitido[]>;
	public showGlobalRefCheckbox: boolean = false;
	public disableCLPOption: boolean = false;
	constructor(private ref: ChangeDetectorRef, private localStorage: LocalStorageService, private builder: FormBuilder, private facturador: FacturacionIndividualService) {
		this.divisasObservable$ = this.facturador.divisasListObservable;
		this.resetingDte$ = this.facturador.submittedDteObservable;
		this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
		this.detailWithTax$ = this.facturador.detailWithTaxInOptionsObservable;
		this.refFromGuides$ = this.facturador.referencesListFromGuidesObservable;
	}
	locale = {
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD/MM/YYYY",
	};
	ngOnInit(): void {
		this.tipoDteSource = JSON.parse(this.localStorage.getTipoDocReferencias()) as TipoDocumentoReferencia[];
		this.tipoReferenciaEnumSource = Object.keys(TipoReferenciaEnum).map((key) => ({
			key,
			name: TipoReferenciaEnum[key],
		}));
		this.tipoReferenciaEnumSource = this.tipoReferenciaEnumSource.splice(0, 3);
		this.tipoDteSource = [...this.tipoDteSource.filter((value, index, array) => array.findIndex((type) => type.codigo === value.codigo) === index)];
		this.createDivisasValueForm();
		this.createOptionsReferenceForm();
		const divisasSubscription = this.divisasObservable$.subscribe((list) => {
			this.divisasList = list.filter((divisa) => divisa != null);
			this.ref.detectChanges();
		});
		const invoiceTypeSubscription = this.invoiceType$.subscribe((res) => {
			this.modificationsByInvoiceType(res);
		});
		this.changeReference();
		//this.optionsReferenceFormGroup.get('referenciaDte').disable();

		const resetSubscription = this.resetingDte$.subscribe((res) => {
			if (res) {
				this.optionsReference.haveDteReference = false;
				this.optionsReference.tipoMoneda = "CLP";
			}
		});

		// const detailWithTaxSubscription = this.detailWithTax$.subscribe(
		//   (res)=>{
		//     this.optionsReferenceFormGroup.get("detallesExentos").patchValue(res);
		//     //console.log("valor recibido",res, this.optionsReferenceFormGroup.get("detallesExentos").value);
		//     this.onSubmit(false);
		//   }
		// )
		const guideRefSubscription = this.refFromGuides$.subscribe((res) => {
			if (res.length > 0) {
				this.optionsReferenceFormGroup.controls.haveDteReference.patchValue(true);
				this.optionsReferenceFormGroup.controls.detallesExentos.patchValue(false);
				this.changeReference();
				this.addGlobalRefCheckbox();
				this.showGlobalRefCheckbox = true;
			} else {
				this.showGlobalRefCheckbox = false;
				if (this.optionsReferenceFormGroup.controls.refGlobal) {
					this.optionsReferenceFormGroup.removeControl("refGlobal");
				}
			}
		});
		this.optionsReference.haveDteReference = false;
		this.optionsReference.tipoMoneda = "CLP";
		this.facturador.updateOptionsReferences(this.optionsReference);
		if (invoiceTypeSubscription) {
			this.subscriptions.push(invoiceTypeSubscription);
		}
		this.subscriptions.push(resetSubscription);
		this.subscriptions.push(divisasSubscription);
		this.subscriptions.push(guideRefSubscription);
		//this.subscriptions.push(detailWithTaxSubscription);
	}

	changeReference() {
		//console.log(this.haveDteReference);
		this.ref.detectChanges();
		if (this.optionsReferenceFormGroup.controls.haveDteReference) {
			//this.attachControls(this.optionsReferenceFormGroup,[["referenciaDte",this.referenceDteFormGroup]])
			this.optionsReferenceFormGroup.controls.dteReferenceList.setValidators([Validators.required]);
		} else if (!this.optionsReferenceFormGroup.controls.haveDteReference) {
			//this.detachControls(this.optionsReferenceFormGroup,["referenciaDte"]);
			this.optionsReferenceFormGroup.controls.dteReferenceList.setValidators([]);
		}
		this.onSubmit(false);
	}

	changeGlobalReference() {
		this.ref.detectChanges();
		if (this.optionsReferenceFormGroup.controls.haveDteReference && this.optionsReferenceFormGroup.controls.refGlobal) {
			this.optionsReferenceFormGroup.controls.dteReferenceList.setValidators([Validators.required]);
		}
		this.onSubmit(false);
	}
	changeDetallesConIVA(event) {
		this.optionsReference.sonDetallesConIVA = event.target.checked;
		this.onSubmit(false);
	}

	getReferencesList(event: ReferenciaDteEmitido[]) {
		if (this.optionsReferenceFormGroup.controls.haveDteReference && event.length > 0) {
			this.optionsReference.listaReferencias = event;
			this.optionsReferenceFormGroup.controls.dteReferenceList.patchValue(event);
			this.onSubmit(false);
		} else {
			this.optionsReference.listaReferencias = undefined;
			this.optionsReferenceFormGroup.controls.dteReferenceList.patchValue(undefined);
		}
	}

	createOptionsReferenceForm() {
		this.optionsReferenceFormGroup = this.builder.group({
			haveDteReference: [false],
			tipoMoneda: ["CLP"],
			detallesExentos: [true],
			dteReferenceList: [],
			//referenciaDte:this.referenceDteFormGroup
		});
	}
	// updateDivisaValue(){
	//   var value = this.divisasValueFormGroup.controls.newValorDivisa.value;
	//   this.onSubmit(true);
	// }
	createDivisasValueForm() {
		this.divisasValueFormGroup = this.builder.group({
			newValorDivisa: [, [Validators.required, Validators.min(1), PositiveNumberValidator]],
		});
	}

	onSubmit(updateDivisaValue: boolean) {
		//check if tipoMoneda is CLP to override currency selected by List
		if (this.optionsReferenceFormGroup.controls.tipoMoneda.value == "CLP") {
			this.optionsReferenceFormGroup.controls.tipoMonedaSelector.patchValue(undefined);
		}
		if (this.optionsReferenceFormGroup.valid) {
			this.optionsReference.sonDetallesConIVA = this.optionsReferenceFormGroup.controls.detallesExentos.value;
			this.optionsReference.haveDteReference = this.optionsReferenceFormGroup.controls.haveDteReference.value;
			this.optionsReference.tipoMoneda = this.optionsReferenceFormGroup.controls.tipoMoneda.value;
			if (updateDivisaValue) {
				this.optionsReference.valorTipoMoneda = this.divisasValueFormGroup.controls.newValorDivisa.value;
			}
			//optional submit of global reference checkbox value
			if (this.showGlobalRefCheckbox) {
				this.optionsReference.globalReferenceForGuideRefs = this.optionsReferenceFormGroup.controls.refGlobal.value;
			}
			if (this.optionsReferenceFormGroup.valid && (this.optionsReference.tipoMoneda == "OTHER" ? this.divisasValueFormGroup.valid : true)) {
				this.facturador.updateOptionsReferences(this.optionsReference);
			}
		} else if (this.optionsReference.haveDteReference && !this.optionsReferenceFormGroup.valid) {
			Swal.fire({
				icon: "error",
				title: "Error en Referencias",
				text: "Las referencias ingresadas debe considerar un tipo de documento, razón de referencia y folio asociado. Seleccione el tipo de documento de la referencia o complete el campo correspondiente.",
			});
		}
	}

	detectChangeReferenceDate(event) {
		this.dteReference.fechaDteReferenciado = moment(event.startDate).toDate();
		//console.log(moment(event.startDate).format('L'),this.dteReference);
	}
	attachControls(parentFormGroup: FormGroup, childrenFormGroupToAttach: FormGroupAttach[]) {
		childrenFormGroupToAttach.forEach((st) => {
			parentFormGroup.addControl(st[0], st[1]);
			parentFormGroup.get(st[0]).enable();
		});
	}
	detachControls(parentFormGroup: FormGroup, childrenFormGroupToDetach: string[]) {
		childrenFormGroupToDetach.forEach((fg) => {
			parentFormGroup.removeControl(fg);
			parentFormGroup.get(fg).disable();
		});
	}

	detectChangetipoDte(event) {
		this.ref.detectChanges();
	}

	modificationsByInvoiceType(type: InvoiceType) {
		this.currentInvoiceType = type;
		switch (type) {
			case InvoiceType.Boleta: {
				this.showingCurrencyOptions = false;
				this.disableCLPOption = null;
				this.ref.detectChanges();
				//console.log(type,this.showingCurrencyOptions);
				break;
			}
			case InvoiceType.Factura: {
				this.showingCurrencyOptions = true;
				this.disableCLPOption = null;
				this.ref.detectChanges();
				break;
			}
			case InvoiceType.GuiaDespacho: {
				this.showingCurrencyOptions = true;
				this.disableCLPOption = null;
				this.ref.detectChanges();
				break;
			}
			default: {
				this.disableCLPOption = null;
				this.ref.detectChanges();
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => {
			sub.unsubscribe();
		});
	}

	addGlobalRefCheckbox() {
		this.optionsReferenceFormGroup.addControl("refGlobal", new FormControl(false));
	}
}
