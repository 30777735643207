import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { runInThisContext } from "vm";
import { Permiso } from "../models/Perfil/Permiso";
import { Permission } from "../shared/models/Permission";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
	providedIn: "root",
})
export class PermissionService {
	private PERMISSIONS_ROLE = "permission-list";
	private ROUTE_PERMISSIONS = "route-permissions";
	private currentPermissions: Permission[] = [];
	private routePermissions: Map<string, boolean> = new Map();
	private basePermission: Permission = undefined;
	permissionContainer = window.sessionStorage;
	public isSuperAdmin = this.tokenService.getUser().superAdmin;
	public isOffline: boolean = false;

	constructor(private tokenService: TokenStorageService) {
		if (this.currentPermissions.length == 0) {
			this.getPermissionList();
		}
	}

	savePermissionList(list: Permission[]) {
		if (list && list.length > 0) {
			this.permissionContainer.removeItem(this.PERMISSIONS_ROLE);
			this.permissionContainer.setItem(this.PERMISSIONS_ROLE, JSON.stringify(list));
			this.currentPermissions = list;
			this.loadRoutePermissions();
		}
		if (!list || list.length == 0) {
			this.getPermissionList();
			this.loadRoutePermissions();
		}
	}

	getPermissionList() {
		let setList = JSON.parse(this.permissionContainer.getItem(this.PERMISSIONS_ROLE)) as Permission[];
		this.currentPermissions = setList;
		this.loadRoutePermissions();
	}

	getPermission(code: string) {
		let permission = this.currentPermissions ? this.currentPermissions.find((perm) => perm.codigo == code) : undefined;
		if (!permission) {
			permission = new Permission();
			permission.codigo = code;
			permission.asignado = false;
		}
		return permission;
	}

	getAccessPermission() {
		if (this.currentPermissions) {
			let perm = this.currentPermissions.find((perm) => perm.codigo === "10");
			if (perm) {
				this.basePermission = perm;
				return this.basePermission;
			} else {
				let dummy = new Permiso();
				dummy.asignado = true;
				return dummy;
			}
		} else {
			let dummy = new Permiso();
			dummy.asignado = true;
			return dummy;
		}
	}

	signOut(): void {
		this.currentPermissions = [];
		this.permissionContainer.removeItem(this.PERMISSIONS_ROLE);
	}

	loadRoutePermissions() {
		//load the Map of the relationships between route strings and permissions

		this.routePermissions.set("/inicio", true);
		this.routePermissions.set("/cuenta", true);
		this.routePermissions.set("/config-emision", true);
		this.routePermissions.set("/plantillas", this.isSuperAdmin); //this.getPermission('20-30')?this.getPermission('20-30').asignado
		this.routePermissions.set("/logs", this.isSuperAdmin); //this.getPermission('30') && this.getPermission('30-100')?this.getPermission('30').asignado && this.getPermission('30-100').asignado:false
		this.routePermissions.set("/correoEnvio", this.isSuperAdmin); //this.getPermission('40') && this.getPermission('40-18')?this.getPermission('40').asignado && this.getPermission('40-18').asignado:false
		this.routePermissions.set("/impuestos", this.isSuperAdmin); //this.getPermission('30')?this.getPermission('30-70').asignado:false
		this.routePermissions.set("/resellers", this.isSuperAdmin);
		this.routePermissions.set("/plan", true);
		this.routePermissions.set("/consolidado/ventas", true);
		this.routePermissions.set("/folios", this.getPermission("20-10") ? this.getPermission("20-10").asignado : false); //if dont get ConfiguracionDte permission, folios only should be 20-10
		this.routePermissions.set("/certificados", this.getPermission("20-20") ? this.getPermission("20-20").asignado : false); //if dont get ConfiguracionDte permission, Certificados only 20-20
		this.routePermissions.set("/proveedores", this.getPermission("30") ? this.getPermission("30-30").asignado : false); ////check parent Permission "Mantenedores" and own permission "PRoveedores"
		this.routePermissions.set("/clientes", this.getPermission("30") ? this.getPermission("30-20").asignado : false); ////check parent Permission "Mantenedores" and own permission "Clientes"
		this.routePermissions.set("/productos", this.getPermission("30") ? this.getPermission("30-60").asignado : false);
		this.routePermissions.set("/usuarios", this.getPermission("30") ? this.getPermission("30-40").asignado : false);
		this.routePermissions.set("/alertas", this.getPermission("30") ? this.getPermission("30-110").asignado : false);
		this.routePermissions.set("/emisores", this.getPermission("30") && this.getPermission("30-80") ? this.getPermission("30").asignado && this.getPermission("30-80").asignado : false);
		this.routePermissions.set("/factura-masiva", this.getPermission("40") && this.getPermission("40-10") ? this.getPermission("40").asignado && this.getPermission("40-10").asignado : false); //only own permission
		this.routePermissions.set("/documentos-emitidos", this.getPermission("40") && this.getPermission("40-18") ? this.getPermission("40").asignado && this.getPermission("40-18").asignado : false);
		this.routePermissions.set("/exportaciones", this.getPermission("40") && this.getPermission("40-19") ? this.getPermission("40").asignado && this.getPermission("40-19").asignado : false);
		this.routePermissions.set("/boletas-honorarios-emitidas", this.getPermission("80") ? this.getPermission("80").asignado : false);
		this.routePermissions.set("/emisor-boletas-honorarios", this.getPermission("80") && this.getPermission("80-16") ? this.getPermission("80").asignado && this.getPermission("80-16").asignado : false);
		this.routePermissions.set("/boletas-honorarios-recibidas", this.getPermission("81") ? this.getPermission("81").asignado : false);

		this.routePermissions.set("/guias-despacho", this.getPermission("40") && this.getPermission("40-20") ? this.getPermission("40").asignado && this.getPermission("40-20").asignado : false);
		this.routePermissions.set("/facturador-individual", this.getPermission("40") && this.getPermission("40-18-10") ? this.getPermission("40").asignado && this.getPermission("40-18-10").asignado : false);
		this.routePermissions.set("/rvd", this.getPermission("40") && this.getPermission("40-18-32") ? this.getPermission("40").asignado && this.getPermission("40-18-32").asignado : false);
		this.routePermissions.set("/cesiones", !this.isOffline && this.getPermission("45") && this.getPermission("45-10") ? this.getPermission("45").asignado && this.getPermission("45-10").asignado : false);
		this.routePermissions.set("/documentos-recibidos", this.getPermission("50") && this.getPermission("50-10") ? this.getPermission("50").asignado && this.getPermission("50-10").asignado : false);
		this.routePermissions.set("/crear-emisor", this.getPermission("50") && this.getPermission("30-80-22") ? this.getPermission("30").asignado && this.getPermission("30-80-22").asignado : false);
		this.routePermissions.set("/clientes-internacionales", this.getPermission("30") && this.getPermission("30-25") ? this.getPermission("30").asignado && this.getPermission("30-25").asignado : false);
		this.routePermissions.set("/crear-emisor", this.getPermission("30") && this.getPermission("30-80-22") ? this.getPermission("30").asignado && this.getPermission("30-80-22").asignado : false);
		this.routePermissions.set("/participantes", this.getPermission("70") && this.getPermission("70-10") ? this.getPermission("70").asignado && this.getPermission("70-10").asignado : false);
		this.routePermissions.set("/empresa", this.getPermission("30") && this.getPermission("30-80-26") ? this.getPermission("30").asignado && this.getPermission("30-80-26").asignado : false);
		this.routePermissions.set("/facturas-compras", this.getPermission("40") && this.getPermission("40-18") ? this.getPermission("40").asignado && this.getPermission("40-18").asignado : false);
		this.routePermissions.set("/cesionarios", !this.isOffline && this.getPermission("30") && this.getPermission("30-15") ? this.getPermission("30").asignado && this.getPermission("30-15").asignado : false);
		this.routePermissions.set("/perfiles", this.getPermission("30") && this.getPermission("30-50") ? this.getPermission("30").asignado && this.getPermission("30-50").asignado : false);
		this.routePermissions.set("/perfiles/permisos", this.getPermission("30-40-18") || this.getPermission("30-50-14").asignado ? this.getPermission("30-40-18").asignado || this.getPermission("30-50-14").asignado : false);

		if (this.routePermissions.size > 0) {
			this.saveRoutePermissionList();
		}
	}

	saveRoutePermissionList() {
		//save the loaded routePermissions into storage: session | local , for persistency of the Map if page is reloaded preventing the logout of the app
		this.permissionContainer.removeItem(this.ROUTE_PERMISSIONS);
		this.permissionContainer.setItem(this.ROUTE_PERMISSIONS, JSON.stringify(this.routePermissions));
	}

	getRoutePermission(routeName: string): boolean {
		this.checkSuperAdmin();
		if (!this.routePermissions.has(routeName)) {
			this.getPermissionList();
		}
		let permission = this.routePermissions.has(routeName) ? this.routePermissions.get(routeName) : false;
		return permission;
	}

	fakePermission(perm: Permission) {
		let code = perm.codigo.split("-");
		return code[0] == "10" || code[0] == "20";
	}

	checkSuperAdmin() {
		if (this.isSuperAdmin == undefined) {
			this.isSuperAdmin = this.tokenService.getUser().superAdmin;
		}
	}
}
