import * as moment from "moment";

export const ConfigConsolidados = {
	barraBusqueda: {
		criterios: ["Razon Social", "Rut Cliente", "Folio", "Track Id"],
		criteriosKeys: ["razonSocialReceptor", "rutReceptor", "folio", "trackId"],
		criterioPrincipal: "Razon Social",
		filiales: [] as any[],
		fechaInicio: null,
		fechaFin: null,
		datePicker: true,
		nombreCampoDatePicker: "Fecha Emisión (Desde - Hasta)",
	},
	table: {
		title: "Consolidado de Ventas",
		add: "Carga Documento",
		options: [
			{
				name: "Importar",
				active: true,
				class: "ft-upload",
			},
			{
				name: "Exportar",
				active: true,
				class: "ft-download",
			},
			{
				name: "Solicitar Soporte",
				active: true,
				class: "ft-shuffle",
			},
		],
	},
};
