import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { LayoutService } from "../../../../../core";
import { Observable, Subscription } from "rxjs";
import { UserModel } from "../../../../../../modules/auth/_models/user.model";
import { AuthService } from "../../../../../../modules/auth/_services/auth.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { Router } from "@angular/router";
import { UsuarioService } from "src/app/services/usuario.service";
import { Usuario } from "src/app/models/Usuario/Usuario";
import Swal from "sweetalert2";
import { SessionUser } from "src/app/shared/models/sessionUser";
import { ChangeCompanyService } from "src/app/services/changecompany.service";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LocalStorage } from "@ng-idle/core";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
	selector: "app-user-offcanvas",
	templateUrl: "./user-offcanvas.component.html",
	styleUrls: ["./user-offcanvas.component.scss"],
})
export class UserOffcanvasComponent implements OnInit, AfterViewInit, OnDestroy {
	extrasUserOffcanvasDirection = "offcanvas-right";
	public _user: Usuario = new Usuario();
	public _userSession: SessionUser;
	public emisor: Emisor;
	msalUserLogged: boolean = false;

	private subscriptionChangeEnvironment: Subscription;

	private currentUserId: string = this.tokenService.getUser().id;
	constructor(private layout: LayoutService, private localStorage: LocalStorageService, private changeCompanyService: ChangeCompanyService, private auth: AuthService, private tokenService: TokenStorageService, private router: Router) {}
	ngAfterViewInit(): void {
		this.emisor = (JSON.parse(this.localStorage.getEmisores()) as Emisor[]).find((em) => em.emisorId == this.tokenService.getCompany());
		this.extrasUserOffcanvasDirection = `offcanvas-${this.layout.getProp("extras.user.offcanvas.direction")}`;
		this._user = this.localStorage.getListaUsuarios().find((u) => u.usuarioId == this.currentUserId);
		this.subscriptionChangeEnvironment = this.localStorage.environmentChanged.subscribe((newEnvironment) => {
			this.emisor.ambiente = newEnvironment;
		});
	}

	ngOnDestroy(): void {
		// Asegúrate de des-suscribirte del observable para prevenir pérdidas de memoria
		this.subscriptionChangeEnvironment.unsubscribe();
	}

	// ngOnDestroy(): void {
	// 	this.changeCompanyService.companyChanged.unsubscribe();
	// }
	ngOnInit(): void {
		this.changeCompanyService.companyChanged.subscribe((newEmisor: Emisor) => {
			this.emisor = newEmisor;
		});
	}

	logout() {
		this.auth.logout();
		//document.location.reload();
	}

	externalLogout() {
		this.tokenService.signOut();
		this.tokenService.msalLogout();
	}

	goToProfile() {
		this.router.navigateByUrl("/cuenta", {
			state: { userId: this.currentUserId },
		});
	}

	goToCompanyProfile() {
		this.router.navigateByUrl("/empresa", {
			state: { userId: this.currentUserId },
		});
	}

	goToAccount() {
		this.router.navigateByUrl("/plan", {
			state: { userId: this.currentUserId },
		});
	}
}
