import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { SortType } from "@swimlane/ngx-datatable";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { Producto } from "src/app/models/Producto/Producto";
import { ProductoFilter } from "src/app/models/Producto/ProductoFilter";
import { EmisorService } from "src/app/services/emisor.service";
import { ImpuestoService } from "src/app/services/impuesto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { ProductoService } from "src/app/services/producto.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigProductos } from "./config-productos";

@Component({
   selector: "app-mantenedor-productos",
   templateUrl: "./mantenedor-productos.component.html",
   styleUrls: ["./mantenedor-productos.component.scss"],
})
export class MantenedorProductosComponent implements OnInit {
   SortType = SortType;
   public loading: boolean = false;
   public rowsMantenedor: Producto[];
   public page: Page = new Page();
   private filter: ProductoFilter = new ProductoFilter();
   private createProductModal: NgbModalRef;
   private editProductModal: NgbModalRef;
   private detailsProductModal: NgbModalRef;
   private taxesProductModal: NgbModalRef;
   private emisorId: string = this.tokenStorage.getCompany();
   private sucursalId: string = this.tokenStorage.getSubsidiary();
   public selectedProduct: Producto = undefined;
   public configLocal = ConfigProductos;
   public componentStarted: boolean = false;
   public emisorSource: Emisor[];
   @ViewChild("tableMobile") tableRef: any;
   public superAdm = false;
   constructor(private modal: NgbModal, private tokenStorage: TokenStorageService, private productService: ProductoService, private ref: ChangeDetectorRef, public permissions: PermissionService, private impuestoService: ImpuestoService, private localStorage: LocalStorageService, public utilities: UtilitiesService, private emisorService: EmisorService) {
      this.superAdm = this.tokenStorage.getUser().superAdmin;
      this.emisorSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
      if (this.superAdm) {
         this.getEmisoresFullList();
      }
   }

   ngOnInit(): void {
      this.filter.emisorId = this.emisorId;
      this.filter.sucursalId = this.sucursalId;
      this.setPage({ offset: 0 });
   }
   setPageSize() {
      //this.page.currentPageSize = this.page.page
      this.setPage({ offset: 0 });
   }
   setPage(pageInfo, event?) {
      this.loading = true;
      this.page.pageNumber = pageInfo.offset;
      let localPage = JSON.parse(JSON.stringify(this.page));
      localPage.pageNumber += 1;

      this.productService
         .getDataByPage(this.filter, localPage)
         .subscribe(
            (result) => {
               this.rowsMantenedor = result.data as Producto[];
               this.setPageFromResponse(this.page, result);
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Productos",
                  text: "No ha sido posible obtener la lista de productos disponibles,favor recargue la página." + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.componentStarted = true;
            this.ref.detectChanges();
         });
   }
   searchElementsByFilter(event) {
      let filterLocal = new ProductoFilter();
      this.filter = { ...filterLocal };
      this.filter[event.criterio] = event.busqueda;
      this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
      this.filter.activo = event.registroActivo;
      this.filter.exento = event.productoExento;
      //this.filter.emisorId =this.emisorId;
      this.filter.emisorId = event.emisorId ? event.emisorId : this.emisorId;
      this.filter.sucursalId = event.sucursalId ? event.sucursalId : this.sucursalId;
      this.setPage({ offset: 0 });
   }
   setPageFromResponse(currentPage: Page, data: PagedResponse<Producto>) {
      currentPage.pageSize = data.pageSize;
      currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
      currentPage.totalPages = data.totalPages;
      currentPage.totalElements = data.totalRecords;
   }
   openCreateProductModal(modalRef) {
      this.createProductModal = this.modal.open(modalRef, {
         windowClass: "",
         size: "lg",
      });
   }
   openEditProductModal(modalRef, row: Producto) {
      this.selectedProduct = row;
      this.editProductModal = this.modal.open(modalRef, {
         windowClass: "",
         size: "lg",
      });
   }
   openDetailsProductModal(modalRef, row: Producto) {
      this.selectedProduct = row;
      this.detailsProductModal = this.modal.open(modalRef, {
         windowClass: "",
         size: "",
      });
   }

   exportToExcel() {
      this.getAllRowsByFilter(this.page, this.filter);
   }

   getAllRowsByFilter(currentPageRef: Page, currentFilter: ProductoFilter) {
      let fullPage: Page = new Page();
      fullPage.pageSize = currentPageRef.totalElements;
      fullPage.pageNumber = 0;
      fullPage.totalElements = currentPageRef.totalElements;
      fullPage.totalPages = 1;
      this.loading = true;
      this.productService.getDataByPage(currentFilter, fullPage).subscribe(
         (result) => {
            let elements = result.data as Producto[];
            this.ref.detectChanges();
            elements.forEach((row) => {});
            this.loading = false;
            this.ref.detectChanges();
            this.exportExcelData(elements);
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error al exportar",
               text: "No es posible exportar los datos. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error),
            });
         },
         () => {
            this.loading = false;
            this.ref.detectChanges();
         }
      );
   }

   exportExcelData(arrayToExport: Array<Producto>): void {
      let arregloExportar = [];
      arrayToExport.forEach((item) => {
         let elementToExport = {};
         elementToExport["Nombre"] = item.nombre;
         elementToExport["Codigo de Barras"] = item.codigoBarra;
         elementToExport["unidad de medida"] = item.unidadMedida;
         elementToExport["Precio"] = item.precio;
         elementToExport["Exento"] = item.exento ? "Exento" : "Afecto";
         arregloExportar.push(elementToExport);
      });
      this.utilities.exportAsExcelFile(arregloExportar, "Productos");
   }

   closeCreateModal() {
      this.createProductModal.close();
   }
   closeEditModal() {
      this.selectedProduct = undefined;
      this.editProductModal.close();
   }
   closeDetailsModal() {
      this.selectedProduct = undefined;
      this.detailsProductModal.close();
   }

   productWithTaxesRequest(event: Producto) {
      if (!event.impuestos || event.impuestos.length <= 0) {
         this.sendNewProductOnly(event);
      } else {
         this.sendNewProductWithTaxes(event);
      }
   }

   sendNewProductOnly(event: Producto) {
      event.emisorId = this.emisorId;
      event.sucursalId = this.sucursalId;
      this.loading = true;
      this.ref.detectChanges();
      this.productService
         .sendProductRequest(event)
         .subscribe(
            (result) => {
               this.ref.detectChanges();
               this.setPage({ offset: 0 });
               this.ref.detectChanges();
               Swal.fire({
                  icon: "success",
                  title: "Ingreso de Producto",
                  text: "Se ha guardado el producto ingresado exitosamente.",
               });
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible guardar el producto ingresado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.closeCreateModal();
            this.ref.detectChanges();
         });
   }

   sendNewProductWithTaxes(event: Producto) {
      event.emisorId = this.emisorId;
      event.sucursalId = this.sucursalId;
      this.loading = true;
      this.ref.detectChanges();
      this.productService
         .sendProductRequest(event)
         .subscribe(
            (result) => {
               this.ref.detectChanges();
               let newProduct = result.data as Producto;
               if (event.impuestos) {
                  newProduct.impuestos = event.impuestos;
                  this.loading = true;
                  this.productService.updateTaxes(newProduct).subscribe(
                     (result) => {
                        this.loading = false;
                        this.setPage({ offset: 0 });
                        this.ref.detectChanges();
                        Swal.fire({
                           icon: "success",
                           title: "Ingreso de Producto",
                           text: "Se ha guardado el producto ingresado exitosamente.",
                        });
                     },
                     (error) => {
                        let errorText = error.error.message;
                        this.loading = false;
                        Swal.fire({
                           icon: "error",
                           title: "Error en Producto",
                           text: "No ha sido posible guardar el producto ingresado, intente nuevamente. " + errorText,
                        });
                     }
                  );
               }
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible guardar el producto ingresado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.closeCreateModal();
            this.ref.detectChanges();
         });
   }

   editProductRequest(event: Producto) {
      this.loading = true;
      event.emisorId = this.emisorId;
      event.sucursalId = this.sucursalId;
      this.productService
         .editProductRequest(event)
         .subscribe(
            (result) => {
               let productRef = this.rowsMantenedor.find((prod) => prod.productoId == event.productoId);
               let index = this.rowsMantenedor.indexOf(productRef);
               this.rowsMantenedor.splice(index, 1, event);
               this.rowsMantenedor = [...this.rowsMantenedor];
               this.loading = false;
               Swal.fire({
                  icon: "success",
                  title: "Edición de Producto",
                  text: "Se han guardado los cambios del producto exitosamente.",
               });
            },
            (error) => {
               this.loading = false;
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible guardar el producto editado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.closeEditModal();
            this.ref.detectChanges();
         });
   }

   deactivateProduct(row: Producto) {
      this.loading = true;
      this.productService
         .deactivateProduct(row.productoId)
         .subscribe(
            (result) => {
               this.ref.detectChanges();
               if (result.data) {
                  this.loading = false;
                  //row.activo = false;
                  this.setPage({ offset: 0 });
                  this.ref.detectChanges();
                  Swal.fire({
                     icon: "success",
                     title: "Producto desactivado",
                     text: "El producto seleccionado ha sido desactivado.",
                  });
               }
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible desactivar el producto seleccionado, intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
         });
   }
   reactivateProduct(row: Producto) {
      this.loading = true;
      this.productService
         .reactivateProduct(row.productoId)
         .subscribe(
            (result) => {
               this.ref.detectChanges();
               if (result.data) {
                  this.loading = false;
                  //row.activo = true;
                  this.setPage({ offset: 0 });
                  this.ref.detectChanges();
                  Swal.fire({
                     icon: "success",
                     title: "Producto reactivado",
                     text: "El producto seleccionado ha sido reactivado.",
                  });
               }
            },
            (error) => {
               Swal.fire({
                  icon: "error",
                  title: "Error en Producto",
                  text: "No ha sido posible reactivar el producto seleccionado, intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
               });
            }
         )
         .add(() => {
            this.loading = false;
            this.ref.detectChanges();
         });
   }
   openTaxesProductModal(modalRef, row: Producto) {
      this.selectedProduct = { ...row };
      this.loading = true;
      this.impuestoService.taxesByProduct(row).subscribe(
         (result) => {
            this.loading = false;
            this.ref.detectChanges();
            row.impuestos = result.data;
            this.selectedProduct = { ...row };
            this.taxesProductModal = this.modal.open(modalRef, {
               windowClass: "",
               size: "lg",
            });
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error en Producto",
               text: "No ha sido posible reactivar el producto seleccionado, intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }

   closeTaxesProductModal() {
      this.taxesProductModal.close();
   }

   updateTaxesRequest(event: Producto) {
      this.loading = true;
      this.productService.updateTaxes(event).subscribe(
         (result) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "success",
               title: "Edición de impuestos para Producto",
               text: "Se han editado exitosamente los impuestos asociados al producto " + event.nombre,
            });
            this.taxesProductModal.close();
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error en impuestos para Producto",
               text: "No ha sido posible editar los impuestos asociados al producto, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }
   toggleExpandRow(row) {
      this.tableRef.rowDetail.toggleExpandRow(row);
   }
   getEmisoresFullList() {
      this.emisorService.getData().subscribe(
         (result) => {
            let emisores = result.data;
            this.emisorSource = emisores;
            this.ref.detectChanges();
         },
         (error) => {
            this.loading = false;
            this.ref.detectChanges();
            Swal.fire({
               icon: "error",
               title: "Error en emisores",
               text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
            });
         }
      );
   }
}
