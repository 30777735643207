import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import { ConfigMantenedorUsuario } from "./config-mantenedor-usuarios";
import { Usuario } from "src/app/models/Usuario/Usuario";
import { UsuarioFilter } from "src/app/models/Usuario/UsuarioFilter";
import { UsuarioService } from "src/app/services/usuario.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { PerfilService } from "src/app/services/perfil.service";
import { PerfilFilter } from "src/app/models/Perfil/PerfilFilter";
import { Perfil } from "src/app/models/Perfil/Perfil";
import { ConfigMantenedorPerfiles } from "../mantenedor-perfiles/config-mantenedor-perfiles";
import { ConfigMantenedorSucursales } from "../mantenedor-usuarios/config-mantenedor-sucursales";
import { UsuarioRequest } from "src/app/models/Usuario/UsuarioRequest";
import { Sucursal } from "src/app/models/Sucursal";
import { SucursalFilter } from "src/app/models/Usuario/SucursalFilter";
import { PermissionService } from "src/app/services/permission.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { EmisorService } from "src/app/services/emisor.service";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SortType } from "@swimlane/ngx-datatable";
import { AuthService } from "src/app/modules/auth";

@Component({
	selector: "app-mantenedor-usuarios",
	templateUrl: "./mantenedor-usuarios.component.html",
	styleUrls: ["./mantenedor-usuarios.component.scss"],
})
export class MantenedorUsuariosComponent implements OnInit {
	SortType = SortType;
	public profileFilter = new PerfilFilter();
	public rowsMantenedorProfile: Perfil[] = [];
	public configLocalProfile = ConfigMantenedorPerfiles;
	public configLocalSucursal = ConfigMantenedorSucursales;
	public loading = false;
	public page = new Page();
	public pageProfile = new Page();
	public pageSucursal = new Page();
	public filter = new UsuarioFilter();
	public filterSucursal = new SucursalFilter();
	public rowsMantenedor: Usuario[] = [];
	public rowsMantenedorSucursal: Sucursal[] = [];
	public sucursalesEmisor: Sucursal[] = [];
	public profilesUser: Perfil[] = [];
	public configLocal = ConfigMantenedorUsuario;
	public searchRows: any[] = [];

	public sessionEmisor: Emisor;

	public emisorId: string;
	private currentSucursalId: string;
	public userToShow: Usuario = new Usuario();
	public userToEdit: Usuario = new Usuario();
	public userToAdd: Usuario = new Usuario();
	public userToAddRut: string = "";
	public repeatPassword: string = "";
	private saveRows: boolean = false;
	public detailUserModal: NgbModalRef;
	public addNewUserModal: NgbModalRef;
	public editUserModal: NgbModalRef;
	public permissionsModal: NgbModalRef;
	public admProfilesModal: NgbModalRef;
	public admSucursalesModal: NgbModalRef;
	public profilesModal: NgbModalRef;
	public sucursalModal: NgbModalRef;
	public searchCriterio;
	private currentEmisorIdObservable$: Observable<string>;
	public emisores: Emisor[] = [];
	public isSuperAdmin: boolean = false;
	// private emisoresDeUsuario: Emisor[] = [];
	private usuarioId: string;
	public perfilesDelEmisor: Perfil[] = [];
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	@ViewChild("notesSwal") private notesSwal: SwalComponent;
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	constructor(private modalService: NgbModal, public utilities: UtilitiesService, private usuarioService: UsuarioService, private perfilService: PerfilService, private tokenService: TokenStorageService, private ref: ChangeDetectorRef, private authService: AuthService, public permissions: PermissionService, private router: Router, private emisorService: EmisorService, private localStorage: LocalStorageService) {
		this.currentEmisorIdObservable$ = this.tokenService.currentEmisorObservable;
		this.emisorId = this.tokenService.getCompany();
		this.currentSucursalId = this.tokenService.getSubsidiary();
		this.isSuperAdmin = this.tokenService.getUser().superAdmin; //this.permissions.getPermission("10-10").asignado;
		this.emisores = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		this.sessionEmisor = this.emisores.find((emisor) => emisor.emisorId == this.emisorId);

		this.usuarioId = this.tokenService.getUser().id;
		this.filter.activo = 1;
		this.filter.comuna = "";
		this.filter.nombre = "";
		this.filter.rut = "";
		this.filter.emisorId = this.emisorId;
		this.filter.usuarioId = this.usuarioId;
	}

	ngOnInit(): void {
		// if (!this.isSuperAdmin) {
		//    this.filterSucursal.emisorId = this.emisorId;
		// }
		this.setPage({ offset: 0 });
		// this.currentEmisorIdObservable$.subscribe((emisorId) => {
		//    this.emisorId = emisorId;
		//    this.filter.emisorId = this.emisorId;
		//    this.filter.usuarioId = this.usuarioId;
		//    this.setPage({ offset: 0 });
		// });
		if (this.isSuperAdmin) {
			this.getFullEmisoresList();
		}
	}

	setPageSize() {
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		this.page.pageNumber += 1;

		this.usuarioService
			.getDataByPage(this.filter, this.page)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Usuario[];
					//console.log(elements);
					this.ref.detectChanges();
					elements.forEach((row) => {});
					this.rowsMantenedor = [...elements];
					this.setPagefromResponse(this.page, pagedData);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.componentStarted = true;
				this.ref.detectChanges();
			});
	}
	setPagefromResponse(currentPage: Page, data: PagedResponse<Usuario>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	searchElements(nombre: string) {
		this.loading = true;
		this.usuarioService.getDataByPage(this.filter, this.page).subscribe(
			(result) => {
				this.loading = false;
				let elements = result.data as Usuario[];
				//console.log(elements);
				elements.forEach((row) => {});
				this.rowsMantenedor = [...elements];
			},
			(error) => {},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	changeSearchedRows(data) {
		if (data != null) {
			this.rowsMantenedor = data;
		}
	}

	filterConcordance(configCriterias, planFilter: UsuarioFilter, eventFilter): UsuarioFilter {
		configCriterias.forEach((criterio) => {
			if (criterio != eventFilter.criterio) {
				planFilter[criterio] = "";
			}
		});
		return planFilter;
	}

	searchElementsByFilter(event) {
		this.filter = this.filterConcordance(this.configLocal.barraBusqueda.criteriosKeys, this.filter, event);
		this.filter.nombre = event.nombre != null ? event.nombre : "";
		this.filter.rut = event.rut != null ? event.rut : "";
		this.filter.activo = event.registroActivo ? 0 : 1;
		this.filter.comuna = event.comuna != null ? event.comuna : "";
		this.filter.emisorId = event.emisorId;
		this.filter.sucursalId = event.sucursalId;
		this.filter.usuarioId = this.usuarioId;
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.setPage({ offset: 0 });
	}

	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}

	getAllRowsByFilter(currentPageRef: Page, currentFilter: UsuarioFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.usuarioService.getDataByPage(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as Usuario[];
				this.ref.detectChanges();
				elements.forEach((row) => {});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al exportar";
				this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error);
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<Usuario>): void {
		let arregloExportar = [];
		arrayToExport.forEach((item) => {
			let elementToExport = {};
			elementToExport["Nombre"] = item.nombre + " " + item.apellidos;
			elementToExport["Rut"] = item.rut;
			elementToExport["Dv"] = item.dv;
			elementToExport["Direccion"] = item.direccion;
			elementToExport["Fecha de Nacimiento"] = item.fechaNacimiento;
			elementToExport["Email"] = item.email;
			elementToExport["Telefono"] = item.telefono;
			elementToExport["Cargo"] = item.cargo;
			elementToExport["Perfil Usuario"] = item.perfilUsuario;
			elementToExport["Comuna"] = item.comuna;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Usuarios");
	}

	detailModal(modalRef, row: Usuario) {
		this.loading = true;
		this.userToShow = row;
		this.loading = false;
		this.ref.detectChanges();
		this.detailUserModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	editModal(modalRef, row: Usuario) {
		this.loading = true;
		this.userToEdit = new Usuario();
		this.userToEdit.nombre = row.nombre;
		this.userToEdit.comuna = row.comuna;
		this.userToEdit.apellidos = row.apellidos;
		this.userToEdit.email = row.email;
		this.userToEdit.direccion = row.direccion;
		this.userToEdit.cargo = row.cargo;
		this.userToEdit.telefono = row.telefono != null ? row.telefono : 0;
		this.userToEdit.whatsapp = row.whatsapp;
		this.userToEdit.rutFormateado = row.rutFormateado;
		this.userToEdit.usuarioId = row.usuarioId;
		//console.log(this.userToEdit);
		this.loading = false;
		this.ref.detectChanges();
		this.editUserModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	editUser(addForm) {
		this.loading = true;
		this.usuarioService.updateUser(this.userToEdit).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Usuario Actualizado",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "El usuario " + this.userToEdit.nombre + " fue actualizado correctamente" + "</p></div>",
				});
				this.editUserModal.close();
				this.setPage({ offset: 0 });
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Actualizar Usuario";
				this.currentSwal.text = "No fue posible actualizar al usuario " + this.userToEdit.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}
	viewUserPermissionModal(modalRef, row: Perfil) {
		this.loading = true;
		this.perfilService.getPermissions("profile", row.perfilId).subscribe(
			(success) => {
				this.loading = false;
				this.ref.detectChanges();
				this.userToEdit.permisos = success.data;
				this.permissionsModal = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Obtener Permisos";
				this.currentSwal.text = "No fue posible obtener permisos del usuario " + this.userToEdit.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	userProfilesModal(modalRef, row: Usuario) {
		this.loading = true;
		this.getListOfPerfiles();
		this.userToEdit = new Usuario();
		this.userToEdit.nombre = row.nombre;
		this.userToEdit.comuna = row.comuna;
		this.userToEdit.apellidos = row.apellidos;
		this.userToEdit.email = row.email;
		this.userToEdit.direccion = row.direccion;
		this.userToEdit.cargo = row.cargo;
		this.userToEdit.telefono = row.telefono != null ? row.telefono : 0;
		this.userToEdit.whatsapp = row.whatsapp;
		this.userToEdit.rutFormateado = row.rutFormateado;
		this.userToEdit.usuarioId = row.usuarioId;
		this.usuarioService.getProfilesByUser(this.userToEdit.usuarioId).subscribe(
			(success) => {
				this.loading = false;
				this.ref.detectChanges();
				this.userToEdit.perfiles = success.data;
				this.admProfilesModal = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Obtener Perfiles";
				this.currentSwal.text = "No fue posible obtener Perfiles del usuario " + this.userToEdit.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	activateUser(row: Usuario) {
		this.loading = true;
		this.usuarioService.activateUser(row.usuarioId).subscribe(
			(res) => {
				if (res.data) {
					Swal.fire({
						icon: "success",
						title: "Usuario Activado",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Usuario " + row.nombre + " Activado" + "</p></div>",
					});
					this.setPage({ offset: 0 });

					this.loading = false;
					this.ref.detectChanges();
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Activar";
				this.currentSwal.text = "No fue posible activar el usuario " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	deactivateUser(row: Usuario) {
		this.loading = true;
		this.usuarioService.deactivateUser(this.usuarioId, row.usuarioId).subscribe(
			(res) => {
				if (res.data) {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Usuario Desactivado",
						showCloseButton: true,
						html: "<div style='text-align:center;'><p>" + "Usuario " + row.nombre + " Desactivado" + "</p></div>",
					});

					this.setPage({ offset: 0 });
				}
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Desactivar";
				this.currentSwal.text = "No fue posible desactivar al usuario " + row.nombre + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}
	setPageProfile(pageInfo) {
		this.loading = true;
		this.pageProfile.pageNumber = pageInfo.offset;
		this.pageProfile.pageNumber += 1;

		this.perfilService
			.getProfilesDataByPage(this.profileFilter, this.pageProfile)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Perfil[];
					//console.log(elements);
					this.ref.detectChanges();
					elements.forEach((row) => {});
					this.rowsMantenedorProfile = [...elements];
					this.setPageProfilefromResponse(this.pageProfile, pagedData);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}
	setPageProfilefromResponse(currentPage: Page, data: PagedResponse<Perfil>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	profileSearchElements(nombre: string) {
		this.loading = true;
		this.perfilService.getProfilesDataByPage(this.profileFilter, this.pageProfile).subscribe(
			(result) => {
				this.loading = false;
				let elements = result.data as Perfil[];
				//console.log(elements);
				elements.forEach((row) => {});
				this.rowsMantenedorProfile = [...elements];
			},
			(error) => {},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	changeSearchedRowsByProfile(data) {
		if (data != null) {
			this.rowsMantenedorProfile = data;
		}
	}

	filterConcordanceByProfile(configCriterias, perfilFilter: PerfilFilter, eventFilter): PerfilFilter {
		configCriterias.forEach((criterio) => {
			if (criterio != eventFilter.criterio) {
				perfilFilter[criterio] = "";
			}
		});
		return perfilFilter;
	}

	searchElementsByProfileFilter(event) {
		this.profileFilter = this.filterConcordanceByProfile(this.configLocal.barraBusqueda.criteriosKeys, this.profileFilter, event);
		this.profileFilter.nombre = event.nombre != null ? event.nombre : "";
		this.profileFilter.activo = 1;
		this.profileFilter.emisorid = this.isSuperAdmin ? "" : this.emisorId;
		this.profileFilter.usuarioId = this.usuarioId;
		this.profileFilter[event.criterio] = event.busqueda;
		this.setPageProfile({ offset: 0 });
	}

	findProfileModal(modalRef) {
		this.loading = true;
		this.profileFilter.nombre = "";
		this.profileFilter.activo = 1;
		this.profileFilter.emisorid = this.isSuperAdmin ? "" : this.emisorId;
		this.profileFilter.usuarioId = this.usuarioId;
		this.loading = false;
		this.ref.detectChanges();
		this.profilesModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});

		this.setPageProfile({ offset: 0 });
	}
	addProfiletoUser(profile: Perfil) {
		this.loading = true;
		var exist = false;
		this.loading = false;
		this.ref.detectChanges();
		if (this.userToEdit.perfiles == null) this.userToEdit.perfiles = [];
		if (this.userToEdit.perfiles != null) {
			this.userToEdit.perfiles.forEach((element) => {
				if (element.perfilId == profile.perfilId) {
					exist = true;
					this.currentSwal.icon = "info";
					this.currentSwal.title = "Perfil no asignado";
					this.currentSwal.text = "El perfil " + profile.nombre + " ya esta asignado al usuario";
					this.currentSwal.fire();
				}
			});
		}

		if (!exist) {
			this.userToEdit.perfiles.push(profile);
			Swal.fire({
				icon: "success",
				title: "Perfil Asignado",
				showCloseButton: true,
				html: "<div style='text-align:center;'><p>" + "El perfil " + profile.nombre + " fue asignado al usuario correctamente" + "</p></div>",
			});
			this.setPageProfile({ offset: 0 });
			this.profilesModal.close();
		}
	}

	editProfiles(modalRef) {
		this.loading = true;
		var request = new UsuarioRequest();
		request.userId = this.userToEdit.usuarioId;
		request.profiles = this.userToEdit.perfiles;
		this.usuarioService.updateUserProfiles(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Perfiles Actualizados",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "Se actualizaron los perfiles de acceso del usuario " + this.userToEdit.nombre + "</p></div>",
				});
				this.admProfilesModal.close();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Perfiles no actualizados";
				this.currentSwal.text = "Error al actualizar los perfiles de acceso del usuario " + this.userToEdit.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}
	deleteUserProfile(profile: Perfil) {
		var nuevaLista: Perfil[] = [];
		this.userToEdit.perfiles.forEach((element) => {
			if (element != profile) {
				nuevaLista.push(element);
			}
		});
		this.userToEdit.perfiles = nuevaLista;
	}

	userSucursalesModal(modalRef, row: Usuario) {
		this.loading = true;
		this.userToEdit = new Usuario();
		this.userToEdit.nombre = row.nombre;
		this.userToEdit.comuna = row.comuna;
		this.userToEdit.apellidos = row.apellidos;
		this.userToEdit.email = row.email;
		this.userToEdit.direccion = row.direccion;
		this.userToEdit.cargo = row.cargo;
		this.userToEdit.telefono = row.telefono != null ? row.telefono : 0;
		this.userToEdit.whatsapp = row.whatsapp;
		this.userToEdit.rutFormateado = row.rutFormateado;
		this.userToEdit.usuarioId = row.usuarioId;
		this.usuarioService.getSucursalesByUserForUser(this.tokenService.getUser().id, this.userToEdit.usuarioId).subscribe(
			(success) => {
				this.loading = false;
				this.ref.detectChanges();
				this.userToEdit.sucursales = [];
				this.userToEdit.sucursales = success.data;
				//console.log(this.userToEdit.sucursales);
				this.admSucursalesModal = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Obtener Perfiles";
				this.currentSwal.text = "No fue posible obtener las sucursales del usuario " + this.userToEdit.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	deleteUserSucursal(sucursal: Sucursal) {
		var nuevaLista: Sucursal[] = [];
		this.userToEdit.sucursales.forEach((element) => {
			if (element != sucursal) {
				nuevaLista.push(element);
			}
		});
		this.userToEdit.sucursales = nuevaLista;
	}

	editSucursales(modalRef) {
		this.loading = true;
		var request = new UsuarioRequest();
		request.userId = this.userToEdit.usuarioId;
		request.sucursales = this.userToEdit.sucursales;
		this.usuarioService.updateUserSucursales(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Sucursales Actualizadas",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + "Se actualizaron las sucursales del usuario " + this.userToEdit.nombre + "</p><p>Debe iniciar sesión nuevamente para reflejar los cambios.</p></div>",
				});
				this.admSucursalesModal.close();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Perfiles no actualizados";
				this.currentSwal.text = "Error al actualizar las sucursales del usuario " + this.userToEdit.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	setPageSucursal(pageInfo) {
		this.loading = true;
		this.pageSucursal.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.pageSucursal));
		localPage.pageNumber += 1;
		this.usuarioService
			.getSucursalDataByPage(this.filterSucursal, localPage)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as Sucursal[];
					this.ref.detectChanges();
					// if(!this.fullListPermission){
					//   this.rowsMantenedorSucursal = elements.filter((suc) => {
					//     return this.emisoresDeUsuario.some((em) => {
					//       return em.emisorId == suc.emisorId;
					//     });
					//   });
					// }else if(this.fullListPermission){
					//   this.rowsMantenedorSucursal = [...elements];
					// }
					this.rowsMantenedorSucursal = [...elements];
					this.setPagefromSucursalResponse(this.pageSucursal, pagedData);
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}
	setPagefromSucursalResponse(currentPage: Page, data: PagedResponse<Sucursal>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	searchSucursalElements(nombre: string) {
		this.loading = true;
		this.usuarioService.getSucursalDataByPage(this.filterSucursal, this.pageSucursal).subscribe(
			(result) => {
				this.loading = false;
				let elements = result.data as Sucursal[];
				//console.log(elements);
				elements.forEach((row) => {});
				this.rowsMantenedorSucursal = [...elements];
			},
			(error) => {},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	changeSearchedRowsBySucursal(data) {
		if (data != null) {
			this.rowsMantenedorSucursal = data;
		}
	}

	filterConcordanceBySucursal(configCriterias, sucursalFilter: SucursalFilter, eventFilter): SucursalFilter {
		configCriterias.forEach((criterio) => {
			if (criterio != eventFilter.criterio) {
				sucursalFilter[criterio] = "";
			}
		});
		return sucursalFilter;
	}
	searchElementsBySucursalFilter(event) {
		this.filterSucursal = this.filterConcordanceBySucursal(this.configLocal.barraBusqueda.criteriosKeys, this.filterSucursal, event);
		this.filterSucursal.nombre = event.nombre != null ? event.nombre : "";
		this.filterSucursal.direccion = event.direccion != null ? event.direccion : "";
		this.filterSucursal.activo = 1;
		this.filterSucursal[event.criterio] = event.busqueda;
		this.filterSucursal.emisorId = event.emisorId;
		this.filterSucursal.usuarioId = this.usuarioId;
		this.setPageSucursal({ offset: 0 });
	}

	findSucursalModal(modalRef) {
		this.loading = true;
		this.filterSucursal.nombre = "";
		this.filterSucursal.direccion = "";
		this.filterSucursal.activo = 1;
		this.filterSucursal.usuarioId = this.usuarioId;
		this.loading = false;
		this.ref.detectChanges();
		this.sucursalModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});

		this.setPageSucursal({ offset: 0 });
	}

	addSucursalToUser(sucursal: Sucursal) {
		this.loading = true;
		var exist = false;
		this.loading = false;
		this.ref.detectChanges();
		if (this.userToEdit.sucursales == null) this.userToEdit.sucursales = [];
		if (this.userToEdit.sucursales != null) {
			this.userToEdit.sucursales.forEach((element) => {
				if (element.sucursalId == sucursal.sucursalId) {
					exist = true;
					this.currentSwal.icon = "info";
					this.currentSwal.title = "Sucursal no asignado";
					this.currentSwal.text = "La Sucursal " + sucursal.nombre + " ya esta asignada al usuario";
					this.currentSwal.fire();
				}
			});
		}

		if (!exist) {
			this.userToEdit.sucursales.push(sucursal);
			Swal.fire({
				icon: "success",
				title: "Sucursal Asignada",
				showCloseButton: true,
				html: "<div style='text-align:center;'><p>" + "La Sucursal " + sucursal.nombre + " fue asignada al usuario correctamente" + "</p></div>",
			});
			this.setPageSucursal({ offset: 0 });
			this.sucursalModal.close();
		}
	}
	newUserModal(modalRef) {
		this.loading = true;
		this.ref.detectChanges();
		this.repeatPassword = "";
				this.userToAddRut = "";
				this.userToAdd = new Usuario();
				this.getListOfPerfiles();
				this.loading = false;

				this.ref.detectChanges();
				this.addNewUserModal = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
		this.loading = false;
		this.ref.detectChanges();
	}
	addUser(addform, sucursalModal) {
		//console.log(addform)
		if (!addform.valid) {
			return;
		}
		this.loading = true;
		this.userToAdd.rut = this.rutSinDv(this.userToAdd.rutFormateado);
		this.userToAdd.perfilInicialId = this.userToAdd.perfilInicial.perfilId;
		var request = new UsuarioRequest();
		request.user = this.userToAdd;
		request.emisorId = this.emisorId;
		request.sucursalId = this.currentSucursalId;
		//console.log(this.userToAdd);
		this.usuarioService.addUser(request).subscribe(
			(res) => {
				this.userToEdit = res.data;
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Usuario Agregado",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + res.message + "</p></div>",
				});
				this.addNewUserModal.close();
				this.userSucursalesModal(sucursalModal, this.userToEdit);
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Usuario no agregado";
				this.currentSwal.text = "Ocurrió un error al agregar usuario. Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	rutSinDv(rutCompleto: string): number {
		let rut: number;
		if (rutCompleto.includes("-")) {
			rut = parseInt(rutCompleto.substring(0, rutCompleto.length - 2));
			return rut;
		} else {
			rut = parseInt(rutCompleto.substring(0, rutCompleto.length - 1));
		}
		return rut;
	}
	permissionList(row: Perfil) {
		this.loading = true;
		this.admProfilesModal.close();
		this.perfilService.getPermissions("profile", row.perfilId).subscribe(
			(success) => {
				this.loading = false;
				this.ref.detectChanges();
				row.perfilId = row.perfilId;
				row.nombre = row.nombre;
				row.permisos = success.data;
				this.router.navigateByUrl("/perfiles/permisos", {
					state: {
						rowProfile: JSON.stringify(row),
						currentUrl: this.router.url,
					},
				});
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al Obtener Permisos";
				this.currentSwal.text = "No fue posible obtener permisos del usuario " + row.nombre + ". Intenta más tarde." + this.utilities.setErrorMessageFromArray(err.error);
				this.currentSwal.fire();
			}
		);
	}

	getFullEmisoresList() {
		this.loading = true;
		this.emisorService.getData().subscribe(
			(result) => {
				this.emisores = result.data;
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	getListOfPerfiles() {
		this.loading = true;
		this.perfilService.getProfiles().subscribe(
			(result) => {
				this.perfilesDelEmisor = result.data;
				this.loading = false;
			},
			(error) => {
				Swal.fire({
					icon: "error",
					title: "Error en perfiles",
					text: "No fue posible obtener los perfiles asignados al emisor. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	unlockUser(row: Usuario) {
		this.loading = true;
		this.authService.recoverPassword(row.email, row.rutFormateado).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Usuario Desbloqueado",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + res.message + "</p></div>",
				});
				this.setPage({ offset: 0 });
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Desbloquear Usuario",
					text: "No fue posible recuperar la contraseña ni desbloquear usuario. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
}
