import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Response } from "src/app/shared/models/Response";
import { Observable } from "rxjs";
import { PersonaAutorizada } from "../models/PersonaAutorizada";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
	providedIn: "root",
})
export class PersonasAutorizadasService {
	private REST_API_SERVER = environment.backend_server + "/PersonaAutorizada";
	constructor(private http: HttpClient, private tokenService: TokenStorageService) {}

	getAuthorizedPersonsByEmisor(idEmisor: string): Observable<Response<PersonaAutorizada[]>> {
		return this.http.get<Response<PersonaAutorizada[]>>(this.REST_API_SERVER + "/getAuthorizedPersonsByEmisor/" + idEmisor);
	}

	toAssignAuthorizedPersons(authorizedPerson: PersonaAutorizada): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/toAssignAuthorizedPerson", authorizedPerson);
	}
	updateAuthorizedPersons(authorizedPerson: PersonaAutorizada): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/updateAuthorizedPerson", authorizedPerson);
	}
	deleteAuthorizedPersons(authorizedPerson: PersonaAutorizada): Observable<Response<boolean>> {
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/deleteAuthorizedPerson", authorizedPerson);
	}
}
