import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AlertService } from "src/app/alerts/alert.service";
import { ComisionRecargo } from "src/app/models/Dte/ComisionRecargo";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { runInThisContext } from "vm";

@Component({
   selector: "app-lista-item-detalle-comision",
   templateUrl: "./lista-item-detalle-comision.component.html",
   styleUrls: ["./lista-item-detalle-comision.component.scss"],
})
export class ListaItemDetalleComisionComponent implements OnInit {
   public listaComisiones: ComisionRecargo[];
   private listaComisionessObservable$: Observable<ComisionRecargo[]>;
   private formatSizeObservable$: Observable<number>;
   private invalidSizeObservable$: Observable<boolean>;
   private invoiceType$: Observable<InvoiceType>;
   public showingInInvoice: boolean = false;
   public showingInSettlement: boolean = false;
   private minItemsInList: number = 1;
   private maxItemsInList: number = 5;
   public maxItemError: boolean = false;
   public sizeMessage: string = "El formato actual solo acepta " + this.maxItemsInList;
   public currentInvoiceType: InvoiceType = InvoiceType.Factura;
   constructor(private facturador: FacturacionIndividualService, private alertService: AlertService, private ref: ChangeDetectorRef) {
      this.listaComisionessObservable$ = this.facturador.detalleComisionDteListObservable;
      this.formatSizeObservable$ = this.facturador.formatSizeObservable;
      this.invalidSizeObservable$ = this.facturador.invalidSizeObservable;
      this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
   }

   ngOnInit(): void {
      this.listaComisionessObservable$.subscribe((list) => {
         this.listaComisiones = list;
      });
      this.formatSizeObservable$.subscribe((size) => {
         this.ref.detectChanges();
         this.maxItemsInList = size;
         //console.log("max item in size",this.maxItemsInList)
         this.setSizeMessage();
      });
      this.invalidSizeObservable$.subscribe((invalidBool) => {
         this.maxItemError = invalidBool;
         if (this.maxItemError) {
            this.alertService.clear("alert-size");
            let maxSizeMessage = " Ha utilizado " + this.facturador.currentSize + " líneas de detalle. Cada item utiliza una línea de detalle, si el item mantiene un campo de descripción utilizará dos líneas de detalle.";
            window.scroll(0, 300);
            return this.alertService.error(this.sizeMessage + maxSizeMessage, { id: "alert-size", autoClose: true });
         }
      });
      const invoiceTypeSub = this.invoiceType$.subscribe((type) => {
         this.currentInvoiceType = type;
         if (type == InvoiceType.Boleta) {
            this.showingInInvoice = false;
         } else if (type == InvoiceType.GuiaDespacho) {
            this.showingInInvoice = true;
         } else {
            this.showingInInvoice = true;
         }
      });
   }

   agregarDetalleComision() {
      this.facturador.setComisionNewItem();
   }

   deleteItemComision(index) {
      if (this.listaComisiones.length > this.minItemsInList) {
         this.facturador.deleteComisionItem(index);
      }
   }

   checkSizeInComisionList() {
      this.maxItemError = this.listaComisiones.length > this.maxItemsInList;
   }
   setSizeMessage() {
      this.sizeMessage = "El formato actual solo acepta " + this.maxItemsInList + " elementos.";
   }
}
