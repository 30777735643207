import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PlantillaImpresion } from "../models/PlantillaImpresion/PlantillaImpresion";
import { PagedResponse } from "../shared/models/PagedResponse";
import { PlantillaFilter } from "../models/PlantillaImpresion/PlantillaFilter";
import { Page } from "../shared/models/Page";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Response } from "../shared/models/Response";
import { Emisor } from "../models/Emisor/Emisor";
import { PlantillaEmisor } from "../models/PlantillaImpresion/PlantillaEmisor";

@Injectable({
	providedIn: "root",
})
export class PlantillaImpresionService {
	private REST_API_SERVER = environment.backend_server + "/plantilla";
	private PLANTILLA_EMISOR_API_SERVER = environment.backend_server + "/plantillaemisor";
	private EMISORES_API_SERVER = environment.backend_server + "/emisor";

	constructor(private http: HttpClient) {}

	getDataByPage(filtro: PlantillaFilter, page: Page): Observable<PagedResponse<PlantillaImpresion>> {
		let descripcion = filtro.descripcion ? filtro.descripcion : "";
		let tipoDteId = filtro.tipoDteId ? filtro.tipoDteId : "";
		let emisorId = filtro.emisorId ? filtro.emisorId : "";
		return this.http.get<PagedResponse<PlantillaImpresion>>(this.REST_API_SERVER + "/list/filter/?emisorId=" + emisorId + " &tipoDteId=" + tipoDteId + "&descripcion=" + descripcion + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	sendPlantillaRequest(request: PlantillaImpresion): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("tipoPlantillaString", JSON.stringify(request));
		formData.append("file", request.file);
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/new", formData);
	}

	editPlantillaRequest(request: PlantillaImpresion): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("tipoPlantillaString", JSON.stringify(request));
		formData.append("file", request.file);
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/update", formData);
	}

	getPlantilla(item: PlantillaImpresion): Observable<Response<PlantillaImpresion>> {
		return this.http.get<Response<PlantillaImpresion>>(this.REST_API_SERVER + "?id=" + item.tipoPlantillaId);
	}

	getPdfSample(item: PlantillaImpresion, emisorId: string): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("logob64", "");
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/pdf/sample" + "?plantillaId=" + item.tipoPlantillaId + "&emisorId=" + emisorId, formData);
	}
	getPdfSampleExporta(item: PlantillaImpresion): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("logob64", "");
		return this.http.post<Response<string>>(this.REST_API_SERVER + "/pdf/sample/exporta" + "?plantillaId=" + item.tipoPlantillaId, formData);
	}
	download(item: PlantillaImpresion): Observable<string> {
		return this.http.get<string>(this.REST_API_SERVER + "/html/download" + "?plantillaId=" + item.tipoPlantillaId);
	}

	deletePlantilla(item: PlantillaImpresion): Observable<any> {
		return this.http.post<any>(this.REST_API_SERVER + "/delete" + "?tipoPlantillaId=" + item.tipoPlantillaId, null);
	}

	emisorListOfPlantilla(item: PlantillaImpresion): Observable<Response<PlantillaEmisor[]>> {
		return this.http.get<Response<PlantillaEmisor[]>>(this.PLANTILLA_EMISOR_API_SERVER + "/issuers?plantillaId=" + item.tipoPlantillaId);
	}

	assignPlantilla(emisor: Emisor, plantilla: PlantillaImpresion, active: boolean): Observable<any> {
		return this.http.post<any>(this.PLANTILLA_EMISOR_API_SERVER + "/assign" + "?emisorId=" + emisor.emisorId + "&plantillaId=" + plantilla.tipoPlantillaId + "&activate=" + active, null);
	}

	activatePlantilla(emisor: PlantillaEmisor): Observable<any> {
		return this.http.post<any>(this.PLANTILLA_EMISOR_API_SERVER + "/activate", emisor);
	}
	// getEmisoresList():Observable<Response<Emisor[]>>{
	//   return this.http.get<Response<Emisor[]>>(this.EMISORES_API_SERVER+'/list');
	// }

	getTipoPlantillas(): Observable<Response<PlantillaImpresion[]>> {
		return this.http.get<Response<PlantillaImpresion[]>>(this.REST_API_SERVER + "/list");
	}

	getPdfLabels(): Observable<Response<string>> {
		return this.http.get<Response<string>>(this.REST_API_SERVER + "/getPdfLabels");
	}
}
