import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent, SwalPortalDirective } from "@sweetalert2/ngx-sweetalert2";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ConfigFacturaMasiva } from "./config-facturador-masivo";
import { Papa, ParseResult } from "ngx-papaparse";
import { FacturadorMasivoService } from "src/app/services/facturador-masivo.service";
import * as FileSaver from "file-saver";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { ArchivoExcelImportacion } from "src/app/models/ArchivoExcelImportacion";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TipoDte } from "src/app/models/tipoDte";
import { SolicitudFacturacionMasiva } from "src/app/models/SolicitudFacturacionMasiva";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { TipoArchivoImportacion } from "src/app/helpers/TipoArchivoImportacion.enum";
import { Router } from "@angular/router";
import { UtilitiesService } from "src/app/services/utilities.service";
import { SessionUser } from "src/app/shared/models/sessionUser";
import { ImportacionMasiva } from "src/app/models/MassInvoicing/ImportacionMasiva";
//import * as moment from "moment";
import * as _moment from "moment";
import { Moment } from "moment";
import { DateTimeAdapter, OwlDateTimeComponent, OwlDateTimeFormats, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { MomentDateTimeAdapter } from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class";
import { Page } from "src/app/shared/models/Page";
import { ImportacionFilter } from "src/app/models/MassInvoicing/ImportacionFilter";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import { DatePipe } from "@angular/common";
import { PermissionService } from "src/app/services/permission.service";
import { Observable } from "rxjs";
import { Response } from "src/app/shared/models/Response";
import { SortType } from "@swimlane/ngx-datatable";
import { resourceLimits } from "worker_threads";
import { EmisorService } from "src/app/services/emisor.service";
import { environment } from "src/environments/environment";

const moment = (_moment as any).default ? (_moment as any).default : _moment;

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
	parseInput: "MM/YYYY",
	fullPickerInput: "l LT",
	datePickerInput: "MM/YYYY",
	timePickerInput: "LT",
	monthYearLabel: "MMM YYYY",
	dateA11yLabel: "LL",
	monthYearA11yLabel: "MMMM YYYY",
};

@Component({
	selector: "app-facturador-masivo",
	templateUrl: "./facturador-masivo.component.html",
	styleUrls: ["./facturador-masivo.component.scss"],
	providers: [
		NgbActiveModal,
		{
			provide: DateTimeAdapter,
			useClass: MomentDateTimeAdapter,
			deps: [OWL_DATE_TIME_LOCALE],
		},
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS },
		{ provide: OWL_DATE_TIME_LOCALE, useValue: "es" },
	],
})
export class FacturadorMasivoComponent implements OnInit {
	SortType = SortType;
	public configLocal = ConfigFacturaMasiva;
	public loading: boolean = false;
	rowsMantenedor: ImportacionMasiva[] = [];
	public searchRows: any[] = [];
	public searchCriteria;
	private saveRows: boolean = false;
	public dteFileForm: FormGroup;
	public configScroll: PerfectScrollbarConfigInterface = {};
	public files: File[] = [];
	public tipoClienteSource;
	public empresasSource: Emisor[];
	public tipoDteSource;
	private addModal: NgbModalRef;
	private detailModal: NgbModalRef;
	private invoiceRequestModal: NgbModalRef;
	public validFile: boolean = false;
	public externalValidFile: boolean = false;
	public resultStringData: string = "";
	public fileData: Object[];
	public resultedFileDataDocuments: any[];
	public numberOfDocumentosToSend: number;
	public sumOfDocumentDetails: number;
	public sumaResumenDocumento: [number, number];
	public ivaTotal: number;
	public totalFinal: number;
	public tipoClienteId: number = 0; // 0 Standard, 1 Export
	public resultData: any[];
	public emisorId: string;
	public currentEmisor: Emisor;
	public currentUser = this.tokenStorage.getUser() as SessionUser;
	public DetailString = "";
	public invalidColumns: Array<string> = [];
	public invoiceRequestDate: any;
	public dateFrom: any = moment();
	public dateTo: any = moment();
	public dateToday: any = moment();
	public page: Page = new Page();
	public filter: ImportacionFilter = new ImportacionFilter();
	public selectedImportacion: ImportacionMasiva;
	public validationMessage: string = "";
	private paymentMatrixModalInstance: any;
	public dateMatrixRequest: any = moment();
	public todayString = moment();
	public componentStarted: boolean = false;
	public wrongDelimiter: boolean = false;
	public sucursalId: string = this.tokenStorage.getSubsidiary();
	@ViewChild("tableMobile") tableRef: any;
	locale = {
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD/MM/YYYY",
	};
	//validation elements
	//Keys
	private validationKeysOriginalStandard = [
		"Id",
		"TipoDte",
		"FmaPago",
		"FechaEmision",
		"Vencimiento",
		"RutRecep",
		"GiroRecep",
		"Contacto",
		"CorreoRecep",
		"DirRecep",
		"CmnaRecep",
		"CiudadRecep",
		"RazonSocialRecep",
		"DirOrigen",
		"CmnaOrigen",
		"CiudadOrigen",
		"DirDest",
		"CmnaDest",
		"CiudadDest",
		"ReferenciaTpoDocRef",
		"ReferenciaFolioRef",
		"ReferenciaFchRef",
		"ReferenciaRazonRef",
		"CodigoProducto",
		"NombreProducto",
		"DescripcionProducto",
		"CantidadProducto",
		"PrecioProducto",
		"UnidadMedidaProducto",
		"DescuentoProducto",
		"RecargoProducto",
		"IndicadorExento",
		"TotalProducto",
	];
	// private validationKeysOriginalStandard = [
	//   "id_instruccion",
	//   "TipoDTE",
	//   "FmaPago",
	//   "FchCancel",
	//   "MntCancel",
	//   "MntPagos_FchPago",
	//   "MntPagos_MntPago",
	//   "MntPagos_GlosaPagos",
	//   "RUTRecep",
	//   "GiroRecep",
	//   "Contacto",
	//   "DirRecep",
	//   "CmnaRecep",
	//   "CiudadRecep",
	//   "DirDest",
	//   "CmnaDest",
	//   "CiudadDest",
	//   "Referencia_TpoDocRef",
	//   "Referencia_FolioRef",
	//   "Referencia_FchRef",
	//   "Referencia_RazonRef",
	//   "Codigo",
	//   "Descripcion",
	//   "Cantidad",
	//   "Precio",
	//   "Exento",
	//   "UnidadMedida",
	//   "TotalItem",
	//   "SaldoAnterior",
	//   "Vencimiento",
	// ];

	private validationKeyExport = [
		"Id",
		"TipoDte",
		"FmaPago",
		"FechaEmision",
		"Vencimiento",
		"CodigoInternoCliente",
		"RazonSocialCliente",
		"NombreFantasiaCliente",
		"GiroCliente",
		"EmailCliente",
		"DireccionCalleCliente",
		"DireccionNumeroCliente",
		"DireccionAdicionalCliente",
		"CiudadCliente",
		"EstadoCliente",
		"CodigoPaisCliente",
		"ReferenciaTpoDocRef",
		"ReferenciaFolioRef",
		"ReferenciaFchRef",
		"ReferenciaRazonRef",
		"CodigoProducto",
		"NombreProducto",
		"DescripcionProducto",
		"CantidadProducto",
		"PrecioProducto",
		"UnidadMedidaProducto",
		"DescuentoProducto",
		"RecargoProducto",
		"IndicadorExento",
		"TotalProducto",
		"ViaTransporte",
		"PuertoEmbarque",
		"PuertoDesembarque",
		"PaisDestino",
		"CodigoUnidadMedidaTara",
		"Tara",
		"CodigoUnidadMedidaPesoNeto",
		"PesoNeto",
		"CodigoUnidadMedidaPesoBruto",
		"PesoBruto",
		"CodigoBulto",
		"CantidadBulto",
		"Marcas",
		"IdContainer",
		"Sello",
		"EmisorSello",
		"TipoCambio",
		"TipoMoneda",
		"Flete",
		"Seguro",
		"CodigoModalidadVenta",
		"CodigoClausulaVenta",
		"TotalClausulaVenta",
	];

	private validationSumKeyName = ["TotalProducto", "TotalProducto"];
	private validationKeysArray: Array<any> = [this.validationKeysOriginalStandard, this.validationKeyExport];
	@ViewChild("summaryModal") private summaryModal: TemplateRef<any>;
	@ViewChild("confirmSwal") private confirmSwal: SwalComponent;
	@ViewChild("currentSwal") private currentSwal: SwalComponent;

	private innerSummaryRef: NgbModalRef;
	private currentSucursal = this.tokenStorage.getSubsidiary();
	public validDate: boolean = true;
	public superAdm = false;
	constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private facturadorService: FacturadorMasivoService, private papaParser: Papa, private tokenStorage: TokenStorageService, private localStorage: LocalStorageService, private router: Router, private ref: ChangeDetectorRef, public utilities: UtilitiesService, public activeModal: NgbActiveModal, public permissions: PermissionService, private emisorService: EmisorService) {
		this.superAdm = this.tokenStorage.getUser().superAdmin;
		this.empresasSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		if (this.superAdm) {
			this.getEmisoresFullList();
		}
	}

	ngOnInit(): void {
		this.tipoClienteSource = [
			{ id: "0", nombre: "Standard" },
			{ id: "1", nombre: "Exportación" },
		];
		this.emisorId = this.tokenStorage.getCompany();
		this.currentEmisor = this.empresasSource.find((empresa) => empresa.emisorId == this.emisorId);
		//this.searchElements(this.emisorId);
		this.filter.emisorId = this.emisorId;
		this.filter.sucursalId = this.currentSucursal;
		this.setPage({ offset: 0 });
		this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		this.createForm();
		this.saveRows = false;
	}

	openAddDteFileModal(addNewModal) {
		this.files = [];
		this.addModal = this.modalService.open(addNewModal, {
			windowClass: "custom-modal",
			size: "xl",
		});
	}

	changeSearchedRows(data) {
		if (data != null) {
			this.rowsMantenedor = data;
		}
	}

	f() {
		return this.dteFileForm.controls;
	}

	ngAfterViewChecked() {
		if (!this.saveRows && (this.rowsMantenedor[0] != undefined || this.rowsMantenedor[0] != null)) {
			this.searchRows = this.rowsMantenedor;
			this.saveRows = true;
		}
	}

	downloadFile(id: string) {
		this.loading = true;
		this.facturadorService
			.getFile(id)
			.subscribe(
				(res) => {
					let uint8Array = res.data as Uint8Array;
					let b64Response = res.data;
					//obtained  8bit array from response that need to be transformed into a Blob
					try {
						const blobCsv = this.utilities.convertB64ToBlob(b64Response, "text/csv");
						FileSaver.saveAs(blobCsv, "lista_de_facturas.csv");
					} catch (error) {
						this.setSwalAndFire(this.currentSwal, "error", "Error en Archivo", "No fue posible obtener el archivo CSV, favor contactar al administrador\n" + error);
					}
				},
				(error) => {
					this.setSwalAndFire(this.currentSwal, "error", "Error", "Error al obtener el archivo. Intente más tarde." + this.utilities.setErrorMessageFromArray(error.error));
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	detail(detailModal, row: ImportacionMasiva) {
		this.loading = true;
		this.selectedImportacion = row;
		this.facturadorService.getDetail(row.importacionMasivaId).subscribe(
			(result) => {
				let resultString = result.data;
				if (resultString) {
					resultString.replace("\n", "<br>");
					this.DetailString = resultString;
					this.resultStringData = resultString;
					this.detailModal = this.modalService.open(detailModal, {
						windowClass: "",
						size: "lg",
						centered: true,
					});
				}
			},
			(error) => {
				this.setSwalAndFire(this.currentSwal, "error", "Error", "No es posible mostrar los detalles del Archivo en este momento, favor contactar al administrador.\n " + this.utilities.setErrorMessageFromArray(error.error));
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	relatedDtes(row: ImportacionMasiva) {
		let id = row.importacionMasivaId;
		let datePipe = new DatePipe("es-ES");
		let fechaCarga = datePipe.transform(row.fechaCarga, "dd-MM-yyyy");
		let horaCarga = datePipe.transform(row.fechaCarga, "HH:mm");
		let filterDescription = !!row.codigoCarta ? "Código de Carta: " + row.codigoCarta : row.origen + " con fecha " + fechaCarga + " a las " + horaCarga;
		if (row.origenImportacionId == 1) {
			this.router.navigateByUrl("/exportaciones", {
				state: {
					paramId: id,
					filterDescription: "Filtrado por " + filterDescription,
					filterEmisor: this.filter.emisorId,
					filterSucursal: this.filter.sucursalId,
				},
			});
		} else {
			this.router.navigateByUrl("/documentos-emitidos", {
				state: {
					paramId: id,
					filterDescription: "Filtrado por " + filterDescription,
					filterEmisor: this.filter.emisorId,
				},
			});
		}
	}

	onSubmit() {
		this.wrongDelimiter = false;
		if (this.dteFileForm.invalid) {
			return;
		}
		//proccess the first element on files array with the parser
		this.papaParser.parse(this.files[0], {
			header: true,
			delimiter: ";",
			complete: (result) => {
				//PRE-CHECKER IF DATA COME WITH COMMENTS
				this.wrongDelimiter = result.meta.delimiter == "," && this.checkConflictInDelimiter(result);
				this.checkConflictInDelimiter(result);
				let withComments = result.data.some((obj) =>
					Object.keys(obj).some(function (prop) {
						return ~prop.indexOf("#");
					})
				);
				let cleanData: Array<Object> = [];
				if (withComments) {
					//CSV FILE ELEMENTS CLEANING empty objects in Result
					let dataCommentRemoved = result.data.filter((obj) => !Object.values(obj).some((val) => (val as string).includes("#")));
					for (let index = 1; index < dataCommentRemoved.length; index++) {
						this.concatValuesToObjProperty(dataCommentRemoved[index], "#A. Disposiciones Generales:", "__parsed_extra");
					}
					let preCleanData = this.CsvDataToObjects(dataCommentRemoved);
					cleanData = preCleanData.filter((obj) => obj["Id"] != "");
				} else {
					cleanData = result.data.filter((obj) => obj["Id"] != "");
				}

				//PREPARECOLUMN VALIDATION   //tipoCliente.value to validationKeys Array
				let tipoClienteObject = this.dteFileForm.controls.tipoCliente.value;
				//NOW SINCE THERE ARE validations by tipoDte  WE NEED TO GET THE VALUE AND CHECK IF IT'S FACTURA EXPORTACION
				let validationKeys = this.validationKeysArray[tipoClienteObject.id];
				//CSV FILE VALIDATION START
				this.validFile = this.CsvValidation(cleanData, validationKeys, validationKeys);
				//if file is not valid, create Resume of invalid data
				if (!this.validFile) {
					let nameOfCurrentProperties = Object.keys(cleanData[0]);
					this.invalidColumns = nameOfCurrentProperties.filter((propertyName) => !validationKeys.includes(propertyName));
				} else if (this.validFile) {
					this.invalidColumns = [];
				}
				this.fileData = cleanData;
				let documentsByDetail = this.sortByProperty(this.fileData, "Id");
				this.resultedFileDataDocuments = documentsByDetail;
				this.numberOfDocumentosToSend = Object.keys(this.resultedFileDataDocuments).length;
				let sumResult = this.sumArrayProperty(this.fileData, this.validationSumKeyName[tipoClienteObject.id]);
				//SUMA DE NETOS AFECTOS Y EXENTOS
				let sumaResumen: [number, number] = this.getSumAfectosExentos(this.fileData, this.validationSumKeyName[tipoClienteObject.id], "IndicadorExento");
				if (sumaResumen && sumaResumen[0] != null && sumaResumen[1] != null) {
					this.ivaTotal = Math.round(sumaResumen[0] * environment.taxPercentage);
					this.totalFinal = sumaResumen[0] + sumaResumen[1] + this.ivaTotal;
				}
				this.sumaResumenDocumento = sumaResumen;
				this.tipoClienteId = +tipoClienteObject.id;
				this.sumOfDocumentDetails = sumResult;
				this.ref.detectChanges();
				//CSV FILE VALIDATION END
			},
		});
		let stringType = this.dteFileForm.controls.tipoCliente.value.id && this.dteFileForm.controls.tipoCliente.value.id == 1 ? "exporta" : "nacional";
		this.loading = true;
		this.facturadorService
			.validateHeaders(stringType, this.files[0])
			.subscribe(
				(res) => {
					this.externalValidFile = res.data as boolean;
					this.validationMessage = res.message;
					this.ref.detectChanges();
				},
				(error) => {
					Swal.fire({
						icon: "error",
						title: "Error en validación",
						text: "No fue posible realizar la validación del archivo. " + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
			});

		this.onConfirmation(this.summaryModal);
		//open file resumme and sweetalert confirmation onConfirmation
		//such confirmation run the onUpload function
	}

	createForm() {
		this.dteFileForm = this.formBuilder.group({
			file: [null, Validators.required],
			tipoCliente: [this.tipoClienteSource[0], Validators.required],
		});
	}

	onSelect(event) {
		if (event.rejectedFiles[0] || this.getName(event) != "csv") {
			this.setSwalAndFire(this.currentSwal, "error", "Error de Archivo", "El archivo ingresado no es de formato CSV, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo");
		}

		this.files.push(...event.addedFiles);
		if (this.files.length > 1) {
			this.files.shift();
		}
		this.dteFileForm.patchValue({ file: this.files[0] });
	}
	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	getName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}

	onConfirmation(summaryModal) {
		let obj = this.dteFileForm.controls.tipoCliente.value;
		this.tipoClienteId = obj.id;
		this.innerSummaryRef = this.modalService.open(summaryModal, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	confirmSwalOpener() {
		this.setSwalAndFire(this.confirmSwal, "info", "Ejecutar Facturación", "¿Esta seguro de facturar los documentos indicados en el archivo?");
	}
	onCancelUpload() {
		this.modalService.dismissAll();
		this.dteFileForm.reset();
	}

	onUpload() {
		//close all after result, summaryModal and addModal
		this.modalService.dismissAll();
		this.addModal.close();
		let emisionMasivaString = {}; //object with expected data to create new element in backend, tipoClienteId & empresaId
		emisionMasivaString["tipoClienteId"] = this.dteFileForm.controls.tipoCliente.value;
		//emisionMasivaString["empresaId"] = this.dteFileForm.controls.filial.value;
		emisionMasivaString["CantidadSolicitada"] = this.fileData.length;
		//create SolicitudFacturacionMasiva
		let solicitud: SolicitudFacturacionMasiva = new SolicitudFacturacionMasiva();
		solicitud.emisorId = this.tokenStorage.getCompany();
		solicitud.sucursalId = this.tokenStorage.getSubsidiary();
		solicitud.cantidadSolicitada = this.fileData.length;
		solicitud.tipoArchivo = this.dteFileForm.controls.tipoCliente.value.id;
		solicitud.usuarioId = this.currentUser.id;
		this.loading = true;
		//check form request method national or export
		let facturadorRequest: Observable<Response<ImportacionMasiva>>;
		if (solicitud.tipoArchivo == TipoArchivoImportacion.Exportacion) {
			facturadorRequest = this.facturadorService.addExportRequest(solicitud, this.files[0]);
		} else {
			facturadorRequest = this.facturadorService.add(solicitud, this.files[0]);
		}
		facturadorRequest
			.subscribe(
				(result) => {
					this.loading = false;
					this.ref.markForCheck();
					this.setPage({ offset: 0 });
					let message = result.message;
					this.setSwalAndFire(this.currentSwal, "success", "Facturación Exitosa", "Se han facturado los documentos solicitados \n\n " + message);
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					let errorStrings = error.error ? (error.error.errors as string[]) : ["Error desconocido."];
					let completeErrorString = "";
					errorStrings.forEach((errorStr) => {
						// errorStr.replace("\\n", "<br>");
						// errorStr.replace("\\r\n", "<br>");
						completeErrorString += "<p>" + errorStr + "</p>";
					});
					Swal.fire({
						icon: "error",
						title: "Error de Facturación",
						showCloseButton: true,
						html: "<div style='text-align:left;'>No fue posible completar el proceso de facturación. <br>Detalle:<br>" + completeErrorString + "</div>",
					});
				}
			)
			.add(() => {
				this.resetFormFacturacion();
				this.loading = false;
				this.ref.detectChanges();
			});
	}

	onShowResults() {}

	setSwalAndFire(swalReference: SwalComponent, iconString: SweetAlertIcon, titleString: string, textString: string) {
		swalReference.icon = iconString;
		swalReference.title = titleString;
		swalReference.text = textString;
		swalReference.fire();
	}

	CsvValidation(fileResult, keyArray: string[], valueTypeArray: string[]): boolean {
		let isValid = keyArray.every((keyString) => keyString in fileResult[0]);
		return isValid;
	}
	resetFormFacturacion() {
		this.validFile = false;
		this.dteFileForm.reset();
		this.files = [];
		this.fileData = [];
		this.invalidColumns = [];
	}

	setEmisorToElement(row: ImportacionMasiva) {
		let empresaAsociada = this.empresasSource.find((Emisor) => Emisor.emisorId == row.idEmisor);
		row.emisor = empresaAsociada;
	}

	setTipoArchivoToElement(row: ImportacionMasiva) {
		row.nombreOrigenImportacion = this.tipoClienteSource.find((tipoArchivo) => tipoArchivo.id == row.origenImportacionId).nombre;
	}

	sortByProperty(rowsOfDocumentos: any[], propertyKey: string): any[] {
		var groupBy = function (rowsOfDocumentos, propertyKey) {
			return rowsOfDocumentos.reduce(function (rv, x) {
				(rv[x[propertyKey]] = rv[x[propertyKey]] || []).push(x);
				return rv;
			}, {});
		};
		return groupBy(rowsOfDocumentos, propertyKey);
	}

	sumArrayProperty(arrayToSum: Array<any>, propertyToSum: string) {
		let sum: number = 0;
		let auxValue;
		arrayToSum.forEach((detailRow) => {
			auxValue = detailRow[propertyToSum];
			if (!Number.isNaN(auxValue) && auxValue != undefined) {
				sum += this.tipoClienteId == 0 ? parseFloat(auxValue) : this.utilities.realParseFloat(auxValue);
			}
		});
		return sum;
	}

	getSumAfectosExentos(arrayToSum: Array<any>, propertyToSum: string, propertyToCheck: string): [number, number] {
		let sumaNetoAfecto = 0;
		let sumaExento = 0;
		let auxValue;
		let auxExento; //toma valor string de grilla, se pasa a numerico en la sgte variable
		let isExento; //0:Afecto | 1:Exento
		try {
			arrayToSum.forEach((detailRow) => {
				auxValue = detailRow[propertyToSum];
				auxExento = detailRow[propertyToCheck];
				isExento = +auxExento;
				if (!Number.isNaN(auxValue) && auxValue != undefined && isExento != undefined) {
					if (isExento) {
						sumaExento += this.tipoClienteId == 0 ? parseFloat(auxValue) : this.utilities.realParseFloat(auxValue);
					} else {
						sumaNetoAfecto += this.tipoClienteId == 0 ? parseFloat(auxValue) : this.utilities.realParseFloat(auxValue);
					}
				}
			});
		} catch (error) {
			console.log(error);
			Swal.fire({
				icon: "error",
				title: "Error en lectura",
				text: "Se ha producido un error al leer los datos del documento." + error,
			});
		}

		return [sumaNetoAfecto, sumaExento];
	}

	//Formato debe contener:
	//numero de columnas, nombre de las columnas , formato de las columnas
	ordenarPorFecha(lista: ImportacionMasiva[]) {
		lista.sort((a: ImportacionMasiva, b: ImportacionMasiva) => {
			return new Date(b.fechaCarga).getTime() - new Date(a.fechaCarga).getTime();
		});
	}

	chosenYearHandler(normalizedYear: Moment) {
		let ctrlValue = moment(this.dateFrom);
		ctrlValue.year(moment(normalizedYear).year());
		this.dateFrom = moment(ctrlValue);
		this.ref.detectChanges();
	}

	chosenMonthHandler(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
		let ctrlValue = moment(this.dateFrom);
		ctrlValue.month(moment(normalizedMonth).month());
		this.dateFrom = moment(ctrlValue);
		this.ref.detectChanges();
		datepicker.close();
	}
	chosenYearHandlerDateTo(normalizedYear: Moment) {
		let ctrlValue = moment(this.dateTo);
		ctrlValue.year(moment(normalizedYear).year());
		this.dateTo = moment(ctrlValue);
		this.ref.detectChanges();
	}

	chosenMonthHandlerDateTo(normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment>) {
		let ctrlValue = moment(this.dateTo);
		ctrlValue.month(moment(normalizedMonth).month());
		this.dateTo = moment(ctrlValue);
		this.ref.detectChanges();
		datepicker.close();
	}

	getMonthYear(date: Date) {
		return moment(date).month() + "," + moment(date).year();
	}

	detectChangeStartDate(event) {
		let fromDate = moment();
		if (event.target != null) {
			fromDate = moment((<HTMLInputElement>event.target).value, "DD/MM/YYYY");
			this.dateFrom.startDate = fromDate;
		}
	}
	detectChangeEndDate(event) {
		let toDate = moment();
		if (event.target != null) {
			toDate = moment((<HTMLInputElement>event.target).value, "DD/MM/YYYY");
			this.dateTo.endDate = toDate;
		}
	}

	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo, $event?) {
		if ($event) {
			this.filter.fechaDesde = $event.fechaInicio;
			this.filter.fechaHasta = $event.fechaFin;
			this.filter.origen = $event.origenImportacion;
			this.filter.sucursalId = $event.sucursalId ? $event.sucursalId : this.currentSucursal;
			this.filter.emisorId = $event.emisorId ? $event.emisorId : this.emisorId;
		}
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		//this.page.pageNumber +=1;
		this.facturadorService
			.getDataByPage(this.filter, localPage)
			.subscribe(
				(result) => {
					let elements = result.data as ImportacionMasiva[];
					elements.forEach((row) => {
						this.setEmisorToElement(row);
						//this.setTipoArchivoToElement(row);
						row.fechaCarga = moment(row.fechaCarga);
					});
					this.ordenarPorFecha(elements);
					if (elements) {
						this.rowsMantenedor = [...elements];
					}
					this.ref.detectChanges();

					this.setPageFromResponse(this.page, result);
					this.ref.detectChanges();
				},
				(error) => {
					this.setSwalAndFire(this.currentSwal, "error", "Error en solicitud", "No es posible obtener los datos.\nDetalle: " + this.utilities.setErrorMessageFromArray(error.error));
				}
			)
			.add(() => {
				this.loading = false;
				this.componentStarted = true;
				this.ref.detectChanges();
			});
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<ImportacionMasiva>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < -1 ? 0 : data.pageNumber - 1;

		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}

	// getSample2(tipo:number){
	//   this.loading= true;
	//   this.facturadorService.downloadSample(tipo).subscribe(
	//     (res)=>{
	//       this.loading = false;
	//       const arr = res.split('\r\n');
	//       this.ref.detectChanges();
	//       const header = arr[0].split(';');
	//       arr.shift;
	//       let splittedCsvRows = [];
	//       arr.forEach(fullRow => {
	//         let splitedRow = fullRow.split(';');
	//         splittedCsvRows.push(splitedRow);
	//       });
	//       let csvContent = "data:text/csv;charset=utf-8," + splittedCsvRows.map(e => e.join(";")).join("\r\n");
	//       var encodedUri = encodeURI(csvContent);
	//       var link = document.createElement("a");
	//       link.setAttribute("href", encodedUri);
	//       let name = tipo==1?'ejemplo_carga_exportación':'ejemplo_carga_masiva'
	//       link.setAttribute("download", name);
	//       document.body.appendChild(link);
	//       link.click();
	//     },
	//     (error)=>{
	//       this.loading = false;
	//       this.ref.detectChanges();
	//       Swal.fire({
	//         icon:'error',
	//         title:'Error en Ejemplo',
	//         text:'No fue posible obtener el archivo de ejemplo, intente nuevamente.'
	//       })
	//     }
	//   )
	// }

	getSample(tipo: number) {
		this.loading = true;
		this.facturadorService.downloadSample(tipo).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				let filename = tipo == 1 ? "ejemplo_carga_masiva_exportacion.csv" : "ejemplo_carga_masiva_nacional.csv";
				let dataType = res.type;
				let binaryData = [];
				binaryData.push(res);
				let blob = new Blob(binaryData, { type: dataType });
				let downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
				downloadLink.setAttribute("download", filename);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en Ejemplo",
					text: "No fue posible obtener el archivo de ejemplo, intente nuevamente.",
				});
			}
		);
	}

	downloadInstructions() {
		this.loading = true;
		this.facturadorService.getPdfInstructions().subscribe(
			(result) => {
				this.PdfDownload(result.data, "Intrucciones Facturación Masiva");
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Descargar Pdf",
					text: "No fue posible descargar el pdf de instrucciones de facturación masiva, intentar nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	CsvDataToObjects(data: any[]): Object[] {
		let results = [];
		var headersLine: string[] = Object.values(data[0]);
		var dataLines: string[] = [];
		var headers = headersLine[0].split(";");
		data.shift();
		data.forEach((element) => {
			let dataline: string[] = Object.values(element);
			let dataArray = dataline[0].split(";");
			let obj = {};
			for (let index = 0; index < dataArray.length; index++) {
				obj[headers[index]] = dataArray[index];
			}
			results.push(obj);
		});
		return results;
	}

	concatValuesToObjProperty(obj, propertyToSet, propertyToGet) {
		let arrayValues = [];
		if (obj[propertyToGet]) {
			let arrayPropertiesToConcat = obj[propertyToGet].forEach((element) => (obj[propertyToSet] += element));
			obj[propertyToSet] += arrayPropertiesToConcat;
		}
	}

	detectChangeDate(event) {
		let fromDate = moment();
		if (event.startDate != null) {
			this.dateMatrixRequest = event;
		}
		if (event.target != null && event.target.value != null) {
			this.validDate = moment(event.target.value, "DD/MM/YYYY", true).isValid();
			if (this.validDate) {
				fromDate = new Object();
				fromDate.startDate = moment(event.target.value, "DD-MM-YYYY");
				this.dateMatrixRequest = fromDate;
			}
		}
	}

	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	PdfDownload(responseB64, name) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "text/pdf");
		FileSaver.saveAs(pdfBlob, name + ".pdf");
		this.loading = false;
		this.ref.detectChanges();
	}

	downloadGlossary() {
		this.loading = true;
		this.facturadorService.getPdfGlossary().subscribe(
			(result) => {
				this.PdfDownload(result.data, "Glosario");
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Descargar Pdf",
					text: "No fue posible descargar el pdf de glosario, favor recargar intentar nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	checkConflictInDelimiter(result: ParseResult) {
		let containsSeparator = Object.keys(result.data[0] as string).some((n) => n.includes(";"));
		return containsSeparator;
	}

	getEmisoresFullList() {
		this.emisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				this.empresasSource = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
}
