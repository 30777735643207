import { ReferenciaDteEmitido } from "./ReferenciaDteEmitido";

export class OptionsReferenceDteEmitido {
   haveDteReference: boolean = false;
   tipoMoneda: string = "CLP";
   referenceDte: ReferenciaDteEmitido;
   sonDetallesConIVA: boolean = false;
   valorTipoMoneda: number = 0;
   listaReferencias: ReferenciaDteEmitido[];
   tipoMonedaAduana: string;
   codigoMonedaAduana: number;
   globalReferenceForGuideRefs: boolean = false;
}
