export const ConfigCertificados = {
   barraBusqueda: {
      criterios: ["Rut Certificado", "Nombre Certificado"],
      criteriosKeys: ["rut", "nombre"],
      criterioPrincipal: "Rut Certificado",
   },
   table: {
      title: "Mantenedor Certificado",
      add: "Carga Documento",
      options: [
         {
            name: "Importar",
            active: true,
            class: "ft-upload",
         },
         {
            name: "Exportar",
            active: true,
            class: "ft-download",
         },
         {
            name: "Solicitar Soporte",
            active: true,
            class: "ft-shuffle",
         },
      ],
   },
};
