import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, startWith } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Divisa } from "../models/Divisa";
import { InboxTypes } from "../shared/models/InboxType.enum";
import { Response } from "../shared/models/Response";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
	providedIn: "root",
})
export class DashboardService {
	currentEmisor: string;
	private REST_API_SERVER = environment.backend_server + "/dashboard";
	private MONITORING_SERVICE = environment.backend_server + "/monitoring";
	private DIVISAS_API_SERVER = environment.backend_server + "/divisa";
	isLoadingDteSummary: Observable<boolean>;
	isLoadingServicesStatus: Observable<boolean>;
	isLoadingDailyDivisas: Observable<boolean>;
	private loadingDteSummarySubject: BehaviorSubject<boolean>;
	private loadingServicesStatusSubject: BehaviorSubject<boolean>;
	private loadingDailyDivisasSubject: BehaviorSubject<boolean>;
	constructor(private http: HttpClient) {
		this.loadingDteSummarySubject = new BehaviorSubject<boolean>(true);
		this.isLoadingDteSummary = this.loadingDteSummarySubject.asObservable();
		this.loadingServicesStatusSubject = new BehaviorSubject<boolean>(true);
		this.isLoadingServicesStatus = this.loadingServicesStatusSubject.asObservable();
		this.loadingDailyDivisasSubject = new BehaviorSubject<boolean>(true);
		this.isLoadingDailyDivisas = this.loadingDailyDivisasSubject.asObservable();
	}

	dteSummary(emisorId: string, sucursalId: string, diasPrevios: number): Observable<Response<any>> {
		this.loadingDteSummarySubject.next(true);
		return this.http.get<Response<any>>(this.REST_API_SERVER + "/dtesummary?emisorId=" + emisorId + "&sucursalId=" + sucursalId + "&lastNDays=" + diasPrevios).pipe(finalize(() => this.loadingDteSummarySubject.next(false)));
	}

	// monitoringServicesStatuses(): Observable<any> {
	//    this.loadingServicesStatusSubject.next(true);
	//    return this.http.get<any>(this.MONITORING_SERVICE + "/getstatus").pipe(finalize(() => this.loadingServicesStatusSubject.next(false)));
	// }

	getDailyDivisas(): Observable<Response<Divisa[]>> {
		this.loadingDailyDivisasSubject.next(true);
		return this.http.get<Response<Divisa[]>>(this.DIVISAS_API_SERVER + "/ListDivisas").pipe(finalize(() => this.loadingDailyDivisasSubject.next(false)));
	}

	syncDivisas(): Observable<Response<boolean>> {
		this.loadingDailyDivisasSubject.next(true);
		return this.http.get<Response<boolean>>(this.DIVISAS_API_SERVER + "/SyncDivisas").pipe(finalize(() => this.loadingDailyDivisasSubject.next(false)));
	}
}
