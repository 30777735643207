import { Injectable } from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import * as FileSaver from "file-saver";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { Page } from "../shared/models/Page";
const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
   providedIn: "root",
})
export class UtilitiesService {
   constructor() {}

   public convertB64ToBlob(b64String: string, contentType = "", sliceSize = 512): Blob {
      const byteCharacters = atob(b64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);

         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
         }

         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
   }

   public exportAsExcelFile(json: any[], excelFileName: string): void {
      try {
         const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

         const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
         };
         const excelBuffer: any = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
         });
         this.saveAsExcelFile(excelBuffer, excelFileName);
      } catch (Error) {
         Swal.fire({
            icon: "error",
            title: "Error al exportar archivo excel",
            text: "No fue posible exportar la información a un archivo excel, intente nuevamente.",
         });
      }
   }

   private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
         type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
   }

   public setErrorMessageFromArray(error: any) {
      let errorString = "";
      if (error && error.errors && error.errors.length > 0) {
         error.errors.forEach((msg) => {
            errorString += msg + ", ";
         });
      }
      return errorString.substring(0, errorString.length - 2);
   }

   public setLoginErrorMessageFromArray(error: any) {
      let errorString = " ";
      if (error.errors && error.errors.length > 0) {
         error.errors.forEach((msg) => {
            errorString += errorString != "" ? msg : "";
         });
      } else errorString = "No se pudo conectar con el servicio. " + "Ingrese las credenciales correctas.";
      return errorString;
   }

   public convertFileToB64String(file: File): Promise<string> {
      return new Promise<string>((resolve, reject) => {
         let reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result?.toString() || "");
         reader.onerror = (error) => reject(error);
      });
   }

   public sortAlphabeth(list: any[], propertyName: string) {
      list.sort(function (a, b) {
         return a[propertyName].localeCompare(b[propertyName]);
      });
   }

   public getIndexForArrayItem(propertyName: string, propertyValue: string, searchArray: any[]): number {
      return searchArray.findIndex((item) => item[propertyName] == propertyValue);
   }

   onSortTable(table: DatatableComponent, tablePage: Page) {
      table.bodyComponent.updateOffsetY(tablePage.pageNumber);
      table.offset = tablePage.pageNumber;
   }

   public clearOtherFilterCriterias(currentCriteria, filter, config) {
      let otherCriterias = config.barraBusqueda.criteriosKeys.filter((crit) => crit != currentCriteria);
      if (otherCriterias && otherCriterias.length > 0) {
         otherCriterias.forEach((crit) => {
            filter[crit] = null;
         });
      }
      return filter;
   }

   public realParseFloat(str: string): number {
      if (str.includes(",")) {
         let preNumber = str.replace(",", ".");
         return parseFloat(preNumber);
      } else {
         return parseFloat(str);
      }
   }
}
