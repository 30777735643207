import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { EventMessage } from "@azure/msal-browser";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { validarRut, VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { CorreoEnvio } from "src/app/models/CorreoEnvio/CorreoEnvio";
import { PlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/PlantillaCorreoEnvio";
import { TipoCorreoEnvio } from "src/app/models/CorreoEnvio/TipoCorreoEnvio";
import { TipoPlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/TipoPlantillaCorreoEnvio";
import { ReceptorRequest } from "src/app/models/Receptor/ReceptorRequest";
import { CorreoEnvioService } from "src/app/services/CorreoEnvio.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PermissionService } from "src/app/services/permission.service";
import { TokenStorageService } from "src/app/services/token-storage.service";

@Component({
	selector: "app-correo-envio-entry",
	templateUrl: "./correo-envio-entry.component.html",
	styleUrls: ["./correo-envio-entry.component.scss"],
})
export class CorreoEnvioEntryComponent implements OnInit {
	//@ViewChild("sendEmailFormRef") private childFormRef: NgForm;
	public sendEmailForm: FormGroup;
	private correoEnvioObj: CorreoEnvio = new CorreoEnvio();
	private emisorId: string = this.tokenStorage.getCompany();
	public sengridtype = false;
	public emailSendingType: TipoCorreoEnvio[] = JSON.parse(this.localStorage.getTipooCorreoEnvio()) as TipoCorreoEnvio[];
	public templateTypes: TipoPlantillaCorreoEnvio[] = JSON.parse(this.localStorage.getTipoPlantillaoCorreoEnvio()) as TipoPlantillaCorreoEnvio[];

	@Input() public correoEnvio: CorreoEnvio = undefined;
	@Input() public editMode: boolean = null;
	@Input() public detailMode: boolean = null;
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendCorreoEnvioRequest: EventEmitter<any> = new EventEmitter<any>();
	constructor(private formBuilder: FormBuilder, private tokenStorage: TokenStorageService, public permissions: PermissionService, public localStorage: LocalStorageService, public correoEnvioService: CorreoEnvioService) {}

	ngOnInit(): void {
		this.createForm();
		if (this.editMode || this.detailMode) {
			this.changeType();
			this.setcorreoEnvioDataToForm(this.correoEnvio);
			//console.log("datos cargados",this.sendEmailForm.controls,this.correoEnvioToEdit,"editmode",this.editMode);
		}
		this.changeType();
	}

	createForm() {
		this.sendEmailForm = this.formBuilder.group({
			tipo: [this.emailSendingType[0], [Validators.required]],
			nombre: ["", Validators.required],
			apikey: ["", Validators.required],
			servidor: ["", Validators.required],
			puerto: [993, Validators.required],
			ssl: [false, Validators.required],
			correo: ["", [Validators.required, SingleEmailValidator]],
			remitente: ["", Validators.required],
			password: ["", Validators.required],
			tipoPlantilla: [this.templateTypes[0], [Validators.required]],
		});
	}
	onSubmit() {
		if (this.sendEmailForm.valid) {
			this.getFormData();
			//console.log("default submit",this.correoEnvioObj);
			this.sendCorreoEnvioRequest.emit(this.correoEnvioObj);
		}
	}
	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}

	getFormData() {
		var type = this.sendEmailForm.controls.tipo.value as TipoCorreoEnvio;
		this.correoEnvioObj.tipoEnvioCorreoId = type.tipoEnvioCorreoId;
		this.correoEnvioObj.nombreTipoEnvio = type.nombre;
		var templateType = this.sendEmailForm.controls.tipoPlantilla.value as TipoPlantillaCorreoEnvio;
		this.correoEnvioObj.tipoPlantillaId = templateType.tipoPlantillaEnvioCorreoId;
		this.correoEnvioObj.nombreTipoPlantillaEnvio = templateType.nombre;
		this.correoEnvioObj.nombre = this.sendEmailForm.controls.nombre.value;
		this.correoEnvioObj.apiKey = this.sendEmailForm.controls.apikey.value;
		this.correoEnvioObj.correo = this.sendEmailForm.controls.correo.value;
		this.correoEnvioObj.password = this.sendEmailForm.controls.password.value;
		this.correoEnvioObj.servidor = this.sendEmailForm.controls.servidor.value;
		this.correoEnvioObj.puerto = this.sendEmailForm.controls.puerto.value;
		this.correoEnvioObj.senderName = this.sendEmailForm.controls.remitente.value;
		this.correoEnvioObj.servidor = this.sendEmailForm.controls.servidor.value;
		this.correoEnvioObj.ssl = this.sendEmailForm.controls.ssl.value;
		this.correoEnvioObj.isSengrid = this.sengridtype ? true : false;
		if (this.editMode) this.correoEnvioObj.envioCorreoId = this.correoEnvio.envioCorreoId;
	}

	setcorreoEnvioDataToForm(receivedcorreoEnvio: CorreoEnvio) {
		// this.sendEmailForm.patchValue(receivedcorreoEnvio);
		this.sendEmailForm.get("tipo").patchValue(receivedcorreoEnvio.tipoEnvioCorreo);
		this.sendEmailForm.get("tipoPlantilla").patchValue(receivedcorreoEnvio.tipoPlantillaEnvioCorreo);
		this.sendEmailForm.get("apikey").patchValue(receivedcorreoEnvio.apiKey != null ? receivedcorreoEnvio.apiKey : "");
		this.sendEmailForm.get("servidor").patchValue(receivedcorreoEnvio.servidor != null ? receivedcorreoEnvio.servidor : "");
		this.sendEmailForm.get("puerto").patchValue(receivedcorreoEnvio.puerto != null ? receivedcorreoEnvio.puerto : 993);
		this.sendEmailForm.get("password").patchValue(receivedcorreoEnvio.password != null ? receivedcorreoEnvio.password : "");
		this.sendEmailForm.get("remitente").patchValue(receivedcorreoEnvio.senderName != null ? receivedcorreoEnvio.senderName : "");
		this.sendEmailForm.get("nombre").patchValue(receivedcorreoEnvio.nombre != null ? receivedcorreoEnvio.nombre : "");
		this.sendEmailForm.get("correo").patchValue(receivedcorreoEnvio.correo != null ? receivedcorreoEnvio.correo : "");
		this.sendEmailForm.get("ssl").patchValue(receivedcorreoEnvio.ssl != null ? receivedcorreoEnvio.ssl : false);
	}

	changeType() {
		var type = this.sendEmailForm.controls.tipo.value as TipoCorreoEnvio;
		if (type.nombre.toLowerCase() == "sengrid") {
			this.sengridtype = true;
			this.sendEmailForm.controls.apikey.setValidators([Validators.required]);
			this.sendEmailForm.controls.servidor.setValidators([]);
			this.sendEmailForm.controls.puerto.setValidators([]);
			this.sendEmailForm.controls.ssl.setValidators([]);
			this.sendEmailForm.controls.password.setValidators([]);
			this.sendEmailForm.get("ssl").patchValue(false);
			this.sendEmailForm.get("servidor").patchValue("");
			this.sendEmailForm.get("puerto").patchValue(0);
			this.sendEmailForm.get("password").patchValue("");
		} else {
			this.sengridtype = false;
			this.sendEmailForm.controls.apikey.setValidators([]);
			this.sendEmailForm.get("apikey").patchValue("");
			this.sendEmailForm.controls.servidor.setValidators([Validators.required]);
			this.sendEmailForm.controls.puerto.setValidators([Validators.required]);
			this.sendEmailForm.controls.ssl.setValidators([Validators.required]);
			this.sendEmailForm.controls.password.setValidators([Validators.required]);
		}
		this.sendEmailForm.updateValueAndValidity();
	}

	changeSsl($event) {
		this.sendEmailForm.controls.ssl.patchValue($event.target.checked);
	}
}
