import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { AuthConfigService } from "./auth-config.service";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router, private config: AuthConfigService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let token = this.tokenStorage.getToken();
		if (token != null) {
			this.tokenStorage.userIsLogged.emit(true);
			return true;
		} else {
			this.router.navigate([this.config.LoginRoute]); //
			this.authService.logout();
			return false;
		}
	}
}
