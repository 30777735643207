import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LogoEmisor } from "src/app/models/Emisor/LogoEmisor";
import { LogoEmisorRequest } from "src/app/models/Emisor/LogoEmisorRequest";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { EmisorService } from "src/app/services/emisor.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-logo-emisor-entry",
	templateUrl: "./logo-emisor-entry.component.html",
	styleUrls: ["./logo-emisor-entry.component.scss"],
})
export class LogoEmisorEntryComponent implements OnInit {
	public loading: boolean = false;
	@Input() relatedEmisor: Emisor;
	@Input() public currentLogoEmisor: LogoEmisor;
	@Output() private sendlogoEmisorRequest: EventEmitter<any> = new EventEmitter<any>();
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	public emisorLogoFormGroup: FormGroup;
	public files: File[] = [];
	public imageFitSource: string[] = ["fill", "contain", "cover", "scale-down", "none"]; // based on object-fit css options
	public logoRequest: LogoEmisorRequest = new LogoEmisorRequest();
	public plantillasPdfDisponibles: PlantillaImpresion[] = [];
	private defaultPlantilla: PlantillaImpresion;
	constructor(private formBuilder: FormBuilder, private utilities: UtilitiesService, private emisorService: EmisorService, private ref: ChangeDetectorRef, private localStorage: LocalStorageService) {}

	ngOnInit(): void {
		this.setLogoForm();
		this.getCurrentDataToForm();
	}

	ngOnDestroy() {
		this.currentLogoEmisor = undefined;
	}

	setLogoForm() {
		this.emisorLogoFormGroup = this.formBuilder.group({
			logoFile: [, Validators.required],
			plantillaImpresionPreview: [this.defaultPlantilla],
		});
	}

	closeLogoModal() {
		this.sendCloseRequestEmitter.emit();
	}

	onSelect(event) {
		var fileExtensionName = this.getName(event);
		if (event.rejectedFiles[0] || (fileExtensionName.toLowerCase() != "png" && fileExtensionName.toLowerCase() != "jpg" && fileExtensionName.toLowerCase() != "jpeg")) {
			Swal.fire({
				icon: "error",
				title: "Error de Archivo",
				text: "El archivo ingresado no es de formato de imagen .JPG o .PNG, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo",
			});
		}

		this.files.push(...event.addedFiles);
		if (this.files.length > 1) {
			this.files.shift();
		}
		this.emisorLogoFormGroup.controls.logoFile.patchValue(this.files[0]);
		this.logoRequest.logoCompleteFile = this.files[0];
		this.logoRequest.nombre = event.addedFiles[0].name;
		this.loading = true;
		this.utilities
			.convertFileToB64String(this.logoRequest.logoCompleteFile)
			.then((data) => {
				this.logoRequest.logoFile = data.split(",")[1];
				this.loading = false;
				this.ref.detectChanges();
			})
			.catch(() => {
				this.loading = false;
				this.ref.detectChanges();
			});
	}
	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
		this.emisorLogoFormGroup.controls.logoFile.patchValue(null);
	}

	getName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}

	onSubmit() {
		if (this.emisorLogoFormGroup.valid || this.logoRequest.logoCompleteFile || (this.currentLogoEmisor && this.currentLogoEmisor.logoFile)) {
			this.getLogoFormData();

			this.sendlogoEmisorRequest.emit(this.logoRequest);
		}
	}

	base64toBlob(base64Data: string, contentType: string = "", sliceSize: number = 512): Blob {
		const byteCharacters = atob(base64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	getLogoFormData() {
		this.logoRequest.emisorId = this.relatedEmisor.emisorId;

		let file: File;

		if (this.emisorLogoFormGroup.controls.logoFile.value != null) {
			file = this.emisorLogoFormGroup.controls.logoFile.value;
			this.logoRequest.logoCompleteFile = file;
		} else if (this.currentLogoEmisor && this.currentLogoEmisor.logoFile) {
			let logoFile = this.currentLogoEmisor.logoFile;
			let contentType = "image/png"; // TODO: cambiar por el tipo mime real del archivo
			let blob = this.base64toBlob(logoFile, contentType);
			file = new File([blob], "filename", { type: contentType });
			this.logoRequest.logoFile = logoFile;
		}
		this.logoRequest.fechaSubida = new Date();
		let plantilla = this.emisorLogoFormGroup.controls.plantillaImpresionPreview.value as PlantillaImpresion;
		this.logoRequest.tipoPlantillaIdPreview = plantilla ? plantilla.tipoPlantillaId : undefined;
		this.logoRequest.tipoPlantillaCodigo = plantilla ? plantilla.codigoTipoDte : undefined;
	}

	getCurrentDataToForm() {
		if (this.relatedEmisor) {
			this.plantillasPdfDisponibles = this.relatedEmisor.plantillasPdfOfEmisor && this.relatedEmisor.plantillasPdfOfEmisor.length > 0 ? this.relatedEmisor.plantillasPdfOfEmisor : [];
			this.defaultPlantilla = this.plantillasPdfDisponibles.find((plantilla) => plantilla.isDefault);
			//this.emisorLogoFormGroup.controls.plantillaImpresionPreview.patchValue(this.plantillasPdfDisponibles.length > 0 ? this.plantillasPdfDisponibles[0] : undefined);
			this.emisorLogoFormGroup.controls.plantillaImpresionPreview.setValue(this.defaultPlantilla);
			this.ref.detectChanges();
		}
	}

	previewOfLogo() {
		if (this.emisorLogoFormGroup.valid || this.logoRequest.logoFile || (this.currentLogoEmisor && this.currentLogoEmisor.logoFile)) {
			this.getLogoFormData();
			this.loading = true;
			if (this.logoRequest.tipoPlantillaCodigo < 110) {
				this.emisorService.getLogoPreviewNacional(this.logoRequest).subscribe(
					(result) => {
						this.loading = false;
						this.ref.detectChanges();
						let b64Response = result.data;
						try {
							if (b64Response == "") {
								throw "Respuesta vacía";
							} else {
								this.responseToPdfPreview(b64Response);
							}
						} catch (error) {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "error",
								title: "Error al obtener muestra",
								text: "No es posible renderizar la muestra asociada a esta plantilla de impresión.",
							});
						}
					},
					(error) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Error en previsualización",
							text: "No fue posible realizar la previsualización del log agregado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
						});
					}
				);
			} else {
				this.emisorService.getLogoPreviewExporta(this.logoRequest).subscribe(
					(result) => {
						this.loading = false;
						this.ref.detectChanges();
						let b64Response = result.data;
						try {
							if (b64Response == "") {
								throw "Respuesta vacía";
							} else {
								this.responseToPdfPreview(b64Response);
							}
						} catch (error) {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "error",
								title: "Error al obtener muestra",
								text: "No es posible renderizar la muestra asociada a esta plantilla de impresión.",
							});
						}
					},
					(error) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Error en previsualización",
							text: "No fue posible realizar la previsualización del log agregado, intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
						});
					}
				);
			}
		} else {
			Swal.fire({
				icon: "info",
				title: "Selecciona Logo para Previsualizar",
				text: "Selecciona un nuevo logo para poder previsualizar el logo en el documento seleccionado",
			});
		}
	}

	responseToPdfPreview(responseB64) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "application/pdf");
		//FileSaver.saveAs(pdfBlob, currentPlantilla.descripcion+"_prueba.pdf");
		if (pdfBlob.size > 0) {
			let fileURL = URL.createObjectURL(pdfBlob);
			this.popupCenter(fileURL, "PDF", 600, 800);
		}
		this.loading = false;
		this.ref.detectChanges();
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const newWindow = window.open(
			url,
			title,
			`
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}
}
