export const ConfigMantenedorEmisores = {
	barraBusqueda: {
		criterios: ["Razón Social", "Rut", "Giro", "Ciudad", "Comuna"],
		criteriosKeys: ["razonSocial", "rut", "giro", "ciudad", "comuna"],
		criterioPrincipal: "Razón Social",
	},
	table: {
		title: "Empresas",
		add: "Agregar Empresa",
		options: [
			{
				name: "Importar",
				active: true,
				class: "ft-upload",
			},
			{
				name: "Exportar",
				active: true,
				class: "ft-download",
			},
			{
				name: "Solicitar Soporte",
				active: true,
				class: "ft-shuffle",
			},
		],
	},
};
