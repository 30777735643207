export class PersonaAutorizadaScrapper {
   personaAutorizadaScraperId: string;
   rut: number;
   dv: string;
   nombre: string;
   password: string;
   emisorId: string;
   activo: boolean;
   rutFormateado: string;
}
