<div class="row" *ngIf="showLegacyBar">
   <div class="pull-left mt-5 ml-5">
      <form class="float-right form-busqueda ml-4" [formGroup]="inputBusqueda" (keyup)="buscar_enviar($event)">
         <div class="row" *ngIf="usingEmisorFilter">
            <div class="col-6">
               <div class="form-group">
                  <input #palabra type="text" class="form-control" [(ngModel)]="cadenaBuscada"
                     formControlName="stringABuscar" placeholder="Búsqueda rápida" />
               </div>
            </div>
            <div class="col-6">
               <div class="form-group">
                  <ng-select formControlName="emisorSelected" [items]="currentEmisoresList" [multiple]="false"
                     placeholder="Empresas Asociadas" bindLabel="razonSocial" (change)="buscar_enviar($event)">
                     <ng-template ng-option-tmp let-item="item">
                        <div [ngbTooltip]="item.razonSocial" placement="bottom" container="body">{{item.razonSocial}}
                        </div>
                     </ng-template>
                  </ng-select>
               </div>
            </div>
         </div>
         <div class="row" *ngIf="!usingEmisorFilter">
            <div class="col-12">
               <div class="form-group">
                  <input #palabra type="text" class="form-control" [(ngModel)]="cadenaBuscada"
                     formControlName="stringABuscar" placeholder="Búsqueda rápida" />
               </div>
            </div>

         </div>


      </form>
   </div>
   <ng-template [ngIf]="usingCheckbox">
      <div class="mt-5 ml-5 pt-1 align-middle">
         <div class="form-check form-check-inline">
            <div class="row ml-3">
               <div class="col-2">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" [(ngModel)]="checkboxValue"
                     (change)="applyFilter($event)">
               </div>
               <div class="col-2 pl-3">
                  <label class="" for="inlineCheckbox1">Vigentes</label>
               </div>
            </div>
         </div>
      </div>
   </ng-template>
</div>

<ngb-accordion *ngIf="!showLegacyBar">
   <ngb-panel>
      <ng-template ngbPanelHeader>
         <button ngbPanelToggle class="accordion-button bg-white btn btn-block text-left d-flex"
            (click)="clickedAccordionButton()">
            <p class="h5 d-inline">Filtros de Busqueda</p><span *ngIf="clicked" title="Item a corregir"
               aria-label="Item a corregir" [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-down.svg'"
               cacheSVG="true" class="ml-auto svg-icon svg-icon-xl svg-icon-primary"></span>
            <span *ngIf="!clicked" title="Item a corregir" aria-label="Item a corregir"
               [inlineSVG]="'./assets/media/svg/icons/Navigation/Angle-right.svg'" cacheSVG="true"
               class="ml-auto svg-icon svg-icon-xl svg-icon-primary"></span>
         </button>

      </ng-template>
      <ng-template ngbPanelContent>
         <ng-container *ngTemplateOutlet="fastSearchAccordion"></ng-container>
      </ng-template>
   </ngb-panel>
</ngb-accordion>

<ng-template #fastSearchAccordion>
   <div class="row">
      <div class="pull-left mt-5 ml-5">
         <form class="float-right form-busqueda ml-4" [formGroup]="inputBusqueda" (keyup)="buscar_enviar($event)">
            <div class="row" *ngIf="usingEmisorFilter">
               <div class="col-6">
                  <div class="form-group">
                     <input #palabra type="text" class="form-control" [(ngModel)]="cadenaBuscada"
                        formControlName="stringABuscar" placeholder="Búsqueda rápida" />
                  </div>
               </div>
               <div class="col-6">
                  <div class="form-group">
                     <ng-select formControlName="emisorSelected" [items]="currentEmisoresList" [multiple]="false"
                        placeholder="Empresas Asociadas" bindLabel="razonSocial" (change)="buscar_enviar($event)">
                        <ng-template ng-option-tmp let-item="item">
                           <div [ngbTooltip]="item.razonSocial" placement="bottom" container="body">{{item.razonSocial}}
                           </div>
                        </ng-template>
                     </ng-select>
                  </div>
               </div>
            </div>
            <div class="row" *ngIf="!usingEmisorFilter">
               <div class="col-12">
                  <div class="form-group">
                     <input #palabra type="text" class="form-control" [(ngModel)]="cadenaBuscada"
                        formControlName="stringABuscar" placeholder="Búsqueda rápida" />
                  </div>
               </div>

            </div>


         </form>
      </div>
      <ng-template [ngIf]="usingCheckbox">
         <div class="mt-5 ml-5 pt-1 align-middle">
            <div class="form-check form-check-inline">
               <div class="row ml-3">
                  <div class="col-2">
                     <input class="form-check-input" type="checkbox" id="inlineCheckbox1" [(ngModel)]="checkboxValue"
                        (change)="applyFilter($event)">
                  </div>
                  <div class="col-2 pl-3">
                     <label class="" for="inlineCheckbox1">Vigentes</label>
                  </div>
               </div>
            </div>
         </div>
      </ng-template>
   </div>
</ng-template>