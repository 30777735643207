import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VerificacionRutValidator } from 'src/app/directives/verifica-rut.directive';
import { CertificadoDigital } from 'src/app/models/CertificadoDigital/CertificadoDigital';
import { Emisor } from 'src/app/models/Emisor/Emisor';
import { CertificadosDigitalesService } from 'src/app/services/certificados-digitales.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PermissionService } from 'src/app/services/permission.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Permission } from 'src/app/shared/models/Permission';
import Swal from 'sweetalert2';
import { MantenedorBaseComponent } from '../../layout/layout-mantenedor/mantenedor-base/mantenedor-base.component';
import { ConfigCertificados } from './config-certificados';
import * as FileSaver from 'file-saver';
import { EmisorService } from 'src/app/services/emisor.service';
import { title } from 'process';
import { CertificadoDigitalFilter } from 'src/app/models/CertificadoDigital/CertificadoDigitalFilter';
import { Page } from 'src/app/shared/models/Page';
import { PagedResponse } from 'src/app/shared/models/PagedResponse';
import * as moment from 'moment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CertificadoFileObject } from 'src/app/models/CertificadoDigital/CertificadoFileObject';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-mantenedor-certificados',
  templateUrl: './mantenedor-certificados.component.html',
  styleUrls: ['./mantenedor-certificados.component.scss'],
})
export class MantenedorCertificadosComponent implements OnInit {
  SortType = SortType;
  private mantenedorComponent: MantenedorBaseComponent<CertificadoDigital>;
  public sessionEmisorId: string = ''; //obtener emisor predefinido del usuario
  public sessionEmisor: Emisor;
  private filter: CertificadoDigitalFilter = new CertificadoDigitalFilter();
  public rowsMantenedor: CertificadoDigital[] = [];
  public page: Page = new Page();
  public certificateForm: FormGroup;
  public emisoresSource: Emisor[];
  public certificadosPermissions: Permission[] = [
    this.permissions.getPermission('20-20'),
    this.permissions.getPermission('20-20-10'),
    this.permissions.getPermission('20-20-12'),
    this.permissions.getPermission('20-20-14'),
  ];
  public componentStarted: boolean = false;
  defaultFilterCriteria = { criterio: 'Certificado', busqueda: '' };
  configLocal = ConfigCertificados;
  formCriteriaCertificados;
  objetoCertificado: CertificadoDigital = new CertificadoDigital();
  certificadoFastSearchCriteria = [
    { key: 'rut' },
    { key: 'friendlyName' },
    { key: 'fechaSubida' },
    { key: 'fechaVenc' },
  ];
  empresasSource: Emisor[];
  sourceForSelectInputs: Array<any> = [];
  columnsToRender: Array<any>;
  public today: number = Date.now();
  public loading: boolean = false;
  public files: File[] = [];
  public superAdm = false;
  public createPlantillaModalRef: NgbModalRef;
  @ViewChild('tableMobile') tableRef: any;
  constructor(
    public certificadosService: CertificadosDigitalesService,
    private tokenService: TokenStorageService,
    private localStorage: LocalStorageService,
    public permissions: PermissionService,
    public utilities: UtilitiesService,
    private emisorService: EmisorService,
    private ref: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.superAdm = this.tokenService.getUser().superAdmin;
    this.sessionEmisorId = this.tokenService.getCompany();
    this.emisoresSource = JSON.parse(
      this.localStorage.getEmisores()
    ) as Emisor[];
    this.sessionEmisor = this.emisoresSource.find(
      (emisor) => emisor.emisorId == this.sessionEmisorId
    );
    if (this.superAdm) {
      this.getEmisoresFullList();
    }
    this.filter.activo = true;
    this.filter.emisorId = this.sessionEmisorId;
    this.createForm();
  }

  ngOnInit(): void {
    this.setPage({ offset: 0 });
  }

  setPageSize() {
    //this.page.currentPageSize = this.page.page
    this.setPage({ offset: 0 });
  }
  toggleExpandRow(row) {
    this.tableRef.rowDetail.toggleExpandRow(row);
  }
  setPage(pageInfo) {
    this.loading = true;
    this.page.pageNumber = pageInfo.offset;
    let localPage = JSON.parse(JSON.stringify(this.page));
    localPage.pageNumber += 1;
    this.certificadosService
      .getDataByPage(this.filter, localPage)
      .subscribe(
        (result) => {
          this.loading = false;
          let rows = result.data as CertificadoDigital[];
          this.rowsMantenedor = [...rows];
          var today = moment();
          this.rowsMantenedor.forEach((row) => {
            row.mesesRestantes = today.diff(row.fechaVenc, 'months') * -1;
          });
          this.setPageFromResponse(this.page, result);
          this.ref.detectChanges();
        },
        (error) => {
          this.loading = false;
          this.ref.detectChanges();
          Swal.fire({
            icon: 'error',
            title: 'Error en Lista de Certificados',
            text:
              'No fue posible obtener la lista de certificados disponibles, recargue la pagina.' +
              this.utilities.setErrorMessageFromArray(error.error),
          });
        }
      )
      .add(() => (this.componentStarted = true));
  }
  setPageFromResponse(
    currentPage: Page,
    data: PagedResponse<CertificadoDigital>
  ) {
    currentPage.pageSize = data.pageSize;
    currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
    currentPage.totalPages = data.totalPages;
    currentPage.totalElements = data.totalRecords;
  }

  popupCenter(url, title, w, h) {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
scrollbars=yes,
width=${w / systemZoom}, 
height=${h / systemZoom}, 
top=${top}, 
left=${left}
`
    );
    if (window.focus) {
      newWindow.focus();
    }
  }
  searchElementsByFilter(event) {
    //console.log(event);
    //this.filter[event.criterio] = event.busqueda;
    this.filter.rut =
      event.criterio == 'rut' ? (event.busqueda ? event.busqueda : '') : '';
    this.filter.nombre =
      event.criterio == 'nombre' ? (event.busqueda ? event.busqueda : '') : '';
    this.filter.activo = event.registroActivo ? false : true;
    this.filter.emisorId =
      event.emisorId == null ? this.sessionEmisorId : event.emisorId;
    this.setPage({ offset: 0 });
  }
  calcRemainingDays(fechaVencimiento: string): number {
    let fechaVenc = Date.parse(fechaVencimiento);
    let diff: number = fechaVenc.valueOf() - this.today;
    let diffDays: number = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays > 0 ? diffDays : 0;
  }

  CertificateDownloadRequest(event: CertificadoDigital) {
    this.certificadosService.downloadCertificateRequest(event).subscribe(
      (res) => {
        this.loading = true;
        let b64Response = res.data;
        const BlobPfx = this.utilities.convertB64ToBlob(
          b64Response,
          'application/x-pkcs12'
        );
        FileSaver.saveAs(BlobPfx, 'Certificado ' + event.rutString + '.pfx');
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Error en descarga',
          text:
            'No fue posible descargar el certificado, intente nuevamente. ' +
            this.utilities.setErrorMessageFromArray(error.error),
        });
      }
    );
  }

  showPass(event: CertificadoDigital) {
    if (this.superAdm) {
      this.certificadosService.getPassword(event).subscribe(
        (res) => {
          this.loading = true;
          Swal.fire({
            icon: 'success',
            title: 'Maneja este dato con mucho cuidado',
            text: res.data,
          });
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Error en descarga',
            text:
              'No fue posible descargar el certificado, intente nuevamente. ' +
              this.utilities.setErrorMessageFromArray(error.error),
          });
        }
      );
    }
  }

  getEmisoresFullList() {
    this.emisorService.getData().subscribe(
      (result) => {
        let emisores = result.data;
        this.emisoresSource = emisores;
        this.ref.detectChanges();
      },
      (error) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'error',
          title: 'Error en emisores',
          text:
            'No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas.' +
            this.utilities.setErrorMessageFromArray(error.error),
        });
      }
    );
  }

  activateCertificate(row: CertificadoDigital) {
    this.loading = true;
    this.certificadosService.reactivateCertificate(row).subscribe(
      (result) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'success',
          title: 'Certificado Activado',
          text: 'El certificado fue activado',
        });
        this.setPage({ offset: 0 });
      },
      (error) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'error',
          title: 'Error al reactivar',
          text:
            'Ocurrió un error al intentar reactivar el certificado seleccionado, intente nuevamente. ' +
            this.utilities.setErrorMessageFromArray(error.error),
        });
      }
    );
  }

  deactivateCertificate(event: CertificadoDigital) {
    this.loading = true;
    this.certificadosService.deactivateCertificate(event).subscribe(
      (result) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'success',
          title: 'Certificado Desactivado',
          text: 'El certificado fue desactivado',
        });
        this.setPage({ offset: 0 });
      },
      (error) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'error',
          title: 'Error al desactivar',
          text:
            'Ocurrió un error al intentar desactivar el certificado seleccionado, intente nuevamente. ' +
            this.utilities.setErrorMessageFromArray(error.error),
        });
      }
    );
  }

  deleteCertificate(event: CertificadoDigital) {
    this.loading = true;
    this.certificadosService.delete(event).subscribe(
      (result) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'success',
          title: 'Certificado Eliminado',
          text: 'El certificado fue eliminado',
        });
        this.setPage({ offset: 0 });
      },
      (error) => {
        this.loading = false;
        this.ref.detectChanges();
        Swal.fire({
          icon: 'error',
          title: 'Error al Eliminar',
          text:
            'Ocurrió un error al intentar eliminar el certificado seleccionado, intente nuevamente. ' +
            this.utilities.setErrorMessageFromArray(error.error),
        });
      }
    );
  }

  openCreateCertificateModal(modalRef) {
    this.createForm();
    this.files = [];
    this.createPlantillaModalRef = this.modalService.open(modalRef, {
      windowClass: '',
      size: 'lg',
    });
  }
  closeCreateCertificateModal() {
    this.createPlantillaModalRef.close();
  }
  createForm() {
    this.certificateForm = this.formBuilder.group({
      password: [, Validators.required],
      rutString: [, [Validators.required, VerificacionRutValidator]],
      emisor: [this.sessionEmisor, Validators.required],
      file: [, Validators.required],
    });
  }

  onFileChangeCER(event) {
    if (event.rejectedFiles[0] || this.getName(event) != 'pfx') {
      Swal.fire({
        icon: 'error',
        title: 'Error de Archivo',
        text:
          'El archivo ingresado no es de formato pfx, favor agregar un archivo con el formato correcto. Razón: ' +
            event.rejectedFiles[0].reason ==
          'size'
            ? 'Tamaño'
            : 'Tipo de archivo',
      });
    } else {
      this.files.push(...event.addedFiles);
      if (this.files.length > 1) {
        this.files.shift();
      }
      this.certificateForm.controls.file.patchValue({ file: this.files[0] });
    }
  }
  getName(event): string {
    if (event.addedFiles) {
      return event.addedFiles[0].name.split('.').pop();
    } else if (event.rejectedFiles) {
      return event.rejectedFiles[0].name.split('.').pop();
    } else {
      return '';
    }
  }

  onRemoveCertificaddoArray(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  addCertificate() {
    if (this.certificateForm.valid) {
      this.loading = true;
      var certificate = new CertificadoFileObject();
      certificate.rutString = this.certificateForm.controls.rutString.value;
      certificate.password = this.certificateForm.controls.password.value;
      var issuing = this.certificateForm.controls.emisor.value as Emisor;
      certificate.emisorId = issuing.emisorId;
      certificate.file = this.files[0];
      this.certificadosService.addCertificate(certificate).subscribe(
        (res) => {
          this.loading = false;
          this.ref.detectChanges();
          Swal.fire({
            icon: 'success',
            title: 'Certificado Guardado',
            text: 'El certificado fue guardado correctamente',
          });
          this.closeCreateCertificateModal();
          this.setPage({ offset: 0 });
        },
        (err) => {
          this.loading = false;
          this.ref.detectChanges();
          Swal.fire({
            icon: 'error',
            title: 'Error al Guardar',
            text:
              'Ocurrió un error al intentar guardar el certificado digital, intente nuevamente. ' +
              this.utilities.setErrorMessageFromArray(err.error),
          });
        }
      );
    }
  }
}
