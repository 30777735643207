import { Injectable } from "@angular/core";
import { DteFilter } from "../models/Dte/DteFilter";
import { Observable } from "rxjs";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Consolidado, ConsolidadoEstadistica } from "../models/Dte/Consolidado";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ConsolidadoService {
	private REST_API_SERVER = environment.backend_server + "/consolidado";

	constructor(private http: HttpClient) {}

	public getDataByPage(filtro: DteFilter, page: Page): Observable<PagedResponse<Consolidado>> {
		return this.http.get<PagedResponse<Consolidado>>(this.REST_API_SERVER + "/list/filter?emisorId=" + filtro.emisorId + "&tipoDteId=" + filtro.tipoDteId + "&fechaDesde=" + (filtro.fechaDesde != undefined ? filtro.fechaDesde.toISOString() : "") + "&fechaHasta=" + (filtro.fechaHasta != undefined ? filtro.fechaHasta.toISOString() : "") + "&SucursalId=" + (filtro.sucursalId ? filtro.sucursalId : "") + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
	}

	public getTotals(filtro: DteFilter): Observable<ConsolidadoEstadistica> {
		return this.http.get<ConsolidadoEstadistica>(this.REST_API_SERVER + "/list/totals?emisorId=" + filtro.emisorId + "&tipoDteId=" + filtro.tipoDteId + "&fechaDesde=" + (filtro.fechaDesde != undefined ? filtro.fechaDesde.toISOString() : "") + "&fechaHasta=" + (filtro.fechaHasta != undefined ? filtro.fechaHasta.toISOString() : "") + "&SucursalId=" + (filtro.sucursalId ? filtro.sucursalId : ""));
	}
}
