<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<form [formGroup]="emisorLogoFormGroup" #logoFormRef="ngForm" (ngSubmit)="onSubmit()">
	<div class="modal-body">
		<div class="row current-logo-container" *ngIf="currentLogoEmisor && currentLogoEmisor.logoFile">
			<div class="col-10 offset-sm-2 mb-3">
				<img [src]="'data:image/png;base64,' + currentLogoEmisor.logoFile" alt="logo actual" class="img-thumbnail" />
			</div>
		</div>
		<div class="row new-logo-container mb-3" *ngIf="logoRequest.logoFile">
			<div class="col-10 offset-sm-2">
				<img [src]="'data:image/png;base64,' + logoRequest.logoFile" alt="logo cargado" class="img-thumbnail" />
			</div>
		</div>
		<div class="form-group position-relative mx-auto">
			<div class="row">
				<label for="" class="col-2 label-control">Logo</label>
				<div class="col-10">
					<ngx-dropzone class="mx-auto" (change)="onSelect($event)" maxFileSize="2097152" accept="image/jpg,image/jpeg,image/png" multiple="false">
						<ngx-dropzone-label class="px-5">
							<i class="flaticon-upload"></i>
							<p>Arrastra o selecciona un archivo con la imagen del logo a utilizar</p>

							<p>El formato del archivo debe ser .png o .jpg</p>
						</ngx-dropzone-label>
						<!-- <ngx-dropzone-label class="d-inline">
                                   
                                </ngx-dropzone-label> -->
						<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
							<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
						</ngx-dropzone-preview>
					</ngx-dropzone>

					<div *ngIf="logoFormRef.submitted && (emisorLogoFormGroup.get('logoFile').invalid || emisorLogoFormGroup.get('logoFile').dirty)" class="invalid-feedback">
						<div *ngIf="emisorLogoFormGroup.get('logoFile').errors?.required">Campo requerido</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group position-relative mx-auto regla-separadora-top">
			<span class="text-danger offset-sm-2" *ngIf="plantillasPdfDisponibles.length == 0">No tiene plantillas de impresión disponibles para previsualizar un logo</span>
			<div class="row">
				<label for="" class="col-2 label-control">Tipo documento</label>
				<div class="col-6">
					<ng-select [items]="plantillasPdfDisponibles" formControlName="plantillaImpresionPreview" [clearable]="false" bindLabel="nombreTipoDte"></ng-select>
				</div>
				<div class="col-4">
					<button [disabled]="!plantillasPdfDisponibles || plantillasPdfDisponibles.length == 0" type="button" class="btn btn-warning" (click)="previewOfLogo()">Previsualizar en documento</button>
					<br />
					<small>Esto es solo una previsualización.</small>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="submit" id="add-contact-item" class="btn btn-success add-contact-item">
			<i class="la la-paper-plane-o d-lg-none"></i>
			<span class="d-none d-lg-block">Guardar</span>
		</button>
		<button type="button" class="btn btn-secondary ml-3" (click)="closeLogoModal()">
			<span class="d-none d-lg-block">Cerrar</span>
		</button>
	</div>
</form>
