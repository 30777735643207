import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Impuesto } from "../models/Impuesto";
import { ImpuestoFilter } from "../models/ImpuestoFilter";
import { Producto } from "../models/Producto/Producto";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { Response } from "../shared/models/Response";

@Injectable({
   providedIn: "root",
})
export class ImpuestoService {
   private REST_API_SERVER = environment.backend_server + "/impuesto";
   constructor(private http: HttpClient) {}

   getDataByPage(filter: ImpuestoFilter, page: Page): Observable<PagedResponse<Impuesto>> {
      let nombreImpuesto = filter.nombre != undefined ? filter.nombre : "";
      let impuestoActivo = filter.activo != undefined ? (filter.activo ? 1 : 0) : "";
      let impuestoEsRetencion = filter.isRetencion ? 1 : 0;
      return this.http.get<PagedResponse<Impuesto>>(this.REST_API_SERVER + "/list/filter" + "?nombre=" + nombreImpuesto + "&activo=" + impuestoActivo + "&isRetencion=" + 0 + "&pageNumber=" + page.pageNumber + "&pageSize=" + page.pageSize);
   }

   sendTaxRequest(request): Observable<Response<boolean>> {
      return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/add", request);
   }

   editTaxRequest(request: Impuesto): Observable<Response<boolean>> {
      return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/update", request);
   }

   activateTax(tax: Impuesto): Observable<Response<boolean>> {
      return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/activate/" + tax.impuestoId);
   }

   deactivateTax(tax: Impuesto): Observable<Response<boolean>> {
      return this.http.get<Response<boolean>>(this.REST_API_SERVER + "/deactivate/" + tax.impuestoId);
   }

   taxesByProduct(producto: Producto): Observable<Response<Impuesto[]>> {
      return this.http.get<Response<Impuesto[]>>(this.REST_API_SERVER + "/getTaxes/" + producto.productoId);
   }
}
