<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar
					[criterioPrincipal]="configLocal.barraBusqueda.criterioPrincipal"
					[config]="configLocal.barraBusqueda"
					(busqueda)="searchElementsByFilter($event)"
					[registroActivacion]="true"
					[productoExento]="true"
					[useAccordionMode]="true"
					[useInlineDeactivatedCheckbox]="true"
					[useInlineSearchButton]="true"
					[extraLengthGenericInput]="true"
					[filtroEmisor]="true"
					[filtroSucursal]="true"
					[listaCompletaEmisores]="emisorSource"
					[mostrarListaCompletaEmisores]="true"
					[emisorClearable]="false"
					[sucursalClearable]="false"></app-search-bar>
				<div class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Productos</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
									<button class="btn btn-success float-right mr-5 mt-5" (click)="openCreateProductModal(createProductModal)" *ngIf="permissions.getPermission('30-60-10').asignado">
										<i class="flaticon-add-circular-button"></i>
										Ingresar Producto
									</button>
								</div>
							</div>
							<!-- <div class="col-1" >
                                <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change) ="setPageSize()">
                                   <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                                </ng-select>
                           </div> -->
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi"
										(sort)="utilities.onSortTable(table, page)">
										<ngx-datatable-column name="nombre" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment text-wrap">{{ row.nombre }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="precio" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Precio</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.precio | currency : "$ " : "symbol" : "1.0-0" : "es" }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="unidadMedida" [flexGrow]="2.4" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Unidad de Medida</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.unidadMedida
														? row.unidadMedida
														: "Sin
                                                    unidad"
												}}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="codigoBarra" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Codigo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.codigoBarra }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="exento" [flexGrow]="2" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Exento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.exento ? "Exento" : "Afecto" }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="3.5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Detalles Producto" id="mantenedor-productos.component_Detalles_Producto" (click)="openDetailsProductModal(detailProductModal, row)" *ngIf="permissions.getPermission('30-60-16').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Producto" id="mantenedor-productos.component_Editar_Producto" (click)="openEditProductModal(editProductModal, row)" *ngIf="permissions.getPermission('30-60-12').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Impuestos Producto" id="mantenedor-productos.component_Impuestos_Producto" (click)="openTaxesProductModal(taxesProduct, row)" *ngIf="permissions.getPermission('30-60-12').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="hand-holding-usd" class="fa-lg text-white"> </fa-icon>
													</div>
												</a>

												<a
													*ngIf="row.activo && permissions.getPermission('30-60-20').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Producto"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar este producto?',
														confirmButtonText: 'Desactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateProduct(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="!row.activo && permissions.getPermission('30-60-20').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Reactivar Producto"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de reactivar este producto?',
														confirmButtonText: 'Reactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#1BC5BD'
													}"
													(confirm)="reactivateProduct(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Precio: {{ row.precio | currency : "$ " : "symbol" : "1.0-0" : "es" }} , Unidad de Medida: {{ row.unidadMedida ? row.unidadMedida : "Sin unidad" }} , Codigo: {{ row.codigoBarra }} , ¿Exento?: {{ row.exento ? "Exento" : "Afecto" }}</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="5.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Nombre</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombre }}</span></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Detalles Producto" id="mantenedor-productos.component_Detalles_Producto" (click)="openDetailsProductModal(detailProductModal, row)" *ngIf="permissions.getPermission('30-60-16').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Producto" id="mantenedor-productos.component_Editar_Producto" (click)="openEditProductModal(editProductModal, row)" *ngIf="permissions.getPermission('30-60-12').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Impuestos Producto" id="mantenedor-productos.component_Impuestos_Producto" (click)="openTaxesProductModal(taxesProduct, row)" *ngIf="permissions.getPermission('30-60-12').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="hand-holding-usd" class="fa-lg text-white"> </fa-icon>
													</div>
												</a>

												<a
													*ngIf="row.activo && permissions.getPermission('30-60-20').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Producto"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar este producto?',
														confirmButtonText: 'Desactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateProduct(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="!row.activo && permissions.getPermission('30-60-20').asignado"
													class="primary edit mr-1 mrless"
													ngbTooltip="Reactivar Producto"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de reactivar este producto?',
														confirmButtonText: 'Reactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#1BC5BD'
													}"
													(confirm)="reactivateProduct(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #createProductModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Ingreso de Producto</div>
				<div class="modal-wizard-desc">Ingrese los datos para un nuevo producto</div>
			</div>
		</div>
		<div class="modal-body">
			<app-product-entry (sendCloseRequestEmitter)="closeCreateModal()" [detailMode]="false" [useProductAndTaxes]="true" (sendProductRequest)="productWithTaxesRequest($event)"></app-product-entry>
		</div>
	</div>
</ng-template>

<ng-template #editProductModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Edición de Producto</div>
				<div class="modal-wizard-desc">Ingrese los datos para editar el producto seleccionado</div>
			</div>
		</div>
		<div class="modal-body">
			<app-product-entry [editMode]="true" [detailMode]="false" [productToEdit]="selectedProduct" (sendCloseRequestEmitter)="closeEditModal()" (sendProductRequest)="editProductRequest($event)"> </app-product-entry>
		</div>
	</div>
</ng-template>

<ng-template #detailProductModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Detalles de Producto</div>
				<div class="modal-wizard-desc">se muestran los datos del producto sleccionado</div>
			</div>
		</div>
	</div>
	<div class="modal-body">
		<app-product-entry [editMode]="true" [detailMode]="true" [productToEdit]="selectedProduct" (sendCloseRequestEmitter)="closeDetailsModal()"></app-product-entry>
	</div>
</ng-template>
<ng-template #taxesProduct let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Impuestos de Producto</div>
				<div class="modal-wizard-desc">se muestran el selector de impuestos disponibles y la lista de impuestos actuales</div>
			</div>
		</div>
		<div class="modal-body p-0">
			<app-product-entry [editMode]="true" [detailMode]="false" [useTaxes]="true" [productToEdit]="selectedProduct" (sendProductRequest)="updateTaxesRequest($event)" (sendCloseRequestEmitter)="closeTaxesProductModal()"></app-product-entry>
		</div>
	</div>
</ng-template>
