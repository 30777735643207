import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { Observable, Subject } from "rxjs";
import { validarRut, VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { Receptor } from "src/app/models/Receptor/Receptor";
import { ResultadoImportacion } from "src/app/models/ResultadoImportacion";
import { ClienteService } from "src/app/services/cliente.service";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { SubheaderService } from "src/app/_metronic/partials/layout";
import Swal from "sweetalert2";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "app-buscador-cliente",
	templateUrl: "./buscador-cliente.component.html",
	styleUrls: ["./buscador-cliente.component.scss"],
})
export class BuscadorClienteComponent implements OnInit {
	@ViewChild("currentSwal") public currentSwal: SwalComponent;
	@ViewChild("swalBuscandoCliente") public swalBuscandoCliente: SwalComponent;

	public currentClient: Receptor = new Receptor();
	public clientObservable: Observable<Receptor>;
	public contraparteGroupForm: FormGroup;
	public proveedorGroupForm: FormGroup;
	@Output() private clientForInvoice: EventEmitter<Receptor> = new EventEmitter<Receptor>();
	public loadingClient$: Observable<boolean>;
	public resetingDte$: Observable<boolean>;
	private clientListModal: NgbModalRef;
	private proveedorListModalRef: NgbModalRef;
	private emisorId = this.tokenStorage.getCompany();
	private searchingSubelement: boolean = false;
	private prevSearch: string;
	private invoiceType$: Observable<InvoiceType>;
	public editableFormControl: boolean = false;
	public editableFromSII: boolean = false;
	public validateRut: boolean = true;
	constructor(private facturador: FacturacionIndividualService, private ref: ChangeDetectorRef, private builder: FormBuilder, private modalService: NgbModal, private tokenStorage: TokenStorageService, private localStorage: LocalStorageService, private clientService: ClienteService, private subheader: SubheaderService) {
		this.setClientForm();
		this.loadingClient$ = this.facturador.isLoadingClientObservable;
		this.resetingDte$ = this.facturador.submittedDteObservable;
		this.invoiceType$ = this.facturador.currentInvoiceTypeObservable;
	}

	ngOnInit(): void {
		this.currentClient = new Receptor();
		const resetDteSubscription = this.resetingDte$.subscribe((res) => {
			if (res) {
				this.currentClient = new Receptor();
			}
		});
		this.invoiceType$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			this.editableFormControl = false;
			this.contraparteGroupForm.get("comuna").disable();
				this.contraparteGroupForm.get("ciudad").disable();
				this.contraparteGroupForm.get("giro").disable();
			this.ref.detectChanges();
		});
	}

	private destroy$ = new Subject<void>();
	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	buscaCliente(rut: string) {
		if (this.searchingSubelement || this.prevSearch == rut) {
			return;
		} else {
			if (rut != null && rut != "" && !this.searchingSubelement && validarRut(rut)) {
				this.currentClient.rutString = this.formatToRut(rut);
				this.contraparteGroupForm.get("rutBusqueda").patchValue(this.currentClient.rutString);
				this.prevSearch = rut;
			}
			if (rut != null && rut != "" && this.contraparteGroupForm.valid && !this.searchingSubelement && rut.length > 7) {
				this.searchingSubelement = true;
				this.prevSearch = rut;
			
					this.facturador.searchClient(rut, this.emisorId).subscribe(
						(result) => {
							this.ref.detectChanges();
							if (result.data.length == 0) {
								// Crear un Subject que emitirá un valor cuando se cancele SweetAlert
								let cancelSubject = new Subject();
								Swal.fire({
									iconHtml: '<img src="./assets/deployAssets/icons/loader_16x16.gif">',
									title: "Buscando en el SII",
									showConfirmButton: false,
									showCancelButton: true,
									cancelButtonText: "Cancelar",
									cancelButtonColor: "red",
									allowOutsideClick: false,
									allowEscapeKey: false,
									showDenyButton: false,
									text: "El cliente no está ingresado. Estamos buscando su información en el SII. Esto puede tardar unos segundos",
									preConfirm: () => {
										// Emitir un valor desde cancelSubject cuando se cancele SweetAlert
										cancelSubject.next();
									},
								});
								setTimeout(() => {
									if (Swal.isVisible() && Swal.getTitle().textContent == "Buscando en el SII") {
										Swal.update({
											title: "Buscando en el SII",
											text: "La búsqueda está tomando más tiempo de lo esperado. Es posible que el SII tenga alguna sobrecarga. Unos segundos más por favor",
										});
									}
								}, 30 * 1000); // 30 segundos * 1000 ms/segundo
								this.clientService
									.getFromSii(rut, this.emisorId)
									.pipe(takeUntil(cancelSubject))
									.subscribe(
										(data) => {
											Swal.close();
											if (data.status == 200) {
												this.currentClient = { ...data.data };
												this.ref.detectChanges();
												this.refreshForm(this.currentClient, this.contraparteGroupForm);
												this.refreshFormValue("rut", data.data.rutString);
												this.clientForInvoice.emit(this.currentClient);
												this.facturador.updateClient(this.currentClient.rut + "-" + this.currentClient.dv, this.currentClient);
												this.searchingSubelement = false;
												this.editableFromSII = null;
											} else {
												this.currentSwal.text = "No fue posible obtener información del SII para este RUT";
												this.currentSwal.fire();
												this.searchingSubelement = false;
											}
										},
										(error) => {
											Swal.close();
											console.log("ERROR", error);
											this.searchingSubelement = false;
											this.prevSearch = undefined;
											this.currentClient = new Receptor();
											this.refreshForm(this.currentClient, this.contraparteGroupForm);
											this.refreshFormValue("razonSocial", "");
											this.ref.detectChanges();
											Swal.fire({
												text: "El RUT " + rut + " no tiene registros en el SII. Se debe agregar manualmente",
												title: "Sin datos en el SII",
												showConfirmButton: true,
												allowOutsideClick: false,
												allowEscapeKey: false,
												showDenyButton: false,
											});
											if (cancelSubject.isStopped) {
												this.searchingSubelement = false;
												this.prevSearch = undefined;
												return;
											}

											//console.log(error);
										}
									);
								this.searchingSubelement = false;
								this.prevSearch = undefined;
								cancelSubject.complete();
							} else if (result.data[0]) {
								let newReceptor = result.data[0] as Receptor;
								this.currentClient = { ...newReceptor };
								this.ref.detectChanges();
								this.refreshForm(this.currentClient, this.contraparteGroupForm);
								this.refreshFormValue("rut", rut);
								this.clientForInvoice.emit(this.currentClient);
								this.facturador.updateClient(this.currentClient.rutString, this.currentClient);
								this.setSubheaderClientTitle(this.currentClient as Receptor);
								this.searchingSubelement = false;
							}
						},
						(error) => {
							this.searchingSubelement = false;
							this.prevSearch = undefined;
							//console.log(error);
						}
					);
				
					}
		}
	}

	setClientForm() {
		this.contraparteGroupForm = this.builder.group({
			rutBusqueda: ["", [VerificacionRutValidator]],
			razonSocial: ["", { disabled: true }],
			giro: ["", { disabled: true }],
			dirFact: ["", { disabled: true }],
			comuna: ["", { disabled: true }],
			ciudad: ["", { disabled: true }],
			correoFact: ["", { disabled: true }],
			pais: ["", { disabled: true }],
		});
	}

	refreshForm(element: any, formGroup: FormGroup) {
		formGroup.patchValue(element);
	}

	refreshFormValue(name: string, value: any) {
		this.contraparteGroupForm.patchValue({ name: value }, { onlySelf: true });
	}

	reset() {
		this.currentClient = new Receptor();
		this.contraparteGroupForm.patchValue(this.currentClient);
	}

	formatToRut(preRutString: string) {
		if (preRutString.includes("-")) {
			return preRutString;
		} else {
			let numberRut = preRutString.trim().slice(0, -1);
			let dv = preRutString.trim().substr(preRutString.length - 1);
			if (isNaN(+numberRut) && (isNaN(+dv) || dv.toLowerCase() != "k")) {
				return preRutString;
			} else {
				return numberRut + "-" + dv;
			}
		}
	}
	inputBlurPlaceholder(event) {} //used in keyup as placeholder for allow the blur in the input with the enter button

	openClientListModal(modalRef) {
		this.clientListModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	openProveedorListModal(modalRef) {
		this.proveedorListModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	selectedReceptor(event) {
		//console.log("received recetori",event)
		this.currentClient = { ...event } as Receptor;
		this.currentClient = this.currentClient as Receptor;
		this.ref.detectChanges();
		this.refreshForm(this.currentClient, this.contraparteGroupForm);
		this.refreshFormValue("rutBusqueda", this.currentClient.rutString);
		this.contraparteGroupForm.patchValue({
			rutBusqueda: this.currentClient.rutString,
		});
		this.ref.detectChanges();
		this.clientForInvoice.emit(this.currentClient);
		this.setSubheaderClientTitle(this.currentClient as Receptor);
		this.facturador.updateClient(this.currentClient.rutString, this.currentClient);
		this.clientListModal.close();
	}

	deactivateValidator(formGroup: FormGroup, controlName: string) {
		formGroup.controls[controlName].clearValidators();
	}

	setSubheaderClientTitle(receptor) {
		let titleMessage = "";
		// if (receptor.rutString) {
		//    titleMessage = "Cliente: " + receptor.razonSocial + "  Rut: " + receptor.rutString;
		// }
		// if (receptor.rutProveedor) {
		//    titleMessage = "Proveedor: " + receptor.razonSocial + "  Rut: " + receptor.rutProveedor;
		// }
		// if (receptor.nombrePais) {
		//    titleMessage = "Cliente: " + receptor.razonSocial + "  Pais: " + receptor.nombrePais;
		// }
		this.subheader.setTitle(titleMessage);
	}

	changeCorreoFact(correo) {
		this.currentClient.correoFact = correo;
	}
	changeDireccion(direccion) {
		this.currentClient.dirFact = direccion;
	}

	changeCiudad(ciudad) {
		this.currentClient.dirFact = ciudad;
	}

	changeComuna(comuna) {
		this.currentClient.dirFact = comuna;
	}
	changeGiro(giro) {
		this.currentClient.giro = giro;
	}
}
