import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { AbstractControl, Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { DetalleDte } from "../../../models/Dte/DetalleDte";
import { DteEmitido } from "../../../models/Dte/DteEmitido";
import { TotalDte } from "../../../models/Dte/TotalDte";
import { NotaCredito } from "../../../models/NotaCredito";
import { TokenStorageService } from "../../../services/token-storage.service";
import { SessionUser } from "../../../shared/models/sessionUser";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { Receptor } from "../../../models/Receptor/Receptor";
import { environment } from "src/environments/environment";
import { PermissionService } from "src/app/services/permission.service";
import { Permission } from "src/app/shared/models/Permission";
import { NotaDebito } from "src/app/models/NotaDebito";
import { typeOfNullifyCN, typeOfNullifyCNForDN, typeOfNullifyCNForDteExport, typeOfNullifyDNForCN, typeOfNullifyDNForDteExport, typeOfNullifyDNInterests } from "src/app/models/Dte/TypeOfNullify.enum";
type FormGroupAttach = [string, FormGroup];

@Component({
	selector: "app-anulador-dte",
	templateUrl: "./anulador-dte.component.html",
	styleUrls: ["./anulador-dte.component.scss"],
})
export class AnuladorDteComponent implements OnInit {
	@ViewChild("anuladorSwal") private anuladorSwal: SwalComponent;
	@Input() public receivedDte: DteEmitido;
	@Input() public notaCredito: boolean;
	@Input() public dteReferences: DteEmitido[];
	@Input() public DebitoMode: boolean = false;
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	@Output() private newCreditNoteRequest: EventEmitter<any> = new EventEmitter<any>();
	@Output() private newDebitNoteRequest: EventEmitter<any> = new EventEmitter<any>();
	@Output() private typeOfNullfySelected: EventEmitter<any> = new EventEmitter<any>();
	public dteNullifyForm: FormGroup;
	public dteDefaultNullForm: FormGroup;
	public dteAmountNullForm: FormGroup;
	public dteTextNullForm: FormGroup;
	public dteInterestsForm: FormGroup;

	public showInterestForm = false;
	public showNullifyDebitForm = false;
	public typeOfNullify = typeOfNullifyCN;
	public typeOfNullifyDNForCN = typeOfNullifyDNForCN;
	public typeOfNullifyDNInterests = typeOfNullifyDNInterests;
	public typeOfNullifyCNForDN = typeOfNullifyCNForDN;
	public typeOfNullifyCNForDteExport = typeOfNullifyCNForDteExport;
	public typeOfNullifyDNForDteExport = typeOfNullifyDNForDteExport;

	public typeOfNullifyValues: any;
	public typeOfNullifySelected = 0;

	public typeOfNullifyDNForCNValues: any;
	public typeOfNullifyDNForCNSelected = 0;

	public typeOfNullifyDNInterestsValues: any;
	public typeOfNullifyDNInterestsSelected = 0;

	public newValueInterests: number = 0;
	public newIvaInterests: number = 0;
	public newTotalInterests: number = 0;
	public motivos = ["Error de digitación", "Reclamo de Cliente", "Otros"];
	public interestsMotive = ["Intereses por mora", "Intereses por cambio de fecha", "Otros"];
	public motivosCorrigeTextos = ["Error de digitación", "Datos desactualizados", "Otros"];
	public configScroll: PerfectScrollbarConfigInterface = {};
	private dteReferenciasDescuentosCorrecciones: DteEmitido[] = [];
	public totalDescontadoCorrecciones: number = 0;
	public totalesNota: TotalDte = new TotalDte();
	public noteToSend = new NotaCredito();
	public debitNoteToSend = new NotaDebito();
	public receivedReceptor: Receptor = new Receptor();
	public editingReceptor: Receptor = new Receptor();
	editing = {};
	public rowsReceivedDte: DetalleDte[] = [];
	public rowsDetalles: any[] = [];
	public arrayPermissions: Permission[] = [this.permissions.getPermission("40-18-30-10"), this.permissions.getPermission("40-18-30-12"), this.permissions.getPermission("40-18-30-14")];
	public arrayCreditoPermission: Permission[] = [this.permissions.getPermission("40-18-30-10"), this.permissions.getPermission("40-18-30-12"), this.permissions.getPermission("40-18-30-14")];
	public arrayDebitoPermissions: Permission[] = [this.permissions.getPermission("40-18-31-10"), this.permissions.getPermission("40-18-31-12"), this.permissions.getPermission("40-18-31-14")];
	private isGrossValue: boolean = false;
	constructor(private formBuilder: FormBuilder, private tokenContainer: TokenStorageService, public permissions: PermissionService) {}

	ngOnInit(): void {
		this.isGrossValue = this.checkIfDteIsGrossValue();
		this.arrayPermissions = this.DebitoMode ? this.arrayDebitoPermissions : this.arrayCreditoPermission;
		this.receivedReceptor = { ...this.receivedDte.receptorAsociado };
		this.editingReceptor = { ...this.receivedReceptor };
		this.createNullifyForm();
		this.rowsReceivedDte = this.receivedDte.detalleDte;
		this.checkDetailsValueByDteType();

		if (this.receivedDte.notaDebitoHabilitada && this.DebitoMode) {
			this.debitNoteToSend.usuarioId = (this.tokenContainer.getUser() as SessionUser).id;
			if (this.receivedDte.codigoTipoDte == 61) {
				this.showNullifyDebitForm = true;
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.debitNoteToSend);
				this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
				this.typeOfNullifyDNForCNValues = Object.values(this.typeOfNullifyDNForCN).filter((enumVal) => typeof enumVal == "number");
				this.dteNullifyForm.get("radioNullType").valueChanges.subscribe((currentRadioVal) => {
					switch (this.dteNullifyForm.get("radioNullType").value) {
						case 0:
							this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["defaultForm", this.dteDefaultNullForm]]);
							this.resetArrayDetalles();
							break;
						default:
							break;
					}
				});
			} else if (this.receivedDte.codigoTipoDte == 112) {
				this.showNullifyDebitForm = true;
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.debitNoteToSend);
				this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
				this.typeOfNullifyDNForCNValues = Object.values(this.typeOfNullifyDNForDteExport).filter((enumVal) => typeof enumVal == "number");
				this.dteNullifyForm.get("radioNullType").valueChanges.subscribe((currentRadioVal) => {
					switch (this.dteNullifyForm.get("radioNullType").value) {
						case 0:
							this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["defaultForm", this.dteDefaultNullForm]]);
							this.resetArrayDetalles();
							break;
						default:
							break;
					}
				});
			} else if (this.receivedDte.codigoTipoDte == 33 || this.receivedDte.codigoTipoDte == 34 || this.receivedDte.codigoTipoDte == 39 || this.receivedDte.codigoTipoDte == 41) {
				this.showInterestForm = true;
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.debitNoteToSend);
				this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "defaultForm"]);
				this.typeOfNullifyDNInterestsValues = Object.values(this.typeOfNullifyDNInterests).filter((enumVal) => typeof enumVal == "number");
				this.dteNullifyForm.get("radioNullType").valueChanges.subscribe((currentRadioVal) => {
					switch (this.dteNullifyForm.get("radioNullType").value) {
						case 0:
							this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "defaultForm"]);
							this.attachControls(this.dteNullifyForm, [["interestsForm", this.dteInterestsForm]]);
							this.resetArrayDetalles();
							break;
						default:
							break;
					}
				});
			}
		}

		if (this.receivedDte.notaCreditoHabilitada && !this.DebitoMode) {
			this.noteToSend.usuarioId = (this.tokenContainer.getUser() as SessionUser).id;
			if (this.receivedDte.codigoTipoDte != 56 && this.receivedDte.codigoTipoDte != 110) {
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
				this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
				this.typeOfNullifyValues = Object.values(this.typeOfNullify).filter((enumVal) => typeof enumVal == "number");
				if (this.receivedDte.codigoTipoDte == 39) {
					this.typeOfNullifyValues = [0];
				}
				this.dteNullifyForm.get("radioNullType").valueChanges.subscribe((currentRadioVal) => {
					switch (this.dteNullifyForm.get("radioNullType").value) {
						case 0:
							this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["defaultForm", this.dteDefaultNullForm]]);
							this.resetArrayDetalles();
							break;
						case 1:
							this.detachControls(this.dteNullifyForm, ["defaultForm", "radioTextGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["amountCorrectionGroup", this.dteAmountNullForm]]);
							this.resetArrayDetalles();
							break;
						case 2:
							this.detachControls(this.dteNullifyForm, ["defaultForm", "amountCorrectionGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["radioTextGroup", this.dteTextNullForm]]);
							this.resetArrayDetalles();
							break;
						default:
							break;
					}
				});
			} else if (this.receivedDte.codigoTipoDte == 56) {
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
				this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
				this.typeOfNullifyValues = Object.values(this.typeOfNullifyCNForDN).filter((enumVal) => typeof enumVal == "number");
				this.dteNullifyForm.get("radioNullType").valueChanges.subscribe((currentRadioVal) => {
					switch (this.dteNullifyForm.get("radioNullType").value) {
						case 0:
							this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["defaultForm", this.dteDefaultNullForm]]);
							this.resetArrayDetalles();
							break;
					}
				});
			} else if (this.receivedDte.codigoTipoDte == 110) {
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
				this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
				this.typeOfNullifyValues = Object.values(this.typeOfNullifyCNForDteExport).filter((enumVal) => typeof enumVal == "number");
				this.dteNullifyForm.get("radioNullType").valueChanges.subscribe((currentRadioVal) => {
					switch (this.dteNullifyForm.get("radioNullType").value) {
						case 0:
							this.detachControls(this.dteNullifyForm, ["amountCorrectionGroup", "radioTextGroup", "interestsForm"]);
							this.attachControls(this.dteNullifyForm, [["defaultForm", this.dteDefaultNullForm]]);
							this.resetArrayDetalles();
							break;
					}
				});
			}
		}

		//console.log(this.rowsReceivedDte,this.receivedDte);

		this.totalDescontadoCorrecciones = this.getDteSumByProperty(this.dteReferences, 61, "total");
	}
	createNullifyForm() {
		this.dteAmountNullForm = this.formBuilder.group({
			arrayDetalles: [[]],
			comentarioDetalles: [, [Validators.required]],
		});

		this.dteTextNullForm = this.formBuilder.group({
			comentarioTextCorrection: ["", [Validators.required]],
			motivoCorrigeTexto: [, [Validators.required]],
			rutReceptor: [this.receivedDte.rutReceptor],
			razonSocialReceptor: [this.receivedDte.razonSocial],
			giroReceptor: [this.editingReceptor.giro], //giro a editar
			direccionFacturacion: [this.editingReceptor.dirFact], //direccion a editar
			comunaReceptor: [this.editingReceptor.comuna], //direccion a editar
			ciudadReceptor: [this.editingReceptor.ciudad], //direccion a editar
		});
		this.disableControls([this.dteTextNullForm.controls.rutReceptor, this.dteTextNullForm.controls.razonSocialReceptor]);
		this.dteDefaultNullForm = this.formBuilder.group({
			motivo: [, [Validators.required]],
			comentario: [, [Validators.required]],
		});
		this.dteInterestsForm = this.formBuilder.group({
			motiveInterests: [, [Validators.required]],
			commentInterests: [, [Validators.required]],
			valueInterests: [, [Validators.required, Validators.max(this.receivedDte.exento > 0 ? this.receivedDte.exento : this.receivedDte.neto), Validators.min(1)]],
			ivaInterests: [,],
			exemptInterests: [,],
			totalInterests: [,],
		});

		this.dteNullifyForm = this.formBuilder.group({
			radioNullType: [this.arrayPermissions[0].asignado ? 0 : this.arrayPermissions[1].asignado ? 1 : this.arrayPermissions[2].asignado ? 2 : null],
			defaultForm: this.dteDefaultNullForm,
			amountCorrectionGroup: this.dteAmountNullForm,
			radioTextGroup: this.dteTextNullForm,
			interestsForm: this.dteInterestsForm,
		});
		this.sendNullType();
	}
	detachControls(parentFormGroup: FormGroup, childrenFormGroupToDetach: string[]) {
		childrenFormGroupToDetach.forEach((fg) => {
			parentFormGroup.removeControl(fg);
		});
	}
	attachControls(parentFormGroup: FormGroup, childrenFormGroupToAttach: FormGroupAttach[]) {
		childrenFormGroupToAttach.forEach((st) => {
			parentFormGroup.addControl(st[0], st[1]);
		});
	}
	disableControls(controls: AbstractControl[]) {
		//disable elements in form
		controls.forEach((control) => {
			control.disable();
		});
	}
	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}
	updateValue(event, cell, rowIndex) {
		this.editing[rowIndex + "-" + cell] = false;
		if (cell === "esExento") {
			this.rowsDetalles[rowIndex][cell] = event.target.checked;
		} else if (cell === "cantidad" || cell === "precioUnitario") {
			let valueToUpdate = event.target.value;
			if (valueToUpdate < 0) {
				this.anuladorSwal.icon = "error";
				this.anuladorSwal.title = "Error en valor";
				this.anuladorSwal.text = "Ingresar solamente valores positivos";
				this.anuladorSwal.fire();
				return;
			} else {
				let roundedValue = Math.round(event.target.value * 1e6) / 1e6;
				this.rowsDetalles[rowIndex][cell] = roundedValue;
			}
		}

		this.rowsDetalles = [...this.rowsDetalles];
		this.updateRelatedValue(rowIndex, cell, "subtotal");
	}

	updateRelatedValue(currentRow: number, currentCell: string, cellToUpdate: string) {
		if (currentCell == "cantidad" || currentCell == "precioUnitario" || currentCell == "esExento") {
			//update last element
			let currentPrecio = this.rowsDetalles[currentRow]["precioUnitario"];
			let currentCantidad = this.rowsDetalles[currentRow]["cantidad"];
			if (currentPrecio < 0 || currentCantidad < 0) {
				this.anuladorSwal.icon = "error";
				this.anuladorSwal.title = "Error en valor";
				this.anuladorSwal.text = "Ingresar solamente valores positivos";
				this.anuladorSwal.fire();
				return;
			}
			this.rowsDetalles[currentRow][cellToUpdate] = Math.round(parseFloat(currentPrecio) * parseFloat(currentCantidad));
			//console.log(Math.round(parseFloat(currentPrecio)*parseFloat(currentCantidad)))
			this.rowsDetalles[currentRow]["total"] = Math.round(parseFloat(currentPrecio) * parseFloat(currentCantidad));
			this.rowsDetalles[currentRow]["precioUnitario"] = Number(parseFloat(currentPrecio));
			this.rowsDetalles[currentRow]["cantidad"] = Number(parseFloat(currentCantidad));
			this.rowsDetalles = [...this.rowsDetalles];
			this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
		}
	}

	onSubmit() {
		if (this.dteNullifyForm.invalid) {
			return;
		}
		if (this.dteNullifyForm.valid) {
			this.noteToSend.dteReferencia = this.receivedDte;
			this.noteToSend.dteReferenciaId = this.receivedDte.dteId;
			this.debitNoteToSend.dteReferencia = this.receivedDte;
			this.debitNoteToSend.dteReferenciaId = this.receivedDte.dteId;
			//check if form contains new dteDetails by current radioNullType
			if (this.dteNullifyForm.get("amountCorrectionGroup")) {
				this.dteNullifyForm.controls.amountCorrectionGroup.patchValue({
					arrayDetalles: this.rowsDetalles,
				});
				//check if details data is unchanged?

				//recalculate new totals
				this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
				//set detalles to Request
				this.noteToSend.itemsNota = this.rowsDetalles;
				this.noteToSend.comentario = this.dteNullifyForm.controls.amountCorrectionGroup.get("comentarioDetalles").value;
				this.noteToSend.motivoString = "otros";
				if (this.noteToSend.neto <= 5) {
					this.anuladorSwal.icon = "error";
					this.anuladorSwal.title = "Monto mínimo";
					this.anuladorSwal.text = "El monto del documento a generar debe ser mayor a 5";
					this.anuladorSwal.fire();
					return;
				}
				if (!this.amountValid()) {
					this.anuladorSwal.icon = "error";
					this.anuladorSwal.title = "Monto erroneo";
					this.anuladorSwal.text = "El monto del documento no puede ser mayor al monto total del documento en referencia";
					this.anuladorSwal.fire();
					return;
				} else if (!this.amountValidByDteHistory(this.noteToSend.total, this.totalDescontadoCorrecciones, this.receivedDte.total)) {
					this.anuladorSwal.icon = "error";
					this.anuladorSwal.title = "Monto erroneo";
					this.anuladorSwal.text = "El monto del documento actual no puede superar al monto total disponible para corregir el documento en referencia";
					this.anuladorSwal.fire();
					return;
				}
			} else if (this.dteNullifyForm.get("radioTextGroup")) {
				this.noteToSend.comentarioCorreccionTexto = this.dteNullifyForm.controls.radioTextGroup.get("comentarioTextCorrection").value;
				this.compareStringConcatenateRequest(this.dteNullifyForm.controls.radioTextGroup.get("giroReceptor"), this.noteToSend, this.receivedReceptor.giro, "Giro");
				this.compareStringConcatenateRequest(this.dteNullifyForm.controls.radioTextGroup.get("direccionFacturacion"), this.noteToSend, this.receivedReceptor.dirFact, "Dirección Facturacion");
				this.compareStringConcatenateRequest(this.dteNullifyForm.controls.radioTextGroup.get("comunaReceptor"), this.noteToSend, this.receivedReceptor.comuna, "Comuna");
				this.compareStringConcatenateRequest(this.dteNullifyForm.controls.radioTextGroup.get("ciudadReceptor"), this.noteToSend, this.receivedReceptor.ciudad, "Ciudad"); //para concatenar la soliciutd de anulación por correccion texto
				this.noteToSend.motivoString = this.dteNullifyForm.controls.radioTextGroup.get("motivoCorrigeTexto").value;
			} else if (this.dteNullifyForm.get("interestsForm")) {
				this.debitNoteToSend.comentario = this.dteNullifyForm.controls.interestsForm.get("commentInterests").value;
				this.debitNoteToSend.motivoString = this.dteNullifyForm.controls.interestsForm.get("motiveInterests").value;
				this.debitNoteToSend.neto = this.dteNullifyForm.controls.interestsForm.get("valueInterests").value;
				this.debitNoteToSend.iva = this.dteNullifyForm.controls.interestsForm.get("ivaInterests").value;
				this.debitNoteToSend.exento = this.dteNullifyForm.controls.interestsForm.get("valueInterests").value;
				if (this.debitNoteToSend.iva > 0) this.debitNoteToSend.exento = 0;
				this.debitNoteToSend.total = this.dteNullifyForm.controls.interestsForm.get("totalInterests").value;
			} else if (this.dteNullifyForm.get("defaultForm")) {
				this.noteToSend.comentario = this.dteNullifyForm.controls.defaultForm.get("comentario").value;
				this.noteToSend.motivoString = this.dteNullifyForm.controls.defaultForm.get("motivo").value;
				this.noteToSend.itemsNota = this.rowsDetalles;
				this.debitNoteToSend.comentario = this.dteNullifyForm.controls.defaultForm.get("comentario").value;
				this.debitNoteToSend.motivoString = this.dteNullifyForm.controls.defaultForm.get("motivo").value;
				this.debitNoteToSend.itemsNota = this.rowsDetalles;
			}
			this.typeOfNullifySelected = this.dteNullifyForm.get("radioNullType").value;
			this.typeOfNullifyDNForCNSelected = this.dteNullifyForm.get("radioNullType").value;
			this.typeOfNullifyDNInterestsSelected = this.dteNullifyForm.get("radioNullType").value;
			// this.newCreditNoteRequest.emit(this.dteNullifyForm);
			this.noteToSend.tipoNota = this.dteNullifyForm.get("radioNullType").value;
			this.debitNoteToSend.tipoNota = this.dteNullifyForm.get("radioNullType").value;
			//console.log(this.noteToSend,this.receivedDte,this.DebitoMode);
			if (this.receivedDte.notaDebitoHabilitada) this.newDebitNoteRequest.emit(this.debitNoteToSend);
			if (this.receivedDte.notaCreditoHabilitada) this.newCreditNoteRequest.emit(this.noteToSend);
		}
	}

	f() {
		return this.dteNullifyForm.controls;
	}

	calculateNewTotalsOfDocument(dteDetails: DetalleDte[], notaTotal: TotalDte, notaToRecalc?: NotaCredito) {
		let groupExentoDetalles = [];
		groupExentoDetalles = dteDetails.filter((detail) => detail.esExento == true || detail.esExento == undefined);
		let groupAfectoDetalles = [];
		groupAfectoDetalles = dteDetails.filter((detail) => detail.esExento == false);
		let totalExento: number = 0;
		let netoAfecto: number = 0;

		if (groupExentoDetalles) {
			groupExentoDetalles.forEach((detail) => {
				totalExento += Math.round(Number(parseFloat(detail.cantidad.toString()) * parseFloat(detail.precioUnitario.toString())));
				notaTotal.exento = totalExento;
			});
		}
		if (groupAfectoDetalles) {
			groupAfectoDetalles.forEach((detail) => {
				netoAfecto += Number(parseFloat(detail.cantidad.toString()) * parseFloat(detail.precioUnitario.toString()));
			});
			notaTotal.afecto = netoAfecto;
			notaTotal.neto = netoAfecto;
		}
		notaTotal.subTotal = Number(totalExento + netoAfecto);

		let Iva: number = Math.round(Math.round(netoAfecto) * environment.taxPercentage);
		notaTotal.iva = Iva;
		let totalAfecto: number = Number(netoAfecto + Iva);
		notaToRecalc.neto = Number(totalExento + netoAfecto);
		notaToRecalc.iva = Iva;
		notaToRecalc.total = Number(totalExento + totalAfecto);
		notaTotal.total = notaToRecalc.total;
	}
	amountValid(): boolean {
		//valida que el total de la nota no sea mayor que el total del documento en referencia recibido (receivedDte)
		return this.noteToSend.total <= this.receivedDte.total && this.noteToSend.total > 0;
	}
	deleteDetailElement(row) {
		let elementToDelete = this.rowsDetalles.find((detailElement) => detailElement.detalleDteId == row.detalleDteId);
		if (elementToDelete) {
			let itemIndexToDelete = this.rowsDetalles.indexOf(elementToDelete);
			this.rowsDetalles.splice(itemIndexToDelete, 1);
			this.rowsDetalles = [...this.rowsDetalles];
			this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
		}
	}
	passElementToNote(row) {
		let localRow = { ...row };
		let resultFindRow = this.rowsDetalles.find((detailElement) => detailElement.detalleDteId == row.detalleDteId);
		if (!resultFindRow) {
			this.rowsDetalles.push(localRow);
			this.rowsDetalles = [...this.rowsDetalles];
			this.calculateNewTotalsOfDocument(this.rowsDetalles, this.totalesNota, this.noteToSend);
		}
	}

	compareStringConcatenateRequest(controlToCheck: AbstractControl, requestObject: NotaCredito, atributoComparado: string, nombreCampo: string) {
		let detalleTexto: DetalleDte = new DetalleDte();
		if (!requestObject.itemsNota) {
			requestObject.itemsNota = [];
		}
		if (atributoComparado != controlToCheck.value) {
			detalleTexto.descripcion = 'En el campo "' + nombreCampo + '" donde dice "' + atributoComparado + '" debe decir "' + controlToCheck.value + '"';
			var exist = requestObject.itemsNota.filter((x) => x.descripcion == detalleTexto.descripcion);
			if (exist.length == 0) requestObject.itemsNota.push(detalleTexto);
		}
	}

	getDteSumByProperty(arrayDte: DteEmitido[], codigoTipoDte: number, propertyToSum: string) {
		let sum: number = 0;
		if (arrayDte) {
			arrayDte.forEach((dte) => {
				if (dte.codigoTipoDte == codigoTipoDte && !dte.anulado) {
					sum += dte[propertyToSum];
				}
			});
		}
		return sum;
	}

	amountValidByDteHistory(currentAmount, historyAmount, originalAmount): boolean {
		//valida que el monto de la nota actual no sobrepase el total del dte original, considerando los montos de notas previas vigentes(no nulos)
		return currentAmount <= originalAmount - historyAmount;
	}
	resetArrayDetalles() {
		this.rowsDetalles = [];
	}

	checkDetailsValueByDteType() {
		if (this.receivedDte.codigoTipoDte == 39 || this.receivedDte.codigoTipoDte == 41 || this.receivedDte.codigoTipoDte == 33) {
			this.rowsReceivedDte.forEach((detail) => {
				detail.precioUnitario = this.isGrossValue ? Math.round(detail.precioUnitario / (1 + environment.taxPercentage)) : +detail.precioUnitario.toFixed(2);
				let detailWithDiscount = detail.descuento ? detail.precioUnitario * detail.cantidad - detail.descuento : detail.precioUnitario * detail.cantidad;
				detail.subtotal = detail.recargo ? detail.precioUnitario * detail.cantidad + detail.recargo : detailWithDiscount;
				detail.total = detail.subtotal;
			});
		}
		if (this.receivedDte.notaCreditoHabilitada && !this.DebitoMode) {
			if (this.receivedDte.codigoTipoDte != 56 && this.receivedDte.codigoTipoDte != 110) this.typeOfNullifyValues = Object.values(this.typeOfNullify).filter((enumVal) => typeof enumVal == "number");
			if (this.receivedDte.codigoTipoDte == 56) this.typeOfNullifyValues = Object.values(this.typeOfNullifyCNForDN).filter((enumVal) => typeof enumVal == "number");
			if (this.receivedDte.codigoTipoDte == 43) this.typeOfNullifyValues = Object.values(this.typeOfNullifyCNForDteExport).filter((enumVal) => typeof enumVal == "number");
		}
		if (this.receivedDte.notaDebitoHabilitada && this.DebitoMode) {
			if (this.receivedDte.codigoTipoDte == 61) this.typeOfNullifyDNForCNValues = Object.values(this.typeOfNullifyDNForCN).filter((enumVal) => typeof enumVal == "number");
			if (this.receivedDte.codigoTipoDte == 112) this.typeOfNullifyDNForCNValues = Object.values(this.typeOfNullifyDNForDteExport).filter((enumVal) => typeof enumVal == "number");
			if (this.receivedDte.codigoTipoDte == 33 || this.receivedDte.codigoTipoDte == 33 || this.receivedDte.codigoTipoDte == 39 || this.receivedDte.codigoTipoDte == 41) this.typeOfNullifyDNInterestsValues = Object.values(this.typeOfNullifyDNInterests).filter((enumVal) => typeof enumVal == "number");
		}
	}
	updateValueInterest(value: number) {
		this.newValueInterests = value;
		if (this.receivedDte.codigoTipoDte == 41 || this.receivedDte.codigoTipoDte == 34) {
			if (this.newValueInterests <= this.receivedDte.exento) Number((this.newTotalInterests = this.newValueInterests).toFixed(2));
			else {
				this.anuladorSwal.icon = "info";
				this.anuladorSwal.title = "Corregir Valor";
				this.anuladorSwal.text = "No se puede agregar más del 100% del valor exento como interés ";
				this.anuladorSwal.fire();
			}
		} else {
			if (this.newValueInterests <= this.receivedDte.neto) {
				this.newIvaInterests = Number((this.newValueInterests * 0.19).toFixed(2));
				this.newTotalInterests = Number((Number(this.newIvaInterests) + Number(this.newValueInterests)).toFixed(2));
			} else {
				this.anuladorSwal.icon = "info";
				this.anuladorSwal.title = "Corregir Valor";
				this.anuladorSwal.text = "No se puede agregar más del 100% del valor neto como interés ";
				this.anuladorSwal.fire();
			}
		}
	}

	checkIfDteIsGrossValue() {
		let totalByProduct = this.receivedDte.detalleDte.reduce((sum, detalle) => (sum += Math.round(detalle.cantidad * detalle.precioUnitario)), 0);
		return this.receivedDte.total == totalByProduct && this.receivedDte.exento != totalByProduct;
	}
	sendNullType() {
		this.typeOfNullfySelected.emit(this.typeOfNullify[this.dteNullifyForm.controls.radioNullType.value]);
	}
}
