import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Response } from "src/app/shared/models/Response";
import { ImportacionMasiva } from "../models/MassInvoicing/ImportacionMasiva";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";
import { ImportacionFilter } from "../models/MassInvoicing/ImportacionFilter";

@Injectable({
	providedIn: "root",
})
export class FacturadorMasivoService {
	private REST_API_SERVER = environment.backend_server + "/facturacionmasiva";
	constructor(private http: HttpClient) {}

	public getData(emisorId: string): Observable<Response<ImportacionMasiva[]>> {
		return this.http.get<Response<ImportacionMasiva[]>>(this.REST_API_SERVER + "/importacion/list?emisorId=" + emisorId);
	}

	public getDataByFilter(emisorId: string, filterSearch): Observable<Response<ImportacionMasiva[]>> {
		return this.http.get<Response<ImportacionMasiva[]>>(this.REST_API_SERVER + "/list?emisorId=" + emisorId + "&field=" + filterSearch.criterio + "&value=" + filterSearch.busqueda);
	}

	public getDataByPage(filter: ImportacionFilter, page: Page): Observable<PagedResponse<ImportacionMasiva>> {
		return this.http.get<PagedResponse<ImportacionMasiva>>(this.REST_API_SERVER + "/importacion/list?emisorId=" + filter.emisorId + "&sucursalId=" + filter.sucursalId + "&fechaDesde=" + (filter.fechaDesde != undefined ? filter.fechaDesde : "") + "&fechaHasta=" + (filter.fechaHasta != undefined ? filter.fechaHasta : "") + "&origenImportacion=" + filter.origen + "&PageNumber=" + page.pageNumber + "&PageSize=" + page.pageSize);
	}

	public add(dteFileString, file): Observable<Response<ImportacionMasiva>> {
		let formData = new FormData();
		formData.append("solicitudString", JSON.stringify(dteFileString));
		formData.append("file", file);
		return this.http.post<Response<ImportacionMasiva>>(this.REST_API_SERVER + "/importacion/facturarcsv", formData);
	}

	public addExportRequest(dteFileString, file): Observable<Response<ImportacionMasiva>> {
		let formData = new FormData();
		formData.append("solicitudString", JSON.stringify(dteFileString));
		formData.append("file", file);
		return this.http.post<Response<ImportacionMasiva>>(this.REST_API_SERVER + "/importacion/facturarcsvexporta", formData);
	}

	public getFile(fileId: string): Observable<Response<any>> {
		return this.http.get<Response<any>>(this.REST_API_SERVER + "/importacion/archivo?fileId=" + fileId);
	}

	public getDetail(fileId: string): Observable<Response<string>> {
		return this.http.get<Response<string>>(this.REST_API_SERVER + "/importacion/reporte?importacionMasivaId=" + fileId);
	}

	public downloadSample(tipo: number): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				Accept: "text/html, application/xhtml+xml, */*",
				"Content-Type": "text/plain; charset=utf-8",
			}),
			responseType: "blob" as "json",
		};
		return this.http.get(this.REST_API_SERVER + "/sample?formato=" + tipo, options);
	}

	public validateHeaders(typeString: string, file: any): Observable<Response<boolean>> {
		let formData = new FormData();
		formData.append("tipo", typeString);
		formData.append("file", file);
		return this.http.post<Response<boolean>>(this.REST_API_SERVER + "/importacion/validarheaders", formData);
	}

	getPdfGlossary(): Observable<Response<string>> {
		return this.http.get<Response<string>>(this.REST_API_SERVER + "/getPdfGlossary");
	}

	getPdfInstructions(): Observable<Response<string>> {
		return this.http.get<Response<string>>(this.REST_API_SERVER + "/getPdfInstructions");
	}
}
