<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar [config]="configLocal.barraBusqueda" [registroActivacion]="true" [filtroTipoEnvio]="true" [filtroTipoPlantillaEnvio]="true" [useAccordionMode]="true" [useInlineSearchButton]="true" [useInlineDeactivatedCheckbox]="true" (busqueda)="searchElementsByFilter($event)"></app-search-bar>
				<div class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Mantenedor Correo de Envio</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" ngbTooltip="Utilice el filtro de búsqueda para acotar el rango a exportar">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>

									<button class="btn btn-warning float-right mr-5 mt-5" (click)="openExampleTemplateModal(exampleTemplateModal)">Ejemplos de plantillas SMTP</button>
								</div>
							</div>

							<div class="card-content">
								<div class="card-body">
									<ngx-datatable
										#table
										class="material mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }"
										[externalSorting]="false"
										[sortType]="SortType.multi">
										<ngx-datatable-column name="nombreTipoEnvio" [flexGrow]="1.7" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoEnvio }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="correo" [flexGrow]="5" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Correo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.correo }}</span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="servidor" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Servidor</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.servidor
														? row.servidor
														: "No
                                                    usa"
												}}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="nombreTipoPlantillaEnvio" [flexGrow]="4" [sortable]="true" [resizeable]="false" [cellClass]="'text-table-alignment'" [headerClass]="'text-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo Plantilla</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoPlantillaEnvio }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="estado" [flexGrow]="1.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Estado</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row">
												<span *ngIf="row.activo" class="success-dot" ngbTooltip="Activado"></span>
												<span *ngIf="!row.activo" class="error-dot" ngbTooltip="Desactivado"></span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Detalle Correo Envio" id="mantenedor-correo-envio.component_Detalle_Correo_Envio" (click)="openShowCorreoEnvioModal(detailCorreoEnvioModal, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Correo Envio" id="mantenedor-correo-envio.component_Editar_Correo_Envio" (click)="openEditCorreoEnvioModal(editCorreoEnvioModal, row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Plantillas" id="mantenedor-correo-envio.component_Plantillas" (click)="openTemplatesModal(templates, row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon class="fa fa-envelope"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Correo de Envio"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar este correo de envio?',
														confirmButtonText: 'Desactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateCorreoEnvio(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="!row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Activar Correo de Envio"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de activar este correo de envio?',
														confirmButtonText: 'Activar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="activateCorreoEnvio(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>

									<!-- seccion tabla mobile-->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">Tipo: {{ row.precio | currency : "$ " : "symbol" : "1.0-0" : "es" }} ,Correo: {{ row.nombreTipoEnvio }} , Correo: {{ row.correo }} , Servidor: {{ row.servidor ? row.servidor : "No usa" }} , Tipo Plantilla: {{ row.nombreTipoPlantillaEnvio }}</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [flexGrow]="1" [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="nombre" [flexGrow]="2" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoEnvio }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="correo" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Correo</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-wrap text-table-alignment">{{ row.correo }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="estado" [flexGrow]="1.4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Estado</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row">
												<span *ngIf="row.activo" class="success-dot" ngbTooltip="Activado"></span>
												<span *ngIf="!row.activo" class="error-dot" ngbTooltip="Desactivado"></span>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Detalle Correo Envio" id="mantenedor-correo-envio.component_Detalle_Correo_Envio" (click)="openShowCorreoEnvioModal(detailCorreoEnvioModal, row)">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Correo Envio" id="mantenedor-correo-envio.component_Editar_Correo_Envio" (click)="openEditCorreoEnvioModal(editCorreoEnvioModal, row)">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a class="primary edit mr-1 mrless" ngbTooltip="Plantillas" id="mantenedor-correo-envio.component_Plantillas" (click)="openTemplatesModal(templates, row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon class="fa fa-envelope"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Correo de Envio"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar este correo de envio?',
														confirmButtonText: 'Desactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateCorreoEnvio(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="!row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Activar Correo de Envio"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de activar este correo de envio?',
														confirmButtonText: 'Activar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="activateCorreoEnvio(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla mobile-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #addCorreoEnvioModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Agregar Correo de Envio</div>
				<div class="modal-wizard-desc">Ingrese datos del nuevo correo de envio</div>
			</div>
		</div>
		<div class="modal-body">
			<app-correo-envio-entry [detailMode]="false" [editMode]="false" (sendCloseRequestEmitter)="closeAddCorreoEnvioModal()" (sendCorreoEnvioRequest)="sendNewCorreoEnvio($event)"></app-correo-envio-entry>
		</div>
	</div>
</ng-template>
<ng-template #editCorreoEnvioModal>
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Editar Correo de Envio</div>
				<div class="modal-wizard-desc">Modifica los datos del cesionario</div>
			</div>
		</div>
		<div class="modal-body">
			<app-correo-envio-entry [editMode]="true" [detailMode]="false" [correoEnvio]="selectedCorreoEnvio" (sendCloseRequestEmitter)="closeEditCorreoEnvioModal()" (sendCorreoEnvioRequest)="editCorreoEnvioRequest($event)"></app-correo-envio-entry>
		</div>
	</div>
</ng-template>
<ng-template #detailCorreoEnvioModal>
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Detalle de Correo de Envio</div>
				<div class="modal-wizard-desc">Se muestran los detalles del correo de envio</div>
			</div>
		</div>
		<div class="modal-body">
			<app-correo-envio-entry [detailMode]="true" [editMode]="false" [correoEnvio]="selectedCorreoEnvio" (sendCloseRequestEmitter)="closeShowCorreoEnvioModal()"></app-correo-envio-entry>
		</div>
	</div>
</ng-template>
<ng-template #templates let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-body p-0">
			<app-plantilla-correo-envio-entry [correoEnvio]="correoEnvioToEdit" (sendCloseRequestEmitter)="closeTemplatesModal()"></app-plantilla-correo-envio-entry>
		</div>
	</div>
</ng-template>

<ng-template #exampleTemplateModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-body p-0">
			<app-plantilla-correo-envio-entry [correoEnvio]="correoEnvioExample" [exampleModal]="true" (sendCloseRequestEmitter)="closeExampleTemplateModal()"></app-plantilla-correo-envio-entry>
		</div>
	</div>
</ng-template>
