<!--begin::Subheader-->
<div class="subheader py-3 py-lg-8" [ngClass]="subheaderCSSClasses" id="kt_subheader">
  <div [ngClass]="subheaderContainerCSSClasses"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center mr-1">
      <ng-container *ngIf="subheaderMobileToggle">
        <!--begin::Mobile Toggle-->
        <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
          <span></span>
        </button>
        <!--end::Mobile Toggle-->
      </ng-container>

      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline flex-wrap mr-5">
        <!--begin::Page Title-->
        <ng-container *ngIf="title$ | async as _title">
          <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">
            {{ _title }}
            <ng-container *ngIf="subheaderDisplayDesc">
              <ng-container *ngIf="description$ | async as _description">
                <small>{{ _description }}</small>
              </ng-container>
            </ng-container>
          </h2>
        </ng-container>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
          <li class="breadcrumb-item" *ngFor="let bc of breadcrumbs$ | async" routerLinkActive="active">
            <a [routerLink]="bc.linkPath" class="text-muted">
              {{ bc.linkText }}
            </a>
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

    <!--begin::Toolbar-->
    <div class="d-flex align-items-center flex-wrap">
      <!--begin::Button-->
      <a class="btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2">
        <span [inlineSVG]="'./assets/media/svg/icons/Communication/Add-user.svg'" cacheSVG="true"
          class="svg-icon svg-icon-lg"></span>
        <span class="d-none d-md-inline">New Member</span>
      </a>
      <!--end::Button-->

      <!--begin::Dropdown-->
      <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left"
        placement="bottom-right" ngbDropdown>
        <a class="btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <span [inlineSVG]="'./assets/media/svg/icons/Files/File.svg'" cacheSVG="true"
            class="svg-icon svg-icon-lg"></span>
          <span class="d-none d-md-inline">New Report</span>
        </a>
        <div class="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right" ngbDropdownMenu>
          <app-dropdown-menu1></app-dropdown-menu1>
        </div>
      </div>
      <!--end::Dropdown-->
      <!--begin::Button-->
      <a class="btn btn-primary btn-icon font-weight-bold" data-toggle="modal" data-target="#kt_chat_modal">
        <span [inlineSVG]="'./assets/media/svg/icons/Communication/Group-chat.svg'" cacheSVG="true"
          class="svg-icon svg-icon-lg"></span>
      </a>
      <!--end::Button-->
    </div>
    <!--end::Toolbar-->
  </div>
</div>
<!--end::Subheader-->