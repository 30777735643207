import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PlantillaImpresion } from "src/app/models/PlantillaImpresion/PlantillaImpresion";
import { PermissionService } from "src/app/services/permission.service";
import { PlantillaImpresionService } from "src/app/services/plantilla-impresion.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import Swal from "sweetalert2";
import * as FileSaver from "file-saver";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TipoDte } from "src/app/models/tipoDte";
import { ConfigMantenedorPlantillas } from "./config-plantillas-impresion";
import { PlantillaFilter } from "src/app/models/PlantillaImpresion/PlantillaFilter";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { EmisorService } from "src/app/services/emisor.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { SortType } from "@swimlane/ngx-datatable";

@Component({
	selector: "app-mantenedor-plantillas-impresion",
	templateUrl: "./mantenedor-plantillas-impresion.component.html",
	styleUrls: ["./mantenedor-plantillas-impresion.component.scss"],
})
export class MantenedorPlantillasImpresionComponent implements OnInit {
	SortType = SortType;
	public loading: boolean = false;
	public rowsMantenedor: PlantillaImpresion[] = [];
	public page: Page = new Page();
	private filter: PlantillaFilter = new PlantillaFilter();
	private createPlantillaModalRef: NgbModalRef;
	private editPlantillaModalRef: NgbModalRef;
	private asignPlantillaModalRef: NgbModalRef;
	public selectedPlantilla: PlantillaImpresion = undefined;
	private addCesionConfigModalRef: NgbModalRef;
	private tipoDteSource: TipoDte[] = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
	private fullListEmisoresPermission: boolean = this.tokenService.getUser().superAdmin;
	public configLocal = ConfigMantenedorPlantillas;
	public emisorId: string;
	public emisoresList: Emisor[] = [];
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	constructor(public permissions: PermissionService, private tokenService: TokenStorageService, private plantillaService: PlantillaImpresionService, private EmisorService: EmisorService, private ref: ChangeDetectorRef, private modalService: NgbModal, public utilities: UtilitiesService, private localStorage: LocalStorageService) {
		this.emisorId = this.tokenService.getCompany();
		if (this.fullListEmisoresPermission) {
			this.getFullEmisoresList();
		}
	}

	ngOnInit(): void {
		this.setPage({ offset: 0 });
	}
	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		this.plantillaService
			.getDataByPage(this.filter, localPage)
			.subscribe(
				(result) => {
					this.loading = false;
					let rows = result.data as PlantillaImpresion[];
					this.rowsMantenedor = [...rows];
					this.rowsMantenedor.forEach((row) => {
						this.setTipoDocumentoToPlantilla(row);
					});
					this.setPageFromResponse(this.page, result);
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error en plantillas",
						text: "No fue posible obtener la lista de plantillas disponibles, recargue la pagina." + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			)
			.add(() => (this.componentStarted = true));
	}
	getFullEmisoresList() {
		this.loading = true;
		this.EmisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				// let emisorService = new Emisor();
				// emisorService.razonSocial = "SERVICES";
				// emisorService.emisorId = null;
				// emisores.push(emisorService);
				this.emisoresList = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	openCreatePlantillaModal(modalRef) {
		this.createPlantillaModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}
	closeCreatePlantillaModal() {
		this.createPlantillaModalRef.close();
	}
	sendNewPlantilla(event: PlantillaImpresion) {
		this.loading = true;
		this.plantillaService
			.sendPlantillaRequest(event)
			.subscribe(
				(result) => {
					// let rows = JSON.parse(JSON.stringify(this.rowsMantenedor));
					// event.tipoPlantillaId = result.data;
					// rows.push(event);
					this.setPage({ offset: 0 });
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Plantilla cargada",
						text: "Se ha cargado exitosamente la plantilla de impresión",
					});
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error en Plantilla",
						text: "No ha podido cargarse la plantilla de impresión, intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
				this.closeCreatePlantillaModal();
			});
	}

	openEditPlantillaModal(modalRef, row: PlantillaImpresion) {
		this.selectedPlantilla = row;
		this.editPlantillaModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	closeEditPlantillaModal() {
		this.editPlantillaModalRef.close();
	}

	editPlantillaRequest(event: PlantillaImpresion) {
		this.loading = true;
		this.plantillaService
			.editPlantillaRequest(event)
			.subscribe(
				(result) => {
					// let rows = JSON.parse(JSON.stringify(this.rowsMantenedor));
					// event.tipoPlantillaId = result.data;
					// rows.push(event);
					// this.ref.detectChanges();
					// this.rowsMantenedor = JSON.parse(JSON.stringify(rows)) as PlantillaImpresion[];
					this.setPage({ offset: 0 });
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Plantilla Actualizada",
						text: "La plantilla de impresión se actualizo exitosamente",
					});
					this.closeEditPlantillaModal();
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al Actualizar Plantilla",
						text: "No se ha podido actualizar la plantilla de impresión, intente nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			)
			.add(() => {
				this.loading = false;
				this.ref.detectChanges();
				this.closeEditPlantillaModal();
			});
	}

	getSample(row: PlantillaImpresion) {
		this.loading = true;
		if (row.codigoTipoDte == 110 || row.codigoTipoDte == 111 || row.codigoTipoDte == 112) {
			this.plantillaService.getPdfSampleExporta(row).subscribe(
				(result) => {
					this.loading = false;
					this.ref.detectChanges();
					let b64Response = result.data;
					try {
						if (b64Response == "") {
							throw "Respuesta vacía";
						} else {
							this.responseToPdfDownload(b64Response, row);
						}
					} catch (error) {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Error al obtener muestra",
							text: "No es posible renderizar la muestra asociada a esta plantilla de impresión.",
						});
					}
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al obtener muestra",
						text: "No es posible renderizar la muestra asociada a esta plantilla de impresión." + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			);
		} else {
			this.plantillaService.getPdfSample(row, this.filter.emisorId ? this.filter.emisorId : this.emisorId).subscribe(
				(result) => {
					this.loading = false;
					this.ref.detectChanges();
					let b64Response = result.data;
					try {
						if (b64Response == "") {
							throw "Respuesta vacía";
						} else {
							this.responseToPdfDownload(b64Response, row);
						}
					} catch (error) {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Error al obtener muestra",
							text: "No es posible renderizar la muestra asociada a esta plantilla de impresión.",
						});
					}
				},
				(error) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al obtener muestra",
						text: "No es posible renderizar la muestra asociada a esta plantilla de impresión." + this.utilities.setErrorMessageFromArray(error.error),
					});
				}
			);
		}
	}
	download(row: PlantillaImpresion) {
		this.loading = true;
		this.plantillaService.download(row).subscribe(
			(result) => {
				this.HtmlDownload(result, row);
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al descargar HTML",
					text: "No se pudo generar el documento, intente mas tarde." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	deletePlantilla(row: PlantillaImpresion) {
		this.loading = true;
		this.plantillaService.deletePlantilla(row).subscribe(
			(result) => {
				this.loading = false;
				// let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
				// this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
				this.setPage({ offset: 0 });
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Plantilla Eliminada",
					text: "La plantilla seleccionada fue eliminada exitosamente",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en eliminación",
					text: "No fue posible eliminar la plantilla de impresión sleeccionada, favor recargar intentar nuevamente." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	setPageFromResponse(currentPage: Page, data: PagedResponse<PlantillaImpresion>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
	}
	HtmlDownload(responseB64, currentPlantilla: PlantillaImpresion) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "text/html");
		FileSaver.saveAs(pdfBlob, currentPlantilla.nombreTipoDte + ".html");
		this.loading = false;
		this.ref.detectChanges();
	}
	responseToPdfDownload(responseB64, currentPlantilla: PlantillaImpresion) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "application/pdf");
		//FileSaver.saveAs(pdfBlob, currentPlantilla.descripcion+"_prueba.pdf");
		if (pdfBlob.size > 0) {
			let fileURL = URL.createObjectURL(pdfBlob);
			this.popupCenter(fileURL, "PDF", 600, 800);
		}
		this.loading = false;
		this.ref.detectChanges();
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const newWindow = window.open(
			url,
			title,
			`
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}

	setTipoDocumentoToPlantilla(row: PlantillaImpresion) {
		let tipo = this.tipoDteSource.find((tipo) => tipo.tipoDteId == row.tipoDteId);
		row.nombreTipoDte = tipo.nombre;
	}

	searchElementsByFilter(event) {
		//console.log(event);
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.tipoDteId = event.tipoDteId;
		this.filter.emisorId = event.emisorId;
		this.setPage({ offset: 0 });
	}

	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}
	getAllRowsByFilter(currentPageRef: Page, currentFilter: PlantillaFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 1;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.plantillaService.getDataByPage(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as PlantillaImpresion[];
				this.ref.detectChanges();
				elements.forEach((row) => {
					this.setTipoDocumentoToPlantilla(row);
				});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				let msg = "";
				if (error.error.message) {
					msg += error.error.message;
				}
				Swal.fire({
					icon: "error",
					title: "Error al exportar",
					text: "No es posible exportar los datos. Intente mas tarde. " + msg,
				});
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}
	exportExcelData(arrayToExport: Array<PlantillaImpresion>): void {
		let arregloExportar = [];
		arrayToExport.forEach((row) => {
			let elementToExport = {};
			elementToExport["Tipo DTE"] = row.nombreTipoDte;
			elementToExport["Descripcion"] = row.descripcion;
			elementToExport["Tipo Papel"] = row.tipoPapel;
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Plantillas_impresion");
	}

	openAsignaPlantillaModal(modalRef, row) {
		this.selectedPlantilla = row;
		this.asignPlantillaModalRef = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	closeAsignaPlantillaModal() {
		this.selectedPlantilla = undefined;
		this.asignPlantillaModalRef.close();
		this.setPage({ offset: 0 });
	}
	downloadLabelsPdf() {
		this.loading = true;
		this.plantillaService.getPdfLabels().subscribe(
			(res) => {
				this.PdfDownload(res.data, "Etiquetas");
				this.loading = false;
				this.ref.detectChanges();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Descargar Pdf",
					text: "No fue posible descargar el pdf de etiquetas, favor recargar intentar nuevamente." + this.utilities.setErrorMessageFromArray(err.error),
				});
			}
		);
	}
	PdfDownload(responseB64, name) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "text/pdf");
		FileSaver.saveAs(pdfBlob, name + ".pdf");
		this.loading = false;
		this.ref.detectChanges();
	}
	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}
}
