export class Permission {
   permisoId: string;
   codigo: string;
   nombre: string;
   asignado: boolean;
   activo: boolean;

   constructor();

   constructor(asigned?: boolean) {
      this.asignado = asigned;
   }
}
