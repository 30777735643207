import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { DetalleDte } from "../models/Dte/DetalleDte";
import { Receptor } from "../models/Receptor/Receptor";
import { Response } from "../shared/models/Response";
import { TotalDteEmitido } from "../models/Dte/TotalDteEmitido";
import { OptionsReferenceDteEmitido } from "../models/Dte/OptionsReferenceDteEmitido";
import { DteEmitido } from "../models/Dte/DteEmitido";
import { Divisa } from "src/app/models/Divisa";
import { finalize } from "rxjs/operators";
import { InvoiceType } from "../models/Dte/invoiceType.enum";
import { Page } from "../shared/models/Page";
import { ProductoFilter } from "../models/Producto/ProductoFilter";
import { Producto } from "../models/Producto/Producto";
import { ProductoService } from "./producto.service";
import { PagedResponse } from "../shared/models/PagedResponse";
import Swal from "sweetalert2";
import { title } from "process";
import { OpcionesReferenciasDteComponent } from "../components/facturacion/facturador-individual/opciones-referencias-dte/opciones-referencias-dte.component";
import { IDte } from "../models/Dte/IDte";
import { TipoDte } from "../models/tipoDte";
import { ComisionRecargo } from "../models/Dte/ComisionRecargo";
import { InvoicingButton } from "../shared/models/InvoicingButton.enum";
import { GuiaDespacho } from "../models/GuiaDespacho";
import { ReferenciaDteEmitido } from "../models/Dte/ReferenciaDteEmitido";
import { FormatoDte } from "../models/Dte/FormatoDte";

@Injectable({
	providedIn: "root",
})
export class FacturacionIndividualService {
	private CLIENT_SEARCH_SERVICE = environment.backend_server + "/clientes";
	private PROVEEDOR_SEARCH_SERVICE = environment.backend_server + "/proveedor";
	private INVOICE_SERVICE = environment.backend_server + "/facturacion";
	private BOLETA_SERVICE = environment.backend_server + "/boleta";
	private GUIDE_SERVICE = environment.backend_server + "/guiaDespacho";
	private SETTLEMENT_SERVICE = environment.backend_server + "/liquidacionFactura";
	private INVOICE_SERVICE_ONVISION = environment.backend_server + "/Impresion";
	private detalleDteList: DetalleDte[];
	private detalleComisionDteList: ComisionRecargo[];
	private resumenTotalesDte: TotalDteEmitido = new TotalDteEmitido();
	private clienteId: string = "";
	private opcionesReferenciaDte: OptionsReferenceDteEmitido = new OptionsReferenceDteEmitido();
	private submittingDte: boolean = false;
	private maxSize: number = 5;
	private maxComisionSize: number = 20;
	public invalidSize: boolean = false;
	public currentSize: number = 0;
	public currentInvoiceType: InvoiceType;
	public productsPage: Page = new Page();
	public productsFilter: ProductoFilter = new ProductoFilter();
	public fullProductsList: Producto[] = [];
	private maxList = 100;
	public detalleDteListObservable: Observable<DetalleDte[]>;
	public detalleComisionDteListObservable: Observable<ComisionRecargo[]>;
	public resumenTotalesObservable: Observable<TotalDteEmitido>;
	public clienteIdObservable: Observable<string>;
	public opcionesReferenciaObservable: Observable<OptionsReferenceDteEmitido>;
	public formatSizeObservable: Observable<number>;
	public invalidSizeObservable: Observable<boolean>;
	public submittedDteObservable: Observable<boolean>;
	public divisasListObservable: Observable<Divisa[]>;
	public clearListObservable: Observable<boolean>;
	public currentInvoiceTypeObservable: Observable<InvoiceType>;
	public detailWithTaxInOptionsObservable: Observable<boolean>;
	public buttonActionsObservable: Observable<InvoicingButton>;
	public isLoadingCodigosAduana: Observable<boolean>;

	private detalleDteListSubject: BehaviorSubject<DetalleDte[]>;
	private detalleComisionDteListSubject: BehaviorSubject<ComisionRecargo[]>;
	private resumenTotalesSubject: BehaviorSubject<TotalDteEmitido>;
	private clienteIdSubject: BehaviorSubject<string>;
	private opcionesReferenciaSubject: BehaviorSubject<OptionsReferenceDteEmitido>;
	private formatSizeSubject: BehaviorSubject<number>;
	private invalidSizeSubject: BehaviorSubject<boolean>;
	private submittedDteSubject: BehaviorSubject<boolean>;
	private divisasListSubject: BehaviorSubject<Divisa[]>;
	private clearListSubject: BehaviorSubject<boolean>;
	private currentInvoiceTypeSubject: BehaviorSubject<InvoiceType>;
	private detailWithTaxInOptionsSubject: BehaviorSubject<boolean>;
	private buttonActionsSubject: Subject<InvoicingButton>;
	private isLoadingCodigosAduanaSubject: BehaviorSubject<boolean>;

	public loadingFormatSizesObservable: Observable<boolean>;
	public loadingFormatSizesSubject: BehaviorSubject<boolean>;
	public formatSizeListObservable: Observable<FormatoDte[]>;
	public formatSizeListSubject: BehaviorSubject<FormatoDte[]>;

	public isLoadingClientObservable: Observable<boolean>;
	private loadingClientSubject: BehaviorSubject<boolean>;

	public resetingClientObservable: Observable<boolean>;
	private resetClientSubject: BehaviorSubject<boolean>;

	public clientIsUnloadedObservable: Observable<boolean>;
	private clientIsUnloadedSubject: BehaviorSubject<boolean>;

	public referencesListFromGuidesObservable: Observable<ReferenciaDteEmitido[]>;
	public referencesListFromGuidesSubject: BehaviorSubject<ReferenciaDteEmitido[]>;

	public detailsListFromGuidesObservable: Observable<DetalleDte[]>;
	public detailsListFromGuidesSubject: BehaviorSubject<DetalleDte[]>;

	public addDetailProductObservable: Observable<DetalleDte | undefined>;
	public addDetailProductSubject: BehaviorSubject<DetalleDte | undefined>;

	public addDetailDocumentObservable: Observable<DetalleDte | undefined>;
	public addDetailDocumentSubject: BehaviorSubject<DetalleDte | undefined>;

	public tipoCantidadFolioObservable: Observable<[string, number]>;
	public tipoCantidadFolioSubject: BehaviorSubject<[string, number]>;

	public selectedClient: Receptor;
	detallePrueba: DetalleDte = new DetalleDte();
	detallePruebaComision: ComisionRecargo = new ComisionRecargo();

	constructor(private http: HttpClient, private productService: ProductoService) {
		this.detalleDteList = [];
		this.detalleComisionDteList = [];
		let format: FormatoDte[] = [];
		this.formatSizeListSubject = new BehaviorSubject<FormatoDte[]>(format);
		this.formatSizeListObservable = this.formatSizeListSubject.asObservable();
		this.loadingFormatSizesSubject = new BehaviorSubject<boolean>(false);
		this.loadingFormatSizesObservable = this.loadingFormatSizesSubject.asObservable();
		this.detalleDteListSubject = new BehaviorSubject<DetalleDte[]>(this.detalleDteList);
		this.detalleComisionDteListSubject = new BehaviorSubject<ComisionRecargo[]>(this.detalleComisionDteList);
		this.detalleDteListObservable = this.detalleDteListSubject.asObservable();
		this.detalleComisionDteListObservable = this.detalleComisionDteListSubject.asObservable();
		this.resumenTotalesSubject = new BehaviorSubject<TotalDteEmitido>(this.resumenTotalesDte);
		this.resumenTotalesObservable = this.resumenTotalesSubject.asObservable();
		this.clienteIdSubject = new BehaviorSubject<string>("");
		this.clienteIdObservable = this.clienteIdSubject.asObservable();
		this.opcionesReferenciaSubject = new BehaviorSubject<OptionsReferenceDteEmitido>(this.opcionesReferenciaDte);
		this.opcionesReferenciaObservable = this.opcionesReferenciaSubject.asObservable();
		this.formatSizeSubject = new BehaviorSubject<number>(10);
		this.formatSizeObservable = this.formatSizeSubject.asObservable();
		this.invalidSizeSubject = new BehaviorSubject<boolean>(false);
		this.invalidSizeObservable = this.invalidSizeSubject.asObservable();
		this.submittedDteSubject = new BehaviorSubject<boolean>(false);
		this.submittedDteObservable = this.submittedDteSubject.asObservable();
		this.divisasListSubject = new BehaviorSubject<Divisa[]>([]);
		this.divisasListObservable = this.divisasListSubject.asObservable();
		this.clearListSubject = new BehaviorSubject<boolean>(false);
		this.clearListObservable = this.clearListSubject.asObservable();
		this.currentInvoiceTypeSubject = new BehaviorSubject<InvoiceType>(InvoiceType.Factura);
		this.currentInvoiceTypeObservable = this.currentInvoiceTypeSubject.asObservable();
		this.buttonActionsSubject = new Subject<InvoicingButton>();
		this.buttonActionsObservable = this.buttonActionsSubject.asObservable();
		this.isLoadingCodigosAduanaSubject = new BehaviorSubject<boolean>(false);
		this.isLoadingCodigosAduana = this.isLoadingCodigosAduanaSubject.asObservable();
		this.clientIsUnloadedSubject = new BehaviorSubject<boolean>(true);
		this.clientIsUnloadedObservable = this.clientIsUnloadedSubject.asObservable();
		this.referencesListFromGuidesSubject = new BehaviorSubject<ReferenciaDteEmitido[]>([]);
		this.referencesListFromGuidesObservable = this.referencesListFromGuidesSubject.asObservable();
		this.detailsListFromGuidesSubject = new BehaviorSubject<DetalleDte[]>([]);
		this.detailsListFromGuidesObservable = this.detailsListFromGuidesSubject.asObservable();
		this.addDetailProductSubject = new BehaviorSubject<DetalleDte>(undefined);
		this.addDetailProductObservable = this.addDetailProductSubject.asObservable();
		this.addDetailDocumentSubject = new BehaviorSubject<DetalleDte>(undefined);
		this.addDetailDocumentObservable = this.addDetailDocumentSubject.asObservable();
		this.tipoCantidadFolioSubject = new BehaviorSubject<[string, number]>(["", 0]);
		this.tipoCantidadFolioObservable = this.tipoCantidadFolioSubject.asObservable();
		this.detallePrueba.precioUnitario = 0;
		this.detallePrueba.cantidad = 0;
		this.detallePrueba.esExento = false;
		this.detallePrueba.itemId = "";
		this.detallePrueba.descripcion = "";
		this.detallePrueba.calcSubTotal();
		this.detallePruebaComision.neto = 0;
		this.detallePruebaComision.cantidad = 0;
		this.detallePruebaComision.exento = 0;
		this.detallePruebaComision.tasa = 0;
		this.detallePruebaComision.glosa = "";
		this.detallePruebaComision.tipoId = "";
		this.detallePruebaComision.tipoNombre = "";
		this.detallePruebaComision.tipoSigla = "";
		this.setItem(this.detallePrueba);
		this.setComisionItem(this.detallePruebaComision);
		//this.facturador.setList(this.listaDetalles);
		this.loadingClientSubject = new BehaviorSubject<boolean>(false);
		this.isLoadingClientObservable = this.loadingClientSubject.asObservable();
		this.resetClientSubject = new BehaviorSubject<boolean>(false);
		this.resetingClientObservable = this.resetClientSubject.asObservable();
		this.detailWithTaxInOptionsSubject = new BehaviorSubject<boolean>(true);
		this.detailWithTaxInOptionsObservable = this.detailWithTaxInOptionsSubject.asObservable();
		this.productsPage.pageSize = 20;
	}

	ngOnInit() {
		this.detalleDteList = [];
		this.setItem(this.detallePrueba);
		this.setComisionItem(this.detallePruebaComision);
	}

	setList(list: DetalleDte[]) {
		this.detalleDteList = [...list];
		this.checkValidList();
		this.detalleDteListSubject.next(this.detalleDteList);
	}
	setComisionList(list: ComisionRecargo[]) {
		this.detalleComisionDteList = [...list];
		this.checkValidComisionList();
		this.detalleComisionDteListSubject.next(this.detalleComisionDteList);
	}

	setItem(item: DetalleDte) {
		this.checkValidList();
		if (!this.invalidSize) {
			this.detalleDteList.push(item);
			this.detalleDteList = [...this.detalleDteList];
			this.detalleDteListSubject.next(this.detalleDteList);
		}
	}
	setComisionItem(item: ComisionRecargo) {
		this.checkValidComisionList();
		if (!this.invalidSize) {
			this.detalleComisionDteList.push(item);
			this.detalleComisionDteList = [...this.detalleComisionDteList];
			this.detalleComisionDteListSubject.next(this.detalleComisionDteList);
		}
	}

	setNewItem(item?: DetalleDte) {
		this.checkValidList();
		if (!this.invalidSize) {
			let newElement = new DetalleDte();
			newElement = item ? item : new DetalleDte();
			let nextIndex = this.checkIndexOfEmptyItem();
			if (nextIndex >= 0 && newElement.relatedProductId) {
				this.detalleDteList.splice(nextIndex, 1, newElement);
			} else {
				this.detalleDteList.push(newElement);
			}
			//this.cleanListIfFirstItemEmpty();
			let list = [...this.detalleDteList];
			this.detalleDteList = list;
			this.detalleDteListSubject.next(this.detalleDteList);
		}
	}
	setComisionNewItem() {
		this.checkValidComisionList();
		if (!this.invalidSize) {
			this.detalleComisionDteList.push(new ComisionRecargo());
			this.detalleComisionDteListSubject.next(this.detalleComisionDteList);
		}
	}

	cleanListIfFirstItemEmpty() {
		let item = this.detalleDteList[0];
		if (item && (item.cantidad == 0 || item.cantidad == null || item.precioUnitario == 0 || item.precioUnitario == null)) {
			this.detalleDteList = [];
		}
	}

	checkIndexOfEmptyItem() {
		let itemIndex = -1;
		if (this.detalleDteList && this.detalleDteList.length > 0) {
			for (let i = 0; i < this.detalleDteList.length; i++) {
				const element = this.detalleDteList[i];
				if (element && (element.cantidad == 0 || element.cantidad == null || element.precioUnitario == 0 || element.precioUnitario == null)) {
					return i;
				}
			}
		}
		return itemIndex;
	}

	updateItem(item: DetalleDte) {
		let updatedItemIndex = this.detalleDteList.indexOf(item);
		if (updatedItemIndex >= 0) {
			this.checkValidList();
			this.detalleDteList[updatedItemIndex] = item;
			this.detalleDteListSubject.next(this.detalleDteList);
		}
	}
	updateComisionItem(item: ComisionRecargo) {
		let updatedItemIndex = this.detalleComisionDteList.indexOf(item);
		if (updatedItemIndex >= 0) {
			this.checkValidComisionList();
			this.detalleComisionDteList[updatedItemIndex] = item;
			this.detalleComisionDteListSubject.next(this.detalleComisionDteList);
		}
	}

	deleteItem(index) {
		this.detalleDteList.splice(index, 1);
		this.checkValidList();
		this.detalleDteListSubject.next(this.detalleDteList);
	}
	deleteComisionItem(index) {
		this.detalleComisionDteList.splice(index, 1);
		this.checkValidComisionList();
		this.detalleComisionDteListSubject.next(this.detalleComisionDteList);
	}

	searchClient(rut: string, emisorId: string): Observable<Response<Receptor[]>> {
		this.loadingClientSubject.next(true);
		return this.http.get<Response<Receptor[]>>(this.CLIENT_SEARCH_SERVICE + "/buscar?pattern=" + rut + "&emisorId=" + emisorId).pipe(finalize(() => this.loadingClientSubject.next(false)));
	}

	updateClient(rut: string, client: Receptor) {
		this.clienteIdSubject.next(rut);
		this.clientIsUnloadedSubject.next(false);
		this.selectedClient = client;
	}

	updateOptionsReferences(optionsRef: OptionsReferenceDteEmitido) {
		//this.checkOptionsDetalleMode(optionsRef, this.detalleDteList);
		this.opcionesReferenciaDte = optionsRef;
		this.opcionesReferenciaSubject.next(optionsRef);
	}

	updateTotales(totales: TotalDteEmitido) {
		this.formatSizeSubject.next(totales.formato && totales.formato.size ? totales.formato.size : 0);
		this.maxSize = totales.formato && totales.formato.size ? totales.formato.size : 0;
		this.resumenTotalesSubject.next(totales);
	}

	updateOptions(opciones: OptionsReferenceDteEmitido) {}

	updateDivisas(divisas: Divisa[]) {
		this.divisasListSubject.next(divisas);
	}
	sendDte(usuarioId: string, sucursalId: string, newDte: IDte): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("usuarioIdString", usuarioId);
		formData.append("sucursalIdString", sucursalId);
		formData.append("dteEmitidoEntString", JSON.stringify(newDte));
		return this.http.post<Response<string>>(this.INVOICE_SERVICE + "/emitir", formData);
	}

	updateFormatoDte(formato: FormatoDte[]) {
		this.maxSize = formato[0].maxLineas;
		this.formatSizeSubject.next(this.maxSize);
		this.formatSizeListSubject.next(formato);
	}

	getFormatSizeItems(emisorId: string, tipoDteId: string): Observable<Response<FormatoDte[]>> {
		this.loadingFormatSizesSubject.next(true);
		return this.http.get<Response<FormatoDte[]>>(this.INVOICE_SERVICE + "/getActiveTemplates/" + emisorId + "/" + tipoDteId).pipe(finalize(() => this.loadingFormatSizesSubject.next(false)));
	}
	sendDteGuide(usuarioId: string, sucursalId: string, newDte: IDte): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("usuarioIdString", usuarioId);
		formData.append("sucursalIdString", sucursalId);
		formData.append("dteEmitidoEntString", JSON.stringify(newDte));
		return this.http.post<Response<string>>(this.GUIDE_SERVICE + "/emitir", formData);
	}
	sendDteSettlement(usuarioId: string, sucursalId: string, newDte: IDte): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("usuarioIdString", usuarioId);
		formData.append("sucursalIdString", sucursalId);
		formData.append("dteEmitidoEntString", JSON.stringify(newDte));
		return this.http.post<Response<string>>(this.SETTLEMENT_SERVICE + "/emitir", formData);
	}

	sendDteBoleta(usuarioId: string, sucursalId: string, newDte: IDte): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("usuarioIdString", usuarioId);
		formData.append("sucursalIdString", sucursalId);
		formData.append("dteEmitidoEntString", JSON.stringify(newDte));
		return this.http.post<Response<string>>(this.BOLETA_SERVICE + "/emitir", formData);
	}

	previewDte(usuarioId: string, sucursalId: string, newDte: IDte): Observable<Response<string>> {
		let formData = new FormData();
		formData.append("usuarioIdString", usuarioId);
		formData.append("sucursalIdString", sucursalId);
		formData.append("dteEmitidoEntString", JSON.stringify(newDte));
		let urlPreview = "/preview";
		if (newDte.codigoTipoDte == 110 || newDte.codigoTipoDte == 111 || newDte.codigoTipoDte == 112) urlPreview += "/exporta";
		if (newDte.codigoTipoDte == 43) urlPreview += "/liquidacion";
		return this.http.post<Response<string>>(this.INVOICE_SERVICE_ONVISION + urlPreview, formData);
	}

	sendFacturaCompraRequest(usuarioId: string, sucursalId: string, newDte: IDte): Observable<Response<any>> {
		let formData = new FormData();
		formData.append("usuarioIdString", usuarioId);
		formData.append("sucursalIdString", sucursalId);
		formData.append("dteEmitidoEntString", JSON.stringify(newDte));
		return this.http.post<Response<any>>(this.INVOICE_SERVICE + "/emitir/facturacompra", formData);
	}

	checkValidList() {
		let currentSize = this.countLinesInList(this.detalleDteList);
		this.invalidSize = currentSize > this.maxSize;
		this.invalidSizeSubject.next(this.invalidSize);
	}
	checkValidComisionList() {
		let currentSize = this.countLinesInComisionList(this.detalleComisionDteList);
		this.invalidSize = currentSize > this.maxSize;
		this.invalidSizeSubject.next(this.invalidSize);
	}

	resetElements() {
		this.opcionesReferenciaDte = new OptionsReferenceDteEmitido();
		this.setList([]);
		this.setItem(new DetalleDte());
		this.setComisionList([]);
		this.setComisionItem(new ComisionRecargo());
		this.updateClient("", undefined);
		this.dteSubmitted();
		this.selectedClient = undefined;
		this.clientIsUnloadedSubject.next(true);
		this.detailsListFromGuidesSubject.next([]);
		this.addDetailProductSubject.next(undefined);
		this.addDetailDocumentSubject.next(undefined);
		//console.log("Destroy Service List")
	}

	dteSubmitted() {
		this.submittedDteSubject.next(true);
	}

	resetSubmit() {
		this.submittedDteSubject.next(false);
	}

	clearUnfilledItems() {
		this.detalleDteList = this.detalleDteList.filter((item) => item.cantidad > 0 && item.precioUnitario > 0);
		this.checkValidList();
		this.detalleDteListSubject.next(this.detalleDteList);
	}
	clearUnfilledComisionItems() {
		this.detalleComisionDteList = this.detalleComisionDteList.filter((item) => (item.cantidad > 0 && item.neto > 0) || item.exento > 0);
		this.checkValidComisionList();
		this.detalleComisionDteListSubject.next(this.detalleComisionDteList);
	}

	countLinesInList(list: DetalleDte[]) {
		let lines = 0;
		list.forEach((item) => {
			if (item.descripcion != undefined && item.descripcion != "") {
				lines += 2;
			} else {
				lines++;
			}
		});
		this.currentSize = lines;
		return lines;
	}
	countLinesInComisionList(list: ComisionRecargo[]) {
		let lines = 0;
		list.forEach((item) => {
			lines++;
		});
		this.currentSize = lines;
		return lines;
	}

	setCurrentInvoiceType(type: InvoiceType) {
		this.currentInvoiceType = type;
		this.currentInvoiceTypeSubject.next(type);
		console.log(this.currentInvoiceType);
	}

	searchProducts(searchTerm: string, searchValue: string, emisorId: string): Observable<PagedResponse<Producto>> {
		this.productsFilter = new ProductoFilter();
		this.productsFilter.emisorId = emisorId;
		this.productsFilter[searchTerm] = searchValue;
		//page size for search && page size por table
		return this.productService.getDataByPage(this.productsFilter, this.productsPage);
	}

	checkOptionsDetalleMode(options: OptionsReferenceDteEmitido, list: DetalleDte[]) {
		let checkOptions = list.some((detail) => detail.totalImpuestosAdicionales > 0) && options.sonDetallesConIVA;
		if (checkOptions) {
			Swal.fire({
				icon: "error",
				title: "Error en detalles",
				text: "El documento solo considera montos netos si los productos tienen impuestos específicos asociados",
			});
			options.sonDetallesConIVA = false;
			this.detailWithTaxInOptionsSubject.next(false);
		}
	}


	sendEmitAction() {
		this.buttonActionsSubject.next(InvoicingButton.Emitir);
	}

	sendPreviewAction() {
		this.buttonActionsSubject.next(InvoicingButton.Previsualizar);
	}

	sendCancelAction() {
		this.buttonActionsSubject.next(InvoicingButton.Cancelar);
	}

	sendFacturaGuiaAction() {
		this.buttonActionsSubject.next(InvoicingButton.FacturarGuias);
	}

	checkForBillableGuides(emisorId, receptorId, usuarioId): Observable<Response<DteEmitido[]>> {
		let formData = new FormData();
		formData.append("emisorId", emisorId);
		formData.append("receiverId", receptorId);
		formData.append("userId", usuarioId);
		return this.http.post<Response<DteEmitido[]>>(this.GUIDE_SERVICE + "/getGuidesByClient", formData);
	}

	passTipoCantidadFolio(info: [string, number]) {
		this.tipoCantidadFolioSubject.next(info);
	}
}
