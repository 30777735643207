import { Component, OnInit } from "@angular/core";

@Component({
   selector: "app-permission-block",
   templateUrl: "./permission-block.component.html",
   styleUrls: ["./permission-block.component.scss"],
})
export class PermissionBlockComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
