import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { LocalStorageService } from "src/app/services/local-storage.service";
import Swal from "sweetalert2";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SingleEmailValidator } from "src/app/directives/multi-email.directive";
import { UtilitiesService } from "src/app/services/utilities.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { SessionUser } from "src/app/shared/models/sessionUser";
import { file } from "jszip";
import * as FileSaver from "file-saver";
import { PermissionService } from "src/app/services/permission.service";
import { PlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/PlantillaCorreoEnvio";
import { TipoPlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/TipoPlantillaCorreoEnvio";
import { CorreoEnvioService } from "src/app/services/CorreoEnvio.service";
import { CorreoEnvio } from "src/app/models/CorreoEnvio/CorreoEnvio";
import { TipoCorreoEnvio } from "src/app/models/CorreoEnvio/TipoCorreoEnvio";
import { ExampleRequest } from "src/app/models/CorreoEnvio/ExampleRequest";

@Component({
	selector: "app-plantilla-correo-envio-entry",
	templateUrl: "./plantilla-correo-envio-entry.component.html",
	styleUrls: ["./plantilla-correo-envio-entry.component.scss"],
})
export class PlantillaCorreoEnvioEntryComponent implements OnInit {
	public loading = false;
	public templateForm: FormGroup;
	public updateTemplateBoxForm: FormGroup;
	public templateList: PlantillaCorreoEnvio[] = [];
	public templateDetail: PlantillaCorreoEnvio = new PlantillaCorreoEnvio();
	public addTemplateModal: NgbModalRef;
	private updateTemplateModal: NgbModalRef;
	private detailTemplateModal: NgbModalRef;
	public templateToEdit = new PlantillaCorreoEnvio();
	public files: File[] = [];
	public isSengrid = false;
	public templateTypes: TipoPlantillaCorreoEnvio[] = JSON.parse(this.localStorage.getTipoPlantillaoCorreoEnvio()) as TipoPlantillaCorreoEnvio[];
	public sendingType: TipoCorreoEnvio[] = JSON.parse(this.localStorage.getTipooCorreoEnvio()) as TipoCorreoEnvio[];
	@Input() public correoEnvio: CorreoEnvio = undefined;
	@Input() public exampleModal = false;
	@Output() private sendCloseRequestEmitter: EventEmitter<any> = new EventEmitter<any>();
	constructor(private formBuilder: FormBuilder, private ref: ChangeDetectorRef, private modal: NgbModal, private tokenService: TokenStorageService, public permissions: PermissionService, private correoEnvioService: CorreoEnvioService, private localStorage: LocalStorageService, private utilities: UtilitiesService) {}

	ngOnInit(): void {
		this.cleanFiles();
		this.setForm();
		if (!this.exampleModal) this.getTemplates();
		var sengrid = this.sendingType.filter((x) => x.nombre.toLocaleLowerCase().includes("sengrid"))[0];
		if (!this.exampleModal && this.correoEnvio.tipoEnvioCorreoId == sengrid.tipoEnvioCorreoId) this.isSengrid = true;
		if (this.exampleModal) {
			this.isSengrid = false;
			this.templateList = this.correoEnvio.Plantillas;
		}
		this.updateValidators();
	}

	public updateValidators() {
		if (this.isSengrid) {
			this.templateForm.controls.file.setValidators([]);
		} else {
			this.templateForm.controls.code.setValidators([]);
			this.templateForm.controls.code.setValue("");
		}
		this.templateForm.updateValueAndValidity();
	}

	cleanFiles() {
		this.files = [];
	}
	onSubmit() {
		if (this.templateForm.valid) {
		}
	}

	setForm() {
		this.templateForm = this.formBuilder.group({
			nombre: ["", [Validators.required]],
			tipo: [this.correoEnvio.tipoPlantillaEnvioCorreo, [Validators.required]],
			file: [[Validators.required]],
			code: ["", [Validators.required]],
		});
	}

	sendCloseRequest() {
		this.sendCloseRequestEmitter.emit();
	}
	openAddTemplateModal(modalRef) {
		this.loading = false;
		this.setForm();
		this.cleanFiles();
		this.updateValidators();
		this.addTemplateModal = this.modal.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	closeAddTemplateModal() {
		this.loading = false;
		this.ref.detectChanges();
		this.addTemplateModal.close();
	}

	exist(tipoPlantillaId: number) {
		var exist = this.templateList.filter((x) => x.tipoPlantillaEnvioCorreoId == tipoPlantillaId)[0];
		if (exist != null) return true;
		else return false;
	}

	add() {
		if (this.templateForm.valid) {
			var Template = new PlantillaCorreoEnvio();
			Template.tipoPlantillaEnvioCorreoId = this.exampleModal ? this.correoEnvio.tipoPlantillaEnvioCorreo.tipoPlantillaEnvioCorreoId : this.correoEnvio.tipoPlantillaId;
			Template.activo = true;
			Template.nombre = this.templateForm.get("nombre").value;
			Template.templateCode = this.templateForm.get("code").value;
			Template.envioCorreoId = this.correoEnvio.envioCorreoId;
			Template.file = this.files[0];
			if (!this.isSengrid && Template.file == null) {
				Swal.fire({
					icon: "success",
					title: "Plantilla Vacia",
					text: "El archivo de la plantilla esta vacio",
				});
			}
			if (this.exampleModal) {
				this.correoEnvioService
					.addExampleTemplate(Template, this.isSengrid)
					.subscribe(
						(res) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "success",
								title: "Plantilla Agregada",
								text: "Se ha agregado exitosamente la plantilla " + Template.nombre,
							});
							this.getTemplates();
							this.closeAddTemplateModal();
						},
						(err) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "error",
								title: "Error al Agregar Plantilla",
								text: "No se ha podido agregar la plantilla del correo " + Template.nombre + ", intente nuevamente",
							});
						}
					)
					.add(() => {
						this.loading = false;
					});
			} else {
				this.correoEnvioService
					.addTemplate(Template, this.isSengrid)
					.subscribe(
						(res) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "success",
								title: "Plantilla Agregada",
								text: "Se ha agregado exitosamente la plantilla " + Template.nombre,
							});
							this.getTemplates();
							this.closeAddTemplateModal();
						},
						(err) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "error",
								title: "Error al Agregar Plantilla",
								text: "No se ha podido agregar la plantilla del correo " + Template.nombre + ", intente nuevamente",
							});
						}
					)
					.add(() => {
						this.loading = false;
					});
			}
		}
	}
	setTemplateForm(item: PlantillaCorreoEnvio) {
		var template = new TipoPlantillaCorreoEnvio();
		if (this.exampleModal) template = this.correoEnvio.tipoPlantillaEnvioCorreo;
		else template = this.templateTypes.filter((mt) => mt.tipoPlantillaEnvioCorreoId == item.tipoPlantillaEnvioCorreoId)[0];
		this.templateForm.patchValue(item);
		this.templateForm.get("nombre").patchValue(item.nombre);
		this.templateForm.get("tipo").patchValue(template);
		this.templateForm.get("code").patchValue(item.templateCode);
	}
	openUpdateTemplateModal(modalRef, row: PlantillaCorreoEnvio) {
		this.setForm();
		this.cleanFiles();
		this.templateToEdit = new PlantillaCorreoEnvio();
		this.templateToEdit = row;
		this.setTemplateForm(row);
		this.updateTemplateModal = this.modal.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	closeUpdateTemplateModal() {
		this.loading = false;
		this.ref.detectChanges();
		this.updateTemplateModal.close();
	}
	update() {
		this.updateValidators();
		this.loading = true;
		if (this.templateForm.valid) {
			this.templateToEdit.nombre = this.templateForm.get("nombre").value;
			this.templateToEdit.tipoPlantillaEnvioCorreoId = this.exampleModal ? this.correoEnvio.tipoPlantillaEnvioCorreo.tipoPlantillaEnvioCorreoId : this.correoEnvio.tipoPlantillaId;
			this.templateToEdit.file = this.files[0];
			this.templateToEdit.envioCorreoId = this.correoEnvio.envioCorreoId;
			this.templateToEdit.templateCode = this.templateForm.get("code").value;
			if (this.exampleModal) {
				this.correoEnvioService
					.updateExampleTemplate(this.templateToEdit, this.isSengrid)
					.subscribe(
						(res) => {
							Swal.fire({
								icon: "success",
								title: "Plantilla Actualizada",
								text: "Se ha actualizado exitosamente la plantilla " + this.templateToEdit.nombre,
							});
							this.getTemplates();
							this.closeUpdateTemplateModal();
						},
						(err) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "error",
								title: "Error al Actualizar Plantilla",
								text: "No se ha podido actualizar la plantilla " + this.templateToEdit.nombre + ", intente nuevamente",
							});
						}
					)
					.add(() => {
						this.loading = false;
						this.ref.detectChanges();
					});
			} else {
				this.correoEnvioService
					.updateTemplate(this.templateToEdit, this.isSengrid)
					.subscribe(
						(res) => {
							Swal.fire({
								icon: "success",
								title: "Plantilla Actualizada",
								text: "Se ha actualizado exitosamente la plantilla " + this.templateToEdit.nombre,
							});
							this.getTemplates();
							this.closeUpdateTemplateModal();
						},
						(err) => {
							this.loading = false;
							this.ref.detectChanges();
							Swal.fire({
								icon: "error",
								title: "Error al Actualizar Plantilla",
								text: "No se ha podido actualizar la plantilla " + this.templateToEdit.nombre + ", intente nuevamente",
							});
						}
					)
					.add(() => {
						this.loading = false;
						this.ref.detectChanges();
					});
			}
		}
	}

	deleteTemplate(item: PlantillaCorreoEnvio) {
		this.loading = true;
		if (this.exampleModal) {
			this.correoEnvioService.deleteExampleTemplate(item.plantillaEnvioCorreoId).subscribe(
				(res) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Plantilla Eliminada",
						text: "Se ha eliminado exitosamente  la plantilla " + item.nombre,
					});
					this.getTemplates();
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al eliminar Plantilla",
						text: "No fue posible eliminar la plantilla " + item.nombre,
					});
				}
			);
		} else {
			this.correoEnvioService.deleteTemplate(item.plantillaEnvioCorreoId).subscribe(
				(res) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "success",
						title: "Plantilla Eliminada",
						text: "Se ha eliminado exitosamente  la plantilla " + item.nombre,
					});
					this.getTemplates();
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Error al eliminar Plantilla",
						text: "No fue posible eliminar la plantilla " + item.nombre,
					});
				}
			);
		}
	}

	getTemplates() {
		this.loading = false;
		if (this.exampleModal) {
			this.correoEnvioService.getExampleTemplates().subscribe(
				(res) => {
					this.templateList = res.data.plantillasEjemploEnvio;
					if (res.data.plantillasEjemploEnvio.length == 0) {
						Swal.fire({
							icon: "info",
							title: "Sin plantillas",
							text: res.message,
						});
					}
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Plantillas de Ejemplo No Encontradas",
						text: "Error al buscar plantillas de ejemplo de correo " + this.correoEnvio.nombre,
					});
				}
			);
		} else {
			this.correoEnvioService.getTemplates(this.correoEnvio.envioCorreoId).subscribe(
				(res) => {
					this.templateList = res.data;
					if (res.data.length == 0) {
						Swal.fire({
							icon: "info",
							title: "Sin plantillas",
							text: res.message,
						});
					}
				},
				(err) => {
					this.loading = false;
					this.ref.detectChanges();
					Swal.fire({
						icon: "error",
						title: "Plantillas No Encontradas",
						text: "Error al buscar plantillas de correo " + this.correoEnvio.nombre,
					});
				}
			);
		}
	}

	onSelect(event) {
		var x = this.getName(event);
		if (event.rejectedFiles[0] || this.getName(event) != "html") {
			Swal.fire({
				icon: "error",
				title: "Error de Archivo",
				text: "El archivo ingresado no es de formato HTML, favor agregar un archivo con el formato correcto. Razón: " + event.rejectedFiles[0].reason == "size" ? "Tamaño" : "Tipo de archivo",
			});
		}

		this.files.push(...event.addedFiles);
		if (this.files.length > 1) {
			this.files.shift();
		}
		this.templateForm.patchValue(this.files[0]);
		this.templateForm.patchValue(this.files[0]);
	}
	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	getName(event): string {
		if (event.addedFiles) {
			return event.addedFiles[0].name.split(".").pop();
		} else if (event.rejectedFiles) {
			return event.rejectedFiles[0].name.split(".").pop();
		} else {
			return "";
		}
	}

	openDetailTemplateModal(modalRef, row: PlantillaCorreoEnvio) {
		this.templateDetail = new PlantillaCorreoEnvio();
		this.templateDetail = row;
		this.setTemplateForm(row);
		this.detailTemplateModal = this.modal.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});
	}

	closeDetailTemplateModal() {
		this.loading = false;
		this.ref.detectChanges();
		this.detailTemplateModal.close();
	}

	downloadTemplate(item: PlantillaCorreoEnvio) {
		this.loading = true;
		this.correoEnvioService.downloadTemplate(item.plantillaEnvioCorreoId).subscribe(
			(res) => {
				this.HtmlDownload(res.data, item.nombre);
				this.loading = false;
				this.ref.detectChanges();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Descargar Plantilla CSHTML",
					text: "No fue posible descargar la plantilla cshtml " + item.nombre,
				});
			}
		);
	}

	HtmlDownload(responseB64, name) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "text/html");
		FileSaver.saveAs(pdfBlob, name + ".html");
		this.loading = false;
		this.ref.detectChanges();
	}

	getExamples() {
		this.loading = true;
		this.correoEnvioService.getExamples().subscribe(
			(res) => {
				res.data.forEach((item) => {
					this.HtmlDownload(item.csHtmlB64, item.nombre);
				});

				this.loading = false;
				this.ref.detectChanges();
			},
			(err) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Descargar Plantilla CSHTML",
					text: "No fue posible descargar ejemplos de  plantillas cshtml ",
				});
			}
		);
	}

	deactivateTemplate(row: PlantillaCorreoEnvio) {
		this.loading = true;
		this.correoEnvioService.deactivateTemplate(row.plantillaEnvioCorreoId, this.correoEnvio.envioCorreoId).subscribe(
			(result) => {
				this.loading = false;
				// let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
				// this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
				this.getTemplates();
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Plantilla Desactivado",
					text: "La plantilla " + row.nombre + " fue desactivada exitosamente",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Desactivar Plantilla",
					text: "No fue posible desactivar la plantilla " + row.nombre + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	activateTemplate(row: PlantillaCorreoEnvio) {
		this.loading = true;
		this.correoEnvioService.activateTemplate(row.plantillaEnvioCorreoId, this.correoEnvio.envioCorreoId).subscribe(
			(result) => {
				this.loading = false;
				// let currentRow = this.rowsMantenedor.find(plantilla => plantilla.tipoPlantillaId == row.tipoPlantillaId);
				// this.rowsMantenedor.splice(this.rowsMantenedor.indexOf(currentRow),1);
				this.getTemplates();
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Plantilla de Envio Activado",
					text: "La plantilla " + row.nombre + " fue activada exitosamente",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Activar Plantilla",
					text: "No fue posible activar la plantilla " + row.nombre + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
}
