<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1"></div>
        <div class="content-detached">
            <div class="content-body">
                <div class="row">
                    <div class="col-8 offset-2">
                        <div class="card card-custom">
                            <div class="card-body">
                                <h1 class="text-danger">Acceso no autorizado</h1>
                                <p>
                                    Su cuenta no tiene permisos suficientes para acceder al modulo solicitado. Favor
                                    contacte a su administrador.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>