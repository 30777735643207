<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-detached">
			<div class="content-body">
				<app-search-bar [config]="configLocal.barraBusqueda" [filtroDTE]="true" [filtroEstadoPlan]="true" (busqueda)="searchElementsByFilter($event)"></app-search-bar>
				<div class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">Configuración Emisión</h4>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<button class="btn btn-warning float-right mr-5 mt-5" (click)="exportToExcel()" *ngIf="permissions.getPermission('20-40-18').asignado">
										<i class="flaticon-download-1"></i>
										Exportar
									</button>
									<button class="btn btn-success float-right mr-5 mt-5" (click)="openAddConfigModal(addConfigModal)" *ngIf="permissions.getPermission('20-40-10').asignado">
										<i class="flaticon-add-circular-button"></i>
										Asignar configuración DTE
									</button>
									<!-- <button class="btn btn-primary float-right mr-5 mt-5" (click)="openAddCesionConfigModal(addCesionConfigModal)" *ngIf="permissions.getPermission('20-40-20').asignado">
                                        <i class="flaticon-add-circular-button"></i>
                                        Configuración Cesión de Documentos
                                    </button> -->
								</div>
							</div>
							<!-- <div class="col-1" >
                                <ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change) ="setPageSize()">
                                   <option *ngFor="let option of page.pageSizes" [value]="option" ></option>
                                </ng-select>
                           </div> -->
							<div class="card-content">
								<div class="card-body">
									<ngx-datatable #table class="material" [rows]="rowsMantenedor" [columnMode]="'flex'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="70" fxFlex="auto" [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.pageSize" (page)="setPage($event)" [messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<ngx-datatable-column name="tipoDte" [flexGrow]="4" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Tipo de Documento</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.nombreTipoDte }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="tipoPapel" [flexGrow]="2" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Max Lineas</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{ row.maxLineasDetalle }}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Acciones" [flexGrow]="5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a class="primary edit mr-1 mrless" ngbTooltip="Editar Configuración" id="mantenedor-configuracion-emision.component_Editar_Configuración" (click)="openEditConfigModal(editConfigModal, row)" *ngIf="permissions.getPermission('20-40-12').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="pencil-alt" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a class="primary edit mr-1 mrless" ngbTooltip="Detalle Configuración" id="mantenedor-configuracion-emision.component_Detalle_Configuración" (click)="openShowConfigModal(detailConfigModal, row)" *ngIf="permissions.getPermission('20-40-14').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a
													*ngIf="permissions.getPermission('20-40-16').asignado && row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Desactivar Configuración"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de desactivar esta configuración?',
														confirmButtonText: 'Desactivar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="deactivateConfig(row)">
													<div class="icon-circle bg-danger text-center align-middle pt-info-button">
														<fa-icon icon="times-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a
													*ngIf="permissions.getPermission('20-40-17').asignado && !row.activo"
													class="primary edit mr-1 mrless"
													ngbTooltip="Activar Configuración"
													[swal]="{
														title: 'Confirmación',
														text: '¿Está seguro de activar esta configuración?',
														confirmButtonText: 'Activar',
														cancelButtonText: 'Cancelar',
														showCancelButton: true,
														confirmButtonColor: '#F64E60'
													}"
													(confirm)="activateConfig(row)">
													<div class="icon-circle bg-success text-center align-middle pt-info-button">
														<fa-icon icon="check-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #addConfigModal let-d="dismiss" let-c="close">
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Configuración Emisión</div>
				<div class="modal-wizard-desc">Seleccione el tipo de documento a configurar y máximo de lineas</div>
			</div>
		</div>
		<div class="modal-body">
			<app-configuracion-emision-entry [detailMode]="false" (sendCloseRequestEmitter)="closeAddConfigModal()" (sendConfigRequest)="sendNewConfig($event)"></app-configuracion-emision-entry>
		</div>
	</div>
</ng-template>
<ng-template #editConfigModal>
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Configuración Emisión</div>
				<div class="modal-wizard-desc">Modifica los datos de la configuración del tipo documento</div>
			</div>
		</div>
		<div class="modal-body">
			<app-configuracion-emision-entry [detailMode]="false" [noEditTypeDte]="true" [editMode]="true" [configToEdit]="selectedConfig" (sendCloseRequestEmitter)="closeEditConfigModal()" (sendConfigRequest)="editConfigRequest($event)"></app-configuracion-emision-entry>
		</div>
	</div>
</ng-template>
<ng-template #detailConfigModal>
	<div class="modal-lg">
		<div class="modal-header">
			<div class="modal-wizard-label">
				<div class="modal-wizard-title">Configuración Emisión</div>
				<div class="modal-wizard-desc">Se muestran los detalles de la configuración del tipo documento</div>
			</div>
		</div>
		<div class="modal-body">
			<app-configuracion-emision-entry [detailMode]="true" [noEditTypeDte]="true" [configToEdit]="selectedConfig" (sendCloseRequestEmitter)="closeShowConfigModal()"></app-configuracion-emision-entry>
		</div>
	</div>
</ng-template>