import { CurrencyPipe, DatePipe } from "@angular/common";
import { SplitInterpolation, ThrowStmt } from "@angular/compiler";
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { errorMonitor } from "events";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { element } from "protractor";
import { Observable, Subscription, zip } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { DteEmitido } from "src/app/models/Dte/DteEmitido";
import { DteFilter } from "src/app/models/Dte/DteFilter";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { TipoDte } from "src/app/models/tipoDte";
import { Traza } from "src/app/models/Traza";
import { DteEmitidosService } from "src/app/services/dte-emitidos.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { UtilitiesService } from "src/app/services/utilities.service";
import { Page } from "src/app/shared/models/Page";
import { PagedResponse } from "src/app/shared/models/PagedResponse";
import { ConfigMantenedorGuiaDespacho } from "./config-mantenedor-guia-despacho";
import * as FileSaver from "file-saver";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FormrulesConfigService } from "src/app/shared/configuration/formrules-config.service";
import { EmailData } from "src/app/shared/models/EmailData";
import { EmailAttachment } from "src/app/shared/models/EmailAttachment";
import { MultipleEmailValidator } from "src/app/directives/multi-email.directive";
import { NotaCredito } from "src/app/models/NotaCredito";
import { DetalleDte } from "src/app/models/Dte/DetalleDte";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { Response } from "src/app/shared/models/Response";
import * as JSZip from "jszip";
import { SameWordEmailValidator } from "src/app/directives/same-word-email.directive";
import { PermissionService } from "src/app/services/permission.service";
import { InvoiceType } from "src/app/models/Dte/invoiceType.enum";
import { NotaDebito } from "src/app/models/NotaDebito";
import { SendInvoiceRequest } from "src/app/models/CorreoEnvio/SendInvoiceRequest";
import { CorreoEnvioService } from "src/app/services/CorreoEnvio.service";
import { FacturacionGuiaRequest } from "src/app/models/Dte/FacturacionGuiaRequest";
import { AnuladorDteComponent } from "../anulador-dte/anulador-dte.component";
import { FormaPago } from "src/app/models/Dte/FormaPago.enum";
import { TipoTraslado } from "src/app/models/TipoTraslado";
import { TipoDespacho } from "src/app/models/TipoDespacho";
import { ActualizarEstadoSiiRequest } from "src/app/models/Dte/ActualizarEstadoSiiRequest";
import { ReenvioEmailRequest } from "src/app/models/Dte/ReenvioEmailRequest";
import { SortType } from "@swimlane/ngx-datatable";
import { ReutilizacionMasivaRequest } from "src/app/models/Dte/ReutilzacionMasivaRequest";
import { SessionUser } from "src/app/shared/models/sessionUser";
import { EmisorService } from "src/app/services/emisor.service";

@Component({
	selector: "app-mantenedor-guia-despacho",
	templateUrl: "./mantenedor-guia-despacho.component.html",
	styleUrls: ["./mantenedor-guia-despacho.component.scss"],
})
export class MantenedorGuiaDespachoComponent implements OnInit {
	SortType = SortType;
	public tracesModal: NgbModalRef;
	public anuladorModal: NgbModalRef;
	public senderDteModal: NgbModalRef;
	public detailModal: NgbModalRef;
	loading: boolean = false;
	public configLocal = ConfigMantenedorGuiaDespacho;
	public rowsMantenedor: DteEmitido[] = [];
	public selected: DteEmitido[] = []; //dtes seleccionados por checkbox
	public selectedItem: DteEmitido; //dte seleccionado para envio individual y despliegue en modal
	public selectedAllItemsInPage: any = {}; // bool para la seleccion total de elementos en tabla
	public searchRows: any[] = [];
	public searchCriteria;
	fileIdState: Observable<object>;
	fileIdValue: string;
	receptorRutString: string;
	public configScroll: PerfectScrollbarConfigInterface = {};
	public emisorId: string;
	public empresasSource;
	public tipoDteSource: TipoDte[];
	public page = new Page();
	public filter = new DteFilter();
	public tracesToShow: Traza[] = [];
	public dteToShow: DteEmitido = new DteEmitido();
	public dteToNullify: DteEmitido = new DteEmitido();
	public dteToNullifyReferences: DteEmitido[] = [];
	public senderForm: FormGroup;
	public usuarioId: string;
	public idGuide = "";
	@ViewChild("currentSwal") private currentSwal: SwalComponent;
	@ViewChild("notesSwal") private notesSwal: SwalComponent;
	public filterDescription: string;
	private currentSucursal: string = this.tokenService.getSubsidiary();
	private currentUser: string = this.tokenService.getUser().id;
	private guidesToInvoicesModal: any;
	public formaPagoFactGuias: any;
	public formaPagoSource: any[] = [
		{ id: 1, nombreForma: "Contado" },
		{ id: 2, nombreForma: "Credito" },
		{ id: 3, nombreForma: "Sin Costo" },
	];
	private tipoTrasladosSource = JSON.parse(this.localStorage.getTipoTraslados()) as TipoTraslado[];
	private tipoDespachoSource = JSON.parse(this.localStorage.getTipoDespacho()) as TipoDespacho[];
	public componentStarted: boolean = false;
	@ViewChild("tableMobile") tableRef: any;
	public superAdm = false;
	constructor(
		public activatedRoute: ActivatedRoute,
		private dteService: DteEmitidosService,
		private correoEnvioService: CorreoEnvioService,
		private ref: ChangeDetectorRef,
		private tokenService: TokenStorageService,
		private localStorage: LocalStorageService,
		public utilities: UtilitiesService,
		private modalService: NgbModal,
		private datePipe: DatePipe,
		private currencyPipe: CurrencyPipe,
		private formBuilder: FormBuilder,
		private formConfig: FormrulesConfigService,
		public permissions: PermissionService,
		private router: Router,
		private emisorService: EmisorService
	) {
		this.superAdm = this.tokenService.getUser().superAdmin;
		this.usuarioId = (this.tokenService.getUser() as SessionUser).id;
		this.emisorId = this.tokenService.getCompany();
		this.filter.importacionMasivaId = "";
		this.empresasSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		if (this.superAdm) {
			this.getEmisoresFullList();
		}
		this.getGuideId();
		this.checkStateId();
		this.starterPage();
		if (this.fileIdState) {
			this.getFileIdInState();
		}
		this.filter.emisorId = this.emisorId;
		this.filter.sucursalId = this.currentSucursal;
		this.filter.tipoDteId = this.idGuide;
		this.filter.tipoTraslado = "";
		this.filter.tipoDespacho = "";
		this.filter.reutilizado = 0;
		this.filter.facturable = "";
		this.createSenderForm();
	}
	getGuideId() {
		this.tipoDteSource.forEach((element) => {
			if (element.codigosii == 52) this.idGuide = element.tipoDteId;
		});
	}
	checkStateId() {
		this.fileIdState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));
	}
	getFileIdInState() {
		this.loading = true;
		this.fileIdState
			.subscribe((result) => {
				this.fileIdValue = result["paramId"];
				this.receptorRutString = result["receptorRut"];
				this.filterDescription = result["filterDescription"];
				this.filter.importacionMasivaId = this.fileIdValue ? this.fileIdValue : ""; //the parameter for File filtering is added once that obtained from the window state.
				this.filter.rutReceptor = this.receptorRutString ? this.receptorRutString : "";
				this.loading = false;
			})
			.add(() => {
				this.loading = false;
			});
	}

	ngOnInit(): void {
		this.setPage({ offset: 0 });
	}

	ngAfterViewInit() {}
	setSwalAndFire(swalReference: SwalComponent, iconString: SweetAlertIcon, titleString: string, textString: string) {
		swalReference.icon = iconString;
		swalReference.title = titleString;
		swalReference.text = textString;
		swalReference.fire();
	}
	downloadFile() {}
	detailOpenModal(modalRef, row: DteEmitido) {
		this.dteToShow = row;
		this.loading = true;
		this.dteService
			.getReceptorDte(row.dteId)
			.subscribe(
				(res) => {
					this.dteToShow.receptorAsociado = res.data;
				},
				(err) => {
					this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador" + this.utilities.setErrorMessageFromArray(err.error));
				}
			)
			.add(() => {
				this.dteService
					.getDetalleDte(row.dteId)
					.subscribe(
						(res) => {
							this.dteToShow.detalleDte = res.data;
							if (this.dteToShow.detalleDte && this.dteToShow.detalleDte.length > 0) {
								this.dteToShow.impuestos = 0;
								this.dteToShow.detalleDte.forEach((detalle) => {
									this.dteToShow.impuestos += detalle.totalImpuestosAdicionales;
								});
							}
						},
						(err) => {
							this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador" + this.utilities.setErrorMessageFromArray(err.error));
						}
					)
					.add(() => {
						this.loading = false;
						this.ref.detectChanges();
						this.detailModal = this.modalService.open(modalRef, {
							windowClass: "",
							size: "lg",
							centered: true,
						});
					});
			});
	}

	searchElements(emisorId: string) {
		this.loading = true;
		this.dteService.getDataByPageForGuides(this.filter, this.page).subscribe(
			(result) => {
				let elements = result.data as DteEmitido[];
				elements.forEach((row) => {
					this.setEmisorToElement(row);
					this.setTipoDteToElement(row);
				});
				this.rowsMantenedor = [...elements];
			},
			(error) => {
				this.setSwalAndFire(this.currentSwal, "error", "Error al obtener detalles", "No fue posible obtener los detalles del documento indicado, intente mas tarde o comuniquese con su administrador." + this.utilities.setErrorMessageFromArray(error.error));
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	ngOnDestroy() {
		this.fileIdState = undefined;
	}

	changeSearchedRows(data) {
		if (data != null) {
			this.rowsMantenedor = data;
		}
	}

	onSelect({ selected }) {
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
	}

	getId(row) {
		return row.dteId;
	}

	selectAll(event) {
		if (!this.selectedAllItemsInPage[this.page.pageNumber]) {
			if (this.selected.length > 0) {
				this.rowsMantenedor.map((dte) => {
					this.selected = this.selected.filter((selectedDte) => selectedDte.dteId !== dte.dteId);
				});
			}
			this.selected.push(...this.rowsMantenedor);
			this.selected = [...this.selected];
			this.selectedAllItemsInPage[this.page.pageNumber] = true;
		} else {
			this.rowsMantenedor.map((dte) => {
				this.selected = this.selected.filter((selectedDte) => selectedDte.dteId !== dte.dteId);
			});
			this.selectedAllItemsInPage[this.page.pageNumber] = false;
		}
	}

	setEmisorToElement(row: DteEmitido) {
		let empresaAsociada = this.empresasSource.find((Emisor) => Emisor.emisorId == row.emisorId);
		row.emisorAsociado = empresaAsociada;
	}

	setTipoDteToElement(row: DteEmitido) {
		let tipoDteAsociado = this.tipoDteSource.find((tipo) => tipo.tipoDteId == row.tipoDteId);
		row.nombreTipoDte = tipoDteAsociado.abreviacion;
	}

	setTrasladoDespacho(row: DteEmitido) {
		let traslado = this.tipoTrasladosSource.find((t) => t.id == row.guiaDespacho.tipoTraslado);
		let despacho = this.tipoDespachoSource.find((d) => d.id == row.guiaDespacho.tipoDespacho);
		if (traslado) {
			row.guiaDespacho.nombreTipoTraslado = traslado.nombre;
		} else row.guiaDespacho.nombreTipoTraslado = "No informado";
		if (despacho) {
			row.guiaDespacho.nombreTipoDespacho = despacho.nombre;
		} else row.guiaDespacho.nombreTipoDespacho = "No informado";
	}
	starterPage() {
		this.page.pageNumber = 0;
		this.page.pageSize = 10;
		this.selectedAllItemsInPage[this.page.pageNumber] = false;
	}

	searchElementsByFilter(event) {
		//se toman los valores del filtro que necesite el endpoint de filtro para dte
		this.filter = this.filterConcordance(this.configLocal.barraBusqueda.criteriosKeys, this.filter, event);
		this.filter.tipoDteId = this.idGuide;
		this.filter.fechaDesde = event.fechaInicio;
		this.filter.tipoTraslado = event.tipoTraslado ? event.tipoTraslado.toString() : "";
		this.filter.tipoDespacho = event.tipoDespacho ? event.tipoDespacho.toString() : "";
		this.filter.facturable = event.facturable ? event.facturable.toString() : "";
		this.filter.fechaHasta = event.fechaFin;
		this.filter.reutilizado = event.reutilizado ? 1 : 0;
		this.filter[event.criterio] = event.busqueda;
		this.filter = this.utilities.clearOtherFilterCriterias(event.criterio, this.filter, this.configLocal);
		this.filter.estadoSii = event.estadoDte;
		this.filter.emisorId = event.emisorId ? event.emisorId : this.emisorId;
		this.filter.sucursalId = event.sucursalId ? event.sucursalId : this.currentSucursal;
		this.setPage({ offset: 0 });
	}

	filterConcordance(configCriterias, dteFilter: DteFilter, eventFilter): DteFilter {
		//vaciar del dteFilter aquellos campos distinto al presnetado en el eventFilter actual, segun el arreglo configCriterias
		configCriterias.forEach((criteria) => {
			if (criteria != eventFilter.criterio) {
				dteFilter[criteria] = "";
			}
		});
		return dteFilter;
	}
	setPageSize() {
		//this.page.currentPageSize = this.page.page
		this.setPage({ offset: 0 });
	}
	setPage(pageInfo) {
		this.loading = true;
		this.page.pageNumber = pageInfo.offset;
		let localPage = JSON.parse(JSON.stringify(this.page));
		localPage.pageNumber += 1;
		//llamada generica a los dte paginados
		this.dteService
			.getDataByPageForGuides(this.filter, localPage)
			.subscribe(
				(pagedData) => {
					let elements = pagedData.data as DteEmitido[];

					this.ref.detectChanges();
					elements.forEach((row) => {
						this.setEmisorToElement(row);
						this.setTipoDteToElement(row);
						this.setTrasladoDespacho(row);
					});
					this.rowsMantenedor = [...elements];
					this.ref.detectChanges();
					this.setPagefromResponse(this.page, pagedData);
				},
				(error) => {
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en la solicitud";
					this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
					this.ref.detectChanges();
				}
			)
			.add(() => {
				this.loading = false;
				this.componentStarted = true;
				this.ref.detectChanges();
			});
	}

	setPagefromResponse(currentPage: Page, data: PagedResponse<DteEmitido>) {
		currentPage.pageSize = data.pageSize;
		currentPage.pageNumber = data.pageNumber - 1 < 0 ? 0 : data.pageNumber - 1;
		currentPage.totalPages = data.totalPages;
		currentPage.totalElements = data.totalRecords;
		//console.log(" trata respuesta",currentPage);
	}

	downloadXml(dte: DteEmitido) {
		this.dteService.getXml(dte.dteId).subscribe(
			(result) => {
				this.loading = true;
				let b64Response = result.data.xml;
				const blobXml = this.utilities.convertB64ToBlob(b64Response, "text/xml");
				FileSaver.saveAs(blobXml, dte.folio + "_" + "dte.xml");
				this.loading = false;
			},
			(error) => {
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error";
				this.currentSwal.text = this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	downloadPDF(dte: DteEmitido) {
		this.loading = true;
		this.dteService.getPdf(dte.dteId).subscribe(
			(result) => {
				this.responseToPdfDownload(result.data, dte);
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al descargar pdf";
				this.currentSwal.text = "No se pudo generar el documento, intente mas tarde." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			}
		);
	}
	downloadPDFCedible(dte: DteEmitido, cedible: boolean) {
		this.loading = true;
		this.dteService.getPdfCedible(dte.dteId, cedible).subscribe(
			(result) => {
				this.responseToPdfDownload(result.data, dte);
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al descargar pdf cedible";
				this.currentSwal.text = "No se pudo generar el documento, intente mas tarde." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			}
		);
	}
	multipleDocumentDownload(fileType) {
		//"pdf" | "xml"
		if (this.selected.length > 0) {
			let pdfSubscriptions: Observable<Response<any>>[] = [];
			this.selected.forEach((dte) => {
				if (fileType == "pdf") {
					pdfSubscriptions.push(this.getDownloadPdfObservable(dte.dteId));
				} else if (fileType == "xml") {
					pdfSubscriptions.push(this.getDownloadXmlObservable(dte.dteId));
				}
			});
			let groupedSubscription = zip(...pdfSubscriptions);
			this.loading = true;
			groupedSubscription
				.subscribe(
					(resultArray) => {
						let zipToDownload = new JSZip();
						resultArray.forEach((response, i) => {
							if (fileType == "pdf") {
								zipToDownload.file(this.selected[i].folio + "_" + this.selected[i].razonSocial + "_dte." + fileType, response.data, { base64: true });
							} else if (fileType == "xml") {
								zipToDownload.file(this.selected[i].folio + "_" + this.selected[i].razonSocial + "_dte." + fileType, response.data.xml, { base64: true });
							}
						});
						let dateOfDownLoad = this.datePipe.transform(Date.now(), "dd/MM/yyyy");
						zipToDownload.generateAsync({ type: "blob" }).then(function (content) {
							FileSaver.saveAs(content, "documentos_" + dateOfDownLoad + ".zip");
						});
						this.currentSwal.icon = "success";
						this.currentSwal.title = "Descarga de documentos";
						this.currentSwal.text = "Los documentos seleccionados se han descargado";
						this.currentSwal.fire();
					},
					(err) => {
						this.currentSwal.icon = "error";
						this.currentSwal.title = "Error en exportación";
						this.currentSwal.text = "No ha sido posible descargar los documentos seleccionados.\nFavor intentar mas tar de o contactar a su administrador.";
						if (err.error.errors) {
							this.currentSwal.text += this.utilities.setErrorMessageFromArray(err.error);
						}
						this.currentSwal.fire();
					},
					() => {
						this.loading = false;
					}
				)
				.add(() => {
					this.loading = false;
					this.ref.detectChanges();
				});
		} else {
			//show error message for not selecting elements
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error de Selección";
			this.currentSwal.text = "No se ha seleccionado ningún documento para envío.\nFavor seleccionar un documento con la columna selectora.";
			this.currentSwal.fire();
		}
	}

	getDocumentSubscription(dteId: string, docType: string): Subscription {
		switch (docType) {
			case "pdf":
				return this.dteService.getPdf(dteId).subscribe();
				break;
			case "pdf":
				break;
			case "both":
				break;
			default:
				break;
		}
	}
	getDownloadXmlObservable(id: string): Observable<Response<any>> {
		return this.dteService.getXml(id);
	}
	getDownloadPdfObservable(id: string): Observable<Response<any>> {
		return this.dteService.getPdf(id);
	}

	responseToPdfDownload(responseB64, currentDte: DteEmitido) {
		const pdfBlob = this.utilities.convertB64ToBlob(responseB64, "application/pdf");
		FileSaver.saveAs(pdfBlob, currentDte.folio + "_dte.pdf");
		this.loading = false;
		this.ref.detectChanges();
	}
	convertStreamToFileDownload(responseB64, currentDte: DteEmitido, fileType: string, extensionFile: string) {
		const docBlob = this.utilities.convertB64ToBlob(responseB64, fileType);
		FileSaver.saveAs(docBlob, currentDte.folio + "_" + currentDte.razonSocial + "_dte." + extensionFile);
	}

	openDocumentSenderModal(modalRef, row?: DteEmitido) {
		if (row) {
			this.selectedItem = row;
		} else if (this.selected.length > 0) {
			this.selectedItem = undefined;
		} else {
			this.currentSwal.icon = "error";
			this.currentSwal.title = "Error de Selección";
			this.currentSwal.text = "No se ha seleccionado ningún documento para envío.\nFavor seleccionar un documento con la columna selectora o usar la emisión individual desde el icono de 'Enviar Correo'";
			this.currentSwal.fire();
			return;
		}

		this.senderDteModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
			centered: true,
		});

		this.senderDteModal.closed.subscribe((res) => {
			this.senderForm.reset();
		});
		this.senderDteModal.dismissed.subscribe((res) => {
			this.senderForm.reset();
		});
	}

	createSenderForm() {
		this.senderForm = this.formBuilder.group(
			{
				receiverEmail: [
					"",
					[
						Validators.required,
						//Validators.pattern(this.formConfig.emailPatternRegex),
						MultipleEmailValidator,
						Validators.maxLength(320),
					],
				],
				ccEmail: [
					"",
					[
						//Validators.pattern(this.formConfig.emailPatternRegex),
						MultipleEmailValidator,
						Validators.maxLength(320),
					],
				],
				ccoEmail: [
					"",
					[
						//Validators.email,
						//Validators.pattern(this.formConfig.emailPatternRegex),
						MultipleEmailValidator,
						Validators.maxLength(320),
					],
				],
				sendPdfCheckbox: [true],
				sendXmlCheckbox: [true],
			},
			{ validators: SameWordEmailValidator }
		);
	}

	onSubmitSenderForm() {
		if (this.senderForm.valid) {
			let emailReceivers: string[] = this.senderForm.controls.receiverEmail.value.toString().split(",");
			let emailsCCs: string[] = this.senderForm.controls.ccEmail.value ? this.senderForm.controls.ccEmail.value.toString().split(",") : [];
			let emailCCOs: string[] = this.senderForm.controls.ccoEmail.value ? this.senderForm.controls.ccoEmail.value.toString().split(",") : [];
			var mailRequest = new SendInvoiceRequest();
			mailRequest.ccos = emailCCOs;
			mailRequest.ccs = emailsCCs;
			mailRequest.dteId = this.selectedItem.dteId;
			mailRequest.dteType = this.selectedItem.codigoTipoDte;
			mailRequest.to = emailReceivers;
			mailRequest.emailType = 3;
			mailRequest.pdf = this.senderForm.controls.sendPdfCheckbox.value ? true : false;
			mailRequest.xml = this.senderForm.controls.sendXmlCheckbox.value ? true : false;
			if (mailRequest.pdf || mailRequest.xml) {
				if (!this.selectedItem && this.selected.length == 1) {
					this.sendDteDocuments(mailRequest);
				} else if (this.selectedItem) {
					this.sendDteDocuments(mailRequest);
				} else if (this.selected.length != 1) {
					//opcion temporal para solicitar solo un elemento
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error de Selección";
					this.currentSwal.text = "Solo puede seleccionar un elemento para el envío.\nFavor seleccionar un documento con la columna selectora o usar la emisión individual desde el icono de 'Enviar Correo'";
					this.currentSwal.fire();
					return;
				}
			} else {
				Swal.fire({
					title: "Documentos No Seleccionados",
					text: "Favor indicar por lo menos un documento a enviar",
					icon: "info",
				});
				return;
			}
		}
	}

	onSubmitEmail() {
		// email request to backend handling
		//valid form data
		if (this.senderForm.valid) {
			//get dtes id array
			let dteIdList: Array<string> = [];
			if (this.selectedItem) {
				dteIdList.push(this.selectedItem.dteId);
			} else if (this.selected.length > 0) {
				this.selected.forEach((dte) => {
					dteIdList.push(dte.dteId);
				});
			}
			//attach xml pdf or both seing boolean in request
			let sendingPdf: boolean = this.senderForm.controls.sendPdfCheckbox.value;
			let sendingXml: boolean = this.senderForm.controls.sendXmlCheckbox.value;
			//attach array of email addresses
			let arrayReceiversEmails: string[] = this.senderForm.controls.receiverEmail.value.toString().split(",");
			let arrayCopyEmails: string[] = this.senderForm.controls.ccEmail.value.toString().split(",");
			let arrayBlindCopyEmails: string[] = this.senderForm.controls.ccoEmail.value.toString().split(",");
		}
	}

	// setDataAttachmentToRequest(
	//   req: DteEmailRequest,
	//   dteToSend: DteEmitido,
	//   fileString: string,
	//   extensionString: string
	// ) {
	//   let docDate = this.datePipe.transform(dteToSend.fecha, "dd/MM/yyyy");
	//   let docName =
	//     dteToSend.folio +
	//     "_" +
	//     dteToSend.rutReceptor +
	//     "_" +
	//     docDate +
	//     "_" +
	//     dteToSend.razonSocial +
	//     extensionString;
	//   let nombreCompletoTipoDte:TipoDte = this.tipoDteSource.find(tipo => tipo.tipoDteId == dteToSend.tipoDteId);
	//   req.data = new EmailData(docName, docDate,dteToSend.razonSocial,nombreCompletoTipoDte.nombre,dteToSend.folio.toString(),dteToSend.rutReceptorDv,dteToSend.total);
	//   req.attachments = [new EmailAttachment(docName, fileString)];
	// }

	sendDteDocuments(request: SendInvoiceRequest) {
		this.loading = true;
		this.ref.detectChanges();
		this.correoEnvioService.sendEmail(request).subscribe(
			(result) => {
				let resumenEnvioCorreo: string = "";
				resumenEnvioCorreo = "Se envió el documento a las siguientes direcciones:<br>" + request.to + "<br>";
				if (request.ccs[0] != "") {
					if (request.ccs.length > 0) {
						resumenEnvioCorreo += "<br> Con copia a las siguientes direcciones:<br>" + request.ccs + "<br>";
					}
				}
				if (request.ccos[0] != "") {
					if (request.ccos.length > 0) {
						resumenEnvioCorreo += "<br> Con copia oculta para las siguientes direcciones:<br>" + request.ccos;
					}
				}
				Swal.fire({
					icon: "success",
					title: "Envío exitoso",
					showCloseButton: true,
					html: "<div style='text-align:center;'><p>" + resumenEnvioCorreo + "</p></div>",
				});
				this.senderDteModal.close();
				this.senderForm.reset();
				this.senderForm.controls.ccEmail.patchValue("");
				this.senderForm.controls.ccoEmail.patchValue("");
				this.senderForm.controls.receiverEmail.patchValue("");
				this.loading = false;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error en el envío";
				this.currentSwal.text = "No fue posible enviar el documento." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	async getPdfForEmailRequest(dteId: string, pdfStringRef: string) {
		pdfStringRef = await (await this.dteService.getPdf(dteId).toPromise()).data;
		return pdfStringRef;
	}

	getXml(dte: DteEmitido) {
		this.dteService.getXml(dte.dteId).subscribe(
			(result) => {
				this.loading = true;
				let b64Response = result.data.xml;
				const blobXml = this.utilities.convertB64ToBlob(b64Response, "text/xml");
				let blob = new Blob([blobXml], { type: "text/xml" });
				let fileURL = URL.createObjectURL(blobXml);
				this.popupCenter(fileURL, "Documentos Tributario Electrónico", 600, 800);
				this.loading = false;
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error en XML";
				this.currentSwal.text = "No fue posible obtener el XML." + this.utilities.setErrorMessageFromArray(error.error);
				this.currentSwal.fire();
				this.loading = false;
			},
			() => {
				this.loading = false;
			}
		);
	}

	popupCenter(url, title, w, h) {
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top = (height - h) / 2 / systemZoom + dualScreenTop;
		const newWindow = window.open(
			url,
			title,
			`
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
		);
		if (window.focus) {
			newWindow.focus();
		}
	}

	navigateToFacturador(type: InvoiceType) {
		this.router.navigateByUrl("facturador-individual", {
			state: { invoiceType: type },
		});
	}

	exportToExcel() {
		this.getAllRowsByFilter(this.page, this.filter);
	}
	getAllRowsByFilter(currentPageRef: Page, currentFilter: DteFilter) {
		let fullPage: Page = new Page();
		fullPage.pageSize = currentPageRef.totalElements;
		fullPage.pageNumber = 0;
		fullPage.totalElements = currentPageRef.totalElements;
		fullPage.totalPages = 1;
		this.loading = true;
		this.dteService.getDataByPageForGuides(currentFilter, fullPage).subscribe(
			(result) => {
				let elements = result.data as DteEmitido[];
				this.ref.detectChanges();
				elements.forEach((row) => {
					this.setEmisorToElement(row);
					this.setTipoDteToElement(row);
				});
				this.loading = false;
				this.ref.detectChanges();
				this.exportExcelData(elements);
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				this.currentSwal.icon = "error";
				this.currentSwal.title = "Error al exportar";
				this.currentSwal.text = "No es posible exportar los datos. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error);
			},
			() => {
				this.loading = false;
				this.ref.detectChanges();
			}
		);
	}

	exportExcelData(arrayToExport: Array<DteEmitido>): void {
		let arregloExportar = [];
		arrayToExport.forEach((row) => {
			let elementToExport = {};
			elementToExport["Tipo DTE"] = row.nombreTipoDte;
			elementToExport["Folio"] = row.folio;
			elementToExport["Emisor"] = row.emisorAsociado.razonSocial;
			elementToExport["Rut Cliente"] = row.rutReceptor;
			elementToExport["Razon Social Cliente"] = row.razonSocial;
			elementToExport["Fecha de Emisión"] = this.datePipe.transform(row.fecha, "dd/MM/yyyy");
			if (row.nombreTipoDte === "Nota de Crédito Electrónica") {
				elementToExport["Monto"] = Number(row.total * -1);
			} else {
				elementToExport["Monto"] = row.total;
			}
			arregloExportar.push(elementToExport);
		});
		this.utilities.exportAsExcelFile(arregloExportar, "Dte_emitidos");
	}

	commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
		const emails = control.value.split(",").map((e) => e.trim());
		const forbidden = emails.some((email) => this.formConfig.emailPatternRegex.test(email));
		return forbidden ? { toAddress: { value: control.value } } : null;
	};
	openTraceShowModal(modalRef, dte: DteEmitido) {
		this.dteToShow = dte;
		this.loading = true;
		this.dteService
			.getTrace(this.dteToShow.dteId)
			.subscribe(
				(result) => {
					this.tracesToShow = result.data as Traza[];
					this.loading = false;
					this.ref.detectChanges();
				},
				(error) => {
					this.loading = false;
					this.currentSwal.icon = "error";
					this.currentSwal.title = "Error en Trazabilidad";
					this.currentSwal.text = "No es posible obtener la trazabilidad de este documento. Intente mas tarde. " + this.utilities.setErrorMessageFromArray(error.error);
					this.currentSwal.fire();
				},
				() => {
					this.loading = false;
				}
			)
			.add(() => {
				this.loading = false;
				this.tracesModal = this.modalService.open(modalRef, {
					windowClass: "",
					size: "lg",
					centered: true,
				});
			});
	}

	sendGuidesToInvoiceModal(modalRef) {
		this.formaPagoFactGuias = [];
		if (this.selected == null || this.selected.length < 1) {
			Swal.fire({
				icon: "error",
				title: "Error en selección",
				text: "Seleccione por lo menos una guía de despacho para ser facturada",
			});
			return;
		} else if (!this.selected.every((g) => g.receptorId == this.selected[0].receptorId)) {
			Swal.fire({
				icon: "error",
				title: "Error en selección",
				text: "Seleccione guias de despacho emitidas para un mismo receptor",
			});
			return;
		} else if (!this.selected.every((g) => g.guiaDespacho.tipoTraslado == 1)) {
			Swal.fire({
				icon: "error",
				title: "Error en selección",
				text: "Seleccione guias de despacho emitidas que constituyan venta",
			});
			return;
		} else if (!this.selected.every((g) => g.estadoSii == 6 || g.estadoSii == 7)) {
			Swal.fire({
				icon: "error",
				title: "Error en selección",
				text: "Seleccione guias de despacho que tengan algún estado de aceptación favorable en SII",
			});
			return;
		} else if (this.selected.some((g) => g.guiaDespacho.facturableId == "2")) {
			let facturadas = this.selected.filter((g) => g.guiaDespacho.facturableId == "2");
			let concatenaFolios = "";
			facturadas.map((g) => (concatenaFolios += g.folio + ","));
			concatenaFolios = concatenaFolios.substring(0, concatenaFolios.length - 1);
			Swal.fire({
				icon: "error",
				title: "Error en selección",
				text: "Seleccione guias de despacho que no hayan sido facturadas previamente. Guia de Despacho Facturada: " + concatenaFolios,
			});
			return;
		}
		this.guidesToInvoicesModal = this.modalService.open(modalRef, {
			windowClass: "",
			size: "lg",
		});
	}

	sendGuidesToInvoicing(ngForm) {
		if (!ngForm.valid) return;
		let arrayIds = this.selected.map((dte) => dte.dteId);
		let facturacionRequest: FacturacionGuiaRequest = new FacturacionGuiaRequest();
		facturacionRequest.dtes = arrayIds;
		facturacionRequest.sucursalId = this.currentSucursal;
		facturacionRequest.usuarioId = this.currentUser;
		facturacionRequest.formaPago = this.formaPagoFactGuias.id;
		this.loading = true;
		this.dteService.guidesToInvoices(facturacionRequest).subscribe(
			(result) => {
				this.loading = false;
				this.ref.detectChanges();
				this.guidesToInvoicesModal.close();
				Swal.fire({
					icon: "success",
					title: "Facturación Exitosa",
					text: "La facturación de la o las guias seleccionadas se realizó con exito.",
				});
				this.router.navigateByUrl("/documentos-emitidos");
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al facturar guías de despacho",
					text: "No fue posible facturar la o las guias de despacho seleccionadas. Intente nuevamente. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}

	createHtmlGuidesList() {
		let response = "<div style='text-align:center'><ul>";
		this.selected.forEach((dte, i) => {
			if (i <= 10) {
				response += "<li style='text-align:left'>Folio: " + dte.folio + " - " + "Monto: " + this.currencyPipe.transform(dte.total, "$ ", "symbol", "1.0-0", "es") + " - Fecha: " + this.datePipe.transform(dte.fecha, "dd/MM/yyyy") + "</li>";
			}
		});
		// if (this.selected.length > 10) {
		//    let restantes = this.selected.length - 10;
		//    response += "<li style='text-align:left'>Otros " + restantes + " documentos seleccionados...</li>";
		// }
		response += "</ul></div>";
		return response;
	}

	resendDte(dte: DteEmitido) {
		this.loading = true;
		var request = new ActualizarEstadoSiiRequest();
		request.Dtes = [dte.dteId];
		request.usuarioId = this.tokenService.getUser().id;
		request.emisorId = this.emisorId;
		this.dteService.resendDte(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Estado Sii Actualizado",
					text: "El documento volverá a ser enviado al servicio de impuestos internos",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Actualizar Estado Sii",
					text: "No fue posible actualizar el estado sii del documento. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	toggleExpandRow(row) {
		this.tableRef.rowDetail.toggleExpandRow(row);
	}

	resendEmailSii(dte: DteEmitido) {
		this.loading = true;
		var request = new ReenvioEmailRequest();
		request.dteId = dte.dteId;
		request.usuarioId = this.tokenService.getUser().id;
		request.emisorId = this.emisorId;
		this.dteService.resendEmailSii(request).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Reenvio de Email Validación Sii",
					text: "La validación del documento desde el sii fue reenviada al email registrado en el servicio de impuestos internos",
				});
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al solicitar reenvio de correo de validación",
					text: "No fue posible solicitar el reenvio del correo de validación desde el sii. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	reuse(dte: DteEmitido) {
		this.loading = true;
		this.dteService.reuse(dte.dteId).subscribe(
			(res) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "success",
					title: "Reutilización de Folio",
					text: res.message,
				});
				this.setPage({ offset: 0 });
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error al Solicitar Reutilización de Folio",
					text: "No fue posible solicitar la reutilización de folio. " + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
	tryMassiveRouseConfirm() {
		if (this.selected.length > 0) {
			var valid = false;
			var ids: string[] = [];
			var invalidList = this.selected.filter((x) => x.estadoSii == 6 || x.estadoSii == 7);
			if (invalidList.length > 0) {
				Swal.fire({
					icon: "error",
					title: "Error de Selección",
					text: "Solo se pueden reutilizar folios de documentos que no estén aceptados por el sii",
				});
				return;
			}
			Swal.fire({
				icon: "info",
				title: "Reutilizar folios",
				text: "¿Está seguro de reutilizar los folios de los documentos seleccionados?",
				confirmButtonText: "Continuar",
				confirmButtonColor: "#1BC5BD",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				cancelButtonColor: "#F64E60",
			}).then((res) => {
				if (res.isConfirmed) {
					this.massiveReuse();
				} else {
					return;
				}
			});
		} else {
			Swal.fire({
				icon: "error",
				title: "Error de Selección",
				text: "No se ha seleccionado ningún documento válido para reutilizar.\nFavor seleccionar un documento con la columna selectora.",
			});
		}
	}
	massiveReuse() {
		if (this.selected.length > 0) {
			var valid = false;
			var ids: string[] = [];
			var invalidList = this.selected.filter((x) => x.estadoSii == 6 || x.estadoSii == 7);
			if (invalidList.length > 0) {
				Swal.fire({
					icon: "error",
					title: "Error de Selección",
					text: "Solo se pueden reutilizar folios de documentos que no estén aceptados por el sii",
				});
			} else {
				this.selected.forEach((dte) => {
					ids.push(dte.dteId);
				});
				this.loading = true;
				var request = new ReutilizacionMasivaRequest();
				request.ids = ids;
				request.usuarioId = this.usuarioId;
				this.dteService.massivereuse(request).subscribe(
					(res) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "success",
							title: "Reutilización de Folios Masivo",
							text: res.message,
						});
						this.setPage({ offset: 0 });
						this.selected = [];
					},
					(error) => {
						this.loading = false;
						this.ref.detectChanges();
						Swal.fire({
							icon: "error",
							title: "Error al Solicitar Reutilización de Folios Masivo",
							text: "No fue posible solicitar la reutilización de folios masivo. " + this.utilities.setErrorMessageFromArray(error.error),
						});
					}
				);
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Error de Selección",
				text: "No se ha seleccionado ningún documento valido para reutilizar.\nFavor seleccionar un documento con la columna selectora.",
			});
		}
	}
	getEmisoresFullList() {
		this.emisorService.getData().subscribe(
			(result) => {
				let emisores = result.data;
				this.empresasSource = emisores;
				this.ref.detectChanges();
			},
			(error) => {
				this.loading = false;
				this.ref.detectChanges();
				Swal.fire({
					icon: "error",
					title: "Error en emisores",
					text: "No fue posible obtener la lista de emisores completa, tiene disponible solamente los emisores ya presentes en el selector de empresas." + this.utilities.setErrorMessageFromArray(error.error),
				});
			}
		);
	}
}
