import { Component, OnInit, EventEmitter, Input, Output, Renderer2, ViewChild, ElementRef, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators, NgForm, NgControlStatus } from "@angular/forms";
import { Emisor } from "src/app/models/Emisor/Emisor";
import { TipoDte } from "src/app/models/tipoDte";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { EstadoDte } from "src/app/models/Dte/EstadosDte.enum";
import * as moment from "moment";
import { OrigenImportacionMasiva } from "src/app/models/MassInvoicing/OrigenImportacionMasiva";
import { Facturable } from "src/app/models/Facturable";
import { TipoTraslado } from "src/app/models/TipoTraslado";
import { EmbeddedTemplateAst, NullTemplateVisitor } from "@angular/compiler";
import { TipoDespacho } from "src/app/models/TipoDespacho";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { fromEvent } from "rxjs";
import { PermissionService } from "src/app/services/permission.service";
import { VerificacionRutValidator } from "src/app/directives/verifica-rut.directive";
import { Usuario } from "src/app/models/Usuario/Usuario";
import { TokenStorageService } from "src/app/services/token-storage.service";
import { TipoCorreoEnvio } from "src/app/models/CorreoEnvio/TipoCorreoEnvio";
import { TipoPlantillaCorreoEnvio } from "src/app/models/CorreoEnvio/TipoPlantillaCorreoEnvio";
import { EstadoSii } from "src/app/models/EstadoSii";
import { Sucursal } from "src/app/models/Sucursal";
import { EmisorService } from "src/app/services/emisor.service";
import { SucursalFilter } from "src/app/models/Usuario/SucursalFilter";
import { Page } from "src/app/shared/models/Page";
import { PageSizes } from "src/app/_metronic/shared/crud-table";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { EstadoAcuseEmitido } from "src/app/models/Dte/EstadoAcuseEmitido";
import { Region } from "src/app/models/Region/Region";
import { Comuna } from "src/app/models/Region/Comuna";
import { TriState } from "../tri-state-checkbox/tri-state-checkbox/tri-state-checkbox.component";
import { UtilitiesService } from "src/app/services/utilities.service";
import { FacturacionIndividualService } from "src/app/services/facturacion-individual.service";
import { TipoReferencium } from "src/app/models/Proveedor/TipoReferencium";
moment.locale("es");
@Component({
	selector: "app-search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
	locale = {
		daysOfWeek: moment.weekdaysMin(),
		monthNames: moment.monthsShort(),
		firstDay: moment.localeData().firstDayOfWeek(),
		applyLabel: "ok",
		clearLabel: "Limpiar",
		format: "DD-MM-YYYY",
	};
	filtrosBusqueda: FormGroup;
	submitted = false;
	isSuperAdm = false;
	sucursalesUsuario: Sucursal[] = [];
	constructor(private formBuilder: FormBuilder, private _renderer: Renderer2, private utilities: UtilitiesService, private localStorage: LocalStorageService, public permissions: PermissionService, private tokenService: TokenStorageService, private emisorService: EmisorService, private ref: ChangeDetectorRef) {
		this.isSuperAdm = this.tokenService.getUser().superAdmin;
		this.sucursalesUsuario = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
	}
	@ViewChild(DaterangepickerDirective, { static: false })
	MainDatePicker: DaterangepickerDirective;
	@ViewChild("mainPicker", { static: false }) mainPickerRef: ElementRef;

	@Input() config: any;
	@Input() public criterioPrincipal: String;
	@Input() fechaInicio: Date = null;
	@Input() fechaFin: Date;
	@Input() fechaVencimientoInicio: Date;
	@Input() fechaVencimientoFin: Date;
	@Input() fechaRecepcionInicio: Date;
	@Input() fechaRecepcionFin: Date;
	@Output() busqueda = new EventEmitter<any>();
	@Input() filtroDTE: boolean;
	@Input() filtroDTEProveedor: boolean = false;
	@Input() filtroDTEExportacion: boolean = false;
	@Input() filtroEstadoSii: boolean;
	@Input() filtroEmisor: boolean;
	@Input() filtroSucursal: boolean;
	@Input() filtroEstadoDte?: boolean;
	@Input() filtroRespuestaEstadoDte?: boolean;
	@Input() filtroImportado?: boolean = false;
	@Input() filtroReutilizado?: boolean = false;
	@Input() filtroAnulados?: boolean = false;
	@Input() filtroEstadoPlan?: boolean = null;
	@Input() filtroOrigenImportacion?: boolean = false;
	@Input() filtroTipoTraslados?: boolean = false;
	@Input() filtroTipoEnvio?: boolean = false;
	@Input() filtroTipoPlantillaEnvio?: boolean = false;
	@Input() filtroTipoDespacho?: boolean = false;
	@Input() filtroFacturables?: boolean = false;
	@Input() filtroPais?: boolean = false;
	@Input() filtroPaisInline?: boolean = false;
	@Input() insideModal: boolean = false;
	@Input() registroActivacion: boolean = false;
	@Input() productoExento: boolean = false;
	@Input() nombreCampoDatePicker: string = "Rango de Fechas";
	@Input() mostrarListaCompletaEmisores: boolean = false;
	@Input() listaCompletaEmisores: Emisor[] = [];
	@Input() emisorBusquedaDefault: string = undefined;
	@Input() filtroUsuarios: boolean = false;
	@Input() listaCompletaUsuarios: Usuario[] = [];
	@Input() searchByCurrentEmisorOnly: boolean = false;
	@Input() emisorClearable: boolean = true;
	@Input() sucursalClearable: boolean = true;
	@Input() showRegiones: boolean = false;
	public regionesSource: Region[] = [];
	@Input() showComunas: boolean = false;
	public comunasSource: Comuna[] = [];
	public emisoresSource: Emisor[];
	public sucursalSeleccionada: Sucursal;
	public sucursalesSource: Sucursal[];
	public tipoDteSource: TipoDte[];
	public estadosPlanSource: string[];
	public origenImportacionesSource: OrigenImportacionMasiva[];
	public tipoTrasladosSource: TipoTraslado[];
	public tipoDespachoSource: TipoDespacho[];
	public tipoEnvioSource: TipoCorreoEnvio[];
	public tipoPlantillaEnvioSource: TipoPlantillaCorreoEnvio[];
	public facturablesSource: Facturable[];
	public selectedRangeDate;
	public selectedRangeDateExpiration;
	public selectedRangeDateReception;
	private criteriosKeys;
	public EstadosDte: EstadoSii[] = [];
	public filtroProductoModal = false;
	fechaMin: Date = new Date("2000-01-02");
	@Input() public useAccordionMode: boolean = false;
	@Input() public useInlineSearchButton: boolean = false;
	@Input() public useInlineDeactivatedCheckbox: boolean = false;
	@Input() public extraLengthGenericInput: boolean = false;
	@Input() public filtroDteReferencias: boolean = false;
	@Input() public useDespliegueCheckbox: boolean = false;
	@Input() public useInlineDespliegueCheckbox: boolean = false;
	public clicked: boolean = false;
	public allOwnSucursalesSource = JSON.parse(this.localStorage.getSucursales()) as Sucursal[];
	public loading: boolean = null; // used for direct request of data and disabling search button
	@Input() public filtroAcuseEmitidos: boolean = false;
	public estadoAcuseEmitidosSource: EstadoAcuseEmitido[] = [];
	ngOnInit(): void {
		this.filtroProductoModal = this.registroActivacion && this.productoExento && this.insideModal;
		if (this.filtroTipoTraslados) {
			this.tipoTrasladosSource = JSON.parse(this.localStorage.getTipoTraslados()) as TipoTraslado[];
		}
		if (this.filtroTipoEnvio) {
			this.tipoEnvioSource = JSON.parse(this.localStorage.getTipooCorreoEnvio()) as TipoCorreoEnvio[];
		}
		if (this.filtroTipoPlantillaEnvio) {
			this.tipoPlantillaEnvioSource = JSON.parse(this.localStorage.getTipoPlantillaoCorreoEnvio()) as TipoPlantillaCorreoEnvio[];
		}
		if (this.filtroTipoDespacho) {
			this.tipoDespachoSource = JSON.parse(this.localStorage.getTipoDespacho()) as TipoDespacho[];
		}
		if (this.filtroFacturables) {
			this.facturablesSource = JSON.parse(this.localStorage.getFacturables()) as Facturable[];
		}
		if (this.filtroDTE) {
			this.tipoDteSource = JSON.parse(this.localStorage.getDteTypes()) as TipoDte[];
		}
		if (this.filtroEstadoPlan) {
			this.estadosPlanSource = ["Activado", "Desactivado"];
		}
		if ((this.filtroEmisor && !this.mostrarListaCompletaEmisores) || (this.filtroEmisor && this.listaCompletaEmisores.length == 0)) {
			this.emisoresSource = JSON.parse(this.localStorage.getEmisores()) as Emisor[];
		} else if (this.filtroEmisor && this.mostrarListaCompletaEmisores && this.listaCompletaEmisores.length > 0) {
			this.emisoresSource = this.listaCompletaEmisores;
		}
		this.sucursalesSource = this.allOwnSucursalesSource;
		//console.log("sucursales al inicio",this.sucursalesSource);
		if (this.isSuperAdm && this.filtroEmisor && this.mostrarListaCompletaEmisores && this.filtroSucursal) {
			// let allSucursales = this.localStorage.getAllSucursales();
			// //console.log("todas las sucursales",allSucursales);
			// if(!allSucursales){
			this.consultaSucursalCompleto();
			// }else{
			//   this.allOwnSucursalesSource = allSucursales;
			//   this.sucursalesSource = this.allOwnSucursalesSource.filter(suc => suc.emisorId == this.tokenService.getCompany());
			// }
		}
		if (!this.isSuperAdm && this.sucursalesUsuario.length > 0) {
			var sucursalesNoAdm: Sucursal[] = [];
			this.sucursalesSource.forEach((user) => {
				this.sucursalesUsuario.forEach((sucursal) => {
					if (sucursal.sucursalId == user.sucursalId && sucursalesNoAdm.find((x) => x.sucursalId == sucursal.sucursalId) == null) sucursalesNoAdm.push(sucursal);
				});
			});
			this.sucursalesSource = sucursalesNoAdm;
		}
		this.sucursalSeleccionada = this.sucursalesSource.find((x) => x.sucursalId == this.tokenService.getSubsidiary());

		if (this.filtroEstadoSii) {
			// let estados = Object.keys(EstadoDte).map((key) => ({
			//   key,
			//   name: EstadoDte[key],
			// }));
			this.EstadosDte = JSON.parse(this.localStorage.getEstadosSii()) as EstadoSii[];
			this.EstadosDte.forEach((item) => {
				if (item.estadoId == 1) item.descripcion = "DTE Emitido";
				if (item.descripcion.toLowerCase().includes("Documento".toLowerCase())) item.descripcion = item.descripcion.toLowerCase().replace("Documento".toLowerCase(), "DTE");
			});
		}
		if (this.filtroOrigenImportacion) {
			let origenesImportaciones = JSON.parse(this.localStorage.getOrigenImportaciones()) as OrigenImportacionMasiva[];
			this.origenImportacionesSource = origenesImportaciones;
		}
		if (this.filtroDTE && this.filtroEstadoDte && !this.filtroDTEExportacion) {
			//filter for main dte grid, it's filter the documents type related to export documents
			this.tipoDteSource = this.tipoDteSource.filter((tipo) => tipo.codigosii < 110 && tipo.codigosii != 52 && tipo.codigosii != 46);
		}
		if (this.filtroDTE && this.filtroDTEExportacion) {
			//filter for main dte grid, it's filter the documents type related to export documents
			this.tipoDteSource = this.tipoDteSource.filter((tipo) => tipo.codigosii >= 110 && tipo.codigosii != 52 && tipo.codigosii != 46);
		}
		if (this.filtroDTE && !this.filtroDTEProveedor) {
			//filter for main dte grid, it's filter the documents type related to export documents
			this.tipoDteSource = this.tipoDteSource.filter((tipo) => tipo.codigosii > 0);
		}
		if (this.filtroAcuseEmitidos) {
			this.estadoAcuseEmitidosSource = this.localStorage.getEstadoAcuseEmitidos();
		}
		if (this.showRegiones) {
			this.regionesSource = this.localStorage.getRegiones();
			this.utilities.sortAlphabeth(this.regionesSource, "nombre");
		}
		this.filtrosBusqueda = this.formBuilder.group({
			campoBusqueda: [""],
			criterios: [""],
			fechaInicio: this.fechaInicio,
			fechaFin: this.fechaFin,
			fechaVencimientoInicio: this.fechaVencimientoInicio,
			fechaVencimientoFin: this.fechaVencimientoFin,
			fechaRecepcionInicio: this.fechaRecepcionInicio,
			fechaRecepcionFin: this.fechaRecepcionFin,
			tiposDTE: [null],
			estadosDteRecibido: [null],
			categoriaItem: [null],
			tipoItem: [null],
			estadosPlan: [null],
			emisor: [null],
			sucursal: [null],
			usuario: [null],
			respuestaEstadoDte: [null],
			estadoDte: [null],
			origenImportacion: [null],
			tipoTraslado: [null],
			tipoDespacho: [null],
			tipoEnvioCorreo: [null],
			tipoPlantillaEnvioCorreo: [null],
			facturable: [null],
			productoExento: [null],
			registroActivo: [null],
			registroDespliegue: [null],
			pais: [null],
			paisInline: [null],
			puerto: [null],
			clausulaVenta: [null],
			modalidadVenta: [null],
			importado: [null],
			reutilizado: [null],
			referenciasDte: [null],
			region: [null],
			comuna: [null],
			selectedRangeDate: this.fechaInicio ? { startDate: this.fechaInicio, endDate: this.fechaFin } : null,
			selectedRangeDateExpiration: null,
			selectedRangeDateReception: null,
			estadoAcusoEmitidos: null,
			anulados: [TriState.Indeterminate],
		});
		this.criteriosKeys = this.config.criteriosKeys;
		if (this.emisorBusquedaDefault && this.filtroEmisor && this.mostrarListaCompletaEmisores) {
			let currentEmisor = this.emisoresSource.find((em) => em.emisorId == this.emisorBusquedaDefault);
			this.filtrosBusqueda.controls.emisor.patchValue(currentEmisor);
		}
		if (this.filtroEmisor && this.searchByCurrentEmisorOnly && !this.mostrarListaCompletaEmisores && this.listaCompletaEmisores.length == 0) {
			this.emisoresSource = this.emisoresSource.filter((em) => em.emisorId == this.tokenService.getCompany());
			this.filtrosBusqueda.controls.emisor.patchValue(this.emisoresSource[0]);
		}
		this.selectCurrentEmisor();
		let sucursal = this.sucursalesSource.find((suc) => suc.sucursalId == this.tokenService.getSubsidiary());
		this.f.sucursal.patchValue(sucursal);
		this.filterSucursalesByEmisor();
	}

	get f() {
		return this.filtrosBusqueda.controls;
	}

	changeValidation(event) {
		if (this.filtrosBusqueda.controls.criterios.value.includes("Rut")) {
			this.filtrosBusqueda.controls.campoBusqueda.setValidators([VerificacionRutValidator]);
		} else {
			this.filtrosBusqueda.controls.campoBusqueda.setValidators([]);
		}
		this.filtrosBusqueda.controls.campoBusqueda.updateValueAndValidity();
	}
	filtrar() {
		this.submitted = true;

		if (this.filtrosBusqueda.invalid && this.filtrosBusqueda.controls.criterios.value.includes("Rut")) {
			return;
		}
		let parseFechaInicio = Date.parse(this.f.fechaInicio.value);
		var fInicio: Date;
		var fFin: Date;
		if (isNaN(parseFechaInicio) === false) {
			fInicio = new Date(parseFechaInicio);
			fInicio = new Date(parseFechaInicio + fInicio.getTimezoneOffset() * 60000);
		}
		let parseFechaFin = Date.parse(this.f.fechaFin.value);
		if (isNaN(parseFechaFin) === false) {
			fFin = new Date(parseFechaFin);
			fFin = new Date(parseFechaFin + fFin.getTimezoneOffset() * 60000);
		}

		let parseFechaVencInicio = Date.parse(this.f.fechaVencimientoInicio.value);
		var fVInicio: Date;
		var fVFin: Date;
		if (isNaN(parseFechaVencInicio) === false) {
			fVInicio = new Date(parseFechaVencInicio);
			fVInicio = new Date(parseFechaVencInicio + fVInicio.getTimezoneOffset() * 60000);
		}
		let parseFechaVencFin = Date.parse(this.f.fechaVencimientoFin.value);
		if (isNaN(parseFechaVencFin) === false) {
			fVFin = new Date(parseFechaVencFin);
			fVFin = new Date(parseFechaVencFin + fVFin.getTimezoneOffset() * 60000);
		}

		let parseFechaRecepcInicio = Date.parse(this.f.fechaRecepcionInicio.value);
		var fRInicio: Date;
		var fRFin: Date;
		if (isNaN(parseFechaRecepcInicio) === false) {
			fRInicio = new Date(parseFechaRecepcInicio);
			fRInicio = new Date(parseFechaRecepcInicio + fRInicio.getTimezoneOffset() * 60000);
		}
		let parseFechaRecepFin = Date.parse(this.f.fechaRecepcionFin.value);
		if (isNaN(parseFechaVencFin) === false) {
			fRFin = new Date(parseFechaRecepFin);
			fRFin = new Date(parseFechaRecepFin + fRFin.getTimezoneOffset() * 60000);
		}
		let criterioIndex = this.config.criterios.indexOf(this.criterioPrincipal);
		let criterioValue = "";
		if (criterioIndex != -1) {
			criterioValue = this.config.criteriosKeys[criterioIndex];
		}

		let referencesCodes = [];
		if (this.filtroDteReferencias) {
			let codigosReferencia = this.f.referenciasDte.value as TipoReferencium[];
			if (codigosReferencia) {
				let codes = codigosReferencia.map((ref) => ref.codigo);
				referencesCodes = codes;
			}
		}
		const data = {
			criterio: criterioValue,
			busqueda: this.f.campoBusqueda.value,
			fechaInicio: this.f.selectedRangeDate.value && this.f.selectedRangeDate.value.startDate ? this.startingDate(this.f.selectedRangeDate.value.startDate) : undefined,
			fechaFin: this.f.selectedRangeDate.value && this.f.selectedRangeDate.value.endDate ? this.endingDate(this.f.selectedRangeDate.value.endDate) : undefined,
			fechaVencInicio: this.f.selectedRangeDateExpiration.value && this.f.selectedRangeDateExpiration.value.startDate ? this.startingDate(this.f.selectedRangeDateExpiration.value.startDate) : undefined,
			fechaVencFin: this.f.selectedRangeDateExpiration.value && this.f.selectedRangeDateExpiration.value.endDate ? this.endingDate(this.f.selectedRangeDateExpiration.value.endDate) : undefined,
			fechaRecepInicio: this.f.selectedRangeDateReception.value && this.f.selectedRangeDateReception.value.startDate ? this.startingDate(this.f.selectedRangeDateReception.value.startDate) : undefined,
			fechaRecepFin: this.f.selectedRangeDateReception.value && this.f.selectedRangeDateReception.value.endDate ? this.endingDate(this.f.selectedRangeDateReception.value.endDate) : undefined,
			tipoDteId: (this.f.tiposDTE.value as TipoDte) ? (this.f.tiposDTE.value as TipoDte).tipoDteId : null,
			tipoTraslado: (this.f.tipoTraslado.value as TipoTraslado) ? (this.f.tipoTraslado.value as TipoTraslado).id : null,
			tipoDespacho: (this.f.tipoDespacho.value as TipoDespacho) ? (this.f.tipoDespacho.value as TipoDespacho).id : null,
			tipoEnvioCorreo: (this.f.tipoEnvioCorreo.value as TipoCorreoEnvio) ? (this.f.tipoEnvioCorreo.value as TipoCorreoEnvio).tipoEnvioCorreoId : "",
			tipoPlantillaEnvioCorreo: (this.f.tipoPlantillaEnvioCorreo.value as TipoPlantillaCorreoEnvio) ? (this.f.tipoPlantillaEnvioCorreo.value as TipoPlantillaCorreoEnvio).tipoPlantillaEnvioCorreoId : "",
			facturable: (this.f.facturable.value as Facturable) ? (this.f.facturable.value as Facturable).facturableId : null,
			activo: this.f.estadosPlan.value ? this.getEstadoPlan(this.f.estadosPlan.value) : 1,
			emisorId: (this.f.emisor.value as Emisor) ? (this.f.emisor.value as Emisor).emisorId : null,
			usuarioId: (this.f.usuario.value as Usuario) ? (this.f.usuario.value as Usuario).usuarioId : null,
			estadoDte: (this.f.estadoDte.value as EstadoSii) ? (this.f.estadoDte.value as EstadoSii).estadoId : null,
			sucursalId: (this.f.sucursal.value as Sucursal) ? (this.f.sucursal.value as Sucursal).sucursalId : null,
			origenImportacion: this.f.origenImportacion.value ? +this.f.origenImportacion.value.origenImportacionId : "",
			registroActivo: this.f.registroActivo.value ? this.f.registroActivo.value : null,
			registroDespliegue: this.f.registroDespliegue.value,
			importado: this.f.importado.value ? this.f.importado.value : false,
			reutilizado: this.f.reutilizado.value ? this.f.reutilizado.value : false,
			productoExento: this.f.productoExento.value ? this.f.productoExento.value : null,
			pais: this.f.pais.value ? this.f.pais.value.codigoSii : null,
			paisInline: this.f.paisInline.value ? this.f.paisInline.value.paisId : null,
			puerto: this.f.puerto.value ? this.f.puerto.value.puertoId : null,
			clausulaVenta: this.f.clausulaVenta.value ? this.f.clausulaVenta.value.clausulaVentaId : null,
			modalidadVenta: this.f.modalidadVenta.value ? this.f.modalidadVenta.value.modalidadVentaId : null,
			listaCodigosReferenciaProveedores: this.filtroDteReferencias && referencesCodes ? referencesCodes : [],
			estadoAcuseEmitido: this.f.estadoAcusoEmitidos.value ? this.f.estadoAcusoEmitidos.value.id : null,
			region: this.f.region.value ? this.f.region.value.nombre : null,
			comuna: this.f.comuna.value ? this.f.comuna.value.nombre : null,
			nulos: this.f.anulados.value == TriState.Checked ? true : this.f.anulados.value == TriState.Unchecked ? false : null,
		};
		//  }
		//console.log(this.f.selectedRangeDate.value.startDate, this.startingDate(this.f.selectedRangeDate.value.startDate),this.endingDate(this.f.selectedRangeDate.value.endDate));
		this.busqueda.emit(data);

		this.selectedRangeDateExpiration = undefined;
	}

	/**
	 * Sidebar open/close in responsive
	 *
	 * @param event     Sidebar open/close
	 */
	sidebar(event) {
		const toggleIcon = document.getElementById("sidebar-left");
		const toggle = document.getElementById("content-overlay");
		if (event.currentTarget.className === "sidebar-toggle d-block d-lg-none") {
			this._renderer.addClass(toggleIcon, "show");
			this._renderer.addClass(toggle, "show");
		}
	}

	getEstadoPlan(estado: boolean) {
		if (estado == false) return 1;
		if (estado == true) return 0;
	}

	openDatepicker(pickerRef: HTMLElement) {
		//console.log(pickerRef, this.mainPickerRef);
		//this.MainDatePicker.open();
		setTimeout(() => {
			this.MainDatePicker.open();
		});
		// this.mainPickerRef.nativeElement.click();
		let event = new MouseEvent("click", { bubbles: true });
		this.mainPickerRef.nativeElement.dispatchEvent(event);
		// pickerRef.focus();
		// pickerRef.click();

		// let materialCalendarSibling = (pickerRef as HTMLElement).nextSibling;
		// this._renderer.removeClass(materialCalendarSibling.firstChild,'hidden');
		// this._renderer.addClass(materialCalendarSibling.firstChild,'shown');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.listaCompletaEmisores != null && changes.listaCompletaEmisores.currentValue != null) {
			this.emisoresSource = changes.listaCompletaEmisores.currentValue;
		}
	}

	clickedAccordionButton() {
		this.clicked = !this.clicked;
	}
	filterSucursalesByEmisor() {
		let emisor = this.f.emisor.value as Emisor;
		if (emisor != null) {
			this.sucursalesSource = this.allOwnSucursalesSource.filter((suc) => suc.emisorId == emisor.emisorId);
			//console.log("Emisor seleccionado: ",emisor,"lista: ",this.sucursalesSource,"todos suc:",this.allOwnSucursalesSource,"emisores:",this.emisoresSource)
			if (this.sucursalesSource.length > 0) {
				this.f.sucursal.patchValue(this.sucursalesSource[0]);
			} else {
				this.f.sucursal.patchValue(this.sucursalesSource[0]);
			}
		} else {
			this.f.sucursal.patchValue(null);
		}
	}

	consultaSucursalCompleto() {
		//if the search bar need all the sucursal list, not only those assigned to the user, then request the full list of sucursales if its not already in the localStorage
		this.loading = true;
		let sucursalFilter = new SucursalFilter();
		let page = new Page();
		page.pageSize = 99999;
		this.emisorService.getSucursalDataByPage(sucursalFilter, page).subscribe(
			(result) => {
				//console.log("sucursales completas",result.data)
				this.loading = null;
				this.ref.detectChanges();
				let allSucursales = (result.data as Sucursal[]).filter((suc) => suc.activa == true);
				this.localStorage.saveAllSucursales(allSucursales);
				this.allOwnSucursalesSource = allSucursales;
				this.sucursalesSource = this.allOwnSucursalesSource.filter((suc) => suc.emisorId == this.tokenService.getCompany());
				//console.log("sucursales completas:",this.sucursalesSource,"emisorId",this.tokenService.getCompany(),result.data);
			},
			(error) => {
				this.loading = null;
			}
		);
	}
	startingDate(date: moment.Moment) {
		var m = moment(date).utcOffset(0);
		m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		//console.log(m.toISOString(),date);
		return m.toISOString();
	}
	endingDate(date: moment.Moment) {
		//var m = moment(date).utcOffset(0);
		//m.set({hour:23,minute:59,second:59,millisecond:59})
		//console.log(m.toISOString(),date.utcOffset(0,true).set({hour:23,minute:59,second:59,millisecond:59}).toISOString());
		return date.utcOffset(0, true).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).toISOString();
	}

	selectCurrentEmisor() {
		if (this.emisoresSource) {
			let emisor = this.emisoresSource.find((em) => em.emisorId == this.tokenService.getCompany());
			if (emisor) {
				this.filtrosBusqueda.controls.emisor.patchValue(emisor);
			}
		}
	}

	loadComunasOfRegion(event: Region) {
		this.utilities.sortAlphabeth(event.comunas, "nombre");
		this.comunasSource = event.comunas;
	}
}
