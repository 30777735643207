export class ProductoFilter {
   productoId: string;
   codigoBarra: string;
   codigoInterno: string;
   activo: boolean;
   exento: boolean;
   nombre: string;
   emisorId: string;
   unidadMedida: string;
   sucursalId: string;
}
