<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"> </ngx-loading>
<ng-template #customLoadingTemplate></ng-template>
<div class="app-content content">
	<div class="content-wrapper">
		<div class="content-header row mb-1"></div>
		<div class="content-detached">
			<div class="content-body">
				<!-- insertar barra de busqueda -->
				<app-search-bar [criterioPrincipal]="" [config]="configLocal.barraBusqueda" [filtroOrigenImportacion]="true" [nombreCampoDatePicker]="configLocal.barraBusqueda.nombreCampoDatePicker" [useAccordionMode]="true" [useInlineDeactivatedCheckbox]="false" [useInlineSearchButton]="true" (busqueda)="setPage({ offset: 0 }, $event)" [filtroEmisor]="true" [filtroSucursal]="true" [listaCompletaEmisores]="empresasSource" [mostrarListaCompletaEmisores]="true"> </app-search-bar>
				<section class="row">
					<div class="col-12">
						<div class="card card-custom">
							<div class="card-header">
								<h4 class="card-title">{{ configLocal.table.title }}</h4>
								<div class="card-toolbar"></div>
							</div>
							<div class="card-head">
								<div class="heading-elements mt-0">
									<!---->
									<!-- <button class="btn btn-primary float-right mr-5 mt-5" (click)="downloadGlossary()">
                                        <i class="flaticon-download-1"></i>
                                        Descargar Glosario
                                    </button> -->

									<!-- *ngIf="permissions.getPermission('40-10-14').asignado"-->

									<div ngbDropdown>
										<button ngbDropdownToggle id="dropdownDownloadFiles" class="btn btn-info float-right mr-5 mt-5">
											<i class="flaticon-refresh"></i>
											Descarga de Intrucciones
										</button>
										<div ngbDropdownMenu aria-labelledby="dropdownDownloadFiles">
											<button ngbDropdownItem (click)="downloadInstructions()">Intrucciones Generales</button>
											<button ngbDropdownItem (click)="downloadGlossary()">Glosario de Exportación</button>
										</div>
									</div>
									<div ngbDropdown>
										<button type="button" ngbDropdownToggle id="dropdownEjemplos" class="btn btn-primary float-right mr-5 mt-5">
											<i class="flaticon-download"></i>
											Ejemplos CSV
										</button>
										<div ngbDropdownMenu aria-labelledby="dropdownEjemplos">
											<button type="button" ngbDropdownItem (click)="getSample(0)">Standard</button>
											<button type="button" ngbDropdownItem (click)="getSample(1)">Exportación</button>
										</div>
									</div>
									<button class="btn btn-success float-right mt-5 mr-5" (click)="openAddDteFileModal(loadFileModal)" *ngIf="permissions.getPermission('40-10-14').asignado">
										<i class="flaticon-download-1"></i>
										<span>{{ configLocal.table.add }}</span>
									</button>
								</div>
							</div>
							<div class="card-content">
								<div class="card-body">
									<!-- seccion tabla -->
									<ngx-datatable
										#table
										class="material rows contacts-table mobile-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[externalSorting]="false"
										[sortType]="SortType.multi"
										(sort)="utilities.onSortTable(table, page)"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<ngx-datatable-column name="fechaCarga" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'date-table-alignment'" [headerClass]="'date-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="date-table-alignment">Fecha</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="date-table-alignment">{{
													row.fechaCarga
														| date
															: "dd/MM/yyyy
                                                    HH:mm"
												}}</span></ng-template
											>
										</ngx-datatable-column>

										<!-- <ngx-datatable-column name="Usuario" [flexGrow]="4" [sortable]="false" [resizeable]="false">
                                        <ng-template let-column="column" ngx-datatable-header-template>
                                            <span class="text-table-alignment">Usuario</span>
                                        </ng-template>
                                        <ng-template ngx-datatable-cell-template let-row="row"><span class="text-table-alignment">{{row.nombreUsuario }}</span></ng-template>
                                    </ngx-datatable-column> -->
										<ngx-datatable-column [cellTemplate]="names" [flexGrow]="6" [sortable]="false" name="Origen y Usuario" [resizeable]="false"></ngx-datatable-column>
										<ngx-datatable-column name="cantidadDte" [flexGrow]="1.3" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">DTE</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.cantidadDte | number : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="cantidadClientes" [flexGrow]="2" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Clientes</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="number-table-alignment">{{ row.cantidadClientes | number : "1.0-0" : "es" }}</span></ng-template
											>
										</ngx-datatable-column>
										<ngx-datatable-column name="montoTotal" [flexGrow]="3" [sortable]="true" [resizeable]="false" [cellClass]="'number-table-alignment'" [headerClass]="'number-table-alignment'">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="number-table-alignment">Monto</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row">
												<span class="number-table-alignment" *ngIf="row.origenImportacionId == 0">{{
													row.montoTotal
														| currency
															: "$
                                                    "
															: "symbol"
															: "1.0-0"
															: "es"
												}}</span>
												<span class="number-table-alignment" *ngIf="row.origenImportacionId == 1">{{
													row.montoTotal
														| currency
															: "$
                                                    "
															: "symbol"
															: "1.0-2"
															: "es"
												}}</span>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Estado" [flexGrow]="2.3" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Estado</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">{{
													row.tieneErrores
														? "Con
                                                    Errores"
														: "Terminado"
												}}</span></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="2.5" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Resumen" class="primary edit mr-1 mrless" id="facturador-masivo.component_Resumen" (click)="detail(resultDetailModal, row)" *ngIf="permissions.getPermission('40-14').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" container="body" ngbTooltip="Detalle Documentos" class="primary edit mr-1 mrless" (click)="relatedDtes(row)" *ngIf="permissions.getPermission('40-16').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="book" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a *ngIf="row.origenImportacionId != 2 && row.tieneArchivo && permissions.getPermission('40-12').asignado" [routerLink]="" (click)="downloadFile(row.importacionMasivaId)" ngbTooltip="Descargar Archivo">
													<!-- <span
                                                 [inlineSVG]="
                                                 './assets/media/svg/files/csv.svg'
                                                 "
                                                 cacheSVG="true"
                                                 class="svg-icon svg-icon-xl svg-icon-info"
                                             ></span> -->
													<div class="icon-circle bg-warning text-center align-middle pt-file-button">
														<fa-icon icon="file-code" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-auto">Elementos por página</div>
												<div class="mr-5">
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-5 col-auto divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos totales</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
									<!-- fin seccion tabla seccion tabla -->

									<!-- tabla movil -->
									<ngx-datatable
										#tableMobile
										*ngIf="componentStarted"
										class="material desktop-hidden"
										[rows]="rowsMantenedor"
										[columnMode]="'flex'"
										[headerHeight]="50"
										[footerHeight]="50"
										[rowHeight]="70"
										fxFlex="auto"
										[perfectScrollbar]="configScroll"
										[externalPaging]="true"
										[count]="page.totalElements"
										[offset]="page.pageNumber"
										[limit]="page.pageSize"
										(page)="setPage($event)"
										[scrollbarH]="true"
										[messages]="{ emptyMessage: 'Sin datos para mostrar' | translate, totalMessage: 'Elementos totales' | translate, selectedMessage: false }">
										<!-- Row Detail Template -->
										<ngx-datatable-row-detail [rowHeight]="70" #myDetailRow>
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
												<div class="text-wrap">
													Cantidad Dte: {{ row.cantidadDte | number : "1.0-0" : "es" }} , Cantidad Clientes: {{ row.cantidadClientes | number : "1.0-0" : "es" }} , Monto: {{ row.montoTotal | currency : "$ " : "symbol" : "1.0-0" : "es" }}, ¿Errores?:
													{{
														row.tieneErrores
															? "Con
                                                    Errores"
															: "Terminado"
													}}
												</div>
											</ng-template>
										</ngx-datatable-row-detail>
										<ngx-datatable-column [width]="30" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
											<ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
												<a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)" class="desktop-hidden"> </a>
											</ng-template>
										</ngx-datatable-column>
										<ngx-datatable-column name="Razón Social" [flexGrow]="5.5" [sortable]="false" [resizeable]="false">
											<ng-template let-column="column" ngx-datatable-header-template>
												<span class="text-table-alignment">Fecha Carga</span>
											</ng-template>
											<ng-template ngx-datatable-cell-template let-row="row"
												><span class="text-table-alignment">
													{{
														row.fechaCarga
															| date
																: "dd/MM/yyyy
                                                    HH:mm"
													}}</span
												></ng-template
											>
										</ngx-datatable-column>

										<ngx-datatable-column name="Acciones" [flexGrow]="3" [sortable]="false" [resizeable]="false">
											<ng-template ngx-datatable-cell-template let-row="row">
												<a [routerLink]="" ngbTooltip="Resumen" class="primary edit mr-1 mrless" id="facturador-masivo.component_Resumen" (click)="detail(resultDetailModal, row)" *ngIf="permissions.getPermission('40-14').asignado">
													<div class="icon-circle bg-warning text-center align-middle pt-info-button">
														<fa-icon icon="info-circle" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
												<a [routerLink]="" container="body" ngbTooltip="Detalle Documentos" class="primary edit mr-1 mrless" (click)="relatedDtes(row)" *ngIf="permissions.getPermission('40-16').asignado">
													<div class="icon-circle bg-info text-center align-middle pt-info-button">
														<fa-icon icon="book" class="fa-lg text-white"></fa-icon>
													</div>
												</a>

												<a *ngIf="row.origenImportacionId != 2 && row.tieneArchivo && permissions.getPermission('40-12').asignado" [routerLink]="" (click)="downloadFile(row.importacionMasivaId)" ngbTooltip="Descargar Archivo">
													<div class="icon-circle bg-warning text-center align-middle pt-file-button">
														<fa-icon icon="file-code" class="fa-lg text-white"></fa-icon>
													</div>
												</a>
											</ng-template>
										</ngx-datatable-column>

										<ngx-datatable-footer>
											<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
												<div class="col-2 pl-0">Elementos por página</div>
												<div>
													<ng-select [(ngModel)]="page.pageSize" [items]="page.pageSizes" [clearable]="false" (change)="setPageSize()" dropdownPosition="top" style="overflow: visible" appendTo="datatable-footer">
														<option *ngFor="let option of page.pageSizes" [value]="option"></option>
													</ng-select>
												</div>
												<div class="ml-2 pl-1 pr-0 col-3 divisor-footer">
													<div class="page-count">{{ rowCount }} Elementos</div>
												</div>
												<datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!(rowCount / pageSize > 1)" (change)="tableMobile.onFooterPage($event)"> </datatable-pager>
											</ng-template>
										</ngx-datatable-footer>
									</ngx-datatable>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>

<ng-template #loadFileModal let-c="close" let-d="dismiss">
	<form (ngSubmit)="onSubmit()" [formGroup]="dteFileForm" #localFormRef="ngForm">
		<div class="modal-xl">
			<div class="modal-header d-flex justify-content-between">
				<div class="modal-wizard-label">
					<div class="modal-wizard-title">Facturación Masiva</div>
					<div class="modal-wizard-desc">Carga de Archivo de Facturación</div>
				</div>

				<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-group row mx-auto align-items-center">
					<ngx-dropzone class="mx-auto" (change)="onSelect($event)" maxFileSize="2097152" [accept]="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'" multiple="false">
						<ngx-dropzone-label class="px-5">
							<i class="flaticon-upload"></i>
							<p>Arrastra o selecciona tus archivos de facturación.</p>

							<p>El formato del archivo debe ser .csv</p>
						</ngx-dropzone-label>
						<!-- <ngx-dropzone-label class="d-inline">
                           
                        </ngx-dropzone-label> -->
						<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
							<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
						</ngx-dropzone-preview>
					</ngx-dropzone>
					<div *ngIf="localFormRef.submitted && (dteFileForm.get('file').invalid || dteFileForm.get('file').dirty || dteFileForm.get('file').touched || dteFileForm.get('file').value == null)" class="invalid-feedback mx-auto drop-feedback px-40">
						<div *ngIf="dteFileForm.get('file').errors?.required" class="pl-40 ml-15">Campo requerido</div>
					</div>
				</div>
				<div class="form-group row mx-auto">
					<div class="offset-md-4 col-4">
						<div class="col-12 label-control">Tipo de Cliente</div>
						<div class="col-12">
							<ng-select
								formControlName="tipoCliente"
								[items]="tipoClienteSource"
								[multiple]="false"
								placeholder="Seleccionar tipo de cliente"
								bindLabel="nombre"
								[clearable]="false"
								[ngClass]="{
									'is-invalid': localFormRef.submitted && dteFileForm.get('tipoCliente').invalid
								}">
							</ng-select>
							<div *ngIf="localFormRef.submitted && (dteFileForm.get('tipoCliente').invalid || dteFileForm.get('tipoCliente').dirty || dteFileForm.get('tipoCliente').touched || dteFileForm.get('tipoCliente').value == null)" class="invalid-feedback">
								<div *ngIf="dteFileForm.get('tipoCliente').errors?.required">Campo requerido</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="form-group position-relative has-icon-left mb-0">
					<button class="submit" class="btn btn-success">
						<i class="flaticon-paper-plane d-lg-none"></i>
						<span class="d-none d-lg-block">Guardar</span>
					</button>
					<button type="button" class="btn btn-secondary ml-3" (click)="d('Cross click')">
						<span class="d-none d-lg-block">Cerrar</span>
					</button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<!-- Modal de detalle de Resultado de la facturacion, se muestra al obtener el resultado de facturacion y como consulta de Accion de Detalles en la fila correspondiente -->
<ng-template #resultDetailModal let-c="close" let-d="dismiss">
	<div class="modal-lg">
		<div class="modal-header">
			<h5>Resumen de Proceso de Facturación</h5>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<table class="table" *ngIf="resultData">
				<tbody>
					<tr>
						<td>Registro Leídos</td>
						<td></td>
					</tr>
					<tr>
						<td>Documentos emitidos</td>
						<td></td>
					</tr>
					<tr>
						<td>Clientes</td>
						<td></td>
					</tr>
					<tr>
						<td>Total Facturado</td>
						<td></td>
					</tr>
					<tr>
						<td>Documentos con Problemas</td>
						<td>
							<a [routerLink]=""> {{ resultData }}+ Ver Log</a>
						</td>
					</tr>
				</tbody>
			</table>
			<!-- <p>{{resultStringData}}</p> -->
			<h5 class="pl-3" [innerText]="DetailString"></h5>
			<table class="table mt-5" *ngIf="selectedImportacion">
				<tbody>
					<tr>
						<td>Origen</td>
						<td class="text-right">{{ selectedImportacion.origen }}</td>
					</tr>
					<tr>
						<td>Fecha y hora</td>
						<td class="text-right">{{ selectedImportacion.fechaCarga | date : "dd/MM/yyyy HH:mm" }}</td>
					</tr>
					<tr *ngIf="selectedImportacion.origenImportacionId == 3">
						<td>Codigo de carta</td>
						<td class="text-right">
							{{
								selectedImportacion.codigoCarta
									? selectedImportacion.codigoCarta
									: "Carta
                            no disponible"
							}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="modal-footer">
			<button class="btn btn-success" (click)="d('Cross click')">Aceptar</button>
		</div>
	</div>
</ng-template>

<!-- Modal de resumen con los datos del archivo cargados -->
<ng-template #summaryModal let-c="close" let-d="dismiss">
	<div class="modal-lg">
		<div class="modal-header">
			<h5>Validación de Archivo</h5>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="card border-danger mb-3 mx-auto" style="max-width: 90%" *ngIf="!externalValidFile || sumOfDocumentDetails == 0">
				<div class="card-body text-danger my-3">
					<h5 class="card-title">Error en resultado</h5>
					<p class="card-text">Si el documento cargado no se muestra como válido o el total a facturar es 0, revise el formato del documento .csv con un editor de texto como el bloc de Notas para evitar símbolos de separación sobrantes (";").</p>
					<!-- <p class="card-text">El delimitador ',' (coma) no es el correcto, corrija el símbolo de separación o asegurese que los separadores de decimales sean puntos '.'</p> -->
				</div>
			</div>
			<table class="table">
				<tbody>
					<tr>
						<td>Registros Leidos</td>
						<td>{{ fileData?.length ? fileData.length : "Sin datos" }}</td>
					</tr>
					<tr>
						<td>Número de Documentos</td>
						<td>{{ numberOfDocumentosToSend ? numberOfDocumentosToSend : "Sin datos" }}</td>
					</tr>
					<tr>
						<td>Estructura</td>
						<td>{{ externalValidFile ? "válido" : "Invalido" }}</td>
					</tr>
					<tr>
						<td>Total Items</td>

						<td *ngIf="tipoClienteId == 0">{{ sumOfDocumentDetails | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
						<td *ngIf="tipoClienteId == 1">{{ sumOfDocumentDetails | currency : "$ " : "symbol" : "1.0-2" : "es" }}</td>
					</tr>
					<tr *ngIf="tipoClienteId == 0 && sumaResumenDocumento">
						<td>Total Neto Afecto</td>

						<td>{{ sumaResumenDocumento[0] | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
					<tr *ngIf="tipoClienteId == 0 && sumaResumenDocumento && ivaTotal">
						<td>Total Impuesto</td>

						<td>{{ ivaTotal | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
					<tr *ngIf="tipoClienteId == 0 && sumaResumenDocumento">
						<td>Total Exento</td>
						<td>{{ sumaResumenDocumento[1] | currency : "$ " : "symbol" : "1.0-0" : "es" }}</td>
					</tr>
					<tr *ngIf="tipoClienteId == 0 && sumaResumenDocumento">
						<td><b>Total</b></td>
						<td>
							<b>{{ totalFinal | currency : "$ " : "symbol" : "1.0-0" : "es" }}</b>
						</td>
					</tr>

					<tr *ngIf="!externalValidFile">
						<td>Columnas desconocidas</td>
						<td>
							<!-- <div *ngFor="let column of invalidColumns">
                            {{column}}<br>
                        </div> -->
							<p>
								{{ validationMessage }}
							</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="modal-footer">
			<button class="btn btn-success" (click)="confirmSwalOpener()" [disabled]="!externalValidFile">Facturar</button>
			<button class="btn btn-default" (click)="d('Cross click')">Cancelar</button>
		</div>
	</div>
</ng-template>

<ng-template #names let-row="row">
	<span class="text-table-alignment">{{ row.origen }}</span>
	<span class="text-table-alignment font-italic">{{ row.nombreUsuario }}</span>
</ng-template>
<swal #confirmSwal title="Confirmación" text="¿Esta seguro de eliminar la facturación para este archivo?" confirmButtonText="Aceptar" cancelButtonText="Cancelar" showCancelButton="true" (confirm)="onUpload()" (cancel)="onCancelUpload()"></swal>
<swal #currentSwal title="Operación" text="Operación realizada" icon="info"></swal>
