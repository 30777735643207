import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class AuthConfigService {
	public wepApiRoute = environment.backend_server;
	public loginEndpoint = "/authentication/login";
	public activeDirectoryEndpoint = "/authentication/login/ad";
	public recoverPassEndpoint = "/authentication/recuperarpassword?email=";
	changePasswordEndpoint = "/authentication/cambiarpasswordtemporal";
	blockAccountEndpoint = "/login/blockaccount";
	// changePasswordElementUser="?user=";
	// changePasswordElementString = "";
	LoginRoute = "auth";
	ChangePasswordRoute = "/change-password";
	loginRetries: number = 3;

	constructor() {}
}
