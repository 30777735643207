import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { toJSDate } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import { BehaviorSubject, Observable, of } from "rxjs";
import { PermissionService } from "src/app/services/permission.service";
import { Permission } from "src/app/shared/models/Permission";
import { AuthConfigService } from "./auth-config.service";
import { AuthService } from "./auth.service";
import { mergeMap } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class RoleGuard implements CanActivate {
	public static reload$ = new BehaviorSubject<void>(null);

	private basePermission: Permission = new Permission();
	//private routePermissions: Permission[] = [];
	constructor(private permissions: PermissionService, private router: Router, private config: AuthConfigService, private authService: AuthService) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return RoleGuard.reload$.pipe(
			mergeMap(() => {
				let permission = this.permissions.getAccessPermission();
				this.basePermission = permission;
				let routePermission = this.permissions.getRoutePermission(state.url);
				if (this.basePermission && this.basePermission.asignado && routePermission) {
					return of(this.basePermission.asignado);
				} else if (!routePermission && state.url != "/inicio") {
					this.router.navigate(["/bloqueado"]); //
					return of(false);
				} else {
					this.router.navigate([this.config.LoginRoute]); //
					this.authService.logout();
					return of(false);
				}
			})
		);
	}
}
