<div class="row" style="margin-top: 200px !important;" *ngIf="!headerMode">
    <div class="col-12">
        <div class="row mx-auto">
            <div class="mx-auto">
                <div class="icon-circle text-center align-middle d-block pt-2" [ngClass]="ambientColor">
                    <fa-icon [icon]="ambientIcon" class="fa-lg text-white mt-auto"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="mx-auto mt-2">
            <span class="badge badge-pill mx-auto text-white" [ngClass]="pillName">{{ambientName}}</span>
        </div>
    </div>
</div>
<div class="row align-items-center" *ngIf="headerMode">
    <div class="mx-auto mr-2 pr-2">
        <div class="icon-circle text-center align-middle d-block pt-2" [ngClass]="ambientColor">
            <fa-icon [icon]="ambientIcon" class="fa-lg text-white mt-auto"></fa-icon>
        </div>
    </div>
    <div class="mx-auto pr-3">
        <span class="badge badge-pill mx-auto text-white" [ngClass]="pillName">{{ambientName}}</span>
    </div>
    <div class="mx-auto pr-3" *ngIf="isOffline">
        <span class="badge badge-pill mx-auto text-dark font-weight-bold bg-secondary">
            Offline
        </span>
    </div>
</div>