export const ConfigFolios = {
	barraBusqueda: {
		criterios: ["Tipo DTE"],
		criteriosKeys: ["tipoDte"],
		criterioPrincipal: "Tipo DTE",
		filiales: [] as any[],
	},
	table: {
		title: "Timbrajes",
		add: "Nuevo Timbraje",
		options: [
			{
				name: "Importar",
				active: true,
				class: "ft-upload",
			},
			{
				name: "Exportar",
				active: true,
				class: "ft-download",
			},
			{
				name: "Solicitar Soporte",
				active: true,
				class: "ft-shuffle",
			},
		],
	},
};
