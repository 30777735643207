import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LogTransaccion } from "../models/LogTransaccion/LogTransaccion";
import { LogTransaccionFilter } from "../models/LogTransaccion/LogTransaccionFilter";
import { Page } from "../shared/models/Page";
import { PagedResponse } from "../shared/models/PagedResponse";

@Injectable({
   providedIn: "root",
})
export class LogsTransaccionesService {
   private REST_API_SERVER = environment.backend_server + "/log";

   constructor(private http: HttpClient) {}

   getDataByPage(filtro: LogTransaccionFilter, page: Page): Observable<PagedResponse<LogTransaccion>> {
      let mensaje = filtro.mensaje ? filtro.mensaje : "";
      let fechaDesde = filtro.fechaDesde ? filtro.fechaDesde : "";
      let fechHasta = filtro.fechaHasta ? filtro.fechaHasta : "";
      let emisorId = filtro.emisorId ? filtro.emisorId : "";
      let usuarioId = filtro.usuarioId ? filtro.usuarioId : "";
      let emisoresList = filtro.emisoresIdList != null && filtro.emisoresIdList.length > 0 ? JSON.stringify(filtro.emisoresIdList) : "";
      var objectRequest = new FormData();
      objectRequest.append("logFilter", JSON.stringify(filtro));

      return this.http.post<PagedResponse<LogTransaccion>>(
         this.REST_API_SERVER +
            "/list/filter?" +
            // 'fechaDesde='+fechaDesde+
            // '&fechaHasta='+fechHasta+
            // '&mensaje='+mensaje+
            // '&emisorId='+emisorId+
            // '&usuarioId='+usuarioId+
            // '&emisoresIdList='+emisoresList+
            // "&pageNumber="+page.pageNumber
            "pageNumber=" +
            page.pageNumber +
            "&pageSize=" +
            page.pageSize,
         objectRequest
      );
   }
}
